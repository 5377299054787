import React, {useEffect, useState} from "react";
import FormGroup from "../../../../../components/forms/FormGroup";
import {useForm} from "react-hook-form";
import axios from "axios";
import {Apis} from "../../../../../util/Apis";
import AlmacenSelect from "../../../../common/components/selects/AlmacenSelect";
import SalaSelectV2 from "../../../../common/components/selects/SalaSelectV2";
import ButtonGroup from "../../../../../components/forms/ButtonGroup";

const MachineMove = ({id, setTitle, setAction}) => {

  const defaultParams = {targetSalaId: null, targetWarehouseId: null}

  const {register, handleSubmit, reset} = useForm({defaultValues: defaultParams});
  const [mode, setMode] = useState(3);

  const doMove = (params) => {
    if (params.targetSalaId == 0) params.targetSalaId = null
    if (params.targetWarehouseId == 0) params.targetWarehouseId = null
    return axios.post(Apis.TGM + '/machines/' + id + '/remove', params)
  }

  useEffect(() => {
    setTitle("Trasladar máquina a:")
  }, [id])

  useEffect(() => {
    setAction(() => handleSubmit(doMove))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <React.Fragment>
      <ButtonGroup
        value={mode}
        onChange={(value) => {
          reset(defaultParams)
          setMode(value)
        }}
        data={[{text: 'ALMACÉN ', value: 1}, {text: 'SALA', value: 2}, {text: 'DAR BAJA', value: 3}]}
      />
      {mode === 2 &&
        <FormGroup>
        <label>Sala</label>
        <SalaSelectV2 {...register("targetSalaId")} />
      </FormGroup>}
      {mode === 1 && <FormGroup>
        <label>Almacen</label>
        <AlmacenSelect {...register("targetWarehouseId")} />
      </FormGroup>}
    </React.Fragment>
  )
}

export default MachineMove
