import React, {useContext, useEffect} from "react";
import {Apis} from "../../../../../util/Apis";
import axios from "axios";
import RelacionAsociadosForm from "./RelacionAsociadosForm";
import {SiteContext} from "../../../../common/context/SiteContext";


const RelacionAsociadosNew = ({setAction, setTitle}) => {

  const {salaId} = useContext(SiteContext)
  const save = (params) => {
    params.salaId = salaId;
    axios.post(Apis.TGM + '/partners-relation', params)
      .catch(err => {
        console.log(err)
      })
  }

  useEffect(() => {
    setTitle("Nueva asociación")
  }, [])

  return (
    <RelacionAsociadosForm value={{}} onSubmit={save} setAction={setAction}/>
  )
}

export default RelacionAsociadosNew