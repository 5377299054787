import React from "react";
import Registrar from "../Registrar";

const CustomerNew = () => {

  return (
    <Registrar data={null}/>
  )
}

export default CustomerNew;
