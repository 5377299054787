import React, {useContext} from 'react'
import {Select} from "../../../../components/forms/FormEl";
import {Apis} from "../../../../util/Apis";
import {SiteContext} from "../../context/SiteContext";
import useFetch2 from "../../../../hooks/useFetch2";

const GroupSelect = React.forwardRef((props, ref) => {

  const {salaId} = useContext(SiteContext)
  const {data, loading} = useFetch2(`${Apis.NOTIF}/customer-group?salaId=${salaId}`)

  return (
    <>
      {
        !loading ?
          <Select name='customerGroupId' ref={ref}>
            {props.todos === 1 && <option hidden value={0}>SELECCIONE</option>}
            {data && data.map(item => <option key={item.id} value={item.id}>{item.name}</option>)}
          </Select>
          :
          <Select disabled={true}>
            <option key={0} value={0}>{"Cargando..."}</option>
          </Select>
      }
    </>
  )
})

export default GroupSelect
