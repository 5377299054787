import React, {useEffect} from "react";
import {Apis} from "../../../../../util/Apis";
import axios from "axios";
import MachineGroupModalForm from "./MachineGroupModalForm";

const MachineGroupNew = ({setAction, setTitle}) => {

  const save = (params) => {
    console.log('guardando grupo...')
    axios.post(Apis.TGM + '/slot-groups', params).then(res => {
    }).catch(err => {
      console.log(err)
    })
  }

  useEffect(() => {
    setTitle("Nuevo grupo")
  }, [])

  return (
    <MachineGroupModalForm value={{}} onSubmit={save} setAction={setAction}/>
  )
}

export default MachineGroupNew
