import React, { useContext, useEffect } from "react";
import axios from "axios";
import { SiteContext } from "../../../common/context/SiteContext";
import { Apis } from "../../../../util/Apis";
import PuntosConfigurationForm from "./PuntosConfigurationForm";
import useGet from "../../../../hooks/useGet";

const PuntosConfiguracionEdit = ({ id, setAction, setTitle }) => {

  const { salaId } = useContext(SiteContext)
  const { data } = useGet({
    url: Apis.PC + '/point-rules/' + id,
    initialValue: []
  })

  const update = (params) => {
    params.id = id
    params.salaId = salaId
    axios.put(Apis.PC + '/point-rules/' + id, params)
  }

  useEffect(() => {
    setTitle("Edición de configuración")
  }, [])


  return (
    <PuntosConfigurationForm value={data} onSubmit={update} setAction={setAction} />
  )
}

export default PuntosConfiguracionEdit
