import React, {useEffect, useState} from 'react';
import PageContainer from '../../../../components/layout/PageContainer';
import PageSection from '../../../../components/layout/PageSection';
import {Title} from "@wargostec/react-components";
import Table from 'react-bootstrap/Table';
import FormGroup, {FormBlock} from '../../../../components/forms/FormGroup';
import Input, {SelectV2} from '../../../../components/forms/FormEl';
import Button, {ButtonBox} from '../../../../components/forms/Button';
import {Apis} from '../../../../util/Apis';
import useFetch2 from '../../../../hooks/useFetch2';
import axios from 'axios';
import {errorToast} from '../../../../components/layout/ToastStyles';
import {FaEdit, FaPlusCircle, FaTrashAlt} from 'react-icons/fa';
import GenericModal2 from '../../../../modals/GenericModal2';
import useEditModal from '../../../../modals/useEditModal';
import NewProfile from '../profiles/NewProfile';
import EditProfile from '../profiles/EditProfile';
import useDeleteModal from '../../../../modals/useDeleteModal';
import DeleteGenericModal from '../../../../modals/DeleteGenericModal';
import ButtonReload from '../../../../components/forms/ButtonReload';
import {LoadingTableV2} from '../../../../components/forms/Tables';
import SwitchToggle from "../../../../components/layout/SwitchToggle";

const PrivSwichButton = ({actionId, groupId, enabled, reload}) => {
  if (enabled === undefined) {
    enabled = false;
  }

  const [switchValue, setSwitchValue] = useState(enabled);

  const modifyAction = (value) => {
    setSwitchValue(!switchValue);

    let payload = {};
    payload.actionId = actionId;
    payload.userGroupId = groupId;
    payload.enable = !enabled;

    axios
      .post(Apis.USER + '/actions/modify', payload)
      .then((res) => {
        reload();
      })
      .catch((err) => {
        console.log('hubo un error');
        errorToast(err.response.data.message, 6000);
        setSwitchValue(!switchValue);
      });
  };

  return (
    <td>
      <SwitchToggle
        name={'enabled'}
        id={'switch' + actionId + '_' + groupId}
        label={''}
        defaultChecked={switchValue}
        onClick={() => modifyAction(enabled)}
      />
    </td>
  );
};

const PermissionsView = () => {
  const [params, setParams] = useState('');
  const [product, setProduct] = useState('gaming')

  const {
    loading,
    data: report,
    fetchData,
    error
  } = useFetch2(Apis.USER + `/actions/report?product=${product}`);

  const deleteElement = (profileId) =>
    axios.delete(Apis.USER + `/user-group/${profileId}`);

  let search = params;

  const handleSearch = (e) => {
    search.term = e.target.value;
    setParams(search);
  };

  const {
    wrapper: wrapper1,
    addAction,
    editAction
  } = useEditModal({
    addComponent: <NewProfile/>,
    editComponent: <EditProfile/>,
    onRefresh: fetchData
  });

  const {wrapper: wrapper2, openDeleteModal} = useDeleteModal({
    text: '¿Desea eliminar este perfil?',
    action: deleteElement,
    onRefresh: fetchData
  });

  return (
    <PageContainer>
      <PageSection>
          <Title text="Permisos" type={'page'}/>
        <ButtonBox>
          <ButtonReload onClick={fetchData}/>
        </ButtonBox>
      </PageSection>

      <PageSection>
        <div>
          <Input placeholder='Buscar por Permisos'/>
        </div>
      </PageSection>

      <PageSection>
        <FormGroup>
          <label>Producto</label>
          <SelectV2 options={
            [
              {id: "gaming", name: "GAMING"},
              {id: "asis", name: "ASISTENCIA"},
            ]
          }
                    onChange={(event) => setProduct(event.target.value)}
          />
        </FormGroup>
      </PageSection>
      <PageSection>
        <FormBlock>
          <LoadingTableV2
            loading={loading}
            error={error}
            data={report && report.groups}
            noDataText={'Aún no hay información'}
          >
            <Table striped hover>
              <thead>
              <tr>
                <th></th>
                {report &&
                  report.groups.map((item) => (
                    <th key={item.id}>
                      <FaEdit
                        style={{marginLeft: '10%', cursor: 'pointer'}}
                        onClick={() => editAction(item.id)}
                      ></FaEdit>

                      <FaTrashAlt
                        style={{marginLeft: '10%', cursor: 'pointer'}}
                        onClick={() => openDeleteModal(item.id)}
                      ></FaTrashAlt>
                    </th>
                  ))}
              </tr>

              <tr>
                <th style={{backgroundColor: '#f0f0f0'}}></th>
                {report &&
                  report.groups.map((item2) => (
                    <th style={{backgroundColor: '#f0f0f0'}} key={item2.id}>
                      {item2.name}
                    </th>
                  ))}

                {report != null ? (
                  <th style={{backgroundColor: '#f0f0f0'}}>
                    <Button
                      size={'s'}
                      variant={'light'}
                      onClick={() => addAction({product: product})}
                    >
                      <FaPlusCircle style={{marginRight: 2}}></FaPlusCircle>
                      Nuevo
                    </Button>
                  </th>
                ) : (
                  <></>
                )}
              </tr>
              </thead>
              <tbody>
              {report &&
                report.actionGroups.map((group) => (
                  <>
                    <h4 style={{margin: '16px 0', fontWeight: 'bold'}}>
                      {group.groupName}
                    </h4>
                    {group.actions.map((item3) => (
                      <tr key={item3.id}>
                        <td>{item3.name}</td>
                        {report.groups.map((pi) => {
                          return (
                            <PrivSwichButton
                              key={item3.id}
                              actionId={item3.id}
                              groupId={pi.id}
                              enabled={item3.data[pi.id]}
                              reload={fetchData}
                            />
                          );
                        })}
                        <td></td>
                      </tr>
                    ))}
                  </>
                ))}
              </tbody>
            </Table>
          </LoadingTableV2>
        </FormBlock>
      </PageSection>
      <GenericModal2 {...wrapper1} />
      <DeleteGenericModal {...wrapper2} />
    </PageContainer>
  );
};

export default PermissionsView;
