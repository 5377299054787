import React from "react";
import { Title } from "@wargostec/react-components";
import { useHistory, useParams } from "react-router";
import CouponTypeForm from "./CouponTypeForm";
import { Apis } from "../../../../util/Apis";
import PageContainer from "../../../../components/layout/PageContainer";
import PageSection from "../../../../components/layout/PageSection";
import { PageBackLabel } from "../../../../components/layout/PageBackLabel";
import { errorToast, successToast } from "../../../../components/layout/ToastStyles";
import AsyncBlock from "../../../../components/forms/AsyncBlock";
import useGet from "../../../../hooks/useGet";
import { usePut } from "../../../../hooks/usePut";

const CouponTypeEdit = () => {

  const { id } = useParams()
  const history = useHistory()

  const { data, loading, fetchData, error } = useGet({
    url: Apis.PC + '/coupon-type/' + id,
  })

  const { mutate } = usePut({
    url: Apis.PC + '/coupon-type/' + id,
    onSuccess: () => {
      successToast()
      history.push('/crm/coupon-types')
    },
    onError: (err) => {
      errorToast(err.response.data.message ? err.response.data.message : "Ocurrió un error al guardar.", 2000)
    }
  })

  const update = async (couponType) => {
    couponType.ini = couponType.iniDate + "T" + couponType.iniTime
    couponType.fin = couponType.finDate + "T" + couponType.finTime
    await mutate(couponType)
  }

  const getDateFormat = (data) => {
    data.iniDate = data.ini.split('T')[0]
    data.iniTime = data.ini.split('T')[1]
    data.finDate = data.fin.split('T')[0]
    data.finTime = data.fin.split('T')[1]
    return data
  }

  return (
    <PageContainer>
      <PageSection>
        <PageBackLabel text='Cupones' onClick={() => history.push('/crm/coupon-types')} />
        <Title text="Editar cupón" type={'page'} />
      </PageSection>

      <PageSection>
        <AsyncBlock loading={loading} error={error}>
          <CouponTypeForm value={data && getDateFormat(data)} onSubmit={update} mode={id} fetchData={fetchData} />
        </AsyncBlock>
      </PageSection>
    </PageContainer>
  )
}

export default CouponTypeEdit
