import React, {useContext, useState} from 'react';
import {Apis} from "../../../../util/Apis";
import {SiteContext} from "../../../common/context/SiteContext";
import Card from "../../../../components/forms/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from '../../../../components/forms/Button';
import axios from "axios";
import {errorToast, successToast} from "../../../../components/layout/ToastStyles";
import {useForm} from "react-hook-form";
import PrinterSelect from "../../../common/components/selects/PrinterSelect";
import {FlexLayout} from '../../../../components/flex/Flex';
import defaultLogo from "../../../../components/images/food.png";
import {Alert} from "../../../../components/util/Alert";
import {ViewerImage} from "../../../../components/util/ViewerImage";

const RedeemProducts = ({data, action}) => {

  const {salaId} = useContext(SiteContext)
  let defaultParams = {enabled: true}
  const [params, setParams] = useState(defaultParams)

  const {register, getValues} = useForm();

  const [enabled, setEnabled] = useState(true)
  const [idProduct, setIdProduct] = useState(0)
  const [printer, setPrinter] = useState(false)

  const goRedeem = (id) => {
    setEnabled(false)
    setIdProduct(id)
  }

  const save = (product) => {
    const printerId = getValues("printerId")
    const param = {
      salaId: salaId,
      customerId: data && data.customer.customerId,
      productId: product,
      printerId: printerId
    }
    axios.post(Apis.PC + '/redeems', param).then(() => {
        setEnabled(true)
        successToast()
        action()
      }
    ).catch(() => {
        setEnabled(true)
        errorToast()
      }
    )
  }

  const image = (value) => {
    let src = `${process.env.REACT_APP_S3_WARGOS_ASSETS}${value}`
    if (value === null) {
      src = defaultLogo
    }
    return src
  }

  const getNameAndValue = (item) => {
    return item.couponTypeId !== null ? (item.exchangeValue + " CUPONES") : (item.exchangeValue + " PUNTOS")
  }

  return (
    <>
      {enabled ?
        <Row>
          <Col>
            <Row>
              {data && data.products.length > 0 ? data.products.map(item =>
                  <Col md={3} sm={12} xs={12}>
                    <Card key={item.id} padding style={{cursor: 'pointer'}}
                          onClick={() => goRedeem(item.id)}>
                      <FlexLayout column alignItems='center'>
                        <strong style={{textAlign: 'center'}}>{item.name}</strong>
                        <ViewerImage className={'img img-fluid'}
                                     src={image(item.photoUrl)}
                                     alt={'imagen'}
                                     style={{width: 'auto', height: '140px'}}
                        />
                        <strong style={{textAlign: 'center'}}>
                          {getNameAndValue(item)}
                        </strong>
                      </FlexLayout>
                    </Card>
                  </Col>
                ) :
                <Alert
                  text={`Cliente sin puntos necesarios para realizar un canje`}
                  variant={'primary'}/>
              }
            </Row>
          </Col>
        </Row>
        :
        <Row>
          <Col>
            {data && data.products.filter(item => item.id === idProduct).map(item =>
              <FlexLayout key={item.id} gap='16px' alignItems='stretch'>
                <Card padding style={{alignItems: 'center', display: 'flex', justifyContent: 'center', flex: 1}}>
                  <FlexLayout column alignItems='center'>
                    <strong style={{textAlign: "center"}}>
                      {item.name}
                    </strong>
                    <ViewerImage className={'img img-fluid'}
                                 src={image(item.photoUrl)} alt={'imagen'}
                                 style={{width: '140px', height: '140px'}} zoom={true}/>
                    <strong style={{textAlign: "center"}}>
                      {getNameAndValue(item)}
                    </strong>
                  </FlexLayout>
                </Card>

                <Card padding style={{flex: 3}}>

                  <Row>
                    <Col md={5}>
                      <FlexLayout justifyContent='space-between'>
                        <strong>{item.couponTypeId !== null ? 'CUPONES DISPONIBLES' : 'PUNTOS DISPONIBLES'}</strong>
                        <span>{item.couponTypeId !== null ? item.couponsQuantity : (data && data.customer.pointsRedeemable)}</span>
                      </FlexLayout>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={5}>
                      <FlexLayout justifyContent='space-between'>
                        <strong>{item.name}</strong>
                        <span>- {item.exchangeValue}</span>
                      </FlexLayout>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={5}>
                      <hr/>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={5}>
                      <FlexLayout justifyContent='space-between'>
                        <strong>PUNTOS RESTANTES</strong>
                        <span>{item.pointDifference}</span>
                      </FlexLayout>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={5}>
                      <FlexLayout column justifyContent='space-between' margin='16px 0'>
                        <strong>Impresora (Opcional):</strong>
                        <PrinterSelect {...register("printerId")} />
                        {printer && <p style={{color: 'red'}}>Seleccione impresora</p>}
                      </FlexLayout>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={5}>
                      <FlexLayout justifyContent='space-between' margin='16px 0'>
                        <Button onClick={() => setEnabled(true)}>
                          Cancelar
                        </Button>
                        <Button variant={'success'} onClick={() => save(item.id)}>
                          Canjear
                        </Button>
                      </FlexLayout>
                    </Col>
                  </Row>

                </Card>
              </FlexLayout>
            )}
          </Col>
        </Row>
      }
    </>
  );
};

export default RedeemProducts;
