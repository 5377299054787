import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {SiteContext} from "../../../common/context/SiteContext";
import {getCasinoDay} from "../../../../components/util/CasinoDates";
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import {useForm} from "react-hook-form";
import Moment from "react-moment";
import Button from "../../../../components/forms/Button";
import SalaIdInput from "../../../common/components/SalaIdInput";
import FormGroup from "../../../../components/forms/FormGroup";
import {DatePickerCustom} from "../../../../components/forms/FormEl";
import MesasSelect from "../../../common/components/selects/MesasSelect";
import Slidebar from "../../../common/layout/Slidebar";
import {Title} from "@wargostec/react-components";
import AsyncBlock from "../../../../components/forms/AsyncBlock";
import PageSection from "../../../../components/layout/PageSection";
import {FlexLayout} from "../../../../components/flex/Flex";
import {useHistory} from "react-router";
import {PageBackLabel} from "../../../../components/layout/PageBackLabel";
import moment from "moment/moment";
import useToggle from "../../../../hooks/useToggle";
import PageContainerFull from '../../../../components/layout/PageContainerFull';
import ReactTable from '../../../../components/util/ReactTable';

const MesasReportSession = () => {

  const {salaId} = useContext(SiteContext)
  const history = useHistory()
  const initialDate = getCasinoDay()
  const {isOpen: slideOpen, toggler} = useToggle({});

  const querystring = window.location.search
  const query = new URLSearchParams(querystring)
  let tableId = query.get('tableId')
  let iniQueryParams = query.get('ini')
  let finQueryParams = query.get('fin')

  let defaultParams = {salaId: salaId, ini: iniQueryParams ? moment(iniQueryParams).format('YYYY-MM-DD') : initialDate,
    fin: finQueryParams ? moment(finQueryParams).format('YYYY-MM-DD') : initialDate, tableId: tableId}

  const [params, setParams] = useState(defaultParams)
  const {loading, data, fetchData, error} = useFetch2(Apis.TABLES + `/snap`, [], params);
  const {register, handleSubmit} = useForm({defaultValues: defaultParams});

  const getData = (params) => {
    setParams(params)
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  const WinValue = useCallback(props => 'S/ ' + (props.row.original.coinInTotal - props.row.original.coinOutTotal), []);
  const estadoValue = useCallback(props => props.renderValue() ? 'Mesa en juego' : 'Cerrado', []);
  const DateValue = useCallback(props => props.renderValue() !== null ? <Moment format={'DD/MM HH:mm'}>{props.renderValue()}</Moment>: '--', []);
  const DefaultValue = useCallback(props => <>{props.renderValue() === null ? 'S/ 0': 'S/ '+props.renderValue()}</>, []);
  const HoldValue = useCallback(props =>
      <span>{props.row.original.coinInTotal === 0 || props.row.original.coinInTotal === null ? 0 :
        (((props.row.original.coinInTotal - props.row.original.coinOutTotal) / props.row.original.coinInTotal) * 100).toFixed(2)}%
    </span>,
    []);

  const actionValue = useCallback(props => 
    <FlexLayout justifyContent='center'>
      <Button variant={'light'} size='xs' onClick={() => history.push(`/tables/detail-session?snapId=${props.renderValue()}`)}>
        Detalle
      </Button>
    </FlexLayout>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  , []);

  const columns = useMemo(
    () => [
      {
        header: 'Inicio',
        accessorKey: 'startDateTime',
        meta: {
          headerClassName:'text-center', 
          columnClassName:'text-center', 
        },
        cell: DateValue
      },
      {
        header: 'Fin',
        accessorKey: 'endDateTime',
        meta: {
          headerClassName:'text-center', 
          columnClassName:'text-center', 
        },
        cell: DateValue
      },
      {
        header: 'Estado',
        accessorKey: 'active',
        meta: {
          headerClassName:'text-center', 
          columnClassName:'text-center', 
        },
        cell: estadoValue
      },
      {
        header: 'Apertura',
        accessorKey: 'iniAmount',
        meta: {
          headerClassName:'text-center', 
          columnClassName:'text-center', 
        },
        cell: DefaultValue
      },
      {
        header: 'Drop',
        accessorKey: 'coinInTotal',
        meta: {
          headerClassName:'text-center', 
          columnClassName:'text-center', 
        },
        cell: DefaultValue
      },
      {
        header: 'Win / Lose',
        id: 'winlose',
        accessorFn: cell => `${cell.coinInTotal} ${cell.coinOutTotal}`,
        meta: {
          headerClassName:'text-center', 
          columnClassName:'text-center', 
        },
        cell: WinValue
      },
      {
        header: 'Rellenos',
        accessorKey: 'cashInTotal',
        meta: {
          headerClassName:'text-center', 
          columnClassName:'text-center', 
        },
        cell: DefaultValue
      },
      {
        header: 'Devoluciones',
        accessorKey: 'cashOutTotal',
        meta: {
          headerClassName:'text-center', 
          columnClassName:'text-center', 
        },
        cell: DefaultValue
      },
      {
        header: 'Hold',
        id: 'Hold',
        accessorFn: cell => `${cell.coinInTotal} ${cell.coinOutTotal}`,
        meta: {
          headerClassName:'text-center', 
          columnClassName:'text-center', 
        },
        cell: HoldValue
      },
      {
        header: 'Cierre',
        accessorKey: 'finAmount',
        meta: {
          headerClassName:'text-center', 
          columnClassName:'text-center', 
        },
        cell: DefaultValue
      },
      {
        header: 'Acciones',
        accessorKey: 'snapId',
        enableGlobalFilter:false, // para deshabilitar el filter
        enableSorting:false, // para deshabilitar el sort
        meta: {
          headerClassName:'text-center', 
          columnClassName:'text-center', 
        },
        cell: actionValue
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ], [])

  return (
    <PageContainerFull>
      <Slidebar visible={slideOpen} onHide={toggler}>
        <div style={{padding: '16px'}}>
          <SalaIdInput {...register("salaId")} />
          <FormGroup>
            <label>Desde</label>
            <DatePickerCustom {...register("ini")} />
          </FormGroup>

          <FormGroup>
            <label>Hasta</label>
            <DatePickerCustom {...register("fin")} />
          </FormGroup>

          <FormGroup>
            <label>Mesa</label>
            <MesasSelect {...register("tableId")} />
          </FormGroup>

          <Button variant={'primary'} title='Buscar' onClick={handleSubmit(getData)}/>
        </div>
      </Slidebar>

      <PageSection>
        <PageBackLabel text='Regresar' onClick={() => history.goBack()}/>
          <Title text={data.length > 0 ? data[0]?.tableCode+' - '+ data[0]?.type : 'Sesiones'}/>

        <AsyncBlock loading={loading} error={error}>
          <ReactTable
            columns={columns}
            data={data || []}
            filterOnclick={toggler}
            filterRefresh={() => fetchData()}
            noData='No existen resultados para el filtro seleccionado'
            pageSize={15}
          />
        </AsyncBlock>
      </PageSection>
    </PageContainerFull>
  );
};

export default MesasReportSession;
