import React, {useContext} from "react";
import {useForm} from "react-hook-form";
import PageContainerFull from "../../../../components/layout/PageContainerFull";
import FormGroup from "../../../../components/forms/FormGroup";
import PageSection from "../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import Card from "../../../../components/forms/Card";
import Input, {Select} from "../../../../components/forms/FormEl";
import {SiteContext} from "../../../common/context/SiteContext";
import MachineSelect from "../../../common/components/selects/MachineSelect";
import Button from "../../../../components/forms/Button";
import {Apis} from "../../../../util/Apis";
import axios from "axios";
import useLoading from "../../../../hooks/useLoading";
import {errorToast, successToast} from "../../../../components/layout/ToastStyles";

const MachineEmulator = () => {

  const {salaId} = useContext(SiteContext)

  const {register, handleSubmit} = useForm();

  const postMeter = async (data) => {
    data.salaId = parseInt(salaId)
    data.confId = parseInt(data.confId)
    data.meter = parseInt(data.meter)
    data.meterId = parseInt(data.meterId)
    await axios.post(Apis.EM + '/meter', data).then(() => {
        successToast()
      }
    ).catch(() => {
      errorToast()
    })
  }

  const {executeSend, loading} = useLoading(postMeter)

  return (
    <PageContainerFull>
      <PageSection>
          <Title text="Emulador"/>
      </PageSection>

      <PageSection>
        <Card padding>

          <FormGroup>
            <label>Máquina</label>
            <MachineSelect {...register("confId")}/>
          </FormGroup>

          <FormGroup>
            <Select {...register("meterId")}>
              <option key={1} value={1}>APOSTADO</option>
              <option key={2} value={2}>GANADO</option>
              <option key={3} value={3}>JACKPOT</option>
              <option key={4} value={4}>GAMES PLAYED</option>
              <option key={6} value={6}>BILLETES</option>
            </Select>
          </FormGroup>

          <FormGroup>
            <label>Créditos</label>
            <Input {...register("meter")}/>
          </FormGroup>
        </Card>

        {/*todo arreglar useLoading*/}
        <Button variant={'primary'} onClick={handleSubmit(postMeter)} disabled={loading}>
          {loading ? 'Cargando' : 'Enviar'}
        </Button>
      </PageSection>

    </PageContainerFull>
  )
}

export default MachineEmulator;
