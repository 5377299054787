import {Title} from '@wargostec/react-components'
import React, {useCallback, useMemo} from 'react'
import {FlexLayout} from "../../../../components/flex/Flex";
import ReactTable from "../../../../components/util/ReactTable";
import AsyncBlock from "../../../../components/forms/AsyncBlock";
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import {getCasinoDay} from "../../../../components/util/CasinoDates";
import Moment from "react-moment";

const MachineEvents = ({confId,size, type}) => {

  const sizeData = size || 100
  const typeData = type || 'form'

  let defaultParams = {ini: getCasinoDay(), fin: getCasinoDay(), confId: confId, size: sizeData}
  const {data, loading, fetchData, error} = useFetch2(Apis.TGM + `/events`, [], defaultParams);

  const eventName = useCallback(props => <>{
    <FlexLayout alignItems='left' justifyContent='left' gap='4px'>
      <span>{props.row.original.eventId + ' - ' + props.row.original.nameEventEs}</span>
    </FlexLayout>
  }</>, [])

  const dateValue = useCallback(props => <>{
    <FlexLayout alignItems='left' justifyContent='left' gap='4px'>
      <span><Moment format={'HH:mm DD/MM/YYYY'}>{props.row.original.eventDate}</Moment></span>
    </FlexLayout>
  }</>, [])

  const columns = useMemo(
    () => [
      {
        header: 'ID',
        accessorKey: 'id',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Fecha / Hora',
        accessorKey: 'eventDate',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        },
        cell: dateValue
      },
      {
        header: 'Evento',
        accessorKey: 'nameEventEs',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        },
        cell: eventName
      },
    ]
    , []
  )

  return (
    <>
      <Title text="Eventos" type={typeData}/>
      <AsyncBlock loading={loading} error={error}>
        <ReactTable
          columns={columns}
          data={data || []}
          customSearch
          noData='No existen resultados'
          pageSize={15}
        />
      </AsyncBlock>
    </>
  )
}

export default MachineEvents
