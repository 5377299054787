import React from 'react'
import PageContainer from "../../../../components/layout/PageContainer";
import PageSection from "../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import UserForm from "./UserForm";
import {useHistory, useParams} from "react-router";
import {Apis} from "../../../../util/Apis";
import {PageBackLabel} from "../../../../components/layout/PageBackLabel";
import useFetch2 from "../../../../hooks/useFetch2";
import AsyncBlock from "../../../../components/forms/AsyncBlock";
import axios from "axios";
import {errorToast, successToast} from "../../../../components/layout/ToastStyles";

const UserEdit = () => {

  const history = useHistory()

  const {id} = useParams()
  const {data, loading, fetchData, error} = useFetch2(Apis.USER + '/users/' + id, {});

  const onUpdate = (user) => {
    if (user.userGroupId === "") {
      user.userGroupId = null
    }
    if (user.roles.asisRoleId === "") {
      user.roles.asisRoleId = null
    }
    user.roles.gamingRoleId = user.userGroupId
    axios.put(Apis.USER + '/users/' + id, user)
      .then(res => {
        successToast("Usuario actualizado correctamente")
        history.push('/admin/users')
      })
      .catch(err => {
        errorToast(err.response.data.message, 6000)
      });
  }

  return (
    <PageContainer>
      <PageSection>
        <PageBackLabel text='Usuarios' onClick={() => history.push('/admin/users')}/>
          <Title text={data.firstName} type={'page'}/>
      </PageSection>

      <PageSection>
        <AsyncBlock loading={loading} error={error}>
          <UserForm value={data} onSubmit={(user) => onUpdate(user)}/>
        </AsyncBlock>

      </PageSection>
    </PageContainer>
  )
}

export default UserEdit;
