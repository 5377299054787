import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import PageContainerFull from "../../../../components/layout/PageContainerFull";
import SalaIdInput from "../../../common/components/SalaIdInput";
import {SiteContext} from "../../../common/context/SiteContext";
import Slidebar from "../../../common/layout/Slidebar";
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import {useForm} from "react-hook-form";
import FormGroup from "../../../../components/forms/FormGroup";
import {Title} from "@wargostec/react-components";
import Button from "../../../../components/forms/Button";
import PageSection from "../../../../components/layout/PageSection";
import {DatePickerCustom, SelectV2} from "../../../../components/forms/FormEl";
import Moment from "react-moment";
import AsyncBlock from '../../../../components/forms/AsyncBlock';
import ReactTable from '../../../../components/util/ReactTable';

const OrdersHistory = () => {

  const {salaId} = useContext(SiteContext)
  let defaultParams = {salaId: salaId, state: "PREPARACION"}

  const [params, setParams] = useState(defaultParams)
  const {loading, data, fetchData, error} = useFetch2(Apis.FOOD + `/food-orders/state-order`, [], params);

  const {register, handleSubmit} = useForm({defaultValues: defaultParams});

  const getData = (params) => {
    setParams(params)
  }

  const [slideOpen, setSlideOpen] = useState(false)

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  const dateValue = useCallback(props => <Moment format={'YYYY-MM-DD HH:mm a'}>{props.renderValue()}</Moment>, []);
  const detailValue = useCallback(props => 
    props.renderValue().length > 0 && props.renderValue().map(x => (<li key={x.id}>{x.quantity} - {x.food.name}</li>))
  , []);
  const clientOrder = useCallback(props => props.row.original.customerName !== null ? props.row.original.customerName : "--" )

  const employeeName = useCallback(props => <>{
    props.row.original.employeeName ? props.row.original.employeeName: '--'
  }</>, []);

  const columns = useMemo(
    () => [
      {
        header: 'N° Orden',
        accessorKey: 'id',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Fecha',
        accessorKey: 'createdAt',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: dateValue
      },
      {
        header: 'Detalle orden',
        accessorKey: 'detail',
        enableSorting:false, // para deshabilitar el sort
        cell: detailValue
      },
      {
        header: 'Cliente',
        accessorKey: 'customerName',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: clientOrder
      },
      {
        header: 'Maquina',
        accessorKey: 'machine',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Realizado por',
        accessorKey: 'employeeName',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: employeeName
      },
      {
        header: 'Estado',
        accessorKey: 'state',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ], [])

  return (
    <PageContainerFull>

      <Slidebar visible={slideOpen} onHide={() => setSlideOpen(false)}>
        <div style={{padding: '16px'}}>
          <SalaIdInput {...register("salaId")} />
          <FormGroup>
            <SelectV2 {...register('state')} options={
              [
                {id: "GENERADO", name: "GENERADO"},
                {id: "PREPARACION", name: "PREPARACION"},
                {id: "LISTO", name: "LISTO"},
                {id: "ENTREGADO", name: "ENTREGADO"},
                {id: "ANULADO", name: "ANULADO"},
              ]
            }/>
          </FormGroup>
          <FormGroup>
            <label>Desde</label>
            <DatePickerCustom  {...register("ini")} />
          </FormGroup>

          <FormGroup>
            <label>Hasta</label>
            <DatePickerCustom  {...register("fin")} />
          </FormGroup>

          <Button variant={'primary'} title='Buscar' onClick={handleSubmit(getData)}/>
        </div>
      </Slidebar>

      <PageSection>
          <Title text="Historial de ordenes" type={'page'}/>
        <AsyncBlock loading={loading} error={error}>
          <ReactTable
            columns={columns}
            data={data || []}
            filterOnclick={() => setSlideOpen(!slideOpen)}
            noData='No existen pedidos para el filtro seleccionado'
            pageSize={15}
          />
        </AsyncBlock>
      </PageSection>

    </PageContainerFull>
  );
};

export default OrdersHistory;
