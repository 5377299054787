import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react'
import PageContainerFull from '../../../../components/layout/PageContainerFull'
import {Button, FormGroup, PageSection, Title} from '@wargostec/react-components'
import useToggle from '../../../../hooks/useToggle';
import {getCasinoDay} from '../../../../components/util/CasinoDates';
import Slidebar from '../../../common/layout/Slidebar';
import {DatePickerCustom} from '../../../../components/forms/FormEl';
import DateFilter2 from '../../../../components/datefilter/DateFilter2';
import CardOverview, {CardOverviewContainer} from '../../../../components/layout/CardOverview';
import {HiOutlineBanknotes} from 'react-icons/hi2';
import {LuCoins, LuTicket} from 'react-icons/lu';
import {numberFormat} from '../../../../components/util/NumberUtil';
import ReactTable from '../../../../components/util/ReactTable';
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import {serializeDownload} from "../../../../util";
import MoneyColored from "../../../../components/util/MoneyColored";
import {FlexLayout} from "../../../../components/flex/Flex";
import {IoEye} from "react-icons/io5";
import {useHistory} from "react-router";
import AsyncBlock from '../../../../components/forms/AsyncBlock';
import {TbListDetails, TbMoneybag} from "react-icons/tb";
import CardTitle from "../../../../components/layout/CardTitle";
import {SiteContext} from "../../../common/context/SiteContext";

const AuditCuadreFisico = () => {

  const {isOpen: slideOpen, toggler} = useToggle({});
  const [showTotals, setShowTotals] = useState(localStorage.getItem('showTotals') || 'total');
  const {salaId} = useContext(SiteContext)

  const querystring = window.location.search
  const query = new URLSearchParams(querystring)
  let periodQueryParams = query.get('period')

  const casinoDay = getCasinoDay()
  if (!periodQueryParams) periodQueryParams = casinoDay

  let fechasDefault = {period: periodQueryParams,salaId: salaId}
  const [fechas, setFechas] = useState(fechasDefault)
  const [tempDate, setTempDate] = useState(fechas.period);
  const {data, fetchData, loading, error} = useFetch2(Apis.CAJA + `/report-fisico`, [], fechas);

  useEffect(() => {
    history.push(`/caja/audit-cuadre-fisico?period=${fechas.period}`)
  }, [fechas])

  useEffect(() => {
    handleSearch()
    // eslint-disable-next-line
  }, [fechas])

  const handleSearch = () => {
    fetchData()
  }

  const download = () => {
    fechas.salaId = salaId
    const link = Apis.CAJA + `/report-fisico/report.xlsx?${serializeDownload(fechas)}`;
    window.location.href = link;
  }

  const history = useHistory();

  const numberValue = useCallback(props => <>{numberFormat(props.renderValue())}</>, []);
  const coloresMoneyValue = useCallback(props => <MoneyColored digits={2} currencySymbol={''}>{props.renderValue()}</MoneyColored>, []);
  const coloresNumberValue = useCallback(props => <MoneyColored digits={0} currencySymbol={''}>{props.renderValue()}</MoneyColored>, []);

  const actionValue = useCallback(props => <>{
    <FlexLayout justifyContent='right'>
      <Button variant={'light'} size='xs' icon={<IoEye />}
        onClick={() => history.push(`/tgm/meter-snap?ini=${props.renderValue()}&fin=${props.renderValue()}&confId=${props.row.original.confId}`)} />
      <Button variant={'light'} size='xs' icon={<TbListDetails />}
        onClick={() => history.push(`/caja/audit-cuadre-fisico-by-conf?period=${props.renderValue()}&confId=${props.row.original.confId}&machine=${props.row.original.machine}`)} />
    </FlexLayout>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }</>, []);

  const columns = useMemo(
    () => [
      {
        header: 'Máquina',
        accessorKey: 'machine',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: "Billetes",
        meta: {
          headerClassName: 'text-center border-l',
        },
        columns: [
          {
            header: 'Contadores',
            accessorKey: 'bill',
            meta: {
              headerClassName: 'text-right border-l',
              columnClassName: 'text-right border-l'
            },
            cell: numberValue
          },
          {
            header: 'Recaudo',
            accessorKey: 'billAudit',
            meta: {
              headerClassName: 'text-right',
              columnClassName: 'text-right'
            },
            cell: numberValue
          },
          {
            header: 'Prebill',
            accessorKey: 'prebill',
            meta: {
              headerClassName: 'text-right',
              columnClassName: 'text-right'
            },
            cell: numberValue
          },
          {
            header: 'Dif',
            accessorKey: 'diffBill',
            meta: {
              headerClassName: 'text-right',
              columnClassName: 'text-right'
            },
            cell: coloresMoneyValue
          }
        ],
      },
      {
        header: "TicketIn",
        meta: {
          headerClassName: 'text-center border-l',
        },
        columns: [
          {
            header: 'Contadores',
            accessorKey: 'ticketIn',
            meta: {
              headerClassName: 'text-right border-l',
              columnClassName: 'text-right border-l'
            },
            cell: numberValue
          },
          {
            header: 'Recaudo',
            accessorKey: 'pticketIn',
            meta: {
              headerClassName: 'text-right',
              columnClassName: 'text-right'
            },
            cell: numberValue
          },
          {
            header: 'Dif',
            accessorKey: 'diffTicketIn',
            meta: {
              headerClassName: 'text-right',
              columnClassName: 'text-right'
            },
            cell: coloresMoneyValue
          }
        ],
      },
      {
        header: "TicketOut",
        meta: {
          headerClassName: 'text-center border-l',
        },
        columns: [
          {
            header: 'Contadores',
            accessorKey: 'ticketOut',
            meta: {
              headerClassName: 'text-right border-l',
              columnClassName: 'text-right border-l'
            },
            cell: numberValue
          },
          {
            header: 'Recaudo',
            accessorKey: 'pticketOut',
            meta: {
              headerClassName: 'text-right',
              columnClassName: 'text-right'
            },
            cell: numberValue
          },
          {
            header: 'Dif',
            accessorKey: 'diffTicketOut',
            meta: {
              headerClassName: 'text-right',
              columnClassName: 'text-right'
            },
            cell: coloresMoneyValue
          }
        ],
      },
      {
        header: "TicketIn Cantidad",
        meta: {
          headerClassName: 'text-center border-l',
        },
        columns: [
          {
            header: 'Contadores',
            accessorKey: 'tiq',
            meta: {
              headerClassName: 'text-right border-l',
              columnClassName: 'text-right border-l'
            },
          },
          {
            header: 'Recaudo',
            accessorKey: 'ptiq',
            meta: {
              headerClassName: 'text-right',
              columnClassName: 'text-right'
            },
          },
          {
            header: 'Dif',
            accessorKey: 'diffTiq',
            meta: {
              headerClassName: 'text-right',
              columnClassName: 'text-right'
            },
            cell: coloresNumberValue
          }
        ],
      },
      {
        header: "TicketOut Cantidad",
        meta: {
          headerClassName: 'text-center border-l',
        },
        columns: [
          {
            header: 'Contadores',
            accessorKey: 'toq',
            meta: {
              headerClassName: 'text-right border-l',
              columnClassName: 'text-right border-l'
            },
          },
          {
            header: 'Recaudo',
            accessorKey: 'ptoq',
            meta: {
              headerClassName: 'text-right',
              columnClassName: 'text-right'
            },
          },
          {
            header: 'Dif',
            accessorKey: 'diffToq',
            meta: {
              headerClassName: 'text-right',
              columnClassName: 'text-right'
            },
            cell: coloresNumberValue
          }
        ],
      },
      {
        header: "Pagos Manuales",
        meta: {
          headerClassName: 'text-center border-l',
        },
        columns: [
          {
            header: 'JP',
            accessorKey: 'jp',
            meta: {
              headerClassName: 'text-right border-l',
              columnClassName: 'text-right border-l'
            },
            cell: numberValue
          },
          {
            header: 'CC',
            accessorKey: 'cc',
            meta: {
              headerClassName: 'text-right',
              columnClassName: 'text-right'
            },
            cell: numberValue
          },
          {
            header: 'Fisico',
            accessorKey: 'hp',
            meta: {
              headerClassName: 'text-right',
              columnClassName: 'text-right'
            },
            cell: numberValue
          },
          {
            header: 'Dif',
            accessorKey: 'diffHandPay',
            meta: {
              headerClassName: 'text-right',
              columnClassName: 'text-right'
            },
            cell: coloresMoneyValue
          }
        ],
      },
      {
        header: "Fichas",
        meta: {
          headerClassName: 'text-center border-l',
        },
        columns: [
          {
            header: 'Contadores',
            accessorKey: 'coin',
            meta: {
              headerClassName: 'text-right border-l',
              columnClassName: 'text-right border-l'
            },
            cell: numberValue
          },
          {
            header: 'Recaudo',
            accessorKey: 'coinAudit',
            meta: {
              headerClassName: 'text-right',
              columnClassName: 'text-right'
            },
            cell: numberValue
          },
          {
            header: 'PFichas',
            accessorKey: 'preCoin',
            meta: {
              headerClassName: 'text-right',
              columnClassName: 'text-right'
            },
            cell: numberValue
          },
          {
            header: 'Dif',
            accessorKey: 'diffCoin',
            meta: {
              headerClassName: 'text-right',
              columnClassName: 'text-right'
            },
            cell: coloresMoneyValue
          }
        ],
      },
      {
        header: 'Acciones',
        accessorKey: 'period',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        },
        cell: actionValue
      }
    ]
    , []
  )

  const filterData = (value) => {
    if (value && value.data) {
      return value.data.filter(item => item.diffBill !== 0 || item.diffTicketIn !== 0 || item.diffTicketOut !== 0 || item.diffHandPay !== 0)
    }
    return []
  }

  const handleShowTotalChange = (type) => {
    setShowTotals(type);
    localStorage.setItem('showTotals', type);
  }

  return (
    <PageContainerFull>
      <PageSection>
        <FlexLayout alignItems='flex-start'>
          <div style={{flex: 1}}>
            <Title text="Cuadre físico" type={'page'} />
          </div>
          <div style={{display: 'flex', flexDirection: 'row', gap: '4px', borderRadius: '20px', background: '#fff', padding: '8px'}}>
            <FlexLayout alignItems='center' gap='4px' onClick={() => handleShowTotalChange('total')} style={{padding: '4px 16px', background: showTotals === 'total' ? '#e5e5e5' : 'transparent', borderRadius: '12px', cursor: 'pointer', transition: '0.2s all ease-in-out'}}>
              <TbMoneybag size={14} />
              <span style={{fontSize: '12px'}}>Total</span>
            </FlexLayout>
            <FlexLayout alignItems='center' gap='4px' onClick={() => handleShowTotalChange('diferencia')} style={{padding: '4px 16px', background: showTotals === 'diferencia' ? '#e5e5e5' : 'transparent', borderRadius: '12px', cursor: 'pointer', transition: '0.2s all ease-in-out'}}>
              <LuCoins size={14} />
              <span style={{fontSize: '12px'}}>Diferencias</span>
            </FlexLayout>
          </div>
        </FlexLayout>
        <Slidebar visible={slideOpen} onHide={toggler}>
          <div style={{padding: '16px'}}>
            <FormGroup>
              <label>Fecha</label>
              <DatePickerCustom value={tempDate} onChange={(e) => setTempDate(e.target.value)} />
            </FormGroup>

            <Button text='Buscar' variant='primary' onClick={() => {
            setFechas({...fechas, period: tempDate});
            handleSearch();
          }} />
          </div>
        </Slidebar>
        <DateFilter2
          showWeek={false}
          showMonth={false}
          showYear={false}
          toggleSlidebar={toggler}
          fechas={fechas}
          setFechas={setFechas}
          usePeriod
        />
        <AsyncBlock error={error} loading={loading}>
          <CardOverviewContainer gridColumns='repeat(5, 1fr)'>
            <CardOverview
              title={'S/ ' + numberFormat(showTotals ==='total' ? data?.totalBill ?? 0 : data?.diffBill ?? 0)}
              subtitle={showTotals ==='total' ? 'Total Billetes' : 'Dif. Billetes'}
              icon={<HiOutlineBanknotes size={22}/>}
            />

            <CardOverview
              title={'S/ ' + numberFormat(showTotals ==='total' ? data?.totalTicketIn ?? 0 : data?.diffPTicketIn ?? 0)}
              subtitle={showTotals ==='total' ? 'Total ticket In' : 'Dif. ticket In'}
              icon={<LuTicket size={22}/>}
            />

            <CardOverview
              title={'S/ ' + numberFormat(showTotals ==='total' ? data?.totalTicketOut ?? 0 : data?.diffPTicketOut ?? 0)}
              subtitle={showTotals ==='total' ? 'Total ticket Out' : 'Dif. ticket Out'}
              icon={<LuTicket size={22}/>}
            />

            <CardOverview
              title={'S/ ' + numberFormat(showTotals ==='total' ? data?.handpayPhysical ?? 0 : data?.diffHandPay ?? 0)}
              subtitle={showTotals ==='total' ? 'Total Pagos manuales' : 'Dif. Pagos manuales'}
              icon={<LuCoins size={22}/>}
            />
            <CardOverview
              title={'S/ ' + numberFormat(0)}
              subtitle='Dif. monedas'
              icon={<LuCoins size={22}/>}
            />
          </CardOverviewContainer>
          {
            filterData(data).length > 0 && (getCasinoDay() !== fechas.period) &&
            <>
              <Title text={"Diferencias : " + filterData(data).length } type='form' />
              <ReactTable
                columns={columns}
                data={data && filterData(data && data)}
                noData='No existen resultados para el filtro seleccionado'
                pageSize={100}
                showColumnFilters={false}
              />
            </>
          }
          <br />
          <Title text="Todos las maquinas" type='form' />
          <ReactTable
            columns={columns}
            data={(data && data.data) || []}
            noData='No existen resultados para el filtro seleccionado'
            pageSize={150}
            customSearch
            filterDownload={() => download()}
          />
        </AsyncBlock>
      </PageSection>
    </PageContainerFull>
  )
}

export default AuditCuadreFisico
