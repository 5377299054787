import FormGroup from "../../../../components/forms/FormGroup";
import Input from "../../../../components/forms/FormEl";
import RequiredLabel from "../../../../components/util/RequiredLabel";
import PrinterSelectV2 from "../../../common/components/selects/PrinterSelectV2";
import React from "react";
import SwitchToggle from "../../../../components/layout/SwitchToggle";

const ActaImpresionInput = ({register, handleFieldBlur, setActaValidation, actaValidation, imprimir, changeImprimir}) =>{
  return (
    <>
      <FormGroup>
        <label>Nro. Acta:</label>
        <Input
          type="number"
          {...register("nroActa", {
            onBlur: () => handleFieldBlur('nroActa', setActaValidation)
          })}
          style={{borderColor: actaValidation && 'red'}}/>
        <RequiredLabel show={actaValidation} text={"Ingrese un numero de acta"}/>
      </FormGroup>
      <FormGroup>
        <SwitchToggle id={"imprimir"} name={"imprimir"} label={"Imprimir"}  defaultChecked={imprimir} onClick={() => changeImprimir()}/>
      </FormGroup>
      {imprimir &&
        <FormGroup>
          <label>Imprimir en:</label>
          <PrinterSelectV2 {...register("printerId")}/>
        </FormGroup>}
    </>
  )
}

export default ActaImpresionInput
