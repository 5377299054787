import React, {useCallback, useContext, useMemo} from 'react';
import ReactTable from "../../../../../components/util/ReactTable";
import {FlexLayout} from "../../../../../components/flex/Flex";
import {FaEdit, FaFilePdf, FaLockOpen, FaRegSun} from "react-icons/fa";
import {Apis} from "../../../../../util/Apis";
import {serializeDownload} from "../../../../../components/util/SerializeParams";
import Moment from "react-moment";
import {useHistory} from "react-router";
import {SiteContext} from "../../../../common/context/SiteContext";
import {Dropdown} from "react-bootstrap";
import useEditModal from "../../../../../modals/useEditModal";
import FondoEdit from "./FondoEdit";
import FondoNew from "./FondoNew";
import ClosedFondoEdit from "../ClosedFondoEdit";
import useModal from "../../../../../modals/useModal";
import ArqueosList from "./ArqueosList";
import {GrTransaction} from "react-icons/gr";
import {IoLockClosed} from "react-icons/io5";
import {FaListCheck} from "react-icons/fa6";
import {Button} from "@wargostec/react-components";
import GenericModal2 from "../../../../../modals/GenericModal2";
import ModalInfo from "../../../../../modals/ModalInfo";
import {MdLocalPrintshop} from "react-icons/md";
import ArqueoPrint from "./ArqueoPrint";
import axios from "axios";
import useDeleteModal from "../../../../../modals/useDeleteModal";
import DeleteGenericModal from "../../../../../modals/DeleteGenericModal";

const TableFondoReport = ({data, fechas, fetchData}) => {

  const {salaId} = useContext(SiteContext)
  const history = useHistory()

  const dateValue = useCallback(props => props.renderValue() !== null ?
    <Moment format={'DD/MM HH:mm'}>{props.renderValue()}</Moment> : '--', []);
  const defaultValue = useCallback(props => props.renderValue() !== null ? (props.renderValue()).toFixed(2) : '--', []);

  const navigateToReport = useCallback((row, reportType) => {
    const {fondoId, period} = row.original;
    history.push(`/caja/${reportType}?fondoId=${fondoId}&period=${period}`);
  }, [history]);

  const pdf = (id) => {
    window.location.href = Apis.CAJA + `/fondos/close/${id}/report.pdf`
  }

  const download = () => {
    const params = {...fechas, salaId};
    window.location.href = Apis.CAJA + `/fondos/report.xlsx?${serializeDownload(params)}`;
  }

  const canjeTicketRepor = useCallback((row) => navigateToReport(row, 'cticket-report'), [navigateToReport]);
  const ventaTicketReport = useCallback((row) => navigateToReport(row, 'cticket-venta-report'), [navigateToReport]);
  const billReport = useCallback((row) => navigateToReport(row, 'cbill-report'), [navigateToReport]);
  const handPayReport = useCallback((row) => navigateToReport(row, 'chandpay-report'), [navigateToReport]);
  const cardReport = useCallback((row) => navigateToReport(row, 'ccardpay-report'), [navigateToReport]);
  const gastoReport = useCallback((row) => navigateToReport(row, 'cother-report'), [navigateToReport]);

  const nameValue = useCallback(props => <>{
    props.renderValue() ?
      <span>{props.renderValue()}</span>
      :
      <span>---</span>
  }</>, []);

  const {
    wrapper: wrapper1,
    addAction,
    editAction
  } = useEditModal({
    editComponent: <FondoEdit/>,
    addComponent: <FondoNew/>,
    onRefresh: fetchData
  });

  const {
    wrapper: wrapper4,
    editAction: closedFondoEdit
  } = useEditModal({
    editComponent: <ClosedFondoEdit/>,
    onRefresh: fetchData
  });

  const {
    wrapper: wrapper5,
    addAction: printArqueo
  } = useEditModal({
    addComponent: <ArqueoPrint/>,
    onRefresh: fetchData
  });

  const movimientosFondo = (id, name) => {
    history.push('/caja/movimientos-fondo/' + id + `?name=${name}`)
  }

  const {wrapper: wrapper3, someAction: arqueos} = useModal({
    component: <ArqueosList/>
  });

  const reOpeningFondo = id => axios.post(Apis.CAJA + '/fondos/' + id + '/re-open/v2')

  const {
    wrapper: wrapper2,
    openDeleteModal: reOpenFondo,
  } = useDeleteModal({
    text: '¿Desea aperturar nuevamente este caja?',
    action: reOpeningFondo,
    onRefresh: fetchData
  })

  const actionValue = useCallback(props =>
      <FlexLayout justifyContent='center'>
        {
          props.row.original.status === 2 &&
          <Button variant={'light'} size='xs' icon={<FaFilePdf/>} onClick={() => pdf(props.row.original.fondoId)}/>
        }
        <Dropdown>
          <Dropdown.Toggle variant="light" id="dropdown-custom-1" size='sm'>
            <FaRegSun/>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {
              props.row.original.status === 1 &&
              <Dropdown.Item
                onClick={() => editAction(props.row.original.fondoId)}><FaEdit/> Editar</Dropdown.Item>
            }
            {
              props.row.original.type === 1 &&
              <Dropdown.Item onClick={() => closedFondoEdit(props.row.original.fondoId)}><IoLockClosed/> Cerrar
                fondo</Dropdown.Item>
            }
            <Dropdown.Item
              onClick={() => movimientosFondo(props.row.original.fondoId, props.row.original.fondoName)}><GrTransaction/> Movimientos</Dropdown.Item>
            {
              props.row.original.status === 2 &&
              <>
                <Dropdown.Item
                  onClick={() => arqueos({id: props.row.original.fondoId})}><FaListCheck/> Arqueos</Dropdown.Item>
                <Dropdown.Item
                  onClick={() => reOpenFondo(props.row.original.fondoId)}><FaLockOpen/> Reaperturar</Dropdown.Item>
              </>
            }
            {
              props.row.original.lastArqueoId  && props.row.original.type === 2 &&
              <Dropdown.Item onClick={() => printArqueo({
                id: props.row.original.lastArqueoId,
                fondoName: props.row.original.fondoName
              })}><MdLocalPrintshop/> Reimprimir
                voucher</Dropdown.Item>
            }
          </Dropdown.Menu>
        </Dropdown>
      </FlexLayout>
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , []);

  const moneyValue = useCallback(props => props.renderValue().toFixed(2), []);

  const columns = useMemo(
    () => [
      {
        header: 'ID',
        accessorKey: 'fondoId',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Fondo',
        accessorKey: 'fondoName',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        }
      },
      {
        header: 'Responsable',
        accessorKey: 'employeeName',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        },
        cell: nameValue
      },
      {
        header: 'Estado',
        accessorKey: 'statusName',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Inicio',
        accessorKey: 'createdAt',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: dateValue
      },
      {
        header: 'Cierre',
        accessorKey: 'closedAt',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: dateValue
      },
      {
        header: 'Venta tickets',
        accessorKey: 'ticketVenta',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
        cell: (props) => (
          <span
            onClick={() => ventaTicketReport(props.row)}
            style={{cursor: 'pointer'}}>
              {defaultValue(props)}
          </span>
        )
      },
      {
        header: 'Prebill',
        accessorKey: 'bill',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
        cell: (props) => (
          <span
            onClick={() => billReport(props.row)}
            style={{cursor: 'pointer'}}>
              {defaultValue(props)}
          </span>
        )
      },
      {
        header: 'Canje tickets',
        accessorKey: 'ticketRedeem',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
        cell: (props) => (
          <span
            onClick={() => canjeTicketRepor(props.row)}
            style={{cursor: 'pointer'}}>
              {defaultValue(props)}
          </span>
        )
      },
      {
        header: 'P.manual',
        accessorKey: 'handpay',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
        cell: (props) => (
          <span
            onClick={() => handPayReport(props.row)}
            style={{cursor: 'pointer'}}>
              {defaultValue(props)}
          </span>
        )
      },
      {
        header: 'Prod',
        accessorKey: 'prod',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
        cell: moneyValue
      },
      {
        header: 'Cashback',
        accessorKey: 'ticketVentaPromo',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
        cell: moneyValue
      },
      {
        header: 'Restricted',
        accessorKey: 'ticketVentaRestricted',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
        cell: moneyValue
      },
      {
        header: 'Tarjetas',
        accessorKey: 'cardpay',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
        cell: (props) => (
          <span
            onClick={() => cardReport(props.row)}
            style={{cursor: 'pointer'}}>
              {defaultValue(props)}
          </span>
        )
      },
      {
        header: 'Gastos',
        accessorKey: 'expenses',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
        cell: (props) => (
          <span
            onClick={() => gastoReport(props.row)}
            style={{cursor: 'pointer'}}>
              {defaultValue(props)}
          </span>
        )
      },
      // {
      //   header: 'Cerrado por',
      //   accessorKey: 'closedByName',
      //   meta: {
      //     headerClassName: 'text-left',
      //     columnClassName: 'text-left',
      //   },
      //   cell: nameValue
      // },
      {
        header: 'Dif',
        accessorKey: 'diff',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        }
      },
      {
        header: 'Acciones',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
        cell: actionValue
      }
    ]
    , []
  )

  return (
    <>
      <ReactTable
        columns={columns}
        data={data || []}
        customSearch
        noData='No existen resultados'
        pageSize={15}
        filterDownload={download}
        filterAdd={addAction}
      />
      <GenericModal2 {...wrapper1} />
      <ModalInfo {...wrapper3} />
      <GenericModal2 {...wrapper4} />
      <GenericModal2 {...wrapper5} />
      <DeleteGenericModal {...wrapper2} />
    </>
  );
};

export default TableFondoReport;
