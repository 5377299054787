import React, {useEffect} from 'react';
import {useForm} from "react-hook-form";
import SalaIdInput from "../../../../common/components/SalaIdInput";
import FormGroup from "../../../../../components/forms/FormGroup";
import Input from "../../../../../components/forms/FormEl";
import {AiOutlineBgColors} from "react-icons/ai";

const SegmentsModal = ({value, onSubmit, setAction}) => {
  const {register, handleSubmit, reset} = useForm();

  useEffect(() => {
    if (value) {
      reset(value)
    }
  }, [value])

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit))
  }, )

  return (
    <React.Fragment>
      <SalaIdInput {...register("salaId")}/>
      <FormGroup>
        <label>Nombre</label>
        <Input type='text' {...register("name")}/>
      </FormGroup>
      <FormGroup>
        <label>Puntos</label>
        <Input type='number' {...register("points")}/>
      </FormGroup>
      <FormGroup>
        <label>Max días sin actividad</label>
        <Input type='number' {...register("maxDaysInactivity")}/>
      </FormGroup>
      <FormGroup>
        <label>Valor de canje x S/ 1</label>
        <Input type='number' {...register("valueExchange")}/>
      </FormGroup>
      <FormGroup>
        <label>Color</label>
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <span style={{flex: 1, alignSelf: "center"}}>Seleccione un color para su nivel</span>
          <div style={{flex: 1, textAlign: "right"}}>
            <AiOutlineBgColors fontSize={'22px'}/>
            <input type='color' style={{padding: 0, width: 100,verticalAlign: "top"}} {...register("hexColor")}/>
          </div>
        </div>
      </FormGroup>
    </React.Fragment>
  )
};

export default SegmentsModal;
