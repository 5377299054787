import MainDashboard from "../../components/layout/MainDashboard";
import React, {useContext, useState} from "react";
import TgmRoutes from "./TgmRoutes";
import DropdownMenu from "../../components/layout/DropdownMenu";
import DropdownMenuItem from "../../components/layout/DropdownMenuItem";
import DropdownContainer from "../../components/layout/DropdownContainer";
import {SiteContext} from "../common/context/SiteContext";
import SecDropdownMenuItem from "../../components/layout/SecDropdownItem";
import * as FaIcons from "react-icons/fa";
import {LuBarChart4, LuBell, LuGamepad2, LuLayoutDashboard, LuSettings2, LuTicket} from "react-icons/lu";
import {HiOutlineSlotMachine} from "../../components/assets/icons/slot-machine";
import {BiTransfer} from "react-icons/bi";


const TgmMain = () => {

  const baseUrl = '/tgm'
  const {moreInfo} = useContext(SiteContext)

  const [isOpen, setIsOpen] = useState(false)
  const toggleMobile = () => {
    setIsOpen(!isOpen)
  }

  return (
    <MainDashboard
      brand={'MÁQUINAS'}
      username={moreInfo.user.name}
      isOpen={isOpen}
      toggleMobile={toggleMobile}
      items={
        <DropdownContainer>
          {/*<DropdownMenu title={'Global'}>*/}
          {/*  <DropdownMenuItem title={'Conexión'} link={baseUrl + '/conexion'}/>*/}
          {/*  <DropdownMenuItem title={'Producción'} link={baseUrl + '/produccion'}/>*/}
          {/*</DropdownMenu>*/}

          <DropdownMenu title={'Tiempo real'} icon={<FaIcons.FaClock />}>
            <DropdownMenuItem title={'Contadores'} link={baseUrl + '/meter-online'} onClick={toggleMobile} />
            <DropdownMenuItem title={'Billetero'} link={baseUrl + '/bill-online'} onClick={toggleMobile} />
            {/* <DropdownMenuItem title={'Conexión'} link={baseUrl + '/machine-link'} onClick={toggleMobile} /> */}
            {/* <DropdownMenuItem title={'Cuadre máquina'} link={baseUrl + '/cuadre-maquina'} onClick={toggleMobile} /> */}
          </DropdownMenu>

          <DropdownMenu title={'Máquinas'} icon={<HiOutlineSlotMachine size={16} />}>
            <SecDropdownMenuItem title={'Máquinas'} link={baseUrl + '/machines'} code={'qfi8rvGD'} onClick={toggleMobile} />
            <SecDropdownMenuItem title={'Dispositivos'} link={baseUrl + '/devices'} code={'qfi8rvGD'} onClick={toggleMobile} />
            <SecDropdownMenuItem title={'Historial'} link={baseUrl + '/devices-status'} code={'qfi8rvGD'} onClick={toggleMobile} />
          </DropdownMenu>

          <DropdownMenu title={'Reportes'} icon={<FaIcons.FaChartLine />}>
            <DropdownMenuItem title={'Historial de contadores'} link={baseUrl + '/meter-snap'} onClick={toggleMobile} />
            {/*<DropdownMenuItem title={'Toma de contadores'} link={baseUrl + '/meter-build'} onClick={toggleMobile} />*/}
            <DropdownMenuItem title={'Contadores por hora'} link={baseUrl + '/accountants'} onClick={toggleMobile} />
            <DropdownMenuItem title={'Eventos'} link={baseUrl + '/machine-events'} onClick={toggleMobile} />
            <DropdownMenuItem title={'Billetero'} link={baseUrl + '/billetera'} onClick={toggleMobile} />
            <DropdownMenuItem title={'Pagos manuales'} link={baseUrl + '/handpay-events'} onClick={toggleMobile} />
            <DropdownMenuItem title={'Cereos'} link={baseUrl + '/cereos'} onClick={toggleMobile} />
            <DropdownMenuItem title={'Contadores x juego'} link={baseUrl + '/meter-per-game'} onClick={toggleMobile} />
          </DropdownMenu>

          {/*
          <DropdownMenu title={'Multijuegos'} icon={<LuGamepad2 size={16} />}>
          </DropdownMenu>
          */}

          <DropdownMenu title={'Análisis'} icon={<LuBarChart4 size={16} />} >
            {/*<DropdownMenuItem title={'Avanzados'} link={baseUrl + '/avanzados'}/>*/}
            <DropdownMenuItem title={'Producción'} link={baseUrl + '/produccion-total'} onClick={toggleMobile} />
            <DropdownMenuItem title={'Producción por dia'} link={baseUrl + '/produccion-diaria'} onClick={toggleMobile} />
            <DropdownMenuItem title={'Producción por mes'} link={baseUrl + '/produccion-por-mes'} onClick={toggleMobile} />
            <DropdownMenuItem title={'Producción por máquina diaria'} link={baseUrl + '/produccion-por-maquina'} onClick={toggleMobile} />
            <DropdownMenuItem title={'Producción por modelo'} link={baseUrl + '/produccion-por-modelo'} onClick={toggleMobile} />
            <DropdownMenuItem title={'Producción por máquina'} link={baseUrl + '/prod-machine'} onClick={toggleMobile} />
            <DropdownMenuItem title={'Producción por juego'} link={baseUrl + '/prod-per-game'} onClick={toggleMobile} />
            {/*<DropdownMenuItem title={'Promedios'} link={baseUrl + '/promedios'}/>*/}
          </DropdownMenu>

          <DropdownMenu title={'Tickets'} icon={<LuTicket size={16} />}>
            <DropdownMenuItem title={'Tickets'} link={baseUrl + '/tickets'} onClick={toggleMobile} />
            <DropdownMenuItem title={'Tickets emitidos'} link={baseUrl + '/tickets-emitted'} onClick={toggleMobile} />
            <DropdownMenuItem title={'Tickets canjeados'} link={baseUrl + '/tickets-redeemed'} onClick={toggleMobile} />
          </DropdownMenu>

          <DropdownMenu title={'Layout'} icon={<LuLayoutDashboard size={16} />} >
            <DropdownMenuItem title={'Configuración'} link={baseUrl + '/layout-config'} onClick={toggleMobile} />
            <DropdownMenuItem title={'Zonas calientes'} link={baseUrl + '/layout-heatmap'} onClick={toggleMobile} />
          </DropdownMenu>

          <DropdownMenu title={'Alertas'} icon={<LuBell size={16} />}>
            <DropdownMenuItem title={'Alertas billetero'} link={baseUrl + '/alerts'} onClick={toggleMobile} />
          </DropdownMenu>

          <DropdownMenu title={'Ludopatía'} icon={<FaIcons.FaUsersSlash />}>
            <DropdownMenuItem title={'Buscador'} link={baseUrl + '/ludopatia/search'} onClick={toggleMobile} />
            {/*<DropdownMenuItem title={'Lista'} link={baseUrl + '/ludopatia-list'}/>*/}
            <DropdownMenuItem title={'Listas'} link={baseUrl + '/ludopatia/gestion-listas'} onClick={toggleMobile} />
          </DropdownMenu>

          <DropdownMenu title={'Asociados'} icon={<FaIcons.FaPeopleArrows />}>
            <DropdownMenuItem title={'Doc. Atribución'} link={baseUrl + '/documentos-atribución'} onClick={toggleMobile} />
            <DropdownMenuItem title={'Lista propietarios'} link={baseUrl + '/lista-propietarios'} onClick={toggleMobile} />
            {/*<DropdownMenuItem title={'Periodos Doc. Atribución'} link={baseUrl + '/periodos-doc-atribucion'} onClick={toggleMobile} />*/}
            <DropdownMenuItem title={'Relación asociados'} link={baseUrl + '/relacion-asociados'} onClick={toggleMobile} />
          </DropdownMenu>

          <DropdownMenu title={'Transferencias'} icon={<BiTransfer size={16} />}>
            <DropdownMenuItem title={'Transferencias'} link={baseUrl + '/aft-transfers'} onClick={toggleMobile} />
          </DropdownMenu>

          <DropdownMenu title={'Configuración'} icon={<LuSettings2 size={16} onClick={toggleMobile}/>}>
            <DropdownMenuItem title={'Modelos y fabricantes'} link={baseUrl + '/model-manufacturer'} onClick={toggleMobile} />
            <DropdownMenuItem title={'Grupos de máquinas'} link={baseUrl + '/machine-set'} />
            <DropdownMenuItem title={'Zonas'} link={baseUrl + '/machine-zones'} onClick={toggleMobile} />
            <DropdownMenuItem title={'Almacenes'} link={baseUrl + '/machine-warehouse'} onClick={toggleMobile} />
          </DropdownMenu>

        </DropdownContainer>

      }
    >
      <TgmRoutes />
    </MainDashboard>
  )
}

export default TgmMain;
