import React, {useContext, useEffect, useState} from "react";
import PageContainer from "../../../../components/layout/PageContainer";
import PageSection from "../../../../components/layout/PageSection";
import {Button, Title} from "@wargostec/react-components";
import {LoadingTableV2} from "../../../../components/forms/Tables";
import Table from "react-bootstrap/Table";
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import {SiteContext} from "../../../common/context/SiteContext";
import {ButtonBox} from "../../../../components/forms/Button";
import moment from "moment/moment";
import {successToast} from "../../../../components/layout/ToastStyles";
import MoneyColoredRound from "../../../../components/util/MoneyColored";
import {NumberRound} from "../../../../components/util/NumberUtil";
import Card from "../../../../components/forms/Card";
import CiFormatter from "../../../../components/util/CiFormatter";
import {useHistory} from "react-router";
import {PageBackLabel} from "../../../../components/layout/PageBackLabel";
import {Calendar, momentLocalizer} from "react-big-calendar";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Moment from "react-moment";
import {getDateFilter} from "../../../../components/util/CasinoDates";
import useLoadingToastr from "../../../../hooks/useLoadingToastr";
import tgmApi from "../../../../apis/tgmApi";
import ButtonReload from "../../../../components/forms/ButtonReload";
import DateFilter from "../../../../components/datefilter/DateFilter";
import Container from "react-bootstrap/Container";


const ProduccionDiaria = () => {

  const baseUrl = '/tgm'
  const history = useHistory()
  const date = getDateFilter('month')

  const querystring = window.location.search
  const query = new URLSearchParams(querystring)
  let iniQueryParams = query.get('ini')
  let finQueryParams = query.get('fin')

  if (iniQueryParams === null) {
    iniQueryParams = date.ini
  }

  if (finQueryParams === null) {
    finQueryParams = date.fin
  }

  const defaultParams = {ini: iniQueryParams, fin: finQueryParams}
  const [params, setParams] = useState(defaultParams)

  const fechasDefault = {ini: iniQueryParams, fin: finQueryParams}
  const [fechas, setFechas] = useState(fechasDefault)
  const [mode, setMode] = useState('Tabla')
  const localize = momentLocalizer(moment);

  const {salaId} = useContext(SiteContext)
  const {loading, data, error, fetchData} = useFetch2(Apis.TGM + '/deltas/pordia', [], params);

  const {data: dataSala, loading: loadingDataSala} = useFetch2(Apis.SALAS + '/salas-config/' + salaId, [])

  const routerProduction = (ini) => {
    const date = moment(ini).format('YYYY-MM-DD')
    history.push('/tgm/produccion-total?ini=' + date + '&fin=' + date)
  }

  const titleCalendar = (value) => {
    let text = ''
    if (value !== 0) {
      if (value >= 1000) {
        text = 'S/ ' + Math.trunc(value / 1000) + 'K';
      } else {
        text = 'S/ ' + (value / 1000).toFixed(1) + 'K';
      }
    }
    return text
  }

  const generateData = (values) => {
    const calendar = []
    values.map(item => {
      calendar.push({
        start: item.fecha,
        end: item.fecha,
        title: titleCalendar(item.prod),
        color: colorStyle(item.prod)
      })
    })
    return calendar
  }

  const colorStyle = (number) => {
    let color = "white"
    if (number > 0) {
      color = "#1b8cea"
    } else if (number < 0) {
      color = "red"
    }
    return color
  }

  const recalc = async (fecha) => {
    const params = {fecha}
    await tgmApi.deltasRecalc(salaId, params)
    successToast("Produccion recalculada");
    fetchData();
  }

  const recalcV2 = async (fecha) => {
    const params = {fecha}
    await tgmApi.deltasRecalcV2(salaId, params)
    successToast("Produccion recalculada");
    fetchData();
  }

  const [getRecalc, loadingRecalc] = useLoadingToastr(recalc, "Hubo un error");
  const [getRecalcV2, loadingRecalcV2] = useLoadingToastr(recalcV2, "Hubo un error");

  const changeRoute = () => {
    history.push('/tgm/produccion-diaria?ini=' + fechas.ini + '&fin=' + fechas.fin)
  }

  useEffect(() => {
    if (params)
      fetchData()
    changeRoute()
  }, [params])

  useEffect(() => {
    setParams({...params, ...fechas})
  }, [fechas])

  return (
    <Container fluid>
        <PageSection>
          <PageBackLabel text='Anual' onClick={() => history.push('/tgm/produccion-por-mes')}/>
          <Title text="Produccion diaria contadores" type={'page'}/>

          <ButtonBox>
            <ButtonReload onClick={fetchData}/>
            <Button variant={'primary'} onClick={() => mode === 'Tabla' ? setMode('Calendario') : setMode('Tabla')}
                    size={"md"} text={mode}/>
          </ButtonBox>

          <Card padding style={{background: '#0A0A0A'}}>
            <DateFilter fechas={fechas} setFechas={setFechas} modeDate={'month'}/>
          </Card>

          <LoadingTableV2 loading={loading && loadingDataSala} error={error} data={data}>
            <Card>
              {
                mode === 'Tabla' ?
                  <Table striped hover>
                    <thead>
                    <tr>
                      <th></th>
                      <th className="text-center">Fecha</th>
                      <th className="text-right">Cereos</th>
                      <th className="text-right">Apostado</th>
                      <th className="text-right">Ganado</th>
                      <th className="text-right">Jackpot</th>
                      <th className="text-right">Produccion</th>
                      <th className="text-right">Ap.prom.</th>
                      <th className="text-right">Hold</th>
                      <th className="text-right">Bill</th>
                      <th className="text-right">TicketIn</th>
                      <th className="text-right">TicketOut</th>
                      <th className="text-right">CC</th>
                      <th className="text-right">Prod.Fisico</th>
                      <th className="text-right">Dif</th>
                      <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {data && dataSala && data.map((item) =>
                      <tr key={item.id}>
                        <td className="text-center"><Moment format={'ddd'}>{item.fecha}</Moment></td>
                        <td className="text-center">{item.fecha}</td>
                        <td className="text-right">{item.breakpoints?.toFixed(0)}</td>
                        <td className="text-right">
                          <CiFormatter>{item.ci}</CiFormatter>
                        </td>
                        <td className="text-right">
                          <CiFormatter>{item.co}</CiFormatter>
                        </td>
                        <td className="text-right">
                          <CiFormatter>{item.jp}</CiFormatter>
                        </td>
                        <td className="text-right">
                          <MoneyColoredRound currencySymbol={dataSala.currencySymbol}>{item.prod}</MoneyColoredRound>
                        </td>
                        <td className="text-right"><NumberRound>{item.apProm}</NumberRound></td>
                        <td className="text-right">{item.hold !== null ? item.hold?.toFixed(1) + '%' : ' '}</td>
                        <td className="text-right">
                          <CiFormatter>{item.bi}</CiFormatter>
                        </td>
                        <td className="text-right">
                          <CiFormatter>{item.ticketIn}</CiFormatter>
                        </td>
                        <td className="text-right">
                          <CiFormatter>{item.ticketOut}</CiFormatter>
                        </td>
                        <td className="text-right">
                          <CiFormatter>{item.cc}</CiFormatter>
                        </td>
                        <td className="text-right">
                          <MoneyColoredRound currencySymbol={dataSala.currencySymbol}>{item.prodPhysical}</MoneyColoredRound>
                        </td>
                        <td className="text-right">
                          <MoneyColoredRound currencySymbol={dataSala.currencySymbol}>{item.diff}</MoneyColoredRound>
                        </td>
                        <td className="text-right">
                          <Button size={'sm'} text="Detalle" variant="light"
                                  onClick={() => history.push(baseUrl + `/produccion-total?ini=${item.fecha}&fin=${item.fecha}`)}/>
                          <Button size={'sm'} variant="light" onClick={() => getRecalc(item.fecha)}
                                  disabled={loadingRecalc} text="Recalcular (hora)"/>
                          <Button size={'sm'} variant="light" onClick={() => getRecalcV2(item.fecha)}
                                  disabled={loadingRecalcV2} text="Recalcular (cierre)"/>
                        </td>
                      </tr>
                    )}
                    </tbody>
                  </Table>
                  :
                  <Calendar
                    localizer={localize}
                    selectable
                    events={generateData(data)}
                    startAccessor="start"
                    endAccessor="end"
                    style={{height: 500}}
                    onSelectEvent={event => routerProduction(event.start)}
                    views={["month"]}
                    messages={{
                      next: ">",
                      previous: "<",
                      today: "Hoy",
                      month: "Mes",
                      week: "Semana",
                      day: "Día",
                    }}
                    eventPropGetter={(event, start, end, isSelected) => ({
                      event,
                      start,
                      end,
                      isSelected,
                      style: {backgroundColor: event.color}
                    })}
                  />
              }
            </Card>
          </LoadingTableV2>
        </PageSection>
    </Container>
  )
}

export default ProduccionDiaria;
