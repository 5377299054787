import React, {useContext, useEffect} from 'react'
import FormGroup from "../../../../../components/forms/FormGroup";
import Input from "../../../../../components/forms/FormEl";
import SalaIdInput from "../../../../common/components/SalaIdInput";
import {useForm} from "react-hook-form";
import {SiteContext} from "../../../../common/context/SiteContext";
import axios from "axios";
import {Apis} from "../../../../../util/Apis";

const GroupModalForm = ({setAction, setTitle}) => {

  const {salaId} = useContext(SiteContext)

  const save = (params) => {
    params.salaId = salaId
    return axios.post(Apis.TGM + '/machine-groups', params)
  }

  const {register, handleSubmit} = useForm();

  useEffect(() => {
    setTitle("Nuevo Grupo")
  }, [])

  useEffect(() => {
    setAction(() => handleSubmit(save))
  }, [])

  return (
    <React.Fragment>
      <SalaIdInput {...register("salaId")}/>
      <FormGroup>
        <label>Nombre</label>
        <Input type='text' {...register("name")}/>
      </FormGroup>
    </React.Fragment>
  )

}
export default GroupModalForm
