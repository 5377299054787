import React, {useEffect} from 'react';
import FormGroup from "../../../../components/forms/FormGroup";
import TableAmountsMesa from "../../../../components/util/TableAmountsMesa";
import useFilasMesa from "../../../../hooks/useFilasMesa";
import {useForm} from "react-hook-form";
import FondoActiveSelect from '../../../common/components/selects/FondoActiveSelect';

const MovimientosFondoForm = ({value, onSubmit, setAction}) => {

  const {rows, setRows, total} = useFilasMesa("CAJA")
  const defaultParams = {rows: rows, total: total}
  const {register, handleSubmit, reset, setValue} = useForm({defaultValues: defaultParams});

  useEffect(() => {
    if (value) {
      reset(value)
    }
  }, [value])

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit))
  }, [])

  useEffect(() => {
    setValue("amount", total.toString())
    setValue("detailDen", rows)
  }, [total])

  return (
    <div>
      <FormGroup>
        <label>Fondo de origen</label>
        <FondoActiveSelect {...register("sourceFondoId")} />
      </FormGroup>
      <FormGroup>
        <label>Fondo de destino</label>
        <FondoActiveSelect {...register("targetFondoId")} />
      </FormGroup>
      <FormGroup>
        <TableAmountsMesa rows={rows} setRows={setRows} total={total}/>
      </FormGroup>
    </div>
  );
};

export default MovimientosFondoForm;
