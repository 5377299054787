import React, {useContext} from 'react';
import {useHistory, useParams} from "react-router";
import {Title} from "@wargostec/react-components";
import {SiteContext} from "../../../common/context/SiteContext";
import {Apis} from "../../../../util/Apis";
import {errorToast, successToast} from "../../../../components/layout/ToastStyles";
import PageContainer from "../../../../components/layout/PageContainer";
import PageSection from "../../../../components/layout/PageSection";
import {PageBackLabel} from "../../../../components/layout/PageBackLabel";
import Loader1 from "../../../common/components/Loader1";
import PuntosPromoForm from "./PuntosPromoForm";
import {usePut} from '../../../../hooks/usePut';
import useGet from '../../../../hooks/useGet';

const PuntosPromoEdit = () => {

  const {id} = useParams()
  const {salaId} = useContext(SiteContext)
  const history = useHistory()

  const {data, loading} = useGet({
    url: Apis.PC + '/point-promo/' + id
  })

  const {mutate} = usePut({
    url: Apis.PC + '/point-promo/' + id,
    onSuccess: () => {
      history.push('/crm/puntos-promociones')
      successToast("Multiplicador actualizado")
    },
    onError: (err) => errorToast(err.response.data.message ? err.response.data.message : "Ocurrió un problema. Por favor intenta nuevamente", 2000)
  })

  const onUpdate = (params) => {
    params.salaId = salaId
    params._id = id
    mutate(params)
  }

  return (
    <PageContainer>
      <PageSection style={{marginBottom: "20px"}}>
        <PageBackLabel text='Multiplicadores' onClick={() => history.push('/crm/puntos-promociones')}/>
        <Title text="Editar multiplicador" type={'page'}/>
      </PageSection>
      <PageSection>
        {
          loading ?
            <Loader1/>
            :
            <>
              {
                data !== undefined &&
                <PuntosPromoForm value={data} onSubmit={onUpdate} edit={1}/>
              }
            </>
        }
      </PageSection>
    </PageContainer>
  )
}

export default PuntosPromoEdit;
