import React, {useContext, useEffect, useState} from "react";
import PageSection from "../../../../components/layout/PageSection";
import {Title, Button} from "@wargostec/react-components";
import {ButtonBox} from "../../../../components/forms/Button";
import Table from "react-bootstrap/Table";
import FormGroup from "../../../../components/forms/FormGroup";
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import {SiteContext} from "../../../common/context/SiteContext";
import {useForm} from "react-hook-form";
import SalaIdInput from "../../../common/components/SalaIdInput";
import {DatePickerCustom} from "../../../../components/forms/FormEl";
import Slidebar from "../../../common/layout/Slidebar";
import NoDataTable from "../../../common/components/NoDataTable";
import Loader1 from "../../../common/components/Loader1";
import Card from "../../../../components/forms/Card";
import DateFilter from "../../../../components/datefilter/DateFilter";
import moment from "moment/moment";
import {Link} from "react-router-dom";
import Container from 'react-bootstrap/Container'
import {BsFilter} from "react-icons/bs";

const Cereos = () => {

  const baseUrl = '/tgm'

  const initialDate = moment(new Date()).format('YYYY-MM-DD')

  const {salaId} = useContext(SiteContext)
  const [slideOpen, setSlideOpen] = useState(false)

  let defaultParams = {salaId: salaId}

  const fechasDefault = {ini: initialDate, fin: initialDate}

  const [params, setParams] = useState( {ini: initialDate, fin: initialDate, salaId: salaId})
  const [fechas, setFechas] = useState(fechasDefault)

  const {
    loading,
    data,
    fetchData
  } = useFetch2(Apis.TGM + '/deltas/cereos', [], params, false)

  const {
    fetchData : fetchData2
  } = useFetch2(Apis.TGM + '/meter-snap/analize', [], params, false)

  const {
    register,
    handleSubmit,
  } = useForm({defaultValues: defaultParams});

  const getData = (params) => {
    setParams(params)
  }

  useEffect(() => {
    setParams({...params, ...fechas})
  }, [fechas])

  useEffect(() => {
    getReport()
  }, [params])

  const getReport = () => {
    if (params && params.ini && params.fin) {
      fetchData()
    }
  }

  const analyze = () =>{
    fetchData2()
  }

  return (
    <Container>
      <Slidebar visible={slideOpen} onHide={() => setSlideOpen(false)}>
        <div style={{padding: '16px'}}>
          <SalaIdInput  {...register("salaId")}/>

          <FormGroup>
            <label>Fecha de Inicio</label>
            <DatePickerCustom {...register("ini")}/>
          </FormGroup>

          <FormGroup>
            <label>Fecha de Fin</label>
            <DatePickerCustom {...register("fin")}/>
          </FormGroup>
          <Button variant={'primary'} text='Buscar' onClick={handleSubmit(getData)}/>
        </div>
      </Slidebar>

      <PageSection>
          <Title text="Cereos" type={'page'}/>
        <ButtonBox>
          <Button variant="primary" onClick={() => setSlideOpen(!slideOpen)}
                  icon={<BsFilter />} text="Filtrar"/>
          <Button variant="primary" text="Analizar" onClick={analyze}/>
        </ButtonBox>

        <Card padding style={{background: '#0A0A0A'}}>
          <DateFilter fechas={fechas} setFechas={setFechas}/>
        </Card>

        <Card padding>
          <Table striped hover>
            <thead>
            <tr>
              <th>Fecha</th>
              <th>Máquina</th>
              <th>Juego</th>
              <th  className="text-right">Produccion</th>
            </tr>
            </thead>
            <tbody>
            {
              loading ? <Loader1/> :
                (
                  data && data.length === 0 ?
                    <NoDataTable text={"No hay información que mostrar"}/>
                    :
                    data && data.map(item =>
                      <tr key={item.id}>
                        <td>{item.fecha}</td>
                        <td>{item.machine}</td>
                        <td>{item.gameName}</td>
                        <td className="text-right">{(item.prod * item.den).toFixed((2))}</td>
                        <td>
                          <Button size={'sm'} text={"Ver"} onClick={<Link
                            to={baseUrl + `/meter-snap?ini=${item.fecha}&fin=${item.fecha}&confId=${item.confId}`}/>}
                                  variant={"light"}/>
                        </td>
                      </tr>
                    )
                )
            }
            </tbody>
          </Table>
        </Card>
      </PageSection>
    </Container>
  )
}

export default Cereos;
