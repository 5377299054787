import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Apis} from '../../../../../util/Apis';
import {useForm} from 'react-hook-form';
import axios from 'axios';
import PageContainer from '../../../../../components/layout/PageContainer';
import Slidebar from '../../../../common/layout/Slidebar';
import SalaIdInput from '../../../../common/components/SalaIdInput';
import FormGroup from '../../../../../components/forms/FormGroup';
import {DatePickerCustom, SelectV2} from '../../../../../components/forms/FormEl';
import Button from '../../../../../components/forms/Button';
import PageSection from '../../../../../components/layout/PageSection';
import {Title} from "@wargostec/react-components";
import DeleteGenericModal from '../../../../../modals/DeleteGenericModal';
import FondoNew from './FondoNew';
import Moment from 'react-moment';
import useEditModal from '../../../../../modals/useEditModal';
import useDeleteModal from '../../../../../modals/useDeleteModal';
import GenericModal2 from '../../../../../modals/GenericModal2';
import FondoEdit from './FondoEdit';
import useFetch2 from '../../../../../hooks/useFetch2';
import useModal from '../../../../../modals/useModal';
import ArqueosList from './ArqueosList';
import ModalInfo from '../../../../../modals/ModalInfo';
import {errorToast, successToast} from '../../../../../components/layout/ToastStyles';
import {useHistory} from "react-router";
import {FlexLayout} from "../../../../../components/flex/Flex";
import ReactTable from "../../../../../components/util/ReactTable";
import AsyncBlock from "../../../../../components/forms/AsyncBlock";
import ClosedFondoEdit from "../ClosedFondoEdit";
import {getCasinoDay} from "../../../../../components/util/CasinoDates";
import {numberFormat} from '../../../../../components/util/NumberUtil';

const FondoList = () => {

  const queryString = window.location.search
  const query = new URLSearchParams(queryString)
  let fechaQueryParams = query.get('fecha')
  let activeQueryParams = query.get('active')
  if (!activeQueryParams) activeQueryParams = true
  if (fechaQueryParams === getCasinoDay()) fechaQueryParams = getCasinoDay()

  const history = useHistory()
  const [slideOpen, setSlideOpen] = useState(false);
  const defaultParams = {active: activeQueryParams, fecha: fechaQueryParams}
  const [params, setParams] = useState(defaultParams);

  const {register, handleSubmit} = useForm({
    defaultValues: defaultParams
  });

  const {loading, data, fetchData, error} = useFetch2(
    Apis.CAJA + '/fondos',
    [],
    params
  );

  const getData = (params) => {
    history.push(`/caja/fondo-list?fecha=${params.fecha}&active=${params.active}`)
    if (params.fecha === getCasinoDay() && params.active === true) {
      params.fecha = null;
    }
    setParams(params);
  }

  const deleteElement = (id) =>
    axios
      .delete(Apis.CAJA + '/fondos/' + id)
      .then(() => successToast('Se anuló el fondo correctamente'))
      .catch((res) => errorToast(res.response.data.message, 3000));

  const {
    wrapper: wrapper1,
    addAction,
    editAction
  } = useEditModal({
    editComponent: <FondoEdit/>,
    addComponent: <FondoNew/>,
    onRefresh: fetchData
  });

  const {wrapper: wrapper2, openDeleteModal} = useDeleteModal({
    text: '¿Desea eliminar este fondo?',
    onRefresh: fetchData,
    action: deleteElement
  });

  const {wrapper: wrapper3, someAction: arqueos} = useModal({
    component: <ArqueosList/>
  });

  const {
    wrapper: wrapper4,
    editAction: closedFondoEdit
  } = useEditModal({
    editComponent: <ClosedFondoEdit/>,
    onRefresh: fetchData
  });

  //hook para 'escuchar' cuando debemos refrescar la vista
  useEffect(() => {
    fetchData(); //TODO (Renzo B.): encontrar la forma de quitar este useEffect
  }, [params]);

  const movimientosFondo = (id, name) => {
    history.push('/caja/movimientos-fondo/' + id + `?name=${name}`)
  }

  const actionsValue = useCallback(props => <>{
    <FlexLayout justifyContent='left'>
      {
        props.row.original.status === 1 &&
        <Button variant={'light'} size='xs'
                onClick={() => editAction(props.row.original.id)}>
          Editar
        </Button>
      }
      {
        props.row.original.fundType?.type === 1 &&
        <Button variant={'warning'} size='xs'
                onClick={() => closedFondoEdit(props.row.original.id)}>
          Cerrar fondo
        </Button>
      }
      <Button variant={'light'} size='xs'
              onClick={() => movimientosFondo(props.row.original.id, props.row.original.name)}>
        Movimientos
      </Button>
      {
        props.row.original.status === 2 &&
        <>
          <Button variant={'light'} size='xs'
                  onClick={() => arqueos({id: props.row.original.id})}>
            Arqueos
          </Button>
        </>
      }
    </FlexLayout>
  }</>, []);

  const dateTimeValue = useCallback(props =>
      props.renderValue() ?
        <Moment format={'YYYY-MM-DD HH:mm a'}>{props.renderValue()}</Moment>
        :
        '---'
    , []);

  const dateValue = useCallback(props => props.renderValue() ?
    <Moment format={'YYYY-MM-DD'}>{props.renderValue()}</Moment> : '--', []);

  const moneyValue = useCallback(props => props.renderValue() ? numberFormat(props.renderValue()) :'--', []);

  const columns = useMemo(
    () => [
      {
        header: 'Id',
        accessorKey: 'id',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Nombre',
        accessorKey: 'name',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        }
      },
      {
        header: 'Encargado',
        accessorKey: 'employeeName',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        }
      },
      {
        header: 'Periodo',
        accessorKey: 'period',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        },
        cell: dateValue
      },
      {
        header: 'Monto Inicial',
        accessorKey: 'iniAmount',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
        cell: moneyValue
      },
      {
        header: 'Creación',
        accessorKey: 'createdAt',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        },
        cell: dateTimeValue
      },
      {
        header: 'Fecha Cierre',
        accessorKey: 'closedAt',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: dateTimeValue
      },
      // {
      //   header: 'Fondo actual',
      //   accessorKey: 'currentAmount',
      //   meta: {
      //     headerClassName: 'text-center',
      //     columnClassName: 'text-center',
      //   },
      // },
      {
        header: 'Acciones',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        },
        cell: actionsValue
      }
    ]
    , []
  )

  return (
    <PageContainer>
      <Slidebar visible={slideOpen} onHide={() => setSlideOpen(false)}>
        <div style={{padding: '16px'}}>
          <SalaIdInput {...register('salaId')} />
          <FormGroup>

            <label>Periodo</label>
            <DatePickerCustom {...register('fecha')} />
          </FormGroup>
          <FormGroup>
            <label style={{marginRight: '20px'}}>Estado</label>
            <SelectV2 options={
              [{name: 'Abierto', id: true}, {name: 'Cerrado', id: false}]
            } {...register('active')}/>
          </FormGroup>

          <Button
            variant={'primary'}
            title='Buscar'
            onClick={handleSubmit(getData)}
          />
        </div>
      </Slidebar>

      <PageSection>
        <Title text="Fondos" type={'page'}/>

        <AsyncBlock loading={loading} error={error}>
          <ReactTable
            columns={columns}
            data={data || []}
            filterAdd={() => addAction()}
            filterOnclick={() => setSlideOpen(!slideOpen)}
            customSearch
            noData='No existen resultados'
            pageSize={15}
          />
        </AsyncBlock>
      </PageSection>

      <GenericModal2 {...wrapper1} />
      <DeleteGenericModal {...wrapper2} />
      <ModalInfo {...wrapper3} />
      <GenericModal2 {...wrapper4} />
    </PageContainer>
  );
};

export default FondoList;
