import React, {useEffect, useReducer} from "react";
import authReducer from "../reducer/authentication.reducer";
import AuthGlobal from "./AuthContext";
import {setCurrentUser} from "../actions/authentication.action";
import {jwtDecode} from "jwt-decode"

//https://medium.com/@nicomorales297/login-con-mern-stack-jwt-hooks-y-context-api-frontend-1f86fb7e738a
const Auth = props => {

  const initialState = {
    isAuthenticated: null,
    user: {}
  };

  const [stateUser, dispatch] = useReducer(authReducer, initialState)

  //TODO, podria llamarse en PrivateRoute
  //mejor llamarlo desde aqui para que private route solo chequea un sitio centralizado en memoria
  //esto debe llamarse siempre al cargar la pagina
  //no deberia haber un private route como padre de este componente xq no tendra contexto y al hacer refresh no funcionara
  useEffect(() => {
    if (localStorage.auth) {
      const token = localStorage.auth ? localStorage.auth : "";
      dispatch(setCurrentUser(jwtDecode(token)));
    }
  }, []);

  return (
    <AuthGlobal.Provider
      value={{
        stateUser,
        dispatch
      }}>
      {props.children}
    </AuthGlobal.Provider>
  );

};

export default Auth;
