import React, {useCallback, useContext, useMemo} from 'react';
import {SiteContext} from "../../../common/context/SiteContext";
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import PageSection from "../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import {ButtonBox} from "../../../../components/forms/Button";
import ButtonReload from "../../../../components/forms/ButtonReload";
import PageContainerFull from "../../../../components/layout/PageContainerFull";
import ReactTable from "../../../../components/util/ReactTable";
import AsyncBlock from "../../../../components/forms/AsyncBlock";
import DealerReport from "./DealerReport";
import {Tab, Tabs} from '../../../../components/tabs/Tabs';

const MesasReport = () => {

  const {salaId} = useContext(SiteContext)

  const {data, fetchData, loading, error} = useFetch2(Apis.TABLES + '/tables/report-online?salaId=' + salaId, []);

  const mesaValue = useCallback(props => <>{props.renderValue()} - {props.row.original.tableCode}</>, []);

  const columns = useMemo(
    () => [
      {
        header: 'ID',
        accessorKey: 'tableId',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Mesa',
        accessorKey: 'tableName',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: mesaValue
      },
      {
        header: 'Coin In',
        accessorKey: 'totalCoinIn',
      },
      {
        header: 'Coin Out',
        accessorKey: 'totalCoinOut',
      },
      {
        header: 'Producción',
        accessorKey: 'totalProd',
      },

    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , []
  )

  return (
    <PageContainerFull>

      <PageSection>
          <Title text="Reporte de mesas en linea" type={'page'}/>
        <ButtonBox>
          <ButtonReload onClick={fetchData}/>
        </ButtonBox>
      </PageSection>

      <PageSection>
        <Tabs>
          <Tab title="Online">
            <AsyncBlock loading={loading} error={error}>
              <ReactTable
                columns={columns}
                data={data || []}
                noData='No existen resultados para el filtro seleccionado'
                sortArray={[{id: 'tableId', asc: false}]}
                pageSize={20}
              />
            </AsyncBlock>
          </Tab>
          <Tab title="Reporte Dealer">
            <DealerReport/>
          </Tab>

        </Tabs>
      </PageSection>
    </PageContainerFull>
  );
};

export default MesasReport;
