import React, {useContext} from 'react'
import {Select} from "../../../../components/forms/FormEl";
import {Apis} from "../../../../util/Apis";
import {SiteContext} from "../../context/SiteContext";
import useFetch from "../../../../hooks/useFetch";

const PrinterSelectV2 = React.forwardRef((props, ref) => {

  const {salaId} = useContext(SiteContext)
  const {data, loading} = useFetch(Apis.PRINT + '/printers/state-report?accountId=' + salaId)

  return (
    <>
      {
        !loading ?
          <Select ref={ref} {...props}>
            {data && data.map(item => <option key={item.printerId} value={item.printerId}>{item.printerName}</option>)}
          </Select>
          :
          <Select disabled>
            <option value={''}>{"Cargando..."}</option>
          </Select>
      }
    </>
  )
})

export default PrinterSelectV2;
