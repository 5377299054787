import React, {useContext, useState} from 'react';
import Card from "../../../../components/forms/Card";
import {FlexLayout} from "../../../../components/flex/Flex";
import {FaIdCard, FaUserCircle} from "react-icons/fa";
import {SlDiamond} from "react-icons/sl";
import PageSection from "../../../../components/layout/PageSection";
import {Controller, useForm} from "react-hook-form";
import CustomerSearchInputV2 from "../../../common/components/CustomerSearchInputV2";
import FormGroup from "../../../../components/forms/FormGroup";
import {SiteContext} from "../../../common/context/SiteContext";
import {getDateFilter} from "../../../../components/util/CasinoDates";
import {Title} from "@wargostec/react-components";
import {Apis} from "../../../../util/Apis";
import axios from "axios";
import Table from "react-bootstrap/Table";
import Moment from "react-moment";
import RedeemProducts from "./RedeemProducts";
import RedeemPending from "./RedeemPending";
import Button from "../../../../components/forms/Button";
import PageContainerFull from '../../../../components/layout/PageContainerFull';
import {Tab, Tabs} from '../../../../components/tabs/Tabs';
import {LoadingTableV2} from "../../../../components/forms/Tables";

const RedeemCustomPoints = () => {

  const {salaId} = useContext(SiteContext)

  const date = getDateFilter('month')

  const [customer, setCustomer] = useState()
  const [data, setData] = useState()
  const [dataRedeem, setDataRedeem] = useState()
  const [document, setDocument] = useState()
  const [loading, setLoading] = useState(false)

  const {control, handleSubmit} = useForm();

  const getData = (params) => {
    setLoading(true)
    let customerId = params.customerId
    params.document = document
    if (customerId !== null) {
      setCustomer(customerId)
      axios.get(Apis.PC + `/redeems/products-available?customerId=${customerId}`, params).then(res => {
        setData(res.data)
      })
      axios.get(Apis.PC + `/redeems?salaId=${salaId}&ini=${date.ini}&fin=${date.fin}&customerId=${customerId}`, params).then(res => {
        setDataRedeem(res.data)
        setLoading(false)
      })
    }
  }

  return (

    <PageContainerFull>

      <PageSection>
          <Title text="Consulta de canjes" type={'page'}/>
        <FlexLayout gap='16px'>
          <Card padding style={{flex: 1, alignSelf: 'stretch'}}>
            <FlexLayout column>
              <FormGroup>
                <Controller name="customerId" control={control}
                            render={({field}) => <CustomerSearchInputV2 {...field} setDocument={setDocument}/>}/>
              </FormGroup>
              <FlexLayout>
                <Button style={{flex: 1}} onClick={handleSubmit(getData)}>
                  Limpiar
                </Button>
                <Button variant='dark' style={{flex: 1}} onClick={handleSubmit(getData)}>
                  Buscar
                </Button>
              </FlexLayout>

            </FlexLayout>
          </Card>
          {customer &&
            <Card padding style={{flex: 1}}>
              <FlexLayout column alignItems='center'>
                <FaUserCircle fontSize={'48'} />
                <strong>{data?.customer?.firstName + ' ' + data?.customer?.lastName1}</strong>
                <FlexLayout column alignItems='center'>
                  <FlexLayout alignItems='center' gap='4px'>
                    <SlDiamond />
                    <span>Nivel: <strong>{data?.customer?.levelName !== null ? data?.customer?.levelName : ' -- '}</strong></span>
                  </FlexLayout>
                  <FlexLayout alignItems='center' gap='4px'>
                    <FaIdCard />
                    <span>Documento: {data?.customer?.document} </span>
                  </FlexLayout>
                </FlexLayout>
              </FlexLayout>
            </Card>
          }
        </FlexLayout>
        {customer &&
          <Tabs>
            <Tab title="Historial de canjes">
              {customer &&
                <Card>
                  <LoadingTableV2 loading={loading} data={dataRedeem} noDataText={"Aún no hay canjes"}>
                    <Table striped hover responsive>
                      <thead>
                      <tr>
                        <th className={"text-center"}>Cliente</th>
                        <th className={"text-center"}>Nivel</th>
                        <th className={"text-center"}>Producto</th>
                        <th className={"text-center"}>Puntos canjeados</th>
                        <th className={"text-center"}>Puntos restantes</th>
                        <th className={"text-center"}>Fecha canje</th>
                        <th className={"text-center"}>Hora canje</th>
                        <th className={"text-center"}>Canjeado por</th>
                        <th className={"text-center"}>Estado</th>
                        <th></th>
                      </tr>
                      </thead>
                      <tbody>
                      {dataRedeem && dataRedeem.map(item =>
                        <tr key={item.id}>
                          <td className={"text-center"}>{item.customerName}</td>
                          <td className={"text-center"}>{item.levelName}</td>
                          <td className={"text-center"}>{item.productName}</td>
                          <td className={"text-center"}>{item.productPoint}</td>
                          <td className={"text-center"}>{item.points}</td>
                          <td className={"text-center"}><Moment format={'DD/MM/YYYY'}>{item.createdAt}</Moment></td>
                          <td className={"text-center"}><Moment format={'HH:mm'}>{item.createdAt}</Moment></td>
                          <td className={"text-center"}>{item.employeeName}</td>
                          <td className={"text-center"}>{item.state}</td>

                        </tr>
                      )}
                      </tbody>
                    </Table>
                  </LoadingTableV2>
                </Card>
              }
            </Tab>
            <Tab title="Canjear productos">
              {customer &&
                <RedeemProducts action={handleSubmit(getData)} data={data}/>
              }
            </Tab>
            <Tab title="Canje pendientes">
              <RedeemPending customerId={customer} />
            </Tab>
          </Tabs>
        }
      </PageSection>

    </PageContainerFull>

  );
};

export default RedeemCustomPoints;
