import React, {useEffect} from 'react';
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import axios from "axios";
import CategoriesForm from "./CategoriesForm";

const CategoriesEdit = ({id, setAction, setTitle}) => {

  const {data} = useFetch2(Apis.FOOD + '/food-categories/' + id)

  function update(params) {
    params.id = id
    return axios.put(Apis.FOOD + '/food-categories/' + id, params);
  }

  useEffect(() => {
    setTitle("Editar categoria")
  }, [])

  return (
        <CategoriesForm value={data} onSubmit={update} setAction={setAction}/>
  );
};

export default CategoriesEdit;
