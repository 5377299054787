import React, {useContext, useEffect} from 'react';
import {SiteContext} from "../../../common/context/SiteContext";
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import {useForm} from "react-hook-form";
import AsyncBlock from "../../../../components/forms/AsyncBlock";
import FormGroup from "../../../../components/forms/FormGroup";
import PrinterSelect from "../../../common/components/selects/PrinterSelect";
import ComputerSelect from "../../../common/components/selects/ComputerSelect";
import axios from "axios";
import {errorToast, successToast} from "../../../../components/layout/ToastStyles";
import Card from '../../../../components/forms/Card';
import Button from '../../../../components/forms/Button';
import SwitchToggle from "../../../../components/layout/SwitchToggle";

const ConfigOrders = () => {

  const {salaId} = useContext(SiteContext)
  let defaultParams = {salaId: salaId}

  const {loading, data, fetchData, error} = useFetch2(Apis.FOOD + `/printer-conf`, [], defaultParams);
  const {register, handleSubmit, reset} = useForm({defaultValues: defaultParams})

  const createOrUpdate = (params) => {
    if (data !== '') {
      onUpdate(params)
    } else {
      onCreate(params)
    }
  }

  const onCreate = (params) => {
    const object = {
      salaId: salaId,
      computerId: params.computerId,
      printReceipts: params.printReceipts,
      printerId: params.printerId,
      hash: Math.random().toString(36).substr(2, 6)
    }
    axios.post(Apis.FOOD + "/printer-conf", object)
      .then(res => {
        successToast()
        fetchData()
      }).catch(err => {
      console.log(err)
      errorToast();
    })
  };

  const onUpdate = (params) => {
    axios.put(Apis.FOOD + "/printer-conf", params)
      .then(res => {
        successToast()
        fetchData()
      }).catch(err => {
      console.log(err)
      errorToast();
    })
  };

  useEffect(() => {
    if (data) {
      reset(data)
    }
  }, [data])

  return (
    <>
      <Card style={{padding: '2rem', marginBottom: '2rem'}}>
        <h4 style={{fontSize: '1.5rem', margin: '2rem 0', fontWeight: '600'}}>Configuración de pedidos</h4>

        <AsyncBlock loading={loading} error={error}>
          <FormGroup>
            <label>Impresora</label>
            <PrinterSelect {...register("printerId")} />
          </FormGroup>
          <FormGroup>
            <label>Computadora</label>
            <ComputerSelect {...register("computerId")} />
          </FormGroup>
          <FormGroup>
            <label style={{paddingTop: '20px'}}>Habilitar impresiones</label><br/>
            <SwitchToggle
              {...register("printReceipts")}
            />
          </FormGroup>
          <Button
            variant={'primary'}
            onClick={handleSubmit(createOrUpdate)}>
            {data !== '' ? 'Actualizar' : 'Guardar'}
          </Button>
        </AsyncBlock>
      </Card>

    </>
  );
};

export default ConfigOrders;
