import React, {useContext, useEffect, useState} from "react";
import {Apis} from "../../../util/Apis";
import {SiteContext} from "../context/SiteContext";
import axios from 'axios'
import styled from "styled-components";

const UlCustom = styled.ul`
  max-height: 250px;
  overflow-y: auto;
  position: absolute;
  width: 100%;
  z-index:10;
`

const LiCustom = styled.li`
  cursor: pointer;

  &:hover {
    color: #16181b;
    text-decoration: none;
    background: #e9ecef;
  }

  &:active {
    color: #fff;
    text-decoration: none;
    background: #007bff;
  }
`

const CustomerSearchInputV2 = React.forwardRef(({onChange, setDocument, value}, ref) => {

  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [noQuery, setNoQuery] = useState(true);
  const [searchValue, setSearchValue] = useState('')
  const {salaId} = useContext(SiteContext);

  const handleSearch = (e) => {
    setLoading(true);
    setSearchValue(e?.target?.value?.toUpperCase())
    e.target.value.length > 0
      ? getCustomers(e.target.value)
      : cleanSearch()
  };

  const cleanSearch = () => {
    setNoQuery(true)
    setSearchValue(null)
    onChange(null)
  }

  const getCustomers = async (query) => {
    setNoQuery(false)
    const items = await axios.get(`${Apis.SEARCH}/elastic?query=${query}&salaId=${salaId}`)
    const options = items.data.map((i) => ({
      id: i.id,
      name: i.fullTotalName,
      document: i.document
    }));
    setOptions(options)
    setLoading(false)
  }

  const getCustomerInfo = (customerId) => {
    axios.get(`${Apis.PC}/persons/${customerId}`)
      .then((data) => {
        setSearchValue(data.data.fullTotalName);
        setLoading(false);
      })
  }

  const customOnChange = (item) => {
    setNoQuery(true);
    setSearchValue(item.name.toUpperCase());
    onChange(item.id);
    if (setDocument) {
      setDocument(item.document);
    }
  };

  useEffect(() => {
    if (value > 0) {
      getCustomerInfo(value)
    }
  }, [value])

  return (
    <div style={{position: 'relative'}}>
      <input className="form-control" id="listSearch" type="text" ref={ref} placeholder="Ingrese un cliente.."
        onChange={handleSearch}
        value={searchValue}
        autoComplete="off"
      />

      <UlCustom className="list-group" id="myList">
        {options && !noQuery && options.map(item =>
          <LiCustom key={item.id} className="list-group-item" onClick={() => {
            customOnChange(item);
          }}>
            {item.name.toUpperCase()}
          </LiCustom>
        )}
      </UlCustom>
    </div>
  )
})
export default CustomerSearchInputV2

