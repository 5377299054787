import React, {useContext, useEffect, useState} from 'react';
import PageSection from "../../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import Button, {ButtonBox} from "../../../../../components/forms/Button";
import Card from "../../../../../components/forms/Card";
import Table from "react-bootstrap/Table";
import Moment from "react-moment";
import {getDateFilter} from "../../../../../components/util/CasinoDates";
import {SiteContext} from "../../../../common/context/SiteContext";
import useFetch2 from "../../../../../hooks/useFetch2";
import {Apis} from "../../../../../util/Apis";
import {LoadingTableV2} from "../../../../../components/forms/Tables";
import moment from "moment";
import useModal from '../../../../../modals/useModal';
import SuggestDeleteMachine from './SuggestDeleteMachine';
import ModalInfo from '../../../../../modals/ModalInfo';
import PageContainerFull from "../../../../../components/layout/PageContainerFull";
import ButtonReload from "../../../../../components/forms/ButtonReload";
import DateFilter from '../../../../../components/datefilter/DateFilter';

const ProductionMachine = () => {

  const date = getDateFilter('month')

  const defaultParams = {ini: date.ini, fin: date.fin}
  const [fechas, setFechas] = useState(defaultParams)
  const [params, setParams] = useState(defaultParams)

  const {
    loading,
    data,
    error,
    fetchData
  } = useFetch2(Apis.TGM + '/deltas/prod-machine-day', [], params);

  const {wrapper: wrapper, someAction: openSuggest} = useModal({
    component: <SuggestDeleteMachine />
  })

  useEffect(() => {
    if (params) fetchData()
  }, [params])

  const getDates = (dates) => {
    const ini = moment(dates.ini);
    const fin = moment(dates.fin);
    let fechas = [];

    if (ini !== undefined && fin !== undefined) {

      while (ini.isSameOrBefore(fin)) {
        fechas.push(ini.format('YYYY-MM-DD'));
        ini.add(1, 'days');
      }
    }
    return fechas
  }

  const getState = (item) => {
    const today = moment(new Date()).format('YYYY-MM-DD')
    const prod = item.prod
    if (prod === null && item.fecha <= today) {
      return 'rgba(225,224,224,0.85)'
    }
    if (prod < 0) {
      return '#fd9090'
    }
    if (prod === 0) {
      return '#cdcdcd'
    }
    return 'white'
  }

  const getProd = (item) => {
    const today = moment(new Date()).format('YYYY-MM-DD')
    const prod = item.prod
    if (prod === null && item.fecha <= today) {
      return 'OFF'
    }
    if (prod !== null) {
      return prod?.toFixed(2)
    }
    return ''
  }

  useEffect(() => {
    setParams({...params, ...fechas})
    getDates(fechas)
  }, [fechas])


  return (
    <PageContainerFull>
      <PageSection>
          <Title text="Producción por maquina" type={'page'}/>
        <ButtonBox style={{marginBottom: "20px"}}>
          <ButtonReload onClick={fetchData} />
          <Button variant={'danger'} onClick={() => openSuggest()}>
            Máquinas sin producción
          </Button>
        </ButtonBox>
        <Card padding style={{background: '#0A0A0A'}}>
          <DateFilter fechas={fechas} setFechas={setFechas} modeDate={'month'} />
        </Card>
        <Card>
          <LoadingTableV2
            loading={loading}
            data={data}
            error={error}
            noDataText={"Aún no hay registros de producción por maquina "}>
            <Table size='sm' bordered responsive>
              <thead>
                <tr>
                  <th className="col-sm-1" />
                  {
                    getDates(fechas) && getDates(fechas).map(item =>
                      <th className="text-right">
                        <Moment format={'DD'}>{item}</Moment>
                      </th>
                    )
                  }
                </tr>
                <tr>
                  <th className="text-center">Máquina</th>
                  {
                    getDates(fechas) && getDates(fechas).map(item =>
                      <th className="text-right">
                        <Moment format={'ddd'}>{item}</Moment>
                      </th>
                    )
                  }
                </tr>
              </thead>
              <tbody>
                {data && data.map((item) =>
                  <tr key={item.id}>
                    <td className="text-center font-small">{item.machine}</td>
                    {item.prod.map(item =>
                      <td className="text-right font-small" style={{background: getState(item)}}>
                        {getProd(item)}
                      </td>
                    )}
                  </tr>
                )}
              </tbody>
            </Table>
          </LoadingTableV2>
        </Card>
      </PageSection>
      <ModalInfo size={'s'} {...wrapper} />
    </PageContainerFull>
  );
};

export default ProductionMachine;
