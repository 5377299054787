import {useEffect, useState} from "react";
import axios from "axios";

const useGet = ({
  url,
  initialValue,
  params = {},
  auto = true,
  select = (data) => data,
  onSuccess = (data) => { },
  onError = (error) => { },
}) => {
  const [data, setData] = useState(initialValue);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = async function (params = {}) {
    try {
      setLoading(true);
      const response = await axios.get(url, {params});
      if (response.status === 200) {
        setData(select(response.data));
        onSuccess(response.data);
        setError(null);
      }
    } catch (error) {
      onError(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (auto) fetchData(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {loading, data, fetchData, error};
};

export default useGet;
