import React from "react";
import Modal from "react-bootstrap/Modal";

//DEPRECATED : NO USAR
const GenericModal = ({title, body, ...props}) => {

  return (
    <Modal {...props}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      {body}
    </Modal>

  );
};

export default GenericModal;
