import React, {useContext} from 'react'
import {Select, SelectV2} from "../../../../components/forms/FormEl";
import {Apis} from "../../../../util/Apis";
import {SiteContext} from "../../context/SiteContext";
import useFetch2 from "../../../../hooks/useFetch2";

const ComputerSelect = React.forwardRef((props, ref) => {

  const {salaId} = useContext(SiteContext)
  const {data, loading} = useFetch2(`${Apis.PRINT}/computers/get-all?accountId=${salaId}`)
  const dataNew = data && data.map(item => {
    return {
      id: item.id,
      name: item.id + ' | ' + item.hostname
    }
  })

  return (
    <>
      {
        !loading ?
          <SelectV2 ref={ref} options={dataNew} {...props} />
          :
          <Select disabled={true}>
            <option key={0} value={0}>{"Cargando..."}</option>
          </Select>
      }
    </>
  )
})

export default ComputerSelect;
