import React, {useContext, useEffect} from "react";
import {Apis} from "../../../../../util/Apis";
import axios from "axios";
import {SiteContext} from "../../../../common/context/SiteContext";
import MachineGroupModalForm from "./MachineGroupModalForm";
import useFetch2 from "../../../../../hooks/useFetch2";

const MachineGroupEdit = ({id, setAction, setTitle}) => {

  const {salaId} = useContext(SiteContext)
  const {data} = useFetch2(Apis.TGM + '/slot-groups/' + id)

  const update = (params) => {
    //se podria crear un componente <Id/> para guardar esta propiedad
    params.id = id
    axios.put(Apis.TGM + '/slot-groups/' + id, params)
      .catch(err => {
        console.log(err)
    })
  }

  useEffect(() => {
    setTitle("Edición de grupo")
  }, [])

  return (
    <MachineGroupModalForm value={data} onSubmit={update} setAction={setAction}/>
  )
}

export default MachineGroupEdit
