import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import PageSection from "../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import Button from "../../../../components/forms/Button";
import FormGroup from "../../../../components/forms/FormGroup";
import {Apis} from "../../../../util/Apis";
import {useForm} from "react-hook-form";
import SalaIdInput from "../../../common/components/SalaIdInput";
import {DatePickerCustom} from "../../../../components/forms/FormEl";
import Slidebar from "../../../common/layout/Slidebar";
import SorteoNew from "./SorteoNew";
import axios from "axios";
import {SiteContext} from "../../../common/context/SiteContext";
import DeleteGenericModal from "../../../../modals/DeleteGenericModal";
import {FaEdit, FaFileExcel, FaFilePdf, FaInfoCircle, FaPrint, FaRegSun, FaTrash, FaTrophy,} from "react-icons/fa";
import {AiOutlineOrderedList} from "react-icons/ai";
import {BiSolidUserPlus, BiTransfer} from "react-icons/bi";
import useEditModal from "../../../../modals/useEditModal";
import useDeleteModal from "../../../../modals/useDeleteModal";
import GenericModal2 from "../../../../modals/GenericModal2";
import SorteoEdit from "./SorteoEdit";
import Moment from "react-moment";
import PageContainerFull from "../../../../components/layout/PageContainerFull";
import AsignarGanador from "./AsignarGanador";
import {errorToast, successToast} from "../../../../components/layout/ToastStyles";
import SorteoPrint from "./SorteoPrint";
import useModal from "../../../../modals/useModal";
import useFetch2 from "../../../../hooks/useFetch2";
import SorteoHistory from "./SorteoHistory";
import ModalInfo from "../../../../modals/ModalInfo";
import {useHistory} from "react-router";
import {Dropdown, DropdownButton, OverlayTrigger} from "react-bootstrap";
import SorteoEditBulk from "./SorteoEditBulk";
import {FlexLayout} from "../../../../components/flex/Flex";
import AsyncBlock from "../../../../components/forms/AsyncBlock";
import ParticipantsList from "./ParticipantsList";
import Tooltip from "react-bootstrap/Tooltip";
import {getStartDateTimeFromRaffle} from "../../../../components/util/CasinoDates";
import moment from "moment";
import SorteoActa from "./SorteoActa";
import CardOverview, {CardOverviewContainer} from "../../../../components/layout/CardOverview";
import {BsCardChecklist, BsCashCoin} from "react-icons/bs";
import {NumberRound} from "../../../../components/util/NumberUtil";
import {RiFileSettingsLine} from "react-icons/ri";
import ReactTable from "../../../../components/util/ReactTable";
import TransferCreditBySorteo from "./TransferCreditBySorteo";

const SorteoList = () => {

  const {salaId} = useContext(SiteContext)
  let defaultParams = {enabled: true}

  const history = useHistory();

  const firstDayOfMonth = moment(moment(new Date())).startOf('month').format('YYYY-MM-DD');
  const lastDayOfMonth = moment(moment(new Date())).endOf('month').format('YYYY-MM-DD');

  const [params, setParams] = useState()
  const {loading, data, fetchData, error} = useFetch2(Apis.SORTEO + '/sorteos', [], params, false);
  const {
    loading: loading2,
    data: data2,
    error: error2,
    fetchData: fetchData2
  } = useFetch2(Apis.SORTEO + '/sorteo-conf');

  const {register, handleSubmit, getValues} = useForm({defaultValues: defaultParams});

  const getData = (params) => {
    setParams(params)
  }

  const download = (id) => {
    window.location.href = Apis.SORTEO + `/sorteo-winner/${id}/report.xlsx`;
  }

  const pdf = (id) => {
    window.location.href = Apis.SORTEO + `/sorteo-winner/${id}/report.pdf`;
  }

  const downloadList = () => {
    window.location.href = Apis.SORTEO + `/sorteos/report.xlsx?salaId=${salaId}&ini=${getValues('ini')}&fin=${getValues('fin')}`;
  }

  //hooks para crear,editar y eliminar
  const {wrapper: wrapper1, addAction, editAction} = useEditModal({
    editComponent: <SorteoEdit />,
    addComponent: <SorteoNew />,
    onRefresh: () => {fetchData(); fetchData2()}
  })

  const {wrapper: wrapperImp, someAction: editActionImp} = useModal({
    component: <SorteoPrint />,
  })

  const {wrapper: wrapper5, someAction: mostrarAction} = useModal({
    component: <SorteoHistory />
  })

  const {wrapper: wrapper6, someAction: mostrarParticipants} = useModal({
    component: <ParticipantsList />
  })

  const {wrapper: wrapper7, someAction: mostrarActa} = useModal({
    component: <SorteoActa />
  })

  const {wrapper: wrapperBulk, refresh: refreshBulk, someAction: editActionBulk} = useModal({
    component: <SorteoEditBulk />
  })

  const {wrapper: wrapper8, addAction : transferCredit} = useEditModal({
    addComponent: <TransferCreditBySorteo/>,
    onRefresh: fetchData
  })

  const deleteElementSorteo = id => axios.delete(Apis.SORTEO + "/sorteos/" + id).then(() => successToast("Se anuló el sorteo correctamente")).catch(() => errorToast())
  const deleteElementGanador = id => axios.delete(Apis.SORTEO + "/sorteo-winner/" + id).then(() => successToast("Se anuló al ganador correctamente")).catch(() => errorToast())

  const {
    wrapper: wrapper2,
    openDeleteModal
  } = useDeleteModal(
    {
      text: '¿Desea anular este Sorteo?',
      action: deleteElementSorteo,
      onRefresh: () => {fetchData(); fetchData2()}
    })

  const {wrapper: wrapper3, refresh: refresh3, someAction: asignarAction} = useModal({
    component: <AsignarGanador />
  })


  const {
    wrapper: wrapper4,
    openDeleteModal: openDeleteModal2
  } = useDeleteModal(
    {
      text: '¿Desea anular el ganador al sorteo?',
      action: deleteElementGanador,
      onRefresh: () => {fetchData(); fetchData2()}
    })

  //Buscar eliminar los refresh de los modals basicos
  useEffect(() => {
    fetchData()
    fetchData2()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh3, refreshBulk, params])

  const [slideOpen, setSlideOpen] = useState(false)
  const [checkStates, setCheckStates] = useState({})
  const [seleccionados, setSeleccionados] = useState([])

  const getRange = (item) => {
    if (item.relativePeriod === undefined || item.relativePeriod === null) {
      return ''
    }

    let period = ''
    if (item.relativePeriod === 'HOUR') {
      period = 'HORAS';
    } else if (item.relativePeriod === 'DAY') {
      period = 'DIAS';
    } else if (item.relativePeriod === 'WEEK') {
      period = 'SEMANAS';
    }

    return item.relativePeriodAmount + ' ' + period
  }

  const handleCheck = (id, kind, e) => {
    if (e.target.checked) {
      setSeleccionados(seleccionados => [...seleccionados, {id: id, kind: kind}])
    } else {
      setSeleccionados(seleccionados => [...seleccionados.filter((item) => item.id !== id)])
    }
    checkStates[id] = e.target.checked
    setCheckStates({...checkStates})
  }

  const viewConfig = () => {
    history.push('/crm/settings')
  }

  const checkValue = useCallback(cell =>
    <input checked={checkStates[cell.row.original.id]} key={Math.random()} type="checkbox" onChange={
      (e) => handleCheck(cell.row.original.id, cell.row.original.kind, e)} />
      // eslint-disable-next-line react-hooks/exhaustive-deps
    , []);
  const dateValue = useCallback(props => <Moment format={'DD/MM'}>{props.renderValue()}</Moment>, []);
  const hourValue = useCallback(props => props.renderValue()?.substring(0, 5), []);

  const promoValue = useCallback(props =>
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <span className="font-bold">{props.renderValue()}</span>
      {props.row.original.anforaName !== null ? <span className="font-small">{'Ánfora: ' + props.row.original.anforaName}</span> : null}
    </div>
    , []);

  const nroActaValue = useCallback(props =>
    props.renderValue() === null ? '' :
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '4px'}}>
        <span>{props.renderValue()}</span>
        <FaEdit style={{cursor: 'pointer'}} onClick={() => mostrarActa({acta: props.renderValue(), id: props.row.original.sorteoWinnerId})} />
      </div>
      // eslint-disable-next-line react-hooks/exhaustive-deps
    , []);

  const rangeValue = useCallback(props =>
    <OverlayTrigger
      placement={'top'}
      overlay={
        <Tooltip>{'Participan cupones desde ' + getStartDateTimeFromRaffle(
          props.row.original.fechaHora,
          props.row.original.relativePeriod,
          props.row.original.relativePeriodAmount
        ) + ' hasta la hora del sorteo'}</Tooltip>
      }
    >
      <span>{getRange(props.row.original)}</span>
    </OverlayTrigger>
    , []);

  const winnerValue = useCallback(props =>
    props.renderValue() > 0 ?
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <span>
          {props.row.original.firstName + ' ' + props.row.original.lastName1 + ' ' + props.row.original.lastName2}
        </span>
        <span className="font-bold font-small">Doc: {props.row.original.document}</span>
      </div>
      :
      <Button variant={'light'} size='xs'
        onClick={() => asignarAction({
          id: props.row.original.id,
          kind: props.row.original.kind,
          sorteoWinnerId: props.row.original.sorteoWinnerId
        })}>
        <BiSolidUserPlus size={24} />
        <span>Asignar</span>
      </Button>
      // eslint-disable-next-line react-hooks/exhaustive-deps
    , []);

  const cuponValue = useCallback(props =>
    props.row.original.sorteoWinnerId !== null &&
    <div style={{display: 'flex', flexDirection: 'column'}}>
      {props.row.original.kind === 'HOT_SEAT' ? '--' :
        <>
          <span className="font-bold">N° {props.renderValue()}</span>
          <span style={{color: 'gray'}} className="font-small">Creado: <Moment format={'DD/MM hh:mm'}>{props.row.original.couponCreatedAt}</Moment></span>
        </>
      }
    </div>
    , []);

  const actionsValue = useCallback(cell =>
    <FlexLayout justifyContent='center'>
      {cell.row.original.customerId > 0 &&
        <DropdownButton variant='dark' size='sm' title="Exportar">
          <Dropdown.Item
            onClick={() => download(cell.row.original.sorteoWinnerId)}><FaFileExcel /> Acta</Dropdown.Item>
          <Dropdown.Item onClick={() => pdf(cell.row.original.sorteoWinnerId)}><FaFilePdf /> PDF</Dropdown.Item>
        </DropdownButton>
      }
      <Dropdown>
        <Dropdown.Toggle variant="light" id="dropdown-custom-1" size='sm'>
          <FaRegSun />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => mostrarAction({id: cell.row.original.id})}><FaTrophy /> Historial</Dropdown.Item>

          {
            cell.row.original.kind === 'VIRTUAL' &&
            <Dropdown.Item
              onClick={() => mostrarParticipants({
                values: {
                  id: cell.row.original.id,
                  customerId: cell.row.original.customerId,
                  ini: firstDayOfMonth,
                  fin: lastDayOfMonth,
                  couponTypeId: cell.row.original.couponTypeId
                }
              })}><AiOutlineOrderedList /> Participantes</Dropdown.Item>
          }
          <Dropdown.Item onClick={() => editAction(cell.row.original.id)}><FaEdit /> Editar</Dropdown.Item>
          <Dropdown.Item onClick={() => editActionImp({id: cell.row.original.id})}>
            <FaPrint /> Imprimir acta
          </Dropdown.Item>
          {
            cell.row.original.customerId > 0
            &&
            <Dropdown.Item onClick={() => openDeleteModal2(cell.row.original.sorteoWinnerId)}><FaTrash /> Anular
              ganador</Dropdown.Item>
          }

          <Dropdown.Item onClick={() => transferCredit({
            id: cell.row.original.id,
            customerId: cell.row.original.customerId,
            confId: cell.row.original.confId,
            amount: cell.row.original.amount,
            machine: cell.row.original.machine,
            kind: cell.row.original.kind
          })}><BiTransfer/> Transferir a máquina</Dropdown.Item>

          <Dropdown.Item
            onClick={() => openDeleteModal(cell.row.original.id)}><FaTrash /> Eliminar
            sorteo</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </FlexLayout>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  , []);

  const infoValue = useCallback(cell =>
    <OverlayTrigger
      placement={'top'}
      overlay={
        <Tooltip>
          <FlexLayout column gap='4px'>
            <span style={{fontSize: '12px'}}>Creado por {cell.row.original.createdByName !== null? cell.row.original.createdByName : 'Soporte'}</span>
            <span style={{fontSize: '12px'}}>Actualizado por {cell.row.original.updatedByName!== null? cell.row.original.createdByName : 'Soporte'}</span>
          </FlexLayout>
        </Tooltip>
      }
    >
      <span><FaInfoCircle size={18} /></span>
    </OverlayTrigger>
  , []);

  const onlyClients = useCallback(cell => <span>{cell.renderValue() ? 'Solo clientes' :'Todos'}</span>
    , []);

  const columns = useMemo(
    () => [
      {
        header: ' ',
        accessorKey: 'checkbox',
        enableGlobalFilter: false, // para deshabilitar el filter
        enableSorting: false, // para deshabilitar el sort
        cell: checkValue
      },
      {
        header: 'ID',
        accessorKey: 'id',
        enableGlobalFilter: false, // para deshabilitar el filter
        enableSorting: false, // para deshabilitar el sort
        meta: {
          headerClassName: 'text-center font-xsmall',
          columnClassName: 'text-center font-xsmall',
        },
      },
      {
        header: 'Fecha',
        accessorKey: 'fecha',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: dateValue
      },
      {
        header: 'Hora',
        accessorKey: 'hora',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: hourValue
      },
      {
        header: 'Premio',
        accessorKey: 'amount',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Modo',
        accessorKey: 'kind',
        enableSorting: false, // para deshabilitar el sort
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center font-bold font-small',
        },
      },
      {
        header: 'Apostado del día',
        accessorKey: 'dailyCi',
        enableSorting: false, // para deshabilitar el sort
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Participantes',
        accessorKey: 'logged',
        enableSorting: false, // para deshabilitar el sort
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: onlyClients
      },
      {
        header: 'Promoción',
        accessorKey: 'couponTypeName',
        enableSorting: false, // para deshabilitar el sort
        cell: promoValue
      },
      {
        header: 'Rango',
        accessorKey: 'range',
        enableSorting: false, // para deshabilitar el sort
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center text-small',
        },
        cell: rangeValue
      },
      {
        header: 'Desde',
        accessorKey: 'timeStart',
        enableSorting: false, // para deshabilitar el sort
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: hourValue
      },
      {
        header: 'Acta',
        accessorKey: 'nroActa',
        enableSorting: false, // para deshabilitar el sort
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: nroActaValue
      },
      {
        header: 'Ganador',
        accessorKey: 'customerId',
        enableSorting: false, // para deshabilitar el sort
        cell: winnerValue
      },
      {
        header: 'Cupón',
        accessorKey: 'couponId',
        enableSorting: false, // para deshabilitar el sort
        cell: cuponValue
      },
      {
        header: 'Máquina',
        accessorKey: 'machine',
        disableSortBy: true,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center font-bold',
        },
      },
      {
        header: 'Acciones',
        accessorKey: 'acciones',
        enableGlobalFilter: false, // para deshabilitar el filter
        enableSorting: false, // para deshabilitar el sort
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: actionsValue
      },
      {
        header: '',
        accessorKey: 'audit',
        enableGlobalFilter: false, // para deshabilitar el filter
        enableSorting: false, // para deshabilitar el sort
        headerClassName: 'text-center',
        columnClassName: 'text-center',
        cell: infoValue
      }
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , []
  )

  return (
    <PageContainerFull>
      <Slidebar visible={slideOpen} onHide={() => setSlideOpen(false)}>
        <div style={{padding: '16px'}}>
          <SalaIdInput {...register("salaId")} />
          <FormGroup>
            <label>Desde</label>
            <DatePickerCustom  {...register("ini")} />
          </FormGroup>

          <FormGroup>
            <label>Hasta</label>
            <DatePickerCustom  {...register("fin")} />
          </FormGroup>

          <Button variant={'primary'} title='Buscar' onClick={handleSubmit(getData)} />
        </div>
      </Slidebar>

      <PageSection>
          <Title text="Sorteos" type={'page'}/>

        <CardOverviewContainer gridColumns='repeat(3, 1fr)'>
          <CardOverview
            title={data && data.length}
            subtitle='Número de sorteos'
            icon={<BsCardChecklist size={20} />}
          />
          <CardOverview
            title={
              <span>
                S/ <NumberRound>{data.reduce((total, item) => total + item.amount, 0)}</NumberRound>
              </span>
            }
            subtitle='Monto total'
            icon={<BsCashCoin size={20} />}
          />
          <AsyncBlock loading={loading2} error={error2}>
            <CardOverview
              title={data2 && data2.nroActa}
              subtitle='N° Acta siguiente'
              icon={<RiFileSettingsLine size={20} />}
              onClick={viewConfig}
            />
          </AsyncBlock>

        </CardOverviewContainer>

        <AsyncBlock loading={loading} error={error}>
          <ReactTable
            columns={columns}
            data={data || []}
            filterOnclick={() => setSlideOpen(!slideOpen)}
            filterDownload={downloadList}
            filterAdd={() => addAction()}
            customButton={seleccionados.length > 0 &&
              <Button variant='light' onClick={() => editActionBulk({
                grupoId: seleccionados.map(item => item.id),
                tipos: new Set(seleccionados.map(item => item.kind))
              })}
              >
                <FaEdit />
                <span>Editar grupo</span>
              </Button>
            }
            noData='No existen resultados para el filtro seleccionado'
            pageSize={50}
            hideInitialColumns={[{id:'logged'}, {id:'dailyCi'}]}
          />
        </AsyncBlock>
      </PageSection>

      <GenericModal2 {...wrapper1} />
      <GenericModal2 {...wrapper3} />
      <GenericModal2 {...wrapperImp} />
      <GenericModal2 {...wrapperBulk} />
      <GenericModal2 {...wrapper7} />
      <DeleteGenericModal {...wrapper2} />
      <DeleteGenericModal {...wrapper4} />
      <ModalInfo {...wrapper5} />
      <ModalInfo {...wrapper6} />
      <GenericModal2 {...wrapper8} />
    </PageContainerFull>
  )
};

export default SorteoList
