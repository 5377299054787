import React, {useContext, useEffect, useState} from "react";
import {SiteContext} from "../../../common/context/SiteContext";
import {Apis} from "../../../../util/Apis";
import {useForm} from "react-hook-form";
import Slidebar from "../../../common/layout/Slidebar";
import SalaIdInput from "../../../common/components/SalaIdInput";
import Button, {ButtonBox} from "../../../../components/forms/Button";
import PageSection from "../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import Table from "react-bootstrap/Table";
import Card from "../../../../components/forms/Card";
import {FlexLayout} from "../../../../components/flex/Flex";
import {numberFormat} from "../../../../components/util/NumberUtil";
import axios from "axios";
import DeleteGenericModal from "../../../../modals/DeleteGenericModal";
import useDeleteModal from "../../../../modals/useDeleteModal";
import Moment from "react-moment";
import AsyncBlock from "../../../../components/forms/AsyncBlock";
import useFetch2 from "../../../../hooks/useFetch2";
import useToggle from "../../../../hooks/useToggle";
import CardOverview, {CardOverviewContainer} from "../../../../components/layout/CardOverview";
import {HiOutlineBanknotes} from "react-icons/hi2";
import {FaCoins, FaSortAmountUpAlt} from "react-icons/fa";
import PageContainer from "../../../../components/layout/PageContainer";
import PeriodAndFondoSelect from "./PeriodAndFondoSelect";
import EmptyState from "../../../common/components/EmptyState";
import {TbFileSad} from "react-icons/tb";
import {getCasinoDay} from "../../../../components/util/CasinoDates";

const CRefillReport = () => {

  const {salaId} = useContext(SiteContext)
  const {isOpen: slideOpen, toggler} = useToggle({});
  const [period, setPeriod] = useState(getCasinoDay());

  const {register, handleSubmit} = useForm();
  const [params, setParams] = useState({
    salaId: salaId,
    fondoId: "",
    period:period
  });

  const determineApiEndpoint = (fondoId) => {
    return fondoId === "" 
      ? Apis.CAJA + '/refill/report/by-period' 
      : Apis.CAJA + '/refill/report/by-fondo';
  };

  const {loading, data, fetchData, error} = useFetch2(determineApiEndpoint(params.fondoId), null, params, false);

  const deleteElement = id => axios.delete(Apis.CAJA + '/refill/' + id)

  const {
    wrapper: wrapper2,
    openDeleteModal
  } = useDeleteModal({
    text: '¿Desea eliminar esta operación de caja?',
    action: deleteElement,
    onRefresh: fetchData
  })

  useEffect(() => {
    setParams((prevParams) => ({
      ...prevParams,
      period,
    }));
  }, [period]);

  const handleFondoIdChange = (fondoId) => {
    setParams((prevParams) => ({...prevParams, fondoId: fondoId}));
  };

  const onSubmit = () => {
    fetchData();
  };

  return (
    <PageContainer>
      <Slidebar visible={slideOpen} onHide={toggler}>
        <div style={{padding: '16px'}}>
          <SalaIdInput {...register("salaId")} />
          <PeriodAndFondoSelect
            onFondoChange={handleFondoIdChange}
            period={period}
            setPeriod={setPeriod}
            {...register("fondoId")} />
          <Button variant={'primary'} title='Buscar' onClick={handleSubmit(onSubmit)} />
        </div>
      </Slidebar>
      <PageSection>
        <Title text="Rellenos" type={'page'} />
        <ButtonBox>
          <Button variant={'primary'} title={'Filtrar'} onClick={toggler} />
        </ButtonBox>
        <AsyncBlock loading={loading} error={error}>
          {data === null ?
            <Card padding>
              <EmptyState
                title={'Rellenos'}
                subtitle={'Haga click en FILTRAR para realizar una busqueda'}
                icon={<FaCoins size={32} />}
              />
            </Card>
            :
            <>
              {data?.data?.length === 0 ?
                <Card padding>
                  <EmptyState
                    title={'Sin resultados'}
                    subtitle={'Haga click en FILTRAR para realizar una nueva busqueda'}
                    icon={<TbFileSad size={32} />}
                  />
                </Card>
                :
                <>
                  <CardOverviewContainer gridColumns='repeat(2, 1fr)'>
                    <CardOverview
                      title={data?.quantity ?? 0}
                      subtitle='Operaciones'
                      icon={<FaSortAmountUpAlt size={20} />}
                    />
                    <CardOverview
                      title={'S/ ' + numberFormat(data?.totalAmount)}
                      subtitle='Total'
                      icon={<HiOutlineBanknotes size={20} />}
                    />
                  </CardOverviewContainer>
                  <Card>
                    <Table striped hover responsive>
                      <thead>
                        <tr>
                          <th className="text-center">Máquina</th>
                          <th>Nombre de Juego</th>
                          <th className="text-right">Monto</th>
                          <th className="text-right">Fichas Por Cobrar</th>
                          <th className="text-center">Fondo</th>
                          <th className="text-center">Fecha</th>
                          <th className="text-center">Acciones</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.data && data?.data?.map(item =>
                          <tr key={item.id}>
                            <td className="text-center">{item.machine}</td>
                            <td>{item.gameName}</td>
                            <td className="text-right">{'S/ ' + numberFormat(item.amount)}</td>
                            <td className="text-right">{'S/ ' + numberFormat(item.fichasPorCobrar)}</td>
                            <td className="text-center">{item.fondoName}</td>
                            <td className="text-center"><Moment format={'YYYY-MM-DD hh:mm a'}>{item.createdAt}</Moment></td>
                            <td className="text-center">
                              <FlexLayout justifyContent='center'>
                                <Button variant="danger" size='xs' onClick={() => openDeleteModal(item.id)}>Eliminar</Button>
                                <Button size='xs'>Imprimir</Button>
                              </FlexLayout>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </Card>
                </>
              }
            </>
          }
        </AsyncBlock>
      </PageSection>
      <DeleteGenericModal {...wrapper2} />
    </PageContainer>
  )
}

export default CRefillReport;
