import React, {useContext, useEffect} from 'react';
import PageSection from '../../../../components/layout/PageSection';
import {Title} from "@wargostec/react-components";
import {PrimaryButton} from '../../../../components/forms/MyButton';
import Table from 'react-bootstrap/Table';
import PageContainer from '../../../../components/layout/PageContainer';
import Button, {ButtonBox} from '../../../../components/forms/Button';
import {useHistory} from 'react-router';
import useEditModal from '../../../../modals/useEditModal';
import useDeleteModal from '../../../../modals/useDeleteModal';
import axios from 'axios';
import {Apis} from '../../../../util/Apis';
import GenericModal2 from '../../../../modals/GenericModal2';
import DeleteGenericModal from '../../../../modals/DeleteGenericModal';
import GroupNew from './GroupNew';
import {SiteContext} from '../../../common/context/SiteContext';
import useFetch2 from '../../../../hooks/useFetch2';
import {LoadingTableV2} from '../../../../components/forms/Tables';

const GroupsList = () => {
	const history = useHistory();
	const {salaId} = useContext(SiteContext);

	if (localStorage.getItem('activeKey')) {
		localStorage.removeItem('activeKey');
	}

	const {data, loading, fetchData, error} = useFetch2(
		Apis.USER + `/user-group?salaId=${salaId}`,
		[]
	);

	const editGroup = (item) =>
		history.push('/admin/groups/edit/' + item.id + '/' + item.name);

	const deleteElement = (id) => axios.delete(Apis.USER + '/user-group/' + id);

	const {wrapper: wrapper1, addAction} = useEditModal({
		addComponent: <GroupNew />,
		onRefresh: fetchData
	});

	const {wrapper: wrapper2, openDeleteModal} = useDeleteModal({
    text: '¿Desea eliminar este grupo?',
    action: deleteElement,
		onRefresh: fetchData
	});

	return (
		<PageContainer>
			<PageSection>
					<Title text="Grupos" type={'page'}/>
				<PrimaryButton onClick={() => addAction()}>Crear Grupo</PrimaryButton>
			</PageSection>
			<PageSection>
				<LoadingTableV2 loading={loading} error={error} data={data}>
					<Table striped hover>
						<thead>
							<tr>
								<th>ID</th>
								<th>Nombre</th>
								<th>Nro de Usuarios</th>
								<th>Fecha de Creación</th>
								<th>Acciones</th>
							</tr>
						</thead>
						<tbody>
							{data.map((item) => (
								<tr key={item.id}>
									<td>{item.id}</td>
									<td>{item.name}</td>
									<td>{item.userQuantity}</td>
									<td>{item.createdAt}</td>
									<td>
										<ButtonBox>
											<Button
												size={'s'}
												variant={'secondary'}
												onClick={() => editGroup(item)}
											>
												Editar
											</Button>
											<Button
												size={'s'}
												variant={'secondary'}
												onClick={() => openDeleteModal(item.id)}
											>
												Eliminar
											</Button>
										</ButtonBox>
									</td>
								</tr>
							))}
						</tbody>
					</Table>
				</LoadingTableV2>
			</PageSection>

			<GenericModal2 {...wrapper1} />
			<DeleteGenericModal {...wrapper2} />
		</PageContainer>
	);
};

export default GroupsList;
