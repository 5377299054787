import React, {useContext, useEffect} from 'react';
import {SiteContext} from "../../../../common/context/SiteContext";
import axios from "axios";
import {Apis} from "../../../../../util/Apis";
import SegmentsModal from "./SegmentsModal";

const SegmentsNew = ({setAction, setTitle}) => {

  const {salaId} = useContext(SiteContext)

  const save = (params) => {
    params.salaId = salaId
    return axios.post(Apis.PC + '/customer-segments', params)
  }

  useEffect(() => {
    setTitle("Nueva zona")
  }, [])

  return (
    <SegmentsModal value={{}} onSubmit={save} setAction={setAction}/>
  )
};

export default SegmentsNew;
