import React, {useCallback, useMemo} from "react";
import {Apis} from "../../../../util/Apis";
import ReactTable from "../../../../components/util/ReactTable";
import useFetch2 from "../../../../hooks/useFetch2";
import GenericModal2 from "../../../../modals/GenericModal2";
import useEditModal from "../../../../modals/useEditModal";
import MachineMove from "../maestros/machine/MachineMove";
import SecComponent from "../../../../components/layout/SecComponent";
import Button from "../../../../components/forms/Button";


const AlmacenDetail = ({id,setTitle}) => {

  useMemo(() => {
    setTitle("Maquinas del almacen Nro. " + id)
  },[id])

  const {wrapper: wrapper1, editAction} = useEditModal({
    editComponent: <MachineMove/>,
    onRefresh: () => window.location.reload()
  })

  const {data} = useFetch2(Apis.TGM + `/machine-warehouse/${id}/machines`, []);


  const groupValue = useCallback(props => props.renderValue()?.name, []);

  const actionsValue = useCallback(props => <>{
    <SecComponent code={'p88gnsxg'}>
      <Button
        variant='dark'
        size={'xs'}
        title={'Dar de baja'}
        onClick={()=>editAction(props.renderValue())}
      />
    </SecComponent>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }</>, []);

  const columns = useMemo(
    () => [
      {
        header: 'Nombre',
        accessorKey: 'machine',
        meta: {
          headerClassName:'text-center',
          columnClassName:'text-center',
        },
      },
      {
        header: 'Serie',
        accessorKey: 'serialNumber'
      },
      {
        header: 'Reg.MIN',
        accessorKey: 'numReg'
      },
      {
        header: 'Den',
        accessorKey: 'den'
      },
      {
        header: 'Fab',
        accessorKey: 'brand'
      },
      {
        header: 'Modelo',
        accessorKey: 'model'
      },
      {
        header: 'Juego',
        accessorKey: 'gameName'
      },
      {
        header: 'Grupo',
        accessorKey: 'slotGroup',
        cell: groupValue
      },
      {
        header: 'Zona',
        accessorKey: 'zoneName'
      },
      {
        header: 'Blu',
        accessorKey: 'bluSn'
      },
      {
        header: 'QR',
        accessorKey: 'qrCode'
      },
      {
        header: 'ID-0',
        accessorKey: 'confId',
        meta: {
          headerClassName:'text-center',
          columnClassName:'text-center',
        },
        enableSorting:false,
      },
      {
        header: 'Acciones',
        accessorKey: 'id',
        meta: {
          headerClassName:'text-center',
          columnClassName:'text-center',
        },
        enableGlobalFilter:false,
        enableSorting:false,
        cell: actionsValue
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <ReactTable
        columns={columns}
        data={data || []}
        noData='No existen resultados'
        pageSize={20}
      />

      <GenericModal2 {...wrapper1}/>
    </>
  )
}

export default AlmacenDetail
