import React, {useEffect} from 'react';
import {Apis} from "../../../../../util/Apis";
import axios from "axios";
import useFetch2 from "../../../../../hooks/useFetch2";
import ProductVariantForm from "./ProductVariantForm";

const ProductVariantEdit = ({id, setAction, setTitle}) => {

  const {loading, data} = useFetch2(Apis.FOOD + '/food-variant/' + id)

  const update = async (params) => {
    await axios.put(Apis.FOOD + '/food-variant/' + id, params)
  }

  useEffect(() => {
    setTitle("Edición de Variante")
  }, [])

  return (
    <React.Fragment>
      {data && !loading &&
        <ProductVariantForm value={data} onSubmit={update} setAction={setAction}/>
      }
    </React.Fragment>
  );
};

export default ProductVariantEdit;
