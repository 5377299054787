import React, {useEffect, useState} from 'react'
import {SelectV2} from "../../../../components/forms/FormEl";
import {Apis} from "../../../../util/Apis";
import Select from "react-select";
import useFetch2 from "../../../../hooks/useFetch2";

const SlotGroupSelectV2 = React.forwardRef((props, ref) => {

  const {data, loading} = useFetch2(`${Apis.SALAS}/slot-models`)

  const [selectedOption, setSelectedOption] = useState(null)
  const [optionsFormat, setOptionsFormat] = useState([])
  const [groupedOption, setGroupedOption] = useState([])

  const prevOnChange = (selected) => {
    props.onChange(selected.value)
  }

  useEffect(() => {
    const selected = optionsFormat.find(option => option.value === props.value)
    setSelectedOption(selected)

  }, [props.value])

  useEffect(() => {
    if (data) {
      const formatted = data.map((str) => {
        return {value: str.modelId, label: str.name};
      });
      setOptionsFormat(formatted)
      if (props.value) {
        const selected = formatted.find(option => option.value === props.value)
        setSelectedOption(selected)
      }
      groupedManufacturer(data)
    }
  }, [data])

  const groupedManufacturer = (value) => {
    let nuevoArray = []
    let arrayTemporal = []
    for (let i = 0; i < value.length; i++) {
      arrayTemporal = nuevoArray.filter(resp => resp["label"] === value[i]["manufacturerName"])
      if (arrayTemporal.length > 0) {
        nuevoArray[nuevoArray.indexOf(arrayTemporal[0])]["options"].push({
          value: value[i]["modelId"],
          label: value[i]["name"]
        })
      } else {
        nuevoArray.push({
          "label": value[i]["manufacturerName"],
          "options": [
            {
              value: value[i]["modelId"],
              label: value[i]["name"]
            }
          ]
        })
      }
    }
    setGroupedOption(nuevoArray)
  }

  return (
    <>
      {
        !loading ?
          <Select
            value={selectedOption}
            onChange={prevOnChange}
            options={groupedOption}
          />
          :
          <SelectV2 disabled options={[{id: 0, name: 'Cargando...'}]}/>
      }
    </>
  )
})

export default SlotGroupSelectV2;
