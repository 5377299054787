import {useHistory, useParams} from "react-router";
import React, {useEffect} from "react";
import FormGroup from "../../../../components/forms/FormGroup";
import Input from "../../../../components/forms/FormEl";
import axios from 'axios'
import {useForm} from "react-hook-form";

const PolicyNameEdit = ({setAction, setTitle}) => {

  const {id} = useParams()
  const {register, control, handleSubmit, watch, errors, reset} = useForm();

  const update = (params) => {
    return axios.update('http://localhost:9081/user-group/' + id, params)
  }

  useEffect(() => {
    setTitle("Cambiar Nombre")
    setAction(() => handleSubmit(update))
  }, [])

  return (
    <FormGroup>
      <label>Nombre</label>
      <Input type={'text'} {...register("name")}/>
    </FormGroup>
  )

}

export default PolicyNameEdit
