import React, { useCallback, useContext, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Title } from "@wargostec/react-components";
import Moment from "react-moment";
import Dropdown from 'react-bootstrap/Dropdown';
import axios from "axios";
import styled from "styled-components";
import DropdownButton from "react-bootstrap/DropdownButton";
import { BsPrinter } from "react-icons/bs";
import PageContainerFull from "../../../../components/layout/PageContainerFull";
import Slidebar from "../../../common/layout/Slidebar";
import SalaIdInput from "../../../common/components/SalaIdInput";
import FormGroup from "../../../../components/forms/FormGroup";
import Button from "../../../../components/forms/Button";
import PageSection from "../../../../components/layout/PageSection";
import PrinterStatusSelect from "../../../common/components/selects/PrinterStatusSelect";
import { Apis } from "../../../../util/Apis";
import { SiteContext } from "../../../common/context/SiteContext";
import CustomerSearchInputV2 from "../../../common/components/CustomerSearchInputV2";
import CustomerLink from "../../../../components/util/CustomerLink";
import CouponTypeSelectV2 from "../../../common/components/selects/CouponTypeSelectV2";
import AsyncBlock from "../../../../components/forms/AsyncBlock";
import ReactTable from "../../../../components/util/ReactTable";
import { FlexLayout } from "../../../../components/flex/Flex";
import { errorToast, successToast } from "../../../../components/layout/ToastStyles";
import useToggle from "../../../../hooks/useToggle";
import useGet from "../../../../hooks/useGet";

const CustomDropDown = styled(DropdownButton)`
  button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 10px;
    background: #F8F9FA;
    border: 1px solid #F8F9FA;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }
`

const TareasImpresion = () => {

  const { salaId } = useContext(SiteContext)
  const { isOpen: slideOpen, setIsOpen: setSlideOpen } = useToggle({})
  const [params, setParams] = useState({ salaId: salaId })
  const { register, control, handleSubmit } = useForm();

  const { loading, data, fetchData, error } = useGet({
    url: Apis.PC + '/coupon-print-job/report',
    initialValue: [],
    params
  })

  const getData = (params) => {
    params.state = params.state === "TODOS" ? null : params.state
    params.couponTypeId = params.couponTypeId === '' ? null : params.couponTypeId
    setParams(params)
    fetchData(params)
  }

  const retry = (id) => {
    return axios.post(Apis.PRINT + '/printjobs/' + id + '/retry')
    //return axios.post(Apis.PC + '/coupon-print-job/' + id + '/retry?computerId=62&printerId=1288')
      .then(() => {
        successToast("Operacion realizada")
      })
      .catch(err => {
        errorToast(err.response.data.message, 4000)
      });
  }

  const fullClient = useCallback(props => <CustomerLink item={props.row.original} />, []);
  const dateValue = useCallback(props => <Moment format={'DD/MM/YYYY HH:mm'}>{props.row.original.createdAt}</Moment>, []);
  const message = useCallback(props => <>{props.row.original.message === '' ? '--' : props.row.original.message}</>, []);

  const actionValue = useCallback(props => (
    <FlexLayout justifyContent='center'>
      <CustomDropDown
        variant='light'
        align="end"
        size="sm"
        title={'Acciones'}
      >
        {(props.row.original.state === null || props.row.original.state === 'ERROR') &&
          <Dropdown.Item>
            <FlexLayout alignItems='center' gap='4px' onClick={() => retry(props.row.original.printJobId)}>
              <BsPrinter />
              <span>Reintentar impresión</span>
            </FlexLayout>
          </Dropdown.Item>
        }
      </CustomDropDown>
    </FlexLayout>
  ), []);

  const columns = useMemo(() => [
    {
      header: 'ID',
      accessorKey: 'id',
      enableGlobalFilter: false,
      enableSorting: false,
      meta: {
        headerClassName: 'text-center font-xsmall',
        columnClassName: 'text-center font-xsmall',
      },
    },
    {
      header: 'Cliente',
      id: 'fullname',
      accessorFn: cell => `${cell.firstName} ${cell.lastName1}`,
      meta: {
        headerClassName: 'text-left',
        columnClassName: 'text-left',
      },
      cell: fullClient
    },
    {
      header: 'Promoción',
      accessorKey: 'name',
      meta: {
        headerClassName: 'text-left',
        columnClassName: 'text-left',
      },
    },
    {
      header: 'Cantidad',
      accessorKey: 'quantity',
      meta: {
        headerClassName: 'text-center',
        columnClassName: 'text-center',
      }
    },
    {
      header: 'Fecha',
      accessorKey: 'createdAt',
      meta: {
        headerClassName: 'text-center',
        columnClassName: 'text-center',
      },
      cell: dateValue
    },
    {
      header: 'Estado',
      accessorKey: 'state',
      meta: {
        headerClassName: 'text-center',
        columnClassName: 'text-center',
      }
    },
    {
      header: 'Mensaje',
      accessorKey: 'message',
      enableGlobalFilter: false,
      enableSorting: false,
      meta: {
        headerClassName: 'text-left font-small',
        columnClassName: 'text-left font-small',
      },
      cell: message
    },
    {
      header: 'Acciones',
      accessorKey: 'acciones',
      enableGlobalFilter: false,
      enableSorting: false,
      meta: {
        headerClassName: 'text-center',
        columnClassName: 'text-center',
      },
      cell: actionValue
    }
  ], [actionValue, dateValue, fullClient, message])

  return (
    <PageContainerFull>

      <Slidebar visible={slideOpen} onHide={() => setSlideOpen(false)}>
        <div style={{ padding: '16px' }}>
          <SalaIdInput {...register("salaId")} />
          <FormGroup>
            <label>Cliente</label>
            <Controller name="customerId" control={control} render={({ field }) => <CustomerSearchInputV2 {...field} />} />
          </FormGroup>

          <FormGroup>
            <label>Tipo de Cupón</label>
            <Controller
              name="couponTypeId"
              control={control}
              render={({ field }) => <CouponTypeSelectV2 {...field} />}
            />
          </FormGroup>

          <FormGroup>
            <label>Estado de Cupón</label>
            <PrinterStatusSelect {...register("state")} />
          </FormGroup>

          <Button variant={'primary'} title='Buscar' onClick={handleSubmit(getData)} />
        </div>
      </Slidebar>

      <PageSection>
        <Title text="Tareas de impresión" type={'page'} />
        <AsyncBlock loading={loading} error={error}>
          <ReactTable
            customSearch
            columns={columns}
            data={data || []}
            filterOnclick={() => setSlideOpen(!slideOpen)}
            noData='No hay resultados para este filtro'
            pageSize={100}
          />
        </AsyncBlock>
      </PageSection>
    </PageContainerFull>
  )
}

export default TareasImpresion;
