import React, { useCallback, useContext, useMemo, useState } from "react";
import { Title } from "@wargostec/react-components";
import { useHistory } from "react-router";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi2";
import moment from "moment";
import Moment from "react-moment";

import PageSection from "../../../../../components/layout/PageSection";
import { Apis } from "../../../../../util/Apis";
import Button from "../../../../../components/forms/Button";
import PageContainerFull from "../../../../../components/layout/PageContainerFull";
import Input, { DatePickerCustom } from "../../../../../components/forms/FormEl";
import { SiteContext } from "../../../../common/context/SiteContext";
import FormGroup from "../../../../../components/forms/FormGroup";
import Slidebar from "../../../../common/layout/Slidebar";
import LevelSelect from "../../../../common/components/selects/LevelSelect";
import SegmentCircle from "./SegmentCircle";
import { FlexLayout } from "../../../../../components/flex/Flex";
import CardOverview, { CardOverviewContainer } from "../../../../../components/layout/CardOverview";
import AsyncBlock from "../../../../../components/forms/AsyncBlock";
import { NumberRound } from "../../../../../components/util/NumberUtil";
import ReactTable from "../../../../../components/util/ReactTable";
import useToggle from "../../../../../hooks/useToggle";
import useGet from "../../../../../hooks/useGet";

const CustomerList = () => {
  const history = useHistory()
  const { salaId } = useContext(SiteContext)
  const { isOpen, toggler } = useToggle({})

  const defaultParams = { salaId, page: 0, size: 20, term: '', ini: '', fin: '' }

  const genderParams = {
    enabled: true,
    ini: moment(new Date(2000, 0, 1)).format('YYYY-MM-DD'),
    fin: moment(new Date()).format('YYYY-MM-DD')
  }

  const { register, handleSubmit } = useForm({ defaultValues: defaultParams });
  const [params, setParams] = useState(defaultParams)

  const { data, loading, fetchData, error } = useGet({
    url: Apis.PC + '/customers/stadistics',
    params,
  })

  const { data: levels } = useGet({
    url: Apis.PC + `/customer-segments`,
    initialValue: []
  })

  const { data: levelsGrouped, loading: loadingGrouped, error: errorGrouped } = useGet({
    url: Apis.PC + `/customers/levels-grouped`,
    initialValue: [],
    select: (data) => {
      data[0] !== null && data.push({ levelName: 'TODOS', levelId: '', quantity: data.filter(item => item !== null).reduce((s, a) => s + a.quantity, 0) })
      return data
    }
  });

  const { data: customers } = useGet({
    url: Apis.PC + '/customers/genders-by-salaid',
    initialValue: [],
    params: genderParams
  });

  const getData = async (params) => {
    await fetchData(params)
    setParams(params)
  }

  const changePage = async (page) => {
    if (page >= 0) {
      let newParams = { ...params, page }
      await fetchData(newParams)
      setParams(newParams)
    }
  }

  const download = () => {
    let link = Apis.PC + '/customers/report.xlsx?salaId=' + salaId;
    if (params.ini) link = link + '&ini=' + params.ini
    if (params.fin) link = link + '&fin=' + params.fin
    window.location.href = link;
  }

  const handleSearch = async e => {
    let newParams = { ...params, term: e.target.value }
    await fetchData(newParams)
    setParams(newParams)
  }

  const lastNameValue = useCallback(props => (props.row.original.lastName1 !== null ? props.row.original.lastName1 : '') + ' ' + (props.row.original.lastName2 !== null ? props.row.original.lastName2 : ''), []);

  const numberValue = useCallback(props => <NumberRound>{props.renderValue()}</NumberRound>, []);

  const levelValue = useCallback(props => <>{
    <FlexLayout alignItems='center' justifyContent='flex-end' gap='4px'>
      <span>{props.renderValue() === null ? '-' : props.renderValue()}</span>
      <SegmentCircle levelId={props.row.original.levelId} array={levels} />
    </FlexLayout>
  }</>, [levels]);

  const rankADTValue = useCallback(props => props.renderValue() !== null ? props.renderValue() : '--', []);

  const dateValue = useCallback(props => <Moment format={'YYYY-MM-DD'}>{props.renderValue()}</Moment>, []);

  const actionValue = useCallback(props => <>{
    <FlexLayout justifyContent='center'>
      <Button variant={'light'} size='xs'
        onClick={() => history.push('customer-info/' + props.renderValue())}>Detalle</Button>
      <Button variant={'light'} size='xs'
        onClick={() => history.push(`/crm/edit-customer/${props.renderValue()}`)}>Editar</Button>
    </FlexLayout>
  }</>, [history]);

  const columns = useMemo(
    () => [
      {
        header: 'Nombre',
        accessorKey: 'firstName'
      },
      {
        header: 'Apellido',
        id: 'lastName',
        accessorFn: cell => `${cell.lastName1} ${cell.lastName2}`,
        cell: lastNameValue
      },
      {
        header: 'Nivel',
        accessorKey: 'levelName',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: levelValue
      },
      {
        header: 'Documento',
        accessorKey: 'document',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header:
          <OverlayTrigger
            placement={'top'}
            overlay={
              <Tooltip>
                <>
                  <strong>Producción promedio teórico diario</strong>
                  <br />
                  <span>Hace referencia a la producción que se espera que el jugador gane en promedio por día</span>
                </>
              </Tooltip>
            }
          >
            <FlexLayout alignItems='center' justifyContent='flex-end' gap='4px' style={{ display: 'inline-flex' }}>
              <span>ADT</span>
              <BsFillInfoCircleFill size={12} />
            </FlexLayout>
          </OverlayTrigger>,
        accessorKey: 'adt',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
        cell: numberValue
      },
      {
        header:
          <OverlayTrigger
            placement={'top'}
            overlay={
              <Tooltip>
                <>
                  <strong>Producción promedio real diaria</strong>
                  <br />
                  <span>Hace referencia a la producción real que un jugador gana en promedio por día</span>
                </>
              </Tooltip>
            }
          >
            <FlexLayout alignItems='center' justifyContent='flex-end' gap='4px' style={{ display: 'inline-flex' }}>
              <span>ADW</span>
              <BsFillInfoCircleFill size={12} />
            </FlexLayout>
          </OverlayTrigger>,
        accessorKey: 'adw',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
        cell: numberValue
      },
      {
        header: 'Ap.Prom',
        accessorKey: 'apProm',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
        cell: numberValue
      },
      {
        header: 'Rank ADT',
        accessorKey: 'rankAdt',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
        cell: rankADTValue
      },
      {
        header: 'Registro',
        accessorKey: 'createdAt',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: dateValue
      },
      {
        header: 'Opciones',
        accessorKey: 'id',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        enableGlobalFilter: false, // para deshabilitar el filter
        enableSorting: false, // para deshabilitar el sort
        cell: actionValue
      },
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , []
  )

  return (
    <PageContainerFull>
      <Slidebar visible={isOpen} onHide={toggler}>
        <div style={{ padding: '16px' }}>
          {levels.length > 0 && <FormGroup>
            <label>Nivel</label>
            <LevelSelect {...register("level")} />
          </FormGroup>}
          <FormGroup>
            <label>Desde</label>
            <DatePickerCustom {...register("ini")} />
          </FormGroup>
          <FormGroup>
            <label>Hasta</label>
            <DatePickerCustom {...register("fin")} />
          </FormGroup>
          <Button variant={'primary'} title='Buscar' onClick={handleSubmit(getData)} />
        </div>
      </Slidebar>

      <PageSection>
        <Title text="Clientes" type={'page'} />
        <AsyncBlock loading={loadingGrouped} error={errorGrouped}>
          <CardOverviewContainer>
            {levelsGrouped.filter(i => i !== null).map(item => {
              return (
                <CardOverview
                  key={item.levelId}
                  title={item.quantity}
                  subtitle={item.levelName}
                  bgColor={'#e5e5e5'}
                />
              )
            })
            }
          </CardOverviewContainer>
        </AsyncBlock>

        <FlexLayout margin='0 0 16px' alignItems='center'>
          <FlexLayout >
            <Button variant={'primary'} onClick={() => changePage(params.page - 1)} disabled={params.page === 0}>
              <HiChevronLeft />
            </Button>
            <Button variant={'primary'} onClick={() => changePage(params.page + 1)} disabled={(params.page + 1) * params.size >
              customers?.find(item => item.name === 'Total')?.count}>
              <HiChevronRight />
            </Button>
          </FlexLayout>
          <Input placeholder='Filtrar por nombre' onChange={handleSearch} />
        </FlexLayout>
        <AsyncBlock loading={loading} error={error}>
          <ReactTable
            helperText={`Numero de clientes: ${customers?.find(item => item.name === 'Total')?.count}`}
            columns={columns}
            data={data || []}
            filterDownload={download}
            filterAdd={() => history.push(`/crm/new-customer`)}
            filterOnclick={toggler}
            noData='No existen resultados para el filtro seleccionado'
            sortArray={[{ id: 'firstName', asc: false }]}
            pageSize={20}
          />
        </AsyncBlock>

      </PageSection>
    </PageContainerFull>
  )
}

export default CustomerList;
