import React, {useContext, useEffect} from "react";
import {Apis} from "../../../../util/Apis";
import Card from "../../../../components/forms/Card";
import Table from "react-bootstrap/Table";
import useFetch2 from "../../../../hooks/useFetch2";
import Moment from "react-moment";
import {LoadingTableV2} from "../../../../components/forms/Tables";
import {SiteContext} from "../../../common/context/SiteContext";

const SorteoHistory = ({id, setTitle}) => {

  const {salaId} = useContext(SiteContext)

  const {loading, data, error} = useFetch2(Apis.PC + '/sorteo-trace/by-sorteo?sorteoId=' + id, []);

  const {
    data: levels,
  } = useFetch2(Apis.PC + `/customer-segments`, []);

  useEffect(() => {
    setTitle("Historial de Ganadores");
  }, []);

  return (
    <Card padding>
      <LoadingTableV2 loading={loading} error={error} data={data} noDataText={'Aún no hay historial de ganadores'}>
        <Table striped hover>
          <thead>
          <tr>
            <th>Fecha/Hora</th>
            <th>Cliente</th>
            { levels &&
              levels.length > 0 &&
              <th>Cupón</th>
            }
            {/* <th>Participio con</th> */}
            <th>Nivel</th>
            <th>Total en anfora</th>
            <th/>
          </tr>
          </thead>
          <tbody>
          {data && data.map((item, index) =>
            <tr key={item.sorteoId} className={index === data.length - 1 ? 'alert-success' : null}>
              <td><Moment format={'DD/MM/YYYY HH:mm'}>{item.createdAt}</Moment></td>
              <td>{item.customer}</td>
              { levels &&
              levels.length > 0 &&
              <td>{item.levelName ? item.levelName : ''}</td>
            }
              <td>{item.winnerCouponId}</td>
              {/* <td>{item.totalCoupons}</td> */}
              <td>{item.totalCoupons}</td>
            </tr>
          )}
          </tbody>
        </Table>
      </LoadingTableV2>
    </Card>
  );
};

export default SorteoHistory;
