import React, {useContext, useEffect, useState} from 'react';
import Slidebar from "../../../common/layout/Slidebar";
import SalaIdInput from "../../../common/components/SalaIdInput";
import FormGroup from "../../../../components/forms/FormGroup";
import {DatePickerCustom} from "../../../../components/forms/FormEl";
import {Controller, useForm} from "react-hook-form";
import CustomerSearchInputV2 from "../../../common/components/CustomerSearchInputV2";
import Button, {ButtonBox} from "../../../../components/forms/Button";
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import {SiteContext} from "../../../common/context/SiteContext";
import {Title} from "@wargostec/react-components";
import PageSection from "../../../../components/layout/PageSection";
import Card from "../../../../components/forms/Card";
import Table from "react-bootstrap/Table";
import Moment from "react-moment";
import {getCasinoDay} from "../../../../components/util/CasinoDates";
import axios from "axios";
import useDeleteModal from "../../../../modals/useDeleteModal";
import DeleteGenericModal from "../../../../modals/DeleteGenericModal";
import {LoadingTableV2} from "../../../../components/forms/Tables";
import {FaDownload} from "react-icons/fa";
import {Container} from 'react-bootstrap';
import {AiFillDelete} from "react-icons/ai";
import useToggle from "../../../../hooks/useToggle";

const RedeemPoints = () => {

  const {salaId} = useContext(SiteContext)
  const {isOpen: slideOpen, toggler} = useToggle({});

  const casinoDay = getCasinoDay()
  const defaultParams = {ini: casinoDay, fin: casinoDay}

  const [params, setParams] = useState(defaultParams)
  const {data, loading, fetchData} = useFetch2(Apis.PC + '/redeems', [], params)
  const {register, control, handleSubmit} = useForm({defaultValues: defaultParams});

  const changeState = id => axios.post(Apis.PC + '/redeems/' + id + '/change-state?state=ENTREGADO')

  const getData = (params) => {
    setParams(params)
  }

  const {
    wrapper: wrapper1,
    openDeleteModal
  } = useDeleteModal({
    text: '¿Desea canjear este producto?',
    action: changeState,
    onRefresh: fetchData
  })

  const deleteElement = id => axios.post(Apis.PC + '/redeems/' + id + '/cancel')

  const {
    wrapper: wrapper2,
    openDeleteModal: openDeleteModal2
  } = useDeleteModal({
    text: '¿Eliminar canje?, sus puntos seran devueltos',
    action: deleteElement,
    onRefresh: fetchData
  })


  const download = () => {
    window.location.href = Apis.PC + `/redeems/report.xlsx?salaId=${salaId}&ini=${params.ini}&fin=${params.fin}`;
  }

  useEffect(() => {
    fetchData()
  }, [params])

  const typeRedeem = (type) => {
    switch (type) {
      case 1:
        return 'Puntos'
      case 2:
        return 'Cupones'
      default:
        return 'Cupones'
    }
  }

  return (
    <Container fluid>
      <Slidebar visible={slideOpen} onHide={toggler}>
        <div style={{padding: '16 px'}}>
          <SalaIdInput {...register("salaId")}/>
          <FormGroup>
            <label>Desde</label>
            <DatePickerCustom {...register("ini")}/>
          </FormGroup>
          <FormGroup>
            <label>Hasta</label>
            <DatePickerCustom {...register("fin")}/>
          </FormGroup>
          <FormGroup>
            <label>Cliente</label>
            <Controller name="customerId" control={control} render={({field}) => <CustomerSearchInputV2 {...field} />}/>
          </FormGroup>

          <Button variant={'primary'} title='Buscar' onClick={handleSubmit(getData)}/>
        </div>
      </Slidebar>

      <PageSection>
        <Title text="Reporte de canjes" type={'page'}/>
        <ButtonBox>
          <Button variant={'primary'} title={'Filtrar'} onClick={toggler}/>
          <Button variant={'primary'} onClick={download}>
            <FaDownload/> Descargar
          </Button>
        </ButtonBox>
      </PageSection>

      <PageSection>
        <Card>
          <LoadingTableV2 loading={loading} data={data} noDataText={'No hay canjes generados'}>
            <Table striped hover>
              <thead>
              <tr>
                <th className={"text-left"}>Cliente</th>
                <th className={"text-left"}>Nivel</th>
                <th className={"text-left"}>Producto</th>
                <th className={"text-right"}>Valor de canje</th>
                <th className={"text-center"}>Tipo de canje</th>
                <th className={"text-center"}>Fecha de canje</th>
                <th className={"text-center"}>Hora de canje</th>
                <th className={"text-center"}>Canjeado por</th>
                <th className={"text-left"}></th>
              </tr>
              </thead>
              <tbody>
              {data && data.map(item =>
                <tr key={item.id}>
                  <td className={"text-left"}>{item.customerName}</td>
                  <td className={"text-left"}>{item.levelName}</td>
                  <td className={"text-left"}>{item.productName ? item.productName : '---'}</td>
                  <td className={"text-right"}>{item.exchangeValue ? item.exchangeValue : '---'}</td>
                  <td className={"text-center"}>{typeRedeem(item.type)}</td>
                  <td className={"text-center"}><Moment format={'DD/MM/YYYY'}>{item.createdAt}</Moment></td>
                  <td className={"text-center"}><Moment format={'HH:mm'}>{item.createdAt}</Moment></td>
                  <td className={"text-center"}>{item.employeeName}</td>
                  <td className={"text-left"}>
                    <ButtonBox>
                      <Button size={'s'} variant={item.state === 'ENTREGADO' ? 'success' : 'light'}
                              onClick={() => openDeleteModal(item.id)}>
                        {item.state}
                      </Button>
                      {
                        item.state === 'GENERADO' &&
                        <Button size={'s'} onClick={() => openDeleteModal2(item.id)} variant={'danger'}>
                          <AiFillDelete fontSize={'22px'}></AiFillDelete>
                        </Button>
                      }
                    </ButtonBox>
                  </td>
                </tr>
              )}
              </tbody>
            </Table>
          </LoadingTableV2>
        </Card>
      </PageSection>

      <DeleteGenericModal {...wrapper1}/>
      <DeleteGenericModal {...wrapper2}/>
    </Container>
  );
};

export default RedeemPoints;
