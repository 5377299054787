import React from "react"
import {Route, Switch} from "react-router"
import EventList from "./pages/bingo/gestionEventos/EventList";
import BingoCardList from "./pages/bingo/generacionCartillas/CardList";
import BingosHome from "./BingosHome";
import EventEdit from "./pages/bingo/gestionEventos/EventEdit";
import EventNew from "./pages/bingo/gestionEventos/EventNew";
import SerieList from "./pages/bingo/gestionSeries/SerieList";
import SorteoBingoList from "./pages/bingo/gestionPartidas/SorteoBingoList";
import VentaCardList from "./pages/bingo/ventaCartillas/VentaCardList";
import JackpotList from "./pages/bingo/gestionJackpots/JackpotList";
import JackpotNew from "./pages/bingo/gestionJackpots/JackpotNew";
import JackpotEdit from "./pages/bingo/gestionJackpots/JackpotEdit";
import RangeList from "./pages/bingo/gestionRangos/RangeList";
import SorteoJackpot from "./pages/bingo/gestionPartidas/SorteoJackpot";
import SalesProfit from "./pages/bingo/gestionVentas/SalesProfit";
import SorteoEdit from "./pages/bingo/gestionPartidas/SorteoEdit";
import SorteoNew from "./pages/bingo/gestionPartidas/SorteoNew";

const BingosRoutes = () => {
  const parentRoute = '/bingos'
  return (
    <Switch>
      <Route path={parentRoute + '/eventos'} component={EventList}/>
      <Route path={parentRoute + '/new-event'} component={EventNew}/>
      <Route path={parentRoute + '/edit-event/:id'} component={EventEdit}/>
      <Route path={parentRoute + '/jackpots'} component={JackpotList}/>
      <Route path={parentRoute + '/new-jackpots'} component={JackpotNew}/>
      <Route path={parentRoute + '/edit-jackpots/:id'} component={JackpotEdit}/>
      <Route path={parentRoute + '/ranges'} component={RangeList}/>
      <Route path={parentRoute + '/sales-profit'} component={SalesProfit}/>
      <Route path={parentRoute + '/partidas'} component={SorteoBingoList}/>
      <Route path={parentRoute + '/edit-partidas/:id'} component={SorteoEdit}/>
      <Route path={parentRoute + '/new-partidas'} component={SorteoNew}/>
      <Route path={parentRoute + '/sorteo-jackpots/:id'} component={SorteoJackpot}/>
      <Route path={parentRoute + '/stock'} component={SerieList}/>
      <Route path={parentRoute + '/cartilllas'} component={BingoCardList}/>
      <Route path={parentRoute + '/venta'} component={VentaCardList}/>
      <Route path="/" component={BingosHome}/>
    </Switch>
  )
}

export default BingosRoutes;
