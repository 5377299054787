import React from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {FaCreditCard, FaQrcode, FaRegClock, FaRobot, FaUserTag} from 'react-icons/fa'
import moment from "moment";
import {FaTicket} from "react-icons/fa6";

const setMethodIcon = (icon) => {
  switch (icon) {
    case 1:
      return <FaUserTag/>
    case 2:
      return <FaRobot/>
    case 3:
      return <FaQrcode/>
    case 4:
      return <FaRegClock/>
    case 5:
      return <FaCreditCard/>
    case 6:
      return <FaTicket/>
    default:
      return '-'
  }
}

const setMethodText = (text, loginTime) => {
  const loginTimeStr = loginTime ? " " + moment(loginTime).format('DD/MM/YY HH:mm') : ""

  switch (text) {
    case 1:
      return 'Logueado por operador' + loginTimeStr
    case 2:
      return 'Logueado de forma automatica' + loginTimeStr
    case 3:
      return 'Logueado por código QR' + loginTimeStr
    case 4:
      return 'Logueado posteriormente' + loginTimeStr
    case 5:
      return 'Logueado por tarjeta' + loginTimeStr
    case 6:
      return 'Logueado por ticket' + loginTimeStr
    default:
      return 'No logueado'
  }
}
const MethodOverlay = ({method, loginTime}) => {
  return (
    <OverlayTrigger
      placement={'top'}
      overlay={
        <Tooltip>{setMethodText(method, loginTime)}</Tooltip>
      }
    >
      <span>{setMethodIcon(method)}</span>
    </OverlayTrigger>
  )
}

export default MethodOverlay
