import React, { useState } from 'react'
import Table from "react-bootstrap/Table";
import Moment from "react-moment";
import { BsFilter } from 'react-icons/bs';
import { MdLocalPlay } from 'react-icons/md';
import { Title } from "@wargostec/react-components";
import PageSection from "../../../../components/layout/PageSection";
import FormGroup from "../../../../components/forms/FormGroup";
import Button, { ButtonBox } from "../../../../components/forms/Button";
import SalaIdInput from "../../../common/components/SalaIdInput";
import { Controller, useForm } from "react-hook-form";
import Slidebar from "../../../common/layout/Slidebar";
import PageContainerFull from "../../../../components/layout/PageContainerFull";
import { Apis } from "../../../../util/Apis";
import SwitchToggle from "../../../../components/layout/SwitchToggle";
import { serializeDownload } from "../../../../components/util/SerializeParams";
import useSortableData, { ColumnSortable, getClassNamesFor } from "../../../../hooks/useSortableData";
import Card from "../../../../components/forms/Card";
import DateTimeV2 from "../../../../components/layout/DateTimeV2";
import getCasinoRange from "../../../../components/util/CasinoDates";
import CustomerLink from '../../../../components/util/CustomerLink';
import { AiOutlineCloudDownload } from 'react-icons/ai';
import AsyncBlock from '../../../../components/forms/AsyncBlock';
import EmptyState from '../../../common/components/EmptyState';
import CouponTypeSelectV2 from "../../../common/components/selects/CouponTypeSelectV2";
import useToggle from '../../../../hooks/useToggle';
import useGet from '../../../../hooks/useGet';

const CouponGenerados = () => {
  const { ini, fin } = getCasinoRange();
  let defaultParams = { onlyPresent: false, promoActive: true, ini: ini, fin: fin }

  const { isOpen: slideOpen, setIsOpen: setSlideOpen } = useToggle({});
  const [params, setParams] = useState()

  const { loading, data, fetchData, error } = useGet({
    url: Apis.PC + `/coupon/group-by-customer-time`,
    initialValue: [],
    auto: false
  })

  const { register, handleSubmit, control } = useForm({ defaultValues: defaultParams });

  const { items, requestSort, sortConfig } = useSortableData(data);


  const getData = (params) => {
    if (params.couponTypeId) {
      setParams(params)
      fetchData(params)
    }
  }

  const download = () => {
    const link = Apis.PC + `/coupon/group-by-customer-time.xlsx?${serializeDownload(params)}`;
    window.location.href = link;
  }

  return (
    <PageContainerFull>

      <Slidebar visible={slideOpen} onHide={() => setSlideOpen(false)}>
        <div style={{ padding: '16px' }}>
          <SalaIdInput {...register("salaId")} />

          <FormGroup>
            <label>Promoción</label>
            <Controller
              name="couponTypeId"
              control={control}
              render={({ field }) => <CouponTypeSelectV2 {...field} />}
            />
          </FormGroup>
          <FormGroup>
            <label>Desde</label>
            <Controller name="ini" control={control} render={({ field }) => <DateTimeV2 {...field} />} />
          </FormGroup>
          <FormGroup>
            <label>Hasta</label>
            <Controller name="fin" control={control} render={({ field }) => <DateTimeV2 {...field} />} />
          </FormGroup>
          <FormGroup>
            <SwitchToggle label={"Presente ahora"} id={"onlyPresent"} {...register("onlyPresent")} />
          </FormGroup>
          <FormGroup>
            <SwitchToggle label={"Expirados"} id={"expired"} {...register("expired")} />
          </FormGroup>
          <Button variant={'primary'} title='Buscar' onClick={handleSubmit(getData)} />
        </div>
      </Slidebar>

      <PageSection>
        <Title text="Cupones generados" type={'page'} />
        <ButtonBox>
          <Button variant='primary' onClick={() => setSlideOpen(!slideOpen)}>
            <BsFilter />
            <span>Filtrar</span>
          </Button>
          <Button variant='primary' onClick={download}>
            <AiOutlineCloudDownload />
            <span>Descargar</span>
          </Button>
        </ButtonBox>
      </PageSection>

      <PageSection>
        <AsyncBlock loading={loading} error={error}>
          {data?.length === 0 ?
            <Card padding>
              <EmptyState
                title={'No hay resultados para este filtro'}
                subtitle={'Puede realizar una nueva busqueda haciendo click en Filtrar.'}
                icon={<MdLocalPlay size={32} />}
              />
            </Card>
            :
            <Card>
              <Table striped hover>
                <thead>
                  <tr>
                    <th>
                      <ColumnSortable type="button"
                        onClick={() => requestSort('rank')}
                        className={getClassNamesFor('rank', sortConfig)}>
                        Rank
                      </ColumnSortable>
                    </th>
                    <th>
                      <ColumnSortable type="button"
                        onClick={() => requestSort('firstName')}
                        className={getClassNamesFor('firstName', sortConfig)}>
                        Cliente
                      </ColumnSortable>
                    </th>
                    <th>
                      <ColumnSortable type="button"
                        onClick={() => requestSort('document')}
                        className={getClassNamesFor('document', sortConfig)}>
                        Documento
                      </ColumnSortable>
                    </th>
                    <th>
                      <ColumnSortable type="button"
                        onClick={() => requestSort('total')}
                        className={getClassNamesFor('total', sortConfig)}>
                        Total Acumulado
                      </ColumnSortable>
                    </th>
                    <th>
                      <ColumnSortable type="button"
                        onClick={() => requestSort('printed')}
                        className={getClassNamesFor('printed', sortConfig)}>
                        Impresos
                      </ColumnSortable>
                    </th>
                    <th>
                      <ColumnSortable type="button"
                        onClick={() => requestSort('available')}
                        className={getClassNamesFor('available', sortConfig)}>
                        Disponibles
                      </ColumnSortable>
                    </th>
                    <th>
                      <ColumnSortable type="button"
                        onClick={() => requestSort('lastCouponDate')}
                        className={getClassNamesFor('lastCouponDate', sortConfig)}>
                        Último Cupón
                      </ColumnSortable>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {items.map((item) =>
                    <tr key={item.id}>
                      <td>{item.rank}</td>
                      <td>{<CustomerLink item={item} />}</td>
                      <td>{item.document}</td>
                      <td>{item.total}</td>
                      <td>{item.printed}</td>
                      <td style={{ fontWeight: "bold" }}>{item.available}</td>
                      <td>
                        <Moment format={'DD/MM/YY HH:mm'}>{item.lastCouponDate}</Moment>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Card>
          }
        </AsyncBlock>
      </PageSection>
    </PageContainerFull>
  )
}

export default CouponGenerados;
