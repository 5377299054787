import React, {useContext} from "react";
import {SiteContext} from "../../../common/context/SiteContext";
import {useHistory} from "react-router";
import {Controller, useForm} from "react-hook-form";
import axios from "axios";
import {Apis} from "../../../../util/Apis";
import PageContainer from "../../../../components/layout/PageContainer";
import PageSection from "../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import FormGroup from "../../../../components/forms/FormGroup";
import Input from "../../../../components/forms/FormEl";
import Button, {ButtonBox} from "../../../../components/forms/Button";
import FondoSelect from "../../../common/components/selects/FondoSelect";
import {errorToast, successToast} from "../../../../components/layout/ToastStyles";
import Card from "../../../../components/forms/Card";
import MachineSearchInput from "../../../common/components/MachineSearchInput";

const CBillOp = () => {
  const {salaId} = useContext(SiteContext)
  const history = useHistory()
  let defaultParams = {salaId: salaId, enabled: true}
  const {register, handleSubmit, setValue, control} = useForm({defaultValues: defaultParams});

  const onCreate = (params) => {
    params.salaId = salaId
    axios.post(Apis.CAJA + '/operation-billetero', params)
      .then(res => {
        history.push('/caja/cbill-create')
        successToast()
        clear()
      }).catch(err => {
        console.log(err)
        errorToast()
      })
  }

  const clear = () => {
    setValue('confId', "")
    setValue('amount', "")
  }


  return (
    <PageContainer>
      <PageSection>
        <Title text="Billetero" type={'page'} />
        <Card padding>
          <FormGroup>
            <label>Máquina</label>
            <Controller
              name="confId"
              control={control}
              render={({field}) => <MachineSearchInput {...field} />}
            />
          </FormGroup>
          <FormGroup>
            <label> Fondo </label>
            <FondoSelect {...register("fondoId")} />
          </FormGroup>
          <FormGroup>
            <label>Monto</label>
            <Input {...register("amount")} />
          </FormGroup>
          <ButtonBox>
            <Button title={"Guardar"} variant={'primary'} onClick={handleSubmit(onCreate)} />
          </ButtonBox>
        </Card>
      </PageSection>
    </PageContainer>
  )
}

export default CBillOp;
