import React, {useEffect} from 'react';
import {Apis} from "../../../../util/Apis";
import useFetch2 from "../../../../hooks/useFetch2";
import {TextArea} from "../../../../components/forms/FormEl";

const PrintJobContent = ({id, setAction, setTitle}) => {
    const {loading, data} = useFetch2(Apis.PRINT + '/printjobs/' + id);

    useEffect(() => {
        setTitle('Contenido de impresión');
    });

    return (
        <React.Fragment>
            {!loading && data &&
                <>
                    <TextArea value={data.content}/>
                </>
            }
        </React.Fragment>
    );
};

export default PrintJobContent;
