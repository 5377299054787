import React, {useEffect} from 'react';
import {Apis} from "../../../../../util/Apis";
import useFetch2 from "../../../../../hooks/useFetch2";
import axios from "axios";
import SiteSalaForm from "./SiteSalaForm";

const SiteSalaEdit = ({id, setAction, setTitle}) => {

  const {loading, data} = useFetch2(Apis.CUSTOMERAPP + '/site-sala/' + id)

  const update = async (params) => {
    await axios.put(Apis.CUSTOMERAPP + '/site-sala/' + id, params)
  }

  useEffect(() => {
    setTitle("Edición de sala")
  }, [])

  return (
    <React.Fragment>
      {data && !loading &&
        <SiteSalaForm value={data} onSubmit={update} setAction={setAction}/>
      }
    </React.Fragment>
  );
};

export default SiteSalaEdit;
