import React, { useContext, useState } from "react";
import MainDashboard from "../../components/layout/MainDashboard";
import CrmRoutes from "./CrmRoutes";
import DropdownMenu from "../../components/layout/DropdownMenu";
import DropdownContainer from "../../components/layout/DropdownContainer";
import { SiteContext } from "../common/context/SiteContext";
import SecDropdownMenuItem from "../../components/layout/SecDropdownItem";
import * as FaIcons from "react-icons/fa";
import * as IoIcons from "react-icons/io";
import * as MdIcons from "react-icons/md";

const CrmMain = () => {

  const baseUrl = '/crm'
  const { moreInfo } = useContext(SiteContext)
  const { user } = moreInfo

  const [isOpen, setIsOpen] = useState(false)
  const toggleMobile = () => {
    setIsOpen(!isOpen)
  }

  return (
    <MainDashboard
      brand={'CLIENTES'}
      username={user?.name}
      isOpen={isOpen}
      toggleMobile={toggleMobile}
      items={
        <DropdownContainer>
          <DropdownMenu title={'Clientes'} icon={<FaIcons.FaUsers />}>
            <SecDropdownMenuItem title={'Lista'} link={baseUrl + '/clientes'} onClick={toggleMobile} />
            <SecDropdownMenuItem title={'Cumpleaños'} link={baseUrl + '/cumpleanos'} />
            <SecDropdownMenuItem title={'Online'} link={baseUrl + '/online'} onClick={toggleMobile} />
            <SecDropdownMenuItem title={'Ranking'} link={baseUrl + '/ranking'} onClick={toggleMobile} />
            <SecDropdownMenuItem title={'Resumen'} link={baseUrl + '/customer-resume'} onClick={toggleMobile} />
            <SecDropdownMenuItem title={'Sesiones'} link={baseUrl + '/sessions'} onClick={toggleMobile} />
            <SecDropdownMenuItem title={'Sesiones x empleado'} link={baseUrl + '/sesiones-por-empleado'} onClick={toggleMobile} />
            <SecDropdownMenuItem title={'Ingresos a sala'} link={baseUrl + '/historial-ingresos'} onClick={toggleMobile} />
            <SecDropdownMenuItem title={'Ocupación por hora'} link={baseUrl + '/ocupación-por-hora'} onClick={toggleMobile} />
            <SecDropdownMenuItem title={'Tarjetas'} link={baseUrl + '/card'} onClick={toggleMobile} />
          </DropdownMenu>

          <DropdownMenu title={'Cupones'} icon={<MdIcons.MdLocalPlay size={16} />}>
            <SecDropdownMenuItem title={'Configuración'} link={baseUrl + '/coupon-types'} onClick={toggleMobile} />
            <SecDropdownMenuItem title={'Carga masiva'} link={baseUrl + '/upload-coupons-list'} onClick={toggleMobile} />
            <SecDropdownMenuItem title={'Cupones otorgados'} link={baseUrl + '/awarded-coupons'} onClick={toggleMobile} />
            <SecDropdownMenuItem title={'Cupones acumulados'} link={baseUrl + '/accumulated-coupons'} onClick={toggleMobile} />
            <SecDropdownMenuItem title={'Cupones generados'} link={baseUrl + '/generated-coupons'} onClick={toggleMobile} />
            <SecDropdownMenuItem title={'Tareas de impresión'} link={baseUrl + '/tareas-impresion'} onClick={toggleMobile} />
            <SecDropdownMenuItem title={'Multiplica'} link={baseUrl + '/coupon-promo'} onClick={toggleMobile} />
            <SecDropdownMenuItem title={'Consulta cupones'} link={baseUrl + '/coupon-search'} onClick={toggleMobile} />
          </DropdownMenu>

          <DropdownMenu title={'Puntos'} icon={<MdIcons.MdStar size={16} />}>
            <SecDropdownMenuItem title={'Configuración'} link={baseUrl + '/puntos-configuracion'} onClick={toggleMobile} />
            <SecDropdownMenuItem title={'Club de puntos'} link={baseUrl + '/segments-list'} onClick={toggleMobile} />
            <SecDropdownMenuItem title={'Multiplica'} link={baseUrl + '/puntos-promociones'} onClick={toggleMobile} />
            <SecDropdownMenuItem title={'Carga masiva'} link={baseUrl + '/upload-points-list'} onClick={toggleMobile} />
            <SecDropdownMenuItem title={'Puntos otorgados'} link={baseUrl + '/puntos-otorgados'} onClick={toggleMobile} />
            <SecDropdownMenuItem title={'Reporte de puntos'} link={baseUrl + '/puntos-balance'} onClick={toggleMobile} />
          </DropdownMenu>

          <DropdownMenu title={'Sorteos'} icon={<FaIcons.FaGift />}>
            <SecDropdownMenuItem title={'Lista de sorteos'} link={baseUrl + '/sorteos'} onClick={toggleMobile} />
            <SecDropdownMenuItem title={'Sorteo masivo'} link={baseUrl + '/sorteos-massive'} onClick={toggleMobile} />
            <SecDropdownMenuItem title={'Ánforas'} link={baseUrl + '/ánforas'} onClick={toggleMobile} />
            <SecDropdownMenuItem title={'Análisis de ganadores'} link={baseUrl + '/analisis-ganador'} onClick={toggleMobile} />
            <SecDropdownMenuItem title={'Estadísticas de sorteos'} link={baseUrl + '/sorteos-statistics'} onClick={toggleMobile} />
          </DropdownMenu>

          <DropdownMenu title={'Promociones'} icon={<IoIcons.IoMdPodium />}>
            <SecDropdownMenuItem title={'Progresivos'} link={baseUrl + '/progressive'} onClick={toggleMobile} />
            <SecDropdownMenuItem title={'Recompensas'} link={baseUrl + '/rewards'} onClick={toggleMobile} />
          </DropdownMenu>

          <DropdownMenu title={'Canjes'} icon={<FaIcons.FaExchangeAlt />}>
            <SecDropdownMenuItem title={'Productos'} link={baseUrl + '/customer-catalog'} onClick={toggleMobile} />
            <SecDropdownMenuItem title={'Reporte de canjes'} link={baseUrl + '/redeem-points'} onClick={toggleMobile} />
            <SecDropdownMenuItem title={'Consultas'} link={baseUrl + '/redeem-custom-points'} onClick={toggleMobile} />
            <SecDropdownMenuItem title={'Canje manual'} link={baseUrl + '/manual-redeem'} onClick={toggleMobile} />
          </DropdownMenu>

          <DropdownMenu title={'Pedidos'} icon={<FaIcons.FaBookReader />}>
            <SecDropdownMenuItem title={'Productos'} link={baseUrl + '/products'} onClick={toggleMobile} />
            <SecDropdownMenuItem title={'Categorias'} link={baseUrl + '/food-categories'} onClick={toggleMobile} />
            <SecDropdownMenuItem title={'Ordenes pendientes'} link={baseUrl + '/pending-orders'} onClick={toggleMobile} />
            <SecDropdownMenuItem title={'Historial de ordenes'} link={baseUrl + '/orders-history'} onClick={toggleMobile} />
            <SecDropdownMenuItem title={'Reporte'} link={baseUrl + '/orders-report'} onClick={toggleMobile} />
          </DropdownMenu>

          <DropdownMenu title={'Aplicación'} icon={<MdIcons.MdAppSettingsAlt />}>
            <SecDropdownMenuItem title={'Notificaciones'} link={baseUrl + '/app-notif-list'} onClick={toggleMobile} />
            <SecDropdownMenuItem title={'Publicidad'} link={baseUrl + '/publicidad'} onClick={toggleMobile} />
            <SecDropdownMenuItem title={'Sitios web'} link={baseUrl + '/sites'} onClick={toggleMobile} />
          </DropdownMenu>
        </DropdownContainer>
      }
    >
      <CrmRoutes />
    </MainDashboard>
  )
}

export default CrmMain;
