import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import Slidebar from "../../../common/layout/Slidebar";
import FormGroup from "../../../../components/forms/FormGroup";
import Button, {ButtonBox} from "../../../../components/forms/Button";
import PageSection from "../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import AsyncBlock from "../../../../components/forms/AsyncBlock";
import Card from "../../../../components/forms/Card";
import EmptyState from "../../../common/components/EmptyState";
import {HiOutlineBanknotes, HiOutlineTicket} from "react-icons/hi2";
import {TbFileSad} from "react-icons/tb";
import CardOverview, {CardOverviewContainer} from "../../../../components/layout/CardOverview";
import {FaSortAmountUpAlt} from "react-icons/fa";
import {numberFormat} from "../../../../components/util/NumberUtil";
import Moment from "react-moment";
import PageContainer from "../../../../components/layout/PageContainer";
import {useForm} from "react-hook-form";
import {DatePickerCustom} from "../../../../components/forms/FormEl";
import useToggle from "../../../../hooks/useToggle";
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import {getDateFilter} from "../../../../components/util/CasinoDates";
import {serializeDownload} from "../../../../util";
import ReactTable from "../../../../components/util/ReactTable";
import {FlexLayout} from "../../../../components/flex/Flex";
import {SiteContext} from "../../../common/context/SiteContext";

const CashBackReport = () => {

  const {salaId} = useContext(SiteContext)
  const {isOpen: slideOpen, toggler} = useToggle({});
  const date = getDateFilter('month')
  let defaultParams = {ini: date.ini, fin: date.fin, salaId: salaId}
  const [params, setParams] = useState(defaultParams)

  const {
    data,
    fetchData,
    loading,
    error
  } = useFetch2(Apis.CAJA + '/operation-ticket-venta/report/by-promo', [], params);
  const {register, handleSubmit} = useForm({defaultParams: params});

  const getData = (params) => {
    setParams(params)
  }

  const getNameValidationType = (type) => {
    switch (type) {
      case 0:
        return 'CashBack'
      case 1:
        return 'Promocional'
      default:
        return 'CashBack'
    }
  }

  const download = () => {
    params.salaId = salaId
    const link = Apis.CAJA + `/operation-ticket-venta/report/by-promo/excel.xlsx?${serializeDownload(params)}`;
    window.location.href = link;
  }

  const createdValue = useCallback(props => <>{
    props.renderValue() ?
      <FlexLayout alignItems='center' justifyContent='center' gap='4px'>
        <Moment format={'YYYY-MM-DD hh:mm a'}>{props.renderValue()}</Moment>
      </FlexLayout>
      :
      <span>---</span>
  }</>, []);

  const validationValue = useCallback(props => <>{
    <div>
      <span>{getNameValidationType(props.row.original.validationType)}</span>
    </div>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }</>, []);

  const amountValue = useCallback(props => <>{
    <div>
      <span>{'S/ ' + numberFormat(props.renderValue())}</span>
    </div>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }</>, []);

  const columns = useMemo(
    () => [
      {
        header: 'Estado',
        accessorKey: 'state',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Código Ticket',
        accessorKey: 'validNumber',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Monto',
        accessorKey: 'amount',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: amountValue
      },
      {
        header: 'Tipo',
        accessorKey: 'validationType',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: validationValue
      },
      {
        header: 'Emitido en',
        accessorKey: 'fondoName',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Responsable',
        accessorKey: 'employeeName',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Fecha de emisión',
        accessorKey: 'createdAt',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: createdValue
      },
      {
        header: 'Canjeado en',
        accessorKey: 'machine',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: (props) => (
          <span>
            {props.renderValue() ? props.renderValue() : 'CAJA'}
          </span>
        )
      },
      {
        header: 'Fecha de canje',
        accessorKey: 'redeemedAt',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: createdValue
      },
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , []
  )

  useEffect(() => {
    if (params) fetchData()
  }, [params])

  return (
    <PageContainer>
      <Slidebar visible={slideOpen} onHide={toggler}>
        <div style={{padding: '16px'}}>
          <FormGroup>
            <label>Fecha de Inicio</label>
            <DatePickerCustom {...register("ini")} />
          </FormGroup>
          <FormGroup>
            <label>Fecha de Fin</label>
            <DatePickerCustom {...register("fin")} />
          </FormGroup>
          <Button variant={'primary'} title='Buscar' onClick={handleSubmit(getData)}/>
        </div>
      </Slidebar>

      <PageSection>
        <Title text={'Venta de tickets promocionales'} type={'page'}/>
        <ButtonBox>
          <Button variant={'primary'} title={'Filtrar'} onClick={toggler}/>
        </ButtonBox>
        <AsyncBlock loading={loading} error={error}>
          {data === null ?
            <Card padding>
              <EmptyState
                title={'Venta de tickets promocionales'}
                subtitle={'Haga click en FILTRAR para realizar una busqueda'}
                icon={<HiOutlineTicket size={32}/>}
              />
            </Card>
            :
            <>
              {data?.data?.length === 0 ?
                <Card padding>
                  <EmptyState
                    title={'Sin resultados'}
                    subtitle={'Haga click en FILTRAR para realizar una nueva busqueda'}
                    icon={<TbFileSad size={32}/>}
                  />
                </Card>
                :
                <>
                  <CardOverviewContainer gridColumns='repeat(2, 1fr)'>
                    <CardOverview
                      title={data?.quantity ?? 0}
                      subtitle='Operaciones'
                      icon={<FaSortAmountUpAlt size={20}/>}
                    />
                    <CardOverview
                      title={'S/ ' + numberFormat(data?.totalAmount)}
                      subtitle='Total'
                      icon={<HiOutlineBanknotes size={20}/>}
                    />
                  </CardOverviewContainer>
                  <Card>
                    <ReactTable
                      columns={columns}
                      filterOnclick={toggler}
                      data={(data && data.data) || []}
                      filterDownload={download}
                      noData='No existen resultados para el filtro seleccionado'
                      pageSize={20}
                    />
                  </Card>
                </>
              }
            </>
          }
        </AsyncBlock>
      </PageSection>
    </PageContainer>
  );
};

export default CashBackReport;
