import React, {useContext, useEffect, useState} from "react";
import {SiteContext} from "../../../common/context/SiteContext";
import {Apis} from "../../../../util/Apis";
import {Controller, useForm} from "react-hook-form";
import Slidebar from "../../../common/layout/Slidebar";
import SalaIdInput from "../../../common/components/SalaIdInput";
import FormGroup from "../../../../components/forms/FormGroup";
import Input from "../../../../components/forms/FormEl";
import Button, {ButtonBox} from "../../../../components/forms/Button";
import PageSection from "../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import Table from "react-bootstrap/Table";
import Moment from "react-moment";
import Card from "../../../../components/forms/Card";
import {numberFormat} from "../../../../components/util/NumberUtil";
import axios from "axios";
import DeleteGenericModal from "../../../../modals/DeleteGenericModal";
import useDeleteModal from "../../../../modals/useDeleteModal";
import CustomerSearchInputV2 from "../../../common/components/CustomerSearchInputV2";
import useFetch2 from "../../../../hooks/useFetch2";
import AsyncBlock from "../../../../components/forms/AsyncBlock";
import CardOverview, {CardOverviewContainer} from "../../../../components/layout/CardOverview";
import {FaSortAmountUpAlt} from "react-icons/fa";
import {HiOutlineBanknotes} from "react-icons/hi2";
import {FlexLayout} from "../../../../components/flex/Flex";
import PageContainer from "../../../../components/layout/PageContainer";
import PeriodAndFondoSelect from "./PeriodAndFondoSelect";
import EmptyState from "../../../common/components/EmptyState";
import {TbFileSad} from "react-icons/tb";
import {PiHandCoinsBold} from "react-icons/pi";
import {getCasinoDay} from "../../../../components/util/CasinoDates";
import useToggle from "../../../../hooks/useToggle";

const CHandPayReport = () => {

  const queryString = window.location.search
  const query = new URLSearchParams(queryString)
  let fondoIdParams = query.get('fondoId') || ""
  let periodParams = query.get('period') || getCasinoDay();
  const {salaId} = useContext(SiteContext)
  const {isOpen: slideOpen, toggler} = useToggle({});

  const [params, setParams] = useState({
    salaId: salaId,
    fondoId: fondoIdParams,
    period: periodParams,
    customerId: "",
    minAmount: ""
  });

  const {register, handleSubmit, control, watch} = useForm({
    defaultValues: params,
  });

  const customerId = watch("customerId");
  const minAmount = watch("minAmount");

  const determineApiEndpoint = (fondoId) => {
    return fondoId === "" 
      ? Apis.CAJA + '/operation-handpay/report/by-period' 
      : Apis.CAJA + '/operation-handpay/report/by-fondo';
  };

  const {loading, data, fetchData, error} = useFetch2(determineApiEndpoint(params.fondoId), null, params, false);

  const deleteElement = id => axios.delete(Apis.CAJA + '/operation-handpay/' + id)

  const {wrapper: wrapper2, openDeleteModal} = useDeleteModal({
    text: '¿Desea eliminar esta operación de caja?',
    action: deleteElement,
    onRefresh: fetchData
  });

  useEffect(() => {
    const query = new URLSearchParams(params).toString();
    const newUrl = `${window.location.pathname}?${query}`;
    window.history.pushState(null, "", newUrl);
    fetchData();
  }, [params]);

  const handleFondoIdChange = (fondoId) => {
    setParams((prevParams) => ({...prevParams, fondoId: fondoId}));
  };

  const onSubmit = () => {
    setParams((prevParams) => ({
      ...prevParams,
      customerId: customerId,
      minAmount: minAmount,
    }));
    fetchData();
  };
  return (
    <PageContainer>
      <Slidebar visible={slideOpen} onHide={toggler}>
        <div style={{padding: '16px'}}>
          <SalaIdInput {...register("salaId")} />
          <PeriodAndFondoSelect
            onFondoChange={handleFondoIdChange}
            period={params.period}
            setPeriod={(newPeriod) => setParams((prevParams) => ({...prevParams, period: newPeriod}))}
            {...register("fondoId")} />
          <FormGroup>
            <label>Cliente</label>
            <Controller name="customerId" control={control} render={({field}) => <CustomerSearchInputV2 {...field} />} />
          </FormGroup>
          <FormGroup>
            <label>Monto minimo</label>
            <Input {...register("minAmount")} />
          </FormGroup>
          <Button variant={'primary'} title='Buscar' onClick={handleSubmit(onSubmit)} />
        </div>
      </Slidebar>
      <PageSection>
        <Title text="Pagos manuales" type={'page'} />
        <ButtonBox>
          <Button variant={'primary'} title={'Filtrar'} onClick={toggler} />
        </ButtonBox>
        <AsyncBlock loading={loading} error={error}>
          {data === null ?
            <Card padding>
              <EmptyState
                title={'Pagos manuales'}
                subtitle={'Haga click en FILTRAR para realizar una busqueda'}
                icon={<PiHandCoinsBold size={32} />}
              />
            </Card>
            :
            <>
              {data?.data?.length === 0 ?
                <Card padding>
                  <EmptyState
                    title={'Sin resultados'}
                    subtitle={'Haga click en FILTRAR para realizar una nueva busqueda'}
                    icon={<TbFileSad size={32} />}
                  />
                </Card>
                :
                <>
                  <CardOverviewContainer gridColumns='repeat(2, 1fr)'>
                    <CardOverview
                      title={data?.quantity ?? 0}
                      subtitle='Operaciones'
                      icon={<FaSortAmountUpAlt size={20} />}
                    />
                    <CardOverview
                      title={'S/ ' + numberFormat(data?.totalAmount)}
                      subtitle='Total'
                      icon={<HiOutlineBanknotes size={20} />}
                    />
                  </CardOverviewContainer>
                  <Card>
                    <Table striped hover responsive>
                      <thead>
                        <tr>
                          <th>Fondo</th>
                          <th className="text-center">Máquina</th>
                          <th>Juego</th>
                          <th className="text-center">Documento</th>
                          <th>Cliente</th>
                          <th className="text-right">Monto</th>
                          <th className="text-center">Fecha</th>
                          <th className="text-center">Acciones</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.data && data?.data.map(item =>
                          <tr key={item.id}>
                            <td>{item.fondoName}</td>
                            <td className="text-center">{item.machine}</td>
                            <td>{item.gameName}</td>
                            <td className="text-center">{item.customerDocument === '' ? '--' : item.customerDocument}</td>
                            <td>{item.customerName === '  ' ? '--' : item.customerName}</td>
                            <td className="text-right">{'S/ ' + numberFormat(item.amount)}</td>
                            <td className="text-center"><Moment format={'YYYY-MM-DD hh:mm a'}>{item.createdAt}</Moment></td>
                            <td className="text-center">
                              <FlexLayout justifyContent='center'>
                                <Button size='xs' variant='danger' onClick={() => openDeleteModal(item.id)}>Eliminar</Button>
                                <Button size='xs'>Imprimir</Button>
                              </FlexLayout>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </Card>
                </>
              }
            </>
          }
        </AsyncBlock>
      </PageSection>
      <DeleteGenericModal {...wrapper2} />
    </PageContainer>
  )
}

export default CHandPayReport;
