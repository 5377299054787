import React from 'react';

const MoneyColoredRound = ({digits, children, currencySymbol = ''}) => {
	let d = digits ;
	if(digits === undefined || digits === null){
		d = 2;
	}

	let amount = (Math.round(children * 100) / 100).toFixed(d);

	let textColor = 'black';
	if (amount > 0) {
		textColor = 'green';
	} else if (amount < 0) {
		textColor = 'red';
	}

	const style = {
		minimumFractionDigits: d,
		useGrouping: true
	};

	const numberFormatter = Intl.NumberFormat('en-US', style);
	const formatted = numberFormatter.format(amount);

	return (
		<span style={{color: textColor, fontWeight: 550}}>
			{currencySymbol +' ' + formatted}
		</span>
	);
};

export default MoneyColoredRound;
