import React, { useEffect } from 'react';
import axios from "axios";
import { Apis } from "../../../../util/Apis";
import PrinterForm from "./PrinterForm";

const PrinterNew = ({ setAction, setTitle }) => {

  const save = (params) => {
    params.computerId = parseInt(params.computerId)
    return axios.post(Apis.PRINT + '/printers', params)
  }

  useEffect(() => {
    setTitle("Nueva impresora")
  }, [])

  return (
    <PrinterForm onSubmit={save} setAction={setAction} />
  )
};

export default PrinterNew;