import React from 'react'
import PageContainer from "../../../../components/layout/PageContainer";
import PageSection from "../../../../components/layout/PageSection";
import UserForm from "./UserForm";
import {Title} from "@wargostec/react-components";
import {useHistory} from "react-router";
import {Apis} from "../../../../util/Apis";
import {PageBackLabel} from "../../../../components/layout/PageBackLabel";
import {errorToast, successToast} from "../../../../components/layout/ToastStyles";
import axios from "axios";


const UserNew = () => {
  const history = useHistory()

  const onCreate = async (user) => {
    if (user.userGroupId === "") {
      user.userGroupId = null
    }
    if (user.roles.asisRoleId === "") {
      user.roles.asisRoleId = null
    }
    user.roles.gamingRoleId = user.userGroupId
    await axios.post(Apis.USER + '/users',user)
      .then(res => {
        successToast("Usuario registrado correctamente")
        history.push('/admin/users')
      })
      .catch(err => {
        errorToast(err.response.data.message,6000)
      });
  }

  return (
    <PageContainer>
      <PageSection>
        <PageBackLabel text='Usuarios' onClick={() => history.push('/admin/users')}/>
          <Title text="Usuario nuevo" type={'page'}/>
      </PageSection>

      <PageSection>
        <UserForm value={{}} onSubmit={(user) => onCreate(user)}/>
      </PageSection>
    </PageContainer>
  )
}

export default UserNew;
