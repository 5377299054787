import React from "react";
import Button from "../../../../components/forms/Button";
import {BsXCircle, BsZoomIn, BsZoomOut} from "react-icons/bs";
import styled from "styled-components";
import {AnimatePresence, motion} from "framer-motion";

const ControlsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  border-radius:8px;
  box-shadow: rgb(0 0 0 / 10%) 0 2px 4px;
  background:white;
  padding:16px;
`

const ButtonContainer = styled.div`
  display: flex;
  gap: 4px;
`

const LayoutControls = ({zoomIn, zoomOut, resetTransform, controlsVisible}) => {

  return (
    <AnimatePresence>
      {controlsVisible && (
        <motion.div
          initial={{opacity: 0, y: -20}}
          animate={{opacity: 1, y: 0}}
          exit={{opacity: 0, y: -20}}
          style={{display: 'flex', flexDirection: 'column', width: '220px'}}
        >
          <ControlsContainer>
            <strong>Controles</strong>
            <ButtonContainer>
              <Button variant='dark' onClick={zoomIn}><BsZoomIn /></Button>
              <Button onClick={resetTransform}><BsXCircle /></Button>
              <Button onClick={zoomOut}><BsZoomOut /></Button>
            </ButtonContainer>
          </ControlsContainer>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default LayoutControls;
