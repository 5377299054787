import React, {useContext, useEffect} from 'react';
import {SiteContext} from "../../../common/context/SiteContext";
import axios from "axios";
import {Apis} from "../../../../util/Apis";
import MovimientosFondoForm from "./MovimientosFondoForm";

const MovimientosFondoNew = ({setAction, setTitle }) => {

  const {salaId} = useContext(SiteContext)

  const save = async (params) => {
    params.salaId = salaId
    params.printerId = null
    params.enabled = true
    axios.post(Apis.CAJA + '/fondos/transfer/v2', params)
  }

  useEffect(() => {
    setTitle("Nueva operación")
  }, [])

  return (
    <div>
     <MovimientosFondoForm value={{}} onSubmit={save} setAction={setAction}/>
    </div>
  );
};

export default MovimientosFondoNew;
