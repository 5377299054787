import {useEffect} from "react";

const Leyenda = ({setTitle}) => {

  useEffect(() => {
    setTitle("Leyenda de Plantilla")
  }, [])

  return (
    <div>
      <div>
        <div>
          <div style={{fontWeight: "bold"}}>Estos comandos permitirán estilizar tu cupón:</div>
          <div>**negrita** ={">"} Texto en negrita</div>
          <div>**centrado** ={">"} Texto en el centro</div>
          <div>**derecha** ={">"} Texto a la derecha</div>
          <div>**izquierda** ={">"} Texto a la izquierda</div>
          <div>**dobleAncho** ={">"} Doble ancho</div>
          <div>**dobleAlto** ={">"} Doble alto</div>
          <div>**dobleAnchoAlto** ={">"} Doble ancho y alto</div>
          <div>**restaurar** ={">"} Restaurar todos los estilos</div>
        </div>
        <br />
        <div>
          <div style={{fontWeight: "bold"}}>Estos son los campos que puedes agregar a tu cupón:</div>
          <div>{"{sala}"} ={">"} Nombre de la sala</div>
          <div>{"{salaDireccion}"} ={">"} Dirección de la sala</div>
          <div>{"{compania}"} ={">"} Nombre de la compañia</div>
          <div>{"{maquina}"} ={">"} Nombre de la máquina</div>
          <div>{"{nombreCliente}"} ={">"} Nombre del cliente</div>
          <div>{"{documentoCliente}"} ={">"} Documento del cliente</div>
          <div>{"{codigoCupon}"} ={">"} Código del cupón</div>
          <div>{"{fechaHora}"} ={">"} Fecha y hora de impresión del cupon</div>
        </div>
        {/* <div>
              <div style={{fontWeight: "bold"}}>Ejemplo:</div>
              <div>**negrita**</div>
              <div>{"{sala}"}</div>
              <div>Maquina: {"{maquina}"}</div>
              <div>**restaurar**</div>
              <div>**centrado**</div>
              <div>{"{cliente}"}</div>
              <div>Documento: {"{documento}"}</div>
          </div> */}
      </div>
    </div>
  )
};

export default Leyenda;
