import React from 'react';
import PageSection from "../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import {Tab, Tabs} from "../../../../components/tabs/Tabs";
import PageContainerFull from "../../../../components/layout/PageContainerFull";
import TicketValidate from "./TicketValidate";
import TicketCreate from "./TicketCreate";

const Tickets = () => {

  return (
    <PageContainerFull>

      <PageSection>
          <Title text="Tickets" type={'page'}/>
        <Tabs>
          <Tab title="Validar ticket">
            <TicketValidate/>
          </Tab>
          <Tab title="Crear ticket">
            <TicketCreate/>
          </Tab>

        </Tabs>
      </PageSection>
    </PageContainerFull>
  );
};

export default Tickets;
