import React, {useEffect} from 'react';
import axios from "axios";
import {Apis} from "../../../../../util/Apis";
import {useForm} from "react-hook-form";

const UpdateTransferComponent = ({info, setAction, setTitle}) => {

  const updateStatus = () => {
    return axios.put(Apis.CAJA + `/operation-transfer/${info.id}/status`, {status: info.status})
  }

  const {handleSubmit} = useForm();

  useEffect(() => {
    setAction(() => handleSubmit(updateStatus))
  }, [])

  useEffect(() => {
    setTitle("Confirmación")
  }, [])

  return (
    <div>
      ¿Desea ejecutar esta acción?
    </div>
  );
};

export default UpdateTransferComponent;
