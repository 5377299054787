import React, {useContext, useState} from "react";
import PageSection from "../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import FormGroup, {FormBlock} from "../../../../components/forms/FormGroup";
import Input from "../../../../components/forms/FormEl";
import {Controller, useForm} from "react-hook-form";
import {SiteContext} from "../../../common/context/SiteContext";
import Button, {ButtonBox} from "../../../../components/forms/Button";
import MachineSelect from "../../../common/components/selects/MachineSelect";
import axios from "axios";
import {Apis} from "../../../../util/Apis";
import {useHistory} from "react-router";
import CustomerSearchInputV2 from "../../../common/components/CustomerSearchInputV2";
import useFetch2 from "../../../../hooks/useFetch2";
import {Container} from "react-bootstrap";

const HandPayOp = () => {

  const {salaId} = useContext(SiteContext)
  const history = useHistory()
  let defaultParams = {salaId: salaId}
  const [params, setParams] = useState(defaultParams)

  const {loading, data, fetchData, error} = useFetch2(Apis.CAJA + '/operation-handpay/pending-re', [], params);
  const {register, control, handleSubmit, watch, errors, reset, setValue} = useForm({defaultValues: defaultParams});

  const onCreate = (params) => {
    params.salaId = salaId
    params.fondoId = 1
    axios.post(Apis.CAJA + '/operation-handpay', params).then(res => {
      history.push('/caja/chandpay-create')
    }).catch(err => {

    })
  }

  return (
    <Container fluid>
      <PageSection>
          <Title text="Pagos manuales" type={'page'}/>
      </PageSection>

      <PageSection>
        <FormBlock>
          <FormGroup>
            <label>Monto</label>
            <Input {...register("amount")}/>
          </FormGroup>
          <FormGroup>
            <label>Máquina</label>
            <MachineSelect todos={0} {...register("confId")}/>
          </FormGroup>
          <FormGroup>
            <label>Cliente</label>
            <Controller name="customerId" control={control} render={({field}) => <CustomerSearchInputV2 {...field} />}/>
          </FormGroup>
          <ButtonBox>
            <Button title={"Guardar"} variant={'primary'} onClick={handleSubmit(onCreate)}/>
            <Button title={"Limpiar"} variant={'secondary'}/>
          </ButtonBox>
        </FormBlock>
        <FormBlock>
          No hay pagos manuales pendientes
        </FormBlock>
      </PageSection>
    </Container>
  )
}

export default HandPayOp
