import React, {useEffect, useState} from 'react'
import PageContainer from '../../../../components/layout/PageContainer'
import PageSection from '../../../../components/layout/PageSection'
import {Title} from "@wargostec/react-components";
import Button, {ButtonBox} from "../../../../components/forms/Button"
import Card from "../../../../components/forms/Card"
import Table from "react-bootstrap/Table"
import FormGroup from "../../../../components/forms/FormGroup"
import SalaIdInput from "../../../common/components/SalaIdInput";
import {DatePickerCustom} from "../../../../components/forms/FormEl";
import Slidebar from "../../../common/layout/Slidebar";
import {Apis} from "../../../../util/Apis";
import {Controller, useForm} from "react-hook-form";
import {useHistory} from "react-router";
import Moment from "react-moment";
import AsyncBlock from "../../../../components/forms/AsyncBlock";
import useFetch2 from "../../../../hooks/useFetch2";
import {Col, Row} from "../../../../components/flex/FlexLayout";
import {Flex} from "../../../../components/flex/Flex";
import StadisticTitle from "../../../../components/layout/StadisticTitle";
import StadisticValue from "../../../../components/layout/StadisticValue";
import {FaSadCry, FaSmile} from "react-icons/fa";
import {PageBackLabel} from "../../../../components/layout/PageBackLabel";
import CustomerSearchInputV2 from "../../../common/components/CustomerSearchInputV2";
import CouponTypeSelectV2 from "../../../common/components/selects/CouponTypeSelectV2";
import {CardTitle} from "../coupons/PrinterDetail";

const SorteoStadisticCustomer = () => {

  const querystring = window.location.search
  const query = new URLSearchParams(querystring)
  let customerQueryParams = query.get('customerId')
  let iniQueryParams = query.get('ini')
  let finQueryParams = query.get('fin')
  let couponQueryParams = query.get('couponTypeId')
  const history = useHistory()

  const [slideOpen, setSlideOpen] = useState(false)

  let defaultParams = {
    customerId: customerQueryParams,
    ini: iniQueryParams,
    fin: finQueryParams,
    couponTypeId: couponQueryParams
  }
  const [params, setParams] = useState(defaultParams)

  const {
    loading,
    data,
    fetchData,
    error
  } = useFetch2(Apis.PC + '/sorteo-trace/by-customer', [], params, false)

  const {
    data: data2
  } = useFetch2(Apis.PC + '/customers/' + customerQueryParams, null, {}, true)

  const {register, control, handleSubmit} = useForm({defaultValues: defaultParams});

  const getData = (params) => {
    setParams(params)
  }

  useEffect(() => {
    if (params) fetchData()
  }, [params])

  const countSorteosGanados = () => {
    let sorteosGanados = 0
    data.forEach(item => {
      if (item.ganado === 1) {
        sorteosGanados++
      }
    })

    return sorteosGanados
  }

  const sumTeoricos = () => {
    let sum = 0
    data.forEach(item => {
      sum = sum + item.teorico
    })

    return sum
  }

  return (
    <PageContainer>
      <Slidebar visible={slideOpen} onHide={() => setSlideOpen(false)}>
        <div style={{padding: '16px'}}>
          <SalaIdInput {...register("salaId")}/>
          <FormGroup>
            <label>Desde</label>
            <DatePickerCustom {...register("ini")}/>
          </FormGroup>

          <FormGroup>
            <label>Hasta</label>
            <DatePickerCustom {...register("fin")}/>
          </FormGroup>

          <FormGroup>
            <label>Cliente</label>
            <Controller name="customerId" control={control} render={({field}) => <CustomerSearchInputV2 {...field} />}/>
          </FormGroup>

          <FormGroup>
            <label>Promoción</label>
            <Controller
              name="couponTypeId"
              control={control}
              render={({field}) => <CouponTypeSelectV2 {...field} />}
            />
          </FormGroup>


          <Button variant={'primary'} title='Buscar' onClick={handleSubmit(getData)}/>
        </div>
      </Slidebar>

      <PageSection>
        <PageBackLabel text='Regresar' onClick={() => history.goBack()}/>
          <Title text="Resumen por cliente" type={'page'}/>

        <ButtonBox>
          <Button variant={'primary'} title={'Filtrar'} onClick={() => setSlideOpen(!slideOpen)}/>
        </ButtonBox>
      </PageSection>

      <PageSection>
        <Card padding>
          <CardTitle><strong>{data2 && data2.person.fullTotalName}</strong></CardTitle>
          <label>Nivel: {data2 && data2.levelName}</label>
          <div></div>
          <label>Documento: {data2 && data2.person.document}</label>
        </Card>
        <Card padding>
          <Row>
            <Col>
              <Flex flexDirection={'column'}>
                <StadisticTitle>Participó</StadisticTitle>
                <StadisticValue>{data && data.length}</StadisticValue>
              </Flex>
            </Col>
            <Col>
              <Flex flexDirection={'column'}>
                <StadisticTitle>Gano en</StadisticTitle>
                <StadisticValue>
                  {countSorteosGanados()}
                </StadisticValue>
              </Flex>
            </Col>
            <Col>
              <Flex flexDirection={'column'}>

                <StadisticTitle>Teórico</StadisticTitle>
                <StadisticValue>
                  {sumTeoricos().toFixed(2)}
                </StadisticValue>
              </Flex>
            </Col>
            <Col>
              <Flex flexDirection={'column'}>

                <StadisticTitle>Suerte</StadisticTitle>
                <StadisticValue>
                  {
                    countSorteosGanados() > sumTeoricos() ?
                      <FaSmile/>
                      :
                      <FaSadCry/>
                  }
                </StadisticValue>
              </Flex>
            </Col>
          </Row>
        </Card>

        <Card padding>
          <AsyncBlock loading={loading} error={error}>
            <Table hover size={'sm'}>
              <thead>
              <tr>
                <th>Fecha</th>
                <th>Cupón</th>
                <th className={'text-right'}>Depositados</th>
                <th className={'text-right'}>Total</th>
                <th className={'text-right'}>Teórico</th>
                <th className={'text-right'}>Ganado</th>
              </tr>
              </thead>
              <tbody>
              {data && data.map(item =>
                <tr key={item.id} className={item.ganado === 1 ? 'alert-success' : null}>
                  <td><Moment format={'DD/MM/YY HH:mm'}>{item.createdAt}</Moment></td>
                  <td>{item.couponName}</td>
                  <td className={'text-right'}>{item.coupons}</td>
                  <td className={'text-right'}>{item.totalCoupons}</td>
                  <td className={'text-right'}>{(item.teorico * 100).toFixed(2)}%</td>
                  <td className={'text-right'}>
                    {
                      item.ganado === 1 && <span>Ganador</span>
                    }
                  </td>
                </tr>
              )}
              </tbody>
            </Table>
          </AsyncBlock>
        </Card>
      </PageSection>
    </PageContainer>
  )
}

export default SorteoStadisticCustomer
