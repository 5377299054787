export function getContrastColor(hexColor) {
  // Convierte el color hexadecimal a RGB
  const r = parseInt(hexColor.slice(1, 3), 16);
  const g = parseInt(hexColor.slice(3, 5), 16);
  const b = parseInt(hexColor.slice(5, 7), 16);

  // Calcula el coeficiente de luminancia
  const luminance = 0.299 * r + 0.587 * g + 0.114 * b;

  // Decide si el texto debe ser blanco o negro
  return luminance > 128 ? '#000000' : '#ffffff';
}