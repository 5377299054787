import {Route, Switch} from "react-router";
import React from "react";
import SessionList from "./pages/sessions/SessionList";
import CrmHome from "./CrmHome";
import SorteoList from "./pages/sorteos/SorteoList";
import CouponTypeList from "./pages/coupon-type/CouponTypeList";
import CouponAwarded from "./pages/coupons/CouponAwarded/CouponAwarded";
import SorteoConfiguration from "./pages/sorteos/SorteoConfiguration";
import CouponAccumulated from "./pages/coupons/CouponAccumulated";
import CouponGenerados from "./pages/coupons/CouponGenerados";
import Estadistica from "./pages/coupons/Estadistica";
import TareasImpresion from "./pages/coupons/TareasImpresion";
import GestionEncuestas from "./pages/encuestas/GestionEncuestas";
import TomarEncuestas from "./pages/encuestas/TomarEncuestas";
import ActivosVSRegistrados from "./pages/clientes/ActivosVSRegistrados";
import CustomerBirthday from "./pages/clientes/CustomerBirthday";
import CustomerList from "./pages/clientes/customers/CustomerList";
import CustomerOnline from "./pages/clientes/CustomerOnline";
import CustomerRanking from "./pages/clientes/CustomerRanking";
import SesionesXEmpleado from "./pages/clientes/SesionesXEmpleado";
import PuntosConfiguracionList from "./pages/puntos/PuntosConfiguracionList";
import PuntosPromoList from "./pages/puntos/PuntosPromoList";
import CouponPromoList from "./pages/coupon-promo/CouponPromoList";
import ReporteDiario from "./pages/pedidos/ReporteDiario";
import OrdenesPedidos from "./pages/pedidos/OrdenesPedidos";
import MenuPedidos from "./pages/pedidos/MenuPedidos";
import CategoriasPedidos from "./pages/pedidos/CategoriasPedidos";
import CouponTypeEdit from "./pages/coupon-type/CouponTypeEdit";
import CouponTypeNew from "./pages/coupon-type/CouponTypeNew";
import CouponPromoNew from "./pages/coupon-promo/CouponPromoNew";
import CouponPromoEdit from "./pages/coupon-promo/CouponPromoEdit";
import CustomerView from "./pages/clientes/customers/CustomerView";
import MachineEmulator from "./pages/coupons/MachineEmulator";
import PointBalance from "./pages/puntos/PointBalance";
import PointBalanceDetail from "./pages/puntos/PointBalanceDetail";
import ListPointsAwarded from "./pages/puntos/PointsAwarded/ListPointsAwarded";
import AnforaList from "./pages/sorteos/AnforaList";
import AnforaParticipantes from "./pages/sorteos/AnforaParticipantes";
import UploadMassiveCoupons from "./pages/coupons/UploadMassiveCoupons";
import UploadMassiveCouponsList from "./pages/coupons/UploadMassiveCouponsList";
import CustomerNew from "./pages/clientes/customers/CustomerNew";
import CustomerEdit from "./pages/clientes/customers/CustomerEdit";
import NotificationList from "./pages/application/NotificationList";
import SesionesExternas from "./pages/clientes/SesionesExternas";
import RewardsList from "./pages/recompensas/RewardsList";
import SorteoStadisticCustomer from "./pages/sorteos/SorteoStadisticCustomer";
import PublicidadList from "./pages/application/PublicidadList";
import GanadorList from "./pages/sorteos/analisis-participantes/GanadorList";
import OnePrinterDetail from "./pages/coupons/OnePrinterDetail";
import SorteoMassive from "./pages/sorteos/SorteoMassive";
import CustomerGroupNotifList from "./pages/application/CustomerGroup/CustomerGroupNotifList";
import CustomerGroupDetailList from "./pages/application/CustomerGroup/CustomerGroupDetailList";
import CustomerIngress from "./pages/clientes/customers/CustomerIngress";
import OcupationPerHour from "./pages/clientes/customers/OcupationPerHour";
import PuntosPromoEdit from "./pages/puntos/PuntosPromoEdit";
import PuntosPromoNew from "./pages/puntos/PuntosPromoNew";
import CustomerInfo from "./pages/clientes/CustomerInfo";
import CustomerResume from "./pages/clientes/CustomerResume";
import SegmentsList from "./pages/clientes/segments/SegmentsList";
import SorteoStatistics from "./pages/sorteos/SorteoStatistics";
import FoodProduct from "./pages/products/FoodProduct";
import CategoriesList from "./pages/products/CategoriesList";
import ComputerPrinterList from "./pages/coupons/ComputerPrinterList";
import ComputerPrinterDownload from "./pages/coupons/ComputerPrinterDownload";
import PendingOrders from "./pages/products/PendingOrders";
import ProductCatalogList from "./pages/clientes/customers/ProductCatalogList";
import PrinterJobList from "./pages/coupons/PrinterJobList";
import RedeemPoints from "./pages/canje-puntos/RedeemPoints";
import OrdersHistory from "./pages/products/OrdersHistory";
import ProgressiveList from "./pages/sorteos/ProgressiveList";
import ProgressiveNew from "./pages/sorteos/ProgressiveNew";
import ProgressiveEdit from "./pages/sorteos/ProgressiveEdit";
import ResponsableConfiguration from "./pages/sorteos/ResponsableConfiguration";
import RedeemCustomPoints from "./pages/canje-puntos/RedeemCustomPoints";
import CardList from './pages/clientes/customers/CardList';
import ConfigOrders from "./pages/products/ConfigOrders";
import OrderReport from "./pages/products/OrderReport";
import Settings from "./pages/Settings";
import SiteList from "./pages/application/SiteList";
import SiteNew from "./pages/application/SiteNew";
import SiteEdit from "./pages/application/SiteEdit";
import ManualRedeemList from "./pages/clientes/customers/ManualRedeemList";
import UploadMassivePointsList from "./pages/puntos/UploadMassivePointsList";
import UploadMassivePoints from "./pages/puntos/UploadMassivePoints";
import FoodProductEdit from "./pages/products/FoodProductEdit";
import FoodProductNew from "./pages/products/FoodProductNew";
import CouponSearch from "./pages/coupons/CouponSearch";

const CrmRoutes = () => {
  const parentRoute = '/crm'
  return (
    <Switch>

      <Route path={parentRoute + '/app-notif-list'} component={NotificationList}/>
      <Route path={parentRoute + '/publicidad'} component={PublicidadList}/>
      <Route path={parentRoute + '/sites'} component={SiteList}/>
      <Route path={parentRoute + '/sites-new'} component={SiteNew}/>
      <Route path={parentRoute + '/sites-edit/:id'} component={SiteEdit}/>
      <Route path={parentRoute + '/customer-group'} component={CustomerGroupNotifList}/>
      <Route path={parentRoute + '/customer-group-detail/:id'} component={CustomerGroupDetailList}/>

      <Route path={parentRoute + '/gestion-encuestas'} component={GestionEncuestas}/>
      <Route path={parentRoute + '/tomar-encuesta'} component={TomarEncuestas}/>

      <Route path={parentRoute + '/activos-vs-registrados'} component={ActivosVSRegistrados}/>
      <Route path={parentRoute + '/cumpleanos'} component={CustomerBirthday}/>

      <Route path={parentRoute + '/clientes/view/:id'} component={CustomerView}/>
      <Route path={parentRoute + '/clientes'} component={CustomerList}/>

      <Route path={parentRoute + '/online'} component={CustomerOnline}/>
      <Route path={parentRoute + '/ranking'} component={CustomerRanking}/>
      <Route path={parentRoute + '/new-customer'} component={CustomerNew}/>
      <Route path={parentRoute + '/edit-customer/:id'} component={CustomerEdit}/>
      <Route path={parentRoute + '/customer-resume'} component={CustomerResume}/>
      <Route path={parentRoute + '/segments-list'} component={SegmentsList}/>
      <Route path={parentRoute + '/customer-catalog'} component={ProductCatalogList}/>


      <Route path={parentRoute + '/sessions'} component={SessionList}/>
      <Route path={parentRoute + '/sesiones-por-empleado'} component={SesionesXEmpleado}/>
      <Route path={parentRoute + '/sesiones-externas'} component={SesionesExternas}/>
      <Route path={parentRoute + '/historial-ingresos'} component={CustomerIngress}/>
      <Route path={parentRoute + '/ocupación-por-hora'} component={OcupationPerHour}/>
      <Route path={parentRoute + '/card'} component={CardList}/>

      <Route path={parentRoute + '/puntos-configuracion'} component={PuntosConfiguracionList}/>
      <Route exact path={parentRoute + '/puntos-balance'} component={PointBalance}/>
      <Route path={parentRoute + '/upload-points'} component={UploadMassivePoints}/>
      <Route path={parentRoute + '/upload-points-list'} component={UploadMassivePointsList}/>
      <Route exact path={parentRoute + '/puntos-otorgados'} component={ListPointsAwarded}/>
      <Route path={parentRoute + '/puntos-balance/:id'} component={PointBalanceDetail}/>

      <Route path={parentRoute + '/puntos-promociones/edit/:id'} component={PuntosPromoEdit}/>
      <Route path={parentRoute + '/puntos-promociones/new'} component={PuntosPromoNew}/>
      <Route path={parentRoute + '/puntos-promociones'} component={PuntosPromoList}/>

      <Route path={parentRoute + '/coupon-promo/edit/:id'} component={CouponPromoEdit}/>
      <Route path={parentRoute + '/coupon-promo/new'} component={CouponPromoNew}/>
      <Route path={parentRoute + '/coupon-promo'} component={CouponPromoList}/>

      <Route path={parentRoute + '/coupon-types/edit/:id'} component={CouponTypeEdit}/>
      <Route path={parentRoute + '/coupon-types/new'} component={CouponTypeNew}/>
      <Route path={parentRoute + '/coupon-types'} component={CouponTypeList}/>

      <Route path={parentRoute + '/computer-printer-list'} component={ComputerPrinterList}/>
      <Route path={parentRoute + '/computer-printer-job-list/:id'} component={PrinterJobList}/>

      <Route path={parentRoute + '/computer-printer-download'} component={ComputerPrinterDownload}/>

      <Route path={parentRoute + '/coupon-printer-detail/:id'} component={OnePrinterDetail}/>

      <Route path={parentRoute + '/awarded-coupons'} component={CouponAwarded}/>
      <Route path={parentRoute + '/accumulated-coupons'} component={CouponAccumulated}/>
      <Route path={parentRoute + '/generated-coupons'} component={CouponGenerados}/>
      <Route path={parentRoute + '/estadisticas'} component={Estadistica}/>
      <Route path={parentRoute + '/upload-coupons'} component={UploadMassiveCoupons}/>
      <Route path={parentRoute + '/upload-coupons-list'} component={UploadMassiveCouponsList}/>
      <Route path={parentRoute + '/coupon-search'} component={CouponSearch}/>

      <Route path={parentRoute + '/tareas-impresion'} component={TareasImpresion}/>

      <Route path={parentRoute + '/sorteos'} component={SorteoList}/>
      <Route path={parentRoute + '/sorteos-statistics'} component={SorteoStatistics}/>
      <Route path={parentRoute + '/sorteos-massive'} component={SorteoMassive}/>
      <Route path={parentRoute + '/sorteos-configuration'} component={SorteoConfiguration}/>
      <Route path={parentRoute + '/responsable-configuration'} component={ResponsableConfiguration}/>

      <Route path={parentRoute + '/progressive/new'} component={ProgressiveNew}/>
      <Route path={parentRoute + '/progressive/edit/:id'} component={ProgressiveEdit}/>
      <Route path={parentRoute + '/progressive'} component={ProgressiveList}/>

      <Route path={parentRoute + '/ánforas/participantes/:id'} component={AnforaParticipantes}/>
      <Route path={parentRoute + '/ánforas'} component={AnforaList}/>
      <Route path={parentRoute + '/analisis-ganador/by-customer'} component={SorteoStadisticCustomer}/>
      <Route path={parentRoute + '/analisis-ganador'} component={GanadorList}/>

      <Route path={parentRoute + '/rewards'} component={RewardsList}/>
      <Route path={parentRoute + '/products'} component={FoodProduct}/>
      <Route path={parentRoute + '/products-type/edit/:id'} component={FoodProductEdit}/>
      <Route path={parentRoute + '/products-type/new'} component={FoodProductNew}/>
      <Route path={parentRoute + '/food-categories'} component={CategoriesList}/>
      <Route path={parentRoute + '/pending-orders'} component={PendingOrders}/>
      <Route path={parentRoute + '/orders-history'} component={OrdersHistory}/>
      <Route path={parentRoute + '/orders-config'} component={ConfigOrders}/>
      <Route path={parentRoute + '/orders-report'} component={OrderReport}/>

      <Route path={parentRoute + '/redeem-points'} component={RedeemPoints}/>
      <Route path={parentRoute + '/redeem-custom-points'} component={RedeemCustomPoints}/>
      <Route path={parentRoute + '/manual-redeem'} component={ManualRedeemList}/>

      <Route path={parentRoute + '/pedido-categoria'} component={CategoriasPedidos}/>
      <Route path={parentRoute + '/menu'} component={MenuPedidos}/>
      <Route path={parentRoute + '/ordenes'} component={OrdenesPedidos}/>
      <Route path={parentRoute + '/reporte-diario'} component={ReporteDiario}/>
      <Route path={parentRoute + '/emulator'} component={MachineEmulator}/>

      <Route path={parentRoute + '/customer-info/:id'} component={CustomerInfo}/>
      <Route path={parentRoute + '/settings'} component={Settings}/>
      <Route path="/tgm" component={CrmHome}/>
    </Switch>
  )
}

export default CrmRoutes;
