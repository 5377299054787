import React, {useContext} from "react";
import useFetch2 from "../../hooks/useFetch2";
import {Apis} from "../../util/Apis";
import {SiteContext} from "../../modules/common/context/SiteContext";
import {FlexContainer, Input} from "@wargostec/react-components";

const TableAmountsMesa = ({rows, setRows, total, setTotal}) => {
  const {salaId} = useContext(SiteContext)

  const handleAmountChange = (index, cantidad, type = "button") => {
    let auxFilas = [...rows];
    const updatedQuantity = type === "button" ? auxFilas[index].quantity + cantidad : cantidad;
    auxFilas[index].quantity = Math.max(0, updatedQuantity);
    setRows(auxFilas)
  };
  
  const {data: dataSala} = useFetch2(Apis.SALAS + '/salas-config/' + salaId, [])
  const symbol = dataSala && dataSala.currencySymbol !== "" ? dataSala.currencySymbol : "S/"
  return (
    <FlexContainer flexDirection="column" gap='8px' style={{width: '100%', padding: '0 8px'}}>
      <FlexContainer flexDirection="row">
        <span style={{flex: '1'}}>Moneda</span>
        <span style={{flex: '1', textAlign: 'right'}}>Cantidad</span>
      </FlexContainer>
      <FlexContainer flexDirection="column" gap='6px'>
        {rows.map((fila, index) => (
          <FlexContainer key={fila.denom} alignItems="center">
            <div style={{flex: 1, fontSize: '16px'}}>{fila.currencySymbol + " " + fila.denom}</div>
            <Input
              type="number"
              style={{flex: 1, textAlign: 'right'}}
              value={fila.quantity}
              onChange={(event) => 
                handleAmountChange(
                  index,
                  parseFloat(event.target.value !== "" ? event.target.value : '0'), 
                  "input"
                  )
                }
            />
          </FlexContainer>
        ))}
      </FlexContainer>
      <FlexContainer alignItems="center" style={{padding:'0 8px'}}>
        <span style={{fontSize:'16px',flex: '1'}}>Total</span>
        <strong style={{fontSize:'20px'}}>{symbol  + ' ' + total}</strong>
      </FlexContainer>
    </FlexContainer>
  )
}
export default TableAmountsMesa
