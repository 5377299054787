import React, {useCallback, useMemo} from 'react';
import {useHistory, useParams} from "react-router";
import PageSection from "../../../../../components/layout/PageSection";
import {PageBackLabel} from "../../../../../components/layout/PageBackLabel";
import {Title} from "@wargostec/react-components";
import useFetch2 from "../../../../../hooks/useFetch2";
import {Apis} from "../../../../../util/Apis";
import ReactTable from "../../../../../components/util/ReactTable";
import AsyncBlock from "../../../../../components/forms/AsyncBlock";
import PageContainerFull from "../../../../../components/layout/PageContainerFull";
import {FlexLayout} from "../../../../../components/flex/Flex";
import {errorToast, successToast} from "../../../../../components/layout/ToastStyles";
import axios from "axios";
import Button from "../../../../../components/forms/Button";
import {getNameJackpot} from "../gestionJackpots/JackpotList";

const SorteoJackpot = () => {

  const {id} = useParams()
  const history = useHistory()

  const {loading, data, fetchData, error} = useFetch2(Apis.BINGO + `/jackpot?eventId=${id}`, []);
  const {
    loading: loading2,
    data: data2,
    fetchData: fetchData2,
    error: error2
  } = useFetch2(Apis.BINGO + `/jackpot/sala`, []);

  const enableOrDisableJack = async (jackpotId, enable) => {
    console.log(id, enable)
    const body = {
      jackpotId: jackpotId,
    }

    if (!enable) {
      await axios.post(Apis.BINGO + '/event/' + id + '/enable-jackpot', body)
        .then(() => {
            successToast("Jackpot habilitado correctamente",2000)
            fetchData()
            fetchData2()
          }
        ).catch((error) => {
          errorToast("Ocurrió un problema al habilitar. Intente nuevamente en unos minutos.",2000)
        })
    } else {
      await axios.post(Apis.BINGO + '/event/' + id + '/disable-jackpot', body)
        .then(() => {
            successToast("Jackpot deshabilitado correctamente",2000)
            fetchData()
            fetchData2()
          }
        ).catch((error) => {
          errorToast("Ocurrió un problema al deshabilitar. Intente nuevamente en unos minutos.",2000)
        })
    }
  }

  const jackpotCell = useCallback(props => <>{
    <FlexLayout alignItems='center' justifyContent='center' gap='4px'>
      <span>{getNameJackpot(props.renderValue())}</span>
    </FlexLayout>
  }</>, []);

  const ballCell = useCallback(props => <>{
    <FlexLayout alignItems='center' justifyContent='center' gap='4px'>
      <span>{props.renderValue() ? "Desde " + props.row.original.startBall : "Con " + props.row.original.winBall}</span>
    </FlexLayout>
  }</>, []);

  const actionEnable = useCallback(props => <>{
    <FlexLayout justifyContent='center'>
      <Button variant={'success'} size={'s'}
              onClick={() => enableOrDisableJack(props.renderValue(), false)}>Habilitar</Button>
    </FlexLayout>
  }</>, []);

  const actionDisable = useCallback(props => <>{
    <FlexLayout justifyContent='center'>
      <Button variant={'danger'} size={'s'}
              onClick={() => enableOrDisableJack(props.renderValue(), true)}>Deshabilitar</Button>
    </FlexLayout>
  }</>, []);

  const columnsEnable = useMemo(
    () => [
      {
        header: 'Id',
        accessorKey: 'id',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Jackpot',
        accessorKey: 'form',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: jackpotCell
      },
      {
        header: 'Bolita',
        accessorKey: 'ballRaise',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: ballCell
      },
      {
        header: 'Monto base',
        accessorKey: 'baseAmount',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Monto actual',
        accessorKey: 'actualAmount',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Acciones',
        accessorKey: 'id',
        enableGlobalFilter: false,
        enableSorting: false,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: actionDisable
      }
    ]
    , []
  )

  const mathJackpot = (arrayActive, arrayInactive) => {
    const activeIds = arrayActive.map(item => item.id);
    return arrayInactive.filter(item => !activeIds.includes(item.id));
  }

  const columnsDisable = useMemo(
    () => [
      {
        header: 'Id',
        accessorKey: 'id',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Jackpot',
        accessorKey: 'form',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: jackpotCell
      },
      {
        header: 'Bolita',
        accessorKey: 'ballRaise',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: ballCell
      },
      {
        header: 'Monto base',
        accessorKey: 'baseAmount',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Monto actual',
        accessorKey: 'actualAmount',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Acciones',
        accessorKey: 'id',
        enableGlobalFilter: false,
        enableSorting: false,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: actionEnable
      }
    ]
    , []
  )

  return (
    <PageContainerFull>
      <PageSection>
        <PageBackLabel text='Lista de partidas' onClick={() => history.goBack()}/>
          <Title text="Jackpots" type={'page'}/>
      </PageSection>

      <PageSection>
          <Title text="Habilitados" type={'page'}/>
        <AsyncBlock loading={loading} error={error}>
          <ReactTable
            columns={columnsEnable}
            data={data || []}
            pageSize={15}
          />
        </AsyncBlock>
      </PageSection>

      <PageSection>
          <Title text="Habilitados" type={'page'}/>
        <AsyncBlock loading={loading2} error={error2}>
          <ReactTable
            columns={columnsDisable}
            data={mathJackpot(data, data2) || []}
            pageSize={15}
          />
        </AsyncBlock>
      </PageSection>
    </PageContainerFull>
  );
};

export default SorteoJackpot;
