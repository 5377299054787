import React from "react";
import styled from "styled-components";
import {FaTools} from "react-icons/fa";

const DropdownMenuStyled = styled.div`
  .parent-container{
    cursor: pointer;
  }
  
  .dropdown-item-container {
    overflow: hidden;
    //background: #0a1b3d;
    background: var(--sidebar-items);
    //transition: all 0.5s linear;
    //max-height: 1500px;
    max-height: 0;
    //necesitamos el cubic-bezier para no tener el problema de delay animando max-height
    //https://stackoverflow.com/a/39103903/4471534
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
    
    &.unfolded{
      max-height: 600px;
      transition: max-height 1s ease-in-out;
      border-radius:6px;
    }
  }
`

const SpanMenuTitle = styled.span`
    display: block;
    padding: 0.5rem 0;
    color: var(--sibebar-font);
    text-decoration: none;
    background: transparent;
    user-select : none;
    cursor : pointer;
    font-weight: 500;
`;

const DropdownMenu = ({children, title, icon, setSelected, selected}) => {

  function isActive() {
    return selected === title
  }

  const setActive = () => {
    //setOpened(!opened)
    if(isActive()){
      setSelected(null)
    }else{
      setSelected(title)
    }
  }

  return (
    <DropdownMenuStyled>
      <div className='parent-container' onClick={() => setActive()}>
        <SpanMenuTitle>
          {icon ? icon : <FaTools/>}
          <span style={{marginLeft: "10px"}}>
            {title}
          </span>
        </SpanMenuTitle>
      </div>

      <ul className={isActive() ? "dropdown-item-container unfolded" : "dropdown-item-container"}>
        {children}
      </ul>

    </DropdownMenuStyled>
  )
};

export default DropdownMenu;
