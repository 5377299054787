import React, {useState} from "react";
import {Select} from "../../../../components/forms/FormEl";

const GroupSelect = React.forwardRef((props, ref) => {

  const [loading, setLoading] = useState(false);

  let data = [
    {'id': 1, 'name': 'Cumpleaños por mes'},
    {'id': 2, 'name': 'cantidad cupones'}
  ]

  return (
    <>
      {
        !loading ?
          <Select name='customerGroupId' ref={ref} onChange={props.changeFunction}>
            {props.todos === 1 && <option hidden value={0}>SELECCIONE</option>}
            {data && data.map(item => <option key={item.id} value={item.id}>{item.name} </option>)}
          </Select>
          :
          <Select disabled={true}>
            <option key={0} value={0}>{"Cargando..."}</option>
          </Select>
      }
    </>
  )
})

export default GroupSelect
