import React from "react";
import PageSection from "../../components/layout/PageSection";
import Container from "react-bootstrap/Container";
import {Title} from "@wargostec/react-components";

const CajaHome = () => {
  return (
    <Container fluid>
      <PageSection>
          <Title text="Bienvenido al modulo de caja" type={'page'}/>
      </PageSection>
    </Container>
  )
}

export default CajaHome;
