import React, {useContext} from 'react';
import {SiteContext} from '../../../common/context/SiteContext';
import {Apis} from '../../../../util/Apis';
import useEditModal from '../../../../modals/useEditModal';
import useDeleteModal from '../../../../modals/useDeleteModal';
import axios from 'axios';
import PageContainer from '../../../../components/layout/PageContainer';
import PageSection from '../../../../components/layout/PageSection';
import Button, {ButtonBox} from '../../../../components/forms/Button';
import Card from '../../../../components/forms/Card';
import Table from 'react-bootstrap/Table';
import GenericModal2 from '../../../../modals/GenericModal2';
import DeleteGenericModal from '../../../../modals/DeleteGenericModal';
import {FaPlus} from 'react-icons/fa';
import NotificationEdit from './NotificationEdit';
import NotificationNew from './NotificationNew';
import Moment from 'react-moment';
import NoDataTable from '../../../common/components/NoDataTable';
import Loader1 from '../../../common/components/Loader1';
import useFetch2 from "../../../../hooks/useFetch2";
import {Title} from "@wargostec/react-components";

const NotificationList = () => {
	const {salaId} = useContext(SiteContext);
	const params = {salaId: salaId}

	const {loading, data, fetchData} = useFetch2(
		Apis.NOTIF + `/notifications`,
		[],
		params,
		true
	);

	const deleteElement = (id) =>
		axios.delete(Apis.NOTIF + '/notifications/' + id);

	//hooks para crear,editar y eliminar
	const {
		wrapper: wrapper1,
		addAction,
		editAction
	} = useEditModal({
		editComponent: <NotificationEdit />,
		addComponent: <NotificationNew />,
		onRefresh: fetchData
	});

	const {wrapper: wrapper2, openDeleteModal} = useDeleteModal({
		text: '¿Desea eliminar esta zona?',
		action: deleteElement,
		onRefresh: fetchData
	});

	return (
		<PageContainer>
			<PageSection>
					<Title text="Notificaciones" type={'page'}/>
				<ButtonBox>
					<Button variant={'primary'} onClick={() => addAction()}>
						<FaPlus /> Nuevo
					</Button>
				</ButtonBox>
			</PageSection>

			<PageSection>
				<Card padding>
					<Table striped hover>
						<thead>
							<tr>
								<th>ID</th>
								<th>Título</th>
								<th>Contenido</th>
								<th>Fecha</th>
								<th>Hora</th>
								<th>Enviados/total</th>
								<th>Clientes notificados/total</th>
								<th>Estado</th>
								<th />
							</tr>
						</thead>
						<tbody>
							{data &&
								data.map((item) => (
									<tr key={item.id}>
										<td>{item.id}</td>
										<td>{item.title}</td>
										<td>{item.body}</td>
										<td>
											<Moment format={'DD/MM/YYYY'}>{item.scheduledAt}</Moment>
										</td>
										<td>
											<Moment format={'HH:mm:ss'}>{item.scheduledAt}</Moment>
										</td>
										<td>
											{item.totalNotifications -
												item.totalNotificationsNotSended}{' '}
											/ {item.totalNotifications ? item.totalNotifications : 0}{' '}
										</td>
										<td>
											{item.totalCustomers - item.totalCustomersNotSended} /{' '}
											{item.totalCustomers ? item.totalCustomers : 0}{' '}
										</td>
										<td>{item.state}</td>
										<td>
											<ButtonBox>
												<Button
													variant={'light'}
													size='xs'
													onClick={() => editAction(item.id)}
													title={'Editar'}
												/>
												<Button
													variant={'danger'}
													size='xs'
													onClick={() => openDeleteModal(item.id)}
													title={'Eliminar'}
												/>
											</ButtonBox>
										</td>
									</tr>
								))}
						</tbody>
					</Table>
					{!loading ? (
						data.length <= 0 && (
							<NoDataTable text={'Aún no hay notificaciones en esta sala'} />
						)
					) : (
						<Loader1 />
					)}
				</Card>
			</PageSection>

			<GenericModal2 {...wrapper1} />
			<DeleteGenericModal {...wrapper2} />
		</PageContainer>
	);
};

export default NotificationList;
