import React, {useContext} from "react";
import PageSection from "../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import FormGroup, {FormBlock} from "../../../../components/forms/FormGroup";
import Input from "../../../../components/forms/FormEl";
import Button, {ButtonBox} from "../../../../components/forms/Button";
import PageContainer from "../../../../components/layout/PageContainer";
import {SiteContext} from "../../../common/context/SiteContext";
import {useHistory} from "react-router";
import {useForm} from "react-hook-form";
import axios from "axios";
import {Apis} from "../../../../util/Apis";
import FondoSelect from "../../../common/components/selects/FondoSelect";
import {errorToast, successToast} from "../../../../components/layout/ToastStyles";


const TransferOp = () => {

  const {salaId} = useContext(SiteContext)
  const history = useHistory()
  let defaultParams = {salaId: salaId, enabled: true}
  const {register, handleSubmit} = useForm({defaultValues: defaultParams});

  const onCreate = (params) => {
    params.salaId = salaId
    params.printerId = null
    axios.post(Apis.CAJA + '/fondos/transfer', params)
      .then(res => {
        successToast()
      }).catch(err => {
      errorToast()
    })
  }

  return (
    <PageContainer>
      <PageSection>
          <Title text="Transferencia entre fondos" type={'page'}/>
      </PageSection>

      <PageSection>
        <FormBlock>
          <FormGroup>
            <label>Fondo origen</label>
            <FondoSelect {...register("sourceFondoId")}/>
          </FormGroup>
          <FormGroup>
            <label>Monto</label>
            <Input {...register("amount")}/>
          </FormGroup>
          <FormGroup>
            <label>Fondo destino</label>
            <FondoSelect {...register("targetFondoId")}/>
          </FormGroup>
          <ButtonBox>
            <Button title={"Transferir"} variant={'primary'} onClick={handleSubmit(onCreate)}/>
          </ButtonBox>
        </FormBlock>
      </PageSection>
    </PageContainer>
  )
}

export default TransferOp;
