import React from "react";
import PageContainer from "../../components/layout/PageContainer";
import Card from "../../components/forms/Card";
import error403 from "./403.png"
import Button, {ButtonBox} from "../../components/forms/Button";

const ErrorAuthPage = () => {
    const doExit = () => {
        window.location = '/logout'
    }

    return (
        <React.Fragment>
            <PageContainer style={{paddingTop: '50px'}}>
                <Card padding style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <img alt={"error403"} src={error403} style={{width: "600px", marginBottom: "30px"}}/>
                    <ButtonBox>
                        <Button variant={'primary'} onClick={doExit}>
                            Salir
                        </Button>
                    </ButtonBox>
                </Card>
            </PageContainer>
        </React.Fragment>
    )
}

export default ErrorAuthPage
