import React, {useEffect} from "react";
import {Apis} from "../../../../../util/Apis";
import axios from "axios";
import CardForm from "./CardForm";


const CardNew = ({info,setAction, setTitle}) => {

  const onCreate = (params) => {
    params.eventId = info.eventId
    const count = params.count ? params.count : 1
    return axios.post(Apis.BINGO + `/bingo-card/${count}`, params)
  }

  useEffect(() => {
    setTitle("Nueva cartilla de bingo")
  })

  return (
    <CardForm value={{}} onSubmit={onCreate} setAction={setAction}/>
  )
}

export default CardNew
