import React, {useEffect, useState} from 'react'
import PageContainerFull from '../../../../components/layout/PageContainerFull'
import PageSection from '../../../../components/layout/PageSection'
import {Title} from "@wargostec/react-components";
import styled from 'styled-components'
import Card from '../../../../components/forms/Card'
import {Col, OverlayTrigger, Row, Tooltip} from 'react-bootstrap'
import {DatePickerCustom, Select} from '../../../../components/forms/FormEl'
import FormGroup from '../../../../components/forms/FormGroup'
import {getCasinoDay} from '../../../../components/util/CasinoDates'
import Button from '../../../../components/forms/Button'
import useFetch2 from '../../../../hooks/useFetch2'
import {Apis} from '../../../../util/Apis'
import LayoutLegend from '../../../crm/pages/clientes/LayoutLegend'
import AuditLayout from './AuditLayout'
import {Controller, useForm} from 'react-hook-form'
import ZoneSelect from '../../../common/components/selects/ZoneSelect'
import {HiOutlineChevronDown, HiOutlineChevronUp, HiOutlineExclamationCircle, HiOutlineXCircle} from 'react-icons/hi2'
import {FlexLayout} from '../../../../components/flex/Flex'
import {HiOutlineSlotMachine} from '../../../../components/assets/icons/slot-machine'
import MachineSearchInput from '../../../common/components/MachineSearchInput'
import {MdOutlineLinkedCamera} from 'react-icons/md'
import axios from 'axios'
import {errorToast, successToast} from '../../../../components/layout/ToastStyles'

const MachineInfo = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
`
const MachineIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    background: #e5e5e5;
`
const MachineDetails = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`
const MachineListContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
    user-select: none;
    transition: 0.3s all ease-in-out;
    @media screen and (max-width: 1440px) {
        grid-template-columns: repeat(2, 1fr);
        gap: 0.5rem;
        transition: 0.3s all ease-in-out;
    }
    @media screen and (max-width: 1024px) {
        grid-template-columns: repeat(2, 1fr);
        gap: 0.5rem;
        transition: 0.3s all ease-in-out;
    }
`
const ChipContainer = styled(FlexLayout)`
    background: #f5f5f5;
    border-radius: 8px;
    padding: 8px;
    align-items: center;
    gap: 4px;

    &:hover {
        background: #e5e5e5;
    }
`

const AuditSnapMeters = () => {

  const casinoDay = getCasinoDay()
  const {register, control, watch, setValue} = useForm({
    defaultValues: {
      period: casinoDay
    }
  })
  const selectValue = watch('modo');
  const period = watch('period');
  const zoneId = watch('zoneId');
  const confId = watch('confId');

  const params = {period: period};

  const {data, loading, fetchData} = useFetch2(`${Apis.TGM}/layout/positions`, [], params)
  const {data: dataMachines} = useFetch2(`${Apis.TGM}/machines?enabled=true`, [])
  const {data: dataSnapMachines} = useFetch2(`${Apis.TGM}/meter-day`, [], params)

  const [legendVisible, setLegendVisible] = useState(true);
  const [showAllMachines, setShowAllMachines] = useState(false);
  const [maquinasSeleccionadas, setMaquinasSeleccionadas] = useState([]);

  useEffect(() => {
    fetchData();
  }, [period]);

  const handleRemoveMaquina = (maquinaId) => {
    setMaquinasSeleccionadas(prevMaquinas => prevMaquinas.filter(maquina => maquina.confId !== maquinaId));
  };

  const filterMachines = (machines) => {
    return machines.filter(maquina => {
      return !data.positions ||
        !data.positions.some(posicion =>
          posicion.data &&
          posicion.data.collected &&
          posicion.data.confId === maquina.confId
        );
    });
  };

  const legendData = [
    {label: 'Disponible', color: '#d4d4d4'},
    {label: 'Seleccionado', color: '#eab308'},
    {label: 'Recaudado', color: '#22c55e'},
  ]

  const takeSnapMachine = async () => {
    const body = {
      confIds: maquinasSeleccionadas.map(obj => obj.confId)
    }

    axios.post(Apis.TGM + '/meter-snap/close-machine', body)
      .then(response => {
        successToast()
        fetchData()
      })
      .catch(error => {
        errorToast()
      });
  };

  useEffect(() => {
    setValue('periodo', casinoDay);
    setValue('modo', "1");
  }, [setValue, casinoDay]);

  useEffect(() => {
    if (data) {

      switch (selectValue) {
        case '1':
          if (confId) {
            const maquinasFiltradas = filterMachines(dataMachines || []);
            const maquinaSelected = maquinasFiltradas.find(maquina => maquina.confId === confId);

            if (maquinaSelected && !maquinasSeleccionadas.some(maquina => maquina.confId === maquinaSelected.confId)) {
              const { confId: selectedConfId, machine } = maquinaSelected;
              setMaquinasSeleccionadas(prevMaquinas => [...prevMaquinas, { confId: selectedConfId, machine }]);
            }
          }
          break;
        case '2':
          if (zoneId) {
            const maquinasPorZona = (dataMachines || []).filter(maquina => maquina.zoneId === Number(zoneId));
            const maquinasFiltradas = filterMachines(maquinasPorZona);
            setMaquinasSeleccionadas(maquinasFiltradas.map(maquina => ({
              confId: maquina.confId,
              machine: maquina.machine
            })));
          }
          break;
        case '3':
          const maquinasFiltradas = filterMachines(dataMachines || []);
          setMaquinasSeleccionadas(maquinasFiltradas.map(maquina => ({
            confId: maquina.confId,
            machine: maquina.machine
          })));
          break;
        default:
          setMaquinasSeleccionadas([]);
          break;
      }
    }
  }, [selectValue, zoneId, dataMachines, confId, data]);

  return (
    <PageContainerFull>
      <PageSection>
        <Title text="Toma de contadores" type={'page'}/>
        <Row>
          <Col md={4}>
            <Card padding style={{display:'flex', flexDirection:'column'}}>
              <FormGroup>
                <label>Periodo</label>
                <DatePickerCustom {...register('period')} />
              </FormGroup>
              <FormGroup>
                <label>Modo</label>
                <Select {...register('modo')}>
                  <option value={'1'}>Por Máquina</option>
                  <option value={'2'}>Por Zona</option>
                  <option value={'3'}>Todas las máquinas</option>
                </Select>
              </FormGroup>

              {selectValue === '1' &&
                <FormGroup>
                  <label>Máquina</label>
                  <Controller
                    name="confId"
                    control={control}
                    render={({field}) => <MachineSearchInput {...field} />}
                  />
                </FormGroup>
              }
              {selectValue === '2' &&
                <FormGroup>
                  <label>Zona</label>
                  <Controller
                    name="zoneId"
                    control={control}
                    render={({field}) => <ZoneSelect {...field} />}
                  />
                </FormGroup>
              }

              <FlexLayout gap='16px' column alignItems='stretch'>
                {maquinasSeleccionadas.length > 0 &&
                  <>
                    <MachineInfo>
                      <MachineIcon>
                        <HiOutlineSlotMachine size={20} />
                      </MachineIcon>
                      <MachineDetails>
                        <strong style={{fontSize: '16px'}}>{maquinasSeleccionadas.length}</strong>
                        <span style={{fontSize: '12px'}}>Maquinas seleccionadas</span>
                      </MachineDetails>
                    </MachineInfo>

                    <MachineListContainer>
                      {maquinasSeleccionadas.slice(0, showAllMachines ? undefined : 20).map((maquina, index) => {
                        const isMaquinaInData =
                          data.positions.data !== null &&
                          data.positions.some(posicion => posicion.data && posicion.data.confId === maquina.confId);
                        return (
                          <ChipContainer key={index}>
                            <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                              <strong style={{fontSize: '13px'}}>{maquina.machine}</strong>
                            </div>
                            {!isMaquinaInData &&
                              <OverlayTrigger placement={'top'}
                                overlay={<Tooltip>Maquina sin posición asignada en el layout</Tooltip>}>
                                <span style={{lineHeight: '16px'}}><HiOutlineExclamationCircle size={20}
                                  color='#737373' /></span>
                              </OverlayTrigger>
                            }
                            <HiOutlineXCircle size={20} color='#737373' style={{cursor: 'pointer'}}
                              onClick={() => handleRemoveMaquina(maquina.confId)} />
                          </ChipContainer>
                        );
                      }
                      )}
                    </MachineListContainer>
                    {maquinasSeleccionadas.length > 20 && (
                      <FlexLayout alignItems='center' justifyContent='flex-end'>
                        {showAllMachines ? (
                          <FlexLayout alignItems='center' gap='4px' style={{cursor: 'pointer'}}
                            onClick={() => setShowAllMachines(false)}>
                            <span>Ver menos</span>
                            <HiOutlineChevronUp size={16} />
                          </FlexLayout>
                        ) : (
                          <FlexLayout alignItems='center' gap='4px' style={{cursor: 'pointer'}}
                            onClick={() => setShowAllMachines(true)}>
                            <span>Ver más</span>
                            <HiOutlineChevronDown size={16} />
                          </FlexLayout>
                        )}
                      </FlexLayout>
                    )}
                  </>
                }
                <FlexLayout justifyContent='flex-end'>
                  <Button variant='dark' onClick={takeSnapMachine}>
                    <MdOutlineLinkedCamera size={20} />
                    <span>Toma de contadores</span>
                  </Button>
                </FlexLayout>
              </FlexLayout>
            </Card>

          </Col>
          <Col md={8}>
            <AuditLayout
              data={data}
              loading={loading}
              maquinasSeleccionadas={maquinasSeleccionadas}
              setMaquinasSeleccionadas={setMaquinasSeleccionadas}
              setHeight='calc(100vh - 10rem)'
              setLegendVisible={setLegendVisible}
              caption={
                <LayoutLegend
                  legendVisible={legendVisible}
                  legendData={legendData}
                />
              }
            />
          </Col>
        </Row>

      </PageSection>
    </PageContainerFull>
  )
}

export default AuditSnapMeters
