import React from "react";
import {FormBlock} from "./FormGroup";
import Loader1 from "../../modules/common/components/Loader1";
import ErrorCard from "./ErrorCard";

const NoDataTable = ({text}) => {
  return (
    <div style={{display: "flex", justifyContent: "center", fontSize: "16px", padding:"16px 0"}}>
      <strong>{text}</strong>
    </div>
  )
}


// deprecated: use V2
const LoadingTable = ({tableData, loading, data, error, text, formBlockStyle}) => {

  const firstvalue = error ?
    <ErrorCard axiosError={error}/>
    :
    <FormBlock style={formBlockStyle}>
      {
        data.length > 0
          ? tableData
          :
          <NoDataTable text={text}/>
      }
    </FormBlock>

  return (
    !loading
      ?
      firstvalue
      :
      <Loader1/>
  )
}

const LoadingTableV2 = ({children, loading, data, error, noDataText, formBlockStyle}) => {

  const firstvalue = error ?
    <ErrorCard axiosError={error}/>
    :
    <div style={formBlockStyle}>
      {
        data && data.length > 0
          ? children
          :
          <NoDataTable text={noDataText}/>
      }
    </div>

  return (
    !loading
      ?
      firstvalue
      :
      <Loader1/>
  )
}

export {NoDataTable, LoadingTable, LoadingTableV2}
