import React from "react";
import { useHistory } from "react-router";
import { Title } from "@wargostec/react-components";
import { BsStar } from "react-icons/bs";
import { AiOutlinePlus } from "react-icons/ai";
import PageContainer from "../../../../components/layout/PageContainer";
import PageSection from "../../../../components/layout/PageSection";
import Button from "../../../../components/forms/Button";
import AsyncBlock from "../../../../components/forms/AsyncBlock";
import { Row } from "../../../../components/flex/FlexLayout";
import { CouponGridContainer } from "../coupon-type/CouponTypeList";
import Card from "../../../../components/forms/Card";
import { Apis } from "../../../../util/Apis";
import EmptyState from "../../../common/components/EmptyState";
import useGet from "../../../../hooks/useGet";

const PuntosPromoList = () => {
  const history = useHistory();

  const { data, loading, error } = useGet({
    url: `${Apis.PC}/point-promo`,
    initialValue: []
  })

  const goToDetail = (id) => history.push('/crm/puntos-promociones/edit/' + id)

  return (
    <PageContainer>
      <PageSection>
        <Title text="Multiplica puntos" type={'page'} />
        <Button variant='primary' onClick={() => history.push("puntos-promociones/new")}>
          <AiOutlinePlus />
          <span>Nuevo</span>
        </Button>
      </PageSection>

      <PageSection>
        <AsyncBlock loading={loading} error={error}>
          {data && data.length > 0 ?
            <>
              {data.map(promoType =>
                <>
                  <Row style={{ fontWeight: "500", marginBottom: "10px" }}>
                  </Row>
                  <CouponGridContainer>
                    <Card key={promoType._id}
                      padding
                      style={{ cursor: 'pointer' }}
                      onClick={() => goToDetail(promoType._id)}
                    >
                      <div style={{ fontWeight: "500" }}>{promoType.name}</div>
                    </Card>
                  </CouponGridContainer>
                </>)
              }
            </>
            : <Card padding>
              <EmptyState
                title={'Configura tus promociones!'}
                subtitle={'Agregue una nueva promoción haciendo click en Nuevo.'}
                icon={<BsStar size={32} />}
              />
            </Card>
          }
        </AsyncBlock>
      </PageSection>
    </PageContainer>
  )
}

export default PuntosPromoList;
