import {Title} from "@wargostec/react-components";
import React, {useEffect, useState} from "react";
import PageContainer from "../../../../../components/layout/PageContainer";
import PageSection from "../../../../../components/layout/PageSection";
import {useHistory} from "react-router";
import {useFieldArray, useForm} from "react-hook-form";
import SalaIdInput from "../../../../common/components/SalaIdInput";
import FormGroup from "../../../../../components/forms/FormGroup";
import Input, {Select} from "../../../../../components/forms/FormEl";
import {PageBackLabel} from "../../../../../components/layout/PageBackLabel";
import Button from "../../../../../components/forms/Button";
import Card from "../../../../../components/forms/Card";
import RangeSalesProfit from "../gestionPartidas/RangeSalesProfit";

const EventForm = ({data, onSubmit, mode = true}) => {

  const history = useHistory()

  const {
    register,
    handleSubmit,
    watch,
    control,
    reset
  } = useForm();

  const {fields, append, remove} = useFieldArray({
    control, name: "listRanges"
  });
  const [rangeArray, setRangeArray] = useState([])

  const watchDinamic = watch("event.dinamic")

  const addRanges = () => {
    append({fromMoney: '', toMoney: '', percentageEnable: '', commissionEnable: ''});
  }

  useEffect(() => {
    if (data) {
      reset(data)
    }
  }, [data])

  return (
    <PageContainer>
      <PageSection>
        <PageBackLabel text='Regresar' onClick={() => history.goBack()}/>
          <Title text={mode ? "Edición de evento de bingo" : "Nuevo evento de bingo"} type={'page'}/>
        <Button onClick={handleSubmit(onSubmit)} variant={'primary'}>
          Guardar
        </Button>
      </PageSection>
      <PageSection>
        <Card padding>
          <SalaIdInput  {...register("event.salaId")}/>
          <FormGroup>
            <label>Nombre</label>
            <Input type='text' {...register("event.name")}/>
          </FormGroup>
          <FormGroup>
            <label>Modo</label>
            <Select disabled={mode} {...register("event.automatic")}>
              <option key={1} value={'true'}>VIRTUAL</option>
              <option key={2} value={'false'}>MANUAL</option>
            </Select>
          </FormGroup>
          <FormGroup>
            <label>Numero de bolitas</label>
            <Select disabled={mode} {...register("event.type")}>
              <option key={75} value={75}>75</option>
              <option key={90} value={90}>90</option>
            </Select>
          </FormGroup>
        </Card>
      </PageSection>
      <PageSection>
        <Card padding>
            <Title text="Reglas" type={'page'}/>
          <SalaIdInput  {...register("event.salaId")}/>
          <FormGroup>
            <label>Tipo de premio</label>
            <Select disabled={mode} {...register("event.prizeType")}>
              <option key={1} value={1}>EFECTIVO</option>
            </Select>
          </FormGroup>
          <FormGroup>
            <label>Dinamica de premio</label>
            <Select disabled={mode} {...register("event.dinamic")}>1
              <option key={1} value={1}>FIJO</option>
              <option key={2} value={2}>VARIABLE</option>
            </Select>
          </FormGroup>
          {watchDinamic == 1 &&
            <div style={{display: 'flex', flexDirection: 'row', gap: 10}}>
              <FormGroup>
                <label>Comisíon para vendedores (%)</label>
                <Input disabled={mode} type='number' {...register("event.percentage")}/>
              </FormGroup>
            </div>
          }
          {/*<FormGroup>*/}
          {/*  <label>Precio cartón</label>*/}
          {/*  <Input disabled={mode} type='number' step="0.01" {...register("pevent.rice")}/>*/}
          {/*</FormGroup>*/}
          <div style={{display: 'flex', flexDirection: 'row', gap: 10}}>
            <FormGroup>
              <label>Compra minima</label>
              <Input disabled={mode} type='number' {...register("event.minBuy")}/>
            </FormGroup>
            <FormGroup>
              <label>Compra máxima</label>
              <Input disabled={mode} type='number' {...register("event.maxBuy")}/>
            </FormGroup>
          </div>
        </Card>


        {watchDinamic != 1 &&

          <Card padding>
            <RangeSalesProfit
              fields={fields}
              dayOfWeekArray={rangeArray}
              setDayOfWeekArray={setRangeArray}
              register={register}
              remove={remove}
              addRanges={addRanges}
              modeDelete={false}
            />
          </Card>

        }

      </PageSection>
    </PageContainer>
  )
}

export default EventForm
