import styled from "styled-components"
import React from "react"

const ParentDiv = styled.div`
  display: flex;
  flex-direction: row;
`

const Option = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 36px;
  border-radius: 4px;
  cursor: ${props => (props.selected ? "default" : "pointer")};
  color: ${props => (props.selected ? "rgb(255, 255, 255) !important" : "rgb(103, 115, 126)")};
  background-color: ${props => (props.selected ? "#37ba32" : "inherit")};
  user-select: none;
  text-align: center;
`

const ButtonGroup = ({value, onChange, data}) => {
  
  return (
    <ParentDiv>
      {data.map(item => (
        <Option
          key={item.value}
          selected={value === item.value}
          onClick={() => onChange(item.value)}
        >
          {item.text}
        </Option>
      ))}
    </ParentDiv>
  )
}

export default ButtonGroup
