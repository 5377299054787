import React, {useCallback, useEffect, useMemo, useState} from "react";
import PageContainer from "../../../../../components/layout/PageContainer";
import PageSection from "../../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import Button from "../../../../../components/forms/Button";
import {Apis} from "../../../../../util/Apis";
import useFetch2 from "../../../../../hooks/useFetch2";
import useEditModal from "../../../../../modals/useEditModal";
import GenericModal2 from "../../../../../modals/GenericModal2";
import {FlexLayout} from "../../../../../components/flex/Flex";
import ReactTable from "../../../../../components/util/ReactTable";
import AsyncBlock from "../../../../../components/forms/AsyncBlock";
import useModal from "../../../../../modals/useModal";
import ModalInfo from "../../../../../modals/ModalInfo";
import VentaCardNew from "./VentaCardNew";
import VentaCardDetail from "./VentaCardDetail";
import Slidebar from "../../../../common/layout/Slidebar";
import {DatePickerCustom} from "../../../../../components/forms/FormEl";
import FormGroup from "../../../../../components/forms/FormGroup";
import useToggle from "../../../../../hooks/useToggle";
import {useForm} from "react-hook-form";
import Moment from "react-moment";

const VentaCardList = () => {
  const querystring = window.location.search
  const query = new URLSearchParams(querystring)
  const {isOpen: slideOpen, toggler} = useToggle({});

  let defaultParams = {}
  const [params, setParams] = useState(defaultParams)
  const {register, handleSubmit} = useForm({defaultValues: defaultParams});

  const getData = (params) => {
    setParams(params)
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  let event = query.get('event')

  const {loading, data, fetchData, error} = useFetch2(Apis.BINGO + `/bingo-card/sala`, [], params);
  const {wrapper: wrapper1, addAction} = useEditModal({
    addComponent: <VentaCardNew />,
    onRefresh: fetchData
  })

  const {wrapper: wrapper3, someAction: mostrarAction} = useModal({
    component: <VentaCardDetail />
  })

  const actionsValue = useCallback(props => <>{
    <FlexLayout justifyContent='center'>
      <Button variant='light' size='s' onClick={() => {
        mostrarAction({
          customerId: props.row.original.customerId,
          sorteoId: props.row.original.sorteoId,
          customerName: props.row.original.customerName
        })
      }
      }>Ver cartillas</Button>
    </FlexLayout>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }</>, []);

  const clienteCell = useCallback(props => <>{
    <FlexLayout alignItems='center' gap='4px'>
      <span>{props.renderValue() ? props.renderValue() : 'No definido'}</span>
    </FlexLayout>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }</>, []);

  const fechaCell = useCallback(props => <>{
    <FlexLayout alignItems='center' justifyContent='center' gap='4px'>
      <Moment format={'YYYY-MM-DD hh:mm a'}>{props.renderValue()}</Moment>
    </FlexLayout>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }</>, []);

  const columns = useMemo(
    () => [
      {
        header: 'Partida',
        accessorKey: 'raffleDate',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: fechaCell
      },
      {
        header: 'Cliente',
        accessorKey: 'customerName',
        cell: clienteCell
      },
      {
        header: 'Cantidad',
        accessorKey: 'quantity',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Acciones',
        accessorKey: 'data',
        enableGlobalFilter: false,
        enableSorting: false,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: actionsValue
      }
    ]
    , []
  )

  return (
    <PageContainer>
      <Slidebar visible={slideOpen} onHide={toggler}>
        <div style={{padding: '16px'}}>
          <FormGroup>
            <label>Desde</label>
            <DatePickerCustom {...register("ini")} />
          </FormGroup>
          <FormGroup>
            <label>Hasta</label>
            <DatePickerCustom {...register("fin")} />
          </FormGroup>
          <Button variant={'primary'} title='Buscar' onClick={handleSubmit(getData)} />
        </div>
      </Slidebar>
      <PageSection>
          <Title text="Lista de cartones" type={'page'}/>

        <AsyncBlock loading={loading} error={error}>
          <ReactTable
            columns={columns}
            data={data || []}
            filterOnclick={toggler}
            filterAdd={() => addAction({eventId: event})}
            customSearch
            noData='No existen resultados'
            pageSize={20}
          />
        </AsyncBlock>

      </PageSection>
      <GenericModal2 {...wrapper1} />
      <ModalInfo size="lg" {...wrapper3} />
    </PageContainer>
  )
}

export default VentaCardList
