import React from "react";
import styled from "styled-components";
import {AnimatePresence, motion} from "framer-motion";

const ControlsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  border-radius:8px;
  box-shadow: rgb(0 0 0 / 10%) 0 2px 4px;
  background:white;
  padding:16px;
`
const LegendItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  span{
    flex:1;
    font-size:13px;
  }
  div{
    width: 32px;
    height: 12px;
    border-radius: 4px;
  }
`

const LayoutLegend = ({legendVisible,legendData}) => {

  return (

    <AnimatePresence>
      {legendVisible && (
        <motion.div
          initial={{opacity: 0, y: -20}}
          animate={{opacity: 1, y: 0}}
          exit={{opacity: 0, y: -20}}
          style={{display: 'flex', flexDirection: 'column', width: '220px'}}
        >
          <ControlsContainer>
            <strong>Leyenda</strong>
            {legendData && legendData.map((item, index) => (
              <LegendItem key={index}>
                <span>{item.label}</span>
                <div style={{ background: item.color }}></div>
              </LegendItem>
            ))}
          </ControlsContainer>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default LayoutLegend;
