import React from "react";

const Sidebar = ({items}) => {

  return (
    <>
      {items}
    </>
  );
};

export default Sidebar;
