import React from 'react'
import {Select} from "../../../../components/forms/FormEl";

const CardTypeSelect = React.forwardRef((props, ref) => {

  return (
    <Select ref={ref} {...props}>
      <option value={'VISA'}>VISA</option>
      <option value={'MASTERCARD'}>MASTERCARD</option>
    </Select>
  )
})

export default CardTypeSelect
