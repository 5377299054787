import React, {useContext, useEffect, useState} from 'react';
import axios from "axios";
import {Apis} from "../../../../../util/Apis";
import {errorToast, successToast} from "../../../../../components/layout/ToastStyles";
import RangeConfig from "../gestionJackpots/RangeConfig";
import {useFieldArray, useForm} from "react-hook-form";
import {SiteContext} from "../../../../common/context/SiteContext";

const RangeNew = ({setAction, setTitle}) => {

  const {salaId} = useContext(SiteContext)

  const [rangeArray, setRangeArray] = useState([])
  const {register, handleSubmit, reset, watch,control} = useForm({});
  const {fields, append, remove} = useFieldArray({
    control, name: "listRanges"
  });

  const onCreate = (params) => {
    if (params) {
      params.listRange = params.listRanges.map(range => {
        range.fromBall = parseInt(range.fromBall)
        range.toBall = parseInt(range.toBall)
        range.salaId = parseInt(salaId)
        return range
      })
    }
    axios.post(Apis.BINGO + '/range-increase', params).then(res => {
      successToast("Rangos guardado correctamente")
    }).catch(err => {
      errorToast()
    })
  }

  useEffect(() => {
    setTitle("Nuevos rangos")
  })

  useEffect(() => {
    setAction(() => handleSubmit(onCreate))
  }, [])

  const addRanges = () => {
    append({fromBall: '', toBall: '', percentage: ''});
  }

  return (
    <div>
      <RangeConfig
        fields={fields}
        dayOfWeekArray={rangeArray}
        setDayOfWeekArray={setRangeArray}
        register={register}
        remove={remove}
        addRanges={addRanges}
        modePer={false}
      />

    </div>
  );
};

export default RangeNew;
