import React, {useEffect} from 'react';
import useFetch2 from "../../../../../hooks/useFetch2";
import {Apis} from "../../../../../util/Apis";
import axios from "axios";
import SalesProfitForm from "./SalesProfitForm";

const SalesProfitEdit = ({id, setAction, setTitle}) => {

  const {data} = useFetch2(Apis.BINGO + '/range-sales-profit/' + id, [])

  const onEdit = (params) => {
    return axios.put(Apis.BINGO + '/range-sales-profit/' + id, params)
  }

  useEffect(() => {
    setTitle("Edición de rango de ganancia")
  })

  return (
    <SalesProfitForm value={data} onSubmit={onEdit} setAction={setAction}/>
  );
};

export default SalesProfitEdit;
