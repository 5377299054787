import React, {useState} from "react";
import Table from "react-bootstrap/Table";
import GameEditModal from "../../modals/GameEditModal";
import PageContainer from "../../../../components/layout/PageContainer";
import PageSection from "../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import {SecondaryButton} from "../../../../components/forms/MyButton";
import {FormBlock} from "../../../../components/forms/FormGroup";
import {Apis} from "../../../../util/Apis";
import useFetch2 from "../../../../hooks/useFetch2";

const GamesReport = () => {

  const {loading, data, fetchData} = useFetch2(Apis.RGS + '/games', []);
  const [show, setShow] = useState(false);

  const handleClose = () => {
    fetchData();
    setShow(false);
  }
  const handleShow = () => setShow(true);

  const [game, setGame] = useState({});

  const editGame = (item) => {
    console.log(item);
    setGame(item);
    handleShow();
  };

  return (
    <PageContainer>
      <PageSection>
          <Title text="Reporte de juegos"/>
      </PageSection>

      <PageSection>
        {
          loading ? <h6>Cargando...</h6>
            :

            <FormBlock>
              <Table striped hover>
                <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Imagen</th>
                  <th>Fondo</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                {data.map(item =>
                  <tr>
                    <td>{item.id}</td>
                    <td>{item.name}</td>
                    <td>
                      <img src={item.imgUrl} style={{width: "100px"}}/>
                    </td>
                    <td>
                      <img src={item.backgroundUrl} style={{width: "100px"}}/>
                    </td>
                    <td>
                      <SecondaryButton onClick={() => editGame(item)}>Editar</SecondaryButton>
                    </td>
                  </tr>
                )}
                </tbody>
              </Table>
            </FormBlock>
        }
      </PageSection>

      <GameEditModal show={show} onHide={handleClose} game={game}/>
    </PageContainer>
  )
}

export default GamesReport;
