import React, {useEffect, useState} from 'react'
import {SelectV2} from "../../../../components/forms/FormEl";
import {Apis} from "../../../../util/Apis";
import useFetch2 from "../../../../hooks/useFetch2";
import Select from "react-select";
import {useHistory} from "react-router";

const MachineGroupSelect = React.forwardRef((props, ref) => {

  const history = useHistory()

  const {data, loading} = useFetch2(Apis.TGM + '/machine-groups',[])

  const [selectedOption, setSelectedOption] = useState(null)
  const [optionsFormat, setOptionsFormat] = useState([])

  const prevOnChange = (selected) => {
    props.onChange(selected.value)
  }

  const customOption = (option) => {
    if (option.button) {
      return (
        <div onClick={() => history.push('/tgm/machine-set')}
             style={{color: '#5276F1', cursor: 'pointer', textDecoration: 'underline'}}>
          {option.label}
        </div>
      );
    } else {
      return option.label;
    }
  };

  useEffect(() => {
    const selected = optionsFormat.find(option => option.value === props.value)
    setSelectedOption(selected)
  }, [props.value])

  useEffect(() => {
    if (data) {
      const formatted = data.map((str) => {
        return {value: str.id, label: str.name};
      });
      formatted.push({value: 0, label: 'TODOS'}, {value: "new", label: 'Nuevo', button: true})
      setOptionsFormat(formatted)
      if (props.value) {
        const selected = formatted.find(option => option.value === props.value)
        setSelectedOption(selected)
      }
    }
  }, [data])

  return (
    <>
      {
        !loading ?
          <Select
            value={selectedOption}
            onChange={prevOnChange}
            options={optionsFormat.map(option => ({
              ...option,
              label: customOption(option),
            }))}
            styles={{
              option: (provided, state) => ({
                ...provided,
                ...(state.data.value === 'new' && { '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0)' } }),
              }),
            }}
          />
          :
          <SelectV2 disabled options={[{id: 0, name: 'Cargando...'}]}/>
      }
    </>
  )
})

export default MachineGroupSelect;
