import React from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {FaInfoCircle} from 'react-icons/fa'

const TextWithHelp = ({label, help}) => {
  return (
    <div style={{display: 'flex', alignItems: 'center', gap: '4px', marginBottom: '0.2rem'}}>
      <label style={{marginBottom: '0'}}>{label}</label>
      <OverlayTrigger
        placement={'top'}
        overlay={
          <Tooltip>
            <span style={{fontSize: '12px'}}>{help}</span>
          </Tooltip>
        }
      >
        <span><FaInfoCircle size={16} /></span>
      </OverlayTrigger>
    </div>
  )
}

export default TextWithHelp