import React, {useEffect} from 'react';
import {Controller, useForm} from "react-hook-form";
import FormGroup, {FormBlockTitle} from "../../../../../components/forms/FormGroup";
import SalaHiddenInput from "../../../../common/components/SalaHiddenInput";
import SwitchToggle from "../../../../../components/layout/SwitchToggle";
import Input from "../../../../../components/forms/FormEl";
import DenomSelect from "../../../../common/components/selects/DenomSelect";
import useFetch2 from "../../../../../hooks/useFetch2";
import {Apis} from "../../../../../util/Apis";
import axios from "axios";
import Button, {ButtonBox} from "../../../../../components/forms/Button";
import {GrContract} from "react-icons/gr";
import {useHistory} from "react-router";
import SlotGroupSelectV2 from "../../../../common/components/selects/SlotGroupSelectV2";
import PangolinSelect from "../../../../common/components/selects/PangolinSelect";
import useEditModal from "../../../../../modals/useEditModal";
import MachineMove from "./MachineMove";
import GenericModal2 from "../../../../../modals/GenericModal2";
import ZoneSelect from "../../../../common/components/selects/ZoneSelect";
import {errorToast, successToast} from "../../../../../components/layout/ToastStyles";
import useDeleteModal from "../../../../../modals/useDeleteModal";
import DeleteGenericModal from "../../../../../modals/DeleteGenericModal";

const MachineEditComponent = ({id, slideOpen, refresh}) => {

  const history = useHistory()
  const {data, loading} = useFetch2(Apis.TGM + '/machines/' + id, []);
  const {register, handleSubmit, reset, control} = useForm();

  const {wrapper: wrapper1, editAction} = useEditModal({
    editComponent: <MachineMove/>,
    onRefresh: () => refresh(true)
  })

  const update = (params) => {
    axios.put(Apis.TGM + '/machines/' + id, params)
      .then(res => {
        successToast("Máquina actualizada correctamente",1000)
        slideOpen(false)
        refresh(true)
      })
      .catch(err => {
        errorToast(err.response.data.message ? err.response.data.message : "Ocurrió un problema. Por favor intenta nuevamente",2000)
      })
  }

  const goToEdit = (id) => {
    history.push('/tgm/machines/edit/' + id)
  }

  useEffect(() => {
    if (data) {
      reset(data)
    }
  }, [data])

  const removePangolin = id => axios.post(Apis.TGM + '/machines/' + id + '/remove-pangolin')

  const {wrapper: wrapper2, openDeleteModal} = useDeleteModal({
    text: 'Al remover el equipo, la maquina dejara de reportar contadores y tickets.',
    action: removePangolin,
    onRefresh: () => {
      refresh(true)
      slideOpen(false)
      successToast("Equipo removido correctamente",1000)
    }
  })

  return (
    <>
      <div style={{display: 'flex', float: 'right', cursor: 'pointer'}} onClick={() => goToEdit(id)}>
        <div style={{paddingRight: '5px'}}>
          Mas detalle
        </div>
        <div>
          <GrContract/>
        </div>
      </div>
      <FormBlockTitle>General</FormBlockTitle>
      <SalaHiddenInput {...register("date")}/>
      <FormGroup>
        <SwitchToggle label={"Habilitado"} {...register("enabled")} id={"enabled"}/>
      </FormGroup>
      <FormGroup>
        <label>Nombre</label>
        <Input {...register("machine")}/>
      </FormGroup>

      <FormGroup>
        <label>Pangolin (SMIB)</label>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <div style={{width: '100%'}}>
            <Controller
              name="blueSn"
              control={control}
              render={({field}) => <PangolinSelect {...field} />}
            />
          </div>
          <div>
            <Button style={{marginLeft: '10px'}} variant={'danger'} title='Remover' size={'xs'}
                    onClick={() => openDeleteModal(id)}/>

          </div>
        </div>
      </FormGroup>

      <FormGroup>
        <label>Fab. y modelo</label>
        <Controller
          name="groupId"
          control={control}
          render={({field}) => <SlotGroupSelectV2 {...field} />}
        />
      </FormGroup>

      <FormGroup>
        <label>Juego</label>
        <Input {...register("gameName")}/>
      </FormGroup>

      <FormGroup>
        <label>Serie</label>
        <Input {...register("serialNumber")}/>
      </FormGroup>

      <FormGroup>
        <label>Denominacion</label>
        <DenomSelect {...register("den")}/>
      </FormGroup>

      <FormGroup>
        <label>Zona</label>
        <ZoneSelect {...register("zoneId")}/>
      </FormGroup>
      
      <FormGroup>
        <label>Posición</label>
        <Input {...register("positionId")}/>
      </FormGroup>

      <FormGroup>
        <label>Reg. MINCETUR</label>
        <Input {...register("numReg")}/>
      </FormGroup>

      {/*<FormGroup>*/}
      {/*  <SwitchToggle label={"AFT Habilitado"} {...register("aftEnabled")} id={"aftEnabled"}/>*/}
      {/*  <label>N° Asset</label>*/}
      {/*  <Input {...register("assetNumber")}/>*/}
      {/*</FormGroup>*/}

      <ButtonBox>
        <Button variant={'dark'} title='Guardar' onClick={handleSubmit(update)}/>
        <Button title='Dar de baja' onClick={()=>editAction(id)} />
      </ButtonBox>

      <GenericModal2 {...wrapper1}/>
      <DeleteGenericModal {...wrapper2} />
    </>
  );
};

export default MachineEditComponent;
