import {TbFileX, TbWorldCancel, TbWorldOff} from "react-icons/tb";
import {BiLock} from "react-icons/bi";
import {MdOutlineError} from "react-icons/md";
import Card from "./Card";
import {FlexLayout} from "../flex/Flex";
import React from "react";

const ErrorCard = ({axiosError}) => {
  let errorMessage = '';
  let icon;

  switch (axiosError.response?.status) {
    case 400:
      errorMessage = 'La solicitud no pudo ser procesada.';
      icon = <TbWorldCancel size={50}/>;
      break;
    case 403:
      errorMessage = 'No tienes permisos para realizar esta operación.';
      icon = <BiLock size={50}/>;
      break;
    case 404:
      errorMessage = 'La información que buscas no existe.';
      icon = <TbFileX size={50}/>;
      break;
    case 500:
      errorMessage = 'Ups, tuvimos un inconveniente. El equipo ha sido notificado y estamos trabajando para solucionarlo.';
      icon = <TbWorldOff size={50}/>;
      break;
    default:
      errorMessage = 'Ocurrió un error inesperado.';
      icon = <MdOutlineError size={50}/>;
      break;
  }

  return (
    <Card>
      <FlexLayout column alignItems="center">
        <FlexLayout justifyContent="center" padding="16px"
                    style={{background: '#d4d4d4', width: '100%', borderRadius: '8px 8px 0 0'}}>
          {icon}
        </FlexLayout>
        <FlexLayout column alignItems="center" padding="16px">
          <strong style={{fontSize: '32px'}}>{axiosError.response?.status}</strong>
          <span style={{fontSize: '16px'}}>{errorMessage}</span>
        </FlexLayout>
      </FlexLayout>
    </Card>
  )
}

export default ErrorCard
