import React, {useContext, useEffect, useState} from 'react';
import PageSection from "../../../../components/layout/PageSection";
import {Title, Button} from "@wargostec/react-components";
import {ButtonBox} from "../../../../components/forms/Button";
import Card, {CardBody, CardHeader} from "../../../../components/forms/Card";
import PageContainer from "../../../../components/layout/PageContainer";
import {SiteContext} from "../../../common/context/SiteContext";
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import {getDateFilter} from "../../../../components/util/CasinoDates";
import Chart from "react-apexcharts";
import Row from "react-bootstrap/Row";
import AsyncBlock from "../../../../components/forms/AsyncBlock";
import Slidebar from "../../../common/layout/Slidebar";
import moment from "moment";
import axios from "axios";
import {TfiReload} from "react-icons/tfi";
import DateFilter from '../../../../components/datefilter/DateFilter';

const ProduccionPorModelo = () => {

  const {salaId} = useContext(SiteContext)
  const date = getDateFilter('month')

  const fechasDefault = {ini: date.ini, fin: date.fin}
  const [fechas, setFechas] = useState(fechasDefault)
  let defaultParams = {salaId: salaId, ini: fechas.ini, fin: fechas.fin}
  const [dataTotal, setDataTotal] = useState([])
  const [dataAvg, setDataAvg] = useState([])
  const [slideOpen, setSlideOpen] = useState(false)
  const [groupData, setGroupData] = useState([])
  const [title, setTitle] = useState('')

  const [params, setParams] = useState(defaultParams)

  const {loading, data, fetchData} = useFetch2(Apis.STATISTIC + '/statistic/prod-total', [], params);

  const {
    loading: loading2,
    data: data2,
    fetchData: fecthData2
  } = useFetch2(Apis.STATISTIC + '/statistic/prod-average', [], params);

  useEffect(() => {
    if (params) fetchData()
    fecthData2()
  }, [params])

  useEffect(() => {
    setParams({...params, ...fechas})
  }, [fechas])

  useEffect(() => {
    if (data) {
      setDataTotal(data.sort((a, b) => b.prodSum - a.prodSum))
    }
    if (data2) {
      setDataAvg(data2.sort((a, b) => b.avgProdSoles - a.avgProdSoles))
    }
  }, [data, data2])

  const barProdModel = {
    options: {
      chart: {
        type: 'bar',
        height: 400,
        events: {
          dataPointSelection: (event, chartContext, config) => {
            infoModel(config.w.config.groupId[config.dataPointIndex], config.w.config.xaxis.categories[config.dataPointIndex])
          }
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 4
        },
      },
      colors: ["#59da1a"],
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 4,
        colors: ['transparent']
      },
      fill: {
        opacity: 1
      },
      legend: {
        show: false,
        showForSingleSeries: true
      },
      tooltip: {
        intersect: false,
        shared: true,
        onDatasetHover: {
          highlightDataSeries: true,
        },
      },
      xaxis: {
        categories: data && dataTotal.map(item => item.groupName ? item.groupName : 'NA')
      },
      groupId: data && dataTotal.map(item => item.groupId),
    },
    series: [
      {
        name: "Producción",
        data: data && dataTotal.map(item => item.prodSum)
      }
    ]
  };

  const barProdModelAvg = {
    options: {
      chart: {
        type: 'bar',
        height: 400
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 4
        },
      },
      colors: ["#feb019"],
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 4,
        colors: ['transparent']
      },
      fill: {
        opacity: 1
      },
      legend: {
        show: false,
        showForSingleSeries: true
      },
      tooltip: {
        intersect: false,
        shared: true,
        onDatasetHover: {
          highlightDataSeries: true,
        },
      },
      xaxis: {
        categories: data2 && dataAvg.map(item => item.groupName ? item.groupName : 'NA')
      }
    },
    series: [
      {
        name: "Producción",
        data: data2 && dataAvg.map(item => item.avgProdSoles?.toFixed(2))
      }
    ]
  };

  const barGroupId = {
    options: {
      chart: {
        type: 'bar',
        height: 800
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true
        },
      },
      xaxis: {
        categories: groupData && groupData.map(item => (moment().month(item.month - 1).format('MMM') + ' ' + item.year))
      },
      colors: ['#068eef'],
      legend: {
        show: true,
        showForSingleSeries: true
      },
      fill: {
        opacity: 1
      },
    },
    series: [
      {
        name: "Monto",
        data: groupData && groupData.map(item => item.ciAvg.toFixed(2))
      }
    ]
  };

  const infoModel = (id, groupName) => {
    setTitle(groupName)
    if (id === 0) {
      return
    }
    setSlideOpen(!slideOpen)
    axios.get(`${Apis.STATISTIC}/statistic/prod-history-by-group?groupId=${id}`, {params: params})
      .then((data) => {
        setGroupData(data.data);
      })
  }

  return (
    <PageContainer>

      <Slidebar title={title} visible={slideOpen} onHide={() => setSlideOpen(false)}>
        <div style={{padding: '16px'}}>
          <Chart
            options={barGroupId.options}
            series={barGroupId.series}
            type="bar"
            height="800"
          />
        </div>
      </Slidebar>

      <PageSection>
        <Title text="Producción por modelo" type={'page'}/>

        <ButtonBox style={{marginBottom: "20px"}}>
            <Button variant="success" onClick={fetchData}
                    icon={<TfiReload />}/>
        </ButtonBox>

        <Card padding style={{background: '#0A0A0A'}}>
          <DateFilter fechas={fechas} setFechas={setFechas} modeDate={'month'}/>
        </Card>

        <Card padding>
          <Row>
            <AsyncBlock loading={loading}>
              <CardHeader>
                <strong>Produccion total por modelo</strong>
              </CardHeader>
              <CardBody>
                <Chart
                  options={barProdModel.options}
                  series={barProdModel.series}
                  type="bar"
                  height="400"
                />
              </CardBody>
            </AsyncBlock>
          </Row>
          <Row>
            <AsyncBlock loading={loading2}>
              <CardHeader>
                <strong>Produccion promedio por modelo</strong>
              </CardHeader>
              <CardBody>
                <Chart
                  options={barProdModelAvg.options}
                  series={barProdModelAvg.series}
                  type="bar"
                  height="400"
                />
              </CardBody>
            </AsyncBlock>
          </Row>
        </Card>
      </PageSection>
    </PageContainer>
  );
};

export default ProduccionPorModelo;
