import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { useMediaQuery } from "../../hooks/useMediaQuery";

const MenuItemSpan = styled.span`
    display: block;
    padding:8px 12px;
    color: #fff;
    text-decoration: none;
    background: transparent;
    font-size: 0.85rem;
    font-weight: 400;
    transition: color 100ms linear 0s;
    
    &:hover{
      background:#404040;
      border-radius:6px;
    }
`

const DropdownMenuItem = ({ title, link, onClick,style }) => {

  let isMobile = useMediaQuery('(min-width: 992px)')
  return (
    <li>
      {isMobile ?
        <NavLink to={link} activeClassName="myActiveLink">
          <div>
            <MenuItemSpan style={style}>{title}</MenuItemSpan>
          </div>
        </NavLink>
        :
        <NavLink to={link} activeClassName="myActiveLink" onClick={onClick}>
          <div>
            <MenuItemSpan style={style}>{title}</MenuItemSpan>
          </div>
        </NavLink>
      }

    </li>
  )
};

export default DropdownMenuItem;
