import React, { useContext, useState } from 'react';
import { SiteContext } from "../../../common/context/SiteContext";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router";
import axios from "axios";
import { Apis } from "../../../../util/Apis";
import { errorToast, successToast } from "../../../../components/layout/ToastStyles";
import PageContainerFull from "../../../../components/layout/PageContainerFull";
import PageSection from "../../../../components/layout/PageSection";
import { PageBackLabel } from "../../../../components/layout/PageBackLabel";
import { Title } from "@wargostec/react-components";
import FormGroup from "../../../../components/forms/FormGroup";
import FileUploader from "../../../common/components/FileUploader";
import Button from "../../../../components/forms/Button";
import Card from "../../../../components/forms/Card";
import { LoadingTable } from "../../../../components/forms/Tables";
import Table from "react-bootstrap/Table";
import useToggle from '../../../../hooks/useToggle';
import { usePost } from '../../../../hooks/usePost';

const UploadMassivePoints = () => {

  const { salaId } = useContext(SiteContext)
  const history = useHistory();
  const { control, getValues, watch } = useForm()

  const [points, setPoints] = useState(null);
  const { isOpen: validFile, setIsOpen: setValidFile } = useToggle({})
  const { loading, mutate } = usePost({
    url: `${Apis.PC}/point-batch`,
    onSuccess: () => {
      successToast("Carga de puntos correctamente", 3000)
      history.push(`/crm/upload-points-list`)
    },
    onError: (error) => {
      errorToast(error.response.data.message, 6000)
    }
  })

  const watchImgUrl = watch("imgUrl")

  const prevAction = () => {
    setValidFile(false)
    let fileUrl = getValues('imgUrl')
    const params = { salaId: salaId, fileName: fileUrl }

    axios.get(`${Apis.PC}/point-batch/preview`, { params: params })
      .then(res => {
        setPoints(res.data)
        setValidFile(true);
      }).catch(() => {
        errorToast("Consulte el formato del archivo, de ser correcto intente en unos minutos")
        setValidFile(false)
      })
  }

  const savePoints = async () => {
    const formBody = {
      fileName: getValues('imgUrl'),
      salaId: salaId,
    }

    await mutate(formBody)
  }

  return (
    <PageContainerFull>
      <PageSection>
        <PageBackLabel text='Lista de cargas' onClick={() => history.push('/crm/upload-points-list')} />
        <Title text="Nueva carga" type={'page'} />
      </PageSection>

      <PageSection>
        <Card padding style={{ minHeight: "150px", padding: "25px", paddingTop: "50px" }}>
          <FormGroup style={{ paddingLeft: "36px" }}>
            <Controller name="imgUrl" control={control} render={({ field }) => <FileUploader folder={'points'} {...field} />} />
            {watchImgUrl &&
              <React.Fragment>
                <div style={{ display: "flex", marginBottom: "20px", marginTop: "30px" }}>
                  <Button variant={'primary'} title={'Previsualizar'} onClick={prevAction} />
                  <p style={{ paddingTop: "30px", paddingLeft: "20px" }}>
                    Se mostrara una lista referencial de los puntos contenidos en el archivo
                  </p>
                </div>
              </React.Fragment>
            }
          </FormGroup>
        </Card>
        {
          points &&
          (validFile ?
            <>
              <Card padding>
                <React.Fragment>
                  <LoadingTable formBlockStyle={{ maxHeight: "700px", overflowX: "auto" }}
                    text="El documento no presenta puntos o es un formato inválido" loading={false}
                    data={points} tableData={
                      <Table striped hover>
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Documento</th>
                            <th>Nombres</th>
                            <th>Apellidos</th>
                            <th>Cantidad</th>
                          </tr>
                        </thead>
                        <tbody>
                          {points && points.map((item, index) =>
                            <tr key={index}>
                              <td>{item.customerId}</td>
                              <td>{item.document}</td>
                              <td>{item.firstName}</td>
                              <td>{item.lastName}</td>
                              <td>{item.point}</td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    } />
                </React.Fragment>
              </Card>

              <Card padding>
                <FormGroup style={{ display: "flex", flexWrap: "wrap", justifyContent: "flex-end", marginTop: "40px" }}>
                  <Button variant={'primary'}
                    disabled={loading}
                    onClick={() => savePoints()}>
                    {loading ? 'Cargando' : 'Cargar puntos'}
                  </Button>
                </FormGroup>
              </Card>
            </>
            :
            <Card padding>
              <FormGroup>
                <h1 style={{ color: "red" }}>
                  Se ha subido un archivo incorrecto, revise la extensión o el contenido.
                </h1>
              </FormGroup>
            </Card>
          )}
      </PageSection>
    </PageContainerFull>
  );
};

export default UploadMassivePoints;
