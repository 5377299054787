import React, {useEffect} from 'react';
import {useForm} from "react-hook-form";
import FormGroup from "../../../../../components/forms/FormGroup";
import Input, {Select} from "../../../../../components/forms/FormEl";
import SalaIdInput from "../../../../common/components/SalaIdInput";

const FundTypeForm = ({value, onSubmit, setAction}) => {

  const {register, handleSubmit, reset} = useForm();

  useEffect(() => {
    if (value) {
      reset(value)
    }
  }, [reset, value])

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit))
  }, [])

  return (
    <React.Fragment>
      <SalaIdInput {...register("salaId")}/>
      <FormGroup>
        <label>Nombre</label>
        <Input name="name" {...register("name")}/>
      </FormGroup>

      <FormGroup>
        <label>Tipo</label>
        <Select {...register("type")}>
          <option key={1} value={1}>BOVEDA</option>
          <option key={2} value={2}>CAJA</option>
          <option key={3} value={3}>FONDO EXTERNO</option>
        </Select>
      </FormGroup>

    </React.Fragment>
  );
};

export default FundTypeForm;
