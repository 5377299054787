import React from "react";
import Table from "react-bootstrap/Table";
import Card from "../../../../../components/forms/Card";
import useFetch2 from "../../../../../hooks/useFetch2";
import {Apis} from "../../../../../util/Apis";
import {LoadingTableV2} from "../../../../../components/forms/Tables";

const ManufacturerList = () => {

  const {loading, data, error} = useFetch2(Apis.TGM + '/slot-mark', []);

  return (
    <Card padding>
      <LoadingTableV2 loading={loading} error={error} data={data}>

        <Table striped hover>
          <thead>
          <tr>
            <th>ID</th>
            <th>Fabricante</th>
          </tr>
          </thead>
          <tbody>
          {data && data.map((item, index) =>
            <tr>
              <td>{item.id}</td>
              <td>{item.fullName}</td>
            </tr>
          )}
          </tbody>
        </Table>
      </LoadingTableV2>
    </Card>
  )
}

export default ManufacturerList;
