import React, {useContext, useEffect, useState} from 'react';
import PageContainer from '../../../../components/layout/PageContainer';
import PageSection from '../../../../components/layout/PageSection';
import Card from '../../../../components/forms/Card';
import Table from 'react-bootstrap/Table';
import Button, {ButtonBox} from '../../../../components/forms/Button';
import PublicidadNew from './PublicidadNew';
import useEditModal from '../../../../modals/useEditModal';
import {Apis} from '../../../../util/Apis';
import useFetch from '../../../../hooks/useFetch';
import {SiteContext} from '../../../common/context/SiteContext';
import GenericModal2 from '../../../../modals/GenericModal2';
import Moment from 'react-moment';
import useDeleteModal from '../../../../modals/useDeleteModal';
import axios from 'axios';
import DeleteGenericModal from '../../../../modals/DeleteGenericModal';
import PublicidadEdit from './PublicidadEdit';
import useFetch2 from "../../../../hooks/useFetch2";
import {ViewerImage} from "../../../../components/util/ViewerImage";
import {Title} from "@wargostec/react-components";

const PublicidadList = () => {

	const {salaId} = useContext(SiteContext);
	const {loading, data, fetchData} = useFetch2(Apis.PC + '/ads-banners?salaId=' + salaId);

	const deleteElement = (id) => axios.delete(Apis.PC + '/ads-banners/' + id);

	const {
		wrapper: wrapper1,
		editAction,
		addAction
	} = useEditModal({
		editComponent: <PublicidadEdit />,
		addComponent: <PublicidadNew />,
		onRefresh: fetchData
	});

	const {wrapper: wrapper2, openDeleteModal} = useDeleteModal({
		text: '¿Deseas eliminar esta publicidad?',
		action: deleteElement,
		onRefresh: fetchData
	});

	return (
		<PageContainer>
			<PageSection>
					<Title text="Publicidad" type={'page'}/>
				<ButtonBox>
					<Button
						variant={'primary'}
						title={'Nuevo'}
						onClick={() => addAction()}
					/>
				</ButtonBox>
			</PageSection>

			<PageSection>
				<Card padding>
					<Table striped hover style={{display: 'table', height: '10px'}}>
						<thead>
							<tr>
								<th>Orden</th>
								<th>Imagen</th>
								<th>Fecha de Inicio</th>
								<th>Fin de Vigencia</th>
								<th />
							</tr>
						</thead>
						<tbody>
							{data &&
								data.map((item, index) => (
									<tr key={item.id}>
										<td className={'item-center'}>{index + 1}</td>
										<td>
											<ViewerImage
												className={'img img-fluid'}
												src={`${process.env.REACT_APP_S3_WARGOS_ASSETS}${item.url}`}
												alt={'imagen'}
												style={{width: '140px', height: '140px'}}
												zoom={true}
											/>
										</td>
										<td className={'item-center'}>
											{item.inicioVigencia && (
												<Moment format={'DD/MM/YYYY'}>
													{item.inicioVigencia}
												</Moment>
											)}
										</td>
										<td className={'item-center'}>
											{item.finVigencia && (
												<Moment format={'DD/MM/YYYY'}>
													{item.finVigencia}
												</Moment>
											)}
										</td>
										<td className={'item-center'}>
											<ButtonBox>
												<Button
													variant={'light'}
													size='s'
													onClick={() => editAction(item.id)}
													title={'Editar'}
												/>
												<Button
													variant={'danger'}
													size='s'
													onClick={() =>
														openDeleteModal(item.id, deleteElement)
													}
													title={'Eliminar'}
												/>
											</ButtonBox>
										</td>
									</tr>
								))}
						</tbody>
					</Table>
				</Card>
			</PageSection>
			<GenericModal2 {...wrapper1} />
			<DeleteGenericModal {...wrapper2} />
		</PageContainer>
	);
};

export default PublicidadList;
