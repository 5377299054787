import React, {useEffect} from "react";
import {Apis} from "../../../../util/Apis";
import Table from "react-bootstrap/Table";
import useFetch2 from "../../../../hooks/useFetch2";
import {LoadingTableV2} from "../../../../components/forms/Tables";
import {Col, Row} from "../../../../components/flex/FlexLayout";
import {Flex} from "../../../../components/flex/Flex";
import StadisticTitle from "../../../../components/layout/StadisticTitle";
import StadisticValue from "../../../../components/layout/StadisticValue";
import {FormBlock} from "../../../../components/forms/FormGroup";
import Button from "../../../../components/forms/Button";
import {useHistory} from "react-router";
import CustomerLink from "../../../../components/util/CustomerLink";

const ParticipantsList = ({values, setTitle}) => {

  const {loading, data, error} = useFetch2(Apis.SORTEO + `/sorteos/${values.id}/participantes/v2`, []);
  const history = useHistory()

  useEffect(() => {
    setTitle("Participantes");
  }, []);

  const sumCoupon = (values) => {
    let count = 0
    if (values) {
      values.forEach(item => {
        count += item.quantity;
      });
    }
    return count
  }

  const getProb = (depositados) => {
    if (sumCoupon(data) > 0) {
      return ((depositados / sumCoupon(data)) * 100).toFixed(2).toString() + '%'
    } else {
      return ''
    }
  }

  return (
    <>
      <FormBlock>
        <Row>
          <Col>
            <Flex flexDirection={'column'}>
              <StadisticTitle>Participantes</StadisticTitle>
              <StadisticValue>{data && data.length}</StadisticValue>
            </Flex>
          </Col>
          <Col>
            <Flex flexDirection={'column'}>

              <StadisticTitle>Cupones depositados</StadisticTitle>
              <StadisticValue>
                {sumCoupon(data)}
              </StadisticValue>
            </Flex>
          </Col>
        </Row>
      </FormBlock>

      <FormBlock>
        <LoadingTableV2 loading={loading} error={error} data={data} noDataText={'Aún no hay participantes'}>
          <Table striped hover>
            <thead>
            <tr>
              <th>Cliente</th>
              <th>N° Cupones</th>
              <th>Probabilidad</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            {data && data.map((item, index) =>
              <tr key={item.sorteoId}
                  style={{
                    background: item.customerId === values.customerId ? 'yellow' : 'white'
                  }}
                  className={index === data.length - 1 ? 'alert-success' : null}
              >
                <td>{<CustomerLink item={item}/>}</td>
                <td>{item.quantity}</td>
                <td>{getProb(item.quantity)}</td>
                <td>
                  <Button variant={'light'} size='xs'
                          onClick={() => history.push(`/crm/analisis-ganador/by-customer?customerId=${item.customerId}&ini=${values.ini}&fin=${values.fin}&couponTypeId=${values.couponTypeId}`)}
                          title={'Ver'}/>
                </td>
              </tr>
            )}
            </tbody>
          </Table>
        </LoadingTableV2>
      </FormBlock>
    </>
  );
};

export default ParticipantsList;
