import React, {useContext, useEffect} from "react";
import axios from "axios";
import {Apis} from "../../../../util/Apis";
import ExpiredManualForm from "./ExpiredManualForm";
import {SiteContext} from "../../../common/context/SiteContext";
import {errorToast} from "../../../../components/layout/ToastStyles";

const ExpiredManual = ({couponTypeId, setAction, setTitle}) => {

  const {salaId} = useContext(SiteContext)

  const save = (params) => {
    params.salaId = salaId
    params.couponTypeId = couponTypeId
    params.fechaHora = params.scheduledAtDate + 'T' + params.scheduledAtTime

    if (params.verification !== 'expirar cupones') {
      //errorToast("Debe ingresar el texto de validación")
      throw Error("Debe ingresar el texto de validación")
    }

    //TODO: no enviar parametros scheduledAtDate y scheduledAtTime

    return axios.post(Apis.PC + '/coupon/expire', params)
  }

  useEffect(() => {
    setTitle("Expiración Manual")
  }, [])

  return (
    <ExpiredManualForm value={null} onSubmit={save} setAction={setAction}/>
  )
};

export default ExpiredManual
