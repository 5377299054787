import React, { useContext } from "react";
import Table from "react-bootstrap/Table";
import { useForm } from "react-hook-form";
import { FaPlus } from "react-icons/fa";
import { useHistory } from "react-router";
import Moment from "react-moment";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Title } from "@wargostec/react-components";
import Slidebar from "../../../common/layout/Slidebar";
import SalaIdInput from "../../../common/components/SalaIdInput";
import FormGroup from "../../../../components/forms/FormGroup";
import { DatePickerCustom } from "../../../../components/forms/FormEl";
import PageSection from "../../../../components/layout/PageSection";
import NewCouponAwarded from "./CouponAwarded/NewCouponAwarded";
import PageContainerFull from "../../../../components/layout/PageContainerFull";
import { Apis } from "../../../../util/Apis";
import { SiteContext } from "../../../common/context/SiteContext";
import { LoadingTableV2 } from "../../../../components/forms/Tables";
import Card from "../../../../components/forms/Card";
import Button, { ButtonBox } from "../../../../components/forms/Button";
import { Alert } from "../../../../components/util/Alert";
import useToggle from "../../../../hooks/useToggle";
import useGet from "../../../../hooks/useGet";

const UploadMassiveCouponsList = () => {
  const baseUrl = '/crm'
  const history = useHistory()
  const { salaId } = useContext(SiteContext)

  let defaultParams = { salaId: salaId, enabled: true }

  const { register } = useForm({ defaultValues: defaultParams });

  const { loading, data, fetchData, error } = useGet({
    url: Apis.PC + '/coupon-batch',
    initialValue: []
  })

  const couponsTypeName = useGet({
    url: Apis.PC + '/coupon-type',
    initialValue: []
  })

  const { isOpen: slideOpen, setIsOpen: setSlideOpen } = useToggle({})
  const { isOpen: show, setIsOpen: setShow } = useToggle({})

  const handleClose = () => {
    fetchData();
    setShow(false);
  }

  const download = () => {
    const link = "https://wgt-tmp-uploads.s3.amazonaws.com/cupones_carga_masiva_ejemplo.xlsx";
    window.location.href = link;
  }

  return (
    <PageContainerFull>
      <Slidebar visible={slideOpen} onHide={() => setSlideOpen(false)}>
        <div style={{ padding: '16px' }}>
          <SalaIdInput {...register("salaId")} />
          <FormGroup>
            <label>Desde</label>
            <DatePickerCustom {...register("ini")} />
          </FormGroup>

          <FormGroup>
            <label>Hasta</label>
            <DatePickerCustom {...register("fin")} />
          </FormGroup>

          {/*TODO REVIEW*/}
          {/*<Button variant={'primary'} title='Buscar' onClick={/!*handleSubmit(getData)*!/}/>*/}
        </div>
      </Slidebar>

      <PageSection>
        <Title text="Lista de cargas masivas de cupones" type={'page'} />
        <ButtonBox>
          <Button variant={'primary'} onClick={() => history.push(`${baseUrl}/upload-coupons`)}>
            <FaPlus /> Nuevo
          </Button>
          {/*<Button variant={'primary'} onClick={() => setSlideOpen(!slideOpen)}>
            <FaSearch/> Buscar
          </Button>*/}
        </ButtonBox>
      </PageSection>

      <PageSection>
        <Row>
          <Col>
            <Alert text="Para clientes nuevos, ingresar todos los campos. Para clientes existentes, solo es necesario el DNI y cantidad de cupones" variant={'warning'} />
          </Col>
          <Col>
            <ButtonBox style={{ cursor: "pointer" }}>
              <Button variant={'primary'} onClick={download}>Descargar ejemplo</Button>
            </ButtonBox>
          </Col>
        </Row>
      </PageSection>

      <PageSection>
        <Card>
          <LoadingTableV2 loading={loading} error={error} data={data} noDataText={'No hay información'}>
            <Table striped hover size={'sm'}>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Fecha</th>
                  <th>Hora</th>
                  <th>Tipo de Cupón</th>
                  <th>Cantidad</th>
                </tr>
              </thead>
              <tbody>
                {data && data.map(item =>
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td><Moment format={'DD/MM/YYYY'}>{item.createdAt}</Moment></td>
                    <td><Moment format={'hh:mm a'}>{item.createdAt}</Moment></td>
                    <td>{couponsTypeName.data &&
                      couponsTypeName.data.map(
                        cup => cup.id === item.couponTypeId && cup.name
                      )
                    }</td>
                    <td>{item.total}</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </LoadingTableV2>
        </Card>
      </PageSection>
      <NewCouponAwarded show={show} onHide={() => handleClose()} />
    </PageContainerFull>
  )
}

export default UploadMassiveCouponsList;
