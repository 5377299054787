import React from 'react'
import {Select, SelectV2} from "../../../../components/forms/FormEl";
import {Apis} from "../../../../util/Apis";
import useFetch2 from "../../../../hooks/useFetch2";

const AnforaSelect = React.forwardRef((props, ref) => {

  const {data, loading} = useFetch2(`${Apis.PC}/anforas`)

  return (
    <>
      {
        !loading ?
          <SelectV2
            ref={ref}
            selectStyle={props.selectStyle}
            options={data}
            {...props}
          ></SelectV2>
          :
          <Select disabled={true}>
            <option key={0} value={0}>{"Cargando..."}</option>
          </Select>
      }
    </>
  )
})

export default AnforaSelect;


