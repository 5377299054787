import React, {useEffect} from "react";
import axios from "axios";
import ManagerForm from "./ManagerForm";
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";

const EditManager = ({id, setAction, setTitle}) => {

  const {data} = useFetch2(Apis.SORTEO + '/sorteo-responsable/' + id)

  function update(params) {
    params.id = id
    return axios.put(Apis.SORTEO + '/sorteo-responsable/' + id, params)
  }

  useEffect(() => {
    setTitle("Editar responsable")
  }, [])

  return (
    <ManagerForm value={data} onSubmit={update} setAction={setAction}/>
  )
}

export default EditManager
