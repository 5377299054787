import React, {useEffect} from "react";
import axios from "axios";
import NotificationForm from "./NotificationForm";
import {Apis} from "../../../../util/Apis";

const NotificationNew = ({setAction, setTitle}) => {

  const save = async (params) => {
    params.state = "Programado"
    await axios.post(Apis.NOTIF + '/notifications', params)
  }

  useEffect(() => {
    setTitle("Nueva notificacion")
  }, [])

  return (
    <NotificationForm value={null} onSubmit={save} setAction={setAction}/>
  )
};

export default NotificationNew
