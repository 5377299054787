import React, {useState} from "react";
import MainDashboard from "../../components/layout/MainDashboard";
import DropdownContainer from "../../components/layout/DropdownContainer";
import DropdownMenu from "../../components/layout/DropdownMenu";
import CajaRoutes from "./CajaRoutes";
import DropdownMenuItem from "../../components/layout/DropdownMenuItem";
import * as FaIcons from "react-icons/fa";
import * as HiIcons2 from "react-icons/hi2";
import {PiMathOperationsBold} from "react-icons/pi";
import {LuSettings2} from "react-icons/lu";

const CajaMain = () => {
  const baseUrl = '/caja'

  const [isOpen, setIsOpen] = useState(false)
  const toggleMobile = () => {
    setIsOpen(!isOpen)
  }
  return (

    <MainDashboard
      brand={'CAJA'}
      isOpen={isOpen}
      toggleMobile={toggleMobile}
      items={
        <DropdownContainer>
          <DropdownMenu title={'Fondo'} icon={<HiIcons2.HiBanknotes size={16} />}>
            {/*<DropdownMenuItem title={'Arqueo'} link={baseUrl + '/fondo-arqueo'}/>*/}
            {/*<DropdownMenuItem title={'Cerrar fondo'} link={baseUrl + '/fondo-cierre'}/>*/}
            <DropdownMenuItem title={'Lista'} link={baseUrl + '/fondo-list'} onClick={toggleMobile} />
            <DropdownMenuItem title={'Resumen'} link={baseUrl + '/fondo-report'} onClick={toggleMobile} />
          </DropdownMenu>

          <DropdownMenu title={'Auditoria'} icon={<FaIcons.FaSearchDollar size={16} />}>
            <DropdownMenuItem title={'Toma de contadores'} link={baseUrl + '/audit-snap-meters'} onClick={toggleMobile}/>
            <DropdownMenuItem title={'Recaudo'} link={baseUrl + '/audit-recaudo'} onClick={toggleMobile}/>
            <DropdownMenuItem title={'Reporte billetero'} link={baseUrl + '/audit-report-bill'} onClick={toggleMobile}/>
            <DropdownMenuItem title={'Cuadre físico diario'} link={baseUrl + '/audit-cuadre-fisico-diario'} onClick={toggleMobile} />
            <DropdownMenuItem title={'Cuadre físico'} link={baseUrl + '/audit-cuadre-fisico'} onClick={toggleMobile} />
            <DropdownMenuItem title={'Venta diaria'} link={baseUrl + '/audit-venta-diaria'} onClick={toggleMobile} />
          </DropdownMenu>

          <DropdownMenu title={'Operaciones'} icon={<PiMathOperationsBold size={16} />}>
            {/*<DropdownMenuItem title={'Canje tickets'} link={baseUrl + '/cticket-create'} onClick={toggleMobile} />*/}
            {/*<DropdownMenuItem title={'Pagos manuales'} link={baseUrl + '/chandpay-create'} onClick={toggleMobile} />*/}
            <DropdownMenuItem title={'Pagos con tarjeta'} link={baseUrl + '/ccardpay-create'} onClick={toggleMobile} />
            <DropdownMenuItem title={'Transferencias'} link={baseUrl + '/ctransfer-create'} onClick={toggleMobile} />
            <DropdownMenuItem title={'Pre billetero'} link={baseUrl + '/cbill-create'} onClick={toggleMobile} />
            <DropdownMenuItem title={'Rellenos'} link={baseUrl + '/refill-create'} onClick={toggleMobile} />
            {/*<DropdownMenuItem title={'Venta Tickets'} link={baseUrl + '/cticket-venta'}/>*/}
            <DropdownMenuItem title={'Gastos'} link={baseUrl + '/cother-create'} onClick={toggleMobile} />
            <DropdownMenuItem title={'Fichas mesas'} link={baseUrl + '/records-create'} onClick={toggleMobile} />
          </DropdownMenu>

          <DropdownMenu title={'Reportes'} icon={<HiIcons2.HiDocumentChartBar size={16} />}>
            <DropdownMenuItem title={'Cash Back'} link={baseUrl + '/cash-back'} onClick={toggleMobile} />
            <DropdownMenuItem title={'Canje Tickets'} link={baseUrl + '/cticket-report'} onClick={toggleMobile} />
            <DropdownMenuItem title={'Venta Tickets'} link={baseUrl + '/cticket-venta-report'} onClick={toggleMobile} />
            <DropdownMenuItem title={'Pagos manuales'} link={baseUrl + '/chandpay-report'} onClick={toggleMobile} />
            <DropdownMenuItem title={'Pagos con tarjeta'} link={baseUrl + '/ccardpay-report'} onClick={toggleMobile} />
            <DropdownMenuItem title={'Billetero'} link={baseUrl + '/cbill-report'} onClick={toggleMobile} />
            <DropdownMenuItem title={'Rellenos'} link={baseUrl + '/crefill-report'} onClick={toggleMobile} />
            <DropdownMenuItem title={'Transferencias'} link={baseUrl + '/ctransfer-report'} onClick={toggleMobile} />
            <DropdownMenuItem title={'Gastos'} link={baseUrl + '/cother-report'} onClick={toggleMobile} />
            <DropdownMenuItem title={'Depósitos'} link={baseUrl + '/cdeposito-report'} onClick={toggleMobile} />
            <DropdownMenuItem title={'Invoices'} link={baseUrl + '/cinvoice-report'} onClick={toggleMobile} />      
            <DropdownMenuItem title={'Sorteos'} link={baseUrl + '/csorteo-report'} onClick={toggleMobile} />
            <DropdownMenuItem title={'Fichas mesas'} link={baseUrl + '/records-report'} onClick={toggleMobile} />
          </DropdownMenu>

          <DropdownMenu title={'Configuración'} icon={<LuSettings2 size={16} onClick={toggleMobile}/>}>
            <DropdownMenuItem title={'Configuración'} link={baseUrl + '/configuration-caja'}  onClick={toggleMobile}/>
            <DropdownMenuItem title={'Categorias'} link={baseUrl + '/expenses-category'}  onClick={toggleMobile}/>
            <DropdownMenuItem title={'Tipos de fondo'} link={baseUrl + '/fund-type'} onClick={toggleMobile} />
          </DropdownMenu>
        </DropdownContainer>

      }
    >
      <CajaRoutes />
    </MainDashboard>
  )
}

export default CajaMain;
