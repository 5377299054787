import React, {useEffect, useState} from "react";
import PageContainer from "../../../../components/layout/PageContainer";
import PageSection from "../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import FormGroup from "../../../../components/forms/FormGroup";
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import SalaIdInput from "../../../common/components/SalaIdInput";
import {DatePickerCustom} from "../../../../components/forms/FormEl";
import Button, {ButtonBox} from "../../../../components/forms/Button";
import Slidebar from "../../../common/layout/Slidebar";
import {Controller, useForm} from "react-hook-form";
import Loader1 from "../../../common/components/Loader1";
import ProduccionTable from "./ProduccionTable";
import moment from "moment";
import MachineInformation from "./MachineInformation";
import Chart from "react-apexcharts";
import {useHistory} from "react-router";
import {PageBackLabel} from "../../../../components/layout/PageBackLabel";
import Card from "../../../../components/forms/Card";
import {BsFilter} from "react-icons/bs";
import MachineSelectV2 from "../../../common/components/selects/MachineSelectV2";
import {getDateFilter} from "../../../../components/util/CasinoDates";

const ProduccionPorMaquina = () => {

  const [slideOpen, setSlideOpen] = useState(false)
  const [dataDeltas, setDataDeltas] = useState([])

  const date = getDateFilter('month')
  const querystring = window.location.search
  const query = new URLSearchParams(querystring)
  let iniQueryParams = query.get('ini')
  let finQueryParams = query.get('fin')

  if (!iniQueryParams) iniQueryParams = date.ini
  if (!finQueryParams) finQueryParams = date.fin

  const firstDayOfMonth = moment(moment(iniQueryParams)).startOf('month').format('YYYY-MM-DD');
  const lastDayOfMonth = moment(moment(finQueryParams)).endOf('month').format('YYYY-MM-DD');
  let confId = query.get('confId')

  const defaultParams = {confId: confId, ini: firstDayOfMonth, fin: lastDayOfMonth}
  const [params, setParams] = useState(defaultParams)

  const {
    loading,
    data,
    error,
    fetchData
  } = useFetch2(Apis.TGM + `/machines/report-produccion-detail`, [], params, false);

  const {data: data2} = useFetch2(Apis.TGM + `/machines/find-by-conf/micro?confId=${params.confId}`, [], {}, true);

  useEffect(() => {
    setDataDeltas(data?.deltas)
  }, [data])

  let barProdMachine = {}

  const history = useHistory()
  const historyCounter = (item) => {
    history.push(`/tgm/meter-snap?ini=${item}&fin=${item}&confId=${confId}`)
  }

  if (dataDeltas !== undefined) {
    barProdMachine = {
      options: {
        chart: {
          id: "basic-bar",
          events: {
            dataPointSelection: (event, chartContext, config) => {
              historyCounter(config.w.config.xaxis.categories[config.dataPointIndex])
            }
          }
        },

        xaxis: {
          categories: dataDeltas.map(item => moment(item.fecha).format('YYYY-MM-DD')) // [ene, fre mar , jun]
        }
      },
      series: [
        {
          name: "series-1",
          data: dataDeltas.map(item => item.prod / 100)
        }
      ]
    };
  }

  const {
    register,
    control,
    handleSubmit,
  } = useForm({defaultValues: defaultParams});

  const getData = (params) => {
    setParams(params)
  }

  useEffect(() => {
  }, [data])

  useEffect(() => {
    if (params.confId) fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])


  return (
    <PageContainer>

      <Slidebar visible={slideOpen} onHide={() => setSlideOpen(false)}>
        <div style={{padding: '16px'}}>
          <SalaIdInput {...register("salaId")}/>

          <FormGroup>
            <label>Maquina</label>
            <Controller
              name="confId"
              control={control}
              render={({field}) => <MachineSelectV2 {...field} />}
            />
          </FormGroup>
          <FormGroup>
            <label>Desde</label>
            <DatePickerCustom {...register("ini")}/>
          </FormGroup>
          <FormGroup>
            <label>Hasta</label>
            <DatePickerCustom {...register("fin")}/>
          </FormGroup>

          <Button variant={'primary'} title='Buscar' onClick={handleSubmit(getData)}/>
        </div>
      </Slidebar>

      <PageSection>
        <PageBackLabel text='Producción' onClick={() => history.goBack()}/>
        <Title text="Producción por máquina" type={'page'}/>
        <ButtonBox style={{marginBottom: "20px"}}>
          <Button variant={'primary'} onClick={() => setSlideOpen(!slideOpen)}>
            <BsFilter/> Filtrar
          </Button>
        </ButtonBox>

        {params.confId &&
          <MachineInformation data={data2}/>
        }
        {loading ? <Loader1/> :
          (data?.length > 0 &&
            <Card>
              <Chart
                options={barProdMachine.options}
                series={barProdMachine.series}
                type="bar"
                width="100%"
                height={400}
              />
            </Card>
          )
        }
        {dataDeltas &&
          <ProduccionTable loading={loading} error={error} data={dataDeltas} showDetailPerMachine={false}/>
        }
      </PageSection>
    </PageContainer>
  )
}

export default ProduccionPorMaquina;
