import React, {useContext, useEffect} from "react";
import axios from "axios";
import {SiteContext} from "../../../common/context/SiteContext";
import {Apis} from "../../../../util/Apis";
import SorteoForm from "./SorteoForm";

const SorteoNew = ({setAction, setTitle}) => {

  const {salaId} = useContext(SiteContext)

  const save = async (params) => {
    params.salaId = salaId
    params.premioTipo = "soles"
    await axios.post(Apis.SORTEO + '/sorteos', params)
  }

  useEffect(() => {
    setTitle("Nuevo Sorteo")
  }, [])

  return (
    <SorteoForm value={{}} onSubmit={save} setAction={setAction}/>
  )
}

export default SorteoNew
