import React, {useEffect} from 'react';
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import axios from "axios";
import DealerForm from "./DealerForm";

const DealerEdit = ({id, setAction, setTitle}) => {

  const {data} = useFetch2(Apis.TABLES + '/dealer/' + id)

  function update(params) {
    params.id = id
    return axios.put(Apis.TABLES + '/dealer/' + id, params);
  }

  useEffect(() => {
    setTitle("Editar dealer")
  }, [])

  return (
    <DealerForm value={data} onSubmit={update} setAction={setAction}/>
  );
};

export default DealerEdit;
