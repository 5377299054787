import React, {useEffect, useState} from 'react'
import {Button, PageSection, Title} from '@wargostec/react-components'
import AuditRecaudoBill from './AuditRecaudoBill'
import AuditRecaudoCoin from './AuditRecaudoCoin'
import Slidebar from '../../../common/layout/Slidebar'
import FormGroup from '../../../../components/forms/FormGroup'
import {DatePickerCustom} from '../../../../components/forms/FormEl'
import useToggle from '../../../../hooks/useToggle'
import DateFilter2 from '../../../../components/datefilter/DateFilter2'
import {getCasinoDay} from '../../../../components/util/CasinoDates'
import useFetch2 from '../../../../hooks/useFetch2'
import {Apis} from '../../../../util/Apis'
import CardOverview, {CardOverviewContainer} from '../../../../components/layout/CardOverview'
import {HiOutlineSlotMachine} from '../../../../components/assets/icons/slot-machine'
import {numberFormat} from '../../../../components/util/NumberUtil'
import {HiOutlineBanknotes} from 'react-icons/hi2'
import {LuCalendar} from 'react-icons/lu'
import AsyncBlock from '../../../../components/forms/AsyncBlock'
import PageContainer from '../../../../components/layout/PageContainer'
import {FlexLayout} from '../../../../components/flex/Flex'
import {RiFileListLine} from "react-icons/ri";

const AuditRecaudo = () => {

  const {isOpen: slideOpen, toggler} = useToggle({});

  const defaultFechas = {period: getCasinoDay()};
  const [fechas, setFechas] = useState(defaultFechas);
  const [tempDate, setTempDate] = useState(fechas.period);
  const [typeRecaudo, setTypeRecaudo] = useState(localStorage.getItem('typeRecaudo') || 'maquina');

  const {data, fetchData, loading, error} = useFetch2(Apis.CAJA + `/report-bill/machines-audit`, [],fechas);

  useEffect(() => {
    handleSearch()
    // eslint-disable-next-line
  }, [fechas])

  const handleSearch = () => {
    fetchData()
  }

  const handleTypeRecaudoChange = (type) => {
    setTypeRecaudo(type);
    localStorage.setItem('typeRecaudo', type);
  }

  return (
    <PageContainer>
      <PageSection>
          <FlexLayout alignItems='flex-start'>
            <div style={{flex:1}}>
              <Title text="Recaudo" type={'page'}/>
            </div>
            <div style={{display:'flex',flexDirection:'row',gap:'4px', borderRadius:'20px', background:'#fff',padding:'8px'}}>
              <FlexLayout alignItems='center' gap='4px' onClick={() => handleTypeRecaudoChange('lista')} style={{padding:'4px 16px', background: typeRecaudo === 'lista' ? '#e5e5e5' : 'transparent',borderRadius:'12px',cursor:'pointer',transition: '0.2s all ease-in-out'}}>
                <RiFileListLine size={14} />
                <span style={{fontSize:'12px'}}>Lista</span>
              </FlexLayout>
              <FlexLayout alignItems='center' gap='4px' onClick={() => handleTypeRecaudoChange('maquina')} style={{padding:'4px 16px', background: typeRecaudo === 'maquina' ? '#e5e5e5' : 'transparent',borderRadius:'12px',cursor:'pointer',transition: '0.2s all ease-in-out'}}>
                <HiOutlineSlotMachine size={14} />
                <span style={{fontSize:'12px'}}>Máquina</span>
              </FlexLayout>
            </div>
          </FlexLayout>
          <Slidebar visible={slideOpen} onHide={toggler}>
          <div style={{padding: '16px'}}>
            <FormGroup>
              <label>Periodo</label>
              <DatePickerCustom value={tempDate} onChange={(e) => setTempDate(e.target.value)} />
            </FormGroup>

            <Button text='Buscar' variant='primary' onClick={() => {
              setFechas({...fechas, period: tempDate});
              handleSearch();
            }} />
          </div>
        </Slidebar>

        <DateFilter2
          showWeek={false}
          showMonth={false}
          showYear={false}
          toggleSlidebar={toggler}
          fechas={fechas}
          setFechas={setFechas}
          usePeriod
        />
        <AsyncBlock loading={loading} error={error}>
          <CardOverviewContainer gridColumns='repeat(5, 1fr)'>
            <CardOverview
              title={data?.total}
              subtitle='Máquinas'
              icon={<HiOutlineSlotMachine size={20} />}
            />
            <CardOverview
              title={'S/ ' + numberFormat(data?.billOnline)}
              subtitle='Billete electronico'
              icon={<HiOutlineBanknotes size={20} />}
            />
            <CardOverview
              title={'S/ ' + numberFormat(data?.billAudit)}
              subtitle='Billete fisico'
              icon={<HiOutlineBanknotes size={20} />}
            />
            <CardOverview
              title={'S/ ' + numberFormat(data?.prebillAudit)}
              subtitle='Prebill'
              icon={<HiOutlineBanknotes size={20} />}
            />
            <CardOverview
              title={'S/ ' + numberFormat(data?.diff)}
              subtitle='Diferencia'
              icon={<LuCalendar size={20} />}
            />
          </CardOverviewContainer>
        </AsyncBlock>
        {typeRecaudo === 'lista' ?
          <AuditRecaudoBill fechas={fechas}/>
          :
          <AuditRecaudoCoin fechas={fechas}/>
        }
        
      </PageSection>
    </PageContainer>
  )
}

export default AuditRecaudo
