import React, {useEffect} from 'react';
import {Controller, useForm} from "react-hook-form";
import {Form} from "react-bootstrap";
import FormGroup from "../../../../../components/forms/FormGroup";
import {Col, Row} from "../../../../../components/flex/FlexLayout";
import Input from "../../../../../components/forms/FormEl";
import DateTimeV2 from "../../../../../components/layout/DateTimeV2";

const LevelProgressiveForm = ({value, onSubmit, setAction}) => {

  const {register, handleSubmit, reset, control} = useForm();

  useEffect(() => {
    if (value && Object.keys(value).length > 0) {
      reset(value)
    }
  }, [value])

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit))
  }, [])

  return (
    <Form>
      <FormGroup>
        <label>Nombre</label>
        <Input type="text" {...register("name")}/>
      </FormGroup>

      <FormGroup>
        <label>Porcentaje</label>
        <Input type="number" {...register("percentage")}/>
      </FormGroup>

      <FormGroup>
        <Row>
          <Col>
            <label>Monto mínimo</label>
            <Input type="number" {...register("minAmount")}/>
          </Col>
          <Col>
            <label>Monto máximo</label>
            <Input type="number" {...register("maxAmount")}/>
          </Col>
        </Row>
      </FormGroup>

      <FormGroup>
        <Row>
          <Col>
            <label>Fecha Inicio</label>
            <Controller name="ini" control={control} render={({field}) => <DateTimeV2 {...field} />}/>
          </Col>
        </Row>
      </FormGroup>

      <FormGroup>
        <Row>
          <Col>
            <Form.Label>Fecha Fin</Form.Label>
            <Controller name="fin" control={control} render={({field}) => <DateTimeV2 {...field} />}/>
          </Col>
        </Row>
      </FormGroup>
    </Form>
  );
};

export default LevelProgressiveForm;
