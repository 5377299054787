import React, {useContext, useEffect, useState} from 'react';
import {SiteContext} from "../../../common/context/SiteContext";
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import PageContainer from "../../../../components/layout/PageContainer";
import {Title} from "@wargostec/react-components";
import PageSection from "../../../../components/layout/PageSection";
import Card, {CardBody, CardHeader} from "../../../../components/forms/Card";
import DateFilter from "../../../../components/datefilter/DateFilter";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Chart from "react-apexcharts";
import axios from "axios";
import {getDateFilter} from "../../../../components/util/CasinoDates";

const SorteoStatistics = () => {

  const {salaId} = useContext(SiteContext)
  const date = getDateFilter('month')

  const fechasDefault = {ini: date.ini, fin: date.fin}
  const [fechas, setFechas] = useState(fechasDefault)
  const [enabled, setEnabled] = useState(false)
  const [title, setTitle] = useState('')
  const [databar, setDataBar] = useState([])

  let defaultParams = {salaId: salaId, ini: fechas.ini, fin: fechas.fin}

  const [params, setParams] = useState(defaultParams)

  const {data, fetchData} = useFetch2(Apis.SORTEO + `/sorteos/grouped-by-coupon-type`, [], params, false)

  const {
    data: data2,
    fetchData: fetchData2
  } = useFetch2(Apis.SORTEO + `/sorteos/grouped-by-customer`, [], params, false);

  useEffect(() => {
    setParams({...params, ...fechas})
    setEnabled(false)
  }, [fechas])

  useEffect(() => {
    fetchData()
    fetchData2()
  }, [params])

  const donutSorteos = {
    series: data.map(item => item.quantity),
    options: {
      chart: {
        type: 'donut',
        height: 400,
        events: {
          dataPointSelection: (event, chartContext, config) => {
            newGraph(config.w.config.couponTypeId[config.dataPointIndex], config.w.config.labels[config.dataPointIndex])
          }
        }
      },
      legend: {
        position: 'bottom',
      },
      labels: data.map(item => item.couponTypeName),
      couponTypeId: data.map(item => item.couponTypeId),
    },
  };

  let fill = {
    opacity: 1
  }

  let legend = {
    show: true,
    showForSingleSeries: true,
    customLegendItems: ['Monto', 'Sorteos'],
    markers: {
      fillColors: ['#00E396', '#775DD0']
    }
  }

  const generateData = (data) => {
    const total = []
    {
      data.map(item =>
        total.push({
          x: item.customerName,
          y: item.amount,
          goals: [
            {
              name: 'Sorteos',
              value: item.quantity,
              strokeWidth: 10,
              strokeHeight: 0,
              strokeLineCap: 'round',
              strokeColor: '#775DD0'
            }
          ]
        })
      )
    }
    return total
  }

  const barTopWinners = {
    options: {
      chart: {
        type: 'bar',
        height: 400
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true
        },
      },
      colors: ['#00fb64'],
      legend: legend,
      fill: fill,
    },
    series: [
      {
        name: "Monto",
        data: generateData(data2)
      }
    ]
  };

  const newGraph = (couponTypeId, couponTypeName) => {
    setEnabled(true)
    setTitle(couponTypeName)
    console.log(couponTypeId + couponTypeName)
    axios.get(`${Apis.SORTEO}/sorteos/${couponTypeId}/winner-by-coupon-type`, {params: params})
      .then((data) => {
        setDataBar(data.data);
      })
  }

  const barTopCouponId = {
    options: {
      chart: {
        type: 'bar',
        height: 400
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true
        },
      },
      xaxis: {
        categories: databar.map(item => item.customerName) // [ene, fre mar , jun]
      },
      colors: ['#2b6de5'],
      legend: {
        show: true,
        showForSingleSeries: true
      },
      fill: fill,
    },
    series: [
      {
        name: "Monto",
        data: databar.map(item => item.amount)
      }
    ]
  };

  return (
    <PageContainer>
      <PageSection>
          <Title text="Estadística de sorteos" type={'page'}/>
      </PageSection>

      <PageSection>
        <Card padding style={{background: '#0A0A0A'}}>
          <DateFilter fechas={fechas} setFechas={setFechas} modeDate={'month'}/>
        </Card>
      </PageSection>

      <PageSection>
        <Row>
          <Col xs={12} md={6}>
            <Card padding>
              <CardHeader>
                <strong>Sorteos por promoción</strong>
              </CardHeader>
              <CardBody>
                <Chart
                  options={donutSorteos.options}
                  series={donutSorteos.series}
                  type="donut"
                  height="450"
                />
              </CardBody>
            </Card>
          </Col>

          <Col xs={12} md={6}>
            <Card padding>
              <CardHeader>
                <strong>Top ganadores de premios</strong>
              </CardHeader>
              <CardBody>
                <Chart
                  options={barTopWinners.options}
                  series={barTopWinners.series}
                  type="bar"
                  height="400"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </PageSection>

      {
        enabled === true &&
        <PageSection>
          <Card padding>
            <CardHeader>
              <strong>Top ganadores de {title}</strong>
            </CardHeader>
            <CardBody>
              <Chart
                options={barTopCouponId.options}
                series={barTopCouponId.series}
                type="bar"
                width="100%"
                height={400}
              />
            </CardBody>
          </Card>
        </PageSection>
      }
    </PageContainer>
  );
};

export default SorteoStatistics;
