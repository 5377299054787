import React from 'react';
import {Col, Row} from "../../../../../components/flex/FlexLayout";
import Input from "../../../../../components/forms/FormEl";
import Button from "../../../../../components/forms/Button";
import {BiPlusCircle, BiTrash} from 'react-icons/bi';

const RangeConfig = ({fields, register, addRanges, remove, modePer = true, modeDelete = true}) => {
  return (
    <>
      <div>
        <Row style={{marginBottom: "10px"}}>
          <Col>Bolilla Inicial</Col>
          <Col>Bolilla Final</Col>
          {
            modePer && <Col>Porcentaje</Col>
          }
          {
            modeDelete && <Col/>
          }
        </Row>
        <div>
          {fields && fields.map((item, index) =>
            <Row key={item.id} style={{display: 'flex', marginBottom: "10px"}}>
              <Col>
                <Input type={"number"} defaultValue={item.fromBall} {...register(`listRanges[${index}].fromBall`)}/>
              </Col>
              <Col>
                <Input type={"number"} defaultValue={item.toBall}  {...register(`listRanges[${index}].toBall`)}/>
              </Col>
              {
                modePer && <Col>
                  <Input type={"number"}
                         defaultValue={item.percentage}  {...register(`listRanges[${index}].percentage`)}/>
                </Col>
              }
              {
                modeDelete && <Col>
                  <Button variant='danger' onClick={() => remove(index)}>
                    <BiTrash size={22} />
                  </Button>
                </Col>
              }
            </Row>
          )}
        </div>
      </div>
      {
        modeDelete &&
        <Button onClick={addRanges}>
          <BiPlusCircle size={22}/>
          <span>Agregar</span>
        </Button>
      }

    </>
  );
};

export default RangeConfig;
