import {useState} from 'react'
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  getGroupedRowModel,
  useReactTable,
  RowData
} from "@tanstack/react-table";
import {DropdownButton, Dropdown, Table as TableBS} from "react-bootstrap";
import {RxCaretDown, RxCaretSort, RxCaretUp} from "react-icons/rx";
import {HiOutlineChevronDoubleLeft, HiOutlineChevronDoubleRight, HiOutlineChevronLeft, HiOutlineChevronRight, HiOutlineViewColumns} from "react-icons/hi2";
import {AiOutlineCloudDownload, AiOutlinePlus, AiOutlineReload, AiOutlineSearch} from "react-icons/ai";
import {BsFilter} from 'react-icons/bs';
import Card from "../card";
import Button from "../button";
import InputText from "../input";
import FlexContainer from "../flex-container";
import Select from "../select";
import styles from './table.module.scss';

declare module '@tanstack/table-core' {
  interface ColumnMeta<TData extends RowData, TValue> {
    headerClassName: string
    columnClassName: string
    align: 'left' | 'center' | 'right' | "justify" | "char"
  }
}

type TableProps = {
  pageSize?: number;
  data?: any[];
  columns?: any[];
  sortArray?: any[];
  searchable?: boolean;
  emptyListText?: string;
  onFilter?: (props: any) => void
  onDownload?: (props: any) => void
  onRefresh?: (props: any) => void
  onAdd?: (props: any) => void
}

const Table: React.FC<TableProps> = ({data = [], columns = [], sortArray = [], pageSize = 10, searchable = false, emptyListText = 'No hay resultados', onFilter, onDownload, onRefresh, onAdd}) => {
  const [sorting, setSorting] = useState(sortArray);
  const [columnFilters, setColumnFilters] = useState([]);
  const [columnVisibility, setColumnVisibility] = useState({})
  const [globalFilter, setGlobalFilter] = useState('')
  const [grouping, setGrouping] = useState([])

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      globalFilter,
      grouping
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onGlobalFilterChange: setGlobalFilter,
    getGroupedRowModel: getGroupedRowModel(),
    onGroupingChange: setGrouping,
    initialState: {
      pagination: {
        pageSize: pageSize,
      },
    },
  });

  const renderSortIndicator = (sortDirection: "asc" | "desc" | false) => {
    if (sortDirection === "asc") {
      return <RxCaretDown style={{marginLeft: "4px", display: "initial"}} />;
    } else if (sortDirection === "desc") {
      return <RxCaretUp style={{marginLeft: "4px", display: "initial"}} />;
    } else {
      return <RxCaretSort style={{marginLeft: "4px", display: "initial"}} />;
    }
  };

  return (
    <Card noPadding>
      <div className={`${styles['header-options-container']}`}>
        <div>
          {onDownload &&
            <Button variant='light' size='sm' text="Descargar" onClick={onDownload} icon={<AiOutlineCloudDownload />} />
          }
          {onRefresh &&
            <Button variant='light' size='sm' text="Actualizar" onClick={onRefresh} icon={<AiOutlineReload />} />
          }
          {onAdd &&
            <Button variant='primary' size='sm' text="Nuevo" onClick={onAdd} icon={<AiOutlinePlus />} />
          }
          {onFilter &&
            <Button variant='dark' size='sm' text="Filtrar" onClick={onFilter} icon={<BsFilter size={14} />} />
          }
          {searchable && <InputText placeholder="Filtrar..." icon={<AiOutlineSearch />} value={globalFilter || ''} onChange={(e) => setGlobalFilter(e.target.value)} />}
        </div>
        <DropdownButton
          className={`${styles['dropdown-button']}`}
          align="end"
          size="sm"
          variant='light'
          title={
            <div className={`${styles['dropdown-title']}`}>
              <HiOutlineViewColumns size={16} />
              <span>Columnas</span>
            </div>
          }
        >
          {table.getAllLeafColumns().map(column => {
            return (
              <Dropdown.ItemText key={column.id} className={`${styles['dropdown-item']}`}>
                <input
                  {...{
                    type: 'checkbox',
                    checked: column.getIsVisible(),
                    onChange: column.getToggleVisibilityHandler(),
                  }}
                />
                {column.columnDef.header.toString()}
              </Dropdown.ItemText>
            )
          })}
        </DropdownButton>
      </div>
      <TableBS striped hover responsive>
        <thead className={`${styles['header-table-container']}`}>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    {...{
                      className: header.column.columnDef.meta?.headerClassName,
                      onClick: header.column.getToggleSortingHandler(),
                    }}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    {header.column.getCanSort() ?
                      <>
                        {renderSortIndicator(header.column.getIsSorted())}
                      </>
                      : null
                    }
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody className={`${styles['body-table-container']}`}>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map(row => {
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map(cell => {
                    return (
                      <td
                        key={cell.id}
                        align={(cell.column.columnDef.meta)?.align}
                        className={`${styles['list-item']} ${(cell.column.columnDef.meta)?.columnClassName}`}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    )
                  })}
                </tr>
              )
            })
          ) : (
            <tr>
              <td className={`${styles['empty-list-text']}`} colSpan={columns.length}>
                {emptyListText}
              </td>
            </tr>
          )}
        </tbody>
      </TableBS>
      <FlexContainer className={`${styles['footer-layout']}`} alignItems='center' padding={'12px 16px'}>
        <span className={`${styles['footer-span']} ${styles['selected-rows']}`} style={{flex: 1}}>
          {table.getFilteredSelectedRowModel().rows.length + ' de ' + table.getFilteredRowModel().rows.length + ' seleccionadas'}
        </span>
        <FlexContainer alignItems="center" gap={12}>
          <span className={`${styles['footer-span']}`}>
            {'Pág. ' + (table.getState().pagination.pageIndex + 1) + ' de ' + table.getPageCount()}
          </span>
          <FlexContainer alignItems="center" gap={8}>
            <Button
              size="xs"
              variant="light"
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
              icon={<HiOutlineChevronDoubleLeft size={18} />}
            />
            <Button
              size="xs"
              variant="light"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
              icon={<HiOutlineChevronLeft size={18} />}
            />
            <Button
              size="xs"
              variant="light"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
              icon={<HiOutlineChevronRight size={18} />}
            />
            <Button
              size="xs"
              variant="light"
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
              icon={<HiOutlineChevronDoubleRight size={18} />}
            />
            <Select
              value={table.getState().pagination.pageSize}
              onChange={e => {
                table.setPageSize(Number(e.target.value))
              }}
            >
              {[10, 20, 50, 100, 200].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  {pageSize} filas
                </option>
              ))}
            </Select>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
    </Card>
  )
}

export default Table