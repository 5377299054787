import React, {useEffect} from 'react';
import {useForm} from "react-hook-form";
import FormGroup from "../../../../../components/forms/FormGroup";
import Input from "../../../../../components/forms/FormEl";
import SalaIdInput from "../../../../common/components/SalaIdInput";

const SalesProfitForm = ({value, onSubmit, setAction}) => {

  const {register, handleSubmit, reset} = useForm();

  useEffect(() => {
    if (value) {
      reset(value)
    }
  }, [value])

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit))
  }, [])

  return (
    <React.Fragment>
      <SalaIdInput {...register("salaId")} />
      <FormGroup>
        <label>De</label>
        <Input type={'text'} {...register("fromMoney")}/>
      </FormGroup>
      <FormGroup>
        <label>Hasta</label>
        <Input type={'text'} {...register("toMoney")}/>
      </FormGroup>
      <FormGroup>
        <label>Ganancia (%)</label>
        <Input type={'text'} {...register("percentage")}/>
      </FormGroup>
      <FormGroup>
        <label>Comisión (%)</label>
        <Input type={'text'} {...register("commission")}/>
      </FormGroup>
    </React.Fragment>
  );
};

export default SalesProfitForm;
