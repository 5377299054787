import {Route, Switch} from "react-router";
import UserNew from "../admin/pages/users/UserNew";
import UserEdit from "../admin/pages/users/UserEdit";
import UserList from "../admin/pages/users/UserList";
import React from "react";
import AdminHome from "./AdminHome";
import PoliciesList from "./pages/policies/PoliciesList";
import GroupsList from "./pages/groups/GroupsList";
import GroupEdit from "./pages/groups/GroupEdit";
import AddUsers from "./pages/groups/groupTabs/AddUsers";
import AddPolicies from "./pages/groups/groupTabs/AddPolicies";
import PolicyEdit from "./pages/policies/PolicyEdit";
import AddActions from "./pages/policies/actions/AddActions";
import ResetPassword from "./pages/password/ResetPassword";
import UserProfile from "../login/UserProfile";
import PermissionsView from "./pages/permit/PermissionsView";
import FacturasList from "./pages/facturas/FacturasList";
import LocationList from "./pages/locations/LocationList";
import CompaniesList from "./pages/companies/CompaniesList";

//Rutas solo del modulo de casino online
const AdminRoutes = () => {
  const parentRoute = '/admin'
  return (
    <Switch>
      <Route path={parentRoute + '/profile'} component={UserProfile}/>

      <Route path={parentRoute + '/users/edit/:id'} component={UserEdit}/>
      <Route path={parentRoute + '/users/new'} component={UserNew}/>
      <Route path={parentRoute + '/users'} component={UserList}/>

      <Route exact path={parentRoute + '/groups'} component={GroupsList}/>
      <Route exact path={parentRoute + '/groups/edit/:id/:groupName'} component={GroupEdit}/>
      <Route path={parentRoute + '/groups/edit/:id/:groupName/add-users'} component={AddUsers}/>
      <Route path={parentRoute + '/groups/edit/:id/:groupName/add-policies'} component={AddPolicies}/>

      <Route exact path={parentRoute + '/policies'} component={PoliciesList}/>
      <Route exact path={parentRoute + '/policies/edit/:id/:policyName'} component={PolicyEdit}/>
      <Route path={parentRoute + '/policies/edit/:id/:policyName/add-actions'} component={AddActions}/>

      <Route path={parentRoute + '/permissions'} component={PermissionsView}/>
      <Route path={parentRoute + '/facturas'} component={FacturasList}/>
      <Route path={parentRoute + '/locations'} component={LocationList}/>
      <Route path={parentRoute + '/companies'} component={CompaniesList}/>

      <Route exact path={parentRoute + '/profile'} component={UserProfile}/>
      <Route path={parentRoute + '/password'} component={ResetPassword}/>

      <Route path="/" component={AdminHome}/>
    </Switch>
  )
}

export default AdminRoutes;
