import React, {useEffect} from "react";
import axios from "axios";
import useFetch from "../../../../hooks/useFetch";
import {Apis} from "../../../../util/Apis";
import NotificationForm from "./NotificationForm";
import {errorToast, successToast} from "../../../../components/layout/ToastStyles";


const NotificationEdit = ({id, setAction, setTitle}) => {

  const {loading, data} = useFetch(Apis.NOTIF + '/notifications/' + id)

  const update = async (params) => {
    params.state = "Programado"
    await axios.put(Apis.NOTIF + '/notifications/' + id, params)
      .then(res => {
        successToast()
      })
      .catch(err => {
        errorToast();
      })
  }

  useEffect(() => {
    setTitle("Edición de notificación")
  }, [])

  return (
    (!loading && data) &&
    <NotificationForm value={data} onSubmit={update} setAction={setAction}/>
  )
}

export default NotificationEdit
