import React from "react";
import PageContainer from "../../../../components/layout/PageContainer";
import PageSection from "../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";

const Liquidacion=() =>{
  return(
    <PageContainer>
      <PageSection>
          <Title text="Liquidacion"/>
      </PageSection>
      <PageSection>

      </PageSection>
    </PageContainer>
  )
}

export default Liquidacion;
