import React from 'react';
import PageContainer from '../../../../components/layout/PageContainer';
import PageSection from '../../../../components/layout/PageSection';
import {Title} from "@wargostec/react-components";
import Table from 'react-bootstrap/Table';
import Button, {ButtonBox} from '../../../../components/forms/Button';
import {LoadingTable} from '../../../../components/forms/Tables';
import useFetch from '../../../../hooks/useFetch';
import {Apis} from '../../../../util/Apis';
import Moment from 'react-moment';
import {FaPlus} from 'react-icons/fa';
import useEditModal from '../../../../modals/useEditModal';
import SorteoEdit from '../../../crm/pages/sorteos/SorteoEdit';
import GenericModal2 from '../../../../modals/GenericModal2';
import AddFundsNew from './AddFundsNew';

const AddFundsList = () => {
	const {loading, data, fetchData} = useFetch(
		Apis.RGS + '/customers/deposits?source=WARGOS',
		[]
	);

	//hooks para crear,editar y eliminar
	const {wrapper: wrapper1, addAction} = useEditModal({
		editComponent: <SorteoEdit />,
		addComponent: <AddFundsNew />,
		onRefresh: fetchData
	});

	return (
		<PageContainer>
			<PageSection>
					<Title text="Últimos fondos añadidos manualmente"/>
				<ButtonBox>
					<Button variant={'primary'} onClick={() => addAction()}>
						<FaPlus /> Añadir Fondos
					</Button>
				</ButtonBox>
			</PageSection>

			<LoadingTable
				loading={loading}
				data={data}
				text={'No se encontraron depósitos manuales'}
				tableData={
					<Table striped hover>
						<thead>
							<tr>
								<th>ID</th>
								<th>Cambio</th>
								<th>Balance anterior</th>
								<th>Monto</th>
								<th>Fuente</th>
								<th>Fecha de depósito</th>
								<th>Hora de depósito</th>
								<th>Tipo</th>
							</tr>
						</thead>
						<tbody>
							{data &&
								data.map((item) => (
									<tr key={item.id}>
										<td>{item.id}</td>
										<td>{item.currency === 1 && 'PEN'}</td>
										<td>{item.previousBalance}</td>
										<td>{item.amount}</td>
										<td>{item.source}</td>
										<td>
											<Moment format={'DD/MM/YYYY'}>{item.createdAt}</Moment>
										</td>
										<td>
											<Moment format={'HH:mm'}>{item.createdAt}</Moment>
										</td>
										<td>{item.type}</td>
									</tr>
								))}
						</tbody>
					</Table>
				}
			/>
			<GenericModal2 {...wrapper1} />
		</PageContainer>
	);
};

export default AddFundsList;
