import React, { useState } from "react";

const useModal = ({ component, onRefresh = () => { } }) => {

  const [showModal, setShowModal] = useState(false);

  const [body, setBody] = useState(null);

  const someAction = (args) => {
    setShowModal(true)
    setBody(React.cloneElement(component, { ...args }))
  }

  const onCloseModal = () => {
    onRefresh()
    setShowModal(false)
  }

  const wrapper = {
    show: showModal,
    onHide: onCloseModal,
    body: body
  }

  return { wrapper, someAction }
}

export default useModal