import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react'
import PageContainerFull from '../../../../components/layout/PageContainerFull'
import {Button, FormGroup, PageSection, Title} from '@wargostec/react-components'
import Slidebar from '../../../common/layout/Slidebar'
import useToggle from '../../../../hooks/useToggle'
import {DatePickerCustom} from '../../../../components/forms/FormEl'
import {getCasinoDay} from '../../../../components/util/CasinoDates'
import DateFilter2 from '../../../../components/datefilter/DateFilter2'
import CardOverview, {CardOverviewContainer} from '../../../../components/layout/CardOverview'
import {HiOutlineBanknotes} from 'react-icons/hi2'
import {HiOutlineCollection} from 'react-icons/hi'
import {LuEye} from 'react-icons/lu'
import {numberFormat} from '../../../../components/util/NumberUtil'
import ReactTable from '../../../../components/util/ReactTable'
import {Apis} from '../../../../util/Apis'
import useFetch2 from '../../../../hooks/useFetch2'
import {useForm} from 'react-hook-form'
import AsyncBlock from '../../../../components/forms/AsyncBlock'
import {serializeDownload} from "../../../../util";
import {SiteContext} from "../../../common/context/SiteContext";
import MoneyColored from "../../../../components/util/MoneyColored";

const AuditReportBill = () => {

  const {isOpen: slideOpen, toggler} = useToggle({});
  const { salaId } = useContext(SiteContext)

  const defaultFechas = {period: getCasinoDay(), ini: getCasinoDay(),salaId: salaId};
  const [params, setParams] = useState(defaultFechas)
  const {handleSubmit, register} = useForm({defaultValues: defaultFechas});
  const [fechas, setFechas] = useState(defaultFechas)
  const {data, fetchData, loading, error} = useFetch2(Apis.CAJA + `/report-bill`, null, params);

  const handleSearch = (data) => {
    const newFechas = {
      ini: data.ini,
      period: data.ini,
      salaId: salaId
    };
    setFechas(newFechas);
    fetchData();
  };

  useEffect(() => {
    setParams({
      ini: fechas.period,
      period: fechas.period,
      salaId: salaId
    });
  }, [fechas, salaId]);

  useEffect(() => {
    if (params) fetchData()
  }, [params])

  const renderValue = useCallback(props => (
    <MoneyColored currencySymbol={'S/ '}>
      {props.renderValue()}
    </MoneyColored>
  ), []);

  const columns = useMemo(
    () => [
      {
        header: 'Máquina',
        accessorKey: 'machine',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Juego',
        accessorKey: 'gameName',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        },
      },
      {
        header: 'Denom.',
        accessorKey: 'den',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Inicial',
        id: 'billIni',
        accessorFn: row => row.billIni ? row.billIni : '--',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        }
      },
      {
        header: 'Final',
        id: 'billFin',
        accessorFn: row => row.billFin ? row.billFin : '--',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        }
      },
      {
        header: 'Total créditos',
        id: 'totalbillCredits',
        accessorFn: row => row.totalbillCredits ? row.totalbillCredits : '--',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        }
      },
      {
        header: 'Total',
        id: 'billOnline',
        accessorFn: row => `S/ ${row.billOnline !== null ? numberFormat(row.billOnline) : '--'}`,
        meta: {
          headerClassName: 'text-right border-l font-bold',
          columnClassName: 'text-right border-l font-bold',
        }
      },
      {
        header: 'Prebill',
        id: 'prebillAudit',
        accessorFn: row => `S/ ${row.prebillAudit !== null ? numberFormat(row.prebillAudit) : '--'}`,
        meta: {
          headerClassName: 'text-right border-l',
          columnClassName: 'text-right border-l',
        },
      },
      {
        header: 'Recaudo',
        id: 'billAudit',
        accessorFn: row => `S/ ${row.billAudit !== null ? numberFormat(row.billAudit) : '--'}`,
        meta: {
          headerClassName: 'text-right border-l',
          columnClassName: 'text-right border-l',
        }
      },
      {
        header: 'Diferencia',
        accessorKey: 'diff',
        id: 'diff',
        meta: {
          headerClassName: 'text-right border-l font-bold',
          columnClassName: 'text-right border-l font-bold',
        },
        cell: renderValue
      },
    ]
    , []
  )

  const download = () => {
    const link = Apis.CAJA + `/report-bill/report.xlsx?${serializeDownload({
      ini: fechas.period,
      period: fechas.period,
      salaId: salaId
    })}`;

    console.log("Parámetros de descarga:", { ini: fechas.period, period: fechas.period, salaId: salaId });
    window.location.href = link;
  }

  return (
    <PageContainerFull>
      <PageSection>
        <Title text="Reporte de billetero" type={'page'} />
        <Slidebar visible={slideOpen} onHide={toggler}>
          <div style={{padding: '16px'}}>
            <FormGroup>
              <label>Fecha</label>
              <DatePickerCustom
                {...register('period', {value: fechas.period})}
              />
            </FormGroup>

            <Button variant='primary' onClick={handleSubmit(handleSearch)} text='Buscar' />
          </div>
        </Slidebar>
        <DateFilter2
          showDay={false}
          showWeek={false}
          showMonth={false}
          showYear={false}
          toggleSlidebar={toggler}
          fechas={fechas}
          setFechas={setFechas}
          usePeriod
        />
        <CardOverviewContainer gridColumns='repeat(4, 1fr)'>
          <CardOverview
            title={'S/ ' + numberFormat(data?.billOnline)}
            subtitle='Billetes contadores'
            icon={<HiOutlineBanknotes size={22} />}
          />
          <CardOverview
            title={'S/ ' + numberFormat(data?.prebillAudit)}
            subtitle='Pre billetero'
            icon={<HiOutlineCollection size={22} />}
          />
          <CardOverview
            title={'S/ ' + numberFormat(data?.billAudit)}
            subtitle='Billetes Recaudo'
            icon={<HiOutlineCollection size={22} />}
          />
          <CardOverview
            title={'S/ ' + numberFormat(data?.diff)}
            subtitle='Diferencia'
            icon={<LuEye size={22} />}
          />
        </CardOverviewContainer>
        <AsyncBlock loading={loading} error={error}>
          <ReactTable
            columns={columns}
            data={data?.data ?? []}
            noData='No existen resultados para el filtro seleccionado'
            pageSize={200}
            customSearch
            filterDownload={download}
            sortArray={[{id:'machine', asc: false}]}
          />
        </AsyncBlock>
      </PageSection>

    </PageContainerFull>
  )
}

export default AuditReportBill
