import styled from "styled-components";

const PageContainer = styled.div`
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-right: auto;
  margin-left: auto;
  transition: 0.5s all ease-in-out;
  @media (min-width: 576px) {
    transition: 0.5s all ease-in-out;
    max-width: 540px;
  }
  @media (min-width: 768px) {
    transition: 0.5s all ease-in-out;
    max-width: 720px;
  }
  @media (min-width: 992px) {
    transition: 0.5s all ease-in-out;
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    transition: 0.5s all ease-in-out;
    max-width: 1140px;
  }
  @media (min-width: 1400px) {
    transition: 0.5s all ease-in-out;
    max-width: 1320px;
  }
`;

export default PageContainer;
