import React, {useContext, useEffect} from "react";
import {SiteContext} from "../../../common/context/SiteContext";
import {Apis} from "../../../../util/Apis";
import AnforaForm from "./AnforaForm";
import axios from 'axios';
import {errorToast, successToast} from "../../../../components/layout/ToastStyles";


const AnforaNew = ({setAction, setTitle}) => {

  const {salaId} = useContext(SiteContext)

  const save =async (params) => {
    params.salaId = salaId
    params.state = 1
    params.ini = params.ini + 'T08:00'
    params.fin = params.fin + 'T08:00'

    await axios.post(Apis.PC + '/anforas', params)
      .then(res => {
        console.log(res)
        successToast()
      }).catch(err => {
        console.log(err)
        errorToast();
    })
  }

  useEffect(() => {
    setTitle("Nueva Ánfora")
  }, [])

  return (
    <AnforaForm value={{}} onSubmit={save} setAction={setAction}/>
  )
}

export default AnforaNew
