import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {SiteContext} from "../../../common/context/SiteContext";
import {Apis} from "../../../../util/Apis";
import {useForm} from "react-hook-form";
import Slidebar from "../../../common/layout/Slidebar";
import SalaIdInput from "../../../common/components/SalaIdInput";
import Button, {ButtonBox} from "../../../../components/forms/Button";
import PageSection from "../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import Card from "../../../../components/forms/Card";
import {numberFormat} from "../../../../components/util/NumberUtil";
import Moment from "react-moment";
import axios from "axios";
import useFetch2 from "../../../../hooks/useFetch2";
import AsyncBlock from "../../../../components/forms/AsyncBlock";
import useToggle from "../../../../hooks/useToggle";
import PageContainer from "../../../../components/layout/PageContainer";
import CardOverview, {CardOverviewContainer} from "../../../../components/layout/CardOverview";
import {FaSortAmountUpAlt} from "react-icons/fa";
import {HiOutlineBanknotes} from "react-icons/hi2";
import PeriodAndFondoSelect from "./PeriodAndFondoSelect";
import {TbFileSad} from "react-icons/tb";
import EmptyState from "../../../common/components/EmptyState";
import {BiTransfer} from "react-icons/bi";
import {getCasinoDay} from "../../../../components/util/CasinoDates";
import {FlexLayout} from "../../../../components/flex/Flex";
import {getStatusName} from "../fondo/MovimientosFondo";
import MoneyColoredRound from "../../../../components/util/MoneyColored";
import useEditModal from "../../../../modals/useEditModal";
import UpdateTransferComponent from "../fondo/lista/UpdateTransferComponent";
import GenericModal2 from "../../../../modals/GenericModal2";
import ReactTable from "../../../../components/util/ReactTable";

const CTransferReport = () => {
  const {salaId} = useContext(SiteContext)
  const {isOpen: slideOpen, toggler} = useToggle({});
  const [period, setPeriod] = useState(getCasinoDay());

  const {register, handleSubmit} = useForm();
  const [params, setParams] = useState({
    salaId: salaId,
    fondoId: "",
    period:period
  });

  const determineApiEndpoint = (fondoId) => {
    return fondoId === "" 
      ? Apis.CAJA + '/operation-transfer/report/by-period' 
      : Apis.CAJA + '/operation-transfer/report/by-fondo';
  };

  const {loading, data, fetchData, error} = useFetch2(determineApiEndpoint(params.fondoId), null, params, false);

  useEffect(() => {
    setParams((prevParams) => ({
      ...prevParams,
      period,
    }));
  }, [period]);

  const handleFondoIdChange = (fondoId) => {
    setParams((prevParams) => ({...prevParams, fondoId: fondoId}));
  };

  const onSubmit = () => {
    fetchData();
  };

  const {
    wrapper: wrapper,
    addAction: updateTransfer,
  } = useEditModal({
    addComponent: <UpdateTransferComponent/>,
    onRefresh: () => {
      fetchData()
    }
  });

  const statusValue = useCallback(props => <> {
    <FlexLayout justifyContent={'center'}>
      <div>{getStatusName(props.row.original.status)}</div>
    </FlexLayout>
  }</>, [])

  const dateValue = useCallback(props => <Moment format={'DD/MM/YYYY'}>{props.renderValue()}</Moment>, []);

  const NumberComponent = useCallback(props => <MoneyColoredRound
    dataSala={data}>{props.renderValue()}</MoneyColoredRound>, [data]);

  const FondoDeDestino = useCallback( props => <> {
    <FlexLayout justifyContent={'center'}>
      {
        props.row.original.relatedFondoName ?
          <div>{props.row.original.relatedFondoName}</div> :
          <div>---</div>
      }
    </FlexLayout>
  }</>, [])

  const approveTransfer = useCallback((props) => {
    const relatedOperationId = props.row.original.relatedOperationId
    return (
      <FlexLayout justifyContent={'center'}>
        {props.row.original.status === 1 && (
          <>
            {
              relatedOperationId === null &&
              <>
                <Button variant={'success'} size='xs' onClick={() => updateTransfer({id : props.row.original.id, status : 2})}>
                  Aprobar
                </Button>
                <Button variant={'warning'} size='xs' onClick={() => updateTransfer({id : props.row.original.id, status : 3})}>
                  Rechazar
                </Button>
              </>
            }
            {
              relatedOperationId !== null &&
              <Button variant={'danger'} size='xs' onClick={() => updateTransfer({id : props.row.original.id, status : 4})}>
                Eliminar
              </Button>

            }
          </>
        )}
      </FlexLayout>
    );
  }, []);

  const columns = useMemo(
    () => [
      {
        header: 'Id',
        accessorKey: 'id',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Fondo de origen',
        accessorKey: 'fondoName',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Fondo destino',
        accessorKey: 'relatedFondoName',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: FondoDeDestino
      },
      {
        header: 'Monto',
        accessorKey: 'amount',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: NumberComponent
      },
      {
        header: 'Fecha',
        accessorKey: 'createdAt',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: dateValue
      },
      {
        header: 'Estado',
        accessorKey: 'statusName',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: statusValue
      },
      {
        header: 'Acciones',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: approveTransfer
      }
    ]
    , []
  )

  return (
    <PageContainer>
      <Slidebar visible={slideOpen} onHide={toggler}>
        <div style={{padding: '16px'}}>
          <SalaIdInput {...register("salaId")} />
          <PeriodAndFondoSelect
            onFondoChange={handleFondoIdChange}
            period={period}
            setPeriod={setPeriod}
            {...register("fondoId")} />
          <Button variant={'primary'} title='Buscar' onClick={handleSubmit(onSubmit)} />
        </div>
      </Slidebar>

      <PageSection>
        <Title text="Transferencias" type={'page'} />
        <ButtonBox>
          <Button variant={'primary'} title={'Filtrar'} onClick={toggler} />
        </ButtonBox>

        <AsyncBlock loading={loading} error={error}>
          {data === null ?
            <Card padding>
              <EmptyState
                title={'Transferencias'}
                subtitle={'Haga click en FILTRAR para realizar una busqueda'}
                icon={<BiTransfer size={32} />}
              />
            </Card>
            :
            <>
              {data?.data?.length === 0 ?
                <Card padding>
                  <EmptyState
                    title={'Sin resultados'}
                    subtitle={'Haga click en FILTRAR para realizar una nueva busqueda'}
                    icon={<TbFileSad size={32} />}
                  />
                </Card>
                :
                <>
                  <CardOverviewContainer gridColumns='repeat(2, 1fr)'>
                    <CardOverview
                      title={data?.quantity ?? 0}
                      subtitle='Operaciones'
                      icon={<FaSortAmountUpAlt size={20} />}
                    />
                    <CardOverview
                      title={'S/ ' + numberFormat(data?.totalAmount)}
                      subtitle='Total'
                      icon={<HiOutlineBanknotes size={20} />}
                    />
                  </CardOverviewContainer>
                  <Card>
                    <AsyncBlock loading={loading} error={error}>
                      <ReactTable
                        columns={columns}
                        data={(data?.data && data?.data) || []}
                        customSearch
                        noData='No existen resultados'
                        pageSize={15}
                      />
                    </AsyncBlock>
                  </Card>
                </>
              }
            </>
          }
        </AsyncBlock>
      </PageSection>
      <GenericModal2 {...wrapper} />
    </PageContainer>
  )
}

export default CTransferReport;
