import React from 'react'
import {Select} from "../../../components/forms/FormEl";

const DayOfWeekSelect = React.forwardRef((props, ref) => {

  return (
    <Select name={props.name} ref={ref}>
      <option key={1}>Lunes</option>
      <option key={2}>Martes</option>
      <option key={3}>Miércoles</option>
      <option key={4}>Jueves</option>
      <option key={5}>Viernes</option>
      <option key={6}>Sábado</option>
      <option key={7}>Domingo</option>
    </Select>
  )
})

export default DayOfWeekSelect;
