export const hexToRgb = (hex) => {
  const bigint = parseInt(hex.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return { r, g, b };
};

// Oscurecer un color RGB
export const darkenColor = ({ r, g, b }) => {
  const factor = 0.8; // Ajusta este valor para controlar la oscuridad
  const darkenedR = Math.floor(r * factor);
  const darkenedG = Math.floor(g * factor);
  const darkenedB = Math.floor(b * factor);
  return `rgb(${darkenedR}, ${darkenedG}, ${darkenedB})`;
};