import React, {useContext} from "react";
import PageSection from "../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import FormGroup, {FormBlock} from "../../../../components/forms/FormGroup";
import Input from "../../../../components/forms/FormEl";
import {Controller, useForm} from "react-hook-form";
import {SiteContext} from "../../../common/context/SiteContext";
import Button, {ButtonBox} from "../../../../components/forms/Button";
import PageContainer from "../../../../components/layout/PageContainer";
import CardTypeSelect from "../../../common/components/selects/CardTypeSelect";
import axios from "axios";
import {Apis} from "../../../../util/Apis";
import {useHistory} from "react-router";
import CustomerSearchInputV2 from "../../../common/components/CustomerSearchInputV2";

const CardPayOp = () => {

  const {salaId} = useContext(SiteContext)
  const history = useHistory()
  let defaultParams = {salaId: salaId, enabled: true}
  const {register, control, handleSubmit} = useForm({defaultValues: defaultParams});

  const onCreate = (params) => {
    params.salaId = salaId
    params.fondoId = 123
    axios.post(Apis.CAJA + '/operation-cardpay', params).then(res => {
      history.push('/caja')
    }).catch(err => {

    })
  }

  return (
    <PageContainer>
      <PageSection>
          <Title text="Pagos con tarjetas" type={'page'}/>
      </PageSection>

      <PageSection>
        <FormBlock>
          <FormGroup>
            <label>Monto</label>
            <Input {...register("amount")}/>
          </FormGroup>
          <FormGroup>
            <label>Cliente</label>
            <Controller name="customerId" control={control} render={({ field }) => <CustomerSearchInputV2 {...field} />}/>
          </FormGroup>
          <FormGroup>
            <label>Tipo de tarjeta</label>
            <CardTypeSelect {...register("cardType")}/>
          </FormGroup>
          <FormGroup>
            <label>Ultimos 4 dígitos</label>
            <Input {...register("cardNumber4")}/>
          </FormGroup>
          <ButtonBox>
            <Button title={"Guardar"} variant={'primary'} onClick={handleSubmit(onCreate)}/>
          </ButtonBox>
        </FormBlock>
      </PageSection>
    </PageContainer>
  )
}

export default CardPayOp
