import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {SiteContext} from "../../../../common/context/SiteContext";
import {Apis} from "../../../../../util/Apis";
import useEditModal from "../../../../../modals/useEditModal";
import useDeleteModal from "../../../../../modals/useDeleteModal";
import axios from "axios";
import PageSection from "../../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import GenericModal2 from "../../../../../modals/GenericModal2";
import DeleteGenericModal from "../../../../../modals/DeleteGenericModal";
import ProductCatalogEdit from "./ProductCatalogEdit";
import ProductCatalogNew from "./ProductCatalogNew";
import {successToast} from "../../../../../components/layout/ToastStyles";
import useFetch2 from "../../../../../hooks/useFetch2";
import FormGroup from "../../../../../components/forms/FormGroup";
import SalaIdInput from "../../../../common/components/SalaIdInput";
import {Select} from "../../../../../components/forms/FormEl";
import Slidebar from "../../../../common/layout/Slidebar";
import {useForm} from "react-hook-form";
import defaultLogo from "../../../../../components/images/food.png";
import useToggle from "../../../../../hooks/useToggle";
import PageContainerFull from '../../../../../components/layout/PageContainerFull';
import Button from '../../../../../components/forms/Button';
import {ViewerImage} from "../../../../../components/util/ViewerImage";
import SwitchToggle from "../../../../../components/layout/SwitchToggle";
import AsyncBlock from '../../../../../components/forms/AsyncBlock';
import ReactTable from '../../../../../components/util/ReactTable';
import {FlexLayout} from '../../../../../components/flex/Flex';

const ProductCatalogList = () => {

  const {salaId} = useContext(SiteContext)
  const {isOpen: showFilter, toggler} = useToggle({});
  let defaultParams = {enabled: true}
  const [params, setParams] = useState(defaultParams)

  const {data, fetchData, loading, error} = useFetch2(Apis.PC + '/catalog-products/active-inactive', [], params);
  const {register, handleSubmit} = useForm();

  const {wrapper: wrapper1, addAction, editAction} = useEditModal({
    editComponent: <ProductCatalogEdit/>,
    addComponent: <ProductCatalogNew/>,
    onRefresh: fetchData
  })

  const deleteElement = id => axios.delete(Apis.PC + '/catalog-products/' + id)

  const {
    wrapper: wrapper2,
    openDeleteModal
  } = useDeleteModal({
    text: '¿Desea eliminar este producto?',
    action: deleteElement,
    onRefresh: fetchData
  })


  useEffect(() => {
    fetchData()
  }, [params])

  const getData = (params) => {
    setParams(params)
  }

  const lockAndUnlock = async (id, locked) => {
    if (!locked) {
      await axios.post(Apis.PC + '/catalog-products/' + id + '/enable')
        .then(() => {
            fetchData()
            successToast("Producto habilitado")
          }
        )
    } else {
      await axios.post(Apis.PC + '/catalog-products/' + id + '/disable')
        .then(() => {
            fetchData()
            successToast("Producto deshabilitado")
          }
        )
    }
  }

  const image = (value) => {
    let src = `${process.env.REACT_APP_S3_WARGOS_ASSETS}${value}`
    if (value === null) {
      src = defaultLogo
    }
    return src
  }

  const imagenView = useCallback(props => (
    <ViewerImage
      src={image(props.row.original.photoUrl)}
      alt={'imagen' + props.row.original.id}
      style={{width: 'auto', height: '80px'}}
      zoom={true}
    />), []);

  const redeemBy = useCallback(props => <>
      {props.row.original.couponTypeId === null ? 'PUNTOS' : 'CUPONES'}</>
    , []);
  const amountValue = useCallback(props => <>
      {props.row.original.point + (props.row.original.couponTypeId === null ? ' PUNTOS' : ' CUPONES')}</>
    , []);
  const enabledValue = useCallback(props =>
      <FlexLayout justifyContent='center'>
        <SwitchToggle
          defaultChecked={props.row.original.enabled}
          id={"enabled" + props.row.original.id}
          name="enabled"
          label="Habilitar"
          onClick={() => lockAndUnlock(props.row.original.id, props.row.original.enabled)}
        />
      </FlexLayout>
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , []);
  const actionValue = useCallback(props => <>{
    <FlexLayout justifyContent='center'>
      <Button size='xs' onClick={() => editAction(props.row.original.id)}>
        Editar
      </Button>
      <Button size='xs' onClick={() => openDeleteModal(props.row.original.id)} variant='danger'>
        Eliminar
      </Button>
    </FlexLayout>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }</>, []);

  const columns = useMemo(() => [
    {
      header: 'ID',
      accessorKey: 'id',
      enableGlobalFilter: false,
      enableSorting: false,
      meta: {
        headerClassName: 'text-center font-xsmall',
        columnClassName: 'text-center font-xsmall',
      },
    },
    {
      header: 'Imagen',
      id: 'photoUrl',
      enableGlobalFilter: false,
      enableSorting: false,
      meta: {
        headerClassName: 'text-center',
        columnClassName: 'text-center',
      },
      cell: imagenView
    },
    {
      header: 'Nombre',
      accessorKey: 'name',
      meta: {
        headerClassName: 'text-center',
        columnClassName: 'text-center',
      },
    },
    {
      header: 'Canje por',
      accessorKey: 'quantity',
      meta: {
        headerClassName: 'text-center',
        columnClassName: 'text-center',
      },
      cell: redeemBy
    },
    {
      header: 'Cantidad',
      accessorKey: 'point',
      meta: {
        headerClassName: 'text-center',
        columnClassName: 'text-center',
      },
      cell: amountValue
    },
    {
      header: 'Precio',
      accessorKey: 'price',
      meta: {
        headerClassName: 'text-center',
        columnClassName: 'text-center',
      }
    },
    {
      header: 'Habilitado',
      accessorKey: 'enabled',
      enableGlobalFilter: false,
      enableSorting: false,
      meta: {
        headerClassName: 'text-center',
        columnClassName: 'text-center',
      },
      cell: enabledValue
    },
    {
      header: 'Acciones',
      accessorKey: 'acciones',
      enableGlobalFilter: false,
      enableSorting: false,
      meta: {
        headerClassName: 'text-center',
        columnClassName: 'text-center',
      },
      cell: actionValue
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [])

  return (
    <PageContainerFull>

      <Slidebar visible={showFilter} onHide={toggler}>
        <div style={{padding: '16px'}}>
          <FormGroup>
            <SalaIdInput {...register("salaId")} />
            <label>Estado</label>
            <Select type='text' {...register("enabled")} defaultValue={true}>
              <option value={true}>Activos</option>
              <option value={false}>De baja</option>
            </Select>
          </FormGroup>
          <Button variant={'primary'} title='Buscar' onClick={handleSubmit(getData)}/>
        </div>
      </Slidebar>

      <PageSection>
        <Title text="Lista de productos" type={'page'}/>
        <AsyncBlock loading={loading} error={error}>
          <ReactTable
            customSearch
            columns={columns}
            data={data || []}
            filterAdd={() => addAction()}
            filterOnclick={toggler}
            noData='No hay resultados para este filtro'
            pageSize={20}
          />
        </AsyncBlock>
      </PageSection>

      <GenericModal2 {...wrapper1} />
      <DeleteGenericModal {...wrapper2} />

    </PageContainerFull>
  );
};

export default ProductCatalogList;
