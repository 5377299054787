import React from 'react';
import {Controller, useForm} from "react-hook-form";
import FormGroup, {FormBlockTitle} from "../../../../../components/forms/FormGroup";
import SalaHiddenInput from "../../../../common/components/SalaHiddenInput";
import SwitchToggle from "../../../../../components/layout/SwitchToggle";
import Input from "../../../../../components/forms/FormEl";
import DenomSelect from "../../../../common/components/selects/DenomSelect";
import {Apis} from "../../../../../util/Apis";
import axios from "axios";
import Button from "../../../../../components/forms/Button";
import {errorToast, successToast} from "../../../../../components/layout/ToastStyles";
import PangolinSelect from "../../../../common/components/selects/PangolinSelect";
import ZoneSelect from "../../../../common/components/selects/ZoneSelect";
import SlotGroupSelectV2 from "../../../../common/components/selects/SlotGroupSelectV2";

const MachineEditComponent = ({slideOpen, refresh}) => {

  const {register, control, handleSubmit, setValue, formState: {errors}} = useForm();

  const create = (params) => {
    axios.post(Apis.TGM + '/machines', params)
      .then(res => {
        successToast()
        slideOpen(false)
        refresh(true)
        setValue('machine', "")
        setValue('gameName', "")
        setValue('serialNumber', "")
        setValue('numReg', "")
        setValue('positionId', "")
        setValue('enabled', false)
      })
      .catch(err => {
        errorToast(err.response.data.message ? err.response.data.message : "Ocurrió un problema. Por favor intenta nuevamente", 2000)
      })
  }


  return (
    <>
      <FormBlockTitle>General</FormBlockTitle>
      <SalaHiddenInput {...register("date")}/>
      <FormGroup>
        <SwitchToggle label={"Habilitado"} {...register("enabled")} id={"enabled"}/>
      </FormGroup>
      <FormGroup>
        <label>Nombre</label>
        <Input {...register("machine", {required: "Campo obligatorio"})}/>
        {errors.machine && <p style={{color: 'red'}}>{errors.machine?.message}</p>}
      </FormGroup>

      <FormGroup>
        <label>Blue (SMIB)</label>
        <Controller
          name="blueSn"
          control={control}
          render={({field}) => <PangolinSelect {...field} />}
        />
      </FormGroup>

      <FormGroup>
        <label>Fab. y modelo</label>
        <Controller
          name="groupId"
          control={control}
          render={({field}) => <SlotGroupSelectV2 {...field} />}
        />
      </FormGroup>

      <FormGroup>
        <label>Juego</label>
        <Input {...register("gameName")}/>
      </FormGroup>

      <FormGroup>
        <label>Serie</label>
        <Input {...register("serialNumber")}/>
      </FormGroup>

      <FormGroup>
        <label>Denominacion</label>
        <DenomSelect {...register("den")}/>
      </FormGroup>

      <FormGroup>
        <label>Zona</label>
        <ZoneSelect {...register("zoneId")}/>
      </FormGroup>

      <FormGroup>
        <label>Posición</label>
        <Input {...register("positionId")}/>
      </FormGroup>

      <FormGroup>
        <label>Reg. MINCETUR</label>
        <Input {...register("numReg")}/>
      </FormGroup>

      <FormGroup>
        <SwitchToggle label={"AFT Habilitado"} {...register("aftEnabled")} id={"aftEnabled"}/>
        <label>N° Asset</label>
        <Input {...register("assetNumber")}/>
      </FormGroup>

      <Button variant={'primary'} title='Guardar' onClick={handleSubmit(create)}/>
    </>
  );
};

export default MachineEditComponent;
