import React, {useEffect} from "react";

const LogoutPage = () => {

  localStorage.clear();

  useEffect(() => {
    goToExternalLogout()
  }, [])

  const goToExternalLogout = () => {
    const searchParams = new URLSearchParams(window.location.search)

    const authorizeEndpoint = process.env.REACT_APP_AUTH_SERVER + '/auth/logout'
    let redirectTo = window.location.origin + '/login'
    const referer1 = searchParams.get("referer")
    if (referer1 !== null && referer1 !== '') {
      redirectTo = window.location.origin + '/login?referer=' + referer1
    }

    const params = {redirect_uri: redirectTo, client_id: process.env.REACT_APP_AUTH_CLIENT_ID}
    const paramString = new URLSearchParams(params)
    window.location.href = `${authorizeEndpoint}?${paramString.toString()}`
  }

  return (
    <div>Saliendo...</div>
  )
}

export default LogoutPage
