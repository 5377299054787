import React, {useEffect} from 'react';
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import axios from "axios";
import MoneyDenomForm from "./MoneyDenomForm";

const MoneyDenomEdit = ({id, setAction, setTitle}) => {

  const {loading, data} = useFetch2(Apis.CAJA + '/money-denom/' + id, {})

  const update = async (params) => {
    await axios.put(Apis.CAJA + '/money-denom/' + id, params)
  }

  useEffect(() => {
    setTitle("Editar denominación")
  }, [])

  return (
    <React.Fragment>
      {data && !loading &&
        <MoneyDenomForm value={data} onSubmit={update} setAction={setAction}/>
      }
    </React.Fragment>
  );
};

export default MoneyDenomEdit;
