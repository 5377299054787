import React, { useEffect } from 'react';
import FormGroup from '../../../../components/forms/FormGroup';
import { Col, Row } from '../../../../components/flex/FlexLayout';
import Input from '../../../../components/forms/FormEl';
import { useForm } from 'react-hook-form';
import SalaIdInput from '../../../common/components/SalaIdInput';

const MidasForm = ({ value, onSubmit, setAction }) => {
  const { register, handleSubmit, reset, control } = useForm({});

  useEffect(() => {
    if (value){
      reset(value)
    }
  }, []);

  useEffect(() => {
    setAction(()=> handleSubmit(onSubmit))
  }, []);

  return (
    <form>
      <FormGroup>
        <SalaIdInput {...register('salaId')}/>
        <Row>
          <Col>
          <label>IP</label>
          <Input  {...register("url")}/>
          </Col>
        </Row>
      </FormGroup>
    </form>
  );
};

export default MidasForm;
