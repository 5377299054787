import React, { useEffect } from 'react';
import { useForm } from "react-hook-form";
import FormGroup from "../../../../components/forms/FormGroup";
import Input from "../../../../components/forms/FormEl";
import ComputerSelect from "../../../common/components/selects/ComputerSelect";

const PrinterForm = ({ value, onSubmit, setAction }) => {
  const { register, handleSubmit, reset } = useForm();

  useEffect(() => {
    if (value) {
      reset(value)
    }
  }, [value])

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit))
  }, [])

  return (
    <React.Fragment>
      <FormGroup>
        <label>Computadora</label>
        <ComputerSelect {...register("computerId")} />
      </FormGroup>
      <FormGroup>
        <label>IP</label>
        <Input type='text' {...register("manualIp")} />
      </FormGroup>
      <FormGroup>
        <label>Nombre</label>
        <Input type='text' {...register("name")} />
      </FormGroup>
    </React.Fragment>
  )
};

export default PrinterForm;
