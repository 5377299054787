import React, {useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import SalaIdInput from "../../../common/components/SalaIdInput";
import FormGroup from "../../../../components/forms/FormGroup";
import Input, {Select} from "../../../../components/forms/FormEl";
import GroupSelect from "../../../common/components/selects/GroupSelect";
import {Apis} from "../../../../util/Apis";
import {errorToast, successToast} from "../../../../components/layout/ToastStyles";
import Button from "../../../../components/forms/Button";
import {Col} from "../../../../components/flex/FlexLayout";
import Check from "../../../../components/images/check2.png"
import DateTimeV2 from "../../../../components/layout/DateTimeV2";

const NotificationForm = ({value, onSubmit, setAction}) => {

  const {register, control, handleSubmit, reset, setValue} = useForm();

  const [image, setImage] = useState({preview: "", raw: ""});
  const [product, setProduct] = useState(value);
  const [prevButton, setPrevButton] = useState(false);

  useEffect(() => {
    if (value) {
      reset(value)
    }
  }, [value])

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit));
  }, [])


  const handleChange = e => {
    if (e.target.files.length) {
      let rawImage = e.target.files[0];
      setImage({
        preview: URL.createObjectURL(rawImage),
        raw: rawImage
      });
      uploadAction(rawImage)
      //reset(value)
    }
  };

  function uploadAction(rawImage) {
    let data = new FormData()

    data.append("file", rawImage);
    data.append('folder', 'notifications')

    fetch(Apis.FILE + "/upload/doc", {
      method: "POST",
      body: data
    }).then(response => response.json())
      .then(res => {
        console.log(res)
        successToast("Imagen subida correctamente.");
        setProduct(values => ({...values, imgUrl: res.url}));
        setPrevButton(true)
        setValue('imgUrl', res.url)
      })
      .catch(error => {
        console.log(error);
        errorToast("Ocurrió un problema al identificar el archivo. Intente de nuevo en unos minutos.");
      })
  }

  const deleteImage = () => {
    setValue('imgUrl', null)
    handleSubmit(onSubmit)()
  }

  return (
    <React.Fragment>
      <SalaIdInput {...register("salaId")}/>
      <FormGroup>
        <label>Título</label>
        <Input type='text' {...register("title")}/>
      </FormGroup>
      <FormGroup>
        <label>Contenido</label>
        <Input type='text' {...register("body")}/>
      </FormGroup>
      <FormGroup>
        <label>Fecha y hora de la notificación</label>
        <Controller name="scheduledAt" control={control} render={({field}) => <DateTimeV2 {...field} />}/>
      </FormGroup>
      <FormGroup hidden>
        <label>URL de Imagen</label>
        <Input type='text' {...register("imgUrl")}/>
      </FormGroup>
      <FormGroup>
        <label>Grupo de usuarios</label>
        <GroupSelect {...register("customerGroupId")} todos={1}/>
      </FormGroup>
      <FormGroup>
        <label>Medio</label>
        <Select {...register("mediumId")}>
          <option value={1}>NOTIFICACION PUSH</option>
          <option value={2}>SMS</option>
          <option value={3}>EMAIL</option>
        </Select>
      </FormGroup>
      <FormGroup>
        {
          (value?.imgUrl != null && value?.imgUrl !== "")
            ? <React.Fragment>
              <Col style={{maxWidth: "80px"}}>
                <img src={Check} style={{maxHeight: "50px", marginLeft: "20px"}}/>
              </Col>
              <Col>
                <h5 style={{marginBottom: "5px"}}>La imagen ya fue subida</h5>
                <div
                  style={{fontSize: "20px", color: "blue"}}>
                  <Button variant={'success'} size='xs' title={"Ver imagen"}
                          onClick={() => window.open(`${process.env.REACT_APP_S3_WARGOS_ASSETS}${value?.imgUrl}`)}/>
                  <Button variant={'danger'} size='xs' title={"Eliminar imagen"}
                          onClick={() => deleteImage()} style={{marginLeft: "30px"}}/>
                </div>
              </Col>
            </React.Fragment>
            :
            <div>
              <label>Imagen de notificación</label>
              <div>
                <input type="file" id="upload-button" name='imgUrl' onChange={handleChange}/>
              </div>
              <input type='hidden' {...register("imgUrl")}/>
            </div>
        }
      </FormGroup>

    </React.Fragment>
  )

}

export default NotificationForm
