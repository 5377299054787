import React, {useContext, useEffect} from "react";
import {Apis} from "../../../../../util/Apis";
import axios from "axios";
import {SiteContext} from "../../../../common/context/SiteContext";
import ZoneModalForm from "./ZoneModalForm";

const ZoneNew = ({setAction, setTitle}) => {

  const {salaId} = useContext(SiteContext)

  const save = (params) => {
    params.salaId = salaId
    return axios.post(Apis.TGM + '/slot-zones', params)
  }

  useEffect(() => {
    setTitle("Nueva zona")
  }, [])

  return (
    <ZoneModalForm value={{}} onSubmit={save} setAction={setAction}/>
  )
}

export default ZoneNew
