import React, {useMemo} from 'react';
import {Controller, useForm} from "react-hook-form";
import FormGroup from "../../../../components/forms/FormGroup";
import Input from "../../../../components/forms/FormEl";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DateTimeV2 from "../../../../components/layout/DateTimeV2";
import useFilasMesa from "../../../../hooks/useFilasMesa";
import TableAmountsMesa from "../../../../components/util/TableAmountsMesa";

const AperturaMesaForm = ({data, onSubmit, setAction}) => {

  const {register, handleSubmit, control} = useForm()
  const {rows,setRows,total} = useFilasMesa(data?.type)

  useMemo(() => {
    setAction(() => handleSubmit((params)=>
    {
      params.amountCents = total
      params.valueTokens = rows
      onSubmit(params)
    }))
  }, [total])

  return (
    <React.Fragment>
      <FormGroup>
        <label>Desde</label>
        <Controller name="startDateTime" control={control} render={({field}) => <DateTimeV2 {...field} />} />
      </FormGroup>
      <FormGroup>
        <label>Hasta</label>
        <Controller name="endDateTime" control={control} render={({field}) => <DateTimeV2 {...field} />} />
      </FormGroup>

      <Row>
        <Col>
          <FormGroup>
            <label>Apuesta mínima</label>
            <Input type='number' {...register("minAmount")} />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <label>Apuesta máxima</label>
            <Input type='number' {...register("maxAmount")} />
          </FormGroup>
        </Col>
      </Row>
      <TableAmountsMesa rows={rows} setRows={setRows} total={total} />
    </React.Fragment>
  );
};

export default AperturaMesaForm;
