import styled from "styled-components";
import React from "react";

const NoDataTableStyle = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  margin-top: 30px;
`;

const NoDataTable = ({text}) => {
  return (
    <NoDataTableStyle><strong>{text}</strong></NoDataTableStyle>
  )
}

export default NoDataTable
