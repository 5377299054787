import React, {useCallback, useMemo} from 'react';
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import PageSection from "../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import AsyncBlock from "../../../../components/forms/AsyncBlock";
import ReactTable from "../../../../components/util/ReactTable";
import PageContainerFull from "../../../../components/layout/PageContainerFull";
import defaultLogo from "../../../../components/images/food.png";
import {ViewerImage} from "../../../../components/util/ViewerImage";

const CompaniesList = () => {

  const {loading, data, fetchData, error} = useFetch2(Apis.USER + '/company', []);

  const image = (value) => {
    let src = `${process.env.REACT_APP_S3_WARGOS_ASSETS}${value}`
    if (value === null) {
      src = defaultLogo
    }
    return src
  }

  const logo = useCallback(props => <>{
    <div style={{display: 'flex', justifyContent: 'center'}}>
      <ViewerImage className={'img img-fluid'}
                   src={props.row.original.logo} alt={'imagen'}
                   style={{width: '140px', height: '140px'}} zoom={true}/>
    </div>

  }</>, []);

  const columns = useMemo(() => [
    {
      header: 'ID',
      accessorKey: 'id',
      meta: {
        headerClassName: 'text-center',
        columnClassName: 'text-center',
      },
    },
    {
      header: 'Nombre',
      accessorKey: 'legalName',
      meta: {
        headerClassName: 'text-left',
        columnClassName: 'text-left',
      },
    },
    {
      header: 'RUC',
      accessorKey: 'ruc',
      meta: {
        headerClassName: 'text-left',
        columnClassName: 'text-left',
      },
    },
    {
      header: 'Logo',
      accessorKey: 'logo',
      meta: {
        headerClassName: 'text-center',
        columnClassName: 'text-center',
      },
      cell: logo
    },
  ], [])

  return (
    <PageContainerFull>
      <PageSection>
          <Title text="Empresas" type={'page'}/>
        <AsyncBlock loading={loading} error={error}>
          <ReactTable
            columns={columns}
            data={data || []}
            noData='No existen empresas registradas'
            pageSize={15}
          />
        </AsyncBlock>
      </PageSection>

    </PageContainerFull>
  );
};

export default CompaniesList;
