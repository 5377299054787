import React, {useContext} from "react";
import {useHistory} from "react-router";
import {SiteContext} from "../../../common/context/SiteContext";
import axios from "axios";
import {Apis} from "../../../../util/Apis";
import LudopatiaForm from "./LudopatiaForm";


const LudopatiaNew = () => {
  const {salaId} = useContext(SiteContext)
  const history = useHistory()

  const onCreate = (params) => {
    params.salaId = salaId
    params.imgUrl = null
    axios.post(Apis.BLACK + '/ban-list-members', params)
      .then(res => {
        history.push('/tgm/ludopatia/gestion-listas')
      }).catch(err => {
      console.log(err)
    })
  }

  return (
    <LudopatiaForm value={{}} onSubmit={onCreate}/>
  )
}

export default LudopatiaNew
