import {useEffect, useState} from "react";
import axios from "axios";
import {Apis} from "../util/Apis";

// custom hook for performing GET request
const useCouponType = (onlyActive) => {
  const [couponTypes, setCouponTypes] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let url = `${Apis.PC}/coupon-type/combo`
    if (onlyActive) {
      url = `${Apis.PC}/coupon-type/active`
    }

    setLoading(true)
    axios.get(url).then((res) => {
      setLoading(false)
      setCouponTypes(res.data)
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onlyActive]);

  return {couponTypes, loading};
};

export default useCouponType;
