import React, {useContext, useEffect, useState} from 'react';
import {SiteContext} from "../../../../common/context/SiteContext";
import {getCasinoDay} from "../../../../../components/util/CasinoDates";
import useFetch2 from "../../../../../hooks/useFetch2";
import {Apis} from "../../../../../util/Apis";
import {useForm} from "react-hook-form";
import PageContainer from "../../../../../components/layout/PageContainer";
import Card from "../../../../../components/forms/Card";
import {LoadingTableV2} from "../../../../../components/forms/Tables";
import Table from "react-bootstrap/Table";
import Moment from "react-moment";
import PageSection from "../../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import Button, {ButtonBox} from "../../../../../components/forms/Button";
import DateFilter from "../../../../../components/datefilter/DateFilter";
import SalaIdInput from "../../../../common/components/SalaIdInput";
import FormGroup from "../../../../../components/forms/FormGroup";
import MachineSelect from "../../../../common/components/selects/MachineSelect";
import {DatePickerCustom} from "../../../../../components/forms/FormEl";
import Slidebar from "../../../../common/layout/Slidebar";
import SegmentCircle from "../../../../crm/pages/clientes/customers/SegmentCircle";
import {BsFilter} from "react-icons/bs";
import ButtonReload from "../../../../../components/forms/ButtonReload";
import {numberFormat} from "../../../../../components/util/NumberUtil";

const HandpayEvents = () => {

  const {salaId} = useContext(SiteContext)

  const casinoDay = getCasinoDay()
  const fechasDefault = {ini: casinoDay, fin: casinoDay}
  const [fechas, setFechas] = useState(fechasDefault)
  const [slideOpen, setSlideOpen] = useState(false)

  let defaultParams = {salaId: salaId, ini: fechas.ini, fin: fechas.fin}
  const [params, setParams] = useState(defaultParams)

  const {data, loading, fetchData, error} = useFetch2(Apis.TGM + `/handpays`, [], params);
  const {register, handleSubmit,} = useForm({defaultValues: defaultParams});

  const getData = (params) => {
    setParams(params)
  }

  useEffect(() => {
    setParams({...params, ...fechas})
  }, [fechas])

  useEffect(() => {
    if (params) fetchData()
  }, [params])

  const array = [
    {id: 0, hexColor: "#ffe603"},
    {id: 1, hexColor: "#53f61b"},
    {id: 2, hexColor: "#ff2a2a"},
  ]

  return (
    <PageContainer>

      <Slidebar visible={slideOpen} onHide={() => setSlideOpen(false)}>
        <div style={{padding: '16px'}}>
          <SalaIdInput {...register("salaId")}/>

          <FormGroup>
            <label>Maquina</label>
            <MachineSelect todos={1} {...register("confId")}/>
          </FormGroup>
          <FormGroup>
            <label>Desde</label>
            <DatePickerCustom {...register("ini")}/>
          </FormGroup>
          <FormGroup>
            <label>Hasta</label>
            <DatePickerCustom {...register("fin")}/>
          </FormGroup>

          <Button variant={'primary'} title='Buscar' onClick={handleSubmit(getData)}/>
        </div>
      </Slidebar>

      <PageSection>
          <Title text="Pagos manuales" type={'page'}/>
        <ButtonBox>
          <Button variant={'primary'}  onClick={() => setSlideOpen(!slideOpen)}>
            <BsFilter /> Filtrar
          </Button>
          <ButtonReload onClick={fetchData}/>
        </ButtonBox>
        <Card padding style={{background: '#0A0A0A'}}>
          <DateFilter fechas={fechas} setFechas={setFechas}/>
        </Card>
        <Card padding>
          <LoadingTableV2 loading={loading} error={error} data={data} noDataText={'No hay información'}>
            <Table striped hover>
              <thead>
              <tr>
                <th>ID</th>
                <th>Maquina</th>
                <th className="text-right">Monto</th>
                <th>Estado</th>
                <th>Fecha/Hora</th>
              </tr>
              </thead>
              <tbody>
              {data && data.map(item =>
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.machineName}</td>
                  <td className="text-right">{'S/ ' + numberFormat(item.amount)}</td>
                  <td><SegmentCircle levelId={item.status} array={array}/>{item.statusName}</td>
                  <td><Moment format={'HH:mm DD/MM/YYYY'}>{item.fechaEmision}</Moment></td>
                </tr>
              )}
              </tbody>
            </Table>
          </LoadingTableV2>
        </Card>
      </PageSection>
    </PageContainer>
  );
};

export default HandpayEvents;
