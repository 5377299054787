import React, {useContext} from 'react';
import PageContainer from "../../../../components/layout/PageContainer";
import PageSection from "../../../../components/layout/PageSection";
import Button, {ButtonBox} from "../../../../components/forms/Button";
import {useHistory} from "react-router";
import Card from "../../../../components/forms/Card";
import styled from "styled-components";
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import {SiteContext} from "../../../common/context/SiteContext";
import Loader1 from "../../../common/components/Loader1";
import {Title} from "@wargostec/react-components";

export const ProgressiveGridContainer = styled.div`
  margin-top: 18px;
  display: grid;
  grid-template-columns: repeat(4, 200px);
  grid-gap: 16px;
`

const ProgressiveList = () => {

  const history = useHistory();
  const {salaId} = useContext(SiteContext)

  const {loading, data} = useFetch2(Apis.TGM + '/progressive?salaId=' + salaId)

  const goToNew = () => {
    history.push('/crm/progressive/new')
  }

  const goToDetail = (id) => {
    history.push('/crm/progressive/edit/' + id)
  }

  return (
    <PageContainer>
      <PageSection>
          <Title text="Progresivos" type={'page'}/>
        <ButtonBox>
          <Button variant={'primary'} title={'Nuevo'} onClick={goToNew}/>
        </ButtonBox>
      </PageSection>

      <PageSection>
        {
          loading ?
            <Loader1/>
            :
            <>
              {
                data &&
                <div>
                  <ProgressiveGridContainer>
                    {data.map(item =>
                      <Card key={item.id} padding style={{cursor: 'pointer'}}
                            onClick={() => goToDetail(item.id)}
                      >
                        {item.name}
                      </Card>)}
                  </ProgressiveGridContainer>
                </div>
              }
            </>
        }
      </PageSection>

    </PageContainer>
  );
};

export default ProgressiveList;
