import React from "react";
import Modal from "react-bootstrap/Modal";
import MyButton from "../components/forms/MyButton";

//DEPRECATED : NO USAR
const BottomModal = ({body, button, onHide}) => {
  return (
    <React.Fragment>
      <Modal.Body>
        <div style={{display: 'block', flexDirection: 'row'}}>
          {body}
        </div>
      </Modal.Body>

      <Modal.Footer>
        <MyButton variant="secondary" onClick={onHide}>
          Cerrar
        </MyButton>
        {button}
      </Modal.Footer>
    </React.Fragment>
  )
}

export default BottomModal
