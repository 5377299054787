import React, {useContext} from "react";
import Table from "react-bootstrap/Table";
import PageContainer from "../../../../../components/layout/PageContainer";
import PageSection from "../../../../../components/layout/PageSection";
import {Title, Button} from "@wargostec/react-components";
import {ButtonBox} from "../../../../../components/forms/Button";
import Card from "../../../../../components/forms/Card";
import GenericModal2 from "../../../../../modals/GenericModal2";
import DeleteGenericModal from "../../../../../modals/DeleteGenericModal";
import {SiteContext} from "../../../../common/context/SiteContext";
import {Apis} from "../../../../../util/Apis";
import useEditModal from "../../../../../modals/useEditModal";
import useDeleteModal from "../../../../../modals/useDeleteModal";
import axios from "axios";
import TipoListaLudopatiaNew from "./TipoListaLudopatiaNew";
import TipoListaLudopatiaEdit from "./TipoListaLudopatiaEdit";
import useFetch2 from "../../../../../hooks/useFetch2";
import {useHistory} from "react-router";

const TipoListaLudopatiaList = () => {

  const history = useHistory()

  const {salaId} = useContext(SiteContext)
  const {data, fetchData} = useFetch2(Apis.BLACK + `/ban-list?salaId=${salaId}`, []);

  //hooks para crear,editar y eliminar
  const {wrapper: wrapper1, addAction, editAction} = useEditModal({
    editComponent: <TipoListaLudopatiaEdit/>,
    addComponent: <TipoListaLudopatiaNew/>,
    onRefresh: fetchData
  })

  const deleteElement = id => axios.delete(Apis.BLACK + '/ban-list/' + id)

  const {
    wrapper: wrapper2,
    openDeleteModal
  } = useDeleteModal({
    text: '¿Desea eliminar este tipo de lista?',
    action: deleteElement,
    onRefresh: fetchData
  })

  const verAction = (id) => {
    history.push('/tgm/ludopatia-list?list='+id)
  }

  return (
    <PageContainer>
      <PageSection>
          <Title text="Listas de ludopatía" type={'page'}/>
        <ButtonBox>
          <Button variant={'primary'} text={'Nuevo'} onClick={() => addAction()}/>
        </ButtonBox>

        <Card padding>

          <Table striped hover>
            <thead>
            <tr>
              <th>Lista</th>
              <th/>
            </tr>
            </thead>
            <tbody>
            {data && data.map(item =>
              <tr key={item.id}>
                <td>{item.name}</td>
                <td>

                    <ButtonBox>
                      <Button size={'s'} onClick={() => verAction(item.id)}>Ver</Button>
                      {
                        item.salaId !== 11 &&
                        <>
                          <Button size={'s'} onClick={() => editAction(item.id)}>Editar</Button>
                          <Button size={'s'} onClick={() => openDeleteModal(item.id)}>Eliminar</Button>
                        </>
                      }
                    </ButtonBox>

                </td>
              </tr>
            )}
            </tbody>
          </Table>

        </Card>
      </PageSection>

      <GenericModal2 {...wrapper1}/>
      <DeleteGenericModal {...wrapper2}/>

    </PageContainer>
  )
};

export default TipoListaLudopatiaList;
