import React from "react";
import Card from "../../../../components/forms/Card";
import PolicyActions from "./actions/PolicyActions";


const PolicyForm = () => {


  return (
    <form>
      <Card padding style={{marginBottom: "25px"}}>
        <PolicyActions/>
      </Card>
    </form>
  )
}

export default PolicyForm;
