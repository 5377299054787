import {useHistory, useParams} from 'react-router';
import PageContainer from '../../../../components/layout/PageContainer';
import PageSection from '../../../../components/layout/PageSection';
import {PageBackLabel} from '../../../../components/layout/PageBackLabel';
import {Title} from "@wargostec/react-components";
import React, {useContext} from 'react';
import useEditModal from '../../../../modals/useEditModal';
import GenericModal2 from '../../../../modals/GenericModal2';
import PolicyNameEdit from './PolicyNameEdit';
import PolicyForm from './PolicyForm';
import {SiteContext} from '../../../common/context/SiteContext';

const PolicyEdit = () => {
	const history = useHistory();
	const {id, policyName} = useParams();
	const {salaId} = useContext(SiteContext);
	const policySalaId = localStorage.getItem('policySalaId');

	const {wrapper: wrapper1, editAction} = useEditModal({
		editComponent: <PolicyNameEdit />
	});

	return (
		<PageContainer>
			<PageSection>
				<PageBackLabel
					text='Políticas'
					onClick={() => history.push('/admin/policies')}
				/>
					<Title text={'Política | ' + policyName} type={'page'}/>
			</PageSection>

			<PageSection>
				<PolicyForm />
			</PageSection>
			<GenericModal2 {...wrapper1} />
		</PageContainer>
	);
};
export default PolicyEdit;
