import React, {useContext, useEffect} from 'react';
import {SiteContext} from "../../context/SiteContext";
import {Apis} from "../../../../util/Apis";
import useFetch2 from "../../../../hooks/useFetch2";
import {Select, SelectV2} from "../../../../components/forms/FormEl";

const CategoriesSelect = React.forwardRef((props, ref) => {

  const {salaId} = useContext(SiteContext)

  let url = `${Apis.FOOD}/food-categories?salaId=${salaId}`

  const {data, loading, error} = useFetch2(url)

  useEffect(() => {
    if (data) {
      data.push({id: "", name: "TODOS"})
    }
  }, [data])

  return (
    <>
      {
        !loading ?
          <SelectV2
            ref={ref}
            selectStyle={props.selectStyle}
            options={data}
            {...props}
          >
          </SelectV2>
          :
          <Select disabled={true}>
            <option key={0} value={0}>{"Cargando..."}</option>
          </Select>
      }
    </>
  )
})
export default CategoriesSelect;
