import React, {useCallback, useMemo} from 'react';
import {Title} from "@wargostec/react-components";
import AsyncBlock from "../../../../components/forms/AsyncBlock";
import ReactTable from "../../../../components/util/ReactTable";
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import {FlexLayout} from "../../../../components/flex/Flex";
import Moment from "react-moment";
import CardOverview, {CardOverviewContainer} from "../../../../components/layout/CardOverview";
import {HiOutlineBanknotes} from "react-icons/hi2";
import Card from "../../../../components/forms/Card";
import {HiOutlineSlotMachine} from "../../../../components/assets/icons/slot-machine";

const ModalEventBill = ({values}) => {

  const arrayEvent= [71,72,73,74,75,76,77,78,79,80];
  const eventsId = arrayEvent.join(',');
  let defaultParams = {confId: values.confId, eventsId: eventsId, size: values.size, ini : values.ini, fin : values.fin};
  const {data, loading, error} = useFetch2(Apis.TGM + `/events`, [], defaultParams);

  const eventName = useCallback(props => <>{
    <FlexLayout alignItems='left' justifyContent='left' gap='4px'>
      <span>{props.row.original.eventId + ' - ' + props.row.original.nameEventEs}</span>
    </FlexLayout>
  }</>, [])

  const dateValue = useCallback(props => <>{
    <FlexLayout alignItems='left' justifyContent='left' gap='4px'>
      <span><Moment format={'HH:mm DD/MM/YYYY'}>{props.row.original.eventDate}</Moment></span>
    </FlexLayout>
  }</>, [])

  const columns = useMemo(
    () => [
      {
        header: 'ID',
        accessorKey: 'id',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Fecha / Hora',
        accessorKey: 'eventDate',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        },
        cell: dateValue
      },
      {
        header: 'Evento',
        accessorKey: 'nameEventEs',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        },
        cell: eventName
      },
    ]
    , []
  )

  const sumAmount = (array) => {
    let sum = 0;
    if (array) {
      array.forEach((item) => {
        sum += getAmountByEventId(item.eventId);
      });
      return sum;
    }
    return sum;
  }

  const getAmountByEventId = (code) => {
    switch (code) {
      case 71:
        return 1
      case 72:
        return 5
      case 73:
        return 10
      case 74:
        return 20
      case 75:
        return 50
      case 76:
        return 100
      case 77:
        return 2
      case 78:
        return 500
      case 80:
        return 200
      default:
        return 0
    }
  }

  return (
    <>
      <Title text="Eventos de billetero" type={'page'}/>
      <Card>
        <CardOverviewContainer gridColumns='repeat(2, 1fr)'>
          <CardOverview
            title={values?.machine ?? "---"}
            subtitle='Maquina'
            icon={<HiOutlineSlotMachine size={20}/>}
          />
          <CardOverview
            title={'S/ ' + sumAmount(data)}
              subtitle='Total'
              icon={<HiOutlineBanknotes size={20}/>}
          />
        </CardOverviewContainer>
      </Card>
      <Card>
        <AsyncBlock loading={loading} error={error}>
          <ReactTable
            columns={columns}
            data={data || []}
            customSearch
            noData='No existen resultados'
            pageSize={15}
          />
        </AsyncBlock>
      </Card>
    </>
  );
};

export default ModalEventBill;
