import React, {useContext, useEffect, useState} from 'react'
import {Select} from "../../../../components/forms/FormEl";
import {Apis} from "../../../../util/Apis";
import {SiteContext} from "../../context/SiteContext";
import useFetch2 from "../../../../hooks/useFetch2";

const ZoneSelect = React.forwardRef(({allOrNone = true,...props}, ref) => {

  const [values, setValues] = useState([]);
  const {salaId} = useContext(SiteContext)
  const {data, loading} = useFetch2(Apis.TGM + '/slot-zones?salaId=' + salaId)

  useEffect(() => {
    if (data) {
      data.push({id: 0, name: "NINGUNA"})
      setValues(data)
    }
  }, [data])

  return (
    <>
      {
        !loading ?
          <Select disabled={props.disabled} ref={ref} {...props}>
            {allOrNone && <option key={0} value={0}>{"TODAS"}</option>}
            {values.map(item => <option key={item.id} value={item.id}>{item.name}</option>)}
          </Select>
          :
          <Select disabled={true}>
            <option key={0} value={0}>{"Cargando..."}</option>
          </Select>
      }
    </>
  )
})

export default ZoneSelect;
