import {Controller, useForm} from "react-hook-form";
import React, {useEffect} from "react";
import Form from "react-bootstrap/Form";
import FormGroup from "../../../../components/forms/FormGroup";
import Input, {Select} from "../../../../components/forms/FormEl";
import CustomerSearchInput from "../../../common/components/CustomerSearchInput";
import CustomerSearchInputV2 from "../../../common/components/CustomerSearchInputV2";

const AddFundsForm = ({value, onSubmit, setAction}) => {

  const {register, control, handleSubmit, watch, errors, reset, setValue, getValues} = useForm();

  useEffect(() => {
    if (value) {
      //react hook form descartar las propiedades que no tienen un componente
      reset(value)
    }
  }, [value])

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit))
  }, [])

  return (
    <Form>
      <FormGroup>
        <label>Cliente</label>
        <Controller name="customerId" control={control} render={({ field }) => <CustomerSearchInputV2 {...field} />}/>
      </FormGroup>

      <FormGroup controlId="couponTypeId">
        <Form.Label>Monto (S/.)</Form.Label>
        <Input type={"number"} {...register("amount")}/>
      </FormGroup>
    </Form>
  )
}

export default AddFundsForm
