import React from 'react'
import {Select} from "../../../../components/forms/FormEl";

const DenomSelect = React.forwardRef((props, ref) => {

  return (
    <Select ref={ref} {...props}>
      <option value={0.01}>0.01</option>
      <option value={0.05}>0.05</option>
      <option value={0.10}>0.10</option>
      <option value={0.50}>0.50</option>
      <option value={1.00}>1.00</option>
      <option value={2.00}>2.00</option>
      <option value={5.00}>5.00</option>
      <option value={10.00}>10.00</option>
    </Select>
  )
})

export default DenomSelect;
