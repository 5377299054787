import React, {useContext} from 'react';
import {SiteContext} from "../../context/SiteContext";
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import {Select, SelectV2} from "../../../../components/forms/FormEl";

const MesasSelect = React.forwardRef((props, ref) => {

  const {salaId} = useContext(SiteContext)

  const {data, loading} = useFetch2(Apis.TABLES + `/tables?salaId=${salaId}`,[])

  const mesasData = (data) => {
    let formatTable = []
    if (data){
      formatTable = data.map(table => {
        return {
          id: table.id,
          name: `${table.type} - ${table.tableCode}`
        }
      })
    }
    formatTable.push({
      id:"",
      name: "TODOS"
    })
    return formatTable
  }

  return (
    <>
      {
        !loading ?
          <SelectV2
            ref={ref}
            options={mesasData(data)}
            {...props}
          >
          </SelectV2>
          :
          <Select disabled={true}>
            <option key={0} value={0}>{"Cargando..."}</option>
          </Select>
      }
    </>
  )
})

export default MesasSelect;
