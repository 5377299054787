import React, {useContext, useEffect, useState} from 'react'
import {SiteContext} from "../../../common/context/SiteContext";
import {Apis} from "../../../../util/Apis";
import useFetch2 from '../../../../hooks/useFetch2';
import axios from "axios";
import {errorToast, successToast} from "../../../../components/layout/ToastStyles";
import {LoadingTableV2} from "../../../../components/forms/Tables";
import Table from "react-bootstrap/Table";
import Button, {ButtonBox} from "../../../../components/forms/Button";
import {Select} from "../../../../components/forms/FormEl";
import Slidebar from "../../../common/layout/Slidebar";
import FormGroup from "../../../../components/forms/FormGroup";
import SalaIdInput from "../../../common/components/SalaIdInput";
import {useForm} from "react-hook-form";
import {useHistory} from "react-router";
import {FiPrinter} from "react-icons/fi";
import PrinterNew from "./PrinterNew";
import PrinterEdit from "./PrinterEdit";
import useEditModal from "../../../../modals/useEditModal";
import GenericModal2 from "../../../../modals/GenericModal2";
import SwitchToggle from "../../../../components/layout/SwitchToggle";
import {FaPencilAlt} from "react-icons/fa";

const PrinterList = () => {
  const {salaId} = useContext(SiteContext)
  const [enabled, setEnabled] = useState(0)
  const [params, setParams] = useState()
  const [filter, setFilter] = useState(true)
  let history = useHistory()

  const {
    data, loading, fetchData
  } = useFetch2(Apis.PRINT + `/printers/state-report?accountId=${salaId}&enabled=${filter}`, [], false);

  const {wrapper: wrapper1, refresh: refresh1, addAction, editAction} = useEditModal({
    addComponent: <PrinterNew/>, editComponent: <PrinterEdit/>, onRefresh: fetchData
  })

  const lockAndUnlock = async (id, locked) => {
    if (!locked) {
      await axios.post(Apis.PC + '/coupon-type/' + id + '/enable-print')
        .then(resp => {
          setEnabled(enabled + 1)
          successToast("Impresora habilitada correctamente")
        }).catch(err => {
          let toastrTxt = "Ocurrio un problema. Por favor intenta nuevamente"
          errorToast(toastrTxt)
          fetchData()
        });
    } else {
      await axios.post(Apis.PC + '/coupon-type/' + id + '/disable-print')
        .then(resp => {
          setEnabled(enabled + 1)
          successToast("Impresora deshabilitada correctamente")
        }).catch(err => {
          console.log(err)
          errorToast(err.response.data.message, 4000)
          fetchData()
        });
    }
  }

  const goPrinterJob = (id) => {
    history.push('computer-printer-job-list/' + id)
  }

  const goPrinterTest = (id) => {
    axios.post(Apis.PRINT + '/printers/' + id + '/test')
      .then(resp => {
        successToast("Impresora probada correctamente")
      })
      .catch(err => {
        successToast("Error al probar impresora")
      })
  }

  useEffect(() => {
    fetchData()
  }, [enabled, refresh1])

  const [showFilter, setShowFilter] = useState(false)
  const {register, handleSubmit, watch} = useForm();


  const getData = (params) => {
    setFilter(params.enabled)
  }

  useEffect(() => {
    if (params) {
      fetchData()
    }
  }, [params])

  useEffect(() => {
    let params = watch()
    setParams(params)
  }, [])

  return (<>
      <h4 style={{fontSize: '1.4rem', margin: '2rem 0', fontWeight: '600'}}>Impresoras</h4>
      <ButtonBox style={{marginBottom: '2rem'}}>
        <Button title={'Nuevo'} variant='primary' onClick={() => addAction()}/>
        <Button title={'Filtrar'} onClick={() => setShowFilter(!showFilter)}/>
      </ButtonBox>

      <Slidebar visible={showFilter} onHide={() => setShowFilter(false)}>
        <div style={{padding: '16px'}}>
          <FormGroup>
            <SalaIdInput {...register("salaId")} />
            <label>Estado</label>
            <Select type='text' {...register("enabled")}>
              <option value={true}>Habilitadas</option>
              <option value={false}>Deshabilitadas</option>
            </Select>
          </FormGroup>
          <Button variant={'primary'} title='Buscar' onClick={handleSubmit(getData)}/>
        </div>
      </Slidebar>

      <LoadingTableV2 loading={loading} data={data}
                      noDataText={'No hay impresoras registradas'}>
        <Table striped hover responsive={true} size={'sm'}>
          <thead>
          <tr>
            <th className={'text-center'}>ID</th>
            <th className={'text-left'}>Impresora</th>
            <th className={'text-left'}>PC</th>
            <th className={'text-center'}>Puerto</th>
            <th className={'text-center'}>IP</th>
            <th className={'text-center'}>Estado</th>
            <th className={'text-center'}>Mensaje</th>
            <th className={'text-center'}>Habilitar</th>
            <th className={'text-center'}>Acciones</th>
          </tr>
          </thead>
          <tbody>
          {data && data.map(item => <tr key={item.printerId}>
            <td className="text-center">{item.printerId}</td>
            <td className="text-left">{item.printerName}</td>
            <td className="text-left">{item.hostname}</td>
            <td className="text-center">{item.portInterfaceName}</td>
            {
              item.type == 'DIRECT' ?
                <td className="text-center">{item.manualIp}</td>
                :
                <td className="text-center">{item.portName}</td>

            }
            <td className="text-center">{item.state}</td>
            <td className="text-center">
              {!item.connected && 'Falta conexión'}
            </td>
            <td className={'text-center'}>
              <SwitchToggle
                defaultChecked={item.enabled}
                id={"printer" + item.printerId}
                name={"enabled"}
                label={"Hab"}
                onClick={() => lockAndUnlock(item.printerId, item.enabled)}/>
            </td>
            <td style={{display: 'flex', gap: '5px', justifyContent: 'center'}}>
              <Button variant={'light'} size={'s'} onClick={() => goPrinterJob(item.printerId)}>
                Impresiones
              </Button>
              <Button variant={'light'} size={'s'} onClick={() => goPrinterTest(item.printerId)}>
                <FiPrinter/> Probar
              </Button>
              <Button variant={'light'} size={'s'} onClick={() => editAction(item.printerId)}>
                <FaPencilAlt/>
              </Button>
            </td>
          </tr>)}
          </tbody>
        </Table>
      </LoadingTableV2>

      <GenericModal2 {...wrapper1}/>
    </>
  )
}

export default PrinterList;
