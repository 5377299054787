import React, {useEffect, useState} from "react";
import axios from "axios";
import {Apis} from "../../../../util/Apis";
import SorteoForm from "./SorteoForm";
import {errorToast, successToast} from "../../../../components/layout/ToastStyles";

const SorteoEditBulk = ({grupoId,tipos, setAction, setTitle}) => {
  const [tipo,setTipo] = useState('')

  const update = async (params) => {
    let sorteos = {
      grupoId : grupoId,
      sorteoUpdate : {},
    }
    if(params.amount !== '') sorteos.sorteoUpdate.amount = params.amount
    if(params.couponTypeId !== '-1') sorteos.sorteoUpdate.couponTypeId = params.couponTypeId
    if(params.anforaId !== '0') sorteos.sorteoUpdate.anforaId = params.anforaId
    if(params.zoneId !== '-1') sorteos.sorteoUpdate.zoneId = params.zoneId
    if(params.avgBet !== '') sorteos.sorteoUpdate.avgBet = params.avgBet
    if(params.sessionCi !== '') sorteos.sorteoUpdate.sessionCi = params.sessionCi
    if(params.sessionDuration !== '') sorteos.sorteoUpdate.sessionDuration = params.sessionDuration
    if(params.showSince?.length === 16) sorteos.sorteoUpdate.showSince = params.showSince
    if(params.showUntil?.length === 16) sorteos.sorteoUpdate.showUntil = params.showUntil
    if(params.winnerQuantity !== '') sorteos.sorteoUpdate.winnerQuantity = params.winnerQuantity
    await axios.put(Apis.SORTEO + '/sorteos/bulk', sorteos)
      .then(res => {
        successToast()
      })
      .catch(err => {
        errorToast();
      })
  }

  useEffect(() => {
    setTitle("Edición de grupo")
    if (tipos) {
      if([...tipos].length > 1) {
        setTipo('MIXTO')
      } else {
        let [tipoAux] = tipos
        setTipo(tipoAux)
      }
    }
  }, [])

  return (
    <React.Fragment>
      {tipo?.length > 0 &&
      <SorteoForm onSubmit={update} setAction={setAction} tipoDefault={tipo}/>
      }
    </React.Fragment>
  )
}

export default SorteoEditBulk
