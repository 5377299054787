import React, {useCallback, useContext, useMemo, useState} from 'react';
import {useHistory, useParams} from "react-router";
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import {FlexLayout} from "../../../../components/flex/Flex";
import Button from "../../../../components/forms/Button";
import MoneyColoredRound from "../../../../components/util/MoneyColored";
import Moment from "react-moment";
import PageSection from "../../../../components/layout/PageSection";
import AsyncBlock from "../../../../components/forms/AsyncBlock";
import ReactTable from "../../../../components/util/ReactTable";
import PageContainer from "../../../../components/layout/PageContainer";
import {PageBackLabel} from "../../../../components/layout/PageBackLabel";
import {SiteContext} from "../../../common/context/SiteContext";
import useEditModal from "../../../../modals/useEditModal";
import GenericModal2 from "../../../../modals/GenericModal2";
import MovimientosFondoNew from "./MovimientosFondoNew";
import {Title} from "@wargostec/react-components";
import UpdateTransferComponent from "./lista/UpdateTransferComponent";
import {DateTime} from 'luxon';

export const getStatusName = (value) => {
  switch (value) {
    case 1:
      return 'Pendiente'
    case 2:
      return 'Aprobado'
    case 3:
      return 'Rechazado'
    default:
      return 'Pendiente'
  }
}

const MovimientosFondo = (callback, deps) => {

  const {salaId} = useContext(SiteContext)
  const {id} = useParams()
  const querystring = window.location.search
  const query = new URLSearchParams(querystring)
  let name = query.get('name')
  let defaultParams = {salaId: salaId, fondoId: id}
  const [params, setParams] = useState(defaultParams)
  const history = useHistory()

  console.log(name)

  const {loading, data, fetchData, error} = useFetch2(Apis.CAJA + '/operation-transfer/report/v2', [], params);

  const {
    wrapper: wrapper2,
    addAction: updateTransfer,
  } = useEditModal({
    addComponent: <UpdateTransferComponent />,
    onRefresh: () => {
      fetchData()
    }
  });

  const {
    wrapper: wrapper1,
    addAction,
  } = useEditModal({
    addComponent: <MovimientosFondoNew />,
    onRefresh: () => {
      setTimeout(() => {
        fetchData()
      }, 1000)
    }
  });

  const NumberComponent = useCallback(props =>
    <MoneyColoredRound
      currencySymbol={data.currencySymbol}>{props.renderValue()}
    </MoneyColoredRound>, [data]);

  const FondoDeOrigen = useCallback(props => <> {
    <FlexLayout justifyContent={'center'}>
      {
        props.row.original.amount > 0 ?
          <div>{props.row.original.relatedFondoName}</div> :
          <div>{props.row.original.fondoName}</div>
      }
    </FlexLayout>
  }</>, deps)

  const FondoDeDestino = useCallback(props => <> {
    <FlexLayout justifyContent={'center'}>
      {
        props.row.original.amount > 0 ?
          <div>{props.row.original.fondoName}</div> :
          <div>{props.row.original.relatedFondoName}</div>
      }
    </FlexLayout>
  }</>)

  const approveTransfer = useCallback((props) => {
    const relatedOperationId = props.row.original.relatedOperationId
    return (
      <FlexLayout justifyContent={'center'}>
        {props.row.original.status === 1 && (
          <>
            {
              relatedOperationId === null &&
              <>
                <Button variant={'success'} size='xs' onClick={() => updateTransfer({id: props.row.original.id, status: 2})}>
                  Aprobar
                </Button>
                <Button variant={'warning'} size='xs' onClick={() => updateTransfer({id: props.row.original.id, status: 3})}>
                  Rechazar
                </Button>
              </>
            }
            {
              relatedOperationId !== null &&
              <Button variant={'danger'} size='xs' onClick={() => updateTransfer({id: props.row.original.id, status: 4})}>
                Eliminar
              </Button>

            }
          </>
        )}
      </FlexLayout>
    );
  }, []);

  const createdValue = useCallback(props => <>{
    props.renderValue() ?
      <FlexLayout alignItems='center' justifyContent='center' gap='4px'>
        <Moment format={'YYYY-MM-DD hh:mm a'}>{props.renderValue()}</Moment>
      </FlexLayout>
      :
      <span>---</span>
  }</>, []);

  const columns = useMemo(
    () => [
      {
        header: 'Id',
        accessorKey: 'id',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Fondo de origen',
        accessorKey: 'fondoName',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: FondoDeOrigen
      },
      {
        header: 'Fondo destino',
        accessorKey: 'relatedFondoName',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: FondoDeDestino
      },
      {
        header: 'Monto',
        accessorKey: 'amount',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: NumberComponent
      },
      {
        header: 'Fecha',
        accessorKey: 'createdAt',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: createdValue
      },
      {
        header: 'Estado',
        id: 'status',
        accessorFn: row =>getStatusName(row.status),
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Acciones',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: approveTransfer
      }
    ]
    , []
  )

  return (
    <PageContainer>
      <PageSection>
        <PageBackLabel text='Fondos' onClick={() => history.goBack()}/>
        <Title text={name} type={'page'}/>
        <AsyncBlock loading={loading} error={error}>
          <ReactTable
            columns={columns}
            data={data && data.data || []}
            customSearch
            noData='No existen resultados'
            pageSize={15}
            customButton={
              <Button
                variant={'dark'}
                title={'Nueva operación'}
                onClick={() => addAction()}
              />
            }
          />
        </AsyncBlock>
      </PageSection>
      <GenericModal2 {...wrapper1} />
      <GenericModal2 {...wrapper2} />
    </PageContainer>
  );
};

export default MovimientosFondo;
