import React, {useRef, useState, useContext} from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";
import {CustomerContext} from "../../modules/common/context/CustomerContext";
import {DateTime} from "luxon";
import {FlexLayout} from "../flex/Flex";

const ContainerLink = styled.div`
  position: relative;
  a{
    text-decoration: none;
    color:#0A0A0A;
  }
`;
const TooltipStyle = styled.div`
  display: ${(props) => (props.show ? "flex" : "none")};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  white-space: initial;
  width: 250px;
  height: 100px;
  position: fixed;
  top: ${(props) => props.posRef.current.y-110}px;
  left: ${(props) => props.posRef.current.x-125}px;
  font-size: var(--fsize-1);
  font-weight: 700;
  letter-spacing: 0.02em;
  z-index: ${(props) => (props.show ? "1" : "-1")};
  padding: 10px;
  opacity: ${(props) => props.show};
  background-color: white;
  color: black;
  box-shadow: rgb(0 0 0 / 10%) 0 0 10px;
  border-radius: 10px;
  transition-property: transform, opacity !important;
  transition-duration: 0.06s !important;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1) !important;
  transform: scale(${(props) => (props.show ? 1 : 0.7)});
  ::after {
    content: "";
    display: inline-block;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #fff;
    position: absolute;
    bottom: -10px;
    left: calc(50% - 10px);
    z-index: -1;
  }
`;

const ContainerInformation = styled.div`
  display: flex;
  align-items:center;
  flex-direction:row;
  gap: 8px;
  >div>span{
    font-size:11px;
    font-weight:400;
    text-align:center;
  }
  >div>strong{
    font-size:12px;
    font-weight:700;
    text-align:center;
  }
`;

const getPoint = (element, tooltip) => {
  const pt = {x: 0, y: 0};
  const elRect = element.getBoundingClientRect();
  pt.x = elRect.left + (element.offsetWidth - tooltip.offsetWidth) / 2;
  pt.y = elRect.top - (tooltip.offsetHeight + 6);
  return pt;
};

const CustomerLink = ({item, name, customerId}) => {
  const [show, setShow] = useState(0);
  const posRef = useRef({x: 0, y: 0});
  const tooltipRef = useRef();
  const {loading, getCustomerInfo} = useContext(CustomerContext);

  const [data, setData] = useState(null);

  const handleOver = async (e) => {
    setShow(1);
    posRef.current = getPoint(e.currentTarget, tooltipRef.current);
    const result = await getCustomerInfo(customerId ? customerId : item.customerId);
    setData(result);
  };

  const handleOut = () => setShow(0);

  const getAge = (birthday) => {
    if (birthday === null || birthday === undefined) {
      return "";
    }

    var ageDifMs = Date.now() - new Date(birthday).getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  return (
    <ContainerLink>
      <Link
        to={`/crm/customer-info/${item.customerId}`}
        onMouseOver={handleOver}
        onMouseOut={handleOut}
      >
        {name ? name : item ? item.firstName + " " + item.lastName1 : "NA"}
      </Link>

      <TooltipStyle ref={tooltipRef} posRef={posRef} show={show}>
        {loading ? (
          <p style={{color: "gray"}}>Cargando...</p>
        ) : (
          <>
            {data !== null && (
              <>
                <strong style={{fontSize: '12px', textAlign: 'center'}}>
                  {name ? name
                    : item
                      ? item.firstName + " " + item.lastName1
                      : "--"}
                </strong>
                <ContainerInformation>
                  <FlexLayout column alignItems='center' gap='4px'>
                    <span>Desde:</span>
                    <strong>
                      {data?.person?.createdAt &&
                        DateTime.fromISO(data.person.createdAt).toFormat("yyyy-MM-dd")}
                    </strong>
                  </FlexLayout>
                  <FlexLayout column alignItems='center' gap='4px'>
                    <span>Edad:</span>
                    <strong>
                      {data?.person?.birthday ? getAge(data.person.birthday) : "--"}
                    </strong>
                  </FlexLayout>
                  <FlexLayout column alignItems='center' gap='4px'>
                    <span>Cumpleaños:</span>
                    <strong>
                      {data?.person?.birthday ? data?.person?.birthday : "--"}
                    </strong>
                  </FlexLayout>
                  {data?.levelName !== null &&
                    <FlexLayout column alignItems='center' gap='4px'>
                      <span>Nivel:</span>
                      <strong>{data?.levelName ? data?.levelName : "--"}</strong>
                    </FlexLayout>
                  }
                </ContainerInformation>
              </>
            )}
          </>
        )}
      </TooltipStyle>
    </ContainerLink>
  );
};

export default CustomerLink;
