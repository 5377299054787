import React, {useContext, useEffect} from "react";
import PageSection from "../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import FormGroup, {FormBlock} from "../../../../components/forms/FormGroup";
import Button, {ButtonBox} from "../../../../components/forms/Button";
import LudopatiaListSelect from "../../../common/components/selects/LudopatiaListSelect";
import PageContainer from "../../../../components/layout/PageContainer";
import {useForm} from "react-hook-form";
import {PageBackLabel} from "../../../../components/layout/PageBackLabel";
import Input from "../../../../components/forms/FormEl";
import {SiteContext} from "../../../common/context/SiteContext";
import {useHistory} from "react-router";
import SwitchToggle from "../../../../components/layout/SwitchToggle";


const LudopatiaForm = ({value, onSubmit, edit}) => {

  const {salaId} = useContext(SiteContext)
  const history = useHistory()

  let defaultParams = {salaId: salaId, enabled: true}
  const {register, handleSubmit, reset} = useForm({defaultValues: defaultParams});

  useEffect(() => {
    if (value) {
      reset(value)
    }
  }, [value])

  return (
    <PageContainer>
      <PageSection>
        <PageBackLabel text='Lista' onClick={() => history.push('/tgm/ludopatia/gestion-listas')}/>
          <Title text={"Agregar/Editar ludópata"}/>
      </PageSection>
      <PageSection>
        <FormBlock>
          <FormGroup>
            <label>Apellido Paterno</label>
            <Input {...register("lastName1")}/>
          </FormGroup>
          <FormGroup>
            <label>Apellido Materno</label>
            <Input {...register("lastName2")}/>
          </FormGroup>
          <FormGroup>
            <label>Nombres</label>
            <Input {...register("firstName")}/>
          </FormGroup>
          <FormGroup>
            <label>Lista</label>
            <LudopatiaListSelect {...register("banListId")}/>
          </FormGroup>
          <FormGroup>
            <label>Documento</label>
            <Input name={"document"} {...register("document")}/>
          </FormGroup>
          <FormGroup>
            <label>Comentario</label>
            <Input name={"comment"} {...register("comment")}/>
          </FormGroup>
          <FormGroup>
            <label>Bloquear entrada</label>
            <SwitchToggle {...register("blockEntry")}/>
          </FormGroup>
        </FormBlock>
        <ButtonBox>
          <Button title={"Guardar"} variant={'primary'} onClick={handleSubmit(onSubmit)}/>
        </ButtonBox>
      </PageSection>
    </PageContainer>
  )
}

export default LudopatiaForm
