import React from 'react';
import PageContainer from '../../../../../components/layout/PageContainer';
import {Title} from "@wargostec/react-components";
import Button, {ButtonBox} from '../../../../../components/forms/Button';
import {FaPlus} from 'react-icons/fa';
import PageSection from '../../../../../components/layout/PageSection';
import Card from '../../../../../components/forms/Card';
import Table from 'react-bootstrap/Table';
import useFetch2 from '../../../../../hooks/useFetch2';
import {Apis} from '../../../../../util/Apis';
import Moment from 'react-moment';
import {useParams} from 'react-router';
import GenericModal2 from '../../../../../modals/GenericModal2';
import DeleteGenericModal from '../../../../../modals/DeleteGenericModal';
import useEditModal from '../../../../../modals/useEditModal';
import useDeleteModal from '../../../../../modals/useDeleteModal';
import CustomerGroupDetailNew from './CustomerGroupDetailNew';
import axios from 'axios';
import CustomerLink from '../../../../../components/util/CustomerLink';

const CustomerGroupDetailList = () => {
	const {id} = useParams();
	const {data, fetchData} = useFetch2(
		Apis.KCUSTOMERGROUP + '/group-member?groupId=' + id
	);

	const {
		wrapper: wrapper1,
		addAction
	} = useEditModal({
		addComponent: <CustomerGroupDetailNew />,
		onRefresh: fetchData
	});

	const deleteElement = (id) =>
		axios.delete(Apis.KCUSTOMERGROUP + '/group-member/' + id);

	const {
		wrapper: wrapper2,
		openDeleteModal
	} = useDeleteModal({
		text: '¿Desea eliminar este cliente?',
		action: deleteElement,
		onRefresh: fetchData
	});

	return (
		<PageContainer>
			<PageSection>
					<Title text="Clientes en el grupo"/>
				<ButtonBox>
					<Button variant={'primary'} onClick={() => addAction()}>
						<FaPlus /> Nuevo
					</Button>
				</ButtonBox>
			</PageSection>

			<PageSection>
				<Card padding>
					<Table striped hover>
						<thead>
							<tr>
								<th>Id Cliente</th>
								<th>Cliente</th>
								<th>Documento</th>
								<th>Fecha de registro</th>
								<th />
							</tr>
						</thead>
						<tbody>
							{data &&
								data.map((item) => (
									<tr key={item.id}>
										<td>{item.customerId}</td>
										<td>{<CustomerLink item={item} />}</td>
										<td>{item.document}</td>
										<td>
											<Moment format={'DD/MM/YY HH:mm'}>
												{item.createdAt}
											</Moment>
										</td>
										<td>
											<ButtonBox>
												<Button
													variant={'danger'}
													size='xs'
													title={'Eliminar'}
													onClick={() =>
														openDeleteModal(item.id)
													}
												/>
											</ButtonBox>
										</td>
									</tr>
								))}
						</tbody>
					</Table>
					{/*{!loading ? data.length <= 0 && <NoDataTable text={"Aún no hay notificaciones en esta sala"}/> : <Loader1/>}*/}
				</Card>
			</PageSection>

			<GenericModal2 {...wrapper1} />
			<DeleteGenericModal {...wrapper2} />
		</PageContainer>
	);
};

export default CustomerGroupDetailList;
