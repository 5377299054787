import React, {useCallback, useEffect, useMemo, useState} from 'react';
import PageContainerFull from "../../../../../components/layout/PageContainerFull";
import PageSection from "../../../../../components/layout/PageSection";
import CardOverview, {CardOverviewContainer} from "../../../../../components/layout/CardOverview";
import moment from "moment";
import {HiOutlineBanknotes} from "react-icons/hi2";
import {HiOutlineClock} from "react-icons/hi";
import {Title} from "@wargostec/react-components";
import axios from "axios";
import {Apis} from "../../../../../util/Apis";
import Input from "../../../../../components/forms/FormEl";
import {Col, Row} from "../../../../../components/flex/FlexLayout";
import Card from "../../../../../components/forms/Card";
import FormGroup from "../../../../../components/forms/FormGroup";
import {CardTitle} from "../../../../crm/pages/coupons/PrinterDetail";
import {errorToast, successToast} from "../../../../../components/layout/ToastStyles";
import {useForm} from "react-hook-form";
import {useHistory, useParams} from "react-router";
import useFilasMesa from "../../../../../hooks/useFilasMesa";
import Button from "../../../../../components/forms/Button";
import styled from "styled-components";
import FondoSelect from "../../../../common/components/selects/FondoSelect";
import useFetch2 from "../../../../../hooks/useFetch2";
import ReactTable from "../../../../../components/util/ReactTable";
import AsyncBlock from "../../../../../components/forms/AsyncBlock";
import {getStatusName} from "../MovimientosFondo";
import {FlexLayout} from "../../../../../components/flex/Flex";
import Moment from "react-moment";
import MoneyColoredRound from "../../../../../components/util/MoneyColored";

const StrongLabel = styled.strong`
    text-align: right !important;
    display: inline-flex !important;
    justify-content: end !important;
`

const FondoClosed = () => {

  const {id} = useParams()
  const history = useHistory()
  const [preArqueo, setPreArqueo] = useState()
  const [enable, setEnable] = useState(false);
  const {rows, setRows, total} = useFilasMesa("CAJA")

  const {loading, data, fetchData, error} = useFetch2(Apis.CAJA + '/operation-transfer/report/v2', [], {fondoId: id});
  const {register, watch, reset, getValues} = useForm()

  const handleSaveArqueo = async () => {
    const body = {
      fondoId: id,
      foundedAmount: getValues("amount"),
      targetFondoId: getValues("targetFondoId")
    }
    try {
      await axios.post(Apis.CAJA + `/fondos/${id}/arqueo-and-open`, body)
      history.push('/caja/fondo-list')
      successToast()
    } catch (error) {
      errorToast({
        title: 'Error al generar el cierre, intente nuevamente en unos minutos',
        variant: 'danger',
      })
    }
  }

  const getData = async () => {
    const amount = getValues("amount")
    let body = {}
    if (amount) {
      body = {foundedAmount: amount}
    }
    try {
      const {data} = await axios.post(Apis.CAJA + `/fondos/${id}/re-open`, body)
      successToast("Pre cuadre generado")
      if (data) {
        setPreArqueo(data);
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  const FondoDeOrigen = useCallback(props => <> {
    <FlexLayout justifyContent={'center'}>
      {
        props.row.original.amount > 0 ?
          <div>{props.row.original.relatedFondoName}</div> :
          <div>{props.row.original.fondoName}</div>
      }
    </FlexLayout>
  }</>, [])

  const FondoDeDestino = useCallback(props => <> {
    <FlexLayout justifyContent={'center'}>
      {
        props.row.original.amount > 0 ?
          <div>{props.row.original.fondoName}</div> :
          <div>{props.row.original.relatedFondoName}</div>
      }
    </FlexLayout>
  }</>)

  const NumberComponent = useCallback(props =>
    <MoneyColoredRound
      currencySymbol={data.currencySymbol}>{props.renderValue()}
    </MoneyColoredRound>, [data]);

  const createdValue = useCallback(props => <>{
    props.renderValue() ?
      <FlexLayout alignItems='center' justifyContent='center' gap='4px'>
        <Moment format={'YYYY-MM-DD hh:mm a'}>{props.renderValue()}</Moment>
      </FlexLayout>
      :
      <span>---</span>
  }</>, []);

  const columns = useMemo(
    () => [
      {
        header: 'Id',
        accessorKey: 'id',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Fondo de origen',
        accessorKey: 'fondoName',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: FondoDeOrigen
      },
      {
        header: 'Fondo destino',
        accessorKey: 'relatedFondoName',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: FondoDeDestino
      },
      {
        header: 'Monto',
        accessorKey: 'amount',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: NumberComponent
      },
      {
        header: 'Fecha',
        accessorKey: 'createdAt',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: createdValue
      },
      {
        header: 'Estado',
        id: 'status',
        accessorFn: row => getStatusName(row.status),
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
    ]
    , []
  )

  return (
    <PageContainerFull>
      <PageSection>
        <Title text="Cierre de fondo" type={'page'}/>
        <div style={{paddingTop: "20px"}}>
          <CardOverviewContainer gridColumns='repeat(2, 1fr)'>
            <CardOverview
              title={preArqueo?.fecIni ? moment(preArqueo.fecIni).format("YYYY-MM-DD hh:mm:ss") : '---'}
              subtitle='Apertura de fondo'
              icon={<HiOutlineClock size={26}/>}
            />

            <CardOverview
              title={preArqueo?.realIniAmount ? 'S/ ' + (preArqueo?.realIniAmount).toFixed(2) : '---'}
              subtitle='Monto inicial'
              icon={<HiOutlineBanknotes size={26}/>}
            />
          </CardOverviewContainer>
        </div>
        <div className="flex flex-row gap-4">
          <Row>
            <Col>
              <Card className="flex-1">
                <div style={{padding: "15px"}} className="flex flex-col w-full">
                  <div className="flex flex-row gap-2 mb-1">
                    <CardTitle className="text-lg font-bold">Ingrese monto de cierre</CardTitle>
                  </div>
                  <Input
                    type='number'
                    placeholder="0"
                    className="w-full h-12"
                    classNameInput='text-lg'
                    {...register('amount')}
                  />
                  {
                    enable &&
                    <p style={{color: "red"}}>Campo obligatorio</p>
                  }
                  <div style={{paddingTop: '4px'}}>
                    <Button variant='dark' size={'s'} onClick={getData}>Pre arqueo</Button>
                  </div>
                </div>
              </Card>
              <Card>
                <div style={{padding: "10px"}} className="flex flex-col w-full">
                  <CardTitle className="text-lg font-bold">Fondo destino</CardTitle>
                  <FormGroup>
                    <FondoSelect {...register("targetFondoId")}/>
                  </FormGroup>
                </div>
              </Card>
              <div>
                <Button variant='primary' onClick={() => handleSaveArqueo()}>Guardar</Button>
              </div>
            </Col>
            <Col>
              <Card className="flex-1">
                <div style={{padding: "10px"}} className="flex flex-col w-full">
                  <CardTitle className="text-lg font-bold">Resumen</CardTitle>
                  <div style={{marginLeft: '30px', marginTop: '10px'}} className="flex flex-col flex-1 gap-4">
                    <FormGroup className="flex flex-row gap-2">
                      <label className="flex-1">Monto inicial</label>
                      <strong style={{marginLeft: '465px'}}
                              className="inline-flex justify-end text-right">{preArqueo?.realIniAmount != null ? (preArqueo?.realIniAmount).toFixed(2) : '---'}</strong>
                    </FormGroup>
                    <FormGroup className="flex flex-row gap-2">
                      <label className="flex-1">Transacciones</label>
                      <strong style={{marginLeft: '455px'}}
                              className="inline-flex justify-end text-right">{preArqueo?.totalOperationNoTransfer != null ? (preArqueo?.totalOperationNoTransfer).toFixed(2) : '---'}</strong>
                    </FormGroup>
                    <FormGroup className="flex flex-row gap-2">
                      <label className="flex-1">Monto final</label>
                      <strong style={{marginLeft: '475px'}}
                              className="inline-flex justify-end text-right">{preArqueo?.finAmount != null ? (preArqueo?.finAmount).toFixed(2) : '---'}</strong>
                    </FormGroup>
                    <hr/>
                    <FormGroup className="flex flex-row gap-2">
                      <label className="flex-1">Faltante / Sobrante</label>
                      <strong style={{marginLeft: '430px'}}
                              className="inline-flex justify-end text-right">{preArqueo?.diff != null ? (preArqueo?.diff).toFixed(2) : '---'}</strong>
                    </FormGroup>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </div>

        <Card style={{width: '100%', maxWidth: '700px', margin: '0 auto 20px', padding: '20px'}}>
          <div style={{textAlign: 'center'}}>
            <CardTitle style={{fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '20px'}}>Detalle de venta /
              operaciones</CardTitle>
            <div>
              <FormGroup className="flex flex-row gap-2">
                <label className="flex-1">Total tickets venta</label>
                <strong style={{marginLeft: '435px'}}
                        className="inline-flex justify-end text-right">{preArqueo?.ticketVenta != null ? (preArqueo?.ticketVenta).toFixed(2) : '---'}</strong>
              </FormGroup>
              <FormGroup className="flex flex-row gap-2">
                <label className="flex-1">Total billetero</label>
                <strong style={{marginLeft: '465px'}}
                        className="inline-flex justify-end text-right">{preArqueo?.bill != null ? (preArqueo?.bill).toFixed(2) : '---'}</strong>
              </FormGroup>
              <FormGroup className="flex flex-row gap-2">
                <label className="flex-1">Total mesas</label>
                <strong style={{marginLeft: '480px'}}
                        className="inline-flex justify-end text-right">{preArqueo?.mesas != null ? (preArqueo?.mesas).toFixed(2) : '---'}</strong>
              </FormGroup>
              <FormGroup className="flex flex-row gap-2">
                <label className="flex-1">Total tickets canje</label>
                <strong style={{marginLeft: '440px'}}
                        className="inline-flex justify-end text-right">{preArqueo?.ticket != null ? (preArqueo?.ticket).toFixed(2) : '---'}</strong>
              </FormGroup>
              <FormGroup className="flex flex-row gap-2">
                <label className="flex-1">Total pagos manuales</label>
                <strong style={{marginLeft: '413px'}}
                        className="inline-flex justify-end text-right">{preArqueo?.handpay != null ? (preArqueo?.handpay).toFixed(2) : '---'}</strong>
              </FormGroup>
              <FormGroup className="flex flex-row gap-2">
                <label className="flex-1">Total tarjetas</label>
                <strong style={{marginLeft: '472px'}}
                        className="inline-flex justify-end text-right">{preArqueo?.cardpay != null ? (preArqueo?.cardpay).toFixed(2) : '---'}</strong>
              </FormGroup>
              <FormGroup className="flex flex-row gap-2">
                <label className="flex-1">Total otros</label>
                <strong style={{marginLeft: '490px'}}
                        className="inline-flex justify-end text-right">{preArqueo?.other != null ? (preArqueo?.other).toFixed(2) : '---'}</strong>
              </FormGroup>
            </div>
          </div>
        </Card>


        <Card padding>
          <CardTitle><strong>Transferencias</strong></CardTitle>
          <AsyncBlock loading={loading} error={error}>
            <ReactTable
              columns={columns}
              data={data && data.data || []}
              customSearch
              noData='No existen resultados'
              pageSize={15}
            />
          </AsyncBlock>
        </Card>
      </PageSection>

    </PageContainerFull>
  );
};

export default FondoClosed;
