import React from 'react'
import {Select} from "../../../../components/forms/FormEl";

const EstadoCivilSelect = React.forwardRef((props, ref) => {

  return (
    <Select name='civil' ref={ref}>
      <option value={'N'}>NO SELECCIONADO</option>
      <option value={'S'}>SOLTERO</option>
      <option value={'C'}>CASADO</option>
      <option value={'V'}>VIUDO</option>
      <option value={'D'}>DIVORCIADO</option>
    </Select>
  )
})

export default EstadoCivilSelect
