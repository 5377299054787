import React, {useEffect, useState} from 'react'
import LayoutLegend from '../../../crm/pages/clientes/LayoutLegend'
import useFetch2 from '../../../../hooks/useFetch2';
import {Apis} from '../../../../util/Apis';
import LayoutCard from '../layout/LayoutCard';

const MeterOnlineLayout = () => {

  const [legendVisible, setLegendVisible] = useState(true);
  const [newData, setNewData] = useState(null);

  const legendData = [
    {label: 'Posición Libre', color: '#d4d4d4'},
    {label: 'Sin conex. servidor', color: '#dc2626'},
    {label: 'Sin conex. maquina', color: '#ffb8b8'},
    {label: 'Puerta abierta', color: '#A020F0'},
    {label: 'Conectado', color: '#065535'},
    {label: 'Jugando', color: '#facc15'}
  ]

  const {data, loading, fetchData} = useFetch2(`${Apis.TGM}/machines/meter-online-by-layout`, [])

  useEffect(() => {
    let intervalId = setInterval(fetchData, 30000); // Intervalo de 10 segundos
    return () => {
      clearInterval(intervalId);
    };
  }, [fetchData]);

  useEffect(() => {
    if (data) {
      const {frameDimensions, machines, positions, layoutKey} = data;
      const updatedPositions = positions?.map((item) => {
        const correspondingMachine = machines.find((machine) => machine.positionId === item.id);
        const infoMachine = correspondingMachine && correspondingMachine.positionId === item.id ? correspondingMachine : null;

        return {
          ...item,
          data: infoMachine,
        };
      });

      setNewData({
        frameDimensions,
        positions: updatedPositions,
        layoutKey,
      });
    }
  }, [data]);

  return (
    <>
      <LayoutCard
        data={newData}
        loading={loading}
        setHeight='calc(100vh - 14rem)'
        type='meterOnline'
        setLegendVisible={setLegendVisible}
        fetchData={fetchData}
        caption={
          <LayoutLegend
            legendVisible={legendVisible}
            legendData={legendData}
          />
        }
      />
    </>
  )
}

export default MeterOnlineLayout
