import React, {useContext, useEffect, useState} from 'react'
import Form from "react-bootstrap/Form";
import FormGroup from "../../../../components/forms/FormGroup";
import Input, {SelectV2} from "../../../../components/forms/FormEl";
import {SiteContext} from "../../../common/context/SiteContext";
import {Controller, useForm} from "react-hook-form";
import {SorteoTypeSelectV2} from "../../../common/components/selects/SorteoTypeSelect";
import AnforaSelect from "../../../common/components/selects/AnforaSelect";
import ZoneSelect from "../../../common/components/selects/ZoneSelect";
import SwitchToggle from "../../../../components/layout/SwitchToggle";
import RequiredLabel from "../../../../components/util/RequiredLabel";
import DateTimeV2 from "../../../../components/layout/DateTimeV2";
import {Col, Row} from "../../../../components/flex/FlexLayout";
import {FaInfoCircle} from "react-icons/fa";
import {getStartDateTimeFromRaffle} from "../../../../components/util/CasinoDates";
import CouponTypeSelectV2 from "../../../common/components/selects/CouponTypeSelectV2";
import {Alert, OverlayTrigger} from "react-bootstrap";
import Tooltip from "react-bootstrap/Tooltip";
import {MachineTypeSelectV2} from "../../../common/components/selects/MachineTypeSelect";

const SorteoForm = ({value, onSubmit, setAction, tipoDefault = ''}) => {

  const {salaId} = useContext(SiteContext)

  const [raffleStartDateTime, setRaffleStartDateTime] = useState('')

  //TODO: replace with react-hook-form
  //custom validations
  const [betValidation, setBetValidation] = useState(false)
  const [durationValidation, setDurationValidation] = useState(false)
  const [coinValidation, setCoinValidation] = useState(false)
  const [amountValidation, setAmountValidation] = useState(false)
  const [winnerQuantity, setWinnerQuantity] = useState(false)

  const [disabledEdicion, setDisabledEdicion] = useState({
    amount: true,
    couponTypeId: true,
    anforaId: true,
    zoneId: true,
    avgBet: true,
    sessionCi: true,
    sessionDuration: true,
    showSince: true,
    showUntil: true,
    winnerQuantity: true
  })

  const defaultParams = {
    salaId: salaId,
    enabled: true,
    anforaId: 0,
    kind: tipoDefault.length > 0 ? tipoDefault : 'MANUAL',
    avgBet: 0,
    sessionDuration: 0,
    sessionCi: 0,
    dailyCi: 0,
    relativePeriod: 'HOUR',
    relativePeriodAmount: '8'
  }

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    watch,
    control,
    formState: {errors}
  } = useForm({defaultValues: defaultParams});

  const watchSorteoKind = watch("kind");

  useEffect(() => {
    if (value && Object.keys(value).length > 0) {
      reset(value)
      calculatePeriod()
    }
  }, [value])

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit))
  }, [])

  const handleSwitchEditar = (campo) => {
    let aux = disabledEdicion
    aux[campo] = !aux[campo]
    setDisabledEdicion({...aux})
  }

  const handleFieldBlur = (valueName, setValidation) => {
    const property = getValues()[valueName]
    setValidation(property.length === 0)
  }

  const calculatePeriod = () => {
    const {fechaHora, relativePeriodAmount, relativePeriod} = getValues()
    const ini = getStartDateTimeFromRaffle(fechaHora, relativePeriod, relativePeriodAmount)

    setRaffleStartDateTime(ini)
  }

  return (
    <Form>
      {tipoDefault.length > 0 &&
        <FormGroup>
          <label>Seleccione los campos que desea editar del grupo</label>
        </FormGroup>}

      {tipoDefault.length === 0 &&
        <FormGroup>
          <label>Tipo de sorteo</label>
          <Controller name="kind" control={control} render={({field}) => <SorteoTypeSelectV2 {...field} />}/>
        </FormGroup>}

      {tipoDefault.length === 0 &&
        <FormGroup>
          <label>Fecha y hora del sorteo</label>
          <Controller name="fechaHora" control={control} render={({field}) => <DateTimeV2 {...field} />}/>
        </FormGroup>}

      <FormGroup>
        <div style={{display: 'flex'}}>
          <label style={{marginRight: '15px'}}>Monto</label>
          {tipoDefault.length > 0 &&
            <SwitchToggle label={"Editar"} defaultChecked={!disabledEdicion['amount']} onClick={() => {
              handleSwitchEditar('amount')
            }}/>}
        </div>
        <Input
          disabled={tipoDefault.length > 0 && disabledEdicion['amount']}
          type="number"
          className="no-spinner"
          {...register("amount", {
            onBlur: (e) => handleFieldBlur('amount', setAmountValidation)
          })}
          style={{borderColor: amountValidation && 'red'}}/>
        <RequiredLabel show={amountValidation} text={"Ingrese un monto"}/>
      </FormGroup>

      {
        watchSorteoKind === "HOT_SEAT"
          ? <React.Fragment>
            <FormGroup>
              <div style={{display: 'flex'}}>
                <label style={{marginRight: '15px'}}>Apuesta promedio</label>
                {tipoDefault.length > 0 &&
                  <SwitchToggle label={"Editar"} defaultChecked={!disabledEdicion['avgBet']} onClick={() => {
                    handleSwitchEditar('avgBet')
                  }}/>}
              </div>
              <Input disabled={tipoDefault.length > 0 && disabledEdicion['avgBet']} type="number"
                     className="no-spinner"
                     {...register("avgBet", {
                       onBlur: (e) => handleFieldBlur('avgBet', setBetValidation)
                     })}
                     style={{borderColor: betValidation && 'red'}}/>
              <RequiredLabel show={betValidation} text={"Ingrese una apuesta"}/>
            </FormGroup>
            <FormGroup>
              <div style={{display: 'flex'}}>
                <label style={{marginRight: '15px'}}>Tiempo de permanencia(min)</label>
                {tipoDefault.length > 0 &&
                  <SwitchToggle label={"Editar"} defaultChecked={!disabledEdicion['sessionDuration']} onClick={() => {
                    handleSwitchEditar('sessionDuration')
                  }}/>}
              </div>
              <Input disabled={tipoDefault.length > 0 && disabledEdicion['sessionDuration']} type="number"
                     className="no-spinner"
                     {...register("sessionDuration", {
                       onBlur: (e) => handleFieldBlur('sessionDuration', setDurationValidation)
                     })}
                     style={{borderColor: durationValidation && 'red'}}/>
              <RequiredLabel show={durationValidation} text={"Ingrese una duración"}/>
            </FormGroup>
            <FormGroup>
              <div style={{display: 'flex'}}>
                <label style={{marginRight: '15px'}}>Total apostado de la sesion</label>
                {tipoDefault.length > 0 &&
                  <SwitchToggle label={"Editar"} defaultChecked={!disabledEdicion['sessionCi']} onClick={() => {
                    handleSwitchEditar('sessionCi')
                  }}/>}
              </div>
              <Input disabled={tipoDefault.length > 0 && disabledEdicion['sessionCi']}
                     type="number"
                     className="no-spinner"
                     {...register("sessionCi", {
                       onBlur: (e) => handleFieldBlur('sessionCi', setCoinValidation)
                     })}
                     style={{borderColor: coinValidation && 'red'}}/>
              <RequiredLabel show={coinValidation} text={"Ingrese un monto"}/>
            </FormGroup>
            <FormGroup>
              <div style={{display: 'flex'}}>
                <label style={{marginRight: '15px'}}>Total apostado del día</label>
                {tipoDefault.length > 0 &&
                  <SwitchToggle label={"Editar"} defaultChecked={!disabledEdicion['dailyCi']} onClick={() => {
                    handleSwitchEditar('dailyCi')
                  }}/>}
              </div>
              <Input disabled={tipoDefault.length > 0 && disabledEdicion['dailyCi']}
                     type="number"
                     className="no-spinner"
                     {...register("dailyCi", {required: "Ingrese un monto"})}/>
              {errors.dailyCi && <p style={{color: 'red'}}>{errors.dailyCi?.message}</p>}
            </FormGroup>
            <FormGroup>
              <label>Aplica para</label>
              <Controller name="machineTypeId" control={control} render={({field}) => <MachineTypeSelectV2 {...field} />}/>
            </FormGroup>
            {tipoDefault.length === 0 &&
              <FormGroup>
                <label>Solo clientes registrados</label>
                <SwitchToggle label={"Registrados"} {...register("logged")} id={"logged"}/>
              </FormGroup>}
          </React.Fragment>
          :
          <div>
            <FormGroup>
              <div style={{display: 'flex'}}>
                <label style={{marginRight: '15px'}}>Promoción</label>
                {tipoDefault.length > 0 &&
                  <SwitchToggle label={"Editar"} defaultChecked={!disabledEdicion['couponTypeId']}
                                onClick={() => {
                                  handleSwitchEditar('couponTypeId')
                                }}/>}
              </div>
              <Controller
                name="couponTypeId"
                control={control}
                render={({field}) => <CouponTypeSelectV2 {...field} />}
              />
            </FormGroup>
          </div>
      }
      {
        watchSorteoKind === "TORNEO" &&
        <div>
          <FormGroup>
            <div style={{display: 'flex'}}>
              <label style={{marginRight: '15px'}}>N° de ganadores</label>
              {tipoDefault.length > 0 &&
                <SwitchToggle label={"Editar"} defaultChecked={!disabledEdicion['winnerQuantity']} onClick={() => {
                  handleSwitchEditar('winnerQuantity')
                }}/>}
            </div>
            <Input disabled={tipoDefault.length > 0 && disabledEdicion['winnerQuantity']}
                   type="number"
                   className="no-spinner"
                   {...register("winnerQuantity", {
                     onBlur: (e) => handleFieldBlur('winnerQuantity', setWinnerQuantity)
                   })}/>
            <RequiredLabel show={winnerQuantity} text={"Ingrese n° de ganadores"}/>
          </FormGroup>
          <FormGroup>
            <div style={{display: 'flex'}}>
              <OverlayTrigger
                placement={'top'}
                overlay={
                  <Tooltip>{'Hora en la que muestra la tabla de participantes'}</Tooltip>
                }>
              <label style={{marginRight: '15px'}}>Inicio del torneo</label>
              </OverlayTrigger>
              {tipoDefault.length > 0 &&
                <SwitchToggle label={"Editar"} defaultChecked={!disabledEdicion['showSince']} onClick={() => {
                  handleSwitchEditar('showSince')
                }}/>}
            </div>
            <Controller name="showSince" control={control} render={({field}) => <DateTimeV2 {...field} />}/>
          </FormGroup>
          <FormGroup>
            <div style={{display: 'flex'}}>
              <OverlayTrigger
                placement={'top'}
                overlay={
                  <Tooltip>{'Hora en la que oculta la tabla de participantes'}</Tooltip>
                }>
              <label style={{marginRight: '15px'}}>Fin del torneo</label>
              </OverlayTrigger>

              {tipoDefault.length > 0 &&
                <SwitchToggle label={"Editar"} defaultChecked={!disabledEdicion['showUntil']} onClick={() => {
                  handleSwitchEditar('showUntil')
                }}/>}
            </div>
            <Controller name="showUntil" control={control} render={({field}) => <DateTimeV2 {...field} />}/>
          </FormGroup>
        </div>
      }
      {
        watchSorteoKind === "VIRTUAL" &&
        <>
          {/*<FormGroup>*/}
          {/*  <div style={{display: 'flex'}}>*/}
          {/*    <label style={{marginRight: '15px'}}>Ánfora</label>*/}
          {/*    {tipoDefault.length > 0 &&*/}
          {/*      <SwitchToggle label={"Editar"} defaultChecked={!disabledEdicion['anforaId']} onClick={() => {*/}
          {/*        handleSwitchEditar('anforaId')*/}
          {/*      }}/>}*/}
          {/*  </div>*/}
          {/*  <AnforaSelect {...register("anforaId")}/>*/}
          {/*</FormGroup>*/}

          {tipoDefault.length === 0 &&
            <>
              <FormGroup>
                <label>
                  Rango de participacion de cupones
                  <OverlayTrigger
                    placement={'top'}
                    overlay={
                      <Tooltip>{'Indique el rango de tiempo anterior al sorteo en el que participaran los cupones'}</Tooltip>
                    }
                  >
                    <span> <FaInfoCircle/></span>
                  </OverlayTrigger>
                </label>
                <Row>
                  <Col>
                    <Input type={'text'} {...register("relativePeriodAmount", {
                      onBlur: (e) => calculatePeriod()
                    })} />
                  </Col>
                  <Col>
                    <SelectV2 {...register("relativePeriod", {
                      onBlur: (e) => calculatePeriod()
                    })} options={
                      [
                        {id: "HOUR", name: "HORAS ANTES"},
                        {id: "DAY", name: "DIAS ANTES"},
                        {id: "WEEK", name: "SEMANAS ANTES"}
                      ]
                    }/>
                  </Col>
                </Row>
                <Row className={'mt-2'}>
                  <Col>
                    <Alert variant={'info'}>
                      <span><FaInfoCircle/>{' Participan cupones desde ' + raffleStartDateTime + ' hasta la hora del sorteo'}</span>
                    </Alert>
                  </Col>
                </Row>
              </FormGroup>
            </>
          }
        </>
      }
      {
        (watchSorteoKind === "VIRTUAL" || watchSorteoKind === "HOT_SEAT") &&
        <FormGroup>
          <div style={{display: 'flex'}}>
            <label style={{marginRight: '15px'}}>Zona</label>
            {tipoDefault.length > 0 &&
              <SwitchToggle label={"Editar"} defaultChecked={!disabledEdicion['zoneId']} onClick={() => {
                handleSwitchEditar('zoneId')
              }}/>}
          </div>
          <ZoneSelect disabled={tipoDefault.length > 0 && disabledEdicion['zoneId']}
                      type="text" {...register("zoneId")}/>
        </FormGroup>
      }
    </Form>
  )
}

export default SorteoForm
