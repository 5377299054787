import React, {useEffect, useState} from "react";
import {Apis} from "../../../../util/Apis";
import useToggle from "../../../../hooks/useToggle";
import LayoutCard from "../../../tgm/pages/layout/LayoutCard";
import LayoutLegend from "./LayoutLegend";
import useGet from "../../../../hooks/useGet";

const OnlineLayout = () => {

  const [newData, setNewData] = useState(null);
  const {isOpen: legendVisible, setIsOpen: setLegendVisible} = useToggle({
    defaultValue: true
  });

  const legendData =[
    {label: 'Ap. prom: S/0 a S/ 0.4', color: '#2398ff' },
    {label: 'Ap. prom: S/ 0.4 a S/ 1', color: '#facc15'},
    {label: 'Ap. prom: S/ 1 a S/ 5', color: '#ef4444'},
    {label: 'Ap. prom: S/ 5 a más', color: '#b91c1c'},
  ]

  const {data: layoutData, loading, fetchData} = useGet({
    url: `${Apis.PC}/session/online-layout`,
    initialValue: []
  })

  useEffect(() => {
    if (layoutData) {
      const {frameDimensions, data, positions, layoutKey} = layoutData;
      const updatedPositions = positions?.map((item) => {
        const correspondingMachine = data.find((machine) => machine.positionId === item.id);
        const infoMachine = correspondingMachine && correspondingMachine.positionId === item.id ? correspondingMachine : null;

        return {
          ...item,
          data: infoMachine,
        };
      });

      setNewData({
        frameDimensions,
        positions: updatedPositions,
        layoutKey,
      });
    }
  }, [layoutData]);


  if (loading) return 'Loading...'

  return (
    <LayoutCard
      data={newData}
      loading={loading}
      setHeight='calc(100vh - 19.5rem)'
      type='online'
      fetchData={fetchData}
      setLegendVisible={setLegendVisible}
      caption={
        <LayoutLegend
          legendVisible={legendVisible}
          legendData={legendData}
        />
      }
    />
  )
}

export default OnlineLayout;
