import React, {useEffect} from 'react';
import FormGroup from "../../../../../components/forms/FormGroup";
import Input from "../../../../../components/forms/FormEl";
import {useForm} from "react-hook-form";
import Form from "react-bootstrap/Form";

const ProductVariantForm = ({value, onSubmit, setAction}) => {

  const {register, handleSubmit, reset, control} = useForm();

  useEffect(() => {
    if (value && Object.keys(value).length > 0) {
      reset(value)
    }
  }, [value])

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit))
  }, [])

  return (
    <Form>

      <FormGroup>
        <label>Nombre</label>
        <Input type="text" {...register("name")}/>
      </FormGroup>

    </Form>
  );
};

export default ProductVariantForm;
