import React, {useContext, useEffect} from "react";
import PageSection from "../../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import Button, {ButtonBox} from "../../../../../components/forms/Button";
import Loader1 from "../../../../common/components/Loader1";
import Card from "../../../../../components/forms/Card";
import PageContainer from "../../../../../components/layout/PageContainer";
import {CouponGridContainer} from "../../coupon-type/CouponTypeList";
import {Apis} from "../../../../../util/Apis";
import useFetch2 from "../../../../../hooks/useFetch2";
import {useHistory} from "react-router";
import {SiteContext} from "../../../../common/context/SiteContext";
import useEditModal from "../../../../../modals/useEditModal";
import CustomerGroupNotifNew from "./CustomerGroupNotifNew";
import GenericModal2 from "../../../../../modals/GenericModal2";


const CustomerGroupNotifList=( )=>{

  const history = useHistory();
  const {salaId} = useContext(SiteContext)
  const {loading, data,fetchData} = useFetch2(Apis.KCUSTOMERGROUP + '/group?salaId=' + salaId)

  const {wrapper: wrapper1, refresh: refresh1, addAction, editAction} = useEditModal({
    addComponent: <CustomerGroupNotifNew/>
  })

  useEffect(() => {
    fetchData()
  }, [refresh1])


  return (
    <PageContainer>
      <PageSection>
          <Title text="Grupos de clientes"/>
        <ButtonBox>
          <Button variant={'primary'} title={'Nuevo'} onClick={() => addAction()} />
        </ButtonBox>
      </PageSection>

      <PageSection>
        {
          loading ?
            <Loader1/>
            :
            data && data.map(item =>
              <div key={item.first}>
                <CouponGridContainer>
                    <Card key={item.id} padding style={{cursor: 'pointer'}} onClick={()=>history.push("/crm/customer-group-detail/"+item.id)}
                    >
                      {item.name}
                    </Card>
                </CouponGridContainer>

              </div>
            )
        }

      </PageSection>

      <GenericModal2 {...wrapper1}/>

    </PageContainer>
  )
}

export default CustomerGroupNotifList;
