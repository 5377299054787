import React, {useContext, useState} from 'react';
import {Title} from "@wargostec/react-components";
import {useForm} from "react-hook-form";
import Moment from "react-moment";
import {FaClock, FaTicketAlt, FaUser} from "react-icons/fa";
import {GiVendingMachine} from "react-icons/gi";
import {Apis} from "../../../../util/Apis";
import PageContainerFull from "../../../../components/layout/PageContainerFull";
import PageSection from "../../../../components/layout/PageSection";
import {SiteContext} from "../../../common/context/SiteContext";
import {FlexLayout} from "../../../../components/flex/Flex";
import Input from "../../../../components/forms/FormEl";
import RequiredLabel from "../../../../components/util/RequiredLabel";
import Button from "../../../../components/forms/Button";
import FormGroup from "../../../../components/forms/FormGroup";
import Card from "../../../../components/forms/Card";
import useToggle from '../../../../hooks/useToggle';
import useGet from "../../../../hooks/useGet";

const CouponSearch = () => {
  const { salaId } = useContext(SiteContext)
  const defaultParams = { salaId: salaId }
  const { isOpen: exists, setIsOpen: setExists } = useToggle({})
  const { isOpen: couponValidation, setIsOpen: setCouponValidation } = useToggle({})
  const [coupon, setCoupon] = useState('')

  const {
    register,
    setValue,
    getValues,
  } = useForm({ defaultValues: defaultParams });

  const { mutate } = useGet({
    url: `${Apis.PC}/coupon/${coupon}/validate-dto?salaId=${salaId}`,
    onSuccess: (data) => {
      setCoupon(data)
      setValue('couponId', data.couponCode)
      setExists(false)
    },
    onError: () => setExists(true)
  })


  const handleFieldBlur = (valueName, setValidation) => {
    const propiedad = getValues()[valueName]
    setValidation(propiedad.length === 0)
  }

  return (
    <PageContainerFull>
      <PageSection>
        <Title text="Consulta de cupones" type={'page'} />
        <FlexLayout gap={'16px'}>
          <Card padding style={{ flex: 1, alignSelf: 'stretch' }}>
            <FlexLayout column>
              <FormGroup>
                <label>Ingrese código del cupón:</label>
                <Input
                  type="number"
                  className="no-spinner"
                  {...register("couponValidate", {
                    onBlur: () => handleFieldBlur('couponValidate', setCouponValidation)
                  })}
                  style={{ borderColor: couponValidation && 'red' }}
                  onChange={event => setCoupon(event.target.value)} />
                <RequiredLabel show={couponValidation} text={"Ingrese un cupón"} /><br />
              </FormGroup>
              <FlexLayout>
                <Button variant='dark' style={{ flex: 1 }} title={"Validar"}
                  onClick={() => mutate()} />
              </FlexLayout>
            </FlexLayout>
          </Card>

          {!exists ?
            <Card padding style={{ flex: 1 }}>
              <FlexLayout column alignItems='center'>
                <FaTicketAlt fontSize={'48'} />
                <strong>Cupón: {coupon?.couponTypeName}</strong>
                <FlexLayout column alignItems='center'>
                  <FlexLayout alignItems='center' gap='4px'>
                    <GiVendingMachine />
                    <span>Máquina: <strong>{coupon?.machineName}</strong></span>
                  </FlexLayout>
                  <FlexLayout alignItems='center' gap='4px'>
                    <FaUser />
                    <span>Pertenece a: {coupon?.firstName !== undefined ? (coupon?.firstName + ' ' + coupon?.lastName1) : ""} </span>
                  </FlexLayout>
                  <FlexLayout alignItems='center' gap='4px'>
                    <FaClock />
                    Generado: <strong>
                      {coupon && coupon.createdAt && (
                        <Moment format={'YYYY-MM-DD HH:mm'}>{coupon.createdAt}</Moment>
                      )}</strong>
                  </FlexLayout>
                </FlexLayout>
              </FlexLayout>
            </Card>
            :
            <Card padding style={{ flex: 1 }}>
              <strong><RequiredLabel show={exists} text={"El cupón no existe"} /></strong>
            </Card>
          }
        </FlexLayout>
      </PageSection>
    </PageContainerFull>
  );
};

export default CouponSearch;
