import React, {useEffect} from 'react';
import axios from "axios";
import {Apis} from "../../../../../util/Apis";
import {errorToast} from "../../../../../components/layout/ToastStyles";
import FundTypeForm from "./FundTypeForm";

const FundTypeNew = ({setAction, setTitle}) => {

  const save = async (params) => {
    await axios.post(Apis.CAJA + '/fund-type', params)
      .then(res => {
      }).catch(err => {
        errorToast()
      })
  }

  useEffect(() => {
    setTitle("Nuevo")
  }, [])

  return (
    <div>
      <FundTypeForm value={{}} onSubmit={save} setAction={setAction}/>
    </div>
  );
};

export default FundTypeNew;
