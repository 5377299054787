import React, { useContext, useState } from 'react'
import { Title } from "@wargostec/react-components";
import moment from "moment/moment"
import { useForm } from "react-hook-form";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
} from 'chart.js';
import { FaDownload } from "react-icons/fa";
import Chart from "react-apexcharts";
import { DateTime } from "luxon";
import Button, { ButtonBox } from "../../../../../components/forms/Button";
import PageContainerFull from '../../../../../components/layout/PageContainerFull';
import PageSection from '../../../../../components/layout/PageSection'
import FormGroup from "../../../../../components/forms/FormGroup";
import { SiteContext } from '../../../../common/context/SiteContext'
import { Apis } from "../../../../../util/Apis";
import { DatePickerCustom } from "../../../../../components/forms/FormEl";
import Slidebar from "../../../../common/layout/Slidebar";
import SalaIdInput from "../../../../common/components/SalaIdInput";
import Card, { CardBody, CardHeader } from "../../../../../components/forms/Card";
import { getCasinoDay } from "../../../../../components/util/CasinoDates";
import AsyncBlock from "../../../../../components/forms/AsyncBlock";
import { serializeDownload } from '../../../../../util';
import useGet from '../../../../../hooks/useGet';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'bottom',
    },
    title: {
      display: true,
      text: 'Clientes logueados por hora',
    },
  },
  scales: {
    x: {
      display: true,
      ticks: {
        maxTicksLimit: 8
      }
    }
  }
};

const OcupationPerHour = () => {

  let ini = DateTime.now()
  const casinoDay = getCasinoDay()

  const { salaId } = useContext(SiteContext)
  const [slideOpen, setSlideOpen] = useState(false)

  const fechasDefault = { ini: casinoDay, fin: casinoDay }
  const [fechas, setFechas] = useState(fechasDefault)

  let defaultParams = { salaId: salaId, ini: fechas.ini, fin: fechas.fin }

  const [params, setParams] = useState(defaultParams)
  const [chartSeries, setChartSeries] = useState([]);
  const { register, handleSubmit } = useForm({ defaultValues: defaultParams });

  const { loading, data, fetchData } = useGet({
    url: Apis.PC + '/machine-played-history',
    params,
    initialValue: [],
    onSuccess: getChartSeries
  })

  const { loading: loading2, data: data2, fetchData: fetchData2 } = useGet({
    url: Apis.BLACK + '/customer-ingress/report',
    params,
    initialValue: []
  })

  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 2,
        dataLabels: {
          total: {
            enabled: true,
            offsetY: 0,
            style: {
              fontSize: '10px',
            }
          }
        }
      },
    },
    dataLabels: {
      enabled: false
    },
    title: {
      text: ' '
    },
    xaxis: {
      categories: data.map(item => moment(item.fechaHora).format('HH:mm')),
    },
    yaxis: {
      title: {
        text: ' '
      },
    },
    tooltip: {
      intersect: false,
      shared: true,
      onDatasetHover: {
        highlightDataSeries: true,
      },
      x: {
        show: true,
        formatter: function (val) {
          return val + ' hrs';
        }
      },
      y: {
        formatter: function (val) {
          return val + ' clientes';
        }
      }
    },
    fill: {
      opacity: 1
    },
    legend: {
      position: 'bottom',
      horizontalAlign: 'center'
    }
  });

  const download = () => {
    const link = Apis.PC + `/machine-played-history/report.xlsx?${serializeDownload(params)}`;
    window.location.href = link;
  }

  const handleChangeDates = fechas => {
    let newParams = { ...params, ...fechas }
    setFechas(fechas)
    setParams(newParams)
    if (newParams && newParams.ini && newParams.fin) {
      fetchData(newParams)
      fetchData2(newParams)
    }
  }

  function getChartSeries(data) {
    setChartOptions((prevOptions) => ({
      ...prevOptions,
      xaxis: {
        categories: data.map((item) => moment(item.fechaHora).format('HH:mm')),
      },
    }));

    const updatedChartSeries = [
      {
        name: 'Registrados',
        data: data.map((entry) => entry.logged),
      },
      {
        name: 'Rechazados',
        data: data.map((entry) => entry.rejected),
      },
      {
        name: 'Anónimos',
        data: data.map((entry) => entry.played - (entry.logged + entry.rejected)),
      },
    ];

    setChartSeries(updatedChartSeries);
  }

  const barIngress = {
    options: {
      chart: {
        type: 'bar',
        height: 400
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 4
        },
      },
      colors: ["#feb019"],
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      legend: {
        show: false,
        showForSingleSeries: true
      },
      tooltip: {
        intersect: false,
        shared: true,
        onDatasetHover: {
          highlightDataSeries: true,
        },
      },
      xaxis: {
        categories: data2 && data2.map(item => ini.set({ hour: item.hour }).set({ minutes: 0 }).set({ seconds: 0 }).toLocal().toFormat("HH:mm")),
      }
    },
    series: [
      {
        name: "Ingresos",
        data: data2 && data2.map(item => item.count)
      }
    ]
  };

  return (
    <PageContainerFull>
      <Slidebar visible={slideOpen} onHide={() => setSlideOpen(false)}>
        <div style={{ padding: '16px' }}>
          <SalaIdInput {...register("salaId")} />
          <FormGroup>
            <label>Desde</label>
            <DatePickerCustom {...register("ini")} />
          </FormGroup>

          <FormGroup>
            <label>Hasta</label>
            <DatePickerCustom {...register("fin")} />
          </FormGroup>

          <Button variant={'primary'} title='Buscar' onClick={handleSubmit(handleChangeDates)} />
        </div>
      </Slidebar>

      <PageSection>
        <Title text="Ocupación por hora" type={'page'} />
        <ButtonBox>
          <Button variant={'primary'} onClick={download}>
            <FaDownload /> Descargar
          </Button>
          <Button variant={'primary'} title={'Filtrar'} onClick={() => setSlideOpen(!slideOpen)} />
        </ButtonBox>

        <Card padding>
          <AsyncBlock loading={loading}>
            <CardHeader>
              <strong>Clientes por hora</strong>
            </CardHeader>
            <CardBody>
              <Chart
                options={chartOptions}
                series={chartSeries}
                type="bar"
                height={350}
              />
            </CardBody>
          </AsyncBlock>
        </Card>

        <Card padding>
          <AsyncBlock loading={loading2}>
            <CardHeader>
              <strong>Ingresos de clientes por hora</strong>
            </CardHeader>
            <CardBody>
              <Chart
                options={barIngress.options}
                series={barIngress.series}
                type="area"
                width="100%"
                height={400}
              />
            </CardBody>
          </AsyncBlock>
        </Card>
      </PageSection>

    </PageContainerFull>
  )
}

export default OcupationPerHour
