import React, {useContext} from "react";
import {SiteContext} from "../../../common/context/SiteContext";
import {Apis} from "../../../../util/Apis";
import useEditModal from "../../../../modals/useEditModal";
import useDeleteModal from "../../../../modals/useDeleteModal";
import axios from "axios";
import PageContainerFull from "../../../../components/layout/PageContainerFull";
import Button, {ButtonBox} from "../../../../components/forms/Button";
import PageSection from "../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import {FaPlus} from "react-icons/fa";
import Table from "react-bootstrap/Table";
import Moment from "react-moment";
import GenericModal2 from "../../../../modals/GenericModal2";
import DeleteGenericModal from "../../../../modals/DeleteGenericModal";
import RewardsEdit from "./RewardsEdit";
import {RewardsNew} from "./RewardsNew";
import useFetch2 from "../../../../hooks/useFetch2";
import Card from "../../../../components/forms/Card";

const RewardsList = () => {
  const {salaId} = useContext(SiteContext)

  const {data, fetchData} = useFetch2(Apis.PC + '/rewards?salaId=' + salaId, []);

  //hooks para crear,editar y eliminar
  const {wrapper: wrapper1, addAction, editAction} = useEditModal({
    editComponent: <RewardsEdit/>,
    addComponent: <RewardsNew/>,
    onRefresh: fetchData
  })

  const deleteElement = id => axios.delete(Apis.PC + "/rewards/" + id)

  const {
    wrapper: wrapper2,
    openDeleteModal
  } = useDeleteModal({
    text: '¿Desea eliminar esta recompensa?',
    action: deleteElement,
    onRefresh: fetchData
  })

  return (
    <PageContainerFull>
      <PageSection>
        <Title text="Recompensas" type={'page'}/>
        <ButtonBox>
          <Button variant={'primary'} onClick={() => addAction()}>
            <FaPlus/> Nuevo
          </Button>
        </ButtonBox>
      </PageSection>

      <PageSection>
        <Card>
          <Table striped hover>
            <thead>
            <tr>
              <th>Monto Mínimo</th>
              <th>Monto Máximo</th>
              <th>Recompensa</th>
              <th>Inicio Vigencia</th>
              <th>Fin Vigencia</th>
              <th>Estado</th>
              <th>Acciones</th>
            </tr>
            </thead>
            <tbody>
            {data && data.map(item =>
              <tr key={item.id}>
                <td>{item.pointMin}</td>
                <td>{item.pointMax}</td>
                <td>{item.description}</td>
                <td><Moment format={'DD/MM/YYYY hh:mm A'}>{item.inicioVigencia}</Moment></td>
                <td><Moment format={'DD/MM/YYYY hh:mm A'}>{item.finVigencia}</Moment></td>
                <td>{item.active ? "Vigente" : "Expirado"}</td>

                <td>
                  <ButtonBox>
                    <Button variant={'light'} size='xs' title={"Editar"} onClick={() => editAction(item.id)}/>
                    <Button variant={'danger'} size='xs' onClick={() => openDeleteModal(item.id)}
                            title={'Eliminar'}/>
                  </ButtonBox>
                </td>
              </tr>
            )}
            </tbody>
          </Table>
        </Card>
      </PageSection>

      <GenericModal2 {...wrapper1}/>
      <DeleteGenericModal {...wrapper2}/>

    </PageContainerFull>
  )
}

export default RewardsList;
