import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {SiteContext} from "../../../common/context/SiteContext";
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import PageContainer from "../../../../components/layout/PageContainer";
import {Title} from "@wargostec/react-components";
import PageSection from "../../../../components/layout/PageSection";
import Card from "../../../../components/forms/Card";
import Button from "../../../../components/forms/Button";
import SalaIdInput from "../../../common/components/SalaIdInput";
import FormGroup from "../../../../components/forms/FormGroup";
import {DatePickerCustom} from "../../../../components/forms/FormEl";
import Slidebar from "../../../common/layout/Slidebar";
import {useForm} from "react-hook-form";
import {getCasinoDay} from "../../../../components/util/CasinoDates";
import DateFilter from "../../../../components/datefilter/DateFilter";
import AsyncBlock from '../../../../components/forms/AsyncBlock';
import ReactTable from '../../../../components/util/ReactTable';

const OrderReport = () => {

  const {salaId} = useContext(SiteContext)
  let defaultParams = {salaId: salaId}
  const [slideOpen, setSlideOpen] = useState(false)

  const initialDate = getCasinoDay()

  const fechasDefault = {ini: initialDate, fin: initialDate}
  const [fechas, setFechas] = useState(fechasDefault)

  const [params, setParams] = useState(defaultParams)
  const {loading, data, fetchData, error} = useFetch2(Apis.FOOD + `/food-orders/report`, [], params);
  const {register, handleSubmit} = useForm({defaultValues: defaultParams});

  const getData = (params) => {
    setParams(params)
  }

  useEffect(() => {
    setParams({...params, ...fechas})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fechas])

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  const monto = useCallback(props => <>{
    props.row.original.totalPurchasePrice ? 'S/ ' + props.row.original.totalPurchasePrice: 'Sin costo el producto'
  }</>, []);

  const columns = useMemo(
    () => [
      {
        header: 'Producto',
        accessorKey: 'productName'
      },
      {
        header: 'Cantidad',
        accessorKey: 'quantity',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Costo total',
        accessorKey: 'totalPurchasePrice',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: monto
      }
    ], [])

  return (
    <PageContainer>
      <Slidebar visible={slideOpen} onHide={() => setSlideOpen(false)}>
        <div style={{padding: '16px'}}>
          <SalaIdInput {...register("salaId")} />
          <FormGroup>
            <label>Desde</label>
            <DatePickerCustom {...register("ini")} />
          </FormGroup>

          <FormGroup>
            <label>Hasta</label>
            <DatePickerCustom {...register("fin")} />
          </FormGroup>

          <Button variant={'primary'} title='Buscar' onClick={handleSubmit(getData)} />
        </div>
      </Slidebar>

      <PageSection>
          <Title text="Consolidado de órdenes por día" type={'page'}/>
        <Card padding style={{background: '#0A0A0A'}}>
          <DateFilter fechas={fechas} setFechas={setFechas} />
        </Card>

        <AsyncBlock loading={loading} error={error}>
          <ReactTable
            columns={columns}
            data={data}
            filterOnclick={() => setSlideOpen(!slideOpen)}
            filterRefresh={() => fetchData()}
            noData='No existen resultados para el filtro seleccionado'
            pageSize={15}
          />
        </AsyncBlock>
      </PageSection>

    </PageContainer>
  );
};

export default OrderReport;
