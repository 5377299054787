import React from "react";
import { useHistory } from "react-router";
import axios from "axios";
import Table from "react-bootstrap/Table";
import { Apis } from "../../../../util/Apis";
import FormGroup from "../../../../components/forms/FormGroup";
import Button from "../../../../components/forms/Button";
import useDeleteModal from "../../../../modals/useDeleteModal";
import DeleteGenericModal from "../../../../modals/DeleteGenericModal";
import EmptyState from "../../../common/components/EmptyState";
import { RiCouponLine } from "react-icons/ri";
import Card from "../../../../components/forms/Card";
import { FlexLayout } from "../../../../components/flex/Flex";
import useGet from "../../../../hooks/useGet";

const CouponTypeMultiplicaTable = ({ id, fetchData }) => {

  const history = useHistory();
  const { data: multiplicas } = useGet({
    url: Apis.PC + '/coupon-type-promo/' + id + '/coupon-type',
    initialValue: []
  })

  const getPromoDescription = (item) => {
    const days = ['Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab', 'Dom']

    const daysName = item.daysOfWeek.sort((a, b) => a - b).map(x => days[x - 1])
    const daysText = daysName.join(", ")

    const hoursName = item.listHourRange.map(x => x.ini + ' a ' + x.fin)
    const hoursNameText = hoursName.join(", ")

    return daysText + ' de ' + hoursNameText
  }

  const goToPromoDetail = (id) => {
    history.push('/crm/coupon-promo/edit/' + id)
  }

  const deleteMultiplica = id => axios.delete(Apis.PC + '/coupon-type-promo/' + id)

  const {
    wrapper: wrapper2,
    openDeleteModal
  } = useDeleteModal(
    {
      text: '¿Desea eliminar este multiplica?',
      action: deleteMultiplica,
      onRefresh: fetchData
    })

  return (
    <>
      {
        multiplicas.length > 0 ?
          <FormGroup style={{ maxHeight: "500px", overflowY: "auto" }}>
            <Table striped hover responsive>
              <thead>
              <tr>
                <th className="text-center">Multiplicador</th>
                <th>Horario</th>
                <th className="text-center">Máquinas participantes</th>
                <th className="text-center">Acciones</th>
              </tr>
              </thead>
              <tbody>
              {multiplicas.map((item, index) =>
                <tr key={index}>
                  <td className="text-center">{'x ' + item.multiplier}</td>
                  <td>{getPromoDescription(item)}</td>
                  <td className="text-center">{item.machineGroupId ===0 ? 'TODOS' : item.machineGroupId}</td>
                  <td className="text-center">
                    <FlexLayout justifyContent='center'>
                      <Button variant={'light'} size='xs' title={'Editar'} onClick={() => goToPromoDetail(item._id)}/>
                      <Button variant={'danger'} size='xs' title={'Eliminar'}
                              onClick={() => openDeleteModal(item._id)}/>
                    </FlexLayout>
                  </td>
                </tr>
              )}
              <DeleteGenericModal {...wrapper2}/>
              </tbody>
            </Table>
          </FormGroup>
          :
          <Card padding>
            <EmptyState
              title={'No hay multiplicadores'}
              subtitle={'Agregue una nuevo multiplicador haciendo click en Nuevo.'}
              icon={<RiCouponLine size={20} />}
            />
          </Card>
      }
    </>
  )
}

export default CouponTypeMultiplicaTable
