import React, { useContext } from "react";
import { Title } from "@wargostec/react-components";
import { useHistory } from "react-router";
import PageContainer from "../../../../components/layout/PageContainer";
import PageSection from "../../../../components/layout/PageSection";
import { PageBackLabel } from "../../../../components/layout/PageBackLabel";
import CouponTypeForm from "./CouponTypeForm";
import { Apis } from "../../../../util/Apis";
import { SiteContext } from "../../../common/context/SiteContext";
import { errorToast, successToast } from "../../../../components/layout/ToastStyles";
import { usePost } from "../../../../hooks/usePost";

const CouponTypeNew = () => {

  const history = useHistory()
  const { salaId } = useContext(SiteContext)

  const { mutate } = usePost({
    url: Apis.PC + '/coupon-type',
    onSuccess: () => {
      successToast()
      history.push('/crm/coupon-types')
    },
    onError: (err) => {
      errorToast(err.response.data.message ? err.response.data.message : "Ocurrió un error al guardar.", 2000)
    }
  })

  const create = async (couponType) => {
    couponType.salaId = salaId
    couponType.ini = couponType.iniDate + "T" + couponType.iniTime
    couponType.fin = couponType.finDate + "T" + couponType.finTime
    await mutate(couponType)
  }

  return (
    <PageContainer>
      <PageSection>
        <PageBackLabel text='Cupones' onClick={() => history.push('/crm/coupon-types')} />
        <Title text="Nuevo cupón" />
      </PageSection>
      <PageSection>
        <CouponTypeForm value={{ enabled: true }} onSubmit={create} mode={false} fetchData={{}} />
      </PageSection>
    </PageContainer>
  )
}

export default CouponTypeNew
