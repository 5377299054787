import React, {useEffect} from "react";
import FormGroup from "../../../../../components/forms/FormGroup";
import {Controller, useForm} from "react-hook-form";
import CustomerSearchInputV2 from "../../../../common/components/CustomerSearchInputV2";
import Input, {Select} from "../../../../../components/forms/FormEl";
import BingoEventSelect from "../../../../common/components/selects/BingoEventSelect";
import useFetch2 from "../../../../../hooks/useFetch2";
import {Apis} from "../../../../../util/Apis";
import Moment from "react-moment";
import SalaIdInput from "../../../../common/components/SalaIdInput";

const CardForm = ({onSubmit, setAction}) => {

  const {register, handleSubmit,control,watch} = useForm({defaultValues:{cant: 1}});

  const watchEvent = watch("eventId")
  const watchSorteoId = watch("sorteoId")
  const watchCant = watch("cant")

  const {fetchData: fetchEvent} = useFetch2(Apis.BINGO + `/event/${watchEvent}`, [], {},false);

  const {data: dataSorteo, fetchData: fetchSorteo} = useFetch2(Apis.BINGO + `/sorteo`, [], {eventId: watchEvent},false);

  const {data: dataSerie, fetchData: fetchSerie} = useFetch2(Apis.BINGO + `/serie`, [], {eventId: watchEvent},false);

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit))
  },[])

  useEffect(()=>{
    if(watchEvent > 0) {
      fetchSorteo()
      fetchSerie()
      fetchEvent()
    }
  },[watchEvent])

  const getCostoTotal = () => {
    let price = 1
    if (dataSorteo){
      const sorteo = dataSorteo.find(item => item.id == watchSorteoId)
      price = sorteo && sorteo.bingoCardPrice
    }
    let total = watchCant * price
    return total ? total : ''
  }

  return (
    <React.Fragment>
      <SalaIdInput {...register("salaId")} />
      <FormGroup>
        <label>Bingo</label>
        <Controller name="eventId" control={control} render={({field}) => <BingoEventSelect {...field} />}/>
      </FormGroup>
      {watchEvent && dataSerie && 
        <FormGroup>
          <label>Serie</label>
            <Select {...register("serieCardId")}>
              <option value='0'>Seleccionar Serie</option>
              {dataSerie.map((item) => (
                <option key={item.serie} value={item.serie}>
                  {item.serie + ') Disponible: ' + item.notAssigned}
                </option>
              ))}
            </Select>
        </FormGroup>
      }
      {watchEvent && dataSorteo &&
        <FormGroup>
          <label>Sorteo</label>
          <Select {...register("sorteoId")}>
            <option value='0'>Seleccionar Sorteo</option>
            {dataSorteo.map(item =>
              <option key={item.id} value={item.id}><Moment format={item.id + ') DD/MM/YYYY - HH:mm'}>{item.startDate}</Moment></option>
            )}
          </Select>
        </FormGroup>
      }
      <FormGroup>
        <label>Cliente</label>
        <Controller
          name="customerId"
          control={control}
          render={({field}) => <CustomerSearchInputV2 {...field} />}
        />
      </FormGroup>
      <FormGroup>
        <label>Numero de cartillas</label>
        <Input type='number' {...register("cant")} />
      </FormGroup>
      <FormGroup>
        <label>Precio total</label>
        <Input type='text' value={`Q ${getCostoTotal()}`} disabled/>
        {!watchSorteoId && <p style={{color: 'red'}}>Selecciona un sorteo para ver el precio total</p>}
      </FormGroup>
    </React.Fragment>
  )
}

export default CardForm
