import React, {useContext, useEffect, useState} from "react";
import Button from "../../../components/forms/Button";
import {logoutUser} from "../../login/actions/authentication.action";
import styled from "styled-components";
import AuthContext from "../../login/store/AuthContext";
import {SiteContext} from "../context/SiteContext";
import {useHistory, useLocation} from "react-router";
import {BiLogOut} from 'react-icons/bi';
import CustomAvatar from "../../../components/layout/CustomAvatar";
import {FaUserTie} from "react-icons/fa";
import {HiOutlineArrowsRightLeft} from "react-icons/hi2";
import {FaPeopleGroup} from "react-icons/fa6";

const TopButtonUser = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  position: relative;
`
const ContainerChangeSala = styled.div`
  display: none;
  transition: 0.5s all ease-in-out;
  @media screen and (max-width: 992px) {
    display: flex;
    transition: 0.5s all ease-in-out;
  }
`
const ContainerButton = styled.div`
  display: flex;
  transition: 0.5s all ease-in-out;
  @media screen and (max-width: 992px) {
    display: none;
    transition: 0.5s all ease-in-out;
  }
`

const CornerInfo = () => {

  const history = useHistory()
  const location = useLocation();
  const {dispatch} = useContext(AuthContext)
  const {info, moreInfo} = useContext(SiteContext)
  const [name, setName] = useState('')

  function changeSala() {
    history.push('/selector?redirect=' + location.pathname)
  }

  const baseAdminUrl = '/admin'

  useEffect(() => {
    if (moreInfo) {
      setName(moreInfo.user?.name.slice(0, 3))
    }
  }, [moreInfo])

  //con esto aseguramos que se seleccione la sala primero
  if (info?.sala == null) {
    history.push('/selector?redirect=' + location.pathname)
    return <div>Redirecting...</div>
  }

  return (
    <TopButtonUser>
      <ContainerButton>
        <Button variant='success'size='s' onClick={changeSala}>
          <HiOutlineArrowsRightLeft />
          <span>{info?.sala?.name}</span>
        </Button>
      </ContainerButton>
      <CustomAvatar name={name}>
        <ContainerChangeSala>
          <div style={{display:'flex',flexDirection:'row',gap:'8px', alignItems:'center',padding:'12px 0'}}>
            <FaPeopleGroup  size={20}/>
            <span>{moreInfo?.user?.company}</span>
          </div>
        </ContainerChangeSala>
        <hr style={{margin:0}}/>
        <ContainerChangeSala>
          <div onClick={() => history.push(`/selector`)} style={{display:'flex',flexDirection:'row',gap:'8px', alignItems:'center',padding:'12px 0'}}>
            <HiOutlineArrowsRightLeft size={20}/>
            <span>{info?.sala?.name}</span>
          </div>
        </ContainerChangeSala>
        <hr style={{margin:0}}/>
        <div onClick={() => history.push(`${baseAdminUrl}/profile`)} style={{display:'flex',flexDirection:'row',gap:'8px', alignItems:'center',padding:'12px 0'}}>
          <FaUserTie size={20}/>
          <span>Mi perfil</span>
        </div>
        <hr style={{margin:0}}/>
        <div onClick={() => logoutUser(dispatch)} style={{display:'flex',flexDirection:'row',gap:'8px', alignItems:'center',padding:'12px 0'}}>
          <BiLogOut size={20}/>
          <span>Salir</span>
        </div>
      </CustomAvatar>
    </TopButtonUser>
  )
}

export default CornerInfo
