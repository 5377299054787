import React, {useEffect, useMemo, useState} from "react";
import useFetch2 from "../../../../../hooks/useFetch2";
import {Apis} from "../../../../../util/Apis";
import AsyncBlock from "../../../../../components/forms/AsyncBlock";


const CardShow = ({ids,setTitle}) => {

  const {loading, data, error} = useFetch2(Apis.BINGO + `/bingo-card-number?bingoCardId=${ids.bingoCard}`, [], {});
  const {data: dataEvent} = useFetch2(Apis.BINGO + `/event/${ids.event}`, []);
  const [dataTable, setDataTable] = useState([])

  useEffect(() => {
    setTitle("Cartilla")
  },[])

  useMemo(()=>{
    const groupedData = data.reduce((grouped, item) => {
      const { yaxis } = item;
      if (!grouped[yaxis]) {
        grouped[yaxis] = { data: [], yaxis};
      }
      grouped[yaxis].data.push({xaxis: item.xaxis, number: item.number});
      return grouped;
    }, {});
    let grouped = Object.values(groupedData).sort((a,b)=>b.yaxis - a.yaxis)
    setDataTable(grouped)
  },[data])

  const generateRow = (data) => {
    const componentes = []
    for(let i=0;i < (dataEvent.type === 75 ? 5:9);i++){
      const coordenada = data.find((item)=>item.xaxis === i)
      componentes.push(<div style={{height: 50, width: 50, fontSize:30, border: "solid",textAlign: "center"}}>
        {coordenada ? coordenada.number: ''}
      </div>)
    }
    return componentes
  }

  return (
    <React.Fragment>
      <AsyncBlock loading={loading} error={error}>
        <div>
          {dataTable.map((item)=>{
            return (
              <div style={{flexDirection: "row",display: "flex"}}>
                { generateRow(item.data)
                }
              </div>)
          })}
        </div>
      </AsyncBlock>
    </React.Fragment>
  )
}

export default CardShow
