import React, {useContext} from 'react'
import {Select} from "../../../../components/forms/FormEl";
import {Apis} from "../../../../util/Apis";
import {SiteContext} from "../../context/SiteContext";
import useFetch2 from "../../../../hooks/useFetch2";

const LudopatiaListSelect = React.forwardRef((props, ref) => {

  const {salaId} = useContext(SiteContext)
  const {data, loading} = useFetch2(`${Apis.BLACK}/ban-list/only?salaId=` + salaId)

  return (
    <Select ref={ref} style={{width: "40%"}} {...props}>
      {data && data.filter(item => item.salaId !== 11).map(item =>
        <option key={item.id} value={item.id}>{item.name}</option>)}
    </Select>
  )
})

export default LudopatiaListSelect
