import React, {useEffect} from 'react';
import {useForm} from "react-hook-form";
import axios from "axios";
import {Apis} from "../../../../../util/Apis";
import FormGroup from "../../../../../components/forms/FormGroup";
import PrinterSelect from "../../../../common/components/selects/PrinterSelect";
import Form from "react-bootstrap/Form";
import {Input} from "@wargostec/react-components";

const ArqueoPrint = ({info, setAction, setTitle}) => {

  const printArqueo = () => {
    return axios.get(Apis.CAJA + `/arqueo/${info.id}/print`, {params: {printerId : getValues("printerId")}})
  }

  const {handleSubmit,register,reset,getValues} = useForm();

  useEffect(() => {
    setAction(() => handleSubmit(printArqueo))
  }, [])

  useEffect(() => {
    setTitle("Seleccione la impresora")
  }, [])

  useEffect(() => {
    if (info) {
      if (Object.keys(info).length !== 0) {
        reset(info)
      }
    }
  }, [info]);

  return (
    <Form>
      <FormGroup>
        <label>Caja</label>
        <Input type='text' {...register("fondoName")} disabled/>
      </FormGroup>
      <FormGroup>
        <label>Impresora</label>
        <PrinterSelect {...register("printerId")}/>
      </FormGroup>
    </Form>
  );
};

export default ArqueoPrint;
