import React, {useEffect} from "react";
import axios from "axios";
import {Apis} from "../../../../util/Apis";
import SorteoAsignarGanador from "./SorteoAsignarGanador";

const AsignarGanador = ({id, kind, sorteoWinnerId, setAction, setTitle}) => {
  const save = async (params) => {
    params.sorteoId = id
    if (params.responsableId === '') params.responsableId = null
    if (sorteoWinnerId) {
      await axios.post(Apis.SORTEO + `/sorteo-winner/${sorteoWinnerId}/assign-customer`, params)
    } else {
      await axios.post(Apis.SORTEO + '/sorteo-winner', params)
    }
  }

  useEffect(() => {
    setTitle("Asignar Ganador")
  }, [])

  return (
    <SorteoAsignarGanador onSubmit={save} setAction={setAction} kind={kind}/>
  )
}

export default AsignarGanador
