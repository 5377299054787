import React, { useState, useEffect } from "react";
import styled from "styled-components";

const ContainerDate = styled.div`
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const Days = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const Day = styled.div`
  user-select: none;
  width: 50px;
  height: 50px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${({ days }) => (days ? "3px solid #068eef" : "1px solid #aba7a7")};
  cursor: pointer;
`;

export const Dates = ({ title, value, onChange }) => {
  const [days, setDays] = useState({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  });

  const arrKeys = Object.keys(days);
  useEffect(() => {
    arrKeys.map((item, index) => {
      value.map((i) => {
        if (i === index + 1) {
          setDays((val) => ({ ...val, [item]: !days.item }));
        }
      });
    });
  }, [value]);

  const onCheck = (day, status, key) => {
    setDays((val) => ({ ...val, [key]: status }));
    let newArray;
    if (status) {
      newArray = [...value, day];
    } else {
      newArray = value.filter((item) => item !== day);
    }
    console.log(newArray);
    onChange(newArray);
  };

  return (
    <ContainerDate>
      <h4>{title ? title : ''}</h4>
      <Days>
        <Day
          days={days.monday}
          onClick={() => onCheck(1, !days.monday, "monday")}
        >
          LUN
        </Day>
        <Day
          days={days.tuesday}
          onClick={() => onCheck(2, !days.tuesday, "tuesday")}
        >
          MAR
        </Day>
        <Day
          days={days.wednesday}
          onClick={() => onCheck(3, !days.wednesday, "wednesday")}
        >
          MIER
        </Day>
        <Day
          days={days.thursday}
          onClick={() => onCheck(4, !days.thursday, "thursday")}
        >
          JUE
        </Day>
        <Day
          days={days.friday}
          onClick={() => onCheck(5, !days.friday, "friday")}
        >
          VIE
        </Day>
        <Day
          days={days.saturday}
          onClick={() => onCheck(6, !days.saturday, "saturday")}
        >
          SAB
        </Day>
        <Day
          days={days.sunday}
          onClick={() => onCheck(7, !days.sunday, "sunday")}
        >
          DOM
        </Day>
      </Days>
    </ContainerDate>
  );
};
