import React, {useEffect} from 'react'

import {Controller, useForm} from "react-hook-form";
import BottomModal from "../../../../../modals/BottomModal";
import FormGroup from "../../../../../components/forms/FormGroup";
import Input from "../../../../../components/forms/FormEl";
import Button from "../../../../../components/forms/Button";
import CustomerSearchInputV2 from "../../../../common/components/CustomerSearchInputV2";


const PointsAwardedForm = ({value, onSubmit, onHide}) => {

  const {register, control, handleSubmit, reset} = useForm();

  useEffect(() => {
    if (value) {
      //react hook form descartar las propiedades que no tienen un componente
      reset(value)
    }
  }, [value])

  return (
    <BottomModal
      onHide={onHide}
      body={
        <React.Fragment>
          <FormGroup>
            <label>Cliente</label>
            <Controller name="customerId" control={control} render={({field}) => <CustomerSearchInputV2 {...field} />}/>
          </FormGroup>
          <FormGroup>
            <label>Cantidad</label>
            <Input {...register("quantity")}/>
          </FormGroup>
          <FormGroup>
            <label>Comentario</label>
            <Input {...register("comment")}/>
          </FormGroup>
        </React.Fragment>
      }
      button={
        <Button title={"Guardar"} variant="primary" onClick={handleSubmit(onSubmit)}>
          Guardar
        </Button>
      }
    />
  )

}

export default PointsAwardedForm
