import React, {useEffect, useState} from 'react'
import Form from "react-bootstrap/Form";
import FormGroup from "../../../../../components/forms/FormGroup";
import Input from "../../../../../components/forms/FormEl";
import MeterPointSelect from "../../../../common/components/selects/MeterPointSelect";
import {Controller, useForm} from "react-hook-form";
import {Col, Row} from "../../../../../components/flex/FlexLayout";
import {MachineTypeSelectV2} from "../../../../common/components/selects/MachineTypeSelect";
import SwitchToggle from "../../../../../components/layout/SwitchToggle";

const RuleEditForm = ({value, onSubmit, setAction}) => {

  const defaultParams = {machineTypeId: 1, voidAmount : 0, minAmount: 0, maxAmount: 9999, netwinEnabled : false}

  const [advanced, setAdvanced] = useState(false)

  const {register, handleSubmit, reset, control} = useForm(
    {defaultValues: defaultParams}
  );

  useEffect(() => {
    if (value && Object.keys(value).length > 0) {
      reset(value)
    }
  }, [value])

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit))
  }, [])

  const showAdvanced = () => {
    setAdvanced(!advanced)
  }

  return (
    <Form>
      <FormGroup>
        <Row>
          <Col>
            <label>Por cada:</label>
            <Input type="number" {...register("amountForPoint")}/>
          </Col>
          <Col>
            <label>Contador:</label>
            <MeterPointSelect name={"meterId"} {...register("meterId")}/>
          </Col>
        </Row>

      </FormGroup>
      <FormGroup>
        <label>Máximo de cupones por jugada</label>
        <Input type="number" {...register("maxPoints")}/>
      </FormGroup>

      <FormGroup>
        <label>Acumulativo</label><br/>
        <SwitchToggle
          label={"Si"}
          {...register("floatPoints")}
        />
      </FormGroup>

      <FormGroup>
        <label>Aplica para:</label>
        <Controller name="machineTypeId" control={control} render={({field}) => <MachineTypeSelectV2 {...field} />}/>
      </FormGroup>

      <span style={{color : 'skyblue', cursor: 'pointer'}} onClick={showAdvanced}>Opciones avanzadas</span>

      {
        advanced &&
        <div>
          <FormGroup>
            <Row>
              <Col>
                <label>Monto mínimo:</label>
                <Input type="number" {...register("minAmount")}/>
              </Col>
              <Col>
                <label>Monto máximo:</label>
                <Input type="number" {...register("maxAmount")}/>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <label>Monto no cuenta para calculo</label>
            <Input type="number"  {...register("voidAmount")}/>
          </FormGroup>
          <FormGroup>
            {/*TODO swich toggle no funcionan*/}
            <SwitchToggle id={"netwinEnabled"} label={"Restar apostado"} {...register("netwinEnabled")}/>
          </FormGroup>
        </div>
      }


    </Form>
  )
}

export default RuleEditForm
