import React, {useCallback, useContext, useMemo} from "react";
import Moment from "react-moment";
import {FaUserCheck, FaUsers} from "react-icons/fa";
import {BsCashCoin} from "react-icons/bs";
import {useHistory} from "react-router";
import {Title} from "@wargostec/react-components";
import {Apis} from "../../../../util/Apis";
import {SiteContext} from "../../../common/context/SiteContext";
import PageSection from "../../../../components/layout/PageSection";
import {NumberRound} from "../../../../components/util/NumberUtil";
import AsyncBlock from "../../../../components/forms/AsyncBlock";
import PageContainerFull from "../../../../components/layout/PageContainerFull";
import CardOverview, {CardOverviewContainer} from "../../../../components/layout/CardOverview";
import {FlexLayout} from "../../../../components/flex/Flex";
import MethodOverlay from "../../../../components/util/MethodOverlay";
import Button, {ButtonBox} from "../../../../components/forms/Button";
import ReactTable from "../../../../components/util/ReactTable";
import useGet from "../../../../hooks/useGet";
import {getBetType} from "../../../../util";
import {Tab, Tabs} from "../../../../components/tabs/Tabs";
import OnlineLayout from "./OnlineLayout";

const CustomerOnline = () => {
  const history = useHistory()
  const {salaId} = useContext(SiteContext)

  const {loading, data, error, fetchData} = useGet({
    url: `${Apis.PC}/session/online-report`
  })

  const {data: dataSala} = useGet({
    url: Apis.SALAS + '/salas-config/' + salaId,
    initialValue: []
  })

  const methodValue = useCallback(props => <>{<MethodOverlay method={props.renderValue()} />}</>, []);

  const defaultValue = useCallback(props => <>{NumberRound({children: props.renderValue()})}</>, []);

  const apPromValue = useCallback(props => <>{
    <FlexLayout alignItems='center' justifyContent='flex-end' gap='4px'>
      <span>{NumberRound({children: props.renderValue()})}</span>
      {getBetType({valor: NumberRound({children: props.renderValue()})})}
    </FlexLayout>
  }</>, []);

  const fullNameValue = useCallback(props => <>{
    props.row.original.customerId > 0 ? props.row.original.firstName + ' ' + props.row.original.lastName1 + ' - ' + props.row.original.document : '--'
  }</>, []);

  const dateValue = useCallback(props => props.renderValue() !== null ? <Moment format={'DD/MM HH:mm'}>{props.renderValue()}</Moment> : '--', []);

  const columns = useMemo(
    () => [
      {
        header: 'Máquina',
        accessorKey: 'machineName',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Logueo',
        accessorKey: 'loginMethod',
        enableSorting: false, // para deshabilitar el sort
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: methodValue
      },
      {
        header: 'Cliente',
        id: 'cliente',
        accessorFn: cell => `${cell.firstName} ${cell.lastName1} ${cell.document}`,
        cell: fullNameValue
      },
      {
        header: 'Nivel',
        accessorKey: 'levelName',
      },
      {
        header: 'Apostado',
        accessorKey: 'ci',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
        cell: defaultValue
      },
      {
        header: 'Ganado',
        accessorKey: 'co',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
        cell: defaultValue
      },
      {
        header: 'Ap. Prom.',
        accessorKey: 'apProm',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
        cell: apPromValue
      },
      {
        header: 'Ultima ap.',
        accessorKey: 'lastCi',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
        cell: defaultValue
      },
      {
        header: 'Duración',
        accessorKey: 'duration',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Inicio',
        accessorKey: 'beginAt',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: dateValue
      },
      {
        header: 'Ultima ap.',
        accessorKey: 'updatedAt',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: dateValue
      },
      {
        header: 'Créditos',
        accessorKey: 'credits',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
      },
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , []
  )

  return (
    <PageContainerFull>
      <PageSection>
        <Title text="Clientes online" type={'page'} />
        {
          (salaId === 61 || salaId === 60) &&
          <ButtonBox>
            <Button variant={'primary'} onClick={() => history.push('/crm/sesiones-externas')}>
              Sesiones externas
            </Button>
          </ButtonBox>
        }
        <Tabs>
          <Tab title='Lista'>
            <AsyncBlock loading={loading} error={error}>
              <CardOverviewContainer gridColumns='repeat(3, 1fr)'>
                <CardOverview
                  title={data?.data && data?.data.length}
                  subtitle='Jugando'
                  icon={<FaUsers size={20} />}
                />
                <CardOverview
                  title={
                    dataSala && data?.apPromMachine !== 'NaN' ?
                      <span>
                        {(dataSala.currencySymbol + " ")} <NumberRound>{data?.apPromMachine}</NumberRound>
                      </span> : '-'
                  }
                  subtitle='Apuesta promedio'
                  icon={<BsCashCoin size={20} />}
                />
                <CardOverview
                  title={data?.ocupacion + ' %'}
                  subtitle='Ocupación'
                  icon={<FaUserCheck size={20} />}
                />
              </CardOverviewContainer>
              <ReactTable
                columns={columns}
                data={data?.data || []}
                noData='No hay clientes jugando en este momento'
                pageSize={50}
                customSearch
                filterRefresh={() => fetchData()}
              />
            </AsyncBlock>
          </Tab>
          <Tab title='Mapa'>
            <OnlineLayout />
          </Tab>
        </Tabs>

      </PageSection>
    </PageContainerFull>
  )
}

export default CustomerOnline;
