import React, {useEffect} from 'react';
import axios from "axios";
import {Apis} from "../../../../util/Apis";
import MidasForm from "./MidasForm";

const MidasNew = ({setAction, setTitle}) => {

  const save = async (params) => {
    params.salaId = parseInt(params.salaId)
    await axios.post(Apis.SALAS + '/sala-hub', params)
  }

  useEffect(() => {
    setTitle("Nueva IP")
  }, [])

  return (
    <MidasForm value={{}} onSubmit={save} setAction={setAction}/>
  );
};

export default MidasNew;
