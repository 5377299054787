import React from 'react';
import Card from "../../../../components/forms/Card";
import {Col, Row} from "../../../../components/flex/FlexLayout";
import {Flex} from "../../../../components/flex/Flex";
import StadisticTitle from "../../../../components/layout/StadisticTitle";
import StadisticValue from "../../../../components/layout/StadisticValue";

const MachineInformation = ({data}) => {

  const datadetail = data

  return (
    <div>
      {
        datadetail &&

        <Card padding>
          <Row>
            <Col>
              <Flex flexDirection={'column'}>
                <StadisticTitle>Maquina</StadisticTitle>
                <StadisticValue>{datadetail.machine}</StadisticValue>
              </Flex>
            </Col>
            <Col>
              <Flex flexDirection={'column'}>
                <StadisticTitle>Modelo</StadisticTitle>
                <StadisticValue>{datadetail.groupName}</StadisticValue>
              </Flex>
            </Col>
            <Col>
              <Flex flexDirection={'column'}>
                <StadisticTitle>Juego</StadisticTitle>
                <StadisticValue>{datadetail.gameName}</StadisticValue>
              </Flex>
            </Col>
          </Row>
        </Card>
      }
    </div>
  );
};

export default MachineInformation;
