import React, {useContext, useEffect, useState} from 'react'
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import {SiteContext} from "../../context/SiteContext";
import Select from "react-select";
import {SelectV2} from "../../../../components/forms/FormEl";

const UserSelect = React.forwardRef((props, ref) => {

  const {salaId} = useContext(SiteContext)

  const {data, loading} = useFetch2(`${Apis.USER}/users`,[],{salaId: salaId})
  const [selectedOption, setSelectedOption] = useState(null)
  const [optionsFormat, setOptionsFormat] = useState([])

  const prevOnChange = (selected) => {
    props.onChange(selected.value)
  }

  useEffect(() => {
    const selected = optionsFormat.find(option => option.value === props.value)
    setSelectedOption(selected)
  }, [props.value])

  useEffect(() => {
    if (data.data) {

      const formatted = data.data.map((item) => {
        return {value: item.id, label: item.firstName + ' ' + item.lastName};
      });
      setOptionsFormat(formatted)
      if (props.value) {
        const selected = formatted.find(option => option.value === props.value)
        setSelectedOption(selected)
      }
    }
  }, [data.data])

  return (
    <>
      {
        !loading ?
          <Select
            value={selectedOption}
            onChange={prevOnChange}
            options={optionsFormat}
          />
          :
          <SelectV2 disabled options={[{id: 0, name: 'Cargando...'}]}/>
      }
    </>
  )
})

export default UserSelect
