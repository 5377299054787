import React, {useContext} from 'react';
import {useForm} from "react-hook-form";
import axios from "axios";
import {Apis} from "../../../../util/Apis";
import {errorToast, successToast} from "../../../../components/layout/ToastStyles";
import Form from "react-bootstrap/Form";
import {Col, Row} from "../../../../components/flex/FlexLayout";
import FormGroup from "../../../../components/forms/FormGroup";
import Input, {Select} from "../../../../components/forms/FormEl";
import MachineSelect from "../../../common/components/selects/MachineSelect";
import Button, {ButtonBox} from "../../../../components/forms/Button";
import Card from "../../../../components/forms/Card";
import {SiteContext} from "../../../common/context/SiteContext";

const TicketCreate = () => {

  const {salaId} = useContext(SiteContext);

  const {
    register, control, handleSubmit, reset, formState: {errors}, setValue
  }
    = useForm({});

  const onCreate = (params) => {
    params.salaId = salaId
    params.date = getDate()
    params.time = Number(new Date().toLocaleTimeString().split(':').join('').split(' ')[0])
    params.amount = params.amount * 100
    axios.post(Apis.TICKET + '/ticket', params)
      .then(res => {
        successToast("Operación realizada correctamente", 2000)
        clear()
      }).catch(err => {
      errorToast("Ocurrió un problema al guardar. Intente de nuevo en unos minutos.",2000)
    })
  }

  const clear = () => {
    setValue('vn', "")
    setValue('amount', "")
    setValue('confId', "")
    setValue('validationType', 0)
  }

  const getDate = () => {
    const day = new Date().toLocaleDateString().split('/')[0]
    const month = new Date().toLocaleDateString().split('/')[1]
    const year = new Date().toLocaleDateString().split('/')[2]
    const dayString = day.length == 1 ? '0' + day : day
    const monthString = month.length == 1 ? '0' + month : month
    return Number(monthString + dayString + year)
  }

  return (
    <>
      <Card padding>
        <Form>
          <Row>
            <Col>
              <FormGroup>
                <label>Numero de ticket</label>
                <Input type="number" {...register("vn", {required: "Campo obligatorio"})}/>
                {errors.vn && <p style={{color: 'red'}}>{errors.vn?.message}</p>}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <label>Monto (S/.)</label>
                <Input type="number" {...register("amount")}/>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <label>Tipo de ticket</label>
                <Select {...register("validationType")}>
                  <option key={1} value={0}>CANJEABLE</option>
                  <option key={2} value={34}>PROMOCIONAL</option>
                </Select>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <label>Maquina (Opcional)</label>
                <MachineSelect {...register("confId")}/>
              </FormGroup>
            </Col>
          </Row>
        </Form>
        <ButtonBox>
          <Button title={"Guardar"} variant={'primary'} onClick={handleSubmit(onCreate)}/>
        </ButtonBox>
      </Card>
    </>
  );
};

export default TicketCreate;
