import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import PageSection from "../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import Button from "../../../../components/forms/Button";
import PageContainer from "../../../../components/layout/PageContainer";
import {SiteContext} from "../../../common/context/SiteContext";
import {useHistory} from "react-router";
import {Apis} from "../../../../util/Apis";
import useFetch2 from "../../../../hooks/useFetch2";
import SalaIdInput from "../../../common/components/SalaIdInput";
import FormGroup from "../../../../components/forms/FormGroup";
import Slidebar from "../../../common/layout/Slidebar";
import {Controller, useForm} from "react-hook-form";
import {serializeDownload} from "../../../../components/util/SerializeParams";
import {MachineTypeSelectV2} from "../../../common/components/selects/MachineTypeSelect";
import DateTimeV2 from "../../../../components/layout/DateTimeV2";
import getCasinoRange from "../../../../components/util/CasinoDates";
import CustomerLink from "../../../../components/util/CustomerLink";
import AsyncBlock from "../../../../components/forms/AsyncBlock";
import ReactTable from "../../../../components/util/ReactTable";
import {NumberRound} from "../../../../components/util/NumberUtil";
import {FlexLayout} from "../../../../components/flex/Flex";

const PointBalance = () => {

  const {salaId} = useContext(SiteContext)
  const [slideOpen, setSlideOpen] = useState(false)
  const history = useHistory();

  const {ini, fin} = getCasinoRange()

  const defaultParams = {ini, fin, machineTypeId: 2, salaId}
  const [params, setParams] = useState(defaultParams)
  const {
    register,
    handleSubmit,
    control
  } = useForm({defaultValues: defaultParams});

  const {
    loading,
    data,
    error,
    fetchData
  } = useFetch2(Apis.PC + '/customers/points-balance/by-range-time', [], params, false);

  const seeDetails = (id) => {
    history.push("/crm/puntos-balance/" + id)
  }

  useEffect(() => {
    fetchData()
  }, [params])

  const getData = (params) => {
    setParams(params)
  }

  const download = () => {
    const link = Apis.PC + `/customers/points-balance/by-range-time/report.xlsx?${serializeDownload(params)}`;
    window.location.href = link;
  }

  const numberValue = useCallback(props => <NumberRound>{props.renderValue()}</NumberRound>, []);
  const clientValue = useCallback(props => <CustomerLink item={props.row.original} customerId={props.row.original.id} />, []);

  const actionsValue = useCallback(props => <>{
    <FlexLayout justifyContent='center'>
      <Button variant='dark' size='xs' onClick={() => seeDetails(props.row.original.id)}>Ver detalles</Button>
    </FlexLayout>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }</>, []);

  const columns = useMemo(
    () => [
      {
        header: 'Cliente',
        accessorKey: 'firstName',
        cell: clientValue
      },
      {
        header: 'Documento',
        accessorKey: 'document',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Apostado',
        accessorKey: 'ci',
        disableSortBy: true,
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
        cell: numberValue
      },
      {
        header: 'Puntos',
        accessorKey: 'points',
        disableSortBy: true,
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
        cell: numberValue
      },
      {
        header: 'Acciones',
        accessorKey: 'acciones',
        enableGlobalFilter: false, // para deshabilitar el filter
        enableSorting: false, // para deshabilitar el sort
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: actionsValue
      }
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , []
  )

  return (
    <PageContainer>
      <Slidebar visible={slideOpen} onHide={() => setSlideOpen(false)}>
        <div style={{ padding: '16px' }}>
          <SalaIdInput {...register("salaId")} />

          <FormGroup>
            <label>Desde</label>
            <Controller name="ini" control={control} render={({ field }) => <DateTimeV2 {...field} />} />
          </FormGroup>

          <FormGroup>
            <label>Hasta</label>
            <Controller name="fin" control={control} render={({ field }) => <DateTimeV2 {...field} />} />
          </FormGroup>

          <FormGroup>
            <label>Aplica para:</label>
            <Controller name="machineTypeId" control={control}
              render={({ field }) => <MachineTypeSelectV2 {...field} />} />
          </FormGroup>

          <Button variant={'primary'} title='Buscar' onClick={handleSubmit(getData)} />
        </div>
      </Slidebar>

      <PageSection>
          <Title text="Reporte de puntos" type={'page'}/>
        <AsyncBlock loading={loading} error={error}>
          <ReactTable
            columns={columns}
            data={data || []}
            filterOnclick={() => setSlideOpen(!slideOpen)}
            filterDownload={download}
            customSearch
            noData='No existen resultados para el filtro seleccionado'
            pageSize={200}
          />
        </AsyncBlock>
      </PageSection>
    </PageContainer>
  )
}

export default PointBalance;
