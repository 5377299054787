import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import PageContainer from "../../../../components/layout/PageContainer";
import PageSection from "../../../../components/layout/PageSection";
import {Button, Title} from "@wargostec/react-components";
import FormGroup from "../../../../components/forms/FormGroup";
import {SiteContext} from "../../../common/context/SiteContext";
import {Apis} from "../../../../util/Apis";
import {Controller, useForm} from "react-hook-form";
import SalaIdInput from "../../../common/components/SalaIdInput";
import Slidebar from "../../../common/layout/Slidebar";
import useFetch2 from "../../../../hooks/useFetch2";
import moment from "moment";
import {serializeDownload} from "../../../../components/util/SerializeParams";
import AsyncBlock from "../../../../components/forms/AsyncBlock";
import ReactTable from "../../../../components/util/ReactTable";
import DateTimeV2 from "../../../../components/layout/DateTimeV2";
import SwitchToggle from "../../../../components/layout/SwitchToggle";
import CardOverview, {CardOverviewContainer} from "../../../../components/layout/CardOverview";
import {HiOutlineSlotMachine} from "../../../../components/assets/icons/slot-machine";
import {FlexLayout} from "../../../../components/flex/Flex";
import {useHistory} from "react-router";
import {getSnapText} from "../../../../components/util/SnapText";
import {IoEye} from "react-icons/io5";
import Moment from "react-moment";

const Accountants = () => {

  const {salaId} = useContext(SiteContext)
  const history = useHistory();
  const [slideOpen, setSlideOpen] = useState(false)
  const actualDate = moment(new Date()).format('YYYY-MM-DDT08:00');

  let defaultParams = {salaId: salaId, fechaHora: actualDate}
  const [params, setParams] = useState(defaultParams)
  const {register, handleSubmit,watch,control} = useForm({defaultValues: defaultParams})

  const {data, loading, error, fetchData} = useFetch2(Apis.TGM + '/meter-snap/all-machines', [], params)

  const getData = (params) => {
    setParams(params)
  }

  const watchClosure = watch('closure')

  useEffect(() => {
    fetchData()
  }, [params])

  const download = () => {
    const link = Apis.TGM + `/meter-snap/all-machines/report.xlsx?${serializeDownload(params)}`;
    window.location.href = link;
  }

  const convertNumber = (value) => {
    return String(value).padStart(8, '0')
  };

  const sourceValue = useCallback(props => <>
    {getSnapText(props.renderValue())}
  </>, [])

  const actionValue = useCallback(props => <>{
    <FlexLayout justifyContent='center'>
      <Button variant={'light'} size='xs' icon={<IoEye />}
              onClick={() => history.push(`/tgm/meter-snap?ini=${moment(props.row.original.fechaHora).format('YYYY-MM-DD')}&fin=${moment(props.row.original.fechaHora).format('YYYY-MM-DD')}&confId=${props.row.original.confId}`)}/>
    </FlexLayout>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }</>, []);

  const dateValue = useCallback(props => <Moment format={'DD/MM HH:mm'}>{props.renderValue()}</Moment>, []);

  const columns = useMemo(
    () => [
      {
        header: 'Máquina',
        accessorKey: 'machine',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center font-bold',
        },
      },
      {
        header: 'RMT',
        id: 'numReg',
        accessorFn: (row) => row.numReg === null ? '--' : convertNumber(row.numReg),
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'CoinIn',
        accessorKey: 'ci',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
      },
      {
        header: 'CoinOut',
        accessorKey: 'co',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        }
      },
      {
        header: 'Jackpot',
        accessorKey: 'jp',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        }
      },
      {
        header: 'Cancel Credit',
        accessorKey: 'cc',
        enableSorting: false,
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
      },
      {
        header: 'Billetes',
        accessorKey: 'bi',
        enableSorting: false,
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        }
      },
      {
        header: 'TicketIn',
        accessorKey: 'ticketIn',
        enableSorting: false,
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
      },
      {
        header: 'TicketOut',
        accessorKey: 'ticketOut',
        enableSorting: false,
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
      },
      {
        header: 'Jugadas',
        accessorKey: 'gp',
        enableSorting: false,
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
      },
      {
        header: 'Fecha',
        accessorKey: 'fechaHora',
        enableSorting: true,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: dateValue
      },
      {
        header: 'Origen',
        accessorKey: 'sourceId',
        enableSorting: true,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: sourceValue
      },
      {
        header: '',
        accessorKey: 'audit',
        enableSorting: false,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: actionValue
      },
    ]
    , []
  )

  return (
    <PageContainer>
      <Slidebar visible={slideOpen} onHide={() => setSlideOpen(false)}>
        <div style={{padding: '16px'}}>
          <SalaIdInput {...register("salaId")} />
          <FormGroup>
            <label>Fecha</label>
            <Controller name="fechaHora" control={control}
                        render={({field}) => <DateTimeV2 {...field} disableHour={watchClosure}/>}/>
          </FormGroup>
          <FormGroup>
            <SwitchToggle label={"Cierre el día"} id={"closure"} {...register("closure")} />
          </FormGroup>
          <Button variant={'primary'} text='Buscar' onClick={handleSubmit(getData)}/>
        </div>
      </Slidebar>
      <PageSection>
        <Title text="Contadores por hora" type={'page'} />

        <CardOverviewContainer gridColumns='repeat(1, 1fr)'>
          <CardOverview
            title={data && (data.length)}
            subtitle='Maquinas'
            icon={<HiOutlineSlotMachine fontSize={'20'} />}
          />
        </CardOverviewContainer>

        <AsyncBlock loading={loading} error={error}>
          <ReactTable
            columns={columns}
            data={data || []}
            noData='No existen resultados para el filtro seleccionado'
            pageSize={100}
            customSearch
            filterOnclick={() => setSlideOpen(!slideOpen)}
            filterDownload={download}
            sortArray={[{id: 'machine', asc: false}]}
          />
        </AsyncBlock>
      </PageSection>
    </PageContainer>
  )
}

export default Accountants
