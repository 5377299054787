import React, {useContext} from 'react'
import {Select} from "../../../../components/forms/FormEl";
import {Apis} from "../../../../util/Apis";
import {SiteContext} from "../../context/SiteContext";
import useFetch from "../../../../hooks/useFetch";

const MachineSelect = React.forwardRef((props, ref) => {

  const {data, loading} = useFetch(`${Apis.TGM}/machines/combo`)

  return (
    <Select ref={ref} {...props} >
      {props.todos === 1 && <option value={0}>TODOS</option>}
      {data && data.map(item => <option key={item.id} value={item.confId}>{item.machine}</option>)}
    </Select>
  )
})

export default MachineSelect
