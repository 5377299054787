import React, { useContext, useState } from 'react';
import { SiteContext } from "../../../../common/context/SiteContext";
import { Apis } from "../../../../../util/Apis";
import useEditModal from "../../../../../modals/useEditModal";
import useDeleteModal from "../../../../../modals/useDeleteModal";
import axios from "axios";
import SegmentsEdit from "./SegmentsEdit";
import SegmentsNew from "./SegmentsNew";
import PageSection from "../../../../../components/layout/PageSection";
import { Title } from "@wargostec/react-components";
import Button, { ButtonBox } from "../../../../../components/forms/Button";
import { FaExchangeAlt, FaPlus } from "react-icons/fa";
import Card from "../../../../../components/forms/Card";
import Table from "react-bootstrap/Table";
import GenericModal2 from "../../../../../modals/GenericModal2";
import DeleteGenericModal from "../../../../../modals/DeleteGenericModal";
import PageContainer from "../../../../../components/layout/PageContainer";
import { LoadingTableV2 } from "../../../../../components/forms/Tables";
import PageCardTitle from "../../../../../components/layout/CardTitle";
import FormGroup from "../../../../../components/forms/FormGroup";
import Input from "../../../../../components/forms/FormEl";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useForm } from "react-hook-form";
import DayOfMonthSelect from "../../../../common/components/selects/DayOfMonthSelect";
import MonthSelect from "../../../../common/components/selects/MonthSelect";
import { errorToast, successToast } from "../../../../../components/layout/ToastStyles";
import AsyncBlock from "../../../../../components/forms/AsyncBlock";
import SegmentCircle from "../customers/SegmentCircle";
import SwitchToggle from "../../../../../components/layout/SwitchToggle";
import useGet from '../../../../../hooks/useGet';
import { usePost } from '../../../../../hooks/usePost';

const SegmentsList = () => {

  const { salaId } = useContext(SiteContext)
  let defaultParams = { salaId: salaId }
  const { register, handleSubmit, reset } = useForm()
  const [mode, setMode] = useState('Anual')

  const { loading, error, data, fetchData } = useGet({
    url: Apis.PC + '/player-club-config',
    initialValue: [],
    params: defaultParams,
    onSuccess: (data) => {
      if (data) {
        if (data.seasonConfig) {
          setMode(data.seasonConfig.period === 1 ? 'Anual' : 'Mensual')
        }
        reset(data)
      }
    }
  })

  const { loading: loading2, error: error2, data: data2, fetchData: fetchData2 } = useGet({
    url: Apis.PC + `/customer-segments`,
    initialValue: [],
    params: defaultParams,
  })

  const { mutate } = usePost({
    url: Apis.PC + "/player-club-config",
    onSuccess: () => successToast(),
    onError: () => errorToast(),
  })

  const { wrapper: wrapper1, addAction, editAction } = useEditModal({
    editComponent: <SegmentsEdit />,
    addComponent: <SegmentsNew />,
    onRefresh: async () => {
      await fetchData(defaultParams);
      await fetchData2(defaultParams)
    }
  })

  const deleteElement = id => axios.delete(Apis.PC + '/customer-segments/' + id)

  const {
    wrapper: wrapper2,
    openDeleteModal
  } = useDeleteModal({
    text: '¿Desea eliminar este segmento ?',
    action: deleteElement,
    onRefresh: async () => {
      await fetchData(defaultParams);
      await fetchData2(defaultParams)
    }
  })

  const updateConfig = (data) => {
    data.seasonConfig.period = mode === 'Anual' ? 1 : 2
    mutate(data)
  };

  const changeMode = (value) => {
    value === 'Anual' ? setMode('Mensual') : setMode('Anual')
    if (value === 'Anual') {
      let monthCurrent = new Date().getMonth() + 1
      reset({ ...data, seasonConfig: { ...data.seasonConfig, monthStart: monthCurrent } })
    }
  }

  return (
    <PageContainer>

      <PageSection>
        <Title text="Club de puntos" type={'page'} />
        <ButtonBox>
          <Button variant={'primary'} onClick={() => addAction()}>
            <FaPlus /> Nuevo nivel
          </Button>
        </ButtonBox>
      </PageSection>

      <PageSection>

        <PageCardTitle title={'Por temporada'} />

        <Card padding>
          <AsyncBlock loading={loading} error={error}>
            <SwitchToggle label={"Habilitado"}
              id={"seasonConfig.seasonEnabled"} {...register("seasonConfig.seasonEnabled")} /><br />
            <FormGroup>
              <Button size={'s'} variant={'success'} onClick={() => changeMode(mode)}>
                {mode}
              </Button>
              <FaExchangeAlt size={20} style={{ paddingLeft: '5px' }} />
            </FormGroup>

            <FormGroup>
              <label>Inicio de temporada</label>
              <Row>
                <Col>
                  <DayOfMonthSelect {...register("seasonConfig.dayStart")} />
                </Col>
                <Col>
                  <MonthSelect {...register("seasonConfig.monthStart")} disabled={mode === 'Mensual'} />
                </Col>
              </Row>
            </FormGroup>

            <SwitchToggle label={"Subida de nivel automático"}
              id={"seasonConfig.autoUpgrade"} {...register("seasonConfig.autoUpgrade")} /><br />
            <SwitchToggle label={"Bajada de nivel automático"}
              id={"seasonConfig.autoDowngrade"} {...register("seasonConfig.autoDowngrade")} /><br />
          </AsyncBlock>


        </Card>

        <PageCardTitle title={'Dinamico'} />

        <Card padding>
          <AsyncBlock loading={loading} error={error}>
            <SwitchToggle label={"Habilitado"}
              id={"dynamicConfig.dynamicEnabled"} {...register("dynamicConfig.dynamicEnabled")} /><br />

            <FormGroup>
              <label>Duración de periodo (días)</label>
              <Input name="name" {...register("dynamicConfig.daysPeriod")} />
            </FormGroup>

            <FormGroup>
              <label>Fecha de caducidad</label>
              <Row>
                <Col>
                  <DayOfMonthSelect {...register("dynamicConfig.vigencyDay")} />
                </Col>
                <Col>
                  <MonthSelect {...register("dynamicConfig.vigencyMonth")} />
                </Col>
              </Row>
            </FormGroup>
          </AsyncBlock>
        </Card>

        <PageCardTitle title={'Niveles'} />
        <Card padding>
          <LoadingTableV2 loading={loading2} error={error2} data={data2}>
            <Table striped hover>
              <thead>
                <tr>
                  <th className={'text-center'}></th>
                  <th className={'text-center'}>Nivel</th>
                  <th className={'text-center'}>Puntos</th>
                  <th className={'text-center'}>Valor de canje por S/1 </th>
                  <th className={'text-center'}>Máximo días sin actividad</th>
                </tr>
              </thead>
              <tbody>
                {data2 && data2.map(item =>
                  <tr key={item.id}>
                    <td className={'text-center'}>
                      <SegmentCircle levelId={item.id} array={data2} />
                    </td>
                    <td className={'text-center'}>{item.name}</td>
                    <td className={'text-center'}>{item.points}</td>
                    <td className={'text-center'}>{item.valueExchange}</td>
                    <td className={'text-center'}>{item.maxDaysInactivity}</td>
                    <td>
                      <ButtonBox>
                        <Button size={'s'} onClick={() => editAction(item.id)}>Editar</Button>
                        <Button size={'s'} onClick={() => openDeleteModal(item.id)}>Eliminar</Button>
                      </ButtonBox>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </LoadingTableV2>
        </Card>

        <ButtonBox>
          <Button title={"Guardar"} variant={'primary'} onClick={handleSubmit(updateConfig)} />
        </ButtonBox>

      </PageSection>

      <GenericModal2 {...wrapper1} />
      <DeleteGenericModal {...wrapper2} />

    </PageContainer>
  );
};

export default SegmentsList;
