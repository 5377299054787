import React, {useCallback, useMemo} from 'react';
import Moment from "react-moment";
import ReactTable from "../../../../../components/util/ReactTable";
import AsyncBlock from "../../../../../components/forms/AsyncBlock";

const TicketTable = ({loading, error, data, fetchData, setSlideOpen, slideOpen}) => {

  const getStatusName = (value) => {
    switch (value) {
      case 0:
        return 'Emitido'
      case 1:
        return 'Canjeado'
      case 2:
        return 'Expirado'
      case 4:
        return 'Pendiente'
      case 5:
        return 'Progreso'
      case 6:
        return 'Error'
      default:
        return 'Emitido'
    }
  }

  const statusValue = useCallback(props =>
      <>
        {getStatusName(props.renderValue())}
      </>
    , []);

  const dateValue = useCallback(props => props.renderValue() !== null ?
    <Moment format={'DD/MM HH:mm'}>{props.renderValue()}</Moment> : '---', []);

  const amountValue = useCallback(props =>
      props.renderValue() !== null ? (props.renderValue() / 100).toFixed(2) : '---'
    , []);

  const machineRedeemValue = useCallback(props =>
      <>
        {
          props.row.original.status === 1 ?
            <span>
            {props.renderValue() !== null ? props.renderValue() : 'Caja'}
          </span>
            :
            <span>
            ---
          </span>
        }
      </>
    , []);

  const machineValue = useCallback(props =>
    props.renderValue() !== null ? props.renderValue() : 'Caja', []);

  const validationValue = useCallback(props =>
    validationName(props.renderValue()), []);

  const validationName = (value) => {
    switch (value) {
      case 0:
        return 'Cobrable'
      case 1:
        return 'Restringido'
      default:
        return 'Cobrabable'
    }
  }

  const columns = useMemo(
    () => [
      {
        header: 'ID',
        accessorKey: 'id',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        }
      },
      {
        header: 'Estado',
        accessorKey: 'status',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        },
        cell: statusValue
      },
      {
        header: 'Número de ticket',
        accessorKey: 'validNumber',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        }
      },
      {
        header: 'Tipo',
        accessorKey: 'validationType',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        },
        cell: validationValue
      },
      {
        header: 'Maquina (emisión)',
        accessorKey: 'machine',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        },
        cell: machineValue
      },
      {
        header: 'Fecha/Hora (emisión)',
        accessorKey: 'createdAt',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: dateValue
      },
      {
        header: 'Maquina (canje)',
        accessorKey: 'machineRedeem',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        },
        cell: machineRedeemValue
      },
      {
        header: 'Fecha/Hora (canje)',
        accessorKey: 'redeemAt',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: dateValue
      },
      {
        header: 'Monto',
        accessorKey: 'amount',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
        cell: amountValue
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ], [data])

  return (
    <>
      <AsyncBlock loading={loading} error={error}>
        <ReactTable
          columns={columns}
          data={data || []}
          noData='No existen resultados para el filtro seleccionado'
          filterRefresh={() => fetchData()}
          filterOnclick={() => setSlideOpen(!slideOpen)}
          pageSize={15}
        />
      </AsyncBlock>
    </>

  );
};

export default TicketTable;
