import React from 'react';
import Table from 'react-bootstrap/Table';
import axios from 'axios';
import { Title } from "@wargostec/react-components";
import { BsStar } from 'react-icons/bs';
import PageSection from '../../../../components/layout/PageSection';
import Button, { ButtonBox } from '../../../../components/forms/Button';
import PageContainer from '../../../../components/layout/PageContainer';
import MachineTypeText from '../../../common/components/MachineTypeText';
import DeleteGenericModal from '../../../../modals/DeleteGenericModal';
import { Apis } from '../../../../util/Apis';
import PuntosConfiguracionNew from './PuntosConfiguracionNew';
import PuntosConfiguracionEdit from './PuntosConfiguracionEdit';
import useEditModal from '../../../../modals/useEditModal';
import useDeleteModal from '../../../../modals/useDeleteModal';
import GenericModal2 from '../../../../modals/GenericModal2';
import MeterText from '../../../common/components/MeterText';
import { LoadingTableV2 } from '../../../../components/forms/Tables';
import Card from '../../../../components/forms/Card';
import { AiOutlinePlus } from 'react-icons/ai';
import EmptyState from '../../../common/components/EmptyState';
import AsyncBlock from '../../../../components/forms/AsyncBlock';
import useGet from '../../../../hooks/useGet';

const PuntosConfiguracionList = () => {

	const { data, loading, error, fetchData } = useGet({
		url: Apis.PC + '/point-rules',
		initialValue: [],
	})

	const deleteElement = (id) => axios.delete(Apis.PC + '/point-rules/' + id);

	//hooks para crear,editar y eliminar
	const {
		wrapper: wrapper1,
		addAction,
		editAction
	} = useEditModal({
		editComponent: <PuntosConfiguracionEdit />,
		addComponent: <PuntosConfiguracionNew />,
		onRefresh: () => {
			setTimeout(() => {
				fetchData()
			}, 1000)
		}
	});

	const { wrapper: wrapper2, openDeleteModal } = useDeleteModal({
		text: '¿Desea eliminar esta configuración de puntos?',
		onRefresh: () => {
			setTimeout(() => {
				fetchData()
			}, 1000)
		},
		action: deleteElement
	});

	return (
		<PageContainer>
			<PageSection>
				<Title text="Configuración de Puntos" type={'page'} />
				<Button variant='primary' onClick={addAction}>
					<AiOutlinePlus />
					<span>Nuevo</span>
				</Button>
			</PageSection>

			<PageSection>
				<AsyncBlock loading={loading} error={error}>
					<Card padding>
						{data.length === 0 ? (
							<EmptyState
								title={'Crea y acumula puntos!'}
								subtitle={
									'Agregue una nueva regla haciendo click en Nuevo y comienza a acumular puntos.'
								}
								icon={<BsStar size={32} />}
							/>
						) : (
							<LoadingTableV2 loading={loading} error={error} data={data}>
								<Table striped hover>
									<thead>
										<tr>
											<th>Regla</th>
											<th>Aplica</th>
											<th>Máximo</th>
											<th>En una Jugada?</th>
											<th>Acciones</th>
										</tr>
									</thead>
									<tbody>
										{data &&
											data.map((item) => (
												<tr key={item.id}>
													<td>
														{'1 PUNTO = S/ ' + item.amountForPoint + ' '}
														<MeterText meterId={item.meterId} />
													</td>
													<td>
														<MachineTypeText
															machineTypeId={item.machineTypeId}
														/>
													</td>
													<td>{item.maxPoints} puntos</td>
													<td>
														{item.floatPoints ? 'ACUMULATIVO' : 'UNA JUGADA'}
													</td>
													<td>
														<ButtonBox>
															<Button
																variant={'light'}
																size={'xs'}
																onClick={() => editAction(item.id)}
																title={'Editar'}
															/>
															<Button
																variant={'danger'}
																size={'xs'}
																onClick={() =>
																	openDeleteModal(item.id)
																}
																title={'Eliminar'}
															/>
														</ButtonBox>
													</td>
												</tr>
											))}
									</tbody>
								</Table>
							</LoadingTableV2>
						)}
					</Card>
				</AsyncBlock>
			</PageSection>
			<GenericModal2 {...wrapper1} />
			<DeleteGenericModal {...wrapper2} />
		</PageContainer>
	);
};

export default PuntosConfiguracionList;
