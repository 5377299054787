import React, { useContext, useState } from "react";
import FormGroup from "../../../../components/forms/FormGroup";
import { useFieldArray, useForm } from "react-hook-form";
import Input, { Select, TextArea } from "../../../../components/forms/FormEl";
import { useHistory, useParams } from "react-router";
import Button, { ButtonBox } from "../../../../components/forms/Button";
import Table from "react-bootstrap/Table";
import PrinterSelect from "../../../common/components/selects/PrinterSelect";
import DayOfWeekSelect from "../../../common/components/DayOfWeekSelect";
import CouponTemplateSelect from "../../../common/components/selects/CouponTemplateSelect";
import MachineTypeText from "../../../common/components/MachineTypeText";
import MeterText from "../../../common/components/MeterText";
import RuleEdit from "./rules/RuleEdit";
import Card from "../../../../components/forms/Card";
import SalaHiddenInput from "../../../common/components/SalaHiddenInput";
import { Col, Row } from "../../../../components/flex/FlexLayout";
import useEditModal from "../../../../modals/useEditModal";
import RuleNew from "./rules/RuleNew";
import GenericModal2 from "../../../../modals/GenericModal2";
import useDeleteModal from "../../../../modals/useDeleteModal";
import DeleteGenericModal from "../../../../modals/DeleteGenericModal";
import axios from "axios";
import { Apis } from "../../../../util/Apis";
import PrinterZoneEdit from "./printers/PrinterZoneEdit";
import PrinterZoneNew from "./printers/PrinterZoneNew";
import { SiteContext } from "../../../common/context/SiteContext";
import SwitchToggle from "../../../../components/layout/SwitchToggle";
import ExpiredHistory from "./ExpiredHistory";
import useModal from "../../../../modals/useModal";
import ModalInfo from "../../../../modals/ModalInfo";
import ExpiredManual from "./ExpiredManual";
import PageCardTitle from "../../../../components/layout/CardTitle";
import CouponTypeMultiplicaTable from "./CouponTypeMultiplierTable";
import { FaArrowRight, FaInfoCircle } from "react-icons/fa";
import Leyenda from "./Leyenda";
import GenderSelect from "../../../common/components/selects/GenderSelect";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Calendar, momentLocalizer } from "react-big-calendar";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from "moment/moment";
import AsyncBlock from "../../../../components/forms/AsyncBlock";
import ButtonGroup from "../../../../components/forms/ButtonGroup";
import { BsFillCalendarCheckFill, BsTable } from "react-icons/bs";
import EmptyState from "../../../common/components/EmptyState";
import { RiCouponLine } from "react-icons/ri";
import { FlexLayout } from "../../../../components/flex/Flex";
import useGet from "../../../../hooks/useGet";

const TextWithHelp = ({ label, help }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '4px', marginBottom: '0.2rem' }}>
      <label style={{ marginBottom: '0' }}>{label}</label>
      <OverlayTrigger
        placement={'top'}
        overlay={
          <Tooltip>
            <span style={{ fontSize: '12px' }}>{help}</span>
          </Tooltip>
        }
      >
        <span><FaInfoCircle size={16} /></span>
      </OverlayTrigger>
    </div>
  )
}

const CouponTypeForm = ({ value, onSubmit, mode, fetchData }) => {

  const urlBase = 'https://res.cloudinary.com/dghqjv5az/image/upload/'
  const params = {}
  
  const localize = momentLocalizer(moment);
  const history = useHistory()
  const { id } = useParams()
  const { salaId } = useContext(SiteContext)
  
  const [modeMult, setModeMult] = useState('Tabla')
  
  const { register, handleSubmit, watch, control } = useForm({ defaultValues: value });

  const paramsStateReport = { enabled: true, accountId: salaId }

  const watchPrintable = watch("printable")
  const watchAutoPrint = watch("autoPrint")
  const templateId = watch("templateId")

  const { fields } = useFieldArray({
    control,
    name: "couponRules",
    keyName: "fakeId"
  });

  const zonesData = useGet({
    url: Apis.TGM + '/slot-zones',
  })

  const printerData = useGet({
    url: Apis.PRINT + '/printers/state-report',
    params: paramsStateReport,
    initialValue: []
  })

  const zonePrinterList = useGet({
    url: Apis.PC + '/coupon-zone-printer?couponTypeId=' + id
  })

  const {
    data: multiplicas,
    fetchData: fetchData2,
    loading: loading2,
    error: error2
  } = useGet({
    url: Apis.PC + '/coupon-type-promo/' + id + '/by-calendar',
    initialValue: [],
    params: params,
    auto: false
  })

  const deleteElement = id => axios.delete(Apis.PC + '/coupon-rule/' + id + "/rule")
  const deleteZonePrinter = id => axios.delete(Apis.PC + '/coupon-zone-printer/' + id)

  const { wrapper: wrapper1, addAction, editAction } = useEditModal({
    editComponent: <RuleEdit />,
    addComponent: <RuleNew />,
    onRefresh: () => {
      mode && fetchData()
    }
  })

  const { wrapper: wrapper3, someAction: experidAction } = useModal({
    component: <ExpiredManual />
  })

  const { wrapper: wrapper4 } = useModal({
    component: <ExpiredHistory />
  })

  const { wrapper: wrapper5, someAction: openLeyenda } = useModal({
    component: <Leyenda />
  })

  const PrinterZoneModal = useEditModal({
    editComponent: <PrinterZoneEdit />,
    addComponent: <PrinterZoneNew />,
    onRefresh: () => {
      mode && fetchData()
    }
  })

  const {
    wrapper: wrapper2,
    openDeleteModal
  } = useDeleteModal(
    {
      text: '¿Desea eliminar esta regla?',
      action: deleteElement,
      onRefresh: () => {
        mode && fetchData()
      }
    })

  const PrinterZoneDeleteModal = useDeleteModal(
    {
      text: '¿Desea eliminar esta impresora por zona?',
      action: deleteZonePrinter,
      onRefresh: () => {
        mode && fetchData()
      }
    })

  const goToPromoNew = () => {
    history.push('/crm/coupon-promo/new')
  }

  const routerEditMultiplier = (id) => {
    history.push('/crm/coupon-promo/edit/' + id)
  }

  const getModeMult = () => {
    modeMult === 'Tabla' ? setModeMult('Calendario') : setModeMult('Tabla')
    params.ini = value.iniDate
    params.fin = value.finDate
    fetchData2(params)
  }

  const generateData = (values) => {
    const calendar = []
    values.filter(item => item.multiplier !== 1).forEach(item => {
      calendar.push({
        start: item.start,
        end: item.end,
        title: (item.multiplier !== 0) ? 'Cupones ' + item.title : 'Deshabilitado (x0)',
        color: colorStyle(item.color),
        id: item.id
      })
    })
    return calendar
  }

  const colorStyle = (color) => {
    switch (color) {
      case 'green':
        return '#7fee7f'
      case 'gray':
        return '#a2a1a1'
      case 'white':
        return '#ffffff'
      default:
        return '#ffffff'
    }
  }

  const getImageUrl = (templateId) => {
    switch (templateId) {
      case "D1":
        return urlBase + "v1710538603/wgtassets/coupontemplates/D1_-_large_nsuhfw.png";
      case "D3":
        return urlBase + "v1710538616/wgtassets/coupontemplates/D3_-_minimal_uxeyzx.png";
      case "D4":
        return urlBase + "v1710538610/wgtassets/coupontemplates/D4_vietbd.png";
      case "D5":
        return urlBase + "v1710538610/wgtassets/coupontemplates/D5_egrqwh.png";
      case "D6":
        return urlBase + "v1710538603/wgtassets/coupontemplates/D6_shhhno.png";
      case "D8":
        return urlBase + "v1712795440/wgtassets/coupontemplates/D8_txzctw.png";
      default:
        return null;
    }
  };

  return (
    <>
      <PageCardTitle title={'General'} />

      <Card padding>
        <SalaHiddenInput {...register("salaId")} />
        <FormGroup>
          <SwitchToggle {...register("enabled")} id={"enabled"} label={"Habilitado"} />
        </FormGroup>
        <FormGroup>
          <TextWithHelp label={'Nombre'} help={'Nombre de la promoción visible para el cliente'}></TextWithHelp>
          <Input name="name" {...register("name")} />
        </FormGroup>
        <FormGroup>
          <label>Abreviación</label>
          <Input name="abrv" {...register("abrv")} />
        </FormGroup>
        <Row>
          <Col>
            <FormGroup>
              <SwitchToggle id={"correlativeEnabled"} label={"Correlativo"} {...register("correlativeEnabled")} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <label>Siguiente correlativo</label>
              <Input {...register("correlativeNext")} disabled={true} />
            </FormGroup>
          </Col>
        </Row>
      </Card>

      <PageCardTitle title={'Horarios'} />
      <Card padding>
        <Row>
          <Col>
            <FormGroup>
              <label>Inicio de promoción</label>
              <Input type={"date"} {...register("iniDate", { required: true })} />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <label>Hora</label>
              <Input type={"time"} {...register("iniTime", { required: true })} />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <label>Fin de promoción</label>
              <Input type={"date"} {...register("finDate", { required: true })} />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <label>Hora</label>
              <Input type={"time"} {...register("finTime", { required: true })} />
            </FormGroup>
          </Col>
        </Row>
      </Card>

      <PageCardTitle title={"Reglas generales"} />
      <Card padding>
        <FormGroup>
          <TextWithHelp label={'Género'}
            help={'Solo participan en la promoción los clientes del genero seleccionado'}></TextWithHelp>
          <GenderSelect {...register("gender")} />
        </FormGroup>

        <FormGroup>
          <TextWithHelp label={'Cantidad maxima'}
            help={'Cantidad máxima de cupones acumulados por cliente'}></TextWithHelp>
          <Input {...register("couponLimit")} />
        </FormGroup>
      </Card>

      {mode &&
        <React.Fragment>
          <PageCardTitle title={'Reglas'} />
          <Card padding>
            <Button title='Agregar' variant='primary' size='s' onClick={() => addAction()} />
            <FormGroup style={{ maxHeight: "500px", overflowY: "auto" }}>
              <Table striped hover responsive size="sm">
                <thead>
                  <tr>
                    <th>Por cada</th>
                    <th className="text-center">Aplica para</th>
                    <th className="text-center">
                      <FlexLayout alignItems='center' justifyContent='center'>
                        <TextWithHelp label={'Monto mínimo'} help={'Monto mínimo para acumular un cupón'} />
                      </FlexLayout>
                    </th>
                    <th className="text-center">
                      <FlexLayout alignItems='center' justifyContent='center'>
                        <TextWithHelp label={'Monto máximo'} help={'Monto máximo para acumular un cupón'} />
                      </FlexLayout>
                    </th>
                    <th className="text-center">
                      <FlexLayout alignItems='center' justifyContent='center'>
                        <TextWithHelp label={'Máximo por jugada'}
                          help={'Cantidad máxima de cupones ganados en una jugada'} />
                      </FlexLayout>
                    </th>
                    <th className="text-center">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {fields.map((field) =>
                    <tr key={field.id}>
                      <td>
                        {'1 CÚPON = S/ ' + field.amountForPoint + ' '}
                        <MeterText meterId={field.meterId} />
                      </td>
                      <td className="text-center">
                        <MachineTypeText machineTypeId={field.machineTypeId} />
                      </td>
                      <td className="text-center">{field.minAmount}</td>
                      <td className="text-center">{field.maxAmount}</td>
                      <td className="text-center">{field.maxPoints}</td>
                      <td className="text-center">
                        <FlexLayout alignItems='center' justifyContent='center'>
                          <Button variant={'light'} size='xs' title={'Editar'} onClick={() => editAction(field.id)} />
                          <Button variant={'danger'} size='xs' title={'Eliminar'}
                            onClick={() => openDeleteModal(field.id)} />
                        </FlexLayout>

                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </FormGroup>
          </Card>
        </React.Fragment>
      }

      <PageCardTitle title={'Sorteo virtual'} />
      <Card padding>
        <FormGroup>
          <SwitchToggle id={"virtual"} label={"Habilitado"} {...register("virtual")} />
        </FormGroup>

        <FormGroup>
          <SwitchToggle id={"autoDeposit"} label={"Depósito automático"} {...register("autoDeposit")} />
        </FormGroup>
      </Card>

      <PageCardTitle title={'Impresión'} />
      <Card padding>
        <FormGroup>
          <SwitchToggle {...register("printable")} label={"Imprimible"} id={"printable"} />
        </FormGroup>
        {watchPrintable &&
          <>
            <Row>
              <Col>
                <FormGroup>
                  <label>Plantilla</label>
                  <CouponTemplateSelect {...register("templateId")} />
                </FormGroup>
              </Col>
              <Col>
                <FlexLayout justifyContent='center'>
                  {templateId ?
                    getImageUrl(templateId) ?
                      <img
                        src={getImageUrl(templateId)}
                        alt={`Plantilla ${templateId}`}
                        style={{
                          border: '1px solid rgb(0 0 0 / 25%)',
                          borderRadius: '8px',
                          height: 'auto',
                          width: '300px',
                          boxShadow: 'rgba(0, 0, 0, 0.15) 0 0 10px'
                        }}
                      />
                      :
                      <FlexLayout
                        padding='16px'
                        justifyContent='center'
                        alignItems='center'
                        style={{
                          border: '1px solid rgb(0 0 0 / 25%)',
                          borderRadius: '8px',
                          height: '100px',
                          width: '300px',
                          textAlign: 'center',
                          boxShadow: 'rgba(0, 0, 0, 0.15) 0 0 10px'
                        }}>Aún no hay imagen disponible para esta plantilla
                      </FlexLayout>

                    : null}
                </FlexLayout>
              </Col>
            </Row>
            {templateId === "R" ?
              <>
                <Button title='Leyenda' variant='primary' size='s' onClick={openLeyenda} />
                <FormGroup style={{ marginTop: "10px" }}>
                  <label>Formato</label>
                  <TextArea name="rawTemplate" {...register("rawTemplate")} />
                </FormGroup>
              </>
              : null
            }
            <FormGroup>
              <SwitchToggle {...register("autoPrint")} label={"Impresion automática"} id={"autoPrint"} />
            </FormGroup>

            {
              watchAutoPrint &&
              <>
                <FormGroup>
                  <label>Impresora por defecto</label>
                  <PrinterSelect {...register("printerId")} />
                </FormGroup>
                <FormGroup style={{ marginTop: "15px" }}>
                  <label style={{ marginBottom: "10px" }}>Impresoras por zonas</label><br />
                  <Button title='Agregar' variant='primary' size='s' onClick={() => PrinterZoneModal.addAction()} />
                  {zonePrinterList.data?.length > 0 &&
                    <FormGroup style={{ maxHeight: "500px", overflowY: "auto" }}>
                      <Table striped hover responsive size="sm">
                        <thead>
                          <tr>
                            <th>Zona</th>
                            <th></th>
                            <th>Impresora</th>
                            <th className="text-center">Modelo</th>
                            <th className="text-center">IP</th>
                            <th className="text-center">Acciones</th>
                          </tr>
                        </thead>
                        <tbody>
                          {zonePrinterList && zonePrinterList.data?.map((item) =>
                            <tr key={item.id}>
                              <td>
                                {zonesData.data &&
                                  zonesData.data.map(
                                    zone => zone.id === item.zoneId && zone.name
                                  )
                                }
                              </td>
                              <td><FaArrowRight /></td>
                              <td>
                                {printerData.data &&
                                  printerData.data.map(
                                    printer => printer.printerId === item.printerId && printer.printerName
                                  )
                                }
                              </td>
                              <td className="text-center">
                                {printerData.data &&
                                  printerData.data.map(
                                    printer => printer.printerId === item.printerId && printer.physicalDevice
                                  )
                                }
                              </td>
                              <td className="text-center">
                                {printerData.data &&
                                  printerData.data.map(
                                    printer => printer.printerId === item.printerId &&
                                    printer.type === 'DIRECT' ? printer.manualIp : printer.portName
                                  )
                                }
                              </td>

                              <td className="text-center">
                                <FlexLayout alignItems='center' justifyContent='center'>
                                  <Button variant={'light'} size='s' title={'Editar'}
                                    onClick={() => PrinterZoneModal.editAction(item.id)} />
                                  <Button variant={'danger'} size='s' title={'Eliminar'}
                                    onClick={() => PrinterZoneDeleteModal.openDeleteModal(item.id)} />
                                </FlexLayout>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </FormGroup>
                  }
                </FormGroup>
              </>
            }
          </>
        }
      </Card>

      <PageCardTitle title={'Módulo de canje'} />
      <Card padding>
        <SwitchToggle label={"Mostrar en el módulo de canje"} {...register("moduleShow")}
          id={"moduleShow"} /><br />
        <SwitchToggle label={"Habilitar botón de impresión"} {...register("modulePrint")} id={"modulePrint"} />
      </Card>

      <PageCardTitle title={'Expiración'} />
      <Card padding>
        {
          mode &&
          <ButtonBox>
            <Button title={"Expiración Manual"} variant={'primary'}
              onClick={() => experidAction({ couponTypeId: id })} />
            {/*<Button title={"Ver ultimas expiraciones"} variant={'primary'} size={'s'}*/}
            {/*        onClick={() => verexperidAction()}/>*/}
          </ButtonBox>
        }

        <FormGroup>
          <label>Periodicidad de expiración</label>
          <Select {...register("periodicidadExpirationId")}>
            <option key={1}>DESHABILITADO</option>
            <option key={2}>DIARIO</option>
            <option key={3}>SEMANAL</option>
          </Select>
        </FormGroup>
        <FormGroup>
          <label>Dia de expiración</label>
          <DayOfWeekSelect {...register("expirationDay")} />
        </FormGroup>
        <FormGroup>
          <label>Hora de expiración</label>
          <Input type={"time"} {...register("expirationHour")} placeholder={"HH:mm"} />
        </FormGroup>
      </Card>

      <PageCardTitle title={'Multiplica'} />
      <Card padding>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ flex: 1 }}>
            <ButtonBox>
              <Button title='Nuevo' variant='primary' size='s' onClick={() => goToPromoNew()} />
            </ButtonBox>
          </div>
          <div style={{ flex: 1 }}>
            <ButtonGroup
              value={modeMult}
              onChange={e => getModeMult(e)}
              data={[{
                text: <BsTable size={20} />,
                value: 'Tabla'
              }, { text: <BsFillCalendarCheckFill size={20} />, value: 'Calendario' }]}
            >
            </ButtonGroup>
          </div>
        </div>

        {
          modeMult === 'Tabla' ?
            <div style={{ paddingTop: '10px' }}>
              <CouponTypeMultiplicaTable id={id}></CouponTypeMultiplicaTable>
            </div>
            :
            <div style={{ paddingTop: '10px' }}>
              <AsyncBlock loading={loading2} error={error2}>
                {
                  multiplicas.length > 0 ?
                    <Calendar
                      localizer={localize}
                      selectable
                      events={multiplicas && generateData(multiplicas)}
                      defaultView={"week"}
                      startAccessor={(event) => {
                        return new Date(event.start)
                      }}
                      endAccessor={(event) => {
                        return new Date(event.end)
                      }}
                      style={{ height: 600 }}
                      views={["week"]}
                      messages={{
                        next: ">",
                        previous: "<",
                        today: "Hoy",
                        month: "Mes",
                        week: "Semana",
                        day: "Día",
                      }}
                      eventPropGetter={(event, start, end, isSelected) => ({
                        event,
                        start,
                        end,
                        isSelected,
                        style: { backgroundColor: event.color, color: 'black', border: "none" }
                      })}
                      onSelectEvent={event => routerEditMultiplier(event.id)}
                      timeslots={6}
                    />
                    :
                    <Card padding>
                      <EmptyState
                        title={'No hay multiplicadores'}
                        subtitle={'Agregue una nuevo multiplicador haciendo click en Nuevo.'}
                        icon={<RiCouponLine size={20} />}
                      />
                    </Card>
                }
              </AsyncBlock>
            </div>
        }
      </Card>

      <ButtonBox>
        <Button variant={'primary'} onClick={handleSubmit(onSubmit)}>Guardar</Button>
        {/*<Button variant={'danger'}>Eliminar cupon</Button>*/}
      </ButtonBox>

      <GenericModal2 {...wrapper1} />
      <GenericModal2 {...PrinterZoneModal.wrapper} />
      <DeleteGenericModal {...wrapper2} />
      <DeleteGenericModal {...PrinterZoneDeleteModal.wrapper} />
      <GenericModal2 {...wrapper3} />
      <ModalInfo {...wrapper4} />
      <ModalInfo size={'s'} {...wrapper5} />
    </>
  )
}

export default CouponTypeForm
