import React, {useCallback, useEffect, useMemo} from 'react';
import useFetch2 from "../../../../../hooks/useFetch2";
import {Apis} from "../../../../../util/Apis";
import {LoadingTableV2} from "../../../../../components/forms/Tables";
import Moment from "react-moment";
import {numberFormat} from "../../../../../components/util/NumberUtil";
import {FlexLayout} from "../../../../../components/flex/Flex";
import ReactTable from "../../../../../components/util/ReactTable";

const ArqueosList = ({id, setTitle}) => {

  const {loading, data, error} = useFetch2(Apis.CAJA + `/fondos/${id}/arqueos`, []);

  useEffect(() => {
    setTitle("Arqueos");
  }, []);

  const numberValue = useCallback(props => <>{numberFormat(props.renderValue())}</>, []);
  const createdValue = useCallback(props => <>{
    props.renderValue() ?
      <FlexLayout alignItems='center' justifyContent='center' gap='4px'>
        <Moment format={'YYYY-MM-DD hh:mm a'}>{props.renderValue()}</Moment>
      </FlexLayout>
      :
      <span>---</span>
  }</>, []);

  const columns = useMemo(() => [
    {
      header: 'ID',
      accessorKey: 'id',
      meta: {
        headerClassName: 'text-center',
        columnClassName: 'text-center',
      },
    },
    {
      header: 'Monto Inicial',
      accessorKey: 'iniAmount',
      meta: {
        headerClassName: 'text-right',
        columnClassName: 'text-right',
      },
      cell: numberValue
    },
    {
      header: 'Monto de Operación',
      accessorKey: 'operationAmount',
      meta: {
        headerClassName: 'text-right',
        columnClassName: 'text-right',
      },
      cell: numberValue
    },
    {
      header: 'Monto Final',
      accessorKey: 'finAmount',
      meta: {
        headerClassName: 'text-right',
        columnClassName: 'text-right',
      },
      cell: numberValue
    },
    {
      header: 'Monto de cierre',
      accessorKey: 'foundedAmount',
      meta: {
        headerClassName: 'text-right',
        columnClassName: 'text-right',
      },
      cell: numberValue
    },
    {
      header: 'Diferencia',
      accessorKey: 'diff',
      meta: {
        headerClassName: 'text-right',
        columnClassName: 'text-right',
      },
      cell: numberValue
    },
    {
      header: 'Fecha de cierre',
      accessorKey: 'createdAt',
      meta: {
        headerClassName: 'text-center',
        columnClassName: 'text-center',
      },
      cell: createdValue
    },
  ], [])

  return (
    <>
      <>
        <LoadingTableV2 loading={loading} error={error} data={data} noDataText={'Aún no hay arqueos'}>
          <ReactTable
            columns={columns}
            showColumnFilters={false}
            data={(data && data) || []}
            noData='No existen resultados para el filtro seleccionado'
            pageSize={10}
          />
        </LoadingTableV2>
      </>
    </>
  );
};

export default ArqueosList;
