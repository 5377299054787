import React, {useContext, useEffect} from 'react';
import {SiteContext} from "../../../common/context/SiteContext";
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import {useForm} from "react-hook-form";
import axios from "axios";
import {errorToast, successToast} from "../../../../components/layout/ToastStyles";
import AsyncBlock from "../../../../components/forms/AsyncBlock";
import Card from "../../../../components/forms/Card";
import FormGroup from "../../../../components/forms/FormGroup";
import Button from "../../../../components/forms/Button";
import {Input} from "@wargostec/react-components";

const Datacenter = () => {

  const {salaId} = useContext(SiteContext)
  const {loading, data, error, fetchData} = useFetch2(Apis.TGM + "/data-center", []);
  const {register, handleSubmit, reset, control,     formState: {errors}} = useForm();

  const update = (value) => {
    axios.put(Apis.TGM + '/data-center', value).then(
      res => {
        successToast()
        fetchData()
      }).catch(err => {
        errorToast()
    })
  }

  useEffect(() => {
    if (data) {
      reset(data)
    }
  }, [data])

  return (
    <>
      <AsyncBlock loading={loading} error={error}>
        <Card padding>
          <h4 style={{fontSize: '1.5rem', margin: '2rem 0', fontWeight: '600'}}>Configuración Mincetur</h4>
          <FormGroup>
            <label>Registro de sala</label>
            <Input {...register("registroSala", {required: true})} />
          </FormGroup>
          <FormGroup>
            <label>Usuario de Mincetur</label>
            <Input {...register("minceturUser", {required: true})} />
          </FormGroup>
          <FormGroup>
            <label>Contraseña de Mincetur</label>
            <Input type={'password'} {...register("minceturPassword", {required: true})} />
          </FormGroup>
          <FormGroup>
            <label>Semilla</label>
            <Input type={'password'} {...register("dcTramaSessionKey", {required: true})} />
          </FormGroup>
          <FormGroup>
            <label>Vector</label>
            <Input type={'password'} {...register("dcTramaIv", {required: true})} />
          </FormGroup>
          <FormGroup>
            <label>Hora de envío de tramas Tecnicas</label>
            <Input type={'time'} {...register("tecTime", {required: true})} />
          </FormGroup>
          <FormGroup>
            <label>Hora de envío de tramas Economicas</label>
            <Input type={'time'} {...register("ecoTime", {required: true})} />
          </FormGroup>
          <FormGroup>
            <label>Retraso de envío de trama</label>
            <Input {...register("eventDelay", {required: true})} />
          </FormGroup>
          <Button title={"Guardar"} variant={'primary'} onClick={handleSubmit(update)}/>
        </Card>
      </AsyncBlock>
    </>
  );
};

export default Datacenter;
