import React from "react";

const MeterText = ({meterId}) => {

  const showMeter = (meterId) => {
    switch (meterId) {
      case 1:
        return "APOSTADOS"
      case 2:
        return "GANADOS"
      case 3:
        return "JACKPOT"
      case 10:
        return "NETWIN"
      case 20:
        return "PUNTOS"
      case 12:
        return "APOSTADO MESAS"
      case 13:
        return "GANADO MESAS"
      default:
        return "n/a"
    }
  }

  return showMeter(meterId)

}

export default MeterText;
