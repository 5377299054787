import React, {useState} from 'react'
import styled from 'styled-components'
import Button from '../forms/Button'

const TabPanelContainer = styled.div`
  display: ${props => props.isActive ? 'block' : 'none'};
  width:100%;
`;

const TabContainer = styled.div`
  display: flex;
  flex-direction:row;
  gap:8px;
  flex-wrap:wrap;
  margin-bottom: 16px;
  background:#fff;
  padding:8px;
  border-radius:8px;
`;
const TabContent = styled.div`
  display: flex;
  flex-direction:row;
  flex-wrap:wrap;
  margin-bottom: 16px;
`;

const Tab = ({children, isActive, onClick, title}) => {
  return (
    <Button
      role="tab"
      size='s'
      variant={isActive ? 'dark': 'light'}
      aria-selected={isActive}
      onClick={onClick}
      style={{padding:'8px 16px'}}
    >
      {title}
    </Button>
  );
};

const TabPanel = ({ isActive, children }) => {
  return (
    <TabPanelContainer isActive={isActive}>
      {children}
    </TabPanelContainer>
  );
};

const Tabs = ({children}) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <div role="tablist">
      <TabContainer>
        {React.Children.map(children, (child, index) => {
          return React.cloneElement(child, {
            isActive: index === activeTab,
            onClick: () => handleTabClick(index),
          });
        })}
      </TabContainer>
      <TabContent>
        {React.Children.map(children, (child, index) => {
          return (
            <TabPanel key={index} isActive={index === activeTab}>
              {child.props.children}
            </TabPanel>
          );
        })}
      </TabContent>
    </div>
  );
};

export {Tabs, Tab};
