import React, {useEffect} from 'react'
import Form from "react-bootstrap/Form";
import FormGroup from "../../../../../components/forms/FormGroup";
import Input from "../../../../../components/forms/FormEl";
import {useForm} from "react-hook-form";
import OwnerSelect from "../../../../common/components/selects/OwnerSelect";

const RelacionAsociadosForm = ({value, onSubmit, setAction}) => {

  const {register, handleSubmit, reset} = useForm();

  useEffect(() => {
    if (value) {
      reset(value)
    }
  }, [value])

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit))
  }, [])

  return (
    <Form>
      <FormGroup>
        <label>Propietario:</label>
        <OwnerSelect {...register("ownerId")}/>
      </FormGroup>
      <FormGroup>
        <label>Porcentaje(%):</label>
        <Input placeholder={"Ejm:40,50,60  Sin el signo %"} type="text" {...register("percentage")}/>
      </FormGroup>
      <FormGroup>
        <label>Tipo de contrato:</label>
        <Input type="text" {...register("tipoContrato")}/>
      </FormGroup>
      <FormGroup>
        <label>Fecha de contrato:</label>
        <Input type="date" {...register("contractDate")}/>
      </FormGroup>
    </Form>
  )

}

export default RelacionAsociadosForm
