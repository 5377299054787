import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {SiteContext} from "../../../../common/context/SiteContext";
import useFetch2 from "../../../../../hooks/useFetch2";
import {Apis} from "../../../../../util/Apis";
import {useForm} from "react-hook-form";
import Slidebar from "../../../../common/layout/Slidebar";
import SalaIdInput from "../../../../common/components/SalaIdInput";
import FormGroup from "../../../../../components/forms/FormGroup";
import {Select} from "../../../../../components/forms/FormEl";
import AsyncBlock from "../../../../../components/forms/AsyncBlock";
import ReactTable from "../../../../../components/util/ReactTable";
import PageContainerFull from "../../../../../components/layout/PageContainerFull";
import {Title, Button} from "@wargostec/react-components";
import PageSection from "../../../../../components/layout/PageSection";
import Moment from "react-moment";
import CardOverview, {CardOverviewContainer} from "../../../../../components/layout/CardOverview";
import {HiOutlineSlotMachine} from "../../../../../components/assets/icons/slot-machine";
import {RiErrorWarningFill} from "react-icons/ri";
import {FlexLayout} from "../../../../../components/flex/Flex";
import SegmentCircleV2 from "../../../../crm/pages/clientes/customers/SegmentCircleV2";

const Devices = () => {

  const {salaId} = useContext(SiteContext)
  const [slideOpen, setSlideOpen] = useState(false)

  let defaultParams = {salaId: salaId}
  const [params, setParams] = useState(defaultParams)
  const {data, loading, error, fetchData} = useFetch2(Apis.TGM + '/pangolin', [], params);
  const {register, handleSubmit} = useForm({defaultValues: defaultParams});

  const getData = (params) => {
    setParams(params)
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  const dateFormat = useCallback(props => props.renderValue() ?
    <Moment format={'YYYY-MM-DD hh:mm'}>{props.renderValue()}</Moment> : '---', []);

  const nameValue = useCallback(props => props.renderValue() ? props.renderValue() : '---', []);

  const statusValue = (props) => {
    const online = props.row.original.online
    const confId = props.row.original.confId

    return (
      <span>
        <FlexLayout justifyContent="center" gap="16px">
              <SegmentCircleV2 color={online ? '#37ba32' : '#D9D9D9'}/>
              <SegmentCircleV2 color={confId ? '#37ba32' : '#D9D9D9'}/>
        </FlexLayout>
      </span>
    );
  };

  const columns = useMemo(
    () => [
      {
        header: 'Blue (SMIB)',
        accessorKey: 'sn',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        },
      },
      {
        header: 'Maquina',
        accessorKey: 'machine',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        },
        cell: nameValue
      },
      {
        header: 'Estado',
        accessorKey: 'status',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: statusValue
      },
      {
        header: 'Servidor',
        accessorKey: 'serverName',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        },
        cell: nameValue
      },
      {
        header: 'Fecha de creación',
        accessorKey: 'createdAt',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        },
        cell: dateFormat
      },
      {
        header: 'Ultima conexión',
        accessorKey: 'lastPing',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        },
        cell: dateFormat
      },
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , []
  )

  return (
    <PageContainerFull>

      <Slidebar visible={slideOpen} onHide={() => setSlideOpen(false)}>
        <div style={{padding: '16px'}}>
          <FormGroup>
            <SalaIdInput {...register('salaId')} />
            <label>Estado</label>
            <Select type='text' {...register('enabled')} defaultValue={true}>
              <option value={true}>Activos</option>
              <option value={false}>Inactivos</option>
            </Select>
          </FormGroup>
          <Button variant={'primary'} text='Buscar' onClick={handleSubmit(getData)}/>
        </div>
      </Slidebar>

      <PageSection>
        <Title text="Dispositivos" type={'page'}/>

        <CardOverviewContainer gridColumns='repeat(3, 1fr)'>
          <CardOverview
            title={data && data.total}
            subtitle='Total'
            icon={<HiOutlineSlotMachine size={20}/>}
          />
          <CardOverview
            title={data && data.connected}
            subtitle='En línea'
            icon={<RiErrorWarningFill size={20}/>}
            bgcolor={'#FFC107'}
          />
          <CardOverview
            title={data && data.unassigned}
            subtitle='Sin asignar'
            icon={<RiErrorWarningFill size={20}/>}
            bgcolor={'#FFC107'}
          />
        </CardOverviewContainer>

        <AsyncBlock loading={loading} error={error}>
          <ReactTable
            columns={columns}
            data={(data && data.data) || []}
            filterOnclick={() => setSlideOpen(!slideOpen)}
            loading={loading}
            pageSize={100}
            sortArray={[{id: 'id', asc: false}]}
            noData='No existen resultados para el filtro seleccionado'
          />
        </AsyncBlock>
      </PageSection>
    </PageContainerFull>
  );
};

export default Devices;
