import React, {useContext, useEffect, useState} from 'react'
import PageSection from "../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import Table from "react-bootstrap/Table";
import PageContainerFull from "../../../../components/layout/PageContainerFull";
import {Apis} from "../../../../util/Apis";
import {useParams} from "react-router";
import {LoadingTableV2} from "../../../../components/forms/Tables";
import useFetch2 from "../../../../hooks/useFetch2";
import {Col, Row} from "../../../../components/flex/FlexLayout";
import {Flex} from "../../../../components/flex/Flex";
import StadisticTitle from "../../../../components/layout/StadisticTitle";
import StadisticValue from "../../../../components/layout/StadisticValue";
import Card from "../../../../components/forms/Card";
import DepositHistory from './DepositHistory';
import ModalInfo from '../../../../modals/ModalInfo';
import Button, {ButtonBox} from "../../../../components/forms/Button";
import useModal from "../../../../modals/useModal";
import CustomerLink from "../../../../components/util/CustomerLink";
import {TfiReload} from "react-icons/tfi";

const AnforaParticipantes = () => {

  const {id} = useParams()
  const [total, setTotal] = useState(0);

  let defaultParams = {anforaId: id}

  const {loading, data, fetchData, error} = useFetch2(Apis.PC + '/coupon/deposit-report', [], defaultParams);

  const {wrapper: wrapper1, someAction: showDeposits} = useModal({
    component: <DepositHistory/>
  })

  useEffect(() => {
    let total2 = 0
    if (data) {
      data.forEach(item => {
        total2 += item.quantity;
      });
    }

    setTotal(total2)
  }, [data])

  const getProb = (depositados) => {
    if (total > 0) {
      return ((depositados / total) * 100).toFixed(2).toString() + '%'
    } else {
      return ''
    }
  }

  return (
    <PageContainerFull>
      <PageSection>
          <Title text="Participantes"/>
        <ButtonBox>
          <Button variant={'primary'} onClick={() => fetchData()}>
            <TfiReload/>
          </Button>
        </ButtonBox>
      </PageSection>

      <PageSection>
        <Card padding>
          <Row>
            <Col>
              <Flex flexDirection={'column'}>
                <StadisticTitle>Participantes</StadisticTitle>
                <StadisticValue>{data && data.length}</StadisticValue>
              </Flex>
            </Col>
            <Col>
              <Flex flexDirection={'column'}>

                <StadisticTitle>Cupones depositados</StadisticTitle>
                <StadisticValue>
                  {total}
                </StadisticValue>
              </Flex>
            </Col>
          </Row>
        </Card>

        <Card>
          <LoadingTableV2 loading={loading} error={error} data={data} noDataText={'Aun no hay participantes'}>
            <Table striped hover size={'sm'}>
              <thead>
              <tr>
                <th>Cliente</th>
                <th>Depositados</th>
                <th>Probabilidad</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              {data && data.map(item =>
                <tr key={item.customerId}>
                  <td><CustomerLink item={item}/></td>
                  <td>{item.quantity}</td>
                  <td>{getProb(item.quantity)}</td>
                  <td>
                    <ButtonBox>
                      <Button variant={'light'} size='xs'
                              onClick={() => showDeposits({customerId: item.customerId, anforaId: id})}
                              title={'Ver cupones'}/>
                    </ButtonBox>
                  </td>
                </tr>
              )}
              </tbody>
            </Table>
          </LoadingTableV2>
        </Card>
      </PageSection>
      <ModalInfo {...wrapper1}/>
    </PageContainerFull>
  )
}

export default AnforaParticipantes;
