import {useEffect, useState, useContext} from 'react'
import { SiteContext } from '../../../../common/context/SiteContext'
import { Apis } from '../../../../../util/Apis'
import Loader1 from '../../../../common/components/Loader1'
import Button from '../../../../../components/forms/Button'
import moment from 'moment'
import axios from 'axios'

const SuggestDeleteMachine = ({setTitle}) => {
  const {salaId} = useContext(SiteContext)

  const defaultParams = {salaId: salaId}

  const [params, setParams] = useState(defaultParams)
  const [loading, setLoading] = useState(false)
  const [machines, setMachines] = useState([])

  const fetchData = async () => {
    try {
      setLoading(true)
      const {data} = await axios.get(Apis.TGM + '/machines/suggest-delete-machine', {params})
      setMachines(data)
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    setTitle("Máquinas sin producción")
  }, [])

  useEffect(() => {
    if (params) fetchData()
  }, [params])

  const disableMachine = async (machine) => {
    let params = {
      salaId: machine.salaId,
      confId: machine.confId,
      lastDate: machine.last_date
    }

    try {
      const {data} = await axios.get(Apis.TGM + '/machines/disabled-machine', {params})
      if(data.success === 0){
        let machinesAux = machines.filter(m => m.id !== machine.id)
        setMachines(machinesAux)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      {!loading ? 
        <div>
        {machines && machines.length>0 ? 
          <>
            {
              machines.map(d => (
                <div key={d.id} style={{flexDirection: "row", display: "flex", marginBottom: "8px"}}>
                  <div style={{paddingRight: "4px"}}>- La máquina <label style={{fontWeight: "bold"}}>{d.machine}</label> no tiene producción <label style={{fontWeight: "bold"}}>{moment(d.last_date, "YYYY-MM-DD").fromNow()}</label>.</div>
                  <Button variant={'danger'} size={'s'} onClick={()=>disableMachine(d)}>
                    Desactivar
                  </Button>
                </div>
              ))
            }
          </>
          : <div>No hay máquinas sin producción en los últimos <label style={{fontWeight: "bold"}}>30 días</label>.</div>}
        </div> : <Loader1/>}
    </>
  )
}

export default SuggestDeleteMachine