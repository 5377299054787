import {motion} from 'framer-motion'
import React from 'react'
import {darkenColor, hexToRgb} from '../../../../util/DarkColor';

const OnlinePositionsLayout = ({
                                 viewBox,
                                 background,
                                 positions,
                                 setSelectedMachine,
                                 selectedMachine,
                                 setDialogOpen,
                                 condition,
                                 selectedGroup
                               }) => {

  const handleCircleClick = (item) => {
    setSelectedMachine(item);
    setDialogOpen(true);
  };

  const getFill = (item, selectedMachine) => {
    if (item.data === null) {
      return '#d4d4d4'; // gris
    } else if (selectedMachine?.confId === item.data.confId) {
      return '#22c55e'; // verde 
    } else {
      return '#3b82f6'; // azul
    }
  };

  const getFillColor = (item, selectedMachine) => {
    const isSelected = item.id === selectedMachine?.positionId;
    const groupSelected = selectedGroup?.some(groupItem => groupItem.positionId === item.id);

    if (isSelected || groupSelected) {
      return '#a855f7'; // Cambia el color si la máquina está seleccionada
    }

    let fillColor = '#d4d4d4';

    if (item.data) {
      if (!item.data.serverLink) {
        fillColor = '#dc2626'; // Rojo si no hay enlace de servidor
      } else if (!item.data.machineLink) {
        fillColor = '#ffb8b8'; // rosadi si hay conexion al server pero no a la maquina
      } else if (item.data.currentCredits > 0) {
        fillColor = '#facc15'; // Amarillo si hay créditos actuales
      } else {
        fillColor = '#22c55e'; // Verde si no hay créditos actuales
      }
    }

    return fillColor;
  };

  const getFillColorOnline = (item) => {
    if (item.data === null || item.data.apProm === null) {
      return '#d4d4d4';
    }

    const apProm = item.data.apProm;

    switch (true) {
      case apProm < 0.4:
        return '#3b82f6';
      case apProm >= 0.4 && apProm < 1:
        return '#facc15';
      case apProm >= 1 && apProm < 5:
        return '#ef4444';
      case apProm >= 5:
        return '#b91c1c';
      default:
        return '#d4d4d4';
    }
  };


  const applyCondition = (item, condition) => {

    const {data} = item;
    const isPlaying = data?.linked && data?.currentCredits > 0;
    const machineSelected = selectedMachine?.confId === item.data?.confId;
    const groupSelected = selectedGroup?.some(groupItem => groupItem.positionId === item.id);

    switch (condition) {
      case 'heatmap':
        return (
          <>
            <motion.circle
              id={item.id}
              cx={item.cx}
              cy={item.cy}
              r="30"
              fill={item.hexColor}
              className="heatmap-filter"
              initial={{scale: 1}}
              animate={
                item.data && (machineSelected || groupSelected)
                  ? {opacity: [1, 0.5, 1], scale: [1, 1.4, 1]}
                  : {}
              }
              transition={
                item.data && (machineSelected || groupSelected)
                  ? {duration: 2, repeat: Infinity}
                  : {}
              }
              style={
                typeof window !== "undefined"
                  ? {opacity: 0.8, mixBlendMode: "soft-light"}
                  : {}
              }
            />
            <motion.circle
              id={item.id}
              cx={item.cx}
              cy={item.cy}
              r="15"
              initial={{scale: 1}}
              animate={
                item.data && (machineSelected || groupSelected)
                  ? {opacity: [1, 0.5, 1], scale: [1, 1.4, 1]}
                  : {}
              }
              transition={
                item.data && (machineSelected || groupSelected)
                  ? {duration: 2, repeat: Infinity}
                  : {}
              }
              fill={item.data === null ? '#d4d4d4' : item.hexColor}
              style={
                typeof window !== "undefined"
                  ? {
                    stroke: darkenColor(hexToRgb(item.hexColor)),
                    strokeWidth: "2px",
                  }
                  : {}
              }
            />
          </>
        );
      case 'config':
        return (
          <>
            <motion.circle
              id={item.id}
              cx={item.cx}
              cy={item.cy}
              r="15"
              initial={{scale: 1}}
              animate={item.data && machineSelected ?
                {opacity: [1, 0.5, 1], scale: [1, 1.4, 1]} : {}
              }
              transition={item.data && machineSelected ?
                {duration: 1, repeat: Infinity} : {}
              }
              fill={getFill(item, selectedMachine)}
            />
            <motion.text
              id={`textm${item.id}`}
              x={item.cx}
              y={item.cy}
              className="cls-2"
            >
              {item.data && item.data.machine}
            </motion.text>
          </>
        );
      case 'online':

        return (
          <>
            <motion.circle
              id={item.id}
              cx={item.cx}
              cy={item.cy}
              r="15"
              initial={{scale: 1}}
              animate={
                item.data && machineSelected
                  ? {opacity: [1, 0.5, 1], scale: [1, 1.4, 1]} : {}
              }
              transition={
                item.data && machineSelected
                  ? {duration: 1, repeat: Infinity} : {}
              }
              fill={getFillColorOnline(item)}
            />
            <motion.text
              id={`textm${item.id}`}
              x={item.cx}
              y={item.cy}
              className="cls-2"
            >
              {item.data === null ? '' : item.data.rejected ? '❌' : item.data.text}
            </motion.text>
          </>
        );
      case 'meterOnline':
        return (
          <>
            <motion.circle
              id={item.id}
              cx={item.cx}
              cy={item.cy}
              r="15"
              animate={
                (isPlaying || (selectedMachine && selectedMachine.positionId === item.id))
                  ? {opacity: [1, 0.5, 1], scale: [1, 1.4, 1]} : {}
              }
              transition={
                (isPlaying || (selectedMachine && selectedMachine.positionId === item.id))
                  ? {duration: 1, repeat: Infinity} : {}
              }
              fill={getFillColor(item, selectedMachine)}
            />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div style={{
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '16px'
    }}>
      <motion.svg xmlns="http://www.w3.org/2000/svg" viewBox={viewBox} width="100%" height="auto"
                  preserveAspectRatio="xMidYMid meet">
        <defs>
          <style>{`
            .cls-2 {font-size: 16px; fill: #fff; font-family: Arial; font-weight: 700; text-anchor: middle; alignment-baseline: central;}
            .heatmap-filter {
              filter: url('#heatmap');
            }
          `}</style>
          <filter id="heatmap">
            <feGaussianBlur in="SourceGraphic" stdDeviation="6"/>
          </filter>
        </defs>
        <motion.g id='maquinas'>
          {positions?.map(item => {
            return (
              <motion.g 
                key={item.id} 
                style={{cursor: 'pointer'}} 
                onClick={() => handleCircleClick(item)}
              >
                {applyCondition(item, condition)}
              </motion.g>
            )
          })}
        </motion.g>
        {background}
      </motion.svg>
    </div>
  )
}

export default OnlinePositionsLayout
