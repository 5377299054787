import React, {useEffect} from "react";
import {Apis} from "../../../../../util/Apis";
import axios from "axios";
import TipoListaLudopatiaForm from "./TipoListaLudopatiaForm";
import useFetch2 from "../../../../../hooks/useFetch2";

const TipoListaLudopatiaEdit = ({id, setAction, setTitle}) => {

  const {data} = useFetch2(Apis.BLACK + '/ban-list/' + id)

  function update(params) {
    //se podria crear un componente <Id/> para guardar esta propiedad
    params.id = id
    return axios.put(Apis.BLACK + '/ban-list/' + id, params)
  }

  useEffect(() => {
    setTitle("Edición de tipo de lista")
  }, [])

  return (
    <TipoListaLudopatiaForm value={data} onSubmit={update} setAction={setAction}/>
  )
}

export default TipoListaLudopatiaEdit
