import React, {useContext, useEffect, useState} from 'react';
import PageSection from "../../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import Button, {ButtonBox} from "../../../../../components/forms/Button";
import Card from "../../../../../components/forms/Card";
import Table from "react-bootstrap/Table";
import PageContainer from "../../../../../components/layout/PageContainer";
import {LoadingTableV2} from "../../../../../components/forms/Tables";
import useFetch2 from "../../../../../hooks/useFetch2";
import {Apis} from "../../../../../util/Apis";
import {PageBackLabel} from "../../../../../components/layout/PageBackLabel";
import {useHistory} from "react-router";
import {useForm} from "react-hook-form";
import {SiteContext} from "../../../../common/context/SiteContext";
import {FormBlock} from "../../../../../components/forms/FormGroup";
import useSortableData, {ColumnSortable, getClassNamesFor} from "../../../../../hooks/useSortableData";
import Input from "../../../../../components/forms/FormEl";
import axios from "axios";
import SwitchToggle from "../../../../../components/layout/SwitchToggle";

const MachinesSetNew = () => {

  const {salaId} = useContext(SiteContext)
  const history = useHistory()
  const {loading, data, error} = useFetch2(Apis.TGM + '/slot-model', []);

  let defaultParams = {salaId: salaId, enabled: true}

  const {register, handleSubmit} = useForm({defaultValues: defaultParams})

  const {items, requestSort, sortConfig} = useSortableData(data);

  const [enabled, setEnabled] = useState(true)
  const [list, setList] = useState([]);

  const onCreate = (params) => {
    params.salaId = salaId
    params.machines = list
    axios.post(Apis.TGM + '/machine-groups', params)
      .then(res => {
        history.push('/tgm/machine-set')
      }).catch(err => {
      console.log(err)
    })
  }

  const addMachine = (machineId, machineName, machineType, enabled) => {
    let indice = list.findIndex(machines => machines.machineId === machineId)
    if (indice !== -1) {
      list.splice(indice, 1)
    } else {
      let newList = list;
      newList.push({
        machineId: machineId,
        machineName: machineName,
        machineType: machineType,
        enabled: enabled
      });
      setList(newList);
    }
    console.log(list)
  }

  useEffect(() => {
    console.log('List', list)
  }, [list])

  return (
    <PageContainer>
      <PageSection>
        <PageBackLabel text='Grupo de Maquinas' onClick={() => history.push('/tgm/machine-set')}/>
          <Title text={"Seleccionar Maquinas"}/>
      </PageSection>

      <PageSection>
        <FormBlock>
          <label>Nombre</label>
          <Input {...register("name")}/>
        </FormBlock>
      </PageSection>

      <PageSection>
        <Card padding>
          <LoadingTableV2 loading={loading} error={error} data={data}>
            <Table striped hover>
              <thead>
              <tr>
                <th>
                  <ColumnSortable type="button" onClick={() => requestSort('id')}
                                  className={getClassNamesFor('id', sortConfig)}>
                    Id
                  </ColumnSortable>
                </th>
                <th>
                  <ColumnSortable type="button" onClick={() => requestSort('name')}
                                  className={getClassNamesFor('name', sortConfig)}>
                    Maquina
                  </ColumnSortable>
                </th>
                <th>
                  Juego
                </th>
                <th>
                  Estado
                </th>
              </tr>
              </thead>
              <tbody>
              {items.map((item, index) =>
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.name}</td>
                  <td>{item.machineType?.name}</td>
                  <td>
                    <SwitchToggle label={"Agregado"} name={"agregado" + item.id}
                                  id={"agregado" + item.id}
                                  onSwitch={() => addMachine(item.id, item.name, item.machineType?.name, enabled)}/>
                  </td>
                </tr>
              )}
              </tbody>
            </Table>
          </LoadingTableV2>
        </Card>
      </PageSection>

      <PageSection>
        <ButtonBox>
          <Button variant={'primary'} onClick={handleSubmit(onCreate)}>Guardar</Button>
        </ButtonBox>
      </PageSection>
    </PageContainer>
  );
};

export default MachinesSetNew;
