import React from "react";
import {Apis} from "../../../../../util/Apis";
import JackpotForm from "./JackpotForm";
import axios from "axios";
import PageSection from "../../../../../components/layout/PageSection";
import {PageBackLabel} from "../../../../../components/layout/PageBackLabel";
import {Title} from "@wargostec/react-components";
import PageContainer from "../../../../../components/layout/PageContainer";
import {useHistory} from "react-router";
import {errorToast, successToast} from "../../../../../components/layout/ToastStyles";
import useFetch2 from "../../../../../hooks/useFetch2";

const JackpotNew = () => {

  const {loading, data, fetchData, error} = useFetch2(Apis.BINGO + `/range-increase`, []);
  const history = useHistory()
  const onCreate = (params) => {
    params.jackpot.actualAmount = params.jackpot.baseAmount
    params.jackpot.actualBall = params.jackpot.ballRaise ? params.jackpot.startBall : params.jackpot.winBall
    axios.post(Apis.BINGO + '/jackpot/ranges', params).then(res => {
      history.push('/bingos/jackpots')
      successToast("Jackpot guardada correctamente")
    }).catch(err => {
      errorToast()
    })
  }

  return (
    <PageContainer>
      <PageSection style={{marginBottom: "20px"}}>
        <PageBackLabel text='Jackpots' onClick={() => history.goBack()}/>
          <Title text="Nuevo jackpot"/>
      </PageSection>

      <PageSection>
        <JackpotForm value={data && {"listRanges" : data}} onSubmit={onCreate} setAction={onCreate}/>
      </PageSection>

    </PageContainer>
  )
}

export default JackpotNew
