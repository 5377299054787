import {Apis} from "../../../../util/Apis";
import React, {useEffect} from "react";
import axios from "axios"
import useFetch2 from "../../../../hooks/useFetch2";
import ProfileForm from "./ProfileForm";


const EditProfile = ({id, setAction, setTitle}) => {

  const {loading, data} = useFetch2(Apis.USER + `/user-group/${id}`, []);

  const save = (params) => {
    return axios.put(Apis.USER + `/user-group/${id}`, params);
  }

  useEffect(() => {
    setTitle("Editar Perfil")
  }, [])

  return (
    <ProfileForm value={data && data} onSubmit={save} setAction={setAction}/>
  )

}

export default EditProfile
