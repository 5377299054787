import React, {useCallback, useContext, useMemo, useState} from 'react';
import Button, {ButtonBox} from "../../../../components/forms/Button";
import {LoadingTableV2} from "../../../../components/forms/Tables";
import Table from "react-bootstrap/Table";
import GenericModal2 from "../../../../modals/GenericModal2";
import DeleteGenericModal from "../../../../modals/DeleteGenericModal";
import {SiteContext} from "../../../common/context/SiteContext";
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import useEditModal from "../../../../modals/useEditModal";
import axios from "axios";
import useDeleteModal from "../../../../modals/useDeleteModal";
import MidasEdit from "./MidasEdit";
import MidasNew from "./MidasNew";
import Card from "../../../../components/forms/Card";
import FormGroup from "../../../../components/forms/FormGroup";
import SecComponent from "../../../../components/layout/SecComponent";
import ReactTable from "../../../../components/util/ReactTable";
import AsyncBlock from "../../../../components/forms/AsyncBlock";

const MidasList = () => {
  const { salaId } = useContext(SiteContext)

  const {
    data,
    loading,
    error,
    fetchData
  } = useFetch2(Apis.SALAS + '/sala-hub?salaId=' + salaId, []);

  const { wrapper: wrapper1, addAction, editAction } = useEditModal({
    editComponent: <MidasEdit />,
    addComponent: <MidasNew />,
    onRefresh: fetchData
  })

  const deleteElement = id => axios.delete(Apis.SALAS + '/sala-hub/' + id)

  const { wrapper: wrapper2, openDeleteModal } = useDeleteModal({
    text: '¿Desea eliminar esta ip?',
    action: deleteElement,
    onRefresh: fetchData
  })

  const actionsValue = useCallback(props => <>{
    <SecComponent code={'p88gnsxg'}>
      <Button
        variant='dark'
        size={'xs'}
        title={'Editar'}
        onClick={() => editAction(props.row.original.id)}
      />
      <Button
        variant='danger'
        size={'xs'}
        title={'Eliminar'}
        onClick={() => openDeleteModal(props.row.original.id)}
      />
    </SecComponent>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }</>, []);

  const columns = useMemo(
    () => [
      {
        header: 'IP',
        accessorKey: 'url',
        meta: {
          headerClassName:'text-center',
          columnClassName:'text-center',
        },
      },
      {
        header: 'Acciones',
        accessorKey: 'button',
        meta: {
          headerClassName:'text-center',
          columnClassName:'text-center',
        },
        enableGlobalFilter:false,
        enableSorting:false,
        cell: actionsValue
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Card padding>
      <h4 style={{ fontSize: '1.5rem', margin: '2rem 0', fontWeight: '600' }}>Midas</h4>
      <FormGroup >
      <Button variant={'primary'} onClick={() => addAction()} style={{marginBottom:'2rem'}}>
        <span>Nuevo</span>
      </Button>

        <AsyncBlock loading={loading} error={error}>
          <ReactTable
            columns={columns}
            data={data || []}
            customSearch
            noData='No existen resultados para el filtro seleccionado'
            pageSize={10}
          />
        </AsyncBlock>
      </FormGroup>
      <GenericModal2 {...wrapper1} />
      <DeleteGenericModal {...wrapper2} />
    </Card>
  );
};

export default MidasList;
