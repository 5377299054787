import React from 'react'
import {Select} from "../../../../components/forms/FormEl";

const CardTypeSelect = React.forwardRef((props, ref) => {

  return (
    <Select ref={ref} {...props}>
      <option key={1} value={1}>COMPRA</option>
      <option key={2} value={2}>VENTA</option>
    </Select>
  )
})

export default CardTypeSelect
