import React, {useEffect} from "react";
import FormGroup from "../../../../../components/forms/FormGroup";
import { useForm} from "react-hook-form";
import Input from "../../../../../components/forms/FormEl";


const SerieForm = ({onSubmit, setAction}) => {

  const {register, handleSubmit} = useForm();

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit))
  },[])

  return (
    <React.Fragment>
      <div style={{display: 'flex', flexDirection: 'row', gap: 10}}>
        <FormGroup>
          <label>Desde</label>
          <Input type='number' {...register("from")}/>
        </FormGroup>
        <FormGroup>
          <label>Hasta</label>
          <Input type='number' {...register("to")}/>
        </FormGroup>
      </div>
    </React.Fragment>
  )
}

export default SerieForm
