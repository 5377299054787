import React, {useContext, useEffect} from "react";
import axios from "axios";
import {Apis} from "../../../../util/Apis";
import AddFundsForm from "./AddFundsForm";

const AddFundsNew = ({setAction, setTitle}) => {

  const save = async (params) => {
    params.crmId = params.customerId
    await axios.post(Apis.RGS + '/customers/add-funds', params)
  }

  useEffect(() => {
    setTitle("Añadir Fondos")
  }, [])

  return (
    <AddFundsForm value={{}} onSubmit={save} setAction={setAction}/>
  )
}

export default AddFundsNew;
