import React from 'react'
import {Select} from "../../../../components/forms/FormEl";
import ButtonGroup from "../../../../components/forms/ButtonGroup";

const MachineTypeSelect = React.forwardRef((props, ref) => {

  return (
    <Select ref={ref} {...props}>
      <option value={1}>MAQUINAS</option>
      <option value={2}>RULETA</option>
      <option value={3}>DERBY</option>
      <option value={4}>MESAS</option>
      <option style={{display: "none"}} selected value={""}/>
    </Select>
  )
})

const MachineTypeSelectV2 = React.forwardRef((props, ref) =>
   (
    <ButtonGroup
      value = {props.value}
      onChange={props.onChange}
      data={[{text : 'MAQUINA', value : 1},{text : 'RULETA', value : 2},{text : 'DERBY', value : 3},{text : 'MESAS', value : 4}]}
    />
  )
)

export default MachineTypeSelect;

export {MachineTypeSelectV2}
