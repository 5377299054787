import React, {useEffect} from 'react';
import {useForm} from "react-hook-form";
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import axios from "axios";
import {errorToast, successToast} from "../../../../components/layout/ToastStyles";
import FormGroup from "../../../../components/forms/FormGroup";
import Input, {SelectV2} from "../../../../components/forms/FormEl";
import Button from "../../../../components/forms/Button";
import AsyncBlock from "../../../../components/forms/AsyncBlock";
import Card from "../../../../components/forms/Card";
import SwitchToggle from "../../../../components/layout/SwitchToggle";

const ParametrosLogueo = () => {

  const {register, handleSubmit, reset} = useForm();
  const {loading, data, error, fetchData} = useFetch2(Apis.PC + "/config-sala", []);

  const update = (value) => {
    axios.put(Apis.PC + "/config-sala", value)
      .then(res => {
        successToast()
        fetchData()
      }).catch(err => {
      errorToast();
    })
  };

  useEffect(() => {
    if (data) {
      reset(data)
    }
  }, [data])

  return (
    <>
      <AsyncBlock loading={loading} error={error}>
        <Card padding>
          <h4 style={{fontSize: '1.5rem', margin: '2rem 0', fontWeight: '600'}}>Configuración de tareas de
            impresión</h4>
          <FormGroup>
            <label>Cantidad máxima de tareas impresión</label>
            <Input {...register("maxCouponsPerPrintJob")} />
          </FormGroup>
          <FormGroup>
            <label>Habilitar sesiones en paralelo por cliente</label>
            <SwitchToggle
              {...register("enabledConcurrentCustomerSessions")}
            />
          </FormGroup>
          <FormGroup>
            <label>Segundos para unificar sesiones en modo automatico</label>
            <Input {...register("secondsToJoinSesions")} />
          </FormGroup>
          <FormGroup>
            <label>Flujo de operación de tarjetas</label>
            <SelectV2 {...register('cardOperationWorkflow')} options={
              [
                {id: 0, name: "TARJETA"},
                {id: 1, name: "LLAVERO"},
                {id: 2, name: "INTEGRACIÓN"},
              ]
            }/>
          </FormGroup>
          <Button title={"Guardar"} variant={'primary'} onClick={handleSubmit(update)}/>
        </Card>
      </AsyncBlock>
    </>
  );
};

export default ParametrosLogueo;
