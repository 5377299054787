import React, {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import useFetch2 from "../../../../../hooks/useFetch2";
import {Apis} from "../../../../../util/Apis";
import Slidebar from "../../../../common/layout/Slidebar";
import SalaIdInput from "../../../../common/components/SalaIdInput";
import FormGroup from "../../../../../components/forms/FormGroup";
import {DatePickerCustom} from "../../../../../components/forms/FormEl";
import PageSection from "../../../../../components/layout/PageSection";
import {Button, Title} from "@wargostec/react-components";
import AsyncBlock from "../../../../../components/forms/AsyncBlock";
import PageContainer from "../../../../../components/layout/PageContainer";
import {getCasinoDay} from "../../../../../components/util/CasinoDates";
import DateFilter2 from "../../../../../components/datefilter/DateFilter2";
import useToggle from "../../../../../hooks/useToggle";
import CardOverview, {CardOverviewContainer} from "../../../../../components/layout/CardOverview";
import {numberFormat} from "../../../../../components/util/NumberUtil";
import {HiOutlineBanknotes} from "react-icons/hi2";
import {LuCoins, LuTicket} from "react-icons/lu";
import {useHistory} from "react-router";
import TableFondoReport from "./TableFondoReport";
import {Tab, Tabs} from "../../../../../components/tabs/Tabs";

const FondoReport = () => {

  const history = useHistory()
  const querystring = window.location.search
  const query = new URLSearchParams(querystring)
  let iniQueryParams = query.get('ini')

  const casinoDay = getCasinoDay()
  if (!iniQueryParams) iniQueryParams = casinoDay

  let defaultParams = {period: iniQueryParams}
  const [fechas, setFechas] = useState(defaultParams)

  const {register, handleSubmit} = useForm({
    defaultValues: defaultParams
  });

  const {loading, data, fetchData, error} = useFetch2(Apis.CAJA + '/fondos/report', [], fechas);

  const {loading: loading2, data: lista, fetchData: fetchData2, error: error2} = useFetch2(
    Apis.CAJA + '/fondos/report',
    [],
    {active: true}
  );

  const {isOpen: slideOpen, toggler} = useToggle({});

  const updateFechas = (newFechas) => {
    setFechas(newFechas);
    const params = new URLSearchParams(newFechas);
    history.replace({search: params.toString()});
  };

  useEffect(() => {
    handleSearch()
    // eslint-disable-next-line
  }, [fechas])

  const handleSearch = () => {
    fetchData()
  }

  return (
    <PageContainer>
      <Slidebar visible={slideOpen} onHide={toggler}>
        <div style={{padding: '16px'}}>
          <SalaIdInput {...register('salaId')} />
          <FormGroup>
            <label>Periodo</label>
            <DatePickerCustom {...register('period')} />
          </FormGroup>
          <Button
            variant={'primary'}
            title='Buscar'
            onClick={handleSubmit(fetchData)}
          />
        </div>
      </Slidebar>

      <PageSection>
        <Title text="Resumen de fondos" type={'page'}/>

        <DateFilter2
          showWeek={false}
          showMonth={false}
          showYear={false}
          toggleSlidebar={toggler}
          fechas={fechas}
          setFechas={updateFechas}
          usePeriod
        />

        <CardOverviewContainer gridColumns='repeat(3, 1fr)'>
          <CardOverview
            title={'S/ '+ numberFormat(data.totalTicketVenta)}
            subtitle='Venta de ticket'
            icon={<LuCoins size={22}/>}
          />
          <CardOverview
            title={'S/ '+ numberFormat(data.totalBill)}
            subtitle='Total prebill'
            icon={<LuTicket size={22}/>}
          />
          <CardOverview
            title={'S/ '+ numberFormat(data.totalTicketRedeem)}
            subtitle='Canje de ticket'
            icon={<HiOutlineBanknotes size={22}/>}
          />
          <CardOverview
            title={'S/ '+ numberFormat(data.totalHandPay)}
            subtitle='Pagos manuales'
            icon={<LuTicket size={22}/>}
          />
          <CardOverview
            title={'S/ '+ numberFormat(data.totalCardPay)}
            subtitle='Tarjeta'
            icon={<LuTicket size={22}/>}
          />
          <CardOverview
            title={'S/ '+ numberFormat(data.totalExpenses)}
            subtitle='Gastos'
            icon={<LuCoins size={22}/>}
          />
          <CardOverview
            title={'S/ '+ numberFormat(data.totalRecaudo)}
            subtitle='Recaudo'
            icon={<LuCoins size={22}/>}
          />
          <CardOverview
            title={'S/ '+ numberFormat(data.totalProd)}
            subtitle='Produccion total'
            icon={<LuCoins size={22}/>}
          />
        </CardOverviewContainer>

        <Tabs>
          <Tab title="Reporte">
            <AsyncBlock loading={loading} error={error}>
              <TableFondoReport data={data && data.data} fechas={fechas} fetchData={fetchData}/>
            </AsyncBlock>
          </Tab>

          <Tab title="Lista">
            <AsyncBlock loading={loading2} error={error2}>
              <TableFondoReport data={lista && lista.data} fechas={fechas} fetchData={fetchData2}/>
            </AsyncBlock>
          </Tab>
        </Tabs>

      </PageSection>
    </PageContainer>
  );
};

export default FondoReport;
