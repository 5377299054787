import React, {useCallback, useMemo, useState} from "react";
import PageContainer from "../../../../../components/layout/PageContainer";
import PageSection from "../../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import Button from "../../../../../components/forms/Button";
import {Apis} from "../../../../../util/Apis";
import useFetch2 from "../../../../../hooks/useFetch2";
import useEditModal from "../../../../../modals/useEditModal";
import GenericModal2 from "../../../../../modals/GenericModal2";
import CardNew from "./CardNew";
import {PageBackLabel} from "../../../../../components/layout/PageBackLabel";
import {useHistory} from "react-router";
import {FlexLayout} from "../../../../../components/flex/Flex";
import ReactTable from "../../../../../components/util/ReactTable";
import AsyncBlock from "../../../../../components/forms/AsyncBlock";
import useModal from "../../../../../modals/useModal";
import CardDetail from "./CardDetail";
import ModalInfo from "../../../../../modals/ModalInfo";

const BingoCardList = () => {
  const querystring = window.location.search
  const query = new URLSearchParams(querystring)
  const [dataTable,setDataTable] = useState([])
  let event = query.get('event')
  let history = useHistory()

  const {loading, data, fetchData,error} = useFetch2(Apis.BINGO + `/bingo-card?eventId=${event}`, [], {});
  const {wrapper: wrapper1, addAction} = useEditModal({
    addComponent: <CardNew/>,
    onRefresh: fetchData
  })

  const {wrapper: wrapper3, someAction: mostrarAction} = useModal({
    component: <CardDetail />
  })

  useMemo(()=>{
    const groupedData = data.reduce((grouped, item) => {
      const { customerId } = item;
      if (!grouped[customerId]) {
        grouped[customerId] = { data: [], count: 0, customerId};
      }
      grouped[customerId].data.push(item);
      grouped[customerId].count += 1;
      return grouped;
    }, {});
    setDataTable(Object.values(groupedData))
  },[data])

  const actionsValue = useCallback(props => <>{
    <FlexLayout justifyContent='center'>
      <Button variant={'light'} size={'s'} onClick={() => {
        mostrarAction({
          data: props.renderValue()
        })
      }
      }>Ver cartillas</Button>
    </FlexLayout>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }</>, []);

  const clienteCell = useCallback(props => <>{
    <FlexLayout alignItems='center' justifyContent='center' gap='4px'>
      <span>{props.renderValue() ? props.renderValue() : 'No definido'}</span>
    </FlexLayout>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }</>, []);

  const columns = useMemo(
    () => [
      {
        header: 'Cliente',
        accessorKey: 'customerId',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: clienteCell
      },
      {
        header: 'Cantidad',
        accessorKey: 'count',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Acciones',
        accessorKey: 'data',
        enableGlobalFilter:false,
        enableSorting:false,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: actionsValue
      }
    ]
    , []
  )

  return (
    <PageContainer>
      <PageSection>
        <PageBackLabel text='Regresar' onClick={() => history.push('/bingos/eventos')}/>
          <Title text="Lista de cartones" type={'page'}/>

        <AsyncBlock loading={loading} error={error}>
          <ReactTable
            columns={columns}
            data={dataTable || []}
            filterAdd={() => addAction({eventId: event})}
            customSearch
            noData='No existen resultados'
            pageSize={20}
          />
        </AsyncBlock>

      </PageSection>
      <GenericModal2 {...wrapper1}/>
      <ModalInfo {...wrapper3} />
    </PageContainer>
  )
}

export default BingoCardList
