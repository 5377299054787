import React, {useContext, useEffect, useState} from "react";
import PageContainer from "../../../../components/layout/PageContainer";
import {Title, Button} from "@wargostec/react-components";
import PageSection from "../../../../components/layout/PageSection";
import FormGroup, {FormBlock} from "../../../../components/forms/FormGroup";
import Input from "../../../../components/forms/FormEl";
import {useForm} from "react-hook-form";
import axios from "axios"
import {Apis} from "../../../../util/Apis";
import {ButtonBox} from "../../../../components/forms/Button";
import {SiteContext} from "../../../common/context/SiteContext";
import useFetch2 from "../../../../hooks/useFetch2";
import {errorToast, successToast} from "../../../../components/layout/ToastStyles";

const BillAlert = () => {

  const {salaId} = useContext(SiteContext)
  let defaultParams = {salaId: salaId, enabled: true}
  const [params, setParams] = useState()
  const {data} = useFetch2(Apis.TGM+'/alert-bill-conf', [],params);
  const {register, handleSubmit, reset} = useForm({defaultValues: defaultParams});

  useEffect(() => {
    if (data) {
      reset(data)
    }
  }, [data])

  const onCreate = (params) => {
    console.log("creando alerta");
    params.salaId=salaId
    axios.put(Apis.TGM+"/alert-bill-conf",params)
      .then(res=>{
        console.log(res)
        console.log(res.data)
      }).catch(err=>{
        console.log(err)
    })
  };

  const testMessage = (params) => {
    const payload = {}
    payload.salaId = salaId
    payload.numbers = params.numbers
    axios.post(Apis.TGM+"/alert-bill-conf/test-message",payload)
        .then(res=>{
          successToast("Mensaje enviado")
        }).catch(err=>{
      errorToast("Error al enviar mensaje")
      console.log(err)
    })
  };

  return (
    <PageContainer>
      <PageSection>
          <Title text="Alerta Billetero" type={'page'}/>

        <form>
          <FormBlock>
            <FormGroup>
              <label style={{marginRight: "20px"}}>Habilitado</label>
              <input type="checkbox" {...register("enabled")}/>
            </FormGroup>
            <FormGroup>
              <label>Monto para activar alerta (soles)</label>
              <Input {...register("billLineBreak")}/>
            </FormGroup>
            <FormGroup>
              <label>Número para SMS's</label>
              <Input {...register("numbers")}/>
            </FormGroup>

          </FormBlock>

          <ButtonBox>
            <Button text={"Guardar"} onClick={handleSubmit(onCreate)} variant={'primary'}/>
            <Button text={"Mensaje de prueba"} onClick={handleSubmit(testMessage)} variant={'primary'}/>
          </ButtonBox>
        </form>

      </PageSection>
    </PageContainer>
  )
}

export default BillAlert;
