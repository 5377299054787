import React, {useState} from 'react'
import Table from "react-bootstrap/Table";
import {useHistory, useParams} from "react-router";
import PageContainer from "../../../../../components/layout/PageContainer";
import PageSection from "../../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import {FormBlock} from "../../../../../components/forms/FormGroup";
import Loader1 from "../../../../common/components/Loader1";
import {PageBackLabel} from "../../../../../components/layout/PageBackLabel";
import axios from 'axios'
import {useForm} from "react-hook-form";
import Button from "../../../../../components/forms/Button";
import {Apis} from "../../../../../util/Apis";
import useFetch2 from "../../../../../hooks/useFetch2";

const AddActions = () => {

  let history = useHistory()
  const {id} = useParams()
  const {loading, data, fetchData} = useFetch2(Apis.USER + '/users/me-info', []);
  const {register, control, handleSubmit, watch, errors, reset} = useForm();
  const {policyName} = useParams()


  let arr = []

  const [selectedValue, setSelectedValue] = useState(arr);

  const handleSingleChange = (event, item) => {
    let yaEsta = false;
    console.log(item.hash)
    const arrLength = arr.length
    if (!event.target.checked) {
      for (let i = 0; i < arrLength; i++) {
        if (arr[i] === item.hash) {
          arr.splice(i, 1)
          break;
        }
      }
    } else if (event.target.checked) {
      for (let i = 0; i < arrLength; i++) {
        if (arr[i] === item.hash) {
          yaEsta = true;
          break;
        }
      }
      if (!yaEsta) {
        arr.push(item.hash)
      }
    }
  }
  const onSubmit = () => {
    /*console.log(arr)*/
    let newActions = ''
    const arrSize = arr.length

    for (let i = 0; i < arrSize; i++) {
      newActions = newActions + arr[i]
      if (i !== arrSize - 1)
        newActions = newActions + ','
    }
    console.log(newActions)

    axios.put(`${Apis.USER}/policies/${id}/attach-actions`, arr).then(res =>
      history.push('/admin/policies/edit/' + id + '/' + policyName)
    ).catch()

  }


  return (
    <PageContainer>
      <PageSection>
        <PageBackLabel text={`Política ${policyName}`} onClick={() => history.goBack()}/>
          <Title text={policyName + ' | Añadir Permisos'} type={'page'}/>
      </PageSection>

      <PageSection>
        <FormBlock>
          {
            loading ?
              <Loader1/>
              :
              <Table style={{marginTop: "20px"}} striped hover>
                <thead>
                <tr>
                  <th>Seleccionar</th>
                  <th>Group Id</th>
                  <th>Nombre</th>
                  <th>Permiso</th>
                </tr>
                </thead>
                <tbody>
                {data.map((item, index) =>
                  <tr key={item.hash}>
                    <td>
                      <input type="checkbox"
                             key={item.hash}
                             checked={selectedValue[index.toString()]}
                             onChange={(event) => handleSingleChange(event, item)}
                      />
                    </td>
                    <td>{item.groupId}</td>
                    <td>{item.name}</td>
                    <td>{item.level}</td>
                  </tr>
                )}
                </tbody>
              </Table>
          }
          <Button style={{marginTop: "15px"}} onClick={() => handleSubmit(onSubmit())}>Añadir</Button>
        </FormBlock>
      </PageSection>
    </PageContainer>
  )
}

export default AddActions
