import React, {useEffect} from 'react'
import {Controller, useForm} from "react-hook-form";
import Input from "../../../../../components/forms/FormEl";
import FormGroup from "../../../../../components/forms/FormGroup";
import CustomerSearchInputV2 from "../../../../common/components/CustomerSearchInputV2";
import Button from "react-bootstrap/Button";
import {useHistory} from "react-router";

const CardForm = ({ value, onSubmit, setAction }) => {
  const history = useHistory()
  const { register, handleSubmit, reset, control, setValue } = useForm()

  useEffect(() => {
    if (value) {
      reset(value)
    }
  }, [value]);

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit))
  }, [])

  return (
    <React.Fragment>
      <FormGroup>
        <label>Código de tarjeta</label>
        <div style={{ display: 'flex', gap: '10px' }}>
          <Input type='text'  {...register("cardNumber")} autoFocus/>
        </div>
      </FormGroup>
      <FormGroup>
        <label>Cliente</label>
        <div style={{display: 'flex'}}>
          <div style={{flex: 1}}>
            <Controller name="customerId" control={control} render={({field}) => <CustomerSearchInputV2 {...field} />}/>
          </div>
          <div style={{flex: 0, paddingLeft: '5px'}}>
            <Button onClick={() => history.push('/crm/new-customer')} variant="light" size={'sm'}>Nuevo</Button>
          </div>
        </div>
      </FormGroup>
    </React.Fragment>
  );
}

export default CardForm;
