const baseIp = 'https://api4.wargos.cloud'
const api5 = 'https://api5.wargos.cloud'

export const Apis = Object.freeze({
  BASE: baseIp,
  OAUTH: 'https://accounts.wargos.cloud',
  TGM: `${baseIp}/xtgmconex`,
  PC: `${baseIp}/xpointcore`,
  RGS: `${baseIp}/xrgslink`,
  SORTEO: `${baseIp}/xsorteos`,
  KCUSTOMERGROUP: `${baseIp}/xcustomergroup`,
  CUSTOMERAPP: `${api5}/xcustomerapp`,
  NOTIF: `${api5}/xnotif`,
  PRINT: `${api5}/xprint`,
  FILE: `${api5}/xfile`,
  USER: `${api5}/xuser`,
  EM: `${baseIp}/xemulator`,
  CAJA: `${api5}/xcaja`,
  CANJE: `${api5}/xcanje`,
  BLACK: `${api5}/xblacklist`,
  SEARCH: `${api5}/xsearch`,
  XACTIVITY: `${api5}/xactivityfeed`,
  FOOD: `${api5}/xfoodorder`,
  SALAS: `${api5}/go-salas`,
  STATISTIC: `${api5}/xstatistic`,
  TICKET: `${api5}/xticket`,
  TABLES: `${baseIp}/xtables`,
  PERSON: `${api5}/xperson`,
  BILLING: `${baseIp}/xbilling`,
  BINGO: `${baseIp}/xbingo`
});