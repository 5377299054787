import React, {useContext, useEffect} from "react";
import {SiteContext} from "../../../common/context/SiteContext";
import axios from "axios";
import {Apis} from "../../../../util/Apis";
import RewardForm from "./RewardForm";

const PutDateFormat = (params) => {
  params.inicioVigencia = params.fechaInicio + 'T' + params.horaInicio;
  params.finVigencia = params.fechaFin + 'T' + params.horaFin;
}

const RewardsNew = ({setAction, setTitle}) => {
  const {salaId} = useContext(SiteContext)

  const save = async (params) => {
    params.salaId = salaId
    params.active = true
    PutDateFormat(params)
    await axios.post(Apis.PC + '/rewards', params)
  }

  useEffect(() => {
    setTitle("Nueva Recompensa")
  }, [])

  return (
    <RewardForm value={{}} onSubmit={save} setAction={setAction}/>
  )
}

export {RewardsNew, PutDateFormat}


