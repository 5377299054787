import React, { useContext, useState } from "react";
import { Title } from "@wargostec/react-components";
import { useForm } from "react-hook-form";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useHistory } from "react-router";
import { BsCheckCircle } from "react-icons/bs";
import axios from "axios";
import PageContainer from "../../../../components/layout/PageContainer";
import PageSection from "../../../../components/layout/PageSection";
import FormGroup from "../../../../components/forms/FormGroup";
import Input, { DatePickerCustom, InputFile, RadioCustom } from "../../../../components/forms/FormEl";
import Button, { ButtonBox } from "../../../../components/forms/Button";
import { SiteContext } from "../../../common/context/SiteContext";
import DocumentTypeSelect from "../../../common/components/selects/DocumentTypeSelect";
import CountrySelect from "../../../common/components/selects/CountrySelect";
import EstadoCivilSelect from "../../../common/components/selects/EstadoCivilSelect";
import { Apis } from "../../../../util/Apis";
import { errorToast, successToast } from "../../../../components/layout/ToastStyles";
import PageCardTitle from "../../../../components/layout/CardTitle";
import Card from "../../../../components/forms/Card";
import { PageBackLabel } from "../../../../components/layout/PageBackLabel";
import { FlexLayout } from "../../../../components/flex/Flex";
import { getEstadoCivil } from "../../../../util";
import { usePut } from "../../../../hooks/usePut";
import { usePost } from "../../../../hooks/usePost";

const Registrar = ({ data }) => {

  const [dataId, setDataId] = useState(data?.id);
  const parentRoute = '/crm'

  const { salaId } = useContext(SiteContext)
  const history = useHistory()

  let defaultParams = {
    documentTypeId: data ? data.person.documentTypeId : 1,
    firstName: data ? data.person.firstName : null,
    lastName1: data ? data.person.lastName1 : null,
    lastName2: data ? data.person.lastName2 : null,
    document: data ? data.person.document : null,
    birthday: data ? data.person.birthday : null,
    civil: data?.person.civil ? data.person.civil : 'N',
    lugar: data?.person.lugar ? data.person.lugar : null,
    address: data?.person.address ? data.person.address : null,
    countryId: data ? data.person.countryId : 173,
    gender: data?.person.gender ? data.person.gender : null,
    cellphone: data?.person.cellphone ? data.person.cellphone : null,
    telephone: data?.person.telephone ? data.person.telephone : null,
    email: data?.person.email ? data.person.email : null,
    documentPath: data ? data.person.documentPath : "null"
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset
  } = useForm({ defaultValues: defaultParams });

  const { mutate: updateCustomer } = usePut({
    url: `${Apis.PC}/customers/v2/${dataId}`,
    onSuccess: () => {
      successToast("Cliente actualizado correctamente")
    },
    onError: (error) => {
      errorToast(error.response.data.message ? error.response.data.message : "Ocurrió un error al guardar.", 7000)
    }
  })

  const { mutate: createCustomer } = usePost({
    url: `${Apis.PC}/customers/v2`,
    onSuccess: () => {
      history.push(`${parentRoute}/clientes`)
      successToast("Cliente creado correctamente")
    },
    onError: (error) => {
      errorToast(error.response.data.message ? error.response.data.message : "Ocurrió un error al guardar. Posiblemente el usuario ya exista, de no ser así inténtelo nuevamente en unos minutos.", 7000)
    }
  })
  async function uploadAction(rawImage) {
    let data = new FormData()
    data.append("file", rawImage);
    data.append('folder', 'document-pdf')

    await fetch(Apis.FILE + "/upload/doc", {
      method: "POST",
      body: data
    }).then(response => response.json())
      .then(res => {
        console.log(res)
        successToast("Documento subido exitosamente.");
        setValue('documentPath', res.url)
        handleSubmit(onSubmit)()
      })
      .catch(error => {
        console.log(error);
        errorToast("Ocurrió un problema al identificar el archivo. Intente de nuevo en unos minutos.");
      })
  }

  const handleChange = e => {
    if (e.target.files.length) {
      let rawImage = e.target.files[0];
      uploadAction(rawImage)
    }
  };

  const onSubmit = async (params) => {
    params.salaId = salaId

    if (dataId) await updateCustomer(params)
    else await createCustomer(params)
  }


  const eliminarDocumento = () => {
    setValue('documentPath', null)
    handleSubmit(onSubmit)()
  }

  const searchInReniec = async (e) => {
    if (parseInt(getValues('documentTypeId')) !== 1) return

    const document = e.target.value
    if (document.length < 6 || document.length > 20) {
      errorToast("Longitud de documento invalida", 7000)
      return
    }

    const params = { document: document, salaId: salaId };
    const response = await axios.get(Apis.PC + '/customers/by-document', { params: params })
    if (response.status === 200) {
      if (response.data.exists) {
        customerFound(response.data.customer)
      } else {
        customerNotFound(document)
      }
    } else customerNotFound(document)
  }

  const customerFound = (customer) => {
    reset(customer.person)
    setDataId(customer.id)
  }

  const customerNotFound = async (document) => {
    setDataId(null)
    if (document.length !== 8) return
    const resReniec = await axios.get(Apis.PERSON + '/search/' + document)
    if (resReniec.status === 200) {
      reset({
        ...resReniec.data, birthday: resReniec.data.fechaNac,
        gender: resReniec.data.gender === 'MASCULINO' ? 'M' : 'F',
        civil: getEstadoCivil(resReniec.data.estadoCivil),
        countryId: 173,
        cellphone: '',
        email: '',
      })
      successToast('Datos extraidos desde RENIEC')
    }
    else {
      errorToast('DNI invalido')
    }
  }

  return (
    <PageContainer>
      <PageSection>
        <PageBackLabel text='Regresar' onClick={() => history.goBack()} />
        <Title text="Registrar cliente" />

        <Button onClick={handleSubmit(onSubmit)} variant={'primary'}>
          Guardar
        </Button>
      </PageSection>

      <PageSection>
        <Card padding>
          <Row>
            <Col>
              <PageCardTitle title={'Datos personales'} />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={4}>
              <FormGroup>
                <label>Tipo de Documento</label>
                <DocumentTypeSelect {...register("documentTypeId")} />
              </FormGroup>
            </Col>
            <Col xs={12} md={4}>
              <FormGroup>
                <label>Documento(*)</label>
                <Input {...register("document", {
                  required: "Ingrese un documento valido",
                  onBlur: (event) => { searchInReniec(event) }
                })} />
                {errors.document && <p style={{ color: 'red' }}>{errors.document?.message}</p>}
              </FormGroup>
            </Col>
            <Col xs={12} md={4}>
              <FormGroup>
                <label>Nacionalidad</label>
                <CountrySelect {...register("countryId")} />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={4}>
              <FormGroup>
                <label>Nombres(*)</label>
                <Input {...register("firstName", { required: "Campo obligatorio" })} />
                {errors.firstName && <p style={{ color: 'red' }}>{errors.firstName?.message}</p>}
              </FormGroup>
            </Col>
            <Col xs={12} md={4}>
              <FormGroup>
                <label>Apellido Paterno(*)</label>
                <Input {...register("lastName1", { required: "Campo obligatorio" })} />
                {errors.lastName1 && <p style={{ color: 'red' }}>{errors.lastName1?.message}</p>}
              </FormGroup>
            </Col>
            <Col xs={12} md={4}>
              <FormGroup>
                <label>Apellido Materno</label>
                <Input {...register("lastName2")} />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <FormGroup>
                <label>Dirección</label>
                <Input {...register("address")} />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <FormGroup>
                <label>Estado civil</label>
                <EstadoCivilSelect  {...register("civil")} />
              </FormGroup>
            </Col>
            <Col xs={12} md={6}>
              <FormGroup>
                <label>Fecha nacimiento</label>
                <DatePickerCustom  {...register("birthday")} />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                <label style={{ marginBottom: '8px' }}>Género:</label>
                <RadioCustom value={"M"} id='masculino' label='Masculino' {...register("gender")} />
                <RadioCustom value={"F"} id='femenino' label='Femenino' {...register("gender")} />
              </FormGroup>
            </Col>
            <Col>
            </Col>
          </Row>

          <PageCardTitle title={'Datos de contacto'} />
          <Row>
            <Col xs={12} md={6}>
              <FormGroup>
                <label>Celular</label>
                <Input  {...register("cellphone")} />
              </FormGroup>
            </Col>
            <Col xs={12} md={6}>
              <FormGroup>
                <label>Correo</label>
                <Input {...register("email")} />
              </FormGroup>
            </Col>
          </Row>

          <PageCardTitle title={'Documento escaneado'} />
          <Row>
            {getValues("documentPath") ?
              <Col>
                <FlexLayout alignItems='center'>
                  <span style={{ fontSize: '16px' }}>El documento ya fue subido correctamente</span>
                  <BsCheckCircle size={20} color="#37ba32" />
                </FlexLayout>
                <FlexLayout margin='1rem 0'>
                  <ButtonBox>
                    <Button variant={'success'} title={"Ver documento"}
                      onClick={() => window.open(`${process.env.REACT_APP_S3_WARGOS_ASSETS}${data.person.documentPath}`)} />
                    <Button variant={'danger'} title={"Eliminar documento"}
                      onClick={() => eliminarDocumento()} />
                  </ButtonBox>

                </FlexLayout>
              </Col>
              :
              <Col>
                <InputFile style={{ marginBottom: '1rem' }} name='documentPath' id="upload-button" onChange={handleChange} />
              </Col>
            }

          </Row>
        </Card>
      </PageSection>

    </PageContainer>
  )
}

export default Registrar;
