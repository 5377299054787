import {useContext} from "react";
import {SiteContext} from "../../modules/common/context/SiteContext";

const SecComponent = ({code, children}) => {
  //TODO, evaluar si este context se puede llamar a fuera de la funcion para optimizar en casos de muchos renders simultaneos
  const {moreInfo} = useContext(SiteContext)
  const {p} = moreInfo

  if (code === undefined || code === null) {
    return children
  }

  if (code instanceof Array) {
    if (code.some(r => p.includes(r))) {
      return children
    } else {
      return null
    }
  } else {
    if (p.includes(code)) {
      return children
    } else {
      return null
    }
  }
}

export default SecComponent
