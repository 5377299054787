import React, {useContext, useEffect, useState} from 'react'
import {Select} from "../../../../components/forms/FormEl";

const OtherPayCategorySelect = React.forwardRef((props, ref) => {

  return (
    <Select ref={ref} {...props} >
      { !props.form
        ? <option value={0}>TODOS</option>
        : <option style={{display:"none"}}/>
      }
      <option value={4}>ADELANTOS</option>
      <option value={1}>MOVILIDAD</option>
      <option value={2}>SERVICIOS</option>
      <option value={3}>OTROS</option>
      <option style={{display:"none"}} selected value={""}/>
    </Select>
  )
})

export default OtherPayCategorySelect;
