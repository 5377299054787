import React, {createContext, useState} from 'react';
import {Apis} from "../../../util/Apis";
import axios from "axios";

export const CustomerContext = createContext({
  getCustomerInfo: () => {
  },
  loading: false
});

export const CustomerProvider = (props) => {

  const [customerMap, setCustomerMap] = useState(new Map())
  const [loading, setLoading] = useState(false)

  const getCustomerInfo = async (customerId) => {
    setLoading(true)
    const existingCustomer = customerMap[customerId]
    if (existingCustomer !== undefined) {
      setLoading(false)
      return existingCustomer
    }

    try {
      const res = await axios.get(Apis.PC + '/customers/' + customerId)
      customerMap[customerId] = res.data
      setCustomerMap(customerMap)

      setLoading(false)
      return res.data
    } catch (e) {
      setLoading(false)
      return null
    }
  }


  return (
    <CustomerContext.Provider value={{loading, getCustomerInfo}}>
      {props.children}
    </CustomerContext.Provider>
  );
}
