import React, {useContext, useEffect, useState} from 'react';
import PageContainer from "../../../../components/layout/PageContainer";
import PageSection from "../../../../components/layout/PageSection";
import {Title, Button} from "@wargostec/react-components";
import Card from "../../../../components/forms/Card";
import {Controller, useForm} from "react-hook-form";
import Input from "../../../../components/forms/FormEl";
import FormGroup from "../../../../components/forms/FormGroup";
import axios from "axios";
import {Apis} from "../../../../util/Apis";
import {errorToast, successToast} from "../../../../components/layout/ToastStyles";
import {SiteContext} from "../../../common/context/SiteContext";
import MachineSearchInput from "../../../common/components/MachineSearchInput";
import AsyncBlock from "../../../../components/forms/AsyncBlock";

const TomaDeContadores = () => {

  const {salaId} = useContext(SiteContext)
  let defaultParams = {salaId: salaId}
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(false)

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: {errors},
    watch,
    reset
  } = useForm({defaultValues: defaultParams});

  const confId = watch("confId")

  const save = (params) => {
    axios.post(Apis.TGM + '/meter-history', params)
      .then(res => {
        successToast("Contador creado correctamente")
        resetForm()
      })
      .catch(error => {
        errorToast(error.response.data.message ? error.response.data.message : "Ocurrió un error al guardar.", 7000)
      })
  }

  const resetForm = () => {
    setValue('confId', "")
    setValue('ci', "")
    setValue('co', "")
    setValue('jp', "")
    setValue('gp', "")
    setValue('ticketIn', "")
    setValue('ticketOut', "")
    setValue('bi', "")
    setValue('cc', "")
  }

  const getMachine = (value) => {
    if (value === undefined || value === null){
      return
    }
    setLoading(true)
    axios.get(Apis.TGM + `/meter-online?confId=${value}`)
      .then(res => {
        setLoading(false)
        setData(res.data)
      }).catch(err => {
      setLoading(false)
    })
  }

  useEffect(() => {
    getMachine(confId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confId])

  useEffect(() => {
    if (data) {
      reset(data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <PageContainer>
      <PageSection style={{marginBottom: "40px"}}>
          <Title text="Toma manual de contadores" type={'page'}/>

        <Card padding>
          <FormGroup>
            <label>Máquina</label>
            <Controller name="confId" control={control} render={({field}) => <MachineSearchInput {...field} />}/>
          </FormGroup>

          <AsyncBlock loading={loading}>
            <FormGroup>
              <label>Coin In / Apostado</label>
              <Input {...register("ci", {required: "Campo obligatorio"})}/>
              {errors.ci && <p style={{color: 'red'}}>{errors.ci?.message}</p>}
            </FormGroup>
            <FormGroup>
              <label>Coin Out / Ganado</label>
              <Input {...register("co", {required: "Campo obligatorio"})}/>
              {errors.co && <p style={{color: 'red'}}>{errors.co?.message}</p>}
            </FormGroup>
            <FormGroup>
              <label>Jackpot / Premios</label>
              <Input {...register("jp", {required: "Campo obligatorio"})}/>
              {errors.jp && <p style={{color: 'red'}}>{errors.jp?.message}</p>}
            </FormGroup>
            <FormGroup>
              <label>Bill / Billetes</label>
              <Input {...register("bi", {required: "Campo obligatorio"})}/>
              {errors.bi && <p style={{color: 'red'}}>{errors.bi?.message}</p>}
            </FormGroup>
            <FormGroup>
              <label>Jugadas / Games Played</label>
              <Input {...register("gp", {required: "Campo obligatorio"})}/>
              {errors.gp && <p style={{color: 'red'}}>{errors.gp?.message}</p>}
            </FormGroup>
            <FormGroup>
              <label>Ticket In</label>
              <Input {...register("ticketIn", {required: "Campo obligatorio"})}/>
            </FormGroup>
            <FormGroup>
              <label>Ticket Out</label>
              <Input {...register("ticketOut", {required: "Campo obligatorio"})}/>
            </FormGroup>
            <FormGroup>
              <label>Cancel Credit / Cancelar crédito</label>
              <Input {...register("cc", {required: "Campo obligatorio"})}/>
            </FormGroup>
            {errors.cc && <p style={{color: 'red'}}>{errors.cc?.message}</p>}
          </AsyncBlock>
        </Card>
        <Button text={"Guardar"} onClick={handleSubmit(save)} variant={'primary'}/>
      </PageSection>
    </PageContainer>);
};

export default TomaDeContadores;
