import React from "react";
import PageContainer from "../../../../components/layout/PageContainer";
import PageSection from "../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import Card from "../../../../components/forms/Card";
import {LoadingTableV2} from "../../../../components/forms/Tables";
import Table from "react-bootstrap/Table";
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import NoDataTable from "../../../common/components/NoDataTable";
import Loader1 from "../../../common/components/Loader1";

const ProduccionSegunDia = () => {

  const {loading, data, error} = useFetch2(Apis.TGM + '/salas/', []);

  return (
      <PageContainer>
        <PageSection>
            <Title text="Producción Según Dia"/>
        </PageSection>
        <PageSection>
          <Card padding>
            <LoadingTableV2 loading={loading} error={error} data={data}>
              <Table striped hover>
                <thead>
                <tr>
                  <th>Campo..</th>
                  <th>Campo..</th>
                </tr>
                </thead>
                {/*<tbody>*/}
                {/*{data && data.map((item, index) =>*/}
                {/*    <tr>*/}
                {/*      <td>{item.}</td>*/}
                {/*      <td>{item.}</td>*/}
                {/*    </tr>*/}
                {/*)}*/}
                {/*</tbody>*/}
              </Table>
              {!loading ? data.length <= 0 && <NoDataTable text={"Aún no hay Data que mostrar"}/> : <Loader1/>}
            </LoadingTableV2>
          </Card>

        </PageSection>
      </PageContainer>
  )
}

export default ProduccionSegunDia;
