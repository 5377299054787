import React, {useMemo, useState} from "react";
import styled from "styled-components";

const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = useState(config);

  const sortedItems = useMemo(() => {
    let sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = key => {
    let direction = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({key, direction});
  }

  return {items: sortedItems, requestSort, sortConfig};
}

//este componente estilizado se debe emplear en las columnas que se desean el estilo
const StyledColumn = styled.th`
    background: none;
    font-weight: 700;
    cursor: pointer;
    user-select: none;

    &:hover {
        background: blue;
        color: white;
    }

    &:after {
        margin-left: .5em;
        font-size: 20px;
    }

    &.ascending:after {
        font-family: "Material Icons";
        content: "\\f1e0";
        margin-left: .5em;
        font-size: 18px;
    }

    &.descending:after {
        font-family: "Material Icons";
        content: "\\f1e3";
        margin-left: .5em;
        font-size: 18px;
    }
`

export const ColumnSortable = ({children, align = 'left', ...props}) => {

  return <StyledColumn {...props} style={{textAlign: align}}>{children}</StyledColumn>
}

export const getClassNamesFor = (name, sortConfig) => {
  if (!sortConfig) {
    return;
  }
  return sortConfig.key === name ? sortConfig.direction : undefined;
};

export default useSortableData;
