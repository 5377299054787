import {useHistory, useParams} from 'react-router';
import PageContainer from '../../../../components/layout/PageContainer';
import PageSection from '../../../../components/layout/PageSection';
import {PageBackLabel} from '../../../../components/layout/PageBackLabel';
import React from 'react';
import GroupForm from './GroupForm';
import useEditModal from '../../../../modals/useEditModal';
import GenericModal2 from '../../../../modals/GenericModal2';
import GroupNameEdit from './GroupNameEdit';
import {Title} from "@wargostec/react-components";

const GroupEdit = () => {
	const history = useHistory();
	const {groupName} = useParams();

	const {wrapper: wrapper1, editAction} = useEditModal({
		editComponent: <GroupNameEdit />
	});

	const {id} = useParams();

	return (
		<PageContainer>
			<PageSection>
				<PageBackLabel
					text='Grupos'
					onClick={() => history.push('/admin/groups')}
				/>
				<div>
						<Title text={'Grupo | ' + groupName} type={'page'}/>
				</div>
			</PageSection>

			<PageSection>
				<GroupForm />
			</PageSection>
			<GenericModal2 {...wrapper1} />
		</PageContainer>
	);
};
export default GroupEdit;
