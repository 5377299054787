import React, {useContext} from 'react';
import {SiteContext} from "../../../common/context/SiteContext";
import axios from "axios";
import {Apis} from "../../../../util/Apis"
import FoodProductForm from "./FoodProductForm";
import PageSection from "../../../../components/layout/PageSection";
import {PageBackLabel} from "../../../../components/layout/PageBackLabel";
import {Title} from "@wargostec/react-components";
import PageContainer from "../../../../components/layout/PageContainer";
import {useHistory} from "react-router";
import {errorToast, successToast} from "../../../../components/layout/ToastStyles";

const FoodProductNew = () => {

  const history = useHistory()
  const {salaId} = useContext(SiteContext)

  const save = async (params) => {
    params.salaId = salaId
    if(params.categoryId === "") {
      errorToast("Debe seleccionar una categoría")
      return
    }
    await axios.post(Apis.FOOD + "/food", params)
      .then(res => {
        successToast()
        history.push('/crm/products')
      }).catch(err => {
        errorToast()
      })
  }

  return (
    <PageContainer>
      <PageSection>
        <PageBackLabel text='Productos' onClick={() => history.push('/crm/products')}/>
          <Title text="Nuevo producto"/>
      </PageSection>

      <PageSection>
        <FoodProductForm value={{}} onSubmit={save} mode={false} fetchData={{}}/>
      </PageSection>
    </PageContainer>
  );
};

export default FoodProductNew;
