import React, {useCallback, useMemo} from 'react'
import PageSection from "../../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import Button from "../../../../../components/forms/Button";
import {Apis} from "../../../../../util/Apis";
import axios from "axios";
import DeleteGenericModal from "../../../../../modals/DeleteGenericModal";
import useDeleteModal from "../../../../../modals/useDeleteModal";
import useFetch2 from "../../../../../hooks/useFetch2";
import {useHistory} from "react-router";
import useEditModal from "../../../../../modals/useEditModal";
import GroupModalForm from "./GroupModalForm";
import GenericModal2 from "../../../../../modals/GenericModal2";
import {FlexLayout} from '../../../../../components/flex/Flex';
import AsyncBlock from '../../../../../components/forms/AsyncBlock';
import ReactTable from '../../../../../components/util/ReactTable';
import PageContainerFull from '../../../../../components/layout/PageContainerFull';

const MachinesSetList = () => {

  const history = useHistory();

  const {data, fetchData,loading,error} = useFetch2(Apis.TGM + `/machine-groups`, []);

  const {wrapper: wrapper1, addAction} = useEditModal({
    addComponent: <GroupModalForm/>,
    onRefresh: fetchData
  })

  const deleteElement = id => axios.delete(Apis.TGM + '/machine-groups/' + id)

  const {wrapper: wrapper2, openDeleteModal} = useDeleteModal({
    text: '¿Desea eliminar este Grupo?',
    action: deleteElement,
    onRefresh: fetchData
  })

  function goEdit(id) {
    history.push('/tgm/machine-set-edit/' + id)
  }

  const actionsValue = useCallback(props => <>{
    <FlexLayout justifyContent='center'>
          <Button size={'s'} onClick={() => goEdit(props.row.original.id)}>Editar</Button>
          <Button size={'s'} variant='danger' onClick={() => openDeleteModal(props.row.original.id)}>Eliminar</Button>
      </FlexLayout>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }</>, []);

  const columns = useMemo(
    () => [
      {
        header: 'Id',
        accessorKey: 'id',
        meta: {
          headerClassName:'text-center',
          columnClassName:'text-center',
        },
        enableSorting:false, // para deshabilitar el sort
      },
      {
        header: 'Grupos',
        accessorKey: 'name',
      },
      {
        header: 'Acciones',
        accessorKey: 'acciones',
        meta: {
          headerClassName:'text-center',
          columnClassName:'text-center',
        },
        enableSorting:false, // para deshabilitar el sort
        cell: actionsValue
      },
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , []
)


  return (
    <PageContainerFull>
      <PageSection>
          <Title text="Grupos de máquinas" type={'page'}/>
        <AsyncBlock loading={loading} error={error}>
          <ReactTable
            columns={columns}
            data={data || []}
            customSearch
            filterAdd={() => addAction()}
            noData='No existen grupos creados por el momento'
          />
        </AsyncBlock>
      </PageSection>

      <DeleteGenericModal {...wrapper2}/>
      <GenericModal2 {...wrapper1}/>
    </PageContainerFull>
  )
}
export default MachinesSetList;
