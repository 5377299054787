import React, {useContext, useEffect, useState} from "react";
import Sidebar from "./Sidebar";
import styled from 'styled-components'
import {useHistory, useLocation} from "react-router";
import {FaBars, FaUsers} from "react-icons/fa";
import {PiPokerChip} from "react-icons/pi";
import logo from "../images/logo.png"
import {HiOutlineSlotMachine} from "../assets/icons/slot-machine";
import {MdBallot, MdPeople, MdSecurity} from "react-icons/md";
import {LuSettings2} from "react-icons/lu";
import GlobalSearch from "../util/GlobalSearch";
import {SiteContext} from "../../modules/common/context/SiteContext";
import {Tooltip} from "./AvatarName";
import CornerInfo from "../../modules/common/layout/CornerInfo";
import {LiaCashRegisterSolid} from "react-icons/lia";
import {TbLayoutSidebarLeftCollapse, TbLayoutSidebarLeftExpand} from "react-icons/tb";
import BannerInfo from "./BannerInfo";
import {Apis} from "../../util/Apis";
import useFetch2 from "../../hooks/useFetch2";
import {ToastContainer} from "react-toastify";
import Modal from "react-bootstrap/Modal";
import {FlexLayout} from "../flex/Flex";
import Button from "../forms/Button";

const RightPanel = styled.div`
  display: flex;
  height: ${({showBanner}) => (showBanner ? "calc(100vh - 110px)" : "calc(100vh - 50px)")};
  scroll-behavior: smooth;
  margin-left: ${({isWide}) => (isWide ? "250px" : "50px")};
  overflow-y: auto;
  transition: 0.3s all ease-in-out;
  width: ${({isWide}) => (isWide ? "calc(100% - 250px)" : "calc(100% - 50px)")};
  @media screen and (max-width: 992px) {
    transition: 0.3s all ease-in-out;
    margin-left: 0;
    width: 100%;
  }
`
const TopbarStyled = styled.div`
  height: 50px;
  background: white;
  box-shadow: 0 0.125rem 9.375rem rgb(90 97 105 / 10%);
  padding: 0 16px 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  font-size: 1rem;
  gap: 8px;
  overflow: visible;
  z-index: 10;
  transition: 0.3s all ease-in-out;
`
const MenuContainer = styled.div`
  height: 50px;
  width: 250px;
  width: ${({isWide}) => (isWide ? "250px" : "50px")};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  @media screen and (max-width: 992px) {
    transition: 0.3s all ease-in-out;
    width: 50px;
  }
`
const ContainerSidebar = styled.aside`
  width: ${({isWide}) => (isWide ? "250px" : "50px")};
  background: var(--sidebar);
  color: var(--sibebar-font);
  height: ${({showBanner}) => (showBanner ? "calc(100vh - 60px)" : "100vh")};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: fixed;
  top: 0;
  left: 0;
  transition: 0.3s width ease-in-out;
  z-index: 10;
  margin-top: ${({showBanner}) => (showBanner ? "60px" : "0px")};
  box-shadow: 0 0.125rem 9.375rem rgb(90 97 105 / 10%), 0 0.25rem 0.5rem rgb(90 97 105 / 12%), 0 0.9375rem 1.375rem rgb(90 97 105 / 10%), 0 0.4375rem 2.1875rem rgb(165 182 201 / 10%);

  @media screen and (max-width: 992px) {
    transition: 0.3s all ease-in-out;
    transform: translateX(-250px);
  }
`
const BackdropContainer = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  display: block;
  z-index: 998;
  background-color: #00000080;
  visibility: ${({isOpen}) => (isOpen ? 'visible' : 'hidden')};
  transition: 0.1s all cubic-bezier(0.25, 0.8, 0.25, 1);
`
const SidebarContainerMobile = styled.aside`
  position: fixed;
  z-index: 999;
  width: 250px;
  height: 100%;
  background: var(--sidebar);
  color: var(--sibebar-font);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  top: 0;
  transition: 0.3s all ease-in-out;
  overflow-y: auto;
  opacity: ${({isOpen}) => (isOpen ? '100%' : '0')};
  left: ${({isOpen}) => (isOpen ? '0' : '-100%')};
`
const Tier1Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  width: 50px;
  padding-bottom: 16px;
  border-right: 1px solid #262626;
`
const Tier2Container = styled.div`
  display: ${({isWide}) => (isWide ? "flex" : "none")};
  opacity: ${({isWide}) => (isWide ? "1" : "0")};
  flex-direction: column;
  padding: 16px 12px 16px 12px;
  gap: 16px;
  width: 100%;
  transition: 0.5s all ease-in-out;
`
const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  transition: 0.3s all ease-in-out;
`

const ModuleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  flex: 1;
  border-radius: 8px 8px 0 0;
  border-bottom: 1px solid #262626;
`
const ShowTier2 = styled.div`
  cursor: pointer;
  display: flex;
  @media screen and (max-width: 992px) {
    transition: 0.3s all ease-in-out;
    display: none;
  }
`
const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  gap: 8px;
`
const SubModuleContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y:auto;
  transition: 0.3s transform ease-in-out;
  &::-webkit-scrollbar {
    width: 0;
    height: 0; /* Remove scrollbar space */
  }
  scrollbar-width: none; 

`
const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  transition: 0.3s all ease-in-out;
`
const LogoBackground = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  border-radius: 6px;
  background: #2563eb;
  transition: 0.3s all ease-in-out;
`
const ModuleItem = styled.span`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    background-color: #404040;
  }

  &.active {
    background-color: #404040;
  }
`

const ContainerSearch = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  padding-left: 12px;
  transition: 0.3s all ease-in-out;
  @media screen and (max-width: 992px) {
    padding-left: 0;
    transition: 0.3s all ease-in-out;
  }
`

const MainDashboard = ({brand, children, items, toggleMobile, isOpen}) => {

  const history = useHistory()
  const [isTier2Visible, setIsTier2Visible] = useState(true);
  const {salaId} = useContext(SiteContext)
  const [showBanner, setShowBanner] = useState(false)
  const {data} = useFetch2(Apis.SALAS + '/banner-by-sala?salaId=' + salaId, [])
  const location = useLocation();
  const pathname = location.pathname;
  const [showFullBlock, setShowFullBlock] = useState(false)

  const toogle = (module) => {
    history.push(module)
  }

  const toggleTier2Visibility = () => {
    setIsTier2Visible(!isTier2Visible);
  }

  useEffect(() => {
    if (data.length > 0) {
      setShowBanner(true);
      if (data[0].title === "Servicio suspendido") {
        setShowFullBlock(true);
      } else {
        setShowFullBlock(false);
      }
    } else {
      setShowBanner(false);
      setShowFullBlock(false)
    }
  }, [data]);

  return (
    <div>
      {showBanner && 
        <BannerInfo 
          setShowBanner={setShowBanner} 
          background={data ? data[0].hexColor : '#d4d4d4'}
          title={data ? data[0].title : ''}
          text={data ? data[0].message : ''}
          closeBanner={data ? !data[0].fixed : true}
        />
      }
      <TopbarStyled>
        <div style={{display: 'flex', flexDirection: 'row', flex: 1, alignItems: 'center'}}>
          <MenuContainer onClick={toggleMobile} isWide={isTier2Visible}>
            <FaBars size={18}/>
          </MenuContainer>
          <ContainerSearch>
            <ShowTier2 onClick={toggleTier2Visibility}>
              {isTier2Visible ?
                <TbLayoutSidebarLeftCollapse size={24}/>
                :
                <TbLayoutSidebarLeftExpand size={24}/>
              }
            </ShowTier2>
            <GlobalSearch history={history}/>
          </ContainerSearch>
        </div>
        <CornerInfo/>
      </TopbarStyled>
      <BackdropContainer isOpen={isOpen} onClick={toggleMobile}/>
      <SidebarContainerMobile isOpen={isOpen}>
        <Tier1Container>
          <LogoContainer>
            <LogoBackground>
              <img src={logo} alt="logo" height={24} width={24}/>
            </LogoBackground>
          </LogoContainer>

          <ModuleContainer>
            <div style={{display: 'flex', flexDirection: 'column', borderRadius: '8px', gap: '8px'}}>
              <ModuleItem
                onClick={() => toogle('/crm')}
                className={pathname.startsWith("/crm") ? "active" : ""}
              >
                <FaUsers size={20}/>
              </ModuleItem>
              <ModuleItem
                onClick={() => toogle('/tgm')}
                className={pathname.startsWith("/tgm") ? "active" : ""}
              >
                <HiOutlineSlotMachine size={20}/>
              </ModuleItem>
              <ModuleItem
                onClick={() => toogle('/caja')}
                className={pathname.startsWith("/caja") ? "active" : ""}
              >
                <LiaCashRegisterSolid size={20}/>
              </ModuleItem>
              <ModuleItem
                onClick={() => toogle('/tables')}
                className={pathname.startsWith("/tables") ? "active" : ""}
              >
                <PiPokerChip size={20}/>
              </ModuleItem>
              <ModuleItem
                onClick={() => toogle('/bingos')}
                className={pathname.startsWith("/bingos") ? "active" : ""}
              >
                <MdBallot size={20}/>
              </ModuleItem>

            </div>
          </ModuleContainer>

          <FooterContainer>
            <ModuleItem
              onClick={() => toogle('/admin')}
              className={pathname.startsWith("/bingos") ? "active" : ""}
            >
              <MdSecurity size={20}/>
            </ModuleItem>
            <ModuleItem
              onClick={() => toogle('/crm/settings')}
              className={pathname.startsWith("/crm/settings") ? "active" : ""}
            >
              <LuSettings2 size={20}/>
            </ModuleItem>
            {/* <AvatarName name={userData.name} lastName={userData.lastName} avatar={userData.avatar} /> */}
          </FooterContainer>

        </Tier1Container>
        <Tier2Container isWide={true}>
          <HeaderContainer>
            <span style={{fontSize: '20px', fontWeight: 'bold'}}>{brand}</span>
          </HeaderContainer>
          <SubModuleContainer>
            <Sidebar items={items}/>
          </SubModuleContainer>
        </Tier2Container>
      </SidebarContainerMobile>
      <ContainerSidebar isWide={isTier2Visible} showBanner={showBanner}>
        <Tier1Container>
          <LogoContainer>
            <LogoBackground>
              <img src={logo} alt="logo" height={24} width={24}/>
            </LogoBackground>
          </LogoContainer>

          <ModuleContainer>
            <div style={{display: 'flex', flexDirection: 'column', borderRadius: '8px', gap: '8px'}}>
              <Tooltip text="CLIENTES">
                <ModuleItem
                  onClick={() => toogle('/crm')}
                  className={pathname.startsWith("/crm") ? "active" : ""}
                >
                  <FaUsers size={20}/>
                </ModuleItem>
              </Tooltip>
              <Tooltip text="MÁQUINAS">
                <ModuleItem
                  onClick={() => toogle('/tgm')}
                  className={pathname.startsWith("/tgm") ? "active" : ""}
                >
                  <HiOutlineSlotMachine size={20}/>
                </ModuleItem>
              </Tooltip>
              <Tooltip text="CAJA">
                <ModuleItem
                  onClick={() => toogle('/caja')}
                  className={pathname.startsWith("/caja") ? "active" : ""}
                >
                  <LiaCashRegisterSolid size={20}/>
                </ModuleItem>
              </Tooltip>
              <Tooltip text="MESAS">
                <ModuleItem
                  onClick={() => toogle('/tables')}
                  className={pathname.startsWith("/tables") ? "active" : ""}
                >
                  <PiPokerChip size={20}/>
                </ModuleItem>
              </Tooltip>
              <Tooltip text="BINGO">
                <ModuleItem
                  onClick={() => toogle('/bingos')}
                  className={pathname.startsWith("/bingos") ? "active" : ""}
                >
                  <MdBallot size={20}/>
                </ModuleItem>
              </Tooltip>

              <Tooltip text="ASISTENCIA">
                <ModuleItem
                  onClick={() => window.open('https://app.asis.pe', "_blank", "noreferrer")}
                >
                  <MdPeople size={20}/>
                </ModuleItem>
              </Tooltip>

            </div>
          </ModuleContainer>

          <FooterContainer>
            <Tooltip text="ADMIN">
              <ModuleItem
                onClick={() =>
                  window.open(
                    'https://admin.wargos.cloud',
                    '_blank',
                    'noreferrer'
                  )
                }
                className={pathname.startsWith("/admin") ? "active" : ""}
              >
                <MdSecurity size={20}/>
              </ModuleItem>
            </Tooltip>
            <Tooltip text="CONFIGURACIÓN">
              <ModuleItem
                onClick={() => toogle('/crm/settings')}
                className={pathname.startsWith("/crm/settings") ? "active" : ""}
              >
                <LuSettings2 size={20}/>
              </ModuleItem>
            </Tooltip>
            {/* <AvatarName name={userData.name} lastName={userData.lastName} avatar={userData.avatar} /> */}
          </FooterContainer>

        </Tier1Container>
        <Tier2Container isWide={isTier2Visible}>
          <HeaderContainer>
            <span style={{fontSize: '20px', fontWeight: 'bold'}}>{brand}</span>
          </HeaderContainer>
          <SubModuleContainer>
            <Sidebar items={items}/>
          </SubModuleContainer>
        </Tier2Container>
      </ContainerSidebar>

      <RightPanel isWide={isTier2Visible} showBanner={showBanner}>
        {children}
      </RightPanel>
      <ToastContainer />

      <Modal centered show={showFullBlock} backdrop="static" keyboard={false} style={{userSelect:'none'}}>
        <Modal.Body>
          <FlexLayout column justifyContent='center' alignItems='center' gap='16px'>
            <h5>{data && data.length > 0 ? data[0].title : ''}</h5>
            <span>{data && data.length > 0 ? data[0].message : ''}</span>
            <Button variant='primary' title='Aceptar' onClick={()=> history.push('/selector')}/>
          </FlexLayout>
        </Modal.Body>
      </Modal>
    </div>
  )
};

export default MainDashboard;
