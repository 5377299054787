import React from 'react';
import styled from "styled-components";

const ProgressContainer = styled.div`
  display:flex;
  flex-direction: row;
  background-color: #EDEDED;
  border-radius: 8px;
  overflow:hidden;
`
const MultiColorProgressBar = ({data}) => {

  //const colors = ["#008ffb", "#ff4560", "#feb019", "black"]
  const colors = ["#3498db", "#e74c3c", "#f1c40f", "#2ecc71", "#9b59b6", "#34495e"]

  return (
    <ProgressContainer style={{progressStyle: "buton"}}>
        {data.map((item, index) => {
            return (
            // <div key={index} style={{width: `${item.value * 100}%`, backgroundColor: colors[index], height: 16}}></div>
            <div key={index} style={{flex: item.value, backgroundColor: colors[index], height: 16}}></div>
            )
        })}
    </ProgressContainer>
  );
};

export default MultiColorProgressBar;
