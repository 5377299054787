import React, {useCallback, useMemo} from "react";
import PageContainer from "../../../../../components/layout/PageContainer";
import PageSection from "../../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import Button from "../../../../../components/forms/Button";
import {Apis} from "../../../../../util/Apis";
import useFetch2 from "../../../../../hooks/useFetch2";
import axios from "axios";
import useDeleteModal from "../../../../../modals/useDeleteModal";
import DeleteGenericModal from "../../../../../modals/DeleteGenericModal";
import useEditModal from "../../../../../modals/useEditModal";
import JackpotEdit from "./JackpotEdit";
import JackpotNew from "./JackpotNew";
import GenericModal2 from "../../../../../modals/GenericModal2";
import {FlexLayout} from "../../../../../components/flex/Flex";
import AsyncBlock from "../../../../../components/forms/AsyncBlock";
import ReactTable from "../../../../../components/util/ReactTable";
import {useHistory} from "react-router";

 export const getNameJackpot = (form) => {
  switch (form) {
    case "Line":
      return "Jackpot de Linea"
    case "Blackout":
      return "Jackpot de Bingo"
    case "Mega":
      return "Mega jackpot"
    default:
      return "Jackpot de Linea"
  }
}

const JackpotList = () => {

  const history = useHistory()
  const baseUrl = '/bingos'

  const {loading, data, fetchData, error} = useFetch2(Apis.BINGO + `/jackpot/sala`, []);
  const {wrapper: wrapper1, addAction, editAction} = useEditModal({
    editComponent: <JackpotEdit/>,
    addComponent: <JackpotNew/>,
    onRefresh: fetchData
  })

  const deleteElement = id => axios.delete(Apis.BINGO + `/jackpot/${id}`)

  const {
    wrapper: wrapper2,
    openDeleteModal
  } = useDeleteModal({
    text: '¿Desea eliminar este jackpot?',
    action: deleteElement,
    onRefresh: fetchData
  })

  const jackpotCell = useCallback(props => <>{
    <FlexLayout alignItems='center' justifyContent='center' gap='4px'>
      <span>{getNameJackpot(props.renderValue())}</span>
    </FlexLayout>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }</>, []);

  const ballCell = useCallback(props => <>{
    <FlexLayout alignItems='center' justifyContent='center' gap='4px'>
      <span>{props.renderValue() ? "Desde " + props.row.original.startBall : "Con " + props.row.original.winBall }</span>
    </FlexLayout>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }</>, []);

  const actionsValue = useCallback(props => <>{
    <FlexLayout justifyContent='center'>
      {/*<Button variant={'primary'} size={'s'} onClick={() => editAction(props.renderValue())}>Editar</Button>*/}
      <Button variant={'primary'} size={'s'} onClick={() => history.push(`${baseUrl}/edit-jackpots/` + props.renderValue())}>Editar</Button>
      <Button variant={'light'} size={'s'} onClick={() => openDeleteModal(props.renderValue())}>Eliminar</Button>
    </FlexLayout>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }</>, []);

  const columns = useMemo(
    () => [
      {
        header: 'Jackpot',
        accessorKey: 'form',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: jackpotCell
      },
      {
        header: 'Bolita',
        accessorKey: 'ballRaise',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: ballCell
      },
      {
        header: 'Monto',
        accessorKey: 'actualAmount',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        }
      },
      {
        header: 'Acciones',
        accessorKey: 'id',
        enableGlobalFilter:false,
        enableSorting:false,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: actionsValue
      }
    ]
    , []
  )

  return (
    <PageContainer>
      <PageSection>

          <Title text="Lista de jackpots" type={'page'}/>

        <AsyncBlock loading={loading} error={error}>
          <ReactTable
            columns={columns}
            data={data || []}
            filterAdd={() => history.push(`${baseUrl}/new-jackpots`)}
            customSearch
            noData='No existen resultados'
            pageSize={15}
          />
        </AsyncBlock>

      </PageSection>

      <GenericModal2 {...wrapper1}/>
      <DeleteGenericModal {...wrapper2}/>
    </PageContainer>
  )
}

export default JackpotList
