import React from 'react';
import moment from "moment";
import {MdFiberManualRecord} from "react-icons/md"

export function getMachineLabels(data) {
  const dataArray = data.map(item => item.machineTypeId)
  const labels = [];
  for (let i = 0; i < dataArray.length; i++) {
    switch (dataArray[i]) {
      case 1:
        labels.push("MAQUINA");
        break;
      case 2:
        labels.push("RULETA");
        break;
      case 3:
        labels.push("DERBY");
        break;
      default:
        labels.push("MAQUINA");
    }
  }
  return labels;
}

export function getName(customer) {
  if (!customer) {
    return '- -'
  } else {
    return customer?.person?.firstName + ' ' + customer?.person?.lastName1
  }
}

export function getEstadoCivil(estadoCivil) {
  switch (estadoCivil) {
    case 'SOLTERO':
      return 'S'
    case 'CASADO':
      return 'C'
    case 'VIUDO':
      return 'V'
    case 'DIVORCIADO':
      return 'D'
    default:
      return 'N'
  }
}

export function getAge(birthday) {
  return moment().diff(birthday, 'years')
}


export function getBetType({valor}) {
  let textColor = 'white'

  if (valor < 0.4) textColor = 'blue'
  else if (0.4 <= valor && valor < 1.0) textColor = 'yellow'
  else if (1.0 <= valor && valor < 5.0) textColor = 'red'
  else if (5.0 <= valor) textColor = '#660000'

  return <MdFiberManualRecord color={textColor} size={28}/>
}

export function generateDonut(data) {
  let dataArray = data.filter(item => item.name !== 'Total')
  const labels = [];
  for (const each of dataArray) {
    if (each.name === "n/a") {
      labels.push({name: 'No indica', count: each.count})
    }
    if (each.name === "M") {
      labels.push({name: 'Hombres', count: each.count})
    }
    if (each.name === "F") {
      labels.push({name: 'Mujeres', count: each.count})
    }
  }
  return labels;
}

export function generateLabels(data) {
  const dataArray = data.map(item => item.ageRange)
  const labels = [];
  for (const each of dataArray) {
    if (each === "null") {
      labels.push('NO INDICA')
    } else {
      labels.push(each)
    }
  }
  return labels;
}

export function generateDistrics(data) {
  const dataArray = data.map(item => item.name)
  const labels = [];
  for (const each of dataArray) {
    if (each === "n/a") {
      labels.push('NO INDICA')
    } else {
      labels.push(each)
    }
  }
  return labels;
}

export function generateEmployeeSessionData(data) {
  const dataGenerate = [];
  for (let i = 0; i < data.length; i++) {

    dataGenerate.push({
      name: data[i].employeeName,
      data: data[i].data,
    });
  }
  return dataGenerate;
}

export function serializeDownload(params) {
  const searchParams = new URLSearchParams();
  Object.keys(params).filter((k) => {
    return (params[k] !== null && params[k] !== undefined)
  }).forEach(key => searchParams.append(key, params[key]));

  return searchParams
}

//verde true, rojo , false
export const getGenericLink = (link) => {
  if (link === true) {
    return '#5FA777';
  } else if (link === false) {
    return '#FF6962';
  } else {
    return '#D9D9D9';
  }
};

export const getMachineLinkColor = (serverLink, machineLink) => {
  if (serverLink === false) {
    return '#D9D9D9'; // Gris por defecto si serverLink es false
  } else if (machineLink === true) {
    return '#37ba32'; // Verde si serverLink es true y machineLink es true
  } else if (machineLink === false) {
    return '#dc2626'; // Rojo si serverLink es true y machineLink es false
  } else {
    return '#D9D9D9'; // Gris por defecto si machineLink es null o undefined
  }
};
