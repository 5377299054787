import React, {useEffect} from "react";
import axios from "axios";
import {Apis} from "../../../../../util/Apis";
import FondoForm from "./FondoForm";

const FondoNew = ({setAction, setTitle}) => {

  const save = async (params) => {
    await axios.post(Apis.CAJA + '/fondos', params)
  }

  useEffect(() => {
    setTitle("Nuevo fondo")
  }, [])

  return (
    <div>
      <FondoForm value={{}} onSubmit={save} setAction={setAction}/>
    </div>
  )
}

export default FondoNew
