import React, { useState } from "react";
import MainDashboard from "../../components/layout/MainDashboard";
import TablesRoutes from "./TablesRoutes";
import DropdownContainer from "../../components/layout/DropdownContainer";
import DropdownMenu from "../../components/layout/DropdownMenu";
import DropdownMenuItem from "../../components/layout/DropdownMenuItem";
import * as MdIcons from "react-icons/md";

const TablesMain = () => {
  const baseUrl = '/tables'

  const [isOpen, setIsOpen] = useState(false)
  const toggleMobile = () => {
    setIsOpen(!isOpen)
  }

  return (
    <MainDashboard
      brand={'MESAS'}
      isOpen={isOpen}
      toggleMobile={toggleMobile}

      items={
        <DropdownContainer>
          <DropdownMenu title={'Mesas'} icon={<MdIcons.MdTableBar />}>
            <DropdownMenuItem title={'Configuración'} link={baseUrl + '/config'} onClick={toggleMobile} />
            <DropdownMenuItem title={'Dealer'} link={baseUrl + '/dealer'} onClick={toggleMobile} />
            <DropdownMenuItem title={'Score'} link={baseUrl + '/mesas'} onClick={toggleMobile} />
            <DropdownMenuItem title={'Sesiones'} link={baseUrl + '/sesiones'} onClick={toggleMobile} />
            <DropdownMenuItem title={'Transacciones'} link={baseUrl + '/transacciones'} onClick={toggleMobile} />
            <DropdownMenuItem title={'Reporte'} link={baseUrl + '/report-online'} onClick={toggleMobile} />
          </DropdownMenu>
        </DropdownContainer>

      }
    >
      <TablesRoutes />
    </MainDashboard>
  )
}

export default TablesMain;
