import React from "react";

const CiFormatter = ({digits, children}) => {

  const goNumber = (value) => {
    let valueK;

    if (value >= 1000000) {
      valueK = (value / 1000000).toFixed(1) + ' M';
    } else if (value >= 1000 && value < 1000000) {
      valueK = Math.trunc(value / 1000) + ' K'
    } else {
      valueK = (value / 1000).toFixed(1) + ' K';
    }

    return valueK;
  }

  return (
    <span>
      {goNumber(children)}
    </span>
  )
}

export default CiFormatter
