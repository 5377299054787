import React, {useContext} from "react";
import Table from "react-bootstrap/Table";
import Button, {ButtonBox} from "../../../../../components/forms/Button";
import {useHistory, useParams} from "react-router";
import useFetch from "../../../../../hooks/useFetch";
import axios from 'axios'
import DeleteGenericModal from "../../../../../modals/DeleteGenericModal";
import useDeleteModal from "../../../../../modals/useDeleteModal";
import NoDataTable from "../../../../common/components/NoDataTable";
import {SiteContext} from "../../../../common/context/SiteContext";
import Loader1 from "../../../../common/components/Loader1";

const PolicyActions = () => {

  const history = useHistory();
  const {policyName, id} = useParams()
  const addUsersPage = () => {
    const parentRoute = '/admin'
    history.push(parentRoute + `/policies/edit/${id}/${policyName}/add-actions`)
  }


  const {data, loading, fetchData} = useFetch(`http://localhost:9081/policies/${id}/actions`, [], null, false)

  const deleteElement = actionHash => axios.delete(`http://localhost:9081/policies/${id}/action/${actionHash}`)

  const {wrapper: wrapper2, openDeleteModal} = useDeleteModal({
    text: '¿Desea retirar el permiso?',
    action: deleteElement,
    onRefresh: fetchData
  })

  const policySalaId = localStorage.getItem('policySalaId')
  const {salaId} = useContext(SiteContext)

  return (
    <React.Fragment>
      {policySalaId === salaId &&
      <Button title={"Añadir Permisos"} variant={'primary'} style={{marginTop: "10px", marginBottom: "20px"}}
              onClick={() => addUsersPage()}/>}
      <Table striped hover style={{marginTop: "20px"}}>
        <thead>
        <tr>
          <th>Id Grupo</th>
          <th>Nombre</th>
          <th>Permiso</th>
          <th/>
        </tr>
        </thead>
        <tbody>
        {data && data.map(item =>
          <tr key={item.hash}>
            <td>{item.groupId}</td>
            <td>{item.name}</td>
            <td>{item.level}</td>
            <td style={{display: "flex", justifyContent: "flex-end"}}>
              <ButtonBox>
                {policySalaId === salaId && <Button onClick={() => openDeleteModal(item.hash)}>Quitar</Button>}
              </ButtonBox>
            </td>
          </tr>
        )}
        </tbody>
      </Table>
      {!loading ? data.length <= 0 && <NoDataTable text={"Esta política no presenta permisos asociados"}/> : <Loader1/>}
      <DeleteGenericModal {...wrapper2}/>
    </React.Fragment>
  )
}

export default PolicyActions
