import React from "react";
import styled from "styled-components"

export const FlexLayout = styled.div`
  display: flex;
  flex-direction: ${props => props.column ? 'column' : 'row'};
  justify-content: ${props => props.justifyContent || 'flex-start'};
  align-items: ${props => props.alignItems || 'flex-start'};
  padding: ${props => props.padding || '0px'};
  margin: ${props => props.margin || '0px'};
  gap: ${props => props.gap || '8px'};
`

export const GridLayout = styled.div`
  display: grid;
  grid-template-columns: ${props => props.gridTemplateColumns || '1fr'};
  align-items: ${props => props.alignItems || 'flex-start'};
  padding: ${props => props.padding || '0px'};
  gap: ${props => props.gap || '8px'};
`

export const Flex = (props) => (
  <div
    className={props.className}
    style={{
      display: 'flex',
      justifyContent: props.justifyContent || 'flex-start',
      flexDirection: props.flexDirection || 'row',
      flexGrow: props.flexGrow || 0,
      flexBasis: props.flexBasis || 'auto',
      flexShrink: props.flexShrink || 1,
      flexWrap: props.flexWrap || 'nowrap',
      flex: props.flex || '0 1 auto',
      alignItems: props.alignItems || 'stretch',
      margin: props.margin || '0',
      padding: props.padding || '0',
      width: props.width || 'auto',
      height: props.height || 'auto',
      maxWidth: props.maxWidth || 'none'
    }}
  >
    {props.children}
  </div>
)
