import axios from "axios";
import {Apis} from "../util/Apis";

function meterSnapAnalize(params) {
  return axios.get(Apis.TGM + '/meter-snap/analize', {params});
}

function deltasSync(salaId, params) {
  return axios.get(Apis.TGM + '/deltas/sync', {params})
}

function deltasRecalc(salaId, params) {
  return axios.get(`${Apis.TGM}/deltas/recalc`, {params})
}

function deltasRecalcV2(salaId, params) {
  return axios.get(`${Apis.TGM}/deltas/recalc/v2`, {params})
}

function syncMachines(salaId) {
  return axios.get(Apis.TGM + `/machines/sync`)
}

function addCereo(meterSnapId) {
  return axios.post(Apis.TGM + `/meter-snap/${meterSnapId}/add-cereo`)
}

function meterSnapEnable(meterSnapId) {
  return axios.post(Apis.TGM + `/meter-snap/${meterSnapId}/enable`)
}

function meterSnapDisable(meterSnapId) {
  return axios.post(Apis.TGM + `/meter-snap/${meterSnapId}/disable`)
}

function nextMachine(confId, salaId) {
  return axios.get(Apis.TGM + `/machines/${confId}/next`)
}

function previousMachine(confId, salaId) {
  return axios.get(Apis.TGM + `/machines/${confId}/prev`)
}

const tgmApi = Object.freeze({
  meterSnapAnalize,
  deltasSync,
  deltasRecalc,
  deltasRecalcV2,
  syncMachines,
  addCereo,
  meterSnapEnable,
  meterSnapDisable,
  previousMachine,
  nextMachine
});

export default tgmApi;
