import styled from "styled-components";
import {useRef, useState} from "react";
import {useOutsideClick} from "../../hooks/useOutsideClick";

const CircleName = styled.div`
  background: linear-gradient(rgb(82, 97, 221), rgb(19, 181, 234));
  color: rgb(255, 255, 255);
  border-color: #069cf9;
  height: 30px;
  width: 30px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  position:relative;
  font-size: 13px;
`

const Menu = styled.div`
  position:absolute;
  z-index: 100;
  overflow: visible; 
  background: #ffffff;
  color: #121212;
  top: 130%;
  right: 0;
  padding: 1em;
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.2) 5px 5px 10px;
  border-radius: 10px;
  font-size: 1rem;
  visibility: ${props => props.visible ? 'visible' : 'hidden'};
  display: flex;
  flex-direction: column;
  
  opacity: 1;
  gap: 0.5em;
`

const MenuList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: block;
  margin-inline-start: 0;
  margin-inline-end: 0;
  font-weight: 500;
  font-size: 0.9em;
`

const CustomAvatar = ({name,children}) => {

  const componentRef = useRef();

  const [visible,setVisible] = useState(false);

  useOutsideClick(componentRef.current , () => {
    setVisible(false)
  });

  return(
    <CircleName type="button" ref={componentRef} onClick={()=>setVisible(!visible)}>
      <span>{name?.substring(0,3)}</span>
      <Menu visible={visible} >
        <h3 style={{fontSize: '1em',fontWeight: 700, textAlign: "center", lineHeight: "1.2em"}}>Hola {name}</h3>
        <MenuList>
          {children}
        </MenuList>
      </Menu>
    </CircleName>
  )
}

export default CustomAvatar
