import React, {useEffect} from "react";
import {Apis} from "../../../../../util/Apis";
import axios from "axios";
import SerieForm from "./SerieForm";


const SerieNew = ({info,setAction, setTitle}) => {

  const onCreate = (params) => {
    params.eventId = info.eventId
    if(params.eventId !== 0) return axios.post(Apis.BINGO + '/serie', params)
  }

  useEffect(() => {
    setTitle("Nueva serie")
  })

  return (
    <SerieForm onSubmit={onCreate} setAction={setAction}/>
  )
}

export default SerieNew
