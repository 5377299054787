import React, {useEffect, useMemo, useState} from "react";
import useFetch2 from "../../../../../hooks/useFetch2";
import {Apis} from "../../../../../util/Apis";
import AsyncBlock from "../../../../../components/forms/AsyncBlock";
import {FlexLayout} from "../../../../../components/flex/Flex";

const CardShow = ({ids, setTitle}) => {

  const {loading, data, error} = useFetch2(Apis.BINGO + `/bingo-card-number?bingoCardId=${ids.bingoCard}`, [], {});
  const {data: dataSorteo} = useFetch2(Apis.BINGO + `/sorteo/info/${ids.sorteo}`, []);
  const [dataTable, setDataTable] = useState([])

  useEffect(() => {
    setTitle("Cartilla")
  }, [])

  useMemo(() => {
    const groupedData = data.reduce((grouped, item) => {
      const {yaxis} = item;
      if (!grouped[yaxis]) {
        grouped[yaxis] = {data: [], yaxis};
      }
      grouped[yaxis].data.push({xaxis: item.xaxis, number: item.number});
      return grouped;
    }, {});
    let grouped = Object.values(groupedData).sort((a, b) => b.yaxis - a.yaxis)
    setDataTable(grouped)
  }, [data])

  const generateRow = (data) => {
    const componentes = []
    for (let i = 0; i < (dataSorteo.type === 90 ? 9 : 5); i++) {
      const coordenada = data.find((item) => item.xaxis === i)
      componentes.push(<div style={{height: 50, width: 50, fontSize: 30, border: "1px solid #0A0A0A", textAlign: "center",borderCollapse:"collapse"}}>
        {coordenada ? coordenada.number : ''}
      </div>)
    }
    return componentes
  }

  return (
    <AsyncBlock loading={loading} error={error}>
      <FlexLayout column gap='0' alignItems='center'>
        {dataTable.map((item) => {
          return (
            <div style={{flexDirection: "row", display: "flex", borderCollapse:'collapse'}}>
              {generateRow(item.data)}
            </div>)
        })}
      </FlexLayout>
    </AsyncBlock>
  )
}

export default CardShow
