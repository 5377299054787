import React, {useState} from 'react'
import Table from "react-bootstrap/Table";
import {useHistory, useParams} from "react-router";
import PageContainer from "../../../../../components/layout/PageContainer";
import PageSection from "../../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import {FormBlock} from "../../../../../components/forms/FormGroup";
import Loader1 from "../../../../common/components/Loader1";
import {Apis} from "../../../../../util/Apis";
import {PageBackLabel} from "../../../../../components/layout/PageBackLabel";
import axios from 'axios'
import {useForm} from "react-hook-form";
import NoDataTable from "../../../../common/components/NoDataTable";
import Button from "../../../../../components/forms/Button";
import useFetch2 from "../../../../../hooks/useFetch2";
import CustomerLink from '../../../../../components/util/CustomerLink';

const AddUsers = () => {

  let history = useHistory()
  const {id} = useParams()
  const {loading, data} = useFetch2(Apis.USER + '/users', []);
  const {handleSubmit} = useForm();
  const {groupName} = useParams()

  let arr = {
    userIds: []
  }

  const [selectedValue, setSelectedValue] = useState(arr);

  const handleSingleChange = (event, item) => {
    let yaEsta = false;
    const arrLength = arr.userIds.length
    if (!event.target.checked) {
      for (let i = 0; i < arrLength; i++) {
        if (arr.userIds[i] === item.id) {
          arr.userIds.splice(i, 1)
          break;
        }
      }
    } else if (event.target.checked) {
      for (let i = 0; i < arrLength; i++) {
        if (arr.userIds[i] === item.id) {
          yaEsta = true;
          break;
        }
      }
      if (!yaEsta) {
        arr.userIds.push(item.id)
      }
    }
  }
  const onSubmit = (params) => {
    /*console.log(selectedValue)*/
    console.log(arr)
    axios.put(`http://localhost:9081/user-group/${id}/attach-users`, arr).then(res => {
        history.push('/admin/groups/edit/' + id + '/' + groupName)
      }
    ).catch()

  }


  return (
    <PageContainer>
      <PageSection>
        <PageBackLabel text={`Grupo ${groupName}`} onClick={() => history.goBack()}/>
          <Title text={groupName + ' | Añadir Usuarios'} type={'page'}/>
      </PageSection>

      <PageSection>
        <FormBlock>
          <Table style={{marginTop: "20px"}} striped hover>
            <thead>
            <tr>
              <th>Seleccionar</th>
              <th>id</th>
              <th>Nombre</th>
              <th>Usuario</th>
              <th>Email</th>
            </tr>
            </thead>
            <tbody>
            {data.map((item, index) =>
              <tr key={item.id}>
                <td>
                  <input type="checkbox"
                         key={item.id}
                         checked={selectedValue[index.toString()]}
                         onChange={(event) => handleSingleChange(event, item)}
                  />
                </td>
                <td>{item.id}</td>
                <td>{<CustomerLink item={item}/>}</td>
                <td>{item.username}</td>
                <td>{item.email}</td>
              </tr>
            )}
            </tbody>
          </Table>
          {!loading ? data.length <= 0 && <NoDataTable text={"No existen usuarios registrados en el sistema"}/> :
            <Loader1/>}
          <Button style={{marginTop: "15px"}} onClick={() => handleSubmit(onSubmit())}>Añadir</Button>
        </FormBlock>
      </PageSection>
    </PageContainer>
  )
}

export default AddUsers
