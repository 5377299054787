import React, {useCallback, useContext, useMemo, useState} from "react";
import {Title} from "@wargostec/react-components";
import Moment from 'react-moment';
import {BsEmojiFrown} from "react-icons/bs";
import moment from "moment";
import {Controller, useForm} from "react-hook-form";
import PageContainerFull from "../../../../components/layout/PageContainerFull";
import PageSection from "../../../../components/layout/PageSection";
import Button from "../../../../components/forms/Button";
import Slidebar from "../../../common/layout/Slidebar";
import FormGroup from "../../../../components/forms/FormGroup";
import {DatePickerCustom} from "../../../../components/forms/FormEl";
import {Apis} from "../../../../util/Apis";
import SalaIdInput from "../../../common/components/SalaIdInput";
import {SiteContext} from "../../../common/context/SiteContext";
import {NumberRound} from "../../../../components/util/NumberUtil";
import CustomerSearchInputV2 from "../../../common/components/CustomerSearchInputV2";
import LateLoginModal from "../../modal-body/LateLoginModal";
import GenericModal2 from "../../../../modals/GenericModal2";
import {serializeDownload} from "../../../../components/util/SerializeParams";
import MachineSearchInput from "../../../common/components/MachineSearchInput";
import CustomerLink from "../../../../components/util/CustomerLink";
import MoneyColored from "../../../../components/util/MoneyColored";
import {FlexLayout} from "../../../../components/flex/Flex";
import AsyncBlock from "../../../../components/forms/AsyncBlock";
import MethodOverlay from "../../../../components/util/MethodOverlay";
import ReactTable from "../../../../components/util/ReactTable";
import DetailCouponsWin from "../../modal-body/DetailCouponsWin";
import {getBetType} from "../../../../util";
import useToggle from "../../../../hooks/useToggle";
import useGet from "../../../../hooks/useGet";
import useModal from "../../../../modals/useNewModal";

const SessionList = () => {

  const {isOpen: slideOpen, setIsOpen: setSlideOpen} = useToggle({})
  const {salaId} = useContext(SiteContext)

  const queryString = window.location.search
  const query = new URLSearchParams(queryString)
  const employeeId = query.get('employeeId')
  const customerId = query.get('customerId')
  let iniQueryParams = query.get('ini')
  let finQueryParams = query.get('fin')
  let confId = query.get('confId')

  const hoy = moment().format('YYYY-MM-DD')

  if (!iniQueryParams) iniQueryParams = hoy
  if (!finQueryParams) finQueryParams = hoy

  const defaultParams = {
    salaId: salaId,
    ini: iniQueryParams,
    fin: finQueryParams,
    employeeId: employeeId,
    confId: confId,
    customerId
  }

  const [params, setParams] = useState(defaultParams)

  const {
    register,
    handleSubmit,
    control
  } = useForm({defaultValues: defaultParams});

  const {loading, data, fetchData, error} = useGet({
    url: Apis.PC + '/session',
    initialValue: [],
    params
  })

  const getData = (params) => {
    setParams(params)
    fetchData(params)
  }


  const download = () => {
    const link = Apis.PC + `/session/report.xlsx?${serializeDownload(params)}`
    window.location.href = link;
  }

  const {wrapper, someAction} = useModal({
    component: <LateLoginModal/>,
    onRefresh: () => fetchData(params)
  })

  const {wrapper: wrapper1, someAction: getCoupons} = useModal({
    component: <DetailCouponsWin/>
  })

  const clientValue = useCallback(props => <>{
    props.row.original.customerId > 0 ?
      <CustomerLink item={props.row.original}/>
      :
      <>
        {props.row.original.rejected ?
          <span style={{color: "#8b0000"}}>No desea <BsEmojiFrown/></span>
          :
          <span style={{color: "#8b0000"}}>No registrado</span>
        }
      </>
  }</>, []);

  const methodValue = useCallback(props => <MethodOverlay method={props.renderValue()}
                                                          loginTime={props.row.original.loggedAt}/>, []);

  const dateValue = useCallback(props => props.renderValue() !== null ?
    <Moment format={'DD/MM HH:mm'}>{props.renderValue()}</Moment> : '--', []);

  const closedAtValue = useCallback(props => props.renderValue() !== null ?
    <Moment format={'DD/MM HH:mm'}>{props.renderValue()}</Moment> :
    <span style={{color: "#8b0000"}}>Abierta</span>, []);

  const durationValue = useCallback(props => !props.row.original.closedAt ? '--' : props.renderValue(), []);

  const numberValue = useCallback(props => <NumberRound>{props.renderValue()}</NumberRound>, []);

  const apPromValue = useCallback(props => <>{
    <FlexLayout alignItems='center' justifyContent='flex-end' gap='4px'>
      <span>{NumberRound({children: props.renderValue()})}</span>
      {getBetType({valor: NumberRound({children: props.renderValue()})})}
    </FlexLayout>
  }</>, []);

  const pointsValue = useCallback(props => <NumberRound digits={1}>{props.renderValue()}</NumberRound>, []);

  const netWinValue = useCallback(props => <MoneyColored>{props.renderValue()}</MoneyColored>, []);

  const actionsValue = useCallback(props => <>{
    <FlexLayout justifyContent='center'>
      {props.row.original.customerId === null &&
        <Button variant='light' size='xs' onClick={() => someAction({id: props.renderValue()})}>Loguear</Button>
      }
      <Button variant='light' size='xs' onClick={() => getCoupons({
        values: {
          id: props.row.original.id,
        }
      })}>Cupones</Button>
    </FlexLayout>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }</>, []);

  const columns = useMemo(
    () => [
      {
        header: 'Máquina',
        accessorKey: 'machine',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Modelo',
        accessorKey: 'groupName',
        meta: {
          columnClassName: 'font-small',
        },
      },
      {
        header: 'Cliente',
        id: 'customerIdx',
        accessorFn: cell => `${cell.firstName} ${cell.lastName}`,
        cell: clientValue
      },
      {
        header: 'Logueo',
        accessorKey: 'loginMethod',
        enableGlobalFilter: false, // para deshabilitar el filter
        enableSorting: false, // para deshabilitar el sort
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: methodValue
      },
      {
        header: 'Inicio',
        accessorKey: 'beginAt',
        enableGlobalFilter: false, // para deshabilitar el filter
        enableSorting: false, // para deshabilitar el sort
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: dateValue
      },
      {
        header: 'Fin',
        accessorKey: 'closedAt',
        enableGlobalFilter: false, // para deshabilitar el filter
        enableSorting: false, // para deshabilitar el sort
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: closedAtValue
      },
      {
        header: 'Duración',
        accessorKey: 'duration',
        enableGlobalFilter: false, // para deshabilitar el filter
        enableSorting: false, // para deshabilitar el sort
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: durationValue
      },
      {
        header: 'Apostado',
        accessorKey: 'ci',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
        cell: numberValue
      },
      {
        header: 'Ganado',
        accessorKey: 'co',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
        cell: numberValue
      },
      {
        header: 'Jackpot',
        accessorKey: 'jp',
        enableSorting: false, // para deshabilitar el sort
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
        cell: numberValue
      },
      {
        header: 'Jugadas',
        accessorKey: 'gp',
        enableSorting: false, // para deshabilitar el sort
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
      },
      {
        header: 'NetWin',
        accessorKey: 'netWin',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
        cell: netWinValue
      },
      {
        header: 'Ap. Prom.',
        accessorKey: 'apProm',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
        cell: apPromValue
      },
      {
        header: 'Puntos',
        accessorKey: 'points',
        enableGlobalFilter: false, // para deshabilitar el filter
        enableSorting: false, // para deshabilitar el sort
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
        cell: pointsValue
      },
      {
        header: 'Logueado',
        accessorKey: 'employee',
        enableGlobalFilter: false, // para deshabilitar el filter
        enableSorting: false, // para deshabilitar el sort
        meta: {
          columnClassName: 'font-small',
        },
      },
      {
        header: 'Acciones',
        accessorKey: 'id',
        enableGlobalFilter: false, // para deshabilitar el filter
        enableSorting: false, // para deshabilitar el sort
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: actionsValue
      }
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , []
  )

  return (
    <PageContainerFull>
      <Slidebar visible={slideOpen} onHide={() => setSlideOpen(false)}>
        <div style={{padding: '16px'}}>
          <SalaIdInput {...register("salaId")}/>

          <FormGroup>
            <label>Cliente</label>
            <Controller name="customerId" control={control}
                        render={({field}) => <CustomerSearchInputV2 {...field} />}/>
          </FormGroup>

          <FormGroup>
            <label>Máquina</label>
            <Controller name="confId" control={control}
                        render={({field}) => <MachineSearchInput {...field} />}/>
          </FormGroup>

          <FormGroup>
            <label>Desde</label>
            <DatePickerCustom {...register("ini")}/>
          </FormGroup>

          <FormGroup>
            <label>Hasta</label>
            <DatePickerCustom {...register("fin")}/>
          </FormGroup>

          <Button variant={'primary'} title='Buscar' onClick={handleSubmit(getData)}/>
        </div>
      </Slidebar>

      <PageSection>
        <Title text="Sesiones" type={'page'}/>

        <AsyncBlock loading={loading} error={error}>
          <ReactTable
            columns={columns}
            data={data || []}
            filterOnclick={() => setSlideOpen(!slideOpen)}
            filterDownload={download}
            noData='No existen resultados para el filtro seleccionado'
            pageSize={15}
          />
        </AsyncBlock>

      </PageSection>

      <GenericModal2 {...wrapper}/>
      <GenericModal2 {...wrapper1}/>
    </PageContainerFull>
  )
}

export default SessionList
