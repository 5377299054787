import React, {useEffect} from 'react'
import Form from "react-bootstrap/Form";
import FormGroup from "../../../../components/forms/FormGroup";
import {Controller, useForm} from "react-hook-form";
import {Select} from "../../../../components/forms/FormEl";
import CouponTypeSelectV2 from "../../../common/components/selects/CouponTypeSelectV2";

const AnforaForm = ({value, onSubmit, setAction}) => {

  const {register, handleSubmit, reset, control} = useForm();

  useEffect(() => {
    if (value) {
      //react hook form descartar las propiedades que no tienen un componente
      reset(value)
    }
  }, [value])

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit))
  })

  return (<Form>
      <FormGroup controlId="name">
        <Form.Label>Nombre</Form.Label>
        <Form.Control type="text" {...register("name")}/>
      </FormGroup>

      {/* <FormGroup controlId="couponTypeId">
        <Form.Label>Tipo de cupón</Form.Label>
        <Controller
          name="couponTypeId"
          control={control}
          render={({field}) => <CouponTypeSelectV2 {...field} />}
        />
      </FormGroup> */}

      <FormGroup controlId="optionMode">
        <Form.Label>Tipo de promoción</Form.Label>
        <Select {...register("optionMode")}>
          <option value={false}>CUPONES</option>
          <option value={true}>OPCIONES</option>
        </Select>
      </FormGroup>

      <FormGroup controlId="ini">
        <Form.Label>Inicio</Form.Label>
        <Form.Control type="date" {...register("ini")}/>
      </FormGroup>

      <FormGroup controlId="fin">
        <Form.Label>Fin</Form.Label>
        <Form.Control type="date" {...register("fin")}/>
      </FormGroup>
    </Form>)
}

export default AnforaForm
