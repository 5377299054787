import React, {useState} from 'react'
import styled from 'styled-components'
import {BsFullscreen, BsFullscreenExit} from 'react-icons/bs'
import {HiOutlineMap} from 'react-icons/hi2'
import {LuLayoutDashboard, LuSettings2} from 'react-icons/lu'
import {useHistory} from "react-router";
import Button from '../../../../components/forms/Button'
import LayoutInfoMachine from '../../../crm/pages/clientes/LayoutInfoMachine'
import {TransformComponent, TransformWrapper} from 'react-zoom-pan-pinch'
import OnlinePositionsLayout from '../../../crm/pages/clientes/OnlinePositions'
import BGLayout from '../../../common/backgrounds/BGLayout'
import LayoutControls from '../../../crm/pages/clientes/LayoutControls'
import LayoutSearchMachine from '../../../crm/pages/clientes/LayoutSearchMachine'
import AsyncBlock from '../../../../components/forms/AsyncBlock'
import {FlexLayout} from '../../../../components/flex/Flex'
import LayoutSearchGroup from '../../../crm/pages/clientes/LayoutSearchGroup'
import EmptyState from '../../../common/components/EmptyState'

const LayoutContainer = styled.div`
  width: 100%;
  height: ${props => props.setHeight || 'calc(100vh - 18rem)'};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: #fff;
  padding:8px;
  box-shadow: 0 0 25px 0 rgba(0,0,0,.05);
  transition: box-shadow .2s linear, border-color .2s linear, background-color .2s linear;
  transition: 0.5s all ease-in-out;
  position:relative;
  &.fullscreen {
    width: 100vw;
    height: 100vh;
    border-radius: 0;
    position:absolute;
    top:0;
    left:0;
    z-index:100;
    transition: 0.5s all ease-in-out;
  }

  @media screen and (max-width: 768px) {
    transition: 0.5s all ease-in-out;
  }
`
const LayoutOptions = styled.div`
  position: absolute;
  right: 12px;
  top: 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-end;
  z-index:1;
`

const LayoutFloors = styled.div`
  position: absolute;
  left: 12px;
  top: 12px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-end;
  z-index:1;
`

const LayoutCard = ({data, fetchData, loading, setHeight, type, caption, setLegendVisible, toggler}) => {

  const [selectedMachine, setSelectedMachine] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const history = useHistory()

  const [dialogOpen, setDialogOpen] = useState(false);
  const [controlsVisible, setControlsVisible] = useState(true);
  const [selectedIdx, setSelectedIdx] = useState(1);
  const [isFullscreen, setIsFullscreen] = useState(false)
  const [searchVisible, setSearchVisible] = useState(false);
  const [groupVisible, setGroupVisible] = useState(false);

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  }

  const handleButtonClick = (idx) => {
    setSelectedIdx(idx);
  };

  console.log(selectedMachine)

  return (
    <TransformWrapper initialScale={1}>
      {({zoomIn, zoomOut, resetTransform, zoomToElement}) => (
        <React.Fragment>
          <LayoutContainer setHeight={setHeight} className={isFullscreen ? 'fullscreen' : ''}>
            <LayoutFloors>
              {data?.frameDimensions?.map((item) => (
                <Button
                  key={item.id}
                  onClick={() => handleButtonClick(item.floorNumber)}
                  variant={selectedIdx === item.floorNumber ? 'dark' : 'light'}
                >
                  Piso {item.floorNumber}
                </Button>
              ))}
            </LayoutFloors>
            <LayoutOptions>
              <FlexLayout gap='8px' alignItems='center'>
                <Button size='s' onClick={() => setSearchVisible(prev => !prev)}>
                  <span>Máquina</span>
                </Button>
                <Button size='s' onClick={() => setGroupVisible(prev => !prev)}>Grupo</Button>
                <Button size='s' onClick={toggleFullscreen} >
                  {isFullscreen ? <BsFullscreenExit /> : <BsFullscreen />}
                </Button>
                <Button size='s' onClick={() => setLegendVisible(prev => !prev)}>
                  <HiOutlineMap />
                </Button>
                <Button size='s' onClick={() => setControlsVisible(prev => !prev)}>
                  <LuSettings2 />
                </Button>
              </FlexLayout>
              <LayoutControls
                zoomIn={() => zoomIn()}
                zoomOut={() => zoomOut()}
                resetTransform={() => resetTransform()}
                controlsVisible={controlsVisible}
              />
              {caption}
              <LayoutSearchMachine
                zoomToElement={zoomToElement}
                searchVisible={searchVisible}
                setSelectedMachine={setSelectedMachine}
                setDialogOpen={setDialogOpen}
                resetTransform={resetTransform}
              />
              <LayoutSearchGroup
                groupVisible={groupVisible}
                setSelectedGroup={setSelectedGroup}
                setDialogOpen={setDialogOpen}
                resetTransform={resetTransform}
              />
            </LayoutOptions>

            <LayoutInfoMachine
              selectedMachine={selectedMachine}
              setDialogOpen={setDialogOpen}
              dialogOpen={dialogOpen}
              fetchData={fetchData}
              type={type}
            />
            <AsyncBlock loading={loading} showLoader={false}>
              <TransformComponent contentStyle={{width: '100%', height: '100%'}}>
                {data?.frameDimensions ? (
                  data.frameDimensions.map(item => (
                    <React.Fragment key={item.id}>
                      {selectedIdx === item.floorNumber && (
                        <OnlinePositionsLayout
                          viewBox={'0 0 ' + item.width + ' ' + item.height}
                          background={<BGLayout url={item.svgFileUrl} />}
                          selectedMachine={selectedMachine}
                          selectedGroup={selectedGroup}
                          positions={data.positions.filter(pos => pos.floorNumber === item.floorNumber)}
                          setSelectedMachine={setSelectedMachine}
                          setDialogOpen={setDialogOpen}
                          condition={type}
                        />
                      )}
                    </React.Fragment>
                  ))
                ) : (
                  <FlexLayout column gap='16px' alignItems='center' justifyContent='center' style={{width: '100%'}}>
                    <EmptyState
                      title={'¡Ops! Parece que el diseño aún no está creado'}
                      subtitle={type !== 'config' ? 'Dirigete a configuración para comenzar a editar!' : '¿Quieres crear uno? ¡Contáctanos y te ayudaremos!'}
                      icon={<LuLayoutDashboard size={38} />}
                    />
                    {type !== 'config' &&
                      <div style={{display: 'flex', justifyContent: 'center'}}>
                        <Button variant='dark' onClick={() => history.push(`/tgm/layout-config`)}>
                          <span>Configuración</span>
                        </Button>
                      </div>
                    }
                  </FlexLayout>
                )}
              </TransformComponent>
            </AsyncBlock>

          </LayoutContainer>
        </React.Fragment>
      )}
    </TransformWrapper>
  )
}

export default LayoutCard
