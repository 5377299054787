import React, {useEffect} from 'react';
import PrinterForm from './PrinterForm';
import axios from "axios";
import { Apis } from "../../../../util/Apis";
import useFetch2 from "../../../../hooks/useFetch2";
import EventForm from "../../../bingos/pages/bingo/gestionEventos/EventForm";

const PrinterEdit = ({id, setAction, setTitle}) => {
  const {data, loading} = useFetch2(Apis.PRINT + '/printers/' + id)
  const update = (params) => {
    params.computerId = parseInt(params.computerId)
    return axios.put(Apis.PRINT + '/printers/' + id, params)
  }
  useEffect(() => {
    setTitle("Editar impresora")
  }, [])

  return (
  <React.Fragment>
    {!loading && <PrinterForm value={data} onSubmit={update} setAction={setAction} />}
  </React.Fragment>
  );
}

export default PrinterEdit;
