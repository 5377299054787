import {useForm} from "react-hook-form";
import React, {useEffect} from "react";
import Form from "react-bootstrap/Form";
import FormGroup from "../../../../components/forms/FormGroup";
import {Col, Row} from "../../../../components/flex/FlexLayout";

const RewardForm = ({value, onSubmit, setAction}) => {
  const {register, handleSubmit, reset} = useForm();

  useEffect(() => {
    if (value) {
      //react hook form descartar las propiedades que no tienen un componente
      reset(value)
    }
  }, [value])

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit))
  }, [])

  return (
    <Form>
      <FormGroup controlId="name">
        <Form.Label>Puntos Mínimos</Form.Label>
        <Form.Control type="number"  {...register("pointMin")}/>
      </FormGroup>

      <FormGroup>
        <Form.Label>Puntos Máximos</Form.Label>
        <Form.Control type={"number"} {...register("pointMax")}/>
      </FormGroup>

      <FormGroup>
        <Form.Label>Recompensa</Form.Label>
        <Form.Control type={"text"}  {...register("description")}/>
      </FormGroup>

      <FormGroup>
        <Row>
          <Col>
            <Form.Label>Fecha Inicio</Form.Label>
            <Form.Control type="date"  {...register("fechaInicio")}/>
          </Col>
          <Col>
            <Form.Label>Hora Inicio</Form.Label>
            <Form.Control type="time" {...register("horaInicio")}/>
          </Col>
        </Row>
      </FormGroup>
      <FormGroup>
        <Row>
          <Col>
            <Form.Label>Fecha Fin</Form.Label>
            <Form.Control type="date" {...register("fechaFin")}/>
          </Col>
          <Col>
            <Form.Label>Hora Fin</Form.Label>
            <Form.Control type="time" {...register("horaFin")}/>
          </Col>
        </Row>
      </FormGroup>
    </Form>
  )
}

export default RewardForm;
