import React, {useContext, useEffect} from 'react';
import axios from 'axios';
import {Apis} from "../../../../util/Apis";
import CerrarMesaForm from "./CerrarMesaForm";
import useFetch2 from "../../../../hooks/useFetch2";
import {SiteContext} from "../../../common/context/SiteContext";
import {errorToast, successToast} from "../../../../components/layout/ToastStyles";

const EditarTransacction = ({id,setAction, setTitle}) => {

  const {salaId} = useContext(SiteContext)

  const params = {salaId: salaId}

  const {data} = useFetch2(Apis.TABLES + '/tables/report',{},params)
  const {data: table} = useFetch2(Apis.TABLES + `/tables/${id?.tableId}`,{},params)

  const save = (params) => {
    const newParams = {
      amountCents : params.amountCents,
      valueTokens : params.valueTokens
    }
    axios.put(Apis.TABLES + '/transaction/' + id?.transactionId, newParams)
      .then(() => {
        successToast()
      })
      .catch(() => {
        errorToast();
      })
  }

  useEffect(() => {
    setTitle("Editar cierre")
  }, [])

  return (
   <CerrarMesaForm id={id} type={table && table.type} data={data?.length > 0 ? data?.find((item)=>item.tableId === id?.tableId) : []} onSubmit={save} setAction={setAction}/>
  );
};

export default EditarTransacction;
