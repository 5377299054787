import React, {useEffect, useMemo, useState} from 'react';
import PageSection from "../../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import Card from "../../../../../components/forms/Card";
import PageContainer from "../../../../../components/layout/PageContainer";
import useFetch2 from "../../../../../hooks/useFetch2";
import {Apis} from "../../../../../util/Apis";
import DateFilter from "../../../../../components/datefilter/DateFilter";
import {getCasinoDay} from "../../../../../components/util/CasinoDates";
import Button from "../../../../../components/forms/Button";
import SalaIdInput from "../../../../common/components/SalaIdInput";
import FormGroup from "../../../../../components/forms/FormGroup";
import MachineSelect from "../../../../common/components/selects/MachineSelect";
import {DatePickerCustom} from "../../../../../components/forms/FormEl";
import Slidebar from "../../../../common/layout/Slidebar";
import {Controller, useForm} from "react-hook-form";
import MachineEventSelect from "../../../../common/components/selects/MachineEventSelect";
import {DateTime} from 'luxon';
import AsyncBlock from '../../../../../components/forms/AsyncBlock';
import ReactTable from '../../../../../components/util/ReactTable';

const MachineEvents = () => {

  const casinoDay = getCasinoDay()
  const fechasDefault = {ini: casinoDay, fin: casinoDay}
  const [fechas, setFechas] = useState(fechasDefault)
  const [slideOpen, setSlideOpen] = useState(false)

  let defaultParams = {ini: fechas.ini, fin: fechas.fin}
  const [params, setParams] = useState(defaultParams)

  const {data, loading, fetchData, error} = useFetch2(Apis.TGM + `/events`, [], params);
  const {register, control, handleSubmit,} = useForm({defaultValues: defaultParams});

  const getData = (params) => {
    if (params.confId === '0' || params.confId === 0) {
      params.confId = null
    }
    setParams(params)
  }

  useEffect(() => {
    setParams({...params, ...fechas})
  }, [fechas])

  useEffect(() => {
    if (params) fetchData()
  }, [params])

  const columns = useMemo(
    () => [
      {
        header: 'Id',
        accessorKey: 'id',
        meta: {
          headerClassName: 'text-center font-small',
          columnClassName: 'text-center font-small',
        },
      },
      {
        header: 'Maquina',
        accessorKey: 'machine'
      },
      {
        header: 'Fecha',
        id: 'eventDate',
        accessorFn: row => row.eventDate ? DateTime.fromISO(row.eventDate).toFormat("yyyy-MM-dd HH:mm:ss"): '--',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Id evento',
        accessorKey: 'eventId',
        meta: {
          headerClassName: 'text-center font-small',
          columnClassName: 'text-center font-small',
        },
      },
      {
        header: 'Evento',
        accessorKey: 'nameEventEs'
      },
    ],
    []
  );

  return (
    <PageContainer>

      <Slidebar visible={slideOpen} onHide={() => setSlideOpen(false)}>
        <div style={{padding: '16px'}}>
          <SalaIdInput {...register("salaId")}/>

          <FormGroup>
            <label>Maquina</label>
            <MachineSelect todos={1} {...register("confId")}/>
          </FormGroup>
          <FormGroup>
            <label>Desde</label>
            <DatePickerCustom {...register("ini")}/>
          </FormGroup>
          <FormGroup>
            <label>Hasta</label>
            <DatePickerCustom {...register("fin")}/>
          </FormGroup>
          <FormGroup>
            <label>Evento</label>
            <Controller
              name="eventId"
              control={control}
              render={({field}) => <MachineEventSelect {...field} />}
            />
          </FormGroup>

          <Button variant={'primary'} title='Buscar' onClick={handleSubmit(getData)}/>
        </div>
      </Slidebar>

      <PageSection>
        <Title text="Eventos de máquinas" type={'page'}/>
        <Card padding style={{background: '#0A0A0A'}}>
          <DateFilter fechas={fechas} setFechas={setFechas}/>
        </Card>
        <AsyncBlock loading={loading} error={error}>
          <ReactTable
            columns={columns}
            data={data || []}
            customSearch
            noData='No existen resultados'
            pageSize={100}
            filterRefresh={fetchData}
            filterOnclick={() => setSlideOpen(!slideOpen)}
          />
        </AsyncBlock>
      </PageSection>
    </PageContainer>
  );
};

export default MachineEvents;
