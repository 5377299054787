import React, {useEffect} from 'react';
import PageCardTitle from '../../../../components/layout/CardTitle';
import FormGroup from '../../../../components/forms/FormGroup';
import SwitchToggle from '../../../../components/layout/SwitchToggle';
import Input from '../../../../components/forms/FormEl';
import {useForm} from 'react-hook-form';
import Card from '../../../../components/forms/Card';
import Button, {ButtonBox} from '../../../../components/forms/Button';
import Table from 'react-bootstrap/Table';
import useFetch2 from '../../../../hooks/useFetch2';
import {Apis} from '../../../../util/Apis';
import {useParams} from 'react-router';
import useEditModal from '../../../../modals/useEditModal';
import SiteSalaEdit from './site-sala/SiteSalaEdit';
import SiteSalaNew from './site-sala/SiteSalaNew';
import GenericModal2 from '../../../../modals/GenericModal2';
import useDeleteModal from '../../../../modals/useDeleteModal';
import DeleteGenericModal from '../../../../modals/DeleteGenericModal';
import axios from 'axios';
import AsyncBlock from '../../../../components/forms/AsyncBlock';

const SiteForm = ({value, onSubmit, mode = false, fetchData}) => {
	const {id} = useParams();
	const {register, handleSubmit, reset} = useForm();

	const deleteElement = (id) =>
		axios.delete(Apis.CUSTOMERAPP + '/site-sala/' + id);

	const {
		loading: loading2,
		data: data2,
		fetchData: fetchData2,
		error: error2
	} = useFetch2(
		Apis.CUSTOMERAPP + `/site-sala/by-site-id/${id}`,
		[],
		{},
		false
	);

	const {
		wrapper: wrapper1,
		addAction,
		editAction
	} = useEditModal({
		editComponent: <SiteSalaEdit />,
		addComponent: <SiteSalaNew />,
		onRefresh: fetchData
	});

	const {wrapper: wrapper2, openDeleteModal} = useDeleteModal({
		text: '¿Desea eliminar esta sala?',
		action: deleteElement,
		onRefresh: fetchData
	});

	useEffect(() => {
		if (value) {
			reset(value);
		}
	}, [value]);

	useEffect(() => {
		if (mode) {
			fetchData2();
		}
	}, [mode]);

	return (
		<>
			<PageCardTitle title={'General'} />

			<Card padding>
				{mode && (
					<FormGroup>
						<SwitchToggle
							{...register('enabled')}
							id={'enabled'}
							label={'Habilitado'}
						/>
					</FormGroup>
				)}
				<FormGroup>
					<label>Nombre</label>
					<Input name='name' {...register('name')} />
				</FormGroup>
				<FormGroup>
					<label>Dominio</label>
					<Input name='defaultDomain' {...register('defaultDomain')} />
				</FormGroup>
				<FormGroup>
					<label>Url</label>
					<Input name='defaultUrl' {...register('defaultUrl')} />
				</FormGroup>
			</Card>

			{mode && (
				<>
					<PageCardTitle title={'Información'} />

					<React.Fragment>
						<PageCardTitle title={'Sala'} />
						<Card padding>
							<Button
								title='Agregar'
								variant='primary'
								size='s'
								onClick={() => addAction()}
							/>
							<AsyncBlock loading={loading2} error={error2}>
								<FormGroup style={{maxHeight: '500px', overflowY: 'auto'}}>
									<Table striped hover>
										<thead>
											<tr>
												<th>SalaId</th>
												<th>Nombre</th>
												<th>Compañia</th>
											</tr>
										</thead>
										<tbody>
											{data2 &&
												data2.map((item, index) => (
													<tr key={item.id}>
														<td>{item.salaId}</td>
														<td>{item.name}</td>
														<td>{item.company}</td>
														<td>
															<ButtonBox>
																<Button
																	variant={'light'}
																	size='s'
																	title={'Editar'}
																	onClick={() => editAction(item.salaId)}
																/>
																<Button
																	variant={'danger'}
																	size='s'
																	title={'Eliminar'}
																	onClick={() => openDeleteModal(item.salaId)}
																/>
															</ButtonBox>
														</td>
													</tr>
												))}
										</tbody>
									</Table>
								</FormGroup>
							</AsyncBlock>
						</Card>
					</React.Fragment>
				</>
			)}

			<ButtonBox>
				<Button variant={'primary'} onClick={handleSubmit(onSubmit)}>
					Guardar
				</Button>
			</ButtonBox>

			<GenericModal2 {...wrapper1} />
			<DeleteGenericModal {...wrapper2} />
		</>
	);
};

export default SiteForm;
