import React, {useState} from 'react'
import PageContainerFull from '../../../../components/layout/PageContainerFull'
import PageSection from '../../../../components/layout/PageSection'
import {Title} from "@wargostec/react-components";
import {getCasinoDay} from '../../../../components/util/CasinoDates'
import useFetch2 from '../../../../hooks/useFetch2'
import {Apis} from '../../../../util/Apis'
import LayoutCard from './LayoutCard'
import LayoutLegend from '../../../crm/pages/clientes/LayoutLegend'
import Button, {ButtonBox} from '../../../../components/forms/Button'
import {errorToast, successToast} from '../../../../components/layout/ToastStyles'
import {BiLogoFigma} from "react-icons/bi";
import {BsFiletypeSvg} from "react-icons/bs";
import useEditModal from "../../../../modals/useEditModal";
import LayoutSvg from "./LayoutSvg";
import GenericModal2 from "../../../../modals/GenericModal2";

const LayoutConfig = () => {

  const [legendVisible, setLegendVisible] = useState(true);
  const casinoDay = getCasinoDay()
  const [fechas, setFechas] = useState({ini: casinoDay, fin: casinoDay})

  const {data, loading, fetchData} = useFetch2(`${Apis.TGM}/layout/zonas-calientes`, [], fechas)

  const {fetchData: fetchSync, loading: loadingSync} = useFetch2(`${Apis.TGM}/layout/sync`)

  const legendData = [
    {label: 'Posición libre', color: '#d4d4d4'},
    {label: 'Posición guardada', color: '#3b82f6'},
    {label: 'Posición seleccionada', color: '#22c55e'},
  ]

  const handleSyncAndFetch = async () => {
    try {
      await fetchSync();
      if (!loadingSync) {
        await fetchData();
        successToast('Se actualizaron las posiciones correctamente');
      }
    } catch (error) {
      errorToast('Hubo un error al sincronizar posiciones: ' + error);
    }
  };

  const goFigma = (layoutKey) => {
    window.open('https://www.figma.com/file/' + layoutKey);
  }

  const {
    wrapper,
    addAction: addSvg
  } = useEditModal({
    addComponent: <LayoutSvg/>,
    onRefresh: fetchData
  });

  return (
    <PageContainerFull>
      <PageSection>
        <Title text="Configuración" type={'page'}/>
        <div style={{paddingBottom: '16px'}}>
          <ButtonBox>
            <Button variant='dark' onClick={handleSyncAndFetch}
                    disabled={loadingSync}>{loadingSync ? 'Cargando...' : 'Sincronizar posiciones'}</Button>
            {
              data && data.layoutKey &&
              <Button variant='light' onClick={() => goFigma(data && data.layoutKey)}><BiLogoFigma size={24}/>Editar
                layout</Button>
            }
            <Button variant='light' onClick={() => addSvg(data && data.layoutId)}><BsFiletypeSvg
              size={24}/>SVG</Button>
          </ButtonBox>
        </div>
        <LayoutCard
          data={data}
          loading={loading}
          setHeight='calc(100vh - 14rem)'
          type='config'
          setLegendVisible={setLegendVisible}
          fetchData={fetchData}
          caption={
            <LayoutLegend
              legendVisible={legendVisible}
              legendData={legendData}
            />
          }
        />
      </PageSection>
      <GenericModal2 {...wrapper} />
    </PageContainerFull>
  )
}

export default LayoutConfig
