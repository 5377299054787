import React, {useEffect} from 'react'
import FormGroup from "../../../../../components/forms/FormGroup";
import Input from "../../../../../components/forms/FormEl";
import SalaIdInput from "../../../../common/components/SalaIdInput";
import {useForm} from "react-hook-form";

const ZoneModalForm = ({value, onSubmit, setAction}) => {

  const {register, handleSubmit, reset} = useForm();

  useEffect(() => {
    if (value) {
      reset(value)
    }
  }, [value])

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit))
  }, [])

  return (
    <React.Fragment>
      <SalaIdInput {...register("salaId")}/>
      <FormGroup>
        <label>Nombre</label>
        <Input type='text' {...register("name")}/>
      </FormGroup>
    </React.Fragment>
  )

}

export default ZoneModalForm
