import React, {useContext, useState} from 'react';
import {Apis} from "../../../../util/Apis";
import {Controller, useForm} from "react-hook-form";
import PageContainer from "../../../../components/layout/PageContainer";
import PageSection from "../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import FormGroup, {FormBlock} from "../../../../components/forms/FormGroup";
import MetodoSelectCaja from "../../../common/components/selects/MetodoSelectCaja";
import CustomerSearchInputV2 from "../../../common/components/CustomerSearchInputV2";
import Button, {ButtonBox} from "../../../../components/forms/Button";
import axios from "axios";
import {SiteContext} from "../../../common/context/SiteContext";
import FondoSelect from "../../../common/components/selects/FondoSelect";
import {errorToast, successToast} from "../../../../components/layout/ToastStyles";
import TableAmountsMesa from "../../../../components/util/TableAmountsMesa";
import useFilasMesa from "../../../../hooks/useFilasMesa";
import Input, {Select} from "../../../../components/forms/FormEl";
import {useHistory} from "react-router";

const FichasMesas = () => {

  const history = useHistory()
  const {salaId} = useContext(SiteContext)
  let defaultParams = {salaId: salaId}
  const {register, control, watch, handleSubmit, reset, setValue,getValues} = useForm({defaultValues: defaultParams});
  const {rows,setRows,total, clear: clearDenom} = useFilasMesa('CAJA')
  const [enable,setEnable] = useState(false)

  const watchType = watch("type")
  const watchTypePay = watch("payType")
  const watchCustomerId = watch("customerId")
  const onCreate = (params) => {
    params.detail = rows
    params.amount = total
    if (params.type == 2 || params.payType == 1){
      params.comission = 0
    }
    axios.post(Apis.CAJA + '/operation-mesas', params).then(res => {
      successToast()
      clearDenom()
      clear()
    }).catch(err => {
      errorToast();
    })
  }

  const clear = () => {
    reset({customerId: ''})
  }

  const getProdPerCustomer = () => {
    const customerId = getValues('customerId')
    const type = Number(getValues('type'))
    if (customerId !== undefined && type === 2) {
      setEnable(true)
      axios.get(Apis.CAJA + '/operation-mesas/prod-per-customer', {
        params: {
          salaId: salaId,
          customerId: customerId
        }
      }).then(res => {
        console.log(res.data)
        setValue('prod', res.data.totalAmount)
      }).catch(err => {
        errorToast();
      })
    } else {
      errorToast("Seleccione la operación correcta", 1000);
      setEnable(false)
    }

  }

  const getFichesCommission = () => {
    let commissionRate = 0
    const type = Number(getValues('type'))
    const typePay = Number(getValues('payType'))
    if (type === 1 && typePay === 2) {
      commissionRate = 0.03
    } else if (type === 1 && typePay === 3) {
      commissionRate = 0.065
    } else {
      return commissionRate
    }
    const fichesCommission = total * commissionRate
    setValue('commission', fichesCommission)
    return fichesCommission
  }

  const newCustomer = () => {
    history.push('/crm/new-customer')
  }

  return (
    <PageContainer>
      <PageSection>
            <Title text="Compras / Ventas de fichas" type={'page'}/>
      </PageSection>

      <PageSection>
        <FormBlock>
          <FormGroup>
            <label> Operación </label>
            <MetodoSelectCaja {...register("type")}/>
          </FormGroup>
          <FormGroup>
            <label> Fondo </label>
            <FondoSelect {...register("fondoId")}/>
          </FormGroup>
          {
            watchType == 1 &&
            <FormGroup>
              <label> Pago </label>
              <Select {...register("payType")}>
                <option key={1} value={1}>EFECTIVO</option>
                <option key={2} value={2}>NICO</option>
                <option key={3} value={3}>POS</option>
              </Select>
            </FormGroup>
          }
          <div>
            <FormGroup>
              <label>Cliente</label>
              <Controller name="customerId" control={control}
                          render={({field}) => <CustomerSearchInputV2 {...field} />}/>
            </FormGroup>
            {
             !(watchCustomerId > 0)  &&
              <Button title={"Nuevo"} onClick={() => newCustomer()} variant='light' size={'s'} />
            }
          </div>
          {
           watchType && watchType != 1 &&
          <div style={{paddingBottom: '10px'}}>
            <Button title='Calcular produccíon' variant='success' size='s' onClick={() => getProdPerCustomer()}/>

              <FormGroup style={{paddingTop: '5px'}}>
                <label> Producción </label>
                <Input {...register("prod")} disabled={true}/>
              </FormGroup>
          </div>
          }
          <FormGroup>
            <label> Fichas </label>
            <div style={{display: "flex", justifyContent: "center"}}>
              <TableAmountsMesa rows={rows} setRows={setRows} total={total} />
            </div>
          </FormGroup>
          {
            watchType == 1 && watchTypePay != 1 && (
              <div style={{paddingBottom: '10px'}}>
                <Button title='Calcular comisión' variant='success' size='s' onClick={() => getFichesCommission()}/>
                <FormGroup style={{paddingTop: '5px'}}>
                  <label> Fichas de comisión </label>
                  <Input {...register("commission")} disabled={true}/>
                </FormGroup>
              </div>
            )}
          <ButtonBox>
            <Button title={"Guardar"} variant={'primary'} onClick={handleSubmit(onCreate)}/>
          </ButtonBox>
        </FormBlock>
      </PageSection>
    </PageContainer>
  );
};

export default FichasMesas;
