import React from 'react'
import {SelectV2} from "../../../../components/forms/FormEl";

const PrinterStatusSelect = React.forwardRef((props, ref) => {

  return (
    <SelectV2 ref={ref} {...props} options={[
      {id: 'TODOS', name: 'TODOS'},
      {id: 'NEW_JOB', name: 'NUEVO'},
      {id: 'SENT_TO_CLIENT', name: 'ENVIADO'},
      {id: 'ERROR', name: 'ERROR'},
      {id: 'DONE', name: 'COMPLETADO'}
    ]}>
    </SelectV2>
  )
})

export default PrinterStatusSelect;
