import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { BiTrash } from "react-icons/bi";
import FormGroup from "../../../../components/forms/FormGroup";
import Input from "../../../../components/forms/FormEl";
import Button, { ButtonBox } from "../../../../components/forms/Button";
import { Col, Row } from "../../../../components/flex/FlexLayout";
import { Apis } from "../../../../util/Apis";
import { errorToast, successToast } from "../../../../components/layout/ToastStyles";
import PromoConfig from "../../../../components/promoConfig/PromoConfig";
import MachineGroupSelect from "../../../common/components/selects/MachineGroupSelect";
import PageCardTitle from "../../../../components/layout/CardTitle";
import Card from "../../../../components/forms/Card";
import CouponTypeSelectV2 from "../../../common/components/selects/CouponTypeSelectV2";
import { useDelete } from "../../../../hooks/useDelete";

const CouponPromoForm = ({ value, onSubmit, edit }) => {

  const history = useHistory()
  const [dayOfWeekArray, setDayOfWeekArray] = useState([])

  const { register, handleSubmit, control } = useForm({
    defaultValues: value
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "listHourRange"
  });

  useEffect(() => {
    if (value) {
      if (value.daysOfWeek !== undefined && value.daysOfWeek !== null) {
        setDayOfWeekArray(value.daysOfWeek)
      }
    }
  }, [value])

  const agregarHoras = () => {
    append({ ini: '', fin: '' });
  }

  const { mutate: deleteMultiplica } = useDelete({
    url: Apis.PC + '/coupon-type-promo/' + value._id,
    onSuccess: () => {
      successToast("Promoción borrada exitosamente")
      history.push('/crm/coupon-promo')
    },
    onError: () => errorToast("Hubo problemas para borrar la Promoción. Intente nuevamente en unos minutos.")
  })

  const preSubmit = (couponPromoForm) => {
    couponPromoForm.daysOfWeek = dayOfWeekArray
    onSubmit(couponPromoForm)
  }

  return (<>
    <Card padding>
      <PageCardTitle title={'Datos generales'} />
      <div>
        <Row>
          <Col>
            <FormGroup>
              <label>Promoción</label>
              <Controller
                name="couponTypeId"
                control={control}
                render={({ field }) => <CouponTypeSelectV2 {...field} />}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <label>Multiplicador ( X ) </label>
              <Input {...register("multiplier")} />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <label>Máquinas</label>
              <Controller
                name="machineGroupId"
                control={control}
                render={({ field }) => <MachineGroupSelect {...field} />}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>

      <PromoConfig
        fields={fields}
        dayOfWeekArray={dayOfWeekArray}
        setDayOfWeekArray={setDayOfWeekArray}
        register={register}
        remove={remove}
        agregarHoras={agregarHoras}
      />
    </Card>

    <ButtonBox>
      <Button variant='primary' onClick={handleSubmit(preSubmit)}>Guardar</Button>
      {edit === 1 &&
        <Button variant='danger' onClick={() => deleteMultiplica()}>
          <BiTrash size={22} />
          <span>Eliminar</span>
        </Button>
      }
    </ButtonBox>
  </>)
};

export default CouponPromoForm
