import React from "react";

const CTicketVentaOp = () =>{
  return(
    <div>

    </div>
  )
}

export  default CTicketVentaOp;
