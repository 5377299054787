import React, {useContext} from "react";
import {Redirect, Route} from "react-router-dom";
import AuthContext from "../../login/store/AuthContext";

function PrivateRoute({component: Component, ...rest}) {

  //TODO, podriamos aca validar el jwt y decodearlo si es necesario en vez de hacerlo en Auth component
  const {stateUser} = useContext(AuthContext);
  //console.log(stateUser)

  //TODO, use react router 5
  //https://css-tricks.com/the-hooks-of-react-router/
  return (
    <Route
      {...rest}
      render={props =>
        stateUser.isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{pathname: "/login", state: {referer: props.location}}}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
