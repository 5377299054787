import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import useCrud from "../../../hooks/useCrud";
import GameForm from "../pages/games/GameForm";
import MyButton, {PrimaryButton} from "../../../components/forms/MyButton";

const GameEditModal = (props) => {
  const gamesApi = useCrud('/games');

  const {show, onHide} = props;
  const [game, setGame] = useState(props.game);

  useEffect(() => {
    setGame(props.game);
  }, [props.game])

  const handleInputChange = (event) => {
    event.persist();
    const {name, value} = event.target;
    setGame(inputs => ({...inputs, [name]: value}));
  }

  const onSave = () => {
    if (game.id) {
      updateGame();
    } else {
      createGame();
    }
  };

  const createGame = () => {
    console.log("creating game");
    gamesApi.post(game).then(res => {
      onHide();
    }).catch(err => {
      console.log(err);
    });
  };

  const updateGame = () => {
    console.log("updating game");
    gamesApi.put(game.id, game).then(res => {
      onHide();
    }).catch(err => {
      console.log(err);
    });
  };


  return (
    <Modal {...props}>
      <Modal.Header closeButton>
        <Modal.Title>Edicion de juego</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <GameForm game={game} handleInputChange={handleInputChange}/>
      </Modal.Body>

      <Modal.Footer>
        <MyButton variant="secondary" onClick={onHide}>
          Cerrar
        </MyButton>
        <PrimaryButton variant="primary" onClick={onSave}>
          Guardar
        </PrimaryButton>
      </Modal.Footer>
    </Modal>

  );
};

export default GameEditModal;
