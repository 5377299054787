import {Configure, Highlight, Hits, Index, InstantSearch, SearchBox, useInstantSearch} from "react-instantsearch";
import React, {useContext, useState} from "react";
import {FaUsers} from "react-icons/fa";
import {HiOutlineSlotMachine} from "../assets/icons/slot-machine";
import algoliasearch from "algoliasearch/lite";
import {SiteContext} from "../../modules/common/context/SiteContext";
import {BsBoxArrowInRight} from "react-icons/bs";
import styled from "styled-components"

const ContainerSearch = styled.div`
  position:absolute;
  top: 36px;
  left: 0;
  background: white;
  border-radius: 8px;
  box-shadow: rgb(0 0 0 / 10%) 0 0 10px;
  padding: 16px;
  min-width:300px;
  display: ${props => props.showSearch ? 'block':'none'};
  transition: 0.5s all ease-in-out;
  @media screen and (max-width: 992px) {
    min-width:100%;
    transition: 0.5s all ease-in-out;
  }
`
const ItemSearch = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  font-size: 13px;
  transition: 0.5s all ease-in-out;
  @media screen and (max-width: 992px) {
    transition: 0.5s all ease-in-out;
  }
`

const GlobalSearch = ({history}) => {

  const [showSearch, setShowSearch] = useState(false)
  const [showDataLabel, setShowDataLabel] = useState(false)
  const searchClient = algoliasearch('ZB9B28UI4U', '24d4e808de4e6a2f981f7e06f0110df2');
  const {salaId} = useContext(SiteContext)

  function Hit({hit}) {
    return (
      <ItemSearch
        onClick={() => {
          redirectByType(hit.type, hit.id, hit.data.path)
          setShowSearch(false)
        }}
      >
        {getIconByType(hit.type)}
        <Highlight attribute="text" hit={hit} />
        <Highlight attribute="data.gameName" hit={hit} />
      </ItemSearch>
    );
  }
  
  function NoResults() {
    const { indexUiState } = useInstantSearch();
  
    return (
      <span className={(!showDataLabel) ? 'menuLabel' : 'menuLabelHide'}> No hay resultados para: <strong>{indexUiState.query}</strong></span>
    );
  }

  const getIconByType = (type) => {
    switch (type) {
      case "customer": {
        return <FaUsers size={20} />
      }
      case "machine": {
        return <HiOutlineSlotMachine size={20} />
      }
      case "item": {
        return <BsBoxArrowInRight size={20} />
      }
      default: return null
    }
  }

  const redirectByType = (type, id, path) => {
    switch (type) {
      case "customer": {
        history.push('/crm/customer-info/' + id)
        break
      }
      case "machine": {
        history.push('/tgm/machines/edit/' + id)
        break
      }
      case "item": {
        if (path) history.push(path)
        break
      }
      default: return null
    }

  }

  const searchFunction = (helper) => {
    if (helper.state.query?.length < 2) {
      return; // no search if less than 2 character
    }
    helper.search();
  }

  return (
    <InstantSearch searchClient={searchClient} indexName="customer_index" searchFunction={searchFunction}
      onStateChange={({uiState, setUiState}) => {
        if (uiState.customer_index && uiState.customer_index.query && uiState.customer_index.query.length >= 2) {
          setShowSearch(true);
          setUiState(uiState);
        } else {
          setShowSearch(false);
        }
      }}>
      <div style={{position: "relative"}}>
        <SearchBox onResetCapture={() => {setTimeout(() => {setShowSearch(false)}, 500)}}
          onFocus={(e) => {
            if (e.target.value?.length >= 2) setShowSearch(true)
            else setShowSearch(false)
          }}
          placeholder={"Busque clientes, maquinas, etc"}
          classNames={{
            root: 'rootAlgolia',
            form: 'formAlgolia',
            input: 'inputAlgolia',
            submit: 'submitButtonAlgolia',
            submitIcon: 'submitIconAlgolia',
            reset: 'submitButtonAlgolia',
            resetIcon: 'resetIconAlgolia',
          }}
        />
        
        <ContainerSearch showSearch={showSearch}>
          <NoResults />
          <Index indexName="customer_index">
            <Configure hitsPerPage={5} filters={`data.salaId:${salaId} OR data.salaId:0`}/>
            <Hits
              transformItems={(items)=>{
                if(items?.length === 0) setShowDataLabel(false)
                else setShowDataLabel(true)
                return items
              }}
              hitComponent={Hit}
              classNames={{
                emptyRoot: 'emptyRoot',
                root: showSearch ? 'rootHits' : 'rootHitsHide',
                list: showSearch ? 'listHits': 'listHitsHide',
                item: showSearch ? 'itemHits': 'itemHitsHide',
              }}
            />
          </Index>
        </ContainerSearch>
      </div>
    </InstantSearch>
  )
}


export default GlobalSearch
