import React, {useContext, useState} from "react";
import Table from "react-bootstrap/Table";
import PageContainer from "../../../../../components/layout/PageContainer";
import PageSection from "../../../../../components/layout/PageSection";
import {PrimaryButton} from "../../../../../components/forms/MyButton";
import {FormBlock} from "../../../../../components/forms/FormGroup";
import {useHistory, useParams} from "react-router";
import {PageBackLabel} from "../../../../../components/layout/PageBackLabel";
import {useForm} from "react-hook-form";
import {SiteContext} from "../../../../common/context/SiteContext";
import axios from "axios";
import NoDataTable from "../../../../common/components/NoDataTable";
import Loader1 from "../../../../common/components/Loader1";
import useFetch2 from "../../../../../hooks/useFetch2";
import {Title} from "@wargostec/react-components";

const AddPolicies = () => {

  let history = useHistory()
  const {id} = useParams()
  const {salaId} = useContext(SiteContext)
  const {loading, data} = useFetch2(`http://localhost:9081/policies?salaId=${salaId}`, []);
  const {handleSubmit} = useForm();

  const {groupName} = useParams()

  let arr = {
    policyIds: []
  }

  const [selectedValue, setSelectedValue] = useState(arr);


  const handleSingleChange = (event, item) => {
    let yaEsta = false;
    const arrLength = arr.policyIds.length
    if (!event.target.checked) {
      for (let i = 0; i < arrLength; i++) {
        if (arr.policyIds[i] === item.id) {
          arr.policyIds.splice(i, 1)
          break;
        }
      }
    } else if (event.target.checked) {
      for (let i = 0; i < arrLength; i++) {
        if (arr.policyIds[i] === item.id) {
          yaEsta = true;
          break;
        }
      }
      if (!yaEsta) {
        arr.policyIds.push(item.id)
      }
    }
  }

  const onSubmit = (params) => {
    console.log(arr)
    axios.put(`http://localhost:9081/user-group/${id}/attach-policies`, arr).then(res =>
      history.push('/admin/groups/edit/' + id + '/' + groupName)
    ).catch()

  }

  return (
    <PageContainer>
      <PageSection>
        <PageBackLabel text={`Grupo ${groupName}`} onClick={() => history.goBack()}/>
          <Title text={`${groupName} | Añadir Políticas`} type={'page'}/>
      </PageSection>
      <PageSection>
        <FormBlock>
          <Table style={{marginTop: "20px"}} striped hover>
            <thead>
            <tr>
              <th>Seleccionar</th>
              <th>IdPolítica</th>
              <th>Nombre</th>
              <th>Sala Id</th>
            </tr>
            </thead>
            <tbody>
            {data && data.map((item, index) =>
              <tr key={item.id}>
                <td>
                  <div className="form-check">
                    <input type="checkbox"
                           key={item.id}
                           checked={selectedValue[index.toString()]}
                           onChange={(event) => handleSingleChange(event, item)}
                    />
                  </div>
                </td>
                <td>{item.id}</td>
                <td>{item.name}</td>
                <td>{item.salaId}</td>
              </tr>
            )}
            </tbody>
          </Table>
          {!loading ? data.length <= 0 && <NoDataTable text={"No existen políticas registradas en el sistema"}/> :
            <Loader1/>}
          <PrimaryButton style={{marginTop: "15px"}} onClick={() => handleSubmit(onSubmit())}>Añadir</PrimaryButton>
        </FormBlock>
      </PageSection>
    </PageContainer>
  )
}

export default AddPolicies;
