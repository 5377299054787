import React, {useState} from 'react';
import axios from "axios";
import {Apis} from "../../../../../util/Apis";
import {errorToast, successToast} from "../../../../../components/layout/ToastStyles";
import {SelectV2} from "../../../../../components/forms/FormEl";

const CashExpenseSelect = ({operationId, cashExpenseId, cashExpenses}) => {
  const [category, setCategory] = useState(cashExpenseId)

  const changeCategory = (categoryId) => {
    setCategory(categoryId)
    axios.put(Apis.CAJA + '/operation-other/' + operationId + '/category', {categoryId: categoryId})
      .then(() => {
        successToast("Categoria cambiada")
      })
      .catch(error => {
        errorToast(error.response.data.message ? error.response.data.message : "Ocurrió un error al guardar.", 7000)
      });
  }

  return (
    <SelectV2 style={{minWidth: '140px'}} onChange={(e) => changeCategory(e.target.value)} value={category || 0}
              options={cashExpenses}/>
  )
};

export default CashExpenseSelect;
