import {useEffect, useMemo, useState} from 'react'
import useFetch2 from "./useFetch2";
import {Apis} from "../util/Apis";

const useFilasMesa = (tableType) => {

  const {data} = useFetch2(Apis.CAJA + '/money-denom', [])

  useEffect(() => {
    if (data && data.length > 0) {
      setRows(getData(data))
    }
  }, [data]);

  const getData = (value) => {
    let denom = []
    if (value) {
      denom = value.map(table => {
        return {
          denom: table.value,
          currencySymbol: table.currencySymbol,
          quantity: 0
        }
      })
      return denom
    }
    return denom
  }

  const [rows, setRows] = useState([]);

  const total = useMemo(() => rows.reduce((sum, fila) => sum + fila.denom * fila.quantity, 0), [rows]);

  const clear = () => {
    setRows(getData(data && data));
  }

  return {rows, total, setRows, clear};
}

export default useFilasMesa
