import React from "react";
import styled from 'styled-components'
import {BsX} from "react-icons/bs";

const SlidebarStyled = styled.div`
  position: fixed;
  top: 0;
  right: -400px;
  display:flex;
  flex-direction:column;
  height: 100%;
  width: 400px;
  background: white;
  z-index: 100;
  transition: all 0.3s ease-in-out;
  transform: ${props => props.visible ? "translateX(-400px)" : "translateX(0px)"};

  @media (max-width: 425px) {
    width: 100%;
  }
`
const SlidebarHeader = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px;
  align-items: center;
  justify-content: space-between;

  span{
    font-size:20px;
  }
`
const SlidebarBody = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
`

const Slidebar = ({title = 'Filtros', visible, children, onHide}) => {
  return (
    <SlidebarStyled visible={visible}>
      <SlidebarHeader>
        <span>{title}</span>
        <div style={{cursor: 'pointer'}} onClick={onHide}>
          <BsX size={32} />
        </div>
      </SlidebarHeader>
      <SlidebarBody>
        {children}
      </SlidebarBody>
    </SlidebarStyled>)
}

export default Slidebar
