import React, {useState} from "react";

//loop
//https://mxstbr.blog/2017/02/react-children-deepdive/#looping-over-children
//con context
//https://blog.logrocket.com/guide-to-react-compound-components-9c4b3eb482e9/
const DropdownContainer = ({children}) => {

  const [selected, setSelected] = useState(null)

  return React.Children.map(children, child => {
    return React.cloneElement(child, {
      setSelected: setSelected,
      selected: selected,
      ...child.props,
    })
  })
}

export default DropdownContainer
