import React from 'react';
import PageSection from "../../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import ModelList from "./ModelList";
import PageContainer from "../../../../../components/layout/PageContainer";
import ManufacturerList from "../manufacturer/ManufacturerList";
import {Tab, Tabs} from '../../../../../components/tabs/Tabs';

const ModelAndManufacturer = () => {
  return (
    <PageContainer>
      <PageSection>
          <Title text="Modelos y fabricantes" type={'page'}/>

        <Tabs>
          <Tab title='Modelos'><ModelList /></Tab>
          <Tab title='Fabricantes'><ManufacturerList /></Tab>
        </Tabs>
      </PageSection>
    </PageContainer>
  );
};

export default ModelAndManufacturer;
