import React, {useContext, useEffect, useState} from "react";
import {SiteContext} from "../../../common/context/SiteContext";
import {Apis} from "../../../../util/Apis";
import {useForm} from "react-hook-form";
import Slidebar from "../../../common/layout/Slidebar";
import SalaIdInput from "../../../common/components/SalaIdInput";
import Button, {ButtonBox} from "../../../../components/forms/Button";
import PageSection from "../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import Table from "react-bootstrap/Table";
import Moment from "react-moment";
import {FlexLayout} from "../../../../components/flex/Flex";
import {numberFormat} from "../../../../components/util/NumberUtil";
import Card, {CardBody, CardHeader} from "../../../../components/forms/Card";
import useDeleteModal from "../../../../modals/useDeleteModal";
import axios from "axios";
import DeleteGenericModal from "../../../../modals/DeleteGenericModal";
import useFetch2 from "../../../../hooks/useFetch2";
import AsyncBlock from "../../../../components/forms/AsyncBlock";
import useToggle from "../../../../hooks/useToggle";
import CardOverview, {CardOverviewContainer} from "../../../../components/layout/CardOverview";
import {FaSortAmountUpAlt} from "react-icons/fa";
import {HiOutlineBanknotes} from "react-icons/hi2";
import PageContainer from "../../../../components/layout/PageContainer";
import PeriodAndFondoSelect from "./PeriodAndFondoSelect";
import EmptyState from "../../../common/components/EmptyState";
import {TbFileSad} from "react-icons/tb";
import {LuReceipt} from "react-icons/lu";
import {getCasinoDay} from "../../../../components/util/CasinoDates";
import CashExpenseSelect from "../fondo/configuration/CashExpenseSelect";
import Chart from "react-apexcharts";
import useEditModal from "../../../../modals/useEditModal";
import VoucherTypeComponent from "./VoucherTypeComponent";
import GenericModal2 from "../../../../modals/GenericModal2";
import {SelectV2} from "../../../../components/forms/FormEl";

const CExpensesReport = () => {

  const queryString = window.location.search
  const query = new URLSearchParams(queryString)
  let fondoIdParams = query.get('fondoId') || ""
  let periodParams = query.get('period') || getCasinoDay();
  const {salaId} = useContext(SiteContext)
  const {isOpen: slideOpen, toggler} = useToggle({});

  const [params, setParams] = useState({
    salaId: salaId,
    fondoId: fondoIdParams,
    period: periodParams,
  });

  const {register, handleSubmit} = useForm({
    defaultValues: params,
  });


  const determineApiEndpoint = (fondoId) => {
    return fondoId === ""
      ? Apis.CAJA + '/operation-other/report/by-period'
      : Apis.CAJA + '/operation-other/report/by-fondo';
  };

  const {loading, data, fetchData, error} = useFetch2(determineApiEndpoint(params.fondoId), null, params, false);
  const {data: cashExpenses, fetchData: fetchData2} = useFetch2(Apis.CAJA + `/expenses-category`, []);
  const {
    data: reportGrouped,
    fetchData: fetchData3,
    loading: loading3,
    error: error3
  } = useFetch2(Apis.CAJA + `/operation-other/report/grouped`, [], params);

  const deleteElement = id => axios.delete(Apis.CAJA + '/operation-other/' + id)

  const {
    wrapper: wrapper,
    addAction: updateVoucherType,
  } = useEditModal({
    addComponent: <VoucherTypeComponent/>,
    onRefresh: () => {
      fetchData()
    }
  });

  const {
    wrapper: wrapper2,
    openDeleteModal
  } = useDeleteModal({
    text: '¿Desea eliminar esta operación de caja?',
    action: deleteElement,
    onRefresh: fetchData
  })

  useEffect(() => {
    const query = new URLSearchParams(params).toString();
    const newUrl = `${window.location.pathname}?${query}`;
    window.history.pushState(null, "", newUrl);
    fetchData();
    fetchData3()
  }, [params]);

  const handleFondoIdChange = (fondoId) => {
    setParams((prevParams) => ({...prevParams, fondoId: fondoId}));
  };

  const onSubmit = () => {
    fetchData();
    fetchData3();
  };

  function getRandomColor(value) {
    const colors = [];
    for (let i = 0; i < value.length; i++) {
      colors.push(`#${Math.floor(Math.random() * 16777215).toString(16)}`)
    }
    return colors;
  }

  function getSerieData(value) {
    const series = [];
    for (let i = 0; i < value.length; i++) {
      const serie = {
        name: value[i].categoryName,
        data: [value[i].amount]
      }
      series.push(serie)
    }
    return series;
  }

  const barExpenses = {
    options: {
      chart: {
        type: 'bar', height: 400
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: 10, colors: ['transparent']
      },
      plotOptions: {
        bar: {
          columnWidth: '40%',
          borderRadius: 4,
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        intersect: false, shared: true, onDatasetHover: {
          highlightDataSeries: true,
        }, y: {
          formatter: function (val) {
            return "S/ " + numberFormat(val)
          }
        }
      },
      xaxis: {
        categories: [`Periodo ${params.period}`]
      }
    }, series: reportGrouped && reportGrouped.length > 0 && getSerieData(reportGrouped)
  };

  return (
    <PageContainer>
      <Slidebar visible={slideOpen} onHide={toggler}>
        <div style={{padding: '16px'}}>
          <SalaIdInput {...register("salaId")} />
          <PeriodAndFondoSelect
            onFondoChange={handleFondoIdChange}
            period={params.period}
            setPeriod={(newPeriod) => setParams((prevParams) => ({...prevParams, period: newPeriod}))}
            {...register("fondoId")} />
          <Button variant={'primary'} title='Buscar' onClick={handleSubmit(onSubmit)} />
        </div>
      </Slidebar>
      <PageSection>
        <Title text="Gastos" type={'page'} />
        <ButtonBox>
          <Button variant={'primary'} title={'Filtrar'} onClick={toggler} />
        </ButtonBox>

        <AsyncBlock loading={loading} error={error}>
          {data === null ?
            <Card padding>
              <EmptyState
                title={'Gastos'}
                subtitle={'Haga click en FILTRAR para realizar una busqueda'}
                icon={<LuReceipt size={32} />}
              />
            </Card>
            :
            <>
              {data?.data?.length === 0 ?
                <Card padding>
                  <EmptyState
                    title={'Sin resultados'}
                    subtitle={'Haga click en FILTRAR para realizar una nueva busqueda'}
                    icon={<TbFileSad size={32} />}
                  />
                </Card>
                :
                <>
                  <Card>
                    <CardHeader>
                      <strong>Distribución de gastos</strong>
                    </CardHeader>
                    <CardBody>
                      <Chart
                        options={barExpenses.options}
                        series={reportGrouped?.length > 0 ? getSerieData(reportGrouped) : []}
                        type="bar"
                        height="200"
                      />
                    </CardBody>
                  </Card>
                  <CardOverviewContainer gridColumns='repeat(2, 1fr)'>
                    <CardOverview
                      title={data?.quantity ?? 0}
                      subtitle='Operaciones'
                      icon={<FaSortAmountUpAlt size={20} />}
                    />
                    <CardOverview
                      title={'S/ ' + numberFormat(data.totalAmount)}
                      subtitle='Total'
                      icon={<HiOutlineBanknotes size={20} />}
                    />
                  </CardOverviewContainer>
                  <Card>
                    <Table striped hover responsive>
                      <thead>
                        <tr>
                          <th className="text-center">ID</th>
                          <th>Fondo</th>
                          <th>Categoría</th>
                          <th>Tipo de comprobante</th>
                          <th>N° de comprobante</th>
                          <th>Descripción</th>
                          <th>Recibido por</th>
                          <th className="text-right">Monto</th>
                          <th className="text-center">Fecha</th>
                          <th className="text-center">Acciones</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.data && data.data.map(item =>
                          <tr key={item.id}>
                            <td className="text-center">{item.id}</td>
                            <td>{item.fondoName}</td>
                            <td>
                              <FlexLayout justifyContent='center'>
                                {cashExpenses &&
                                  <CashExpenseSelect
                                    operationId={item.id}
                                    cashExpenseId={item.categoryId ? item.categoryId : ''}
                                    cashExpenses={[{
                                      id: 0,
                                      name: "Seleccione"
                                    }, ...cashExpenses]}/>
                                }
                              </FlexLayout>
                            </td>
                            <td>
                              <SelectV2 options={
                                [
                                  {id: 0, name: "NINGUNO"},
                                  {id: 1, name: "FACTURA"},
                                  {id: 2, name: "BOLETA"},
                                  {id: 3, name: "RECIBO"}
                                ]
                              } onChange={(e) => updateVoucherType({
                                id: item.id,
                                voucherType: e.target.value,
                                voucherNumber: item.voucherNumber,
                                voucherPath: item.voucherPath
                              })}
                              defaultValue={item.voucherType}
                              />
                            </td>
                            <td>{item.voucherNumber ? item.voucherNumber : '--'}</td>
                            <td>{item.description}</td>
                            <td>{item.receivedBy ? item.receivedBy : '--'}</td>
                            <td className="text-right">{'S/ ' + numberFormat(item.amount)}</td>
                            <td className="text-center"><Moment format={'YYYY-MM-DD hh:mm a'}>{item.createdAt}</Moment></td>
                            <td>
                              <FlexLayout justifyContent='center'>
                                <Button variant='danger' size='xs' onClick={() => openDeleteModal(item.id, deleteElement)}>Eliminar</Button>
                                <Button size='xs'>Imprimir</Button>
                              </FlexLayout>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </Card>
                </>
              }
            </>
          }
        </AsyncBlock>
      </PageSection>
      <DeleteGenericModal {...wrapper2} />
      <GenericModal2 {...wrapper} />
    </PageContainer>
  )
}

export default CExpensesReport;
