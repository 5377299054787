import React, {useEffect} from "react";
import {Apis} from "../../../../../util/Apis";
import axios from "axios";
import ZoneModalForm from "./ZoneModalForm";
import useFetch2 from "../../../../../hooks/useFetch2";

const ZoneEdit = ({id, setAction, setTitle}) => {

  const {data} = useFetch2(Apis.TGM + '/slot-zones/' + id)

  function update(params) {
    //se podria crear un componente <Id/> para guardar esta propiedad
    params.id = id
    return axios.put(Apis.TGM + '/slot-zones/' + id, params)
  }

  useEffect(() => {
    setTitle("Edición de zona")
  }, [])

  return (
    <ZoneModalForm value={data} onSubmit={update} setAction={setAction}/>
  )
}

export default ZoneEdit
