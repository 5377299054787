import React, {useContext, useEffect} from "react";
import {Apis} from "../../../../../util/Apis";
import axios from "axios";
import {SiteContext} from "../../../../common/context/SiteContext";
import TipoListaLudopatiaForm from "./TipoListaLudopatiaForm";

const TipoListaLudopatiaNew = ({setAction, setTitle}) => {

  const {salaId} = useContext(SiteContext)

  const save = (params) => {
    params.salaId = salaId
    return axios.post(Apis.BLACK + '/ban-list', params)
  }

  useEffect(() => {
    setTitle("Nuevo tipo de lista")
  }, [])

  return (
    <TipoListaLudopatiaForm value={{}} onSubmit={save} setAction={setAction}/>
  )
}

export default TipoListaLudopatiaNew
