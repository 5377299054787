import React, {useEffect} from 'react';
import {useForm} from "react-hook-form";
import FormGroup from "../../../../components/forms/FormGroup";
import Input, {Select} from "../../../../components/forms/FormEl";
import MachineSelect from "../../../common/components/selects/MachineSelect";
import SalaIdInput from "../../../common/components/SalaIdInput";

const AftTransferForm = ({value, onSubmit, setAction, mode= 'AFT'}) => {

  const {register, handleSubmit, reset, control, setValue, getValues} = useForm()

  useEffect(() => {
    if (value) {
      if (Object.keys(value).length !== 0) {
        reset(value)
      }
    }
  }, [value]);

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit))
  }, [])

  return (
    <React.Fragment>
      <SalaIdInput {...register("salaId")} />
      <FormGroup>
        <label>Monto canjeable</label>
        <Input type='number' {...register("cashableAmount")} />
      </FormGroup>
      <FormGroup>
        <label>Monto restringido</label>
        <Input type='number' {...register("restrictedAmount")} />
      </FormGroup>
      <FormGroup>
        <label>Monto no restringido</label>
        <Input type='number' {...register("nonrestrictedAmount")} />
      </FormGroup>
      {
        mode !== 'HOT_SEAT' ?
          <FormGroup>
            <label>Maquina</label>
            <MachineSelect {...register("confId")}/>
          </FormGroup>
          :
          <FormGroup>
            <label>Maquina</label>
            <Input type='text' value={value && value.machine} disabled={true}/>
            {!value.confId && <p style={{color: 'red'}}>Falta aún sortear</p>}
          </FormGroup>
      }

      <FormGroup>
        <label>Tipo de transferencia</label>
        <Select {...register("transferType")}>
          <option value={0}>NORMAL</option>
          <option value={16}>BONUS</option>
          <option value={17}>JACKPOT</option>
        </Select>
      </FormGroup>
      <FormGroup>
        <label>Flags</label>
        <Input type='number' {...register("transferFlags")} />
      </FormGroup>
      <FormGroup>
        <label>Motivo</label>
        <Input type='text' {...register("motive")} />
      </FormGroup>

    </React.Fragment>
  );
};

export default AftTransferForm;
