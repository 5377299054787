import React, {useCallback, useContext, useMemo} from 'react'
import PageContainerFull from '../../../../components/layout/PageContainerFull'
import {Title} from "@wargostec/react-components";
import {Apis} from '../../../../util/Apis'
import {SiteContext} from '../../../common/context/SiteContext'
import useFetch2 from '../../../../hooks/useFetch2'
import AsyncBlock from '../../../../components/forms/AsyncBlock'
import PageSection from '../../../../components/layout/PageSection'
import ReactTable from '../../../../components/util/ReactTable'
import moment from 'moment'

const MeterPerGame = () => {
  const {salaId} = useContext(SiteContext)
  const {data, loading, error} = useFetch2(Apis.TGM + '/meter-online-per-game')

  function formatPercentageString(inputString) {
    const number = parseFloat(inputString) / 100;
    const formattedNumber = number.toFixed(2);
    const percentageString = formattedNumber + " %";
    return percentageString;
  }

  const gameValue = useCallback(props => props.renderValue()!== ''  ? props.renderValue() : '--', []);
  const percentValue = useCallback(props => formatPercentageString(props.renderValue()), []);
  const estadoValue = useCallback(props => props.renderValue() ? 'Jugando': '--' ,[]);
  const lastActivityValue = useCallback(props => props.renderValue() !== null ? moment(props.renderValue()).format('YYYY-MM-DD hh:mm a') : '--',[]);

  const columns = useMemo(
    () => [
      {
        header: 'Máquina',
        accessorKey: 'machine',
        meta: {
          headerClassName:'text-center', // class para la cabecera
          columnClassName:'text-center', // class para la columna
        }
      },
      {
        header: 'Juego',
        accessorKey: 'gameName',
        cell: gameValue
      },
      {
        header: 'CoinIn',
        accessorKey: 'ci',
        enableGlobalFilter:false, // para deshabilitar el filter de forma global
        meta: {
          headerClassName:'text-right', 
          columnClassName:'text-right', 
        }
      },
      {
        header: 'CoinOut',
        accessorKey: 'co',
        enableGlobalFilter:false,
        enableMultiSort:true,
        meta: {
          headerClassName:'text-right',
          columnClassName:'text-right', 
        }
      },
      {
        header: 'Jackpot',
        accessorKey: 'jp',
        enableGlobalFilter:false, 
        meta: {
          headerClassName:'text-right', 
          columnClassName:'text-right', 
        } 
      },
      {
        header: 'Jugadas',
        accessorKey: 'gp',
        enableGlobalFilter:false, 
        meta: {
          headerClassName:'text-right', 
          columnClassName:'text-right', 
        } 
      },
      {
        header: 'Porcentaje de pago',
        accessorKey: 'pbase',
        enableGlobalFilter:false, 
        enableSorting:false, // para deshabilitar el sort
        meta: {
          headerClassName:'text-center', 
          columnClassName:'text-center', 
        },
        cell: percentValue
      },
      {
        header: 'Estado',
        accessorKey: 'activeGame',
        enableMultiSort:true,
        meta: {
          headerClassName:'text-center', 
          columnClassName:'text-center', 
        },
        cell: estadoValue
      }, 
      {
        header: 'Ultima actividad',
        accessorKey: 'updatedAt',
        enableGlobalFilter:false,
        meta: {
          headerClassName:'text-center', 
          columnClassName:'text-center', 
        },
        cell: lastActivityValue
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <PageContainerFull>
      <PageSection>
          <Title text="Contadores por juego" type={'page'}/>
        <AsyncBlock loading={loading} error={error}>
          <ReactTable 
            pageSize={10}
            columns={columns} 
            data={data || []}
            loading={loading}
            sortArray={[{id: 'machine', asc: false}]}
       />
        </AsyncBlock>
      </PageSection>
    </PageContainerFull>
  )
}

export default MeterPerGame
