import React, {useEffect} from 'react';
import axios from "axios";
import {Apis} from "../../../../util/Apis";
import AftTransferForm from "../../../tgm/pages/aft/AftTransferForm";

const TransferCreditBySorteo = ({info, setAction, setTitle}) => {

  const save = async (params) => {
    await axios.post(Apis.TGM + "/aft-transfer", params).catch((err) => {
    });
  }

  useEffect(() => {
    setTitle("Nueva transferencia")
  }, [])

  return (
    <AftTransferForm
      value={info && {
        "confId": info.confId,
        "cashableAmount": info.amount,
        "restrictedAmount": 0,
        "nonrestrictedAmount": 0,
        "motive": `SORTEO ${info.kind} - ${info.id}`,
        "machine": info.machine
      }}
      onSubmit={save} setAction={setAction} mode={info && info.kind}/>
  );
};

export default TransferCreditBySorteo;
