import React, {useEffect} from "react";
import {Controller, useForm} from "react-hook-form";
import CustomerSearchInput from "../../../../common/components/CustomerSearchInput";
import FormGroup from "../../../../../components/forms/FormGroup";
import CustomerSearchInputV2 from "../../../../common/components/CustomerSearchInputV2";

const CustomerGroupDetailForm = ({value, onSubmit, setAction}) => {

  const {control, handleSubmit, reset} = useForm();

  useEffect(() => {
    if (value) {
      reset(value)
    }
  }, [value])

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit));
  }, [])


  return (
    <React.Fragment>
      <FormGroup>
        <label>Cliente</label>
        <Controller name="customerId" control={control} render={({ field }) => <CustomerSearchInputV2 {...field} />}/>
      </FormGroup>
    </React.Fragment>
  )

}

export default CustomerGroupDetailForm
