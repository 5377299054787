import React, {useEffect} from 'react';
import {useForm} from "react-hook-form";
import SalaIdInput from "../../../common/components/SalaIdInput";
import FormGroup from "../../../../components/forms/FormGroup";
import Input from "../../../../components/forms/FormEl";

const DealerForm = ({value, onSubmit, setAction}) => {

  const {register, handleSubmit, reset} = useForm()

  useEffect(() => {
    if (value) {
      reset(value)
    }
  }, [value])

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit))
  }, [])

  return (
    <React.Fragment>
      <SalaIdInput {...register("salaId")}/>
      <FormGroup>
        <label>Nombre</label>
        <Input type='text' {...register("name")}/>
      </FormGroup>
      <FormGroup>
        <label>Apellido</label>
        <Input type='text' {...register("lastName")}/>
      </FormGroup>
    </React.Fragment>
  );
};

export default DealerForm;
