import React, {useCallback, useMemo} from 'react';
import useFetch2 from "../../../../../hooks/useFetch2";
import {Apis} from "../../../../../util/Apis";
import axios from "axios";
import useDeleteModal from "../../../../../modals/useDeleteModal";
import PageContainer from "../../../../../components/layout/PageContainer";
import {Button, PageSection, Title} from "@wargostec/react-components";
import AsyncBlock from "../../../../../components/forms/AsyncBlock";
import ReactTable from "../../../../../components/util/ReactTable";
import {FlexLayout} from "../../../../../components/flex/Flex";
import useEditModal from "../../../../../modals/useEditModal";
import CashExpenseEdit from "./CashExpenseEdit";
import CashExpenseNew from "./CashExpenseNew";
import GenericModal2 from "../../../../../modals/GenericModal2";
import DeleteGenericModal from "../../../../../modals/DeleteGenericModal";

const CashExpense = () => {

  const {data, fetchData, loading, error} = useFetch2(Apis.CAJA + `/expenses-category`, []);

  const deleteElement = id => axios.delete(Apis.CAJA + '/expenses-category/' + id)


  const {wrapper: wrapper1, addAction, editAction} = useEditModal({
    editComponent: <CashExpenseEdit />,
    addComponent: <CashExpenseNew />,
    onRefresh: fetchData
  })

  const {
    wrapper: wrapper2,
    openDeleteModal
  } = useDeleteModal({
    text: '¿Desea eliminar esta categoria?',
    action: deleteElement,
    onRefresh: fetchData
  })

  const actionValue = useCallback(props => <>{
    <FlexLayout justifyContent='center'>
      <Button variant="light" size='xs' onClick={() => editAction(props.row.original.id)}
              text={"Editar"}/>
      <Button variant="danger" size='xs' onClick={() => openDeleteModal(props.row.original.id)}
              text={"Eliminar"}/>
    </FlexLayout>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }</>, []);

  const columns = useMemo(() => [
    {
      header: 'ID',
      accessorKey: 'id',
      enableGlobalFilter:false,
      enableSorting:false,
      meta: {
        headerClassName: 'text-center font-xsmall',
        columnClassName: 'text-center font-xsmall',
      },
    },
    {
      header: 'Nombre',
      accessorKey: 'name',
      meta: {
        headerClassName: 'text-center',
        columnClassName: 'text-center',
      },
    },
    {
      header: 'Acciones',
      accessorKey: 'acciones',
      enableGlobalFilter:false,
      enableSorting:false,
      meta: {
        headerClassName: 'text-center',
        columnClassName: 'text-center',
      },
      cell:actionValue
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [])


  return (
    <PageContainer>
      <PageSection>
        <Title text="Categorias de gasto" type={'page'}/>

        <AsyncBlock loading={loading} error={error}>
          <ReactTable
            customSearch
            columns={columns}
            data={data || []}
            filterAdd={() => addAction()}
            noData='Sin categorias registradas'
            pageSize={20}
          />
        </AsyncBlock>
      </PageSection>

      <GenericModal2 {...wrapper1} />
      <DeleteGenericModal {...wrapper2} />

    </PageContainer>
  );
};

export default CashExpense;
