import React from 'react';
import Button from "./Button";
import {TfiReload} from "react-icons/tfi";

const ButtonReload = (props) => {

  return (
    <>
      <Button variant={'success'} {...props}>
        <TfiReload style={{fontSize: "20px"}} />
      </Button>
    </>
  );
};

export default ButtonReload;
