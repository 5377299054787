import React, {forwardRef, useState} from "react";
import {FormGroup} from "@wargostec/react-components";
import {DatePickerCustom} from "../../../../components/forms/FormEl";
import FondoSelect from "../../../common/components/selects/FondoSelect";

const PeriodAndFondoSelect = forwardRef(({onFondoChange, period, setPeriod},ref) => {
  const [fondoId, setFondoId] = useState('');

  const onDateChange = (event) => {
    setPeriod(event.target.value);
    if (fondoId !== "") {
      onFondoChange("");
    }
    setFondoId('')
  };

  const onFondoSelectChange = (event) => {
    const selectedFondoId = event.target.value;
    setFondoId(selectedFondoId);
    onFondoChange(selectedFondoId);
  };

  return (
    <>
      <FormGroup>
        <label>Periodo</label>
        <DatePickerCustom value={period} onChange={onDateChange} />
      </FormGroup>
      <FormGroup>
        <label>Fondo</label>
        <FondoSelect period={period} onChange={onFondoSelectChange} />
      </FormGroup>
    </>
  );
});

export default PeriodAndFondoSelect;
