import React from "react";
import PageContainer from "../../../../../components/layout/PageContainer";
import PageSection from "../../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import {useParams} from "react-router";
import Card from "../../../../../components/forms/Card";
import FormGroup, {FormBlockTitle} from "../../../../../components/forms/FormGroup";
import Input from "../../../../../components/forms/FormEl";
import {useForm} from "react-hook-form";
import axios from "axios";
import {Apis} from "../../../../../util/Apis";
import Button from "../../../../../components/forms/Button";

const CustomerView = () => {

  const {id} = useParams()

  const {register, handleSubmit, watch, errors, reset, control} = useForm();


  const createUser = (data) => {
    axios.post(Apis.PC + `/customers/${id}/create-user`, data).then(res => {
      console.log('exito')
    }).catch(err => {

    })
  }

  return (
    <PageContainer>
      <PageSection>
        <Title text="Clientes"/>
      </PageSection>

      <PageSection>
        <Card padding>
          <FormBlockTitle>Credenciales</FormBlockTitle>

          <FormGroup>
            <label>Usuario</label>
            <Input type='text' name='username' {...register("username")}/>
          </FormGroup>

          <FormGroup>
            <label>Contraseña</label>
            <Input type='password' name='password' {...register("password")}/>
          </FormGroup>

          <Button variant={'primary'} onClick={handleSubmit(createUser)}>Crear</Button>
        </Card>
      </PageSection>
    </PageContainer>
  )
}

export default CustomerView
