import React from "react";
import Loader1 from "../../modules/common/components/Loader1";
import ErrorCard from "./ErrorCard";

const AsyncBlock = ({ children, loading, error, showLoader = true }) => {
  const content = error ? <ErrorCard axiosError={error}/> : children;

  return (
    loading && showLoader
      ?
      <Loader1/>
      :
      content
  );
};

export default AsyncBlock;