import React, {useContext, useEffect} from "react";
import axios from "axios";
import {SiteContext} from "../../../common/context/SiteContext";
import {Apis} from "../../../../util/Apis";
import SorteoForm from "./SorteoForm";
import {errorToast, successToast} from "../../../../components/layout/ToastStyles";
import useFetch2 from "../../../../hooks/useFetch2";

const SorteoEdit = ({id, setAction, setTitle}) => {

  const {salaId} = useContext(SiteContext)
  const {loading, data} = useFetch2(Apis.SORTEO + '/sorteos/' + id)

  const update = async (params) => {
    params.id = id
    params.salaId = salaId
    params.premioTipo = "soles"
    await axios.put(Apis.SORTEO + '/sorteos/' + id, params)
      .then(res => {
        successToast()
      })
      .catch(err => {
        errorToast();
      })
  }

  useEffect(() => {
    setTitle("Edición de sorteo")
  }, [])

  return (
    <React.Fragment>
      {data && !loading &&
      <SorteoForm value={data && data} onSubmit={update} setAction={setAction}/>
      }
    </React.Fragment>
  )
}

export default SorteoEdit
