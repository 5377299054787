import React, {useContext, useEffect} from 'react';
import {SiteContext} from "../../../common/context/SiteContext";
import axios from "axios";
import {Apis} from "../../../../util/Apis";
import CategoriesForm from "./CategoriesForm";

const CategoriesNew = ({setAction, setTitle}) => {
  const {salaId} = useContext(SiteContext)

  const save = async (params) => {
    params.salaId = salaId
    await axios.post(Apis.FOOD + "/food-categories", params).catch((err) => {
    });
  }

  useEffect(() => {
    setTitle("Nuevo Producto")
  }, [])

  return (
    <CategoriesForm value={{}} onSubmit={save} setAction={setAction}/>
  );
};

export default CategoriesNew;
