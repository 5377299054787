import React, {useEffect} from 'react';
import {Apis} from "../../../../../util/Apis";
import axios from "axios";
import LevelProgressiveForm from "./LevelProgressiveForm";
import useFetch2 from "../../../../../hooks/useFetch2";

const LevelProgressiveEdit = ({id, setAction, setTitle}) => {

  const {loading, data} = useFetch2(Apis.TGM + `/progressive-level/` + id)

  const update = async (params) => {
    await axios.put(Apis.TGM + `/progressive-level/` + id, params)
  }

  useEffect(() => {
    setTitle("Edición de Nivel")
  }, [])

  return (
    <React.Fragment>
      {data && !loading &&
        <LevelProgressiveForm value={data} onSubmit={update} setAction={setAction}/>
      }
    </React.Fragment>
  );
};

export default LevelProgressiveEdit;
