import {Route, Switch} from "react-router";
import AddFundsList from "./pages/funds/AddFundsList";
import GamesReport from "./pages/games/GamesReport";
import PlayerReport from "./pages/customers/PlayersReport";
import UserNew from "../admin/pages/users/UserNew";
import UserEdit from "../admin/pages/users/UserEdit";
import UserList from "../admin/pages/users/UserList";
import React from "react";
import CasinoOnlineHome from "./CasinoOnlineHome";

//Rutas solo del modulo de casino online
const CasinoOnlineRoutes = () => {
  const parentRoute = '/casino'
  return (
    <Switch>
      <Route path={parentRoute + '/add-funds'} component={AddFundsList}/>
      <Route path={parentRoute + '/games'} component={GamesReport}/>
      <Route path={parentRoute + '/players'} component={PlayerReport}/>

      <Route path="/users/new" component={UserNew}/>
      <Route path="/users/edit/:id" component={UserEdit}/>
      <Route path="/users" component={UserList}/>

      <Route path="/" component={CasinoOnlineHome}/>
    </Switch>
  )
}

export default CasinoOnlineRoutes;
