import React, {useContext, useEffect, useState} from 'react';
import PageContainer from "../../../../components/layout/PageContainer";
import Slidebar from "../../../common/layout/Slidebar";
import SalaIdInput from "../../../common/components/SalaIdInput";
import FormGroup from "../../../../components/forms/FormGroup";
import {DatePickerCustom} from "../../../../components/forms/FormEl";
import Button from "../../../../components/forms/Button";
import {SiteContext} from "../../../common/context/SiteContext";
import {getCasinoDay} from "../../../../components/util/CasinoDates";
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import {useForm} from "react-hook-form";
import PageSection from "../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import AsyncBlock from "../../../../components/forms/AsyncBlock";
import TableTransaction from "./TableTransaction";
import MesasSelect from "../../../common/components/selects/MesasSelect";

const MesasTransaction = () => {

  const {salaId} = useContext(SiteContext)
  let defaultParams = {salaId: salaId}
  const [slideOpen, setSlideOpen] = useState(false)

  const initialDate = getCasinoDay()

  const fechasDefault = {ini: initialDate, fin: initialDate}
  const [fechas, setFechas] = useState(fechasDefault)

  const querystring = window.location.search
  const query = new URLSearchParams(querystring)
  let snapId = query.get('snapId')

  if (snapId !== null && snapId !== undefined) {
    defaultParams.snapId = snapId
  }

  const [params, setParams] = useState(defaultParams)
  const {loading, data, fetchData, error} = useFetch2(Apis.TABLES + `/transaction`, [], params);
  const {register, handleSubmit} = useForm({defaultValues: defaultParams});

  const getData = (params) => {
    setParams(params)
  }

  useEffect(() => {
    setParams({...params, ...fechas})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fechas])

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  return (
    <PageContainer>
      <Slidebar visible={slideOpen} onHide={() => setSlideOpen(false)}>
        <div style={{padding: '16px'}}>
          <SalaIdInput {...register("salaId")} />
          <FormGroup>
            <label>Desde</label>
            <DatePickerCustom {...register("ini")} />
          </FormGroup>

          <FormGroup>
            <label>Hasta</label>
            <DatePickerCustom {...register("fin")} />
          </FormGroup>

          <FormGroup>
            <label>Mesa</label>
            <MesasSelect {...register("tableId")} />
          </FormGroup>

          <Button variant={'primary'} title='Buscar' onClick={handleSubmit(getData)} />
        </div>
      </Slidebar>

      <PageSection>
          <Title text="Transacciones" type={'page'}/>

        <AsyncBlock loading={loading} error={error}>
          <TableTransaction 
            data={data.filter(item => item.type !== "COIN_IN" && item.type !== "COIN_OUT")}
            fetchData={fetchData}
            filterOnclick={() => setSlideOpen(!slideOpen)}
          />
        </AsyncBlock>
      </PageSection>

    </PageContainer>
  );
};

export default MesasTransaction;
