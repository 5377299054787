import {toast} from "react-toastify";

const toastSucessStyle = {
  backgroundColor:"#37ba32",
  fontSize: "14px",
  paddingRight: "8px",
  borderRadius: "6px",
}

const toastErrorSucessStyle = {
  backgroundColor:"#dc2626",
  fontSize: "14px",
  paddingRight: "8px"
}

const successToast = (text = "Se ha guardado correctamente", time = 3000) => {
  return (
    toast.success(text, {
      position: "top-right",
      autoClose: time,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: 'colored',
      style: toastSucessStyle
    })
  )
}

const errorToast = (text = "Ocurrió un problema al guardar. Intente de nuevo en unos minutos", time = 5000) => {
  toast.error(text, {
    position: "top-right",
    autoClose: time,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: 'colored',
    style: toastErrorSucessStyle
  })
}

export {successToast, errorToast};
