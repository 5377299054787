import React, {useMemo} from 'react'
import PageSection from "../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import {Apis} from "../../../../util/Apis";
import useFetch2 from "../../../../hooks/useFetch2";
import {numberFormat} from '../../../../components/util/NumberUtil';
import ReactTable from '../../../../components/util/ReactTable';
import AsyncBlock from '../../../../components/forms/AsyncBlock';
import PageContainer from '../../../../components/layout/PageContainer';

const BillOnline = () => {

  const {data, fetchData, loading, error} = useFetch2(Apis.TGM + '/machines/bill-online', []);

  const columns = useMemo(
    () => [
      {
        header: 'Máquina',
        accessorKey: 'machine',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Juego',
        id: 'gameName',
        accessorFn: (row) => row.gameName === "" ? '--' : row.gameName,
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'font-small',
        },
      },
      {
        header: 'Denom.',
        accessorKey: 'den',
        enableSorting: false, // para deshabilitar el sort
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Créditos',
        accessorKey: 'currentCredits',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
      },
      {
        header: 'Inicial',
        accessorKey: 'billIni',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
      },
      {
        header: 'Ahora',
        accessorKey: 'billFin',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
      },
      {
        header: 'Bill',
        id: 'bill',
        accessorFn: (row) => row.bill === null ? '--' : ('S/ ' + numberFormat(row.bill)),
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        }
      },
    ]
    , []
  )

  return (
    <PageContainer>

      <PageSection>
        <Title text="Billetero online" type={'page'}/>

        <AsyncBlock loading={loading} error={error}>
          <ReactTable
            columns={columns}
            data={data || []}
            customSearch
            filterRefresh={fetchData}
            loading={loading}
            pageSize={100}
            sortArray={[{id: 'machine', asc: false}]}
            noData='No existen resultados para el filtro seleccionado'
          />
        </AsyncBlock>
      </PageSection>


    </PageContainer>
  )
}

export default BillOnline;
