import React, {useContext, useEffect} from "react";
import PublicidadForm from "./PublicidadForm";
import axios from "axios";
import {SiteContext} from "../../../common/context/SiteContext";
import {Apis} from "../../../../util/Apis";
import {putDateFormat} from "../recompensas/RewardsEdit";

const PublicidadNew = ({setAction, setTitle}) => {
  const {salaId} = useContext(SiteContext);

  const save = (params) => {
    if (params.url === undefined) params.url = ''
    params.salaId = salaId;
    putDateFormat(params)
    return axios.post(Apis.PC + "/ads-banners", params)
  };

  useEffect(() => {
    setTitle("Publicidad Nueva");
  });

  return <PublicidadForm value={{}} onSubmit={save} setAction={setAction}/>;
};

export default PublicidadNew;
