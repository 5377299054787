import React, {useContext} from 'react'
import {Select} from "../../../../components/forms/FormEl";
import {Apis} from "../../../../util/Apis";
import {SiteContext} from "../../context/SiteContext";
import useFetch from "../../../../hooks/useFetch";

const ManufacterSelect = React.forwardRef((props, ref) => {

  const {salaId} = useContext(SiteContext)
  //TODO, check sala id
  const {data, loading} = useFetch(`${Apis.TGM}/slot-mark`)

  return (
    <Select ref={ref} {...props}>
      {data && data.map(item => <option key={item.id} name={'shortName'} value={item.id}>{item.shortName}</option>)}
    </Select>
  )
})

export default ManufacterSelect;
