import React, {useCallback, useMemo} from "react";
import PageContainer from "../../../../components/layout/PageContainer";
import PageSection from "../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import Button from "../../../../components/forms/Button";
import {Apis} from "../../../../util/Apis";
import useFetch2 from "../../../../hooks/useFetch2";
import axios from "axios";
import useDeleteModal from "../../../../modals/useDeleteModal";
import DeleteGenericModal from "../../../../modals/DeleteGenericModal";
import useEditModal from "../../../../modals/useEditModal";
import AlmacenEdit from "./AlmacenEdit";
import AlmacenNew from "./AlmacenNew";
import GenericModal2 from "../../../../modals/GenericModal2";
import {FlexLayout} from "../../../../components/flex/Flex";
import AsyncBlock from "../../../../components/forms/AsyncBlock";
import ReactTable from "../../../../components/util/ReactTable";
import useModal from "../../../../modals/useModal";
import ModalInfo from "../../../../modals/ModalInfo";
import AlmacenDetail from "./AlmacenDetail";

const AlmacenList = () => {

  const {loading, data, fetchData, error} = useFetch2(Apis.TGM + `/machine-warehouse`, []);
  const {wrapper: wrapper1, addAction, editAction} = useEditModal({
    editComponent: <AlmacenEdit/>,
    addComponent: <AlmacenNew/>,
    onRefresh: fetchData
  })

  const deleteElement = id => axios.delete(Apis.TGM + `/machine-warehouse/${id}`)

  const {
    wrapper: wrapper2,
    openDeleteModal
  } = useDeleteModal({
    text: '¿Desea eliminar este almacen?',
    action: deleteElement,
    onRefresh: fetchData
  })

  const {wrapper: wrapper3, someAction: mostrarAction} = useModal({
    component: <AlmacenDetail />
  })

  const actionsValue = useCallback(props => <>{
    <FlexLayout justifyContent='center'>
      <Button variant={'primary'} size={'s'} onClick={() => {
        mostrarAction({
          id: props.renderValue()
        })
      }
      }>Ver maquinas</Button>
      <Button variant={'light'} size={'s'} onClick={() => editAction(props.renderValue())}>Editar</Button>
      <Button variant={'light'} size={'s'} onClick={() => openDeleteModal(props.renderValue())}>Eliminar</Button>
    </FlexLayout>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }</>, []);

  const columns = useMemo(
    () => [
      {
        header: 'Numero de alamacen',
        accessorKey: 'id',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Nombre',
        accessorKey: 'name',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Cantidad de maquinas',
        accessorKey: 'count',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        }
      },
      {
        header: 'Acciones',
        accessorKey: 'id',
        enableGlobalFilter:false,
        enableSorting:false,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: actionsValue
      }
    ]
    , []
  )

  return (
    <PageContainer>
      <PageSection>

          <Title text="Lista de almacenes" type={'page'}/>

        <AsyncBlock loading={loading} error={error}>
          <ReactTable
            columns={columns}
            data={data || []}
            filterAdd={() => addAction()}
            customSearch
            noData='No existen resultados'
            pageSize={15}
          />
        </AsyncBlock>

      </PageSection>

      <GenericModal2 {...wrapper1}/>
      <DeleteGenericModal {...wrapper2}/>
      <ModalInfo {...wrapper3} />
    </PageContainer>
  )
}

export default AlmacenList
