import styled from "styled-components";

const MyButton = styled.button`
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  background: #fff;
  border: 1px solid #dcdfe6;
  color: #566074;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  transition: .1s;
  font-weight: 500;
  -ms-user-select: none;
  padding: 12px 20px;
  font-size: 1rem;
  border-radius: 4px;
`

const PrimaryButton = styled(MyButton)`
  color: #fff;
  background-color: #4429b4;
  border-color: #4429b4;
`

const SecondaryButton = styled(MyButton)`
  color: var(--purple);
  background-color: #00dd8d;
  border: 2px solid #00dd8d;
`

const MiniButton = styled(MyButton)`
  color: var(--purple);
  background-color: #00dd8d;
  border: 2px solid #00dd8d;

  padding: 6px;
  font-size: 0.8rem;
`
const DeleteButton = styled(MyButton)`
  color: #f8f9fa;
  background-color: #dc3545;
  border-color: #dc3545;
  margin-right: 20px;
  padding: 6px;
  font-size: 0.8rem;
`

export {PrimaryButton, SecondaryButton, MiniButton, DeleteButton}
export default MyButton
