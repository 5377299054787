import styled from "styled-components";

const StadisticValue = styled.span`
  font-size: 24px;
  line-height: 30px;
  font-family: var(--headers-font-family);
  font-weight: var(--headers-font-weight, 700);
  padding-bottom: 4px;
`
export default StadisticValue;
