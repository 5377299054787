import React, {useContext, useEffect, useRef, useState} from 'react'
import {useHistory} from "react-router";
import styled from 'styled-components'
import {LiaDoorOpenSolid, LiaUserEditSolid} from "react-icons/lia";
import {logoutUser} from '../../modules/login/actions/authentication.action';
import AuthContext from '../../modules/login/store/AuthContext';

const AvatarContainer = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
`
const Menu = styled.div`
  position: absolute;
  display: ${props => props.open ? 'flex' : 'none'};
  flex-direction: column;
  bottom: 0%;
  left: 100%;
  background:#171717;
  border: 1px solid #404040;
  margin-left:16px;
  border-radius:8px;
  width: 184px;
  padding-bottom:8px;
`

const MenuItem = styled.div`
  display: inline-flex;
  align-items:center;
  gap:4px;
  padding: 8px 16px;
  cursor: pointer;
  z-index:1;
`

const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
`

const TooltipText = styled.span`
  visibility: hidden;
  width: 140px;
  font-size:12px;
  font-weight: 600;
  background: #171717;
  color: white;
  border: 1px solid #262626;
  padding: 8px;
  position: absolute;
  z-index: 1;
  top: -1px;
  left: 100%;
  margin-left: 8px;
  border-radius: 8px;
  opacity: 0;
  transition: opacity 0.3s ease 0s;
`

const TooltipTrigger = styled.div`
  cursor: pointer;

  &:hover + ${TooltipText} {
    visibility: visible;
    opacity: 1;
  }
`

export const Tooltip = ({ text, children }) => {
  return (
    <TooltipContainer>
      <TooltipTrigger>{children}</TooltipTrigger>
      <TooltipText>{text}</TooltipText>
    </TooltipContainer>
  );
};

const AvatarName = ({name, lastName,avatar}) => {

  const history = useHistory()
  const {dispatch} = useContext(AuthContext)
  const [menuOpen, setMenuOpen] = useState(false);
  const containerRef = useRef(null);
  const initials = (name?.[0] || '') + (lastName?.[0] || '');

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuOpen && containerRef.current && !containerRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    }
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    }
  }, [menuOpen]);


  return (
    <AvatarContainer id="avatar-container" ref={containerRef} onClick={handleMenuToggle}>
      <img src={avatar} alt={initials} width={36} height={36} style={{borderRadius:'50%'}} />
      <Menu open={menuOpen}>
        <div style={{display:'flex', flexDirection:'row', gap:'8px',alignItems:'center',padding:'8px',borderBottom:'1px solid #404040'}}>
          <img src={avatar} alt={initials} width={36} height={36} style={{borderRadius:'50%'}} />
          <span>{name} {lastName}</span>
        </div>
        <MenuItem onClick={()=> history.push('/admin/profile')}>
          <LiaUserEditSolid size={20}/>
          Perfil
        </MenuItem>
        <MenuItem onClick={() => logoutUser(dispatch)}>
          <LiaDoorOpenSolid size={20}/>
          Cerrar Sesión
        </MenuItem>
      </Menu>
    </AvatarContainer>
  )
}

export default AvatarName
