import React from 'react';
import PageSection from "../../../../components/layout/PageSection";
import {PageBackLabel} from "../../../../components/layout/PageBackLabel";
import {Title} from "@wargostec/react-components";
import PageContainer from "../../../../components/layout/PageContainer";
import {useHistory} from "react-router";
import SiteForm from "./SiteForm";
import axios from "axios";
import {Apis} from "../../../../util/Apis";
import {errorToast, successToast} from "../../../../components/layout/ToastStyles";

const SiteNew = () => {

  const querystring = window.location.search
  const query = new URLSearchParams(querystring)
  let tenant = query.get('tenant')
  const history = useHistory()

  const create = async (params) => {
    params.tenant = tenant
    await axios.post(Apis.CUSTOMERAPP + '/site-info', params)
      .then(res => {
        successToast()
        history.push('/crm/sites')
      }).catch(err => {
        errorToast()
      })
  }

  return (
    <PageContainer>
      <PageSection>
        <PageBackLabel text='Sitios' onClick={() => history.push('/crm/sites')}/>
          <Title text="Nuevo sitio"/>
      </PageSection>

      <PageSection>
        <SiteForm value={{}} onSubmit={create} fetchData={{}}/>
      </PageSection>
    </PageContainer>
  );
};

export default SiteNew;
