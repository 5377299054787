import React, { useEffect, useContext } from 'react';
import CardForm from "./CardForm";
import axios from "axios";
import { Apis } from "../../../../../util/Apis";
import { SiteContext } from "../../../../common/context/SiteContext";

const CardNew = ({ setTitle, setAction }) => {
  const { salaId } = useContext(SiteContext)
  const save = (params) => {
    params.salaId = salaId
    return axios.post(Apis.PC + "/card", params)
  }
  useEffect(() => {
    setTitle("Nueva Tarjeta")
  }, [])
  return (
    <CardForm value={{}} onSubmit={save} setAction={setAction} />
  );
}

export default CardNew;