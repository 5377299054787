import React from 'react';
import { useHistory } from "react-router";
import { Title } from "@wargostec/react-components";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { FaPlus } from "react-icons/fa";
import { Card } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Moment from "react-moment";
import { Apis } from "../../../../util/Apis";
import PageContainerFull from "../../../../components/layout/PageContainerFull";
import PageSection from "../../../../components/layout/PageSection";
import Button, { ButtonBox } from "../../../../components/forms/Button";
import { LoadingTableV2 } from "../../../../components/forms/Tables";
import NewPointsAwarded from "./PointsAwarded/NewPointsAwarded";
import { Alert } from "../../../../components/util/Alert";
import useGet from '../../../../hooks/useGet';
import useToggle from '../../../../hooks/useToggle';

const UploadMassivePointsList = () => {
  const baseUrl = '/crm'
  const history = useHistory()
  const { isOpen: show, toggler } = useToggle({})

  const { loading, data, fetchData, error } = useGet({
    url: Apis.PC + '/point-batch',
    initialValue: []
  })

  const handleClose = () => {
    fetchData();
    toggler();
  }

  const download = () => {
    const link = "https://wgt-tmp-uploads.s3.amazonaws.com/puntos_carga_masiva_ejemplo.xlsx";
    window.location.href = link;
  }

  return (
    <PageContainerFull>
      <PageSection>
        <Title text="Lista de cargas masivas de puntos" type={'page'} />
        <ButtonBox>
          <Button variant={'primary'} onClick={() => history.push(`${baseUrl}/upload-points`)}>
            <FaPlus /> Nuevo
          </Button>
        </ButtonBox>
      </PageSection>

      <PageSection>
        <Row>
          <Col>
            <Alert text="Si el cliente esta registrado solo es necesario el DNI y el número de puntos, pero si el cliente es nuevo
        ingresar todos los campos del excel" variant={'warning'} />
          </Col>
          <Col>
            <ButtonBox style={{ cursor: "pointer" }}>
              <Button variant={'primary'} onClick={download}>Descargar ejemplo</Button>
            </ButtonBox>
          </Col>
        </Row>
      </PageSection>

      <PageSection>
        <Card>
          <LoadingTableV2 loading={loading} error={error} data={data} noDataText={"No hay información"} ></LoadingTableV2>
          <Table striped hover size={'sm'}>
            <thead>
              <tr>
                <th>Id</th>
                <th>Fecha</th>
                <th>Hora</th>
                <th>Cantidad</th>
              </tr>
            </thead>
            <tbody>
              {data && data.map(item =>
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td><Moment format={'DD/MM/YYYY'}>{item.createdAt}</Moment></td>
                  <td><Moment format={'hh:mm a'}>{item.createdAt}</Moment></td>
                  <td>{item.totalPoints}</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Card>
      </PageSection>
      <NewPointsAwarded show={show} onHide={() => handleClose()} />
    </PageContainerFull>
  );
};

export default UploadMassivePointsList;
