import styled from "styled-components";

const Row = styled.div`
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
`

const Col = styled.div`
  flex-basis:  100%;
  flex : 1;
  margin: 0 10px;
`

export {Col, Row}
