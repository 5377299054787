import React, {useContext, useEffect} from "react";
import {Apis} from "../../../../util/Apis";
import Card from "../../../../components/forms/Card";
import Table from "react-bootstrap/Table";
import useFetch2 from "../../../../hooks/useFetch2";
import {SiteContext} from "../../../common/context/SiteContext";
import NoDataTable from "../../../common/components/NoDataTable";
import Loader1 from "../../../common/components/Loader1";
import Moment from "react-moment";

const DepositHistory = ({customerId, anforaId, setTitle}) => {

  const {salaId} = useContext(SiteContext)
  let defaultParams = {salaId: salaId}

  const {
    loading,
    data
  } = useFetch2(Apis.PC + `/coupon-print-job?anforaId=${anforaId}&customerId=${customerId}&purged=false`, [], defaultParams);

  useEffect(() => {
    setTitle("Cupones Depositados");
  }, [])

  return (
    <Card padding>
      <Table striped hover>
        <thead>
        <tr>
          <th>Fecha Deposito</th>
          <th>Hora Deposito</th>
          <th>Cliente</th>
          <th>Cantidad de Cupones</th>
        </tr>
        </thead>
        <tbody>
        {data && data.map(item =>
          <tr key={item.customerId}>
            <td><Moment format={'DD/MM/YYYY'}>{item.createdAt}</Moment></td>
            <td><Moment format={'HH:mm:ss'}>{item.createdAt}</Moment></td>
            <td>{item.customer}</td>
            <td>{item.quantity}</td>
          </tr>
        )}
        </tbody>
      </Table>
      {!loading ? data.length <= 0 && <NoDataTable text={"Aún no hay cupones registrados que mostrar"}/> : <Loader1/>}
    </Card>
  );
};

export default DepositHistory;
