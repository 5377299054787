import React, {useEffect} from 'react';
import axios from 'axios';
import {Apis} from "../../../../util/Apis";
import useFetch2 from "../../../../hooks/useFetch2";
import {errorToast, successToast} from "../../../../components/layout/ToastStyles";
import AperturaMesaForm from "./AperturaMesaForm";

const EditarApertura = ({id,setAction, setTitle}) => {

  const {data} = useFetch2(Apis.TABLES + '/tables/' + id?.tableId,{})

  const save = (params) => {
    params.tableId = id?.tableId
    axios.put(Apis.TABLES + '/snap/' + id?.snapId, params)
      .then(() => {
        successToast()
      })
      .catch(() => {
        errorToast();
      })
  }

  useEffect(() => {
    setTitle("Editar apertura")
  }, [])

  return (
    <AperturaMesaForm data={data} onSubmit={save} setAction={setAction}/>  );
};

export default EditarApertura;
