import React from 'react';
import './App.css';
import {Route, Switch} from "react-router";
import Auth from "./modules/login/store/Auth";
import SecureApp from "./modules/common/pages/SecureApp";
import LogoutPage from "./modules/login/LogoutPage";
import CallbackPage from "./modules/login/CallbackPage";
import NewLoginPage from "./modules/login/NewLoginPage";
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <Auth>
      <Switch>
        {/*Desde aqui redireccionamos a los demas modulos */}
        <Route path="/logout" component={LogoutPage}/>
        <Route exact path="/login" component={NewLoginPage}/>
        <Route path="/callback" component={CallbackPage}/>
        <Route path='/' component={SecureApp}/>
      </Switch>
    </Auth>
  );
}

export default App;
