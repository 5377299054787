import React, {useContext, useEffect} from 'react';
import axios from 'axios'
import {Apis} from "../../../../util/Apis";
import {errorToast, successToast} from "../../../../components/layout/ToastStyles";
import AperturaMesaForm from "./AperturaMesaForm";
import useFetch2 from "../../../../hooks/useFetch2";
import {SiteContext} from "../../../common/context/SiteContext";

const AperturarMesaNew = ({id,setAction, setTitle}) => {

  const {data} = useFetch2(Apis.TABLES + '/tables/' + id,{})

  const {salaId} = useContext(SiteContext);

  const save = async (params) => {
    params.salaId = salaId
    params.tableId = id
    await axios.post(Apis.TABLES + '/snap', params)
      .then(() => {
        successToast()
      })
      .catch(() => {
        errorToast();
      })
  }

  useEffect(() => {
    setTitle("Aperturar mesa")
  }, [])

  return (
    <AperturaMesaForm data={data} onSubmit={save} setAction={setAction}/>
  );
};

export default AperturarMesaNew;
