import React from 'react';
import {Col, Row} from "../../../../../components/flex/FlexLayout";
import Input from "../../../../../components/forms/FormEl";
import SwitchToggle from "../../../../../components/layout/SwitchToggle";

const RangeSalesProfit = ({fields, rangeArray, setRangeArray, register, addRanges, remove}) => {
  return (
    <>
      <div>
        <Row style={{marginBottom: "10px"}}>
          <Col>De</Col>
          <Col>Hasta</Col>
          <Col>Ganancia</Col>
          <Col>Comisión</Col>
        </Row>
        <div>
          {fields && fields.map((item, index) =>
            <Row key={item.id} style={{display: 'flex', marginBottom: "10px"}}>
              <Col>
                <Input type={"number"} defaultValue={item.fromMoney} {...register(`listRanges[${index}].fromMoney`)}/>
              </Col>
              <Col>
                <Input type={"number"} defaultValue={item.toMoney}  {...register(`listRanges[${index}].toMoney`)}/>
              </Col>
              <Col>
                <SwitchToggle {...register(`listRanges[${index}].percentageEnable`)} id={"percentageEnable"} label={"Habilitado"}/>
              </Col>
              <Col>
                <SwitchToggle {...register(`listRanges[${index}].commissionEnable`)} id={"commissionEnable"} label={"Habilitado"}/>
              </Col>
            </Row>
          )}
        </div>
      </div>

    </>
  );
};

export default RangeSalesProfit;
