import React, {useEffect} from "react";
import axios from "axios";
import {Apis} from "../../../../util/Apis";
import SorteoPrintForm from "./SorteoPrintForm";

const SorteoPrint = ({id,setAction, setTitle}) => {

  const print =async (params) => {
    await axios.get(Apis.SORTEO + `/sorteos/${id}/print?printerId=${params.printerId}`)
  }

  useEffect(() => {
    setTitle("Imprimir Sorteo")
  }, [])

  return (
    <SorteoPrintForm value={{}} onSubmit={print} setAction={setAction}/>
  )
}

export default SorteoPrint
