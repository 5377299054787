import React, {useContext, useEffect} from "react";
import axios from "axios";
import {Apis} from "../../../../../util/Apis";
import PrinterZoneForm from "./PrinterZoneForm";
import {useParams} from "react-router";

const getComputerId = async (params) => {
  return axios.get(Apis.PRINT + `/printers/` + params.printerId).then(resp => params.computerId = resp.data.computerId)
}

const PrinterZoneNew = ({setAction, setTitle}) => {

  const {id} = useParams()
  const save = async (params) => {
    params.couponTypeId = id
    params.computerId = await getComputerId(params)
    await axios.post(Apis.PC + `/coupon-zone-printer`, params)
  }

  useEffect(() => {
    setTitle("Nueva Impresora para Zona")
  }, [])

  return (
    <PrinterZoneForm value={{}} onSubmit={save} setAction={setAction}/>
  )
}

export default PrinterZoneNew
export {getComputerId}
