import React from 'react';
import {MdFiberManualRecord} from 'react-icons/md';

const SegmentCircle = React.forwardRef((props, ref) => {

  const getColors = (id) => {
    const colors = props.array.find(item => item.id === id)
    return colors !== undefined ? colors.hexColor : 'transparent'
  }

  return (
    <MdFiberManualRecord color={getColors(props.levelId)} size={28}/>
  );
})

export default SegmentCircle;


