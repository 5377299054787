import React, {useContext, useEffect} from "react";
import axios from 'axios';
import FormGroup from "../../../components/forms/FormGroup";
import Form from "react-bootstrap/Form";
import {Controller, useForm} from "react-hook-form";
import {errorToast, successToast} from "../../../components/layout/ToastStyles";
import {SiteContext} from "../../common/context/SiteContext";
import {Apis} from "../../../util/Apis";
import CustomerSearchInputV2 from "../../common/components/CustomerSearchInputV2";


const LateLoginModal = ({id, setAction, setTitle}) => {

  const {salaId} = useContext(SiteContext)

  const {register, control, handleSubmit} = useForm();

  const save = (params) => {
    params.salaId = salaId

    axios.post(Apis.PC + '/session/' + id + '/late-login', params)
      .then(res => {
        successToast()
      }).catch(err => {
      errorToast();
    })
  }

  useEffect(() => {
    setTitle("Loguear a cliente")
    setAction(() => handleSubmit(save))
  }, [])

  return (
    <Form>
      <FormGroup controlId="name">
        <label>Cliente</label>
        <Controller name="customerId" control={control} render={({field}) => <CustomerSearchInputV2 {...field} />}/>
      </FormGroup>
    </Form>
  )
}

export default LateLoginModal
