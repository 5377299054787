import React, {useContext, useEffect, useState} from 'react';
import {SiteContext} from "../../../common/context/SiteContext";
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import {Controller, useForm} from "react-hook-form";
import axios from "axios";
import useDeleteModal from "../../../../modals/useDeleteModal";
import Slidebar from "../../../common/layout/Slidebar";
import SalaIdInput from "../../../common/components/SalaIdInput";
import FormGroup from "../../../../components/forms/FormGroup";
import CustomerSearchInputV2 from "../../../common/components/CustomerSearchInputV2";
import Button, {ButtonBox} from "../../../../components/forms/Button";
import PageSection from "../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import Table from "react-bootstrap/Table";
import Moment from "react-moment";
import DeleteGenericModal from "../../../../modals/DeleteGenericModal";
import MetodoSelectCaja from "../../../common/components/selects/MetodoSelectCaja";
import Card from '../../../../components/forms/Card';
import PageContainer from '../../../../components/layout/PageContainer';
import {numberFormat} from '../../../../components/util/NumberUtil';
import PeriodAndFondoSelect from './PeriodAndFondoSelect';
import EmptyState from '../../../common/components/EmptyState';
import {TbFileSad} from 'react-icons/tb';
import {PiHandCoinsBold} from 'react-icons/pi';
import AsyncBlock from '../../../../components/forms/AsyncBlock';
import {getCasinoDay} from '../../../../components/util/CasinoDates';

const FichasReport = () => {
  const {salaId} = useContext(SiteContext)
  const [slideOpen, setSlideOpen] = useState(false)
  const [period, setPeriod] = useState(getCasinoDay());

  const {register, handleSubmit, control, watch} = useForm();
  const [params, setParams] = useState({
    salaId: salaId,
    fondoId: "",
    period:period,
    customerId: "",
    type: ""
  });

  const customerId = watch("customerId");
  const type = watch("type");

  const determineApiEndpoint = (fondoId) => {
    return fondoId === "" 
      ? Apis.CAJA + '/operation-mesas/report/by-period' 
      : Apis.CAJA + '/operation-mesas/report/by-fondo';
  };


  const {data: data2} = useFetch2(Apis.SALAS + '/salas-config/' + salaId, [])
  const {loading, data, fetchData, error} = useFetch2(determineApiEndpoint(params.fondoId), null, params, false);

  const deleteElement = id => axios.delete(Apis.CAJA + '/operation-mesas/' + id)

  const {wrapper: wrapper2, openDeleteModal} = useDeleteModal({
    text: '¿Desea eliminar esta operación de caja?',
    action: deleteElement,
    onRefresh: fetchData
  });

  useEffect(() => {
    setParams((prevParams) => ({
      ...prevParams,
      customerId,
      type,
      period,
    }));
  }, [customerId, type,period]);

  const handleFondoIdChange = (fondoId) => {
    setParams((prevParams) => ({...prevParams, fondoId: fondoId}));
  };

  const onSubmit = () => {
    fetchData();
  };

  const getPayType = (value) => {
    switch (value) {
      case 1: return 'EFECTIVO'
      case 2: return 'NICO'
      case 3: return 'POS'
      default: return 'EFECTIVO'
    }
  }

  return (
    <PageContainer>
      <Slidebar visible={slideOpen} onHide={() => setSlideOpen(false)}>
        <div style={{padding: '16px'}}>
          <SalaIdInput {...register("salaId")} />
          <PeriodAndFondoSelect
            onFondoChange={handleFondoIdChange}
            period={period}
            setPeriod={setPeriod}
            {...register("fondoId")} />
          <FormGroup>
            <label>Operación</label>
            <MetodoSelectCaja {...register("type")} />
          </FormGroup>
          <FormGroup>
            <label>Cliente</label>
            <Controller name="customerId" control={control} render={({field}) => <CustomerSearchInputV2 {...field} />} />
          </FormGroup>
          <Button variant={'primary'} title='Buscar' onClick={handleSubmit(onSubmit)} />
        </div>
      </Slidebar>

      <PageSection>
        <Title text="Operaciones mesas" type={'page'} />
        <ButtonBox>
          <Button variant={'primary'} title={'Filtrar'} onClick={() => setSlideOpen(!slideOpen)} />
        </ButtonBox>

        <AsyncBlock loading={loading} error={error}>
          {data === null ?
            <Card padding>
              <EmptyState
                title={'Operaciones mesas'}
                subtitle={'Haga click en FILTRAR para realizar una busqueda'}
                icon={<PiHandCoinsBold size={32} />}
              />
            </Card>
            :
            <>
              {data?.data?.length === 0 ?
                <Card padding>
                  <EmptyState
                    title={'Sin resultados'}
                    subtitle={'Haga click en FILTRAR para realizar una nueva busqueda'}
                    icon={<TbFileSad size={32} />}
                  />
                </Card>
                :
                <>
                  <Card>
                    <Table striped hover responsive>
                      <thead>
                        <tr>
                          <th>Fondo</th>
                          <th className="text-center">Operación</th>
                          <th>Cliente</th>
                          <th className="text-right">Sub total</th>
                          <th className="text-right">Comisión</th>
                          <th className="text-right">Total</th>
                          <th className="text-center">Tipo de pago</th>
                          <th className="text-center">Fecha</th>
                          <th className="text-center">Acciones</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data && data.map(item =>
                          <tr key={item.id}>
                            <td>{item.fondoName}</td>
                            <td className="text-center">{item.type && item.type === 1 ? "ENTRADA" : "SALIDA"}</td>
                            <td>{item.customerName}</td>
                            <td className="text-right">
                              {data2 && data2.currencySymbol !== "" ?
                                <>{data2.currencySymbol} {numberFormat(item.amount - item.commission)}</>
                                :
                                <>{'S/ ' + numberFormat(item.amount - item.commission)}</>
                              }
                            </td>
                            <td className="text-right">
                              {(item.commission !== null && item.payType !== 1)
                                ? `S/ ${numberFormat(item.commission)}`
                                : '--'
                              }
                            </td>
                            <td className="text-right">
                              {data2 && data2.currencySymbol !== "" ?
                                <>{data2.currencySymbol} {numberFormat(item.amount)}</>
                                :
                                <>{'S/ ' + numberFormat(item.amount)}</>
                              }
                            </td>
                            <td className="text-center">{getPayType(item.payType)}</td>

                            <td className="text-center"><Moment format={'YYYY-MM-DD hh:mm a'}>{item.createdAt}</Moment></td>
                            <td className="text-center">
                              <Button variant='danger' size='s' onClick={() => openDeleteModal(item.id)}>Eliminar</Button>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </Card>
                </>
              }
            </>
          }
        </AsyncBlock>
      </PageSection>
      <DeleteGenericModal {...wrapper2} />
    </PageContainer>
  );
};

export default FichasReport;
