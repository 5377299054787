import React from "react";
import DropdownMenuItem from "./DropdownMenuItem";
import SecComponent from "./SecComponent";

const SecDropdownMenuItem = ({title, link, code,onClick}) => {

  return (
    <SecComponent code={code}>
      <DropdownMenuItem title={title} link={link} onClick={onClick}/>
    </SecComponent>
  )
};

export default SecDropdownMenuItem;
