import React from 'react';
import {MdFiberManualRecord} from 'react-icons/md';

const SegmentCircleV2 = React.forwardRef((props, ref) => {
  const {color = '#D9D9D9'} = props;

  return (
    <MdFiberManualRecord color={color} size={28}/>
  );
});

export default SegmentCircleV2;
