import React, {useContext, useEffect} from 'react';
import {useParams} from "react-router";
import axios from "axios";
import {Apis} from "../../../../../util/Apis";
import {SiteContext} from "../../../../common/context/SiteContext";
import SiteSalaForm from "./SiteSalaForm";

const SiteSalaNew = ({setAction, setTitle}) => {

  const {salaId} = useContext(SiteContext)
  const {id} = useParams()

  const save = async (params) => {
    params.salaId= salaId
    params.siteId = id
    await axios.post(Apis.CUSTOMERAPP + '/site-sala', params)
  }

  useEffect(() => {
    setTitle("Nueva sala")
  }, [])

  return (
    <SiteSalaForm value={{}} onSubmit={save} setAction={setAction}/>
  );
};

export default SiteSalaNew;
