import React, {useContext} from "react";
import {Apis} from "../../../../../util/Apis";
import useFetch2 from "../../../../../hooks/useFetch2";
import {useHistory, useParams} from "react-router";
import EventForm from "./EventForm";
import {SiteContext} from "../../../../common/context/SiteContext";
import axios from "axios";
import {errorToast, successToast} from "../../../../../components/layout/ToastStyles";

const EventEdit = () => {

  const {salaId} = useContext(SiteContext)
  const history = useHistory()

  const {id} = useParams()
  const {data,loading} = useFetch2(Apis.BINGO + '/event/' + id + '/range',[])

  const update = (params) => {
    return axios.put(Apis.BINGO + '/event/' + id + '/range', params)
      .then(() => {
        successToast("Evento actualizado correctamente")
        history.push(`/bingos/eventos`)
      })
      .catch(error => errorToast(error.response.data.message ? error.response.data.message : "Ocurrió un error al guardar.", 7000))
  }

  return (
    <React.Fragment>
      {!loading && <EventForm data={data} onSubmit={update}/>}
    </React.Fragment>
  )
}

export default EventEdit
