import React, {useRef, useState} from 'react'
import {Apis} from "../../../../util/Apis";
import useFetch2 from "../../../../hooks/useFetch2";
import Select from "react-select";

const MachineSelectV2 = React.forwardRef((props, ref) => {

  const {data, loading} = useFetch2(`${Apis.TGM}/machines/combo`)
  const [selectedMachine, setSelectedMachine] = useState(null);
  const machineSelectRef = useRef(null);

  const handleMachineChange = (selectedOption) => {
    setSelectedMachine(selectedOption);
    props.onChange(selectedOption.value);
  };

  const machineOptions = data?.map(machine => ({
    value: machine.confId,
    label: machine.machine,
  })) || [];

  return (
    <Select
      options={machineOptions}
      value={selectedMachine}
      onChange={handleMachineChange}
      placeholder="Seleccione..."
      autoFocus
      ref={machineSelectRef}
      styles={{
        container: (baseStyles) => ({
          ...baseStyles,
          width: '100%'
        }),
        indicatorSeparator: (baseStyles) => ({
          ...baseStyles,
          display: 'none'
        })
      }}
    />
  )
})

export default MachineSelectV2
