import React, { useContext, useState } from 'react'
import PageSection from "../../../../components/layout/PageSection";
import { Title } from "@wargostec/react-components";
import Table from "react-bootstrap/Table";
import FormGroup from "../../../../components/forms/FormGroup";
import Button, { ButtonBox } from "../../../../components/forms/Button";
import { Controller, useForm } from "react-hook-form";
import Slidebar from "../../../common/layout/Slidebar";
import { SiteContext } from "../../../common/context/SiteContext";
import { Apis } from "../../../../util/Apis";
import useSortableData, { ColumnSortable, getClassNamesFor } from "../../../../hooks/useSortableData";
import Card from "../../../../components/forms/Card";
import DateFilter from "../../../../components/datefilter/DateFilter";
import Moment from "react-moment";
import Container from 'react-bootstrap/Container'
import { getCasinoDay } from "../../../../components/util/CasinoDates";
import CustomerSearchInputV2 from "../../../common/components/CustomerSearchInputV2";
import AsyncBlock from "../../../../components/forms/AsyncBlock";
import { BsFilter } from 'react-icons/bs';
import CardOverview, { CardOverviewContainer } from '../../../../components/layout/CardOverview';
import { MdLocalPlay } from 'react-icons/md';
import EmptyState from '../../../common/components/EmptyState';
import CouponTypeSelectV2 from "../../../common/components/selects/CouponTypeSelectV2";
import useToggle from '../../../../hooks/useToggle';
import useGet from '../../../../hooks/useGet';

const CouponAccumulated = () => {

  const initialDate = getCasinoDay()
  const fechasDefault = { ini: initialDate, fin: initialDate }
  const [montoTotal, setMontoTotal] = useState(0)

  const { salaId } = useContext(SiteContext)
  const { isOpen: slideOpen, setIsOpen: setSlideOpen } = useToggle({})

  const [fechas, setFechas] = useState(fechasDefault)

  let defaultParams = { salaId: salaId, ini: fechas.ini, fin: fechas.fin }

  const [params, setParams] = useState(defaultParams)

  const { control, handleSubmit } = useForm({ defaultValues: defaultParams });
  const { data, loading, fetchData } = useGet({
    url: Apis.PC + `/coupon-prev`,
    params,
    initialValue: [],
    onSuccess: (data) => {
      let totalAmount = 0
      data?.forEach(item => {
        totalAmount = totalAmount + item.quantity
      })
      setMontoTotal(totalAmount)
    }
  })

  const { items, requestSort, sortConfig } = useSortableData(data && data)

  const handleChangeFechas = async (fechas) => {
    setFechas(fechas)
    let newParams = { ...params, ...fechas }
    if (newParams.couponTypeId) setParams(newParams)
    if (newParams && newParams.couponTypeId) await fetchData(newParams)
  }

  const getData = async (params) => {
    params.ini = fechas.ini
    params.fin = fechas.fin
    if (params.couponTypeId) setParams(params)
    if (params && params.couponTypeId) await fetchData(params)
  }

  const getMulti = (multp) => {
    let multiplier = '';
    if (multp !== 1) {
      multiplier = 'x' + multp;
    } else if (multp === 1) {
      multiplier = ' ';
    }
    return multiplier;
  }

  return (
    <Container fluid>

      <Slidebar visible={slideOpen} onHide={() => setSlideOpen(false)}>
        <div style={{ padding: '16px' }}>
          <FormGroup>
            <label>Cliente</label>
            <Controller name="customerId" control={control} render={({ field }) => <CustomerSearchInputV2 {...field} />} />
          </FormGroup>

          <FormGroup>
            <label>Promoción</label>
            <Controller
              name="couponTypeId"
              control={control}
              render={({ field }) => <CouponTypeSelectV2 {...field} />}
            />
          </FormGroup>
          <Button variant={'primary'} title='Buscar' onClick={handleSubmit(getData)} />
        </div>
      </Slidebar>

      <PageSection>
        <Title text="Cupones acumulados" type={'page'} />
        <ButtonBox>
          <Button variant='primary' onClick={() => setSlideOpen(!slideOpen)}>
            <BsFilter />
            <span>Filtrar</span>
          </Button>
        </ButtonBox>
      </PageSection>

      <PageSection>
        <Card padding style={{ background: '#0A0A0A' }}>
          <DateFilter fechas={fechas} setFechas={handleChangeFechas} />
        </Card>

        {montoTotal > 0 &&
          <AsyncBlock loading={loading}>
            <CardOverviewContainer gridColumns='repeat(3, 1fr)'>
              <CardOverview
                title={montoTotal + " cupones"}
                subtitle='Total de cupones'
                icon={<MdLocalPlay size={20} />}
              />
            </CardOverviewContainer>
          </AsyncBlock>
        }

        <AsyncBlock loading={loading}>
          {data?.length === 0 ?
            <Card padding>
              <EmptyState
                title={'No hay resultados para este filtro'}
                subtitle={'Puede realizar una nueva busqueda haciendo click en Filtrar.'}
                icon={<MdLocalPlay size={32} />}
              />
            </Card>
            :
            <Card>
              <Table striped hover>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Máquina</th>
                    <th>Nombre</th>
                    <th>Apellido</th>
                    <th>Juego</th>
                    <th style={{ textAlign: 'center' }}>
                      <ColumnSortable type="button" onClick={() => requestSort('meterAmount')}
                        className={getClassNamesFor('meterAmount', sortConfig)}>
                        Jugada Ganadora
                      </ColumnSortable>
                    </th>
                    <th style={{ textAlign: 'center' }}>Multiplicador</th>
                    <th style={{ textAlign: 'center' }}>Cupones Ganados</th>
                    <th>
                      <ColumnSortable type="button" onClick={() => requestSort('createdAt')}
                        className={getClassNamesFor('createdAt', sortConfig)}>
                        Fecha
                      </ColumnSortable>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {items.map((item) =>
                    <tr key={item.id}>
                      <td>{item.id}</td>
                      <td>{item.machine}</td>
                      <td>{item.firstName}</td>
                      <td>{item.lastName1}</td>
                      <td>{item.gameName}</td>
                      <td style={{ textAlign: 'center' }}>{item.meterAmount.toFixed(2)}</td>
                      <td style={{ textAlign: 'center' }}>{getMulti(item.multiplier)}</td>
                      <td style={{ textAlign: 'center' }}>{item.quantity}</td>
                      <td><Moment format={'HH:mm DD/MM/YYYY'}>{item.createdAt}</Moment></td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Card>
          }
        </AsyncBlock>
      </PageSection>
    </Container>
  )
}

export default CouponAccumulated;
