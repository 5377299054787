import React, {useContext, useEffect, useRef, useState} from 'react';
import Container from "react-bootstrap/Container";
import PageSection from "../../../../components/layout/PageSection";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {FlexLayout} from "../../../../components/flex/Flex";
import Card, {CardHeader} from "../../../../components/forms/Card";
import {Title} from "@wargostec/react-components";
import {SiteContext} from "../../../common/context/SiteContext";
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import axios from "axios";
import {getCasinoDay} from "../../../../components/util/CasinoDates";
import AsyncBlock from "../../../../components/forms/AsyncBlock";
import {errorToast, successToast} from "../../../../components/layout/ToastStyles";
import Button from "react-bootstrap/Button";
import {AiFillDelete} from "react-icons/ai";
import styled from "styled-components";
import Moment from "react-moment";

const ItemOrders = styled.div`
    display: flex;
    flex-direction: row;
    margin: 1px;
    text-align: left;
    font-size: 20px;
    padding-top: 10px;
    align-items: center;
`

const PendingOrders = () => {

  const dragItem = useRef();
  const {salaId} = useContext(SiteContext)
  const ini = getCasinoDay()
  const defaultParams = {salaId: salaId, ini: ini, fin: ini}

  const [params, setParams] = useState(defaultParams)

  const {
    loading,
    data,
    fetchData,
    error
  } = useFetch2(Apis.FOOD + '/food-orders/state-order?state=GENERADO', [], params, false)

  const {
    loading: loading2,
    data: data2,
    fetchData: fetchData2,
    error: error2
  } = useFetch2(Apis.FOOD + '/food-orders/state-order?state=PREPARACION', [], params, false)

  const {
    loading: loading3,
    data: data3,
    fetchData: fetchData3,
    error: error3
  } = useFetch2(Apis.FOOD + '/food-orders/state-order?state=LISTO', [], params, false)


  const postGenerate = (id) => {
    axios.post(Apis.FOOD + `/food-orders/${id}/set-state?state=PREPARACION`).then(res => {
      fetchData()
      fetchData2()
      successToast('Orden en preparación')
    }).catch(error => {
      errorToast('Ocurrio un problema')
    })
  }

  const postFinish = (id) => {
    axios.post(Apis.FOOD + `/food-orders/${id}/set-state?state=LISTO`).then(res => {
      fetchData2()
      fetchData3()
      successToast('Orden listo')
    }).catch(error => {
      errorToast('Ocurrio un problema')
    })
  }

  const postDelivered = (id) => {
    axios.post(Apis.FOOD + `/food-orders/${id}/set-state?state=ENTREGADO`).then(res => {
      fetchData3()
      successToast('Pedido entregado')
    }).catch(error => {
      errorToast('Ocurrio un problema')
    })
  }

  const postDelete = (id) => {
    axios.post(Apis.FOOD + `/food-orders/${id}/set-state?state=ANULADO`).then(res => {
      fetchData()
      fetchData2()
      successToast('Pedido anulado')
    }).catch(error => {
      errorToast('Ocurrio un problema')
    })
  }

  useEffect(() => {
    let intervalId = setInterval(fetchData, 5000)
    return (() => {
      clearInterval(intervalId)
    })
  }, [])

  useEffect(() => {
    fetchData()
    fetchData2()
    fetchData3()
  }, [])

  return (
    <Container fluid>

      <PageSection>
        <Title text="Ordenes pendientes" type={'page'}/>
      </PageSection>

      <PageSection>
        <Row>
          <Col xs={12} md={4}>
            <AsyncBlock loading={loading} error={error}>
              <Card padding>
                <CardHeader>
                  <strong style={{fontStyle: '16px'}}> PEDIDOS GENERADOS: {data && data.length}
                  </strong>
                </CardHeader>
                {
                  data && data.map(item =>
                    <ItemOrders id={item.id}>
                      <div style={{width: '90%'}}>
                        <Card padding
                              onClick={() => postGenerate(item.id)}>

                          <FlexLayout column alignItems='left'>
                            <div>
                              <span>Máquina: {item.confId}</span>
                            </div>
                            <div>
                              {
                                item.detail && item.detail.map(food =>
                                  <ul>
                                    <li>{food.quantity} x {food.food.name}</li>
                                  </ul>
                                )
                              }
                            </div>
                            <div>
                              {
                                item.customerId !== null &&
                                <span>Cliente: {item.customerId}</span>
                              }
                            </div>
                            <div>
                              Hora: <Moment format={'HH:mm'}>{item.createdAt}</Moment>
                            </div>
                          </FlexLayout>
                        </Card>
                      </div>
                      <div style={{width: '10%', paddingLeft: '10px'}}>
                        <Button variant={"danger"} style={{right: '20%'}} onClick={() => postDelete(item.id)}>
                          <AiFillDelete fontSize={'22px'}></AiFillDelete>
                        </Button>
                      </div>
                    </ItemOrders>
                  )
                }
              </Card>
            </AsyncBlock>
          </Col>


          <Col xs={12} md={4}>
            <AsyncBlock loading={loading2} error={error2}>
              <Card padding>
                <CardHeader>
                  <strong style={{fontStyle: '16px'}}>EN PREPARACIÓN: {data2 && data2.length}</strong>
                </CardHeader>
                {
                  data2 && data2.map(item =>
                    <ItemOrders id={item.id}>
                      <div style={{width: '90%'}}>
                        <Card padding
                              onClick={() => postFinish(item.id)}>

                          <FlexLayout column alignItems='left'>
                            <div>
                              <span>Máquina: {item.confId}</span>
                            </div>
                            <div>
                              {
                                item.detail && item.detail.map(food =>
                                  <ul>
                                    <li>{food.quantity} x {food.food.name}</li>
                                  </ul>
                                )
                              }
                            </div>

                            <div>
                              {
                                item.customerId !== null &&
                                <span>Cliente: {item.customerId}</span>
                              }
                            </div>
                            <div>
                              Hora: <Moment format={'HH:mm'}>{item.createdAt}</Moment>
                            </div>
                          </FlexLayout>
                        </Card>
                      </div>
                      <div style={{width: '10%', paddingLeft: '10px'}}>
                        <Button variant={"danger"} style={{right: '20%'}} onClick={() => postDelete(item.id)}>
                          <AiFillDelete fontSize={'22px'}></AiFillDelete>
                        </Button>
                      </div>
                    </ItemOrders>
                  )
                }
              </Card>
            </AsyncBlock>
          </Col>

          <Col xs={12} md={4}>
            <AsyncBlock loading={loading3} error={error3}>
              <Card padding>
                <CardHeader>
                  <strong>PEDIDOS LISTO: {data3 && data3.length}</strong>
                </CardHeader>
                {
                  data3 && data3.map(item =>
                    <div id={item.id}
                         style={{margin: '1px', textAlign: 'left', fontSize: '40px', paddingTop: '10px'}}
                         onClick={() => postDelivered(item.id)}>
                      <Card padding>

                        <FlexLayout column alignItems='left'>
                          <div style={{fontSize: '20px'}}>
                            <span>Máquina: {item.confId}</span>
                          </div>
                          <div style={{fontSize: '20px'}}>
                            {
                              item.detail && item.detail.map(food =>
                                <ul>
                                  <li>{food.quantity} x {food.food.name}</li>
                                </ul>
                              )
                            }
                          </div>

                          <FlexLayout alignItems='center'>
                            {
                              item.customerId !== null &&
                              <span>Cliente: {item.customerId}</span>
                            }
                          </FlexLayout>
                        </FlexLayout>

                      </Card>
                    </div>
                  )
                }
              </Card>
            </AsyncBlock>
          </Col>

        </Row>

      </PageSection>
    </Container>
  );
};

export default PendingOrders;
