import React, {useEffect, useState} from 'react';
import Button from "../forms/Button";
import moment from "moment";
import 'moment/locale/es';
import ToolbarTitle from "./ToolbarTitle";
import styled from "styled-components"
import {FaChevronLeft, FaChevronRight} from 'react-icons/fa'
import {FlexLayout} from '../flex/Flex';
import Card from '../forms/Card';
import {getCasinoDay} from '../util/CasinoDates';

const FilterInfo = styled(FlexLayout)`
  align-items: center;
  color: white;
  gap: 8px;
  transition: 0.5s all ease-in-out;
  @media screen and (max-width: 768px) {
    transition: 0.5s all ease-in-out;
    justify-content: center;
  }
`

const FilterButton = styled(FlexLayout)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: white;
  flex: 1;
  gap: 8px;
  transition: 0.5s all ease-in-out;
  @media screen and (max-width: 768px) {
    transition: 0.5s all ease-in-out;
    justify-content: center;
  }
`
const FilterContainer = styled(FlexLayout)`
  align-items: center;
  flex-wrap: wrap;
  transition: 0.5s all ease-in-out;
  user-select:none;
  @media screen and (max-width: 768px) {
    transition: 0.5s all ease-in-out;
    flex-direction: column;
  }
`
const CustomPanel = styled.div`
  width: 350px;
  height: 100%;
  background-color: #f0f0f0;
  position: absolute;
  left: -350px;
  transition: 0.5s all ease-in-out;
  display: ${({open}) => (open ? 'block' : 'none')};
`

const DateFilter2 = ({fechas, setFechas, modeDate = 'day', usePeriod, children, toggleSlidebar, showDay = true, showMonth = true, showYear = true, showWeek = true}) => {

  const [mode, setMode] = useState(modeDate)
  const inicio = usePeriod ? moment(fechas.period).format('LL') : moment(fechas.ini).format('LL')
  const final = usePeriod ? moment(fechas.period).format('LL') : (fechas.fin ? moment(fechas.fin).format('LL') : moment(fechas.ini).format('LL'))
  const mes = moment(fechas.ini).format('MMMM YYYY')
  const anho = moment(fechas.ini).format('YYYY')

  const changeMode = (modeSelected) => {
    setMode(modeSelected)
  }

  const updateParams = (ini, fin) => {
    setFechas({ini, fin})
  }

  const updatePeriodParams = (period) => {
    setFechas({period})
  }

  const hoy = () => {
    const fechaHoy = moment(new Date()).format('YYYY-MM-DD')
    if (usePeriod) {
      updatePeriodParams(fechaHoy)
    } else {
      updateParams(fechaHoy, fechaHoy)
    }
  }

  const previous = () => {
    return handleUpdate(false)
  }

  const next = () => {
    return handleUpdate(true)
  }

  const handleUpdate = (next) => {
    const amount = next ? 1 : -1;
    if (usePeriod) {
      const newPeriod = moment(fechas.period).add(amount, "days").format('YYYY-MM-DD');
      updatePeriodParams(newPeriod);
    } else {
      let modeLocal = mode;

      if (modeLocal === "week" || modeLocal === "weeks") { // Modificación aquí
        const ini = moment(fechas.ini).add(amount, "weeks").startOf('week').format('YYYY-MM-DD');
        const fin = moment(ini).endOf('week').format('YYYY-MM-DD');
        updateParams(ini, fin);
      } else if (modeLocal === "day" || modeLocal === "today") {
        const ini = moment(fechas.ini).add(amount, "days").format('YYYY-MM-DD');
        updateParams(ini, ini);
      } else if (modeLocal === "month") {
        const ini = moment(fechas.ini).add(amount, "month").startOf('month').format('YYYY-MM-DD');
        const fin = moment(ini).endOf('month').format('YYYY-MM-DD');
        updateParams(ini, fin);
      } else if (modeLocal === "year") {
        const ini = moment(fechas.ini).add(amount, "years").startOf('year').format('YYYY-MM-DD');
        const fin = moment(ini).endOf('year').format('YYYY-MM-DD');
        updateParams(ini, fin);
      }
    }
  }

  const day = () => {
    if (usePeriod) {
      updatePeriodParams(fechas.period)
    } else {
      updateParams(fechas.ini, fechas.ini)
    }
  }

  const week = () => {
    const y = moment(fechas.ini).year()
    const firstDayOfYear = moment(new Date(y, 0, 1)).format('YYYY-MM-DD');
    const day = fechas.ini === firstDayOfYear ? moment(fechas.ini).add('days', 1).format('YYYY-MM-DD') : fechas.ini
    const weeknumber = (moment(day).format('w'))
    const year = (moment(fechas.ini).format('YYYY'))

    const firstDayOfWeek = moment(new Date(year, 0, (weeknumber - 1) * 7 + 3)).format('YYYY-MM-DD');
    const lastDayOfWeek = moment(new Date(year, 0, (weeknumber - 1) * 7 + 9)).format('YYYY-MM-DD');

    updateParams(firstDayOfWeek, lastDayOfWeek)
  }

  const month = () => {
    const firstDayOfMonth = moment(moment(fechas.ini)).startOf('month').format('YYYY-MM-DD');
    const lastDayOfMonth = moment(moment(fechas.ini)).endOf('month').format('YYYY-MM-DD');

    updateParams(firstDayOfMonth, lastDayOfMonth)
  }

  const year = () => {
    const firstDayOfYear = moment(moment(fechas.ini)).startOf('year').format('YYYY-MM-DD');
    const lastDayOfYear = moment(moment(fechas.ini)).endOf('year').format('YYYY-MM-DD');

    updateParams(firstDayOfYear, lastDayOfYear)
  }

  const canNavigateNext = () => {
    if (usePeriod) {
      return moment(fechas.period).isBefore(moment().endOf('day'));
    }
    return moment(fechas.fin).isBefore(moment().endOf('day'));
  }

  useEffect(() => {
    const today = getCasinoDay();

    if ((mode === "day" || mode === "today") && usePeriod) {
      if (fechas && fechas.period === today) {
        setMode('today');
      }
    } else if ((mode === "day" || mode === "today") && !usePeriod) {
      if (fechas && fechas.ini === today) {
        setMode('today');
      }
    }
  }, [fechas, usePeriod, mode]);

  return (
    <Card padding style={{background: '#0A0A0A'}}>
      <FilterContainer>
        <FilterButton>
          <Button size={'s'} value={mode} variant={mode === "today" ? "primary" : "light"}
            onClick={() => {
              changeMode("today")
              hoy();
            }}
          >
            Hoy
          </Button>
          {showDay &&
            <Button size={'s'} value={mode} variant={mode === "day" ? "primary" : "light"}
                   onClick={() => {
                     day();
                     changeMode("day")
                   }}
          >
            Día
          </Button>
          }
          {showWeek &&
            <Button size={'s'} value={mode} variant={mode === "week" ? "primary" : "light"}
              onClick={() => {
                changeMode("week")
                week();
              }}
            >
              Semana
            </Button>
          }
          {showMonth &&
            <Button size={'s'} value={mode} variant={mode === "month" ? "primary" : "light"}
              onClick={() => {
                changeMode("month")
                month();
              }}
            >
              Mes
            </Button>
          }
          {showYear &&
            <Button size={'s'} value={mode} variant={mode === "year" ? "primary" : "light"}
              onClick={() => {
                changeMode("year")
                year();
              }}
            >
              Año
            </Button>
          }

          <Button size={'s'} value={mode} variant={mode === "custom" ? "primary" : "light"}
            onClick={() => {
              changeMode("custom");
              toggleSlidebar(true); // Abre el panel lateral
            }}
          >
            Personalizado
          </Button>
        </FilterButton>
        <FilterInfo>
          <FaChevronLeft
            size={20}
            onClick={previous}
            cursor='pointer'
          />
          <ToolbarTitle mode={mode} inicio={inicio} final={final} mes={mes} anho={anho} />
          <FaChevronRight
            size={20}
            onClick={canNavigateNext() ? next : null}
            cursor={canNavigateNext() ? 'pointer' : 'not-allowed'}
          />
        </FilterInfo>
        <CustomPanel open={mode === "custom"}>
          {children}
        </CustomPanel>
      </FilterContainer>
    </Card>
  );
};

export default DateFilter2;
