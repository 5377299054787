import React, {useContext, useEffect} from "react";
import axios from "axios";
import {Apis} from "../../../../util/Apis";
import AnforaPurgarForm from "./AnforaPurgarForm";
import {SiteContext} from "../../../common/context/SiteContext";

const AnforaPurgarSave = ({anforaId, setAction, setTitle}) => {

  const {salaId} = useContext(SiteContext)
  const validationTxt = 'purgar anfora'

  const save = (params) => {
    params.salaId = salaId
    params.anforaId = anforaId
    params.fechaHora = params.scheduledAtDate + 'T' + params.scheduledAtTime

    if (params.verification !== validationTxt) {
      //errorToast("Debe ingresar el texto de validación")
      throw Error("Debe ingresar el texto de validación")
    }

    //TODO: no enviar parametros scheduledAtDate y scheduledAtTime

    return axios.post(Apis.PC + '/coupon/purge-anfora', params)
  }

  useEffect(() => {
    setTitle("Purgar ánfora")
  }, [])

  return (
      <AnforaPurgarForm value={null} onSubmit={save} setAction={setAction}/>
  )
};

export default AnforaPurgarSave
