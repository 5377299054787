import React, {useCallback, useMemo, useContext} from 'react';
import Moment from "react-moment";
import MoneyColoredRound from "../../../../components/util/MoneyColored";
import Button from '../../../../components/forms/Button';
import useEditModal from "../../../../modals/useEditModal";
import EditarTransacction from "./EditarTransacction";
import GenericModal2 from "../../../../modals/GenericModal2";
import EditarApertura from "./EditarApertura";
import ReactTable from '../../../../components/util/ReactTable';
import {SiteContext} from '../../../common/context/SiteContext';
import useFetch2 from '../../../../hooks/useFetch2';
import {Apis} from '../../../../util/Apis';

const TableTransaction = ({data, loading, fetchData = () => {}, filterOnclick = () => {}}) => {
  const {salaId} = useContext(SiteContext)
  const {data: dataSala} = useFetch2(Apis.SALAS + '/salas-config/' + salaId, [])

  const {wrapper: wrapper2, editAction: editAction2} = useEditModal({
    editComponent: <EditarTransacction />,
    onRefresh: () => {setTimeout(() => {
      fetchData()
    },1000)}
  })

  const {wrapper: wrapper1, editAction} = useEditModal({
    editComponent: <EditarApertura />,
    onRefresh: () => {setTimeout(() => {
      fetchData()
    },1000)}
  })

  const NumberComponentMonto = useCallback(props => <MoneyColoredRound currencySymbol={dataSala.currencySymbol}>{props.renderValue()}</MoneyColoredRound>, [dataSala]);

  const nameType = (type) => {
    if (type === "OPENING"){
      type = "Apertura"
    } else if (type === "CLOSING"){
      type = "Cierre"
    } else if (type === "COIN_IN"){
      type = "Ingreso de fichas"
    } else if (type === "COIN_OUT"){
      type = "Salida de fichas"
    } else if (type === "CASH_IN"){
      type = "Incremento de fichas"
    } else if (type === "CASH_OUT"){
      type = "Devolucion de fichas"
    }
    return type
  }

  const onClickType = (value)=>{
    value.type === "OPENING" ?
              editAction({snapId: value.snapId,tableId: value.tableId}) :
              editAction2({transactionId: value.id,tableId: value.tableId})
  }

  const mesaValue = useCallback(props => <>{props.renderValue()} - {props.row.original.tableCode}</>, []);
  const clienteValue = useCallback(props =>
    props.row.original.type === "COIN_IN" || props.row.original.type === "COIN_OUT" ?
      <span>{props.renderValue() !== null ? props.renderValue() : "ANÓNIMO"}</span>
      :
      <span>--</span>
    , []);
  const dateValue = useCallback(props => props.renderValue() !== null ? <Moment format={'DD/MM HH:mm'}>{props.renderValue()}</Moment>: '--', []);
  const typeValue = useCallback(props => nameType(props.renderValue()), []);
  const actionValue = useCallback(props => 
    props.renderValue() === "COIN_IN" || props.renderValue() === "COIN_OUT" || props.renderValue() === "CLOSING"
      ? '':<Button size='xs' onClick={() => onClickType(props.row.original)}>Editar</Button>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  , []);
  const dealerName = useCallback(props => <>{props.renderValue()} - {props.row.original.dealerLastName}</>, []);

  const columns = useMemo(
    () => [
      {
        header: 'Mesa',
        accessorKey: 'tableName',
        meta: {
          headerClassName:'text-center',
          columnClassName:'text-center',
        },
        cell: mesaValue
      },
      {
        header: 'Cliente',
        accessorKey: 'customerName',
        meta: {
          headerClassName:'text-center',
          columnClassName:'text-center',
        },
        cell: clienteValue
      },
      {
        header:'Monto',
        accessorKey: 'amountCents',
        meta: {
          headerClassName:'text-center',
          columnClassName:'text-center',
        },
        cell: NumberComponentMonto
      },
      {
        header: 'Tipo',
        id: 'tipo',
        accessorFn: cell => `${cell.type}`,
        meta: {
          headerClassName:'text-center',
          columnClassName:'text-center',
        },
        cell: typeValue
      },
      {
        header: 'Fecha',
        accessorKey: 'createdAt',
        meta: {
          headerClassName:'text-center',
          columnClassName:'text-center',
        },
        cell: dateValue
      },
      {
        header: 'Dealer',
        accessorKey: 'dealerName',
        meta: {
          headerClassName:'text-center',
          columnClassName:'text-center',
        },
        cell: dealerName
      },
      {
        header: 'Acciones',
        accessorKey: 'type',
        enableGlobalFilter:false, // para deshabilitar el filter
        enableSorting:false, // para deshabilitar el sort
        meta: {
          headerClassName:'text-center',
          columnClassName:'text-center',
        },
        cell: actionValue
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ], [dataSala])

  return (
    <>
      <ReactTable
        columns={columns}
        data={data || []}
        filterOnclick={() => filterOnclick()}
        filterRefresh={() => fetchData()}
        noData='No existen resultados para el filtro seleccionado'
        pageSize={15}
      />
      <GenericModal2 {...wrapper1} />
      <GenericModal2 {...wrapper2} />
    </>
  );
};

export default TableTransaction;
