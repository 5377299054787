import {Apis} from "../../../../util/Apis";
import React, {useContext, useEffect} from "react";
import {SiteContext} from "../../../common/context/SiteContext";
import axios from "axios"
import ProfileForm from "./ProfileForm";


const NewProfile = ({info, setAction, setTitle}) => {

  const {salaId}=useContext(SiteContext)

  const save = (params) => {
    params.product = info.product
    return axios.post(Apis.USER + '/user-group', params);
  }

  useEffect(() => {
    setTitle("Nuevo Perfil")
  }, [])

  return (
    <ProfileForm value={{}} onSubmit={save} setAction={setAction}/>
  )

}

export default NewProfile
