import React, {useContext, useState } from "react";
import {Title} from "@wargostec/react-components";
import PageSection from "../../components/layout/PageSection";
import Card, { CardBody, CardHeader } from "../../components/forms/Card";
import {NumberRound} from "../../components/util/NumberUtil";
import useFetch2 from "../../hooks/useFetch2";
import {Apis} from "../../util/Apis";
import {SiteContext} from "../common/context/SiteContext"
import { FaCoins, FaUsers } from "react-icons/fa"
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { GiPayMoney, GiReceiveMoney } from "react-icons/gi"
import Button from "../../components/forms/Button"
import styled from "styled-components"
import {Link} from "react-router-dom"
import Table from "react-bootstrap/Table"
import { LoadingTableV2 } from "../../components/forms/Tables";
import Moment from "react-moment"
import moment from "moment/moment"
import Chart from 'react-apexcharts'
import { FlexLayout, GridLayout } from "../../components/flex/Flex";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const ItemDetails = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  span{
    font-size: 24px;
    font-weight: bold;
  }
`

const TgmHome = () => {

  const {salaId} = useContext(SiteContext)

  var initialDate = moment(new Date()).format('YYYY-MM-DD')
  if (new Date().getHours()<8) {   
    initialDate = moment((new Date()).setDate(new Date().getDate() - 1)).format('YYYY-MM-DD')
  }else{
    initialDate = moment(new Date()).format('YYYY-MM-DD')
  }

  const endDate = moment((new Date()).setDate(new Date().getDate() + 1)).format('YYYY-MM-DD')

  const fechasDefault = {ini: initialDate, fin: endDate}

  const [fechas, setFechas] = useState(fechasDefault)

  const defaultParams = { salaId: salaId};
  const [params,setParams] = useState(defaultParams);

  const defaultParams2 = {salaId: salaId, ini: fechas.ini, fin: fechas.fin}
  const [params2, setParams2] = useState(defaultParams2)

  const {data} = useFetch2(Apis.TGM + `/meter-online/realtime-summary`,[],params);

  const {data: data2} = useFetch2(Apis.PC + `/session/online-report`,[],params);

  const {data: data3, loading,error} = useFetch2(Apis.BLACK + `/customer-ingress`,[],params);
  
  const {data: data4} = useFetch2(Apis.PC + '/machine-played-history', [], params2);

  const area = {
    options: {
      chart: {
        type: 'line',
        stacked: false,
        height: 350,
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: true
        },
        toolbar: {
          autoSelected: 'zoom'
        }
        
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      markers: {
        size: 0,
      },
      xaxis: {
        categories: data4.map(item => item.fechaHora),
        tickAmount: data4.length /8,
        labels:{  
          formatter: (val) => { return moment(val).format('HH:mm') }
        }
      },
      yaxis: {
        labels: {
          formatter: (val) => {
            return val
          },
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        intersect: false,
        shared: true,
        onDatasetHover: {
          highlightDataSeries: true,
        },
        y: {
          formatter: function (val) {
            return  val + " clientes"
          }
        }
      }
    },
    series: [{
      name: 'Clientes',
      data: data4.map(logg => logg.logged),
    }, {
      name: 'Ocupación',
      data: data4.map(play => play.played),
    }]
  };

  return (
    <Container fluid>
      <PageSection>
          <Title text="Bienvenido al modulo TGM" type={'page'}/>
      </PageSection>

      <PageSection>
        <Row>
          <Col xs={12} md={12} >
            <Card padding>
              <Row>
                <Col xs={12} md={4} >

                  <FlexLayout column alignItems='center'>
                    <span>Producción</span>
                    <ItemDetails>
                      <span>S/ <NumberRound>{data.prod}</NumberRound></span>
                      <GiReceiveMoney fontSize={'24'} />
                    </ItemDetails>
                  </FlexLayout>
                </Col>
                <Col xs={12} md={4} >
                  <FlexLayout column alignItems='center'>
                    <span>Coin In</span>
                    <ItemDetails>
                      <span>S/ <NumberRound>{data.ci}</NumberRound></span>
                      <FaCoins fontSize={'24'} />
                    </ItemDetails>
                  </FlexLayout>
                </Col>
                <Col xs={12} md={4} >
                  <FlexLayout column alignItems='center'>
                    <OverlayTrigger placement={'left'} overlay={<Tooltip>Porcentaje de retorno</Tooltip>}>
                      <span>RTP</span>
                    </OverlayTrigger>
                    <ItemDetails>
                      <span><NumberRound>{data.rtp}</NumberRound>  %</span>
                      <GiPayMoney fontSize={'24'} />
                    </ItemDetails>
                  </FlexLayout>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col xs={12} md={12} style={{ marginBottom: '1rem' }}>
            <span style={{ fontSize: '20px', paddingRight: '16px' }}>Clientes en Línea</span>
            <Link to={'crm/online'}>
              <Button size={'s'} variant={'primary'}>Ver detalle</Button>
            </Link>
          </Col>

          <Col xs={12} md={4} >
            <Card padding>
              <FlexLayout column alignItems='center'>
                <span>Clientes jugando</span>
                <ItemDetails>
                  <span>{data2.total}</span>
                  <FaUsers fontSize={'24'} />
                </ItemDetails>
              </FlexLayout>
            </Card>
          </Col>

          <Col xs={12} md={4} >
            <Card padding>
              <FlexLayout column alignItems='center'>
                <span>Apuesta promedio</span>
                <ItemDetails>
                  <span>
                    {data2.apPromMachine === 'NaN' ? <>0</> : <NumberRound>{data2.apPromMachine}</NumberRound>}
                  </span>
                  <FaCoins fontSize={'24'} />
                </ItemDetails>
              </FlexLayout>
            </Card>
          </Col>

          <Col xs={12} md={4} >
            <Card padding>
              <FlexLayout column alignItems='center'>
                <span>Ocupación</span>
                <ItemDetails>
                  <span>{data2.ocupacion + " %"}</span>
                  <FaUsers fontSize={'24'} />
                </ItemDetails>
              </FlexLayout>
            </Card>
          </Col>

          <Col xs={12} md={6} >
            <Card>
              <CardHeader>
                <FlexLayout alignItems='center'>
                  <strong>Ultimos ingresos a la sala</strong>
                  <Link to={'crm/historial-ingresos'}>
                    <Button size={'s'} variant={'primary'}>Ver más</Button>
                  </Link>
                </FlexLayout>
              </CardHeader>
              <LoadingTableV2 loading={loading} error={error} data={data3}>
                <Table striped bordered hover >
                  <thead>
                    <tr>
                      <th className="text-center">Fecha</th>
                      <th className="text-left">Cliente</th>
                      <th className="text-right">Documento</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data3 && data3.slice(0, 10).map((item, i) =>
                      <tr key={i}>
                        <td style={{ verticalAlign: 'middle', textAlign: 'center', fontSize: '14px' }}>
                          <Moment format={'YYYY-MM-DD HH:mm'}>{item.createdAt}</Moment>
                        </td>
                        <td style={{ verticalAlign: 'middle', textAlign: 'left', textTransform: 'uppercase', fontSize: '14px' }}>
                          {item.firstName + ' ' + item.lastName1}
                        </td>
                        <td style={{ verticalAlign: 'middle', textAlign: 'right', textTransform: 'uppercase', fontSize: '14px' }}>
                          {item.document}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </LoadingTableV2>
            </Card>
          </Col>

          <Col xs={12} md={6} >
            <Card>
              <CardHeader>
                <FlexLayout alignItems='center'>
                  <strong>Ocupación de Clientes</strong>
                  <Link to={'crm/ocupación-por-hora'}>
                    <Button size={'s'} variant={'primary'}>Ver más</Button>
                  </Link>
                </FlexLayout>
              </CardHeader>
              <CardBody>
                <Chart
                  options={area.options}
                  series={area.series}
                  type="line"
                  height={350}
                />
              </CardBody>
            </Card>
          </Col>

        </Row>
      </PageSection>
    </Container>
  )
}

export default TgmHome;
