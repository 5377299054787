import React from 'react'
import {Select} from "../../../../components/forms/FormEl";

const GenderSelect = React.forwardRef((props, ref) => {

  return (
    <Select ref={ref} {...props}>
      <option value={'N'}>DESHABILITADO</option>
      <option value={'F'}>FEMENINO</option>
      <option value={'M'}>MASCULINO</option>
    </Select>
  )
})

export default GenderSelect
