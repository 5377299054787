import React from 'react';
import styled from "styled-components";
import { useState } from 'react';

const ImgStyled = styled.div`

.lightbox {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter:blur(4px);
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  opacity: 0;
  transition: opacity ease 0.4s;
}

.show {
  visibility: visible;
  opacity: 1;
}

.show_image {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius:16px;
  margin:16px;
}`;

export function ViewerImage({ zoom,alt, ...rest }) {
  const [click, setClick] = useState(false)

  const setFlag = () => {
    setClick(true)
  }

  const unsetFlag = () => {
    setClick(false)
  }

  if (!zoom) return <img {...rest} alt={alt} />
  else
    return (
      <ImgStyled>
        {click ? (
          <>
            <img {...rest} alt={alt} onClick={setFlag}/>
            <div onClick={unsetFlag} className='lightbox show relative'>
              <img src={rest.src} alt={alt} className='show_image'/>
            </div>
          </>
        ) : (
          <img {...rest} alt={alt} onClick={setFlag}/>
        )}
      </ImgStyled>
    )
};
