import React, {useCallback, useContext, useMemo} from 'react';
import useFetch2 from "../../../../../hooks/useFetch2";
import {Apis} from "../../../../../util/Apis";
import {SiteContext} from "../../../../common/context/SiteContext";
import {FlexLayout} from "../../../../../components/flex/Flex";
import Moment from "react-moment";
import ReactTable from "../../../../../components/util/ReactTable";
import AsyncBlock from "../../../../../components/forms/AsyncBlock";
import SegmentCircleV2 from "../../../../crm/pages/clientes/customers/SegmentCircleV2";
import {getGenericLink} from "../../../../../util";
import Card from "../../../../../components/forms/Card";

const ServerList = () => {

  const {salaId} = useContext(SiteContext)
  const defaultParams = {salaId: salaId}
  const {data, loading, error, fetchData} = useFetch2(Apis.TGM + '/pangolin-server', [], defaultParams);

  const createdValue = useCallback(props => <>{
    props.renderValue() ?
      <FlexLayout alignItems='center' justifyContent='center' gap='4px'>
        <Moment format={'YYYY-MM-DD hh:mm'}>{props.renderValue()}</Moment>
      </FlexLayout>
      :
      <span>---</span>
  }</>, []);

  const columns = useMemo(
    () => [
      {
        header: 'ID',
        accessorKey: 'id',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Servidor', accessorKey: 'name',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Pangolin IP', accessorKey: 'serverIp',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Public IP', accessorKey: 'publicIp',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Creado',
        accessorKey: 'createdAt',
        cell: createdValue,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Estado',
        accessorKey: 'online',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: (props) => <span>
          <FlexLayout justifyContent="center" gap="16px">
          <SegmentCircleV2 color={getGenericLink(props.row.original.online)}/>
        </FlexLayout>
        </span>
      },
    ]
    , []
  )

  return (
    <>
      <AsyncBlock loading={loading} error={error}>
        <Card padding>
          <h4 style={{fontWeight: '600'}}>Servidores</h4>
          <ReactTable
            columns={columns}
            data={(data && data) || []}
            loading={loading}
            pageSize={100}
            sortArray={[{id: 'id', asc: false}]}
            noData='No existen resultados'
          />
        </Card>
      </AsyncBlock>
    </>
  );
};

export default ServerList;
