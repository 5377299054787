import React, {createContext, useEffect, useMemo, useState} from 'react';
import {Apis} from "../../../util/Apis";
import {datadogRum} from "@datadog/browser-rum";
import useFetch2 from "../../../hooks/useFetch2";
import ErrorAuthPage from "../../login/ErrorAuthPage";

export const SiteContext = createContext({
  salaId: null,
  setSalaId: () => {
  },
  info: {},
  setInfo: () => {
  },
  cajaConfig: {},  
  setCajaConfig: () => {}
});

export const SiteProvider = (props) => {

  //TODO, sala y salaId podrian guardarse en un solo item de local storage, es necesario guardarlo al hacer refresh se pierde el contexto
  //TODO, moreInfo debe tener toda la data necesaria para guardarla en memoria, hacer query on refresh
  const [salaId, setSalaId] = useState(null)
  const [info, setInfo] = useState({})
  const [moreInfo, setMoreInfo] = useState({})
  const [config, setConfig] = useState({})
  const [cajaConfig, setCajaConfig] = useState({});

  const {loading, data, error} = useFetch2(Apis.USER + '/users/me?product=gaming', null)
  const {loading: loadingCaja, data: dataCaja} = useFetch2(Apis.CAJA + '/config-caja', null);  


  useEffect(() => {
    const salaId = localStorage.getItem('salaId')
    setSalaId(salaId)
    const sala = JSON.parse(localStorage.getItem('sala'))
    setInfo({sala: sala})
    const configCaja = JSON.parse(localStorage.getItem('configCaja'));
    setCajaConfig(configCaja || {})
  }, [])

  useMemo(() => {
    if (data?.user !== undefined && data?.user !== null) {
      setMoreInfo(data)
      datadogRum.setUser({
        //id: '1234',
        name: data.user.name
      })
    }
    if (data?.config !== undefined && data?.config !== null) {
      setConfig(data.config)
    }
    if (dataCaja !== undefined && dataCaja !== null) {
      setCajaConfig(dataCaja)
    }

  }, [data,dataCaja])

  useEffect(() => {
    if (cajaConfig) {
      localStorage.setItem('configCaja', JSON.stringify(cajaConfig));
    }
  }, [cajaConfig]);

  const renderChildren = () => {
    if (error && error.response?.status === 403) return <ErrorAuthPage/>
    if ((loading || !data) && !error) return <div>Cargando...</div>
    return props.children
  }

  return (
    <SiteContext.Provider value={{salaId, setSalaId, info, setInfo, moreInfo, config,cajaConfig,setCajaConfig}}>
      {
        renderChildren()
      }
    </SiteContext.Provider>
  );
}
