import React, { useContext, useState } from 'react'
import PageSection from "../../../../../components/layout/PageSection";
import { Title } from "@wargostec/react-components";
import Table from "react-bootstrap/Table";
import FormGroup from "../../../../../components/forms/FormGroup";
import Button, { ButtonBox } from "../../../../../components/forms/Button";
import SalaIdInput from "../../../../common/components/SalaIdInput";
import { Controller, useForm } from "react-hook-form";
import { DatePickerCustom } from "../../../../../components/forms/FormEl";
import Slidebar from "../../../../common/layout/Slidebar";
import { SiteContext } from "../../../../common/context/SiteContext";
import PageContainerFull from "../../../../../components/layout/PageContainerFull";
import { Apis } from "../../../../../util/Apis";
import NewCouponAwarded from "./NewCouponAwarded";
import Moment from "react-moment";
import Card from "../../../../../components/forms/Card";
import CustomerSearchInputV2 from "../../../../common/components/CustomerSearchInputV2";
import { AiOutlinePlus } from 'react-icons/ai';
import { BsFilter } from 'react-icons/bs';
import AsyncBlock from '../../../../../components/forms/AsyncBlock';
import EmptyState from '../../../../common/components/EmptyState';
import { MdLocalPlay } from "react-icons/md";
import useToggle from '../../../../../hooks/useToggle';
import useGet from '../../../../../hooks/useGet';

const CouponAwarded = () => {
  const { salaId } = useContext(SiteContext)
  let defaultParams = { salaId: salaId, enabled: true }
  const [params, setParams] = useState()

  const { register, control, handleSubmit } = useForm({ defaultValues: defaultParams });
  const { data, fetchData, loading, error } = useGet({
    url: Apis.PC + '/coupon-gift',
    params,
    initialValue: []
  })

  const { isOpen: slideOpen, toggler: togglerSlideOpen } = useToggle({})
  const { isOpen: show, toggler: togglerShow } = useToggle({
    onClose: async () => await fetchData(params)
  })

  const getData = (params) => {
    setParams(params)
    fetchData(params)
  }

  return (
    <PageContainerFull>
      <Slidebar visible={slideOpen} onHide={() => togglerSlideOpen()}>
        <div style={{ padding: '16px' }}>
          <SalaIdInput {...register("salaId")} />
          <FormGroup>
            <label>Cliente</label>
            <Controller name="customerId" control={control} render={({ field }) => <CustomerSearchInputV2 {...field} />} />
          </FormGroup>
          <FormGroup>
            <label>Desde</label>
            <DatePickerCustom {...register("ini")} />
          </FormGroup>

          <FormGroup>
            <label>Hasta</label>
            <DatePickerCustom {...register("fin")} />
          </FormGroup>

          <Button variant={'primary'} title='Buscar' onClick={handleSubmit(getData)} />
        </div>
      </Slidebar>

      <PageSection>
        <Title text="Cupones otorgados" type={'page'} />
        <ButtonBox>
          <Button variant='primary' onClick={() => togglerShow()}>
            <AiOutlinePlus />
            <span>Nuevo</span>
          </Button>
          <Button variant='primary' onClick={() => togglerSlideOpen()}>
            <BsFilter />
            <span>Filtrar</span>
          </Button>
        </ButtonBox>
      </PageSection>

      <PageSection>
        <AsyncBlock loading={loading} error={error}>
          {data?.length === 0 ?
            <Card padding>
              <EmptyState
                title={'No hay resultados para este filtro'}
                subtitle={'Puede realizar un nuevo registro en Nuevo o puede intentar con otro filtro.'}
                icon={<MdLocalPlay size={32} />}
              />
            </Card>
            :
            <Card>
              <Table striped hover responsive>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Cliente</th>
                    <th>Promoción</th>
                    <th>Cantidad</th>
                    <th>Máquina</th>
                    <th>Fecha de asignación</th>
                    <th>Motivo</th>
                    <th>Estado</th>
                    <th>Solicitado por</th>
                    <th>Autorizado por</th>
                  </tr>
                </thead>
                <tbody>
                  {data && data.map(item =>
                    <tr key={item.id}>
                      <td>{item.id}</td>
                      <td>{item.customerName}</td>
                      <td>{item.couponName}</td>
                      <td>{item.quantity}</td>
                      <td>{item.machine}</td>
                      <td>
                        <Moment format={'DD/MM HH:mm'}>{item.createdAt}</Moment>
                      </td>
                      <td>{item.comment}</td>
                      <td>{item.state}</td>
                      <td>{item.employee}</td>
                      <td>{item.resolvedName}</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Card>
          }
        </AsyncBlock>
      </PageSection>
      <NewCouponAwarded show={show} onHide={() => togglerShow()} />
    </PageContainerFull>
  )
}

export default CouponAwarded;
