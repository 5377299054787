import {useEffect, useState} from 'react';
import axios from 'axios';

// custom hook for performing GET request
const useFetch2 = (
  url,
  initialValue,
  params = {},
  auto = true
) => {
  const [data, setData] = useState(initialValue);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = async function () {
    try {
      setLoading(true);
      const response = await axios.get(url, {params: params});
      if (response.status === 200) {
        setData(response.data);
        setError(null);
      }
    } catch (error) {
      //throw error;
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (auto) fetchData();
  }, [url]);

  return {loading, data, fetchData, error};
};

export default useFetch2;

//* (Renzo B.) es mejor usar tanstack/react-query, ofrece opciones como useQuery y useMutation <-- IMPORANTE
//* (Renzo B.) otra opcion

export const useQuery = ({
                           url,
                           initialValue,
                           params = {},
                           auto = true,
                           onSuccess = () => {
                           },
                           onError = () => {
                           },
                         }) => {
  const [data, setData] = useState(initialValue);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = async function () {
    try {
      setLoading(true);
      const response = await axios.get(url, {params: params});
      if (response.status === 200) {
        setData(response.data);
        onSuccess(response.data);
        setError(null);
      }
    } catch (error) {
      //throw error;
      onError(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (auto) fetchData();
  }, [url]);

  return {loading, data, fetchData, error};
};
