import React, {useContext} from 'react';
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import useEditModal from "../../../../modals/useEditModal";
import axios from "axios";
import useDeleteModal from "../../../../modals/useDeleteModal";
import PageSection from "../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import Button, {ButtonBox} from "../../../../components/forms/Button";
import {FaPlus} from "react-icons/fa";
import Card from "../../../../components/forms/Card";
import Table from "react-bootstrap/Table";
import GenericModal2 from "../../../../modals/GenericModal2";
import DeleteGenericModal from "../../../../modals/DeleteGenericModal";
import PageContainer from "../../../../components/layout/PageContainer";
import DealerEdit from "./DealerEdit";
import DealerNew from "./DealerNew";
import {SiteContext} from "../../../common/context/SiteContext";

const DealerList = () => {

  const {salaId} = useContext(SiteContext)
  const defaultParams = {salaId}
  const {data, fetchData} = useFetch2(Apis.TABLES + '/dealer', [], defaultParams);

  const {wrapper: wrapper1, addAction, editAction} = useEditModal({
    editComponent: <DealerEdit/>,
    addComponent: <DealerNew/>,
    onRefresh: fetchData
  })

  const deleteElement = id => axios.delete(Apis.TABLES + '/dealer/' + id)

  const {
    wrapper: wrapper2,
    openDeleteModal
  } = useDeleteModal({
    text: '¿Desea eliminar este dealer?',
    action: deleteElement,
    onRefresh: fetchData
  })

  return (
    <PageContainer>
      <PageSection>
          <Title text="Dealer" type={'page'}/>
        <ButtonBox>
          <Button variant={'primary'} onClick={() => addAction()}>
            <FaPlus/> Nuevo
          </Button>
        </ButtonBox>
      </PageSection>

      <PageSection>
        <Card padding>
          <Table striped hover>
            <thead>
            <tr>
              <th>N°</th>
              <th>Nombres</th>
              <th>Apellidos</th>
              <th/>
            </tr>
            </thead>
            <tbody>
            {data && data.map((item, index) =>
              <tr key={item.id}>
                <td>{index + 1}</td>
                <td>{item.name}</td>
                <td>{item.lastName}</td>
                <td>
                  <ButtonBox>
                    <Button size={'s'} onClick={() => editAction(item.id)}>Editar</Button>
                    <Button size={'s'} onClick={() => openDeleteModal(item.id)} variant={'danger'}>Eliminar</Button>
                  </ButtonBox>
                </td>
              </tr>
            )}
            </tbody>
          </Table>
        </Card>

      </PageSection>

      <GenericModal2 {...wrapper1}/>
      <DeleteGenericModal {...wrapper2}/>
    </PageContainer>
  );
};

export default DealerList;
