import React, {useMemo} from "react";
import PageContainer from "../../../../../components/layout/PageContainer";
import PageSection from "../../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import {Apis} from "../../../../../util/Apis";
import useFetch2 from "../../../../../hooks/useFetch2";
import useEditModal from "../../../../../modals/useEditModal";
import GenericModal2 from "../../../../../modals/GenericModal2";
import AsyncBlock from "../../../../../components/forms/AsyncBlock";
import ReactTable from "../../../../../components/util/ReactTable";
import SerieNew from "./SerieNew";

const SerieList = () => {
  const querystring = window.location.search
  const query = new URLSearchParams(querystring)
  let event = query.get('event')
  let eventName = query.get('eventName')
  let defaultParams = { eventId: event}

  const {loading, data, fetchData, error} = useFetch2(Apis.BINGO + `/serie`, [], defaultParams);
  const {wrapper: wrapper1, addAction} = useEditModal({
    addComponent: <SerieNew/>,
    onRefresh: fetchData
  })

  const columns = useMemo(
    () => [
      {
        header: 'Nro. de serie',
        accessorKey: 'serie',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Cantidad',
        accessorKey: 'cant',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Disponibles',
        accessorKey: 'notAssigned',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      }
    ]
    , []
  )

  return (
    <PageContainer>
      <PageSection>

          <Title text={"Stock de cartones - " + eventName}/>

        <AsyncBlock loading={loading} error={error}>
          <ReactTable
            columns={columns}
            data={data || []}
            filterAdd={() => addAction({eventId: event})}
            customSearch
            noData='No existen resultados'
            pageSize={15}
          />
        </AsyncBlock>

      </PageSection>

      <GenericModal2 {...wrapper1}/>
    </PageContainer>
  )
}

export default SerieList
