import React, {useEffect, useState} from 'react';
import {Controller, useForm} from "react-hook-form";
import FormGroup from "../../../../../components/forms/FormGroup";
import Input, {Select} from "../../../../../components/forms/FormEl";
import {Apis} from '../../../../../util/Apis';
import CouponTypeSelectV2 from '../../../../common/components/selects/CouponTypeSelectV2';
import SwitchToggle from "../../../../../components/layout/SwitchToggle";
import {FileUploaderV2} from "../../../../common/components/fileuploader/FileUploaderV2";

const ProductCatalogForm = ({value, onSubmit, setAction}) => {
  const {register, handleSubmit, reset, control, setValue,getValues} = useForm()

  useEffect(() => {
    if (value) {
      if (Object.keys(value).length !== 0) {
        reset(value)
      }
    }
  }, [value]);

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit))
  }, [])

  const [selectedOption, setSelectedOption] = useState('points');

  useEffect(() => {
    if (getValues('couponTypeId') !== null) {
      setSelectedOption('coupons');
    }else{
      setSelectedOption('points');
    }
  }, [getValues('couponTypeId')]);
  
  const handleSelectChange = (e) => {
    const newOption = e.target.value;
    if (newOption === 'points') {
      setValue('couponTypeId', null);
    }
    setSelectedOption(newOption);
  }

  return (
    <React.Fragment>

      <Controller
        name="photoUrl"
        control={control}
        render={({field}) => <FileUploaderV2 folder={'foods'} {...field}
          assetsURL={`${process.env.REACT_APP_S3_WARGOS_ASSETS}`} baseURL={Apis.FILE} />}
      />

      <FormGroup>
        <label>Nombre del producto</label>
        <Input type='text' {...register("name")} />
      </FormGroup>

      <FormGroup>
        <label>Canjear por </label>
        <Select value={selectedOption} onChange={handleSelectChange}>
          <option key={1} value={'points'}>PUNTOS</option>
          <option key={2} value={'coupons'}>CUPONES</option>
        </Select>
      </FormGroup>

      {selectedOption === 'points' && (
        <FormGroup>
          <label>Puntos</label>
          <Input type="number" {...register("point")} />
        </FormGroup>
      )}

      {selectedOption === 'coupons' && <>
        <FormGroup>
          <label>Promoción</label>
          <Controller
            name="couponTypeId"
            control={control}
            render={({field}) => <CouponTypeSelectV2 {...field} />}
          />
        </FormGroup>
        <FormGroup>
          <label>Cupones</label>
          <Input type="number" {...register("point")} />
        </FormGroup>
      </>
      }

      <SwitchToggle
        name="automaticRedeem"
        defaultChecked={getValues('autoRedeemEnabled')}
        label="Canjear automaticamente"
        {...register("autoRedeemEnabled")}
      />

      <FormGroup>
        <label>Precio</label>
        <Input type="number" {...register("price")} />
      </FormGroup>

      <SwitchToggle
        name="cashback"
        defaultChecked={getValues('cashback')}
        label="Cashback"
        {...register("cashback")}
      />

    </React.Fragment>
  );
};

export default ProductCatalogForm;
