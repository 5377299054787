import React, {useEffect, useState} from "react";
import FormGroup from "../../../../../components/forms/FormGroup";
import {useFieldArray, useForm} from "react-hook-form";
import Input, {Select} from "../../../../../components/forms/FormEl";
import SwitchToggle from "../../../../../components/layout/SwitchToggle";
import SalaIdInput from "../../../../common/components/SalaIdInput";
import Card from "../../../../../components/forms/Card";
import Button, {ButtonBox} from "../../../../../components/forms/Button";
import {Col, Row} from "../../../../../components/flex/FlexLayout";
import RangeConfig from "./RangeConfig";
import EmptyState from "../../../../common/components/EmptyState";
import {RiCouponLine} from "react-icons/ri";
import {FaPlus} from "react-icons/fa";
import {useHistory} from "react-router";

const JackpotForm = ({value, onSubmit}) => {
  const history = useHistory()

  const [rangeArray, setRangeArray] = useState([])

  const {register, handleSubmit, reset, watch,control} = useForm({defaultValues:{ballRaise: true,amountRaise: true,reset:false}});

  const {fields, append, remove} = useFieldArray({
    control, name: "listRanges"
  });

  const watchAmountRaise = watch("jackpot.amountRaise")
  const watchBallRaise = watch("jackpot.ballRaise")

  useEffect(() => {
    if (value && Object.keys(value).length > 0) {
      reset(value)
    }
  }, [value])

  const preSubmit = (couponPromoForm) => {
    // couponPromoForm.daysOfWeek = dayOfWeekArray
    onSubmit(couponPromoForm)
  }

  const addRanges = () => {
    append({fromBall: '', toBall: '', percentage: ''});
  }

  return (
    <>
      <Card padding>
        <div>
          <SalaIdInput  {...register("jackpot.salaId")}/>

          <Row>
            <Col>
              <FormGroup>
                <label>Tipo de jackpot</label>
                <Select {...register("jackpot.form")}>
                  <option key={1} value={'Line'}>Linea</option>
                  <option key={2} value={'Blackout'}>Bingo</option>
                  <option key={3} value={'Mega'}>Mega</option>
                </Select>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <label>Monto base</label>
                <Input type='number' {...register("jackpot.baseAmount")}/>
              </FormGroup>
            </Col>
          </Row>

          <SwitchToggle
            id='jackpot.ballRaise'
            label={"Incrementa la bolita"}
            {...register("jackpot.ballRaise")}
          />
          {watchBallRaise ?
            <div>
              <Row>
                <Col>
                  <FormGroup>
                    <label>Gana desde bolita</label>
                    <Input type='number' {...register("jackpot.startBall")}/>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <label>Periodo de incremento</label>
                    <Select {...register("jackpot.raiseBy")}>
                      <option key={1} value={1}>Día</option>
                      <option key={2} value={2}>Partida</option>
                    </Select>
                  </FormGroup>
                </Col>
              </Row>
            </div>
            :
            <FormGroup>
              <label>Gana con bolita</label>
              <Input type='number' {...register("jackpot.winBall")}/>
            </FormGroup>
          }

          <SwitchToggle
            id="jackpot.reset"
            label={"Reiniciar al finalizar"}
            {...register("jackpot.reset")}
          />
          <SwitchToggle
            id="jackpot.amountRaise"
            label={"Incrementa premio"}
            {...register("jackpot.amountRaise")}
          />
        </div>

        {
          watchAmountRaise &&
          <div>
            {
              !(value && Object.keys(value).length > 0  && value.listRanges.length > 0) ?
                <Card padding>
                  <EmptyState
                    title={'Aún no hay rangos creados'}
                    subtitle={'Agregue rangos haciendo click en Nuevo.'}
                    icon={<RiCouponLine size={20}/>}
                  />
                  <div style={{display: 'flex', justifyContent:'center'}}>
                    <Button variant={'primary'} size={'s'} onClick={() => history.push(`/bingos/ranges`)}>
                      <FaPlus/> Nuevo
                    </Button>
                  </div>

                </Card>
                :
                <RangeConfig
                  fields={fields}
                  dayOfWeekArray={rangeArray}
                  setDayOfWeekArray={setRangeArray}
                  register={register}
                  remove={remove}
                  addRanges={addRanges}
                  modeDelete={false}
                />
            }
          </div>
        }
      </Card>

      <ButtonBox>
        <Button variant='primary' onClick={handleSubmit(preSubmit)}>Guardar</Button>
      </ButtonBox>

    </>
  )
}

export default JackpotForm
