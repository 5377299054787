import React, {useEffect} from 'react';
import {useForm} from "react-hook-form";
import axios from "axios";
import {Apis} from "../../../../util/Apis";

const AuditModal = ({id, setTitle, setAction}) => {

  const defaultParams = {period: id}
  const {handleSubmit} = useForm({defaultValues: defaultParams});

  const generate = (params) => {
    params.type = 'MANUAL'
    return axios.post(Apis.CAJA + '/venta-diaria/generate', params)
  }

  useEffect(() => {
    setTitle("Confirmación")
  }, [id])

  useEffect(() => {
    setAction(() => handleSubmit(generate))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <React.Fragment>
      <span>
        ¿Está seguro que desea cerrar la auditoría del periodo <b>{id}</b> ?
      </span>
    </React.Fragment>
  );
};

export default AuditModal;
