import React, {useEffect} from 'react';
import {Col, Form, Row} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {numberFormat} from "../../../../components/util/NumberUtil";

const ClosedFondoForm = ({value, onSubmit, setAction}) => {

  const {register, control, handleSubmit, reset, setValue} = useForm({});

  useEffect(() => {
    if (value) {
      reset(value)
    }
  }, [value])

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit))
  }, [])

  return (
    <Form>
      <Row>
        <Col>
          <label><strong>{"Desde -> " + (value && value.fondoName)}</strong></label>
        </Col>
      </Row>

      <Row>
        <Col>
          <label style={{marginTop:'10px' }}>Ingresos</label>
        </Col>
        <Col>
          <label style={{marginLeft: '150px'}}>S/. {value && numberFormat(value.input)}</label>
        </Col>
      </Row>
      <Row>
        <Col>
          <label>Salidas</label>
        </Col>
        <Col>
          <label style={{marginLeft: '150px', marginBottom:'10px'}}>S/. {value && numberFormat(value.output)}</label>
        </Col>
      </Row>
      <span>

    </span>
      <Row>
        <Col>
          <label><strong>Total</strong></label>
        </Col>
        <Col>
          <label style={{ marginLeft: '150px'}}><strong>S/. {value && numberFormat(value.input + value.output)}</strong></label>
        </Col>
      </Row>
    </Form>
  );
};

export default ClosedFondoForm;
