import React, {useState} from 'react'
import styled from 'styled-components'
import {motion} from 'framer-motion'
import Button from '../../../../components/forms/Button'
import {HiOutlineMap} from 'react-icons/hi2'
import {LuSettings2} from 'react-icons/lu'
import {TransformComponent, TransformWrapper} from 'react-zoom-pan-pinch'
import LayoutControls from '../../../crm/pages/clientes/LayoutControls'
import BGLayout from '../../../common/backgrounds/BGLayout'

const LayoutContainer = styled.div`
  width: 100%;
  height: ${props => props.setHeight || 'calc(100vh - 18rem)'};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: #fff;
  padding:8px;
  box-shadow: 0 0 25px 0 rgba(0,0,0,.05);
  transition: box-shadow .2s linear, border-color .2s linear, background-color .2s linear;
  transition: 0.5s all ease-in-out;
  position:relative;
  &.fullscreen {
    width: 100vw;
    height: 100vh;
    border-radius: 0;
    position:absolute;
    top:0;
    left:0;
    z-index:100;
    transition: 0.5s all ease-in-out;
  }

  @media screen and (max-width: 768px) {
    transition: 0.5s all ease-in-out;
  }
`
const LayoutOptions = styled.div`
  position: absolute;
  right: 12px;
  top: 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-end;
  z-index:1;
`

const LayoutFloors = styled.div`
  position: absolute;
  left: 12px;
  top: 12px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-end;
  z-index:1;
`

const AuditLayout = ({data, loading, setHeight, caption, setLegendVisible, maquinasSeleccionadas, setMaquinasSeleccionadas}) => {

  const [controlsVisible, setControlsVisible] = useState(true);
  const [selectedIdx, setSelectedIdx] = useState(1);

  const handleButtonClick = (idx) => {
    setSelectedIdx(idx);
  };

  const handleMaquinaSeleccionada = (item) => {
    const {confId, machine} = item.data;
    setMaquinasSeleccionadas(prev => {
      const isAlreadySelected = prev.some(selectedItem => selectedItem.confId === confId);
      if (isAlreadySelected) {
        return prev.filter(selectedItem => selectedItem.confId !== confId);
      } else {
        return [...prev, {confId, machine}];
      }
    });
  };

  const getFillColor = (item, maquinasSeleccionadas) => {
    if (item.data && maquinasSeleccionadas.some(selectedItem => selectedItem.confId === item.data.confId)) {
      return {color: '#eab308'};
    } else if (item.data === null) {
      return {color: '#f5f5f5'};
    } else if (item.data.collected) {
      return {color: '#22c55e', clickable: false};
    } else {
      return {color: '#d4d4d4', clickable: true};
    }
  };

  return (
    <TransformWrapper initialScale={1}>
      {({zoomIn, zoomOut, resetTransform}) => (
        <React.Fragment>
          <LayoutContainer setHeight={setHeight} >
            <LayoutFloors>
              {data?.frameDimensions?.map((item) => (
                <Button
                  key={item.id}
                  onClick={() => handleButtonClick(item.floorNumber)}
                  variant={selectedIdx === item.floorNumber ? 'dark' : 'light'}
                >
                  Piso {item.floorNumber}
                </Button>
              ))}
            </LayoutFloors>
            <LayoutOptions>
              <div style={{display: 'flex', flexDirection: 'row', gap: '8px'}}>
                <Button onClick={() => setLegendVisible(prev => !prev)}>
                  <HiOutlineMap />
                </Button>
                <Button onClick={() => setControlsVisible(prev => !prev)}>
                  <LuSettings2 />
                </Button>
              </div>
              <LayoutControls
                zoomIn={() => zoomIn()}
                zoomOut={() => zoomOut()}
                resetTransform={() => resetTransform()}
                controlsVisible={controlsVisible}
              />
              {caption}
            </LayoutOptions>

            {!loading ?
              <TransformComponent contentStyle={{width: '100%', height: '100%'}}>
                {data?.frameDimensions?.map(item => (
                  <React.Fragment key={item.id}>
                    {selectedIdx === item.floorNumber && (
                      <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '16px'}}>
                        <motion.svg xmlns="http://www.w3.org/2000/svg" viewBox={'0 0 ' + item.width + ' ' + item.height} width="100%" height="auto" preserveAspectRatio="xMidYMid meet">
                          <defs>
                            <style>{`
                            .cls-2 {font-size: 16px; fill: #fff; font-family: Arial; font-weight: 700; text-anchor: middle; alignment-baseline: central;}
                            .heatmap-filter {
                              filter: url('#heatmap');
                            }
                          `}</style>
                            <filter id="heatmap">
                              <feGaussianBlur in="SourceGraphic" stdDeviation="6" />
                            </filter>
                          </defs>
                          <motion.g id='maquinas'>
                            {data.positions.filter(pos => pos.floorNumber === item.floorNumber).map(item => {
                              const nombreCliente = '';
                              const fillColor = getFillColor(item, maquinasSeleccionadas);
                              return (
                                <motion.g key={item.id} style={{cursor: fillColor.clickable ? 'pointer' : 'default'}} onClick={fillColor.clickable ? () => item.data && handleMaquinaSeleccionada(item) : null} >
                                  <motion.circle
                                    id={item.positionId}
                                    cx={item.cx}
                                    cy={item.cy}
                                    r="15"
                                    fill={fillColor.color}
                                  />
                                  <motion.text id={`textm${item.positionId}`} x={item.cx} y={item.cy} className="cls-2">{nombreCliente}</motion.text>
                                </motion.g>
                              )
                            })}
                          </motion.g>
                          <BGLayout url={item.svgFileUrl} />
                        </motion.svg>
                      </div>
                    )}
                  </React.Fragment>
                ))}
              </TransformComponent>
              :
              <span>Cargando layout...</span>
            }
          </LayoutContainer>
        </React.Fragment>
      )}
    </TransformWrapper>
  )
}

export default AuditLayout