import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import PageSection from "../../../../../components/layout/PageSection";
import {Title, Button, Table, FormGroup} from "@wargostec/react-components";
import {FlexLayout} from "../../../../../components/flex/Flex";
import PageContainer from "../../../../../components/layout/PageContainer";
import {ButtonBox} from "../../../../../components/forms/Button";
import AsyncBlock from "../../../../../components/forms/AsyncBlock";
import Input from "../../../../../components/forms/FormEl";
import SwitchToggle from "../../../../../components/layout/SwitchToggle";
import useEditModal from "../../../../../modals/useEditModal";
import MoneyDenomEdit from "../../moneyDenom/MoneyDenomEdit";
import MoneyDenomNew from "../../moneyDenom/MoneyDenomNew";
import axios from "axios";
import {Apis} from "../../../../../util/Apis";
import useDeleteModal from "../../../../../modals/useDeleteModal";
import useFetch2 from "../../../../../hooks/useFetch2";
import GenericModal2 from "../../../../../modals/GenericModal2";
import DeleteGenericModal from "../../../../../modals/DeleteGenericModal";
import TextWithHelp from '../../../../../components/util/TextWithHelp';
import Card from '../../../../../components/forms/Card';
import {useForm} from 'react-hook-form';
import {errorToast, successToast} from '../../../../../components/layout/ToastStyles';
import {SiteContext} from '../../../../common/context/SiteContext';

const ConfigurationCaja = () => {
  const {setCajaConfig} = useContext(SiteContext);
  const { data, loading, fetchData, error } = useFetch2(Apis.CAJA + '/money-denom', null);
  const { data: dataConfig, fetchData: fetchDataConfig } = useFetch2(Apis.CAJA + '/config-caja', null);
  const { data: dataExchange, fetchData: fetchDataExchange } = useFetch2(Apis.CAJA + '/exchange-rate', null);

  const [exchangeData, setExchangeData] = useState(null);

  const {
    register: registerConfig,
    handleSubmit: handleSubmitConfig,
    reset: resetConfig
  } = useForm({
    defaultValues: dataConfig || {}
  });

  const {
    register: registerExchange,
    handleSubmit: handleSubmitExchange,
    reset: resetExchange
  } = useForm({
    defaultValues: dataExchange || {}
  });

  useEffect(() => {
    if (dataConfig) {
      resetConfig(dataConfig);
    }
  }, [dataConfig, resetConfig]);

  useEffect(() => {
    if (dataExchange && dataExchange.length > 0) {
      const initialData = dataExchange[0];
      resetExchange(initialData);
      setExchangeData(initialData);
    }
  }, [dataExchange, resetExchange]);

  const { wrapper: wrapper1, addAction, editAction } = useEditModal({
    editComponent: <MoneyDenomEdit />,
    addComponent: <MoneyDenomNew />,
    onRefresh: () => {
      fetchData()
    }
  });

  const deleteElement = (id) => axios.delete(Apis.CAJA + '/money-denom/' + id);
  const { wrapper: wrapper2, openDeleteModal } = useDeleteModal({
    text: '¿Deseas eliminar esta publicidad?',
    action: deleteElement,
    onRefresh: fetchData
  });

  const getType = (type) => {
    switch (type) {
      case 1:
        return 'Moneda';
      case 2:
        return 'Billete';
      case 3:
        return 'Ficha';
      default:
        return 'Moneda';
    }
  };

  const onSubmitConfig = async (formData) => {
    try {
      await axios.put(Apis.CAJA + '/config-caja', formData);
      successToast('Configuración actualizada con éxito');
      fetchDataConfig(); // Refresca los datos
      localStorage.setItem('configCaja', JSON.stringify(formData));
      setCajaConfig(formData);
    } catch (error) {
      console.error('Error al actualizar la configuración:', error);
      errorToast('Error al actualizar la configuración');
    }
  };

  const onSubmitExchange = async (formData) => {
    if (!dataExchange || dataExchange.length === 0) return;
    const exchangeRateId = dataExchange[0].id;
    try {
      await axios.put(`${Apis.CAJA}/exchange-rate/${exchangeRateId}`, formData);
      successToast('Tipo de cambio actualizado con éxito');
      fetchDataExchange(); // Refresca los datos
    } catch (error) {
      console.error('Error al actualizar el tipo de cambio:', error);
      errorToast('Error al actualizar el tipo de cambio');
    }
  };

  const type = useCallback(props => <>{getType(props.renderValue())}</>, []);
  const MoreOptions = useCallback(props =>
      <FlexLayout alignItems='center' justifyContent='center'>
        <ButtonBox>
          <Button variant='dark' size='xs' onClick={() => editAction(props.row.original.id)} text={"Editar"}/>
          <Button variant='light' size='xs' onClick={() => openDeleteModal(props.row.original.id, deleteElement)} text={"Elimnar"}/>
        </ButtonBox>

      </FlexLayout>,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

  const columns = useMemo(
    () => [
      {
        header: 'Denominacion',
        accessorKey: 'value',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Simbolo',
        accessorKey: 'currencySymbol',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Tipo',
        accessorKey: 'type',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: type
      },
      {
        header: 'Acciones',
        accessorKey: 'button',
        enableGlobalFilter: false,
        enableSorting: false,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: MoreOptions
      }
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , []
  )

  return (
    <PageContainer>
      <PageSection>
        <Title text="Configuración" type={'page'}/>
        <ButtonBox>
          <Button variant={"primary"} text={"Guardar"} onClick={handleSubmitConfig(onSubmitConfig)}/>
        </ButtonBox>
        <Card padding>
          <FlexLayout column alignItems='normal'>
            <strong style={{fontSize: '16px'}}>Principal</strong>
            <FlexLayout gap='16px'>
              <FormGroup>
                <TextWithHelp label={'Moneda'} help={'Moneda principal'}/>
                <Input name="currency" type={'text'} {...registerConfig('currency')} />
              </FormGroup>
              <FormGroup>
                <TextWithHelp label={'Simbolo'} help={'Simbolo de moneda principal'}/>
                <Input name="symbol" type={'text'} {...registerConfig('symbol')} />
              </FormGroup>
            </FlexLayout>
            <FlexLayout gap='16px'>
              <FormGroup>
                <TextWithHelp label={'Apertura de caja'}
                              help={'Al activar el switch, no sera necesario el supervisor'}/>
                <FlexLayout alignItems='center' style={{height: '40px'}}>
                  <SwitchToggle label={"Sin supervisor"} id={"logged"} {...registerConfig("onlyCashier")}
                                paddingBottom='0px'/>
                </FlexLayout>
              </FormGroup>
              <FormGroup>
                <TextWithHelp label={'Monto por denominación'}
                              help={'Al activar el switch, ingresaran el monto detallado'}/>
                <FlexLayout alignItems='center' style={{height: '40px'}}>
                  <SwitchToggle label={"Habilitado"} id={"amountByDenomEnabled"} {...registerConfig("amountByDenomEnabled")}
                                paddingBottom='0px'/>
                </FlexLayout>
              </FormGroup>
          </FlexLayout>
          </FlexLayout>
        </Card>
        <Card padding>
          <FlexLayout column alignItems='normal'>
            <strong style={{fontSize: '16px'}}>Pagos manuales</strong>
            <FlexLayout gap='16px'>
              <FormGroup>
                <TextWithHelp label={'Monto mínimo'} help={'los pagos automaticos se mostraran a partir de este monto'}/>
                <Input name="minimumHp" type={'text'} {...registerConfig('minimumHp')} />
              </FormGroup>
              <FormGroup>
                <TextWithHelp label={'Cliente opcional para montos menores a'} help={'El cliente será opcional para montos menores a este valor.'}/>
                <Input name="customerRequiredHp" type={'text'} {...registerConfig('customerRequiredHp')} />
              </FormGroup>
            </FlexLayout>
          </FlexLayout>
        </Card>
        <Card padding>
          <FlexLayout column alignItems='normal'>
            <strong style={{fontSize: '16px'}}>Tickets</strong>
            <FlexLayout gap='16px'>
              <FormGroup>
                <TextWithHelp label={'Venta de tickets'} help={'Ingrese los montos preferenciales separados por comas'}/>
                <Input name="amountsTi" type={'text'} {...registerConfig('amountsTi')}  />
              </FormGroup>
              <FormGroup>
                <TextWithHelp label={'Monto máximo'} help={'Ingrese el monto maximo para vender en caja'}/>
                <Input name="ticketVentaAmount" type={'number'} {...registerConfig('ticketVentaAmount')}  />
              </FormGroup>
            </FlexLayout>
          </FlexLayout>
        </Card>
        <FlexLayout gap='16px'>
          <Card padding style={{width:'50%'}}>
            <FlexLayout column>
              <strong style={{fontSize: '16px'}}>Tarjetas</strong>
              <FormGroup>
                <TextWithHelp label={'Banco y cliente opcional'}
                              help={'Al activar el switch, solo sera necesario registrar el monto'}/>
                <FlexLayout alignItems='center' style={{height: '40px'}}>
                  <SwitchToggle label={"Habilitado"} id={"logged"} {...registerConfig("genericModeCp")}
                                paddingBottom='0px'/>
                </FlexLayout>
              </FormGroup>
            </FlexLayout>
          </Card>
          <Card padding style={{width:'50%'}}>
            <FlexLayout column alignItems='normal'>
              <strong style={{fontSize: '16px'}}>Recaudo</strong>
              <FormGroup>
                <TextWithHelp label={'Monto por denominación'}
                              help={'Al activar el switch, ingresaran el monto detallado'}/>
                <FlexLayout alignItems='center' style={{height: '40px'}}>
                  <SwitchToggle label={"Habilitado"} id={"auditByDenomEnabled"} {...registerConfig("auditByDenomEnabled")}
                                paddingBottom='0px'/>
                </FlexLayout>
              </FormGroup>
            </FlexLayout>
          </Card>
        </FlexLayout>
        <Card padding>
          <FlexLayout column alignItems='normal'>
            <strong style={{fontSize: '16px'}}>Tipo de cambio</strong>
            <FlexLayout gap='16px'>
              <FormGroup>
                <label>Monto</label>
                <Input name="rate" type={'text'} {...registerExchange("rate")} />
              </FormGroup>
              <FormGroup>
                <label>Moneda</label>
                <Input name="fromCurrency" type={'text'} readOnly {...registerExchange("fromCurrency")} />
              </FormGroup>
            </FlexLayout>
          </FlexLayout>
          <Button variant={"primary"} text={"Guardar"} onClick={handleSubmitExchange(onSubmitExchange)} />
        </Card>
        <Card padding>
          <AsyncBlock loading={loading} error={error}>
            <strong style={{fontSize: '16px'}}>Denominación para caja</strong>
            <Table
              columns={columns}
              data={data || []}
              onAdd={() => addAction()}
              emptyListText='No existen resultados'
              pageSize={10}
              searchable
            />
          </AsyncBlock>
        </Card>
      </PageSection>
      <GenericModal2 {...wrapper1} />
      <DeleteGenericModal {...wrapper2} />
    </PageContainer>
  );
};

export default ConfigurationCaja;
