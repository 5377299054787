import React from "react";


function ToolbarTitle(props) {
  if (props.mode === "day" || props.mode === "today") {
    return <span style={{textAlign:'center'}}>{`${props.inicio.toUpperCase()}`}</span>
  }
  if (props.mode === "week") {
    return <span style={{textAlign:'center'}}>{`${props.inicio.toUpperCase()} - ${props.final.toUpperCase()}`}</span>
  }
  if (props.mode === "month") {
    return <span style={{textAlign:'center'}}>{`${props.mes.toUpperCase()}`}</span>
  }
  if (props.mode === "year") {
    return <span style={{textAlign:'center'}}>{`${props.anho.toUpperCase()}`}</span>
  }
  if (props.mode === "custom") {
    if (props.final && props.inicio !== props.final) {
      return <span style={{textAlign:'center'}}>{`${props.inicio.toUpperCase()} - ${props.final.toUpperCase()}`}</span>
    } else {
      return <span style={{textAlign:'center'}}>{`${props.inicio.toUpperCase()}`}</span>
    }
  }
}

export default ToolbarTitle
