import React from "react";
import styled from 'styled-components'

const SwitchContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  padding-bottom: ${props => props.paddingBottom || '8px'};
`;

const SwitchCheckbox = styled.input`
  width: 34px !important;
  height: 18px !important;
  margin-top: 0px !important;
  transition: 0.5s all ease-in-out;
  &:checked {
    transition: 0.5s all ease-in-out;
    background-color: #0A0A0A !important;
    border:transparent !important;
  }
  &:focus{
    border:transparent !important;
    outline: 0;
    box-shadow: none !important;
  }
`;

const SwitchToggle = React.forwardRef((props, ref) => {
  return (
    <SwitchContainer className="form-check form-switch" paddingBottom={props.paddingBottom}>
      <SwitchCheckbox type="checkbox" className="form-check-input" id={props.id} ref={ref} {...props}/>
      <label className="form-check-label" htmlFor={props.id}>
        {props.label}
      </label>
    </SwitchContainer>
  )
})

export default SwitchToggle
