import React from 'react'
import {Select} from "../../../../components/forms/FormEl";

const CouponTemplateSelect = React.forwardRef((props, ref) => {

  return (
    <Select ref={ref} {...props}>
      <option key={1} value={"1"}>M1</option>
      <option key={2} value={"2"}>M2</option>
      <option key={3} value={"3"}>M3</option>
      <option key={4} value={"4"}>M4</option>
      <option key={5} value={"5"}>M5</option>
      <option key={6} value={"L3"}>L3</option>
      <option key={7} value={"L4"}>L4</option>
      <option key={8} value={"L5"}>L5</option>
      <option key={9} value={"L6"}>L6</option>
      <option key={10} value={"L7"}>L7</option>
      <option key={11} value={"E1"}>E1</option>
      <option key={12} value={"D1"}>D1</option>
      <option key={13} value={"D3"}>D3</option>
      <option key={14} value={"D4"}>D4</option>
      <option key={15} value={"D5"}>D5</option>
      <option key={16} value={"D6"}>D6</option>
      <option key={17} value={"D7"}>D7</option>
      <option key={18} value={"D8"}>D8</option>
      <option key={19} value={"R"}>Personalizado</option>
    </Select>
  )
})

export default CouponTemplateSelect;
