import React, {useEffect} from "react";
import RuleEditForm from "./RuleEditForm";
import useFetch from "../../../../../hooks/useFetch";
import {Apis} from "../../../../../util/Apis";
import axios from "axios";

const RuleEdit = ({id, setAction, setTitle}) => {

  const {loading, data} = useFetch(Apis.PC + '/coupon-rule/' + id)

  const update = async (params) => {
    await axios.put(Apis.PC + '/coupon-rule/' + id + "/rule", params)
  }

  useEffect(() => {
    setTitle("Edición de Regla")
  }, [])

  return (
    <React.Fragment>
      {data && !loading &&
        <RuleEditForm value={data} onSubmit={update} setAction={setAction}/>
      }
    </React.Fragment>
  )
}

export default RuleEdit
