import React from 'react'
import {Select} from "../../../../components/forms/FormEl";
import {Apis} from "../../../../util/Apis";
import useFetch2 from "../../../../hooks/useFetch2";

const CountrySelect = React.forwardRef((props, ref) => {

  const {data, loading} = useFetch2(Apis.PC + '/api/combos/countries')

  return (
    <Select ref={ref} {...props}>
      <option value={173}>Perú</option>
      {data && data.map(item => <option key={item.id} value={item.id}>{item.name}</option>)}
    </Select>
  )
})

export default CountrySelect;
