import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useForm } from "react-hook-form";
import Moment from "react-moment";
import { Title } from "@wargostec/react-components";
import SalaIdInput from "../../../../common/components/SalaIdInput";
import FormGroup from "../../../../../components/forms/FormGroup";
import Input, { DatePickerCustom } from "../../../../../components/forms/FormEl";
import Slidebar from "../../../../common/layout/Slidebar";
import PageSection from "../../../../../components/layout/PageSection";
import { SiteContext } from "../../../../common/context/SiteContext";
import { Apis } from "../../../../../util/Apis";
import { serializeDownload } from "../../../../../components/util/SerializeParams";
import PageContainerFull from '../../../../../components/layout/PageContainerFull';
import AsyncBlock from '../../../../../components/forms/AsyncBlock';
import ReactTable from '../../../../../components/util/ReactTable';
import Button from '../../../../../components/forms/Button';
import useToggle from '../../../../../hooks/useToggle';
import useGet from '../../../../../hooks/useGet';

const CustomerIngress = () => {

  const { salaId } = useContext(SiteContext)
  const { isOpen: slideOpen, setIsOpen: setSlideOpen } = useToggle({})

  const [params, setParams] = useState({ salaId: salaId })

  const { register, handleSubmit } = useForm({ defaultValues: params });

  const { loading, data, fetchData, error } = useGet({
    url: Apis.BLACK + '/customer-ingress',
    params: params,
    initialValue: []
  })

  const getData = (params) => {
    setParams(params)
    fetchData(params)
  }

  const dateValue = useCallback(props => <Moment format={'DD/MM HH:mm'}>{props.renderValue()}</Moment>, []);

  const defaultValue = useCallback(props => props.renderValue() === '' ? '--' : props.renderValue(), []);

  const fullNameValue = useCallback(props => <>{props.row.original.firstName + ' ' + props.row.original.lastName1 + ' ' + props.row.original.lastName2}</>, []);

  const columns = useMemo(
    () => [
      {
        header: 'Fecha',
        accessorKey: 'createdAt',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: dateValue
      },
      {
        header: 'Documento',
        accessorKey: 'document',
        enableSorting: false, // para deshabilitar el sort
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },

      },
      {
        header: 'Cliente',
        accessorKey: 'firstName',
        cell: fullNameValue
      },
      {
        header: 'Nivel',
        accessorKey: 'nivel',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: '--'
      },
      {
        header: 'Fecha nacimiento',
        accessorKey: 'birthday',
        enableSorting: false, // para deshabilitar el sort
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Edad',
        accessorKey: 'age',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Departamento',
        accessorKey: 'departamento',
        cell: defaultValue
      },
      {
        header: 'Provincia',
        accessorKey: 'provincia',
        cell: defaultValue
      },
      {
        header: 'Distrito',
        accessorKey: 'distrito',
        cell: defaultValue
      }
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , []
  )

  const download = () => {
    const link = Apis.BLACK + `/customer-ingress/report.xlsx?${serializeDownload(params)}`;
    window.location.href = link;
  }

  return (
    <PageContainerFull>
      <Slidebar visible={slideOpen} onHide={() => setSlideOpen(false)}>
        <div style={{ padding: '16px' }}>
          <SalaIdInput {...register("salaId")} />
          <FormGroup>
            <label>DNI</label>
            <Input type='text' name='document' {...register("document")} />
          </FormGroup>
          <FormGroup>
            <label>Desde</label>
            <DatePickerCustom {...register("ini")} />
          </FormGroup>

          <FormGroup>
            <label>Hasta</label>
            <DatePickerCustom {...register("fin")} />
          </FormGroup>

          <Button variant={'primary'} title='Buscar' onClick={handleSubmit(getData)} />
        </div>
      </Slidebar>

      <PageSection>
        <Title text="Ingresos a sala" type={'page'} />
        <AsyncBlock loading={loading} error={error}>
          <ReactTable
            columns={columns}
            data={data || []}
            filterDownload={download}
            filterOnclick={() => setSlideOpen(!slideOpen)}
            pageSize={15}
          />
        </AsyncBlock>
      </PageSection>
    </PageContainerFull>
  );
};

export default CustomerIngress;
