import React, {useEffect, useState} from "react";
import Form from "react-bootstrap/Form";
import FormGroup from "../../../../components/forms/FormGroup";
import {Controller, useForm} from "react-hook-form";
import {Col, Row} from "../../../../components/flex/FlexLayout";
import SwitchToggle from '../../../../components/layout/SwitchToggle';
import {Apis} from "../../../../util/Apis";
import {FileUploaderV2} from "../../../common/components/fileuploader/FileUploaderV2";

const PublicidadForm = ({value, onSubmit, setAction}) => {

  const {register, handleSubmit, reset, control, setValue} = useForm({defaultValues: {advanced: false}});
  const [advanced, setAdvanced] = useState(false)

  useEffect(() => {
    if (value) {
      if (Object.keys(value).length !== 0) {
        reset(value)
      }
    }
  }, [value]);

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit))
  })

  const showAdvanced = () => {
    setValue("advanced", !advanced)
    setAdvanced(!advanced)
  }

  return (
    <Form>
      <Controller name="url" control={control} render={({field}) =>
        <FileUploaderV2
          folder={'ads'}
          {...field}
          baseURL={Apis.FILE}
          assetsURL={process.env.REACT_APP_S3_WARGOS_ASSETS}/>}/>
      <FormGroup>
        <label style={{paddingTop: '20px'}}>Activado</label><br/>
        <SwitchToggle
          {...register("enabled")} id={"enabled"}
        />
      </FormGroup>

      <span style={{color: 'skyblue', cursor: 'pointer'}} onClick={showAdvanced}>Opciones avanzadas</span>
      {
        advanced &&
        <div>
          <FormGroup>
            <Row>
              <Col>
                <Form.Label>Fecha Inicio</Form.Label>
                <Form.Control type="date"  {...register("fechaInicio")}/>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col>
                <Form.Label>Fecha Fin</Form.Label>
                <Form.Control type="date"  {...register("fechaFin")}/>
              </Col>
            </Row>
          </FormGroup>
        </div>
      }
    </Form>
  );
};

export default PublicidadForm;
