import {Title} from '@wargostec/react-components'
import React, {useMemo} from 'react'
import {getCasinoDay} from "../../../../components/util/CasinoDates";
import useFetch2 from "../../../../hooks/useFetch2";
import ReactTable from "../../../../components/util/ReactTable";
import AsyncBlock from "../../../../components/forms/AsyncBlock";

const MachineMeters = ({confId}) => {

  let defaultParams = {ini: getCasinoDay(), fin: getCasinoDay(), confId: confId}
  const {data, loading, fetchData, error} = useFetch2(`https://sanmartin-487526.wargos.dev/xbluereports/meter-online/extra-info`, [], defaultParams);
  const columns = useMemo(
    () => [
      {
        header: 'Contador',
        accessorKey: 'meterName',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        },
      },
      {
        header: 'Valor',
        accessorKey: 'meterValue',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        },
      },
    ]
    , []
  )


  return (
    <>
      <Title text="Historial de Contadores" type='form' />
      <AsyncBlock loading={loading} error={error}>
        <ReactTable
          columns={columns}
          data={(data && data.meters) || []}
          customSearch
          noData='No existen resultados'
          pageSize={15}
        />
      </AsyncBlock>
    </>
  )
}

export default MachineMeters
