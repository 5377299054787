import React from "react";
import Table from "react-bootstrap/Table";
import useFetch2 from "../../../../../hooks/useFetch2";
import {Apis} from "../../../../../util/Apis";
import {LoadingTableV2} from "../../../../../components/forms/Tables";
import useSortableData, {ColumnSortable, getClassNamesFor} from "../../../../../hooks/useSortableData";

//deprecated
//no manejaremos mas esta vista, sera reemplazada por los grupos configurables
const ModelList = () => {

  const {loading, data, error} = useFetch2(Apis.TGM + '/slot-model', []);
  const {items, requestSort, sortConfig} = useSortableData(data);

  return (
    <div>
      <LoadingTableV2 loading={loading} error={error} data={data}>
        <Table striped hover>
          <thead>
            <tr>
              <th>
                <ColumnSortable type="button" onClick={() => requestSort('id')}
                  className={getClassNamesFor('id', sortConfig)}>
                  Id
                </ColumnSortable>
              </th>
              <th>
                <ColumnSortable type="button" onClick={() => requestSort('name')}
                  className={getClassNamesFor('name', sortConfig)}>
                  Nombre
                </ColumnSortable>
              </th>
              <th>
                Tipo
              </th>
              <th>
                Fabricante
              </th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) =>
              <tr>
                <td>{item.id}</td>
                <td>{item.name}</td>
                <td>{item.machineType?.name}</td>
                <td>{item.mark?.shortName}</td>
              </tr>
            )}
          </tbody>
        </Table>
      </LoadingTableV2>
    </div>
  )
}

export default ModelList;
