import React, { useState } from "react";
import MainDashboard from "../../components/layout/MainDashboard";
import AdminRoutes from "./AdminRoutes";
import DropdownContainer from "../../components/layout/DropdownContainer";
import DropdownMenu from "../../components/layout/DropdownMenu";
import DropdownMenuItem from "../../components/layout/DropdownMenuItem";
import * as FaIcons from "react-icons/fa";

const AdminMain = () => {
  const baseUrl = '/admin'

  const [isOpen, setIsOpen] = useState(false)
  const toggleMobile = () => {
    setIsOpen(!isOpen)
  }

  return (
    <MainDashboard
      brand={'ADMIN'}
      isOpen={isOpen}
      toggleMobile={toggleMobile}
      items={
        <DropdownContainer>
          {/*<DropdownMenu title={'Admin'} icon={<FaIcons.FaKey />}>*/}
          {/*  /!**/}
          {/*    oculto hasta que se revise el funcionamiento*/}
          {/*   <DropdownMenuItem title={'Actividades'} link={baseUrl + '/activity'} onClick={toggleMobile} /> *!/*/}
          {/*</DropdownMenu>*/}

          <DropdownMenuItem style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}
                            title={'Usuarios'}
                            link={baseUrl + '/users'}/>
          <DropdownMenuItem style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}
                            title={'Permisos'}
                            link={baseUrl + '/permissions'}/>
          <DropdownMenuItem style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}
                            title={'Facturas'}
                            link={baseUrl + '/facturas'}/>
          <DropdownMenuItem style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}
                            title={'Compañias'}
                            link={baseUrl + '/companies'}/>
          <DropdownMenuItem style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}
                            title={'Establecimientos'}
                            link={baseUrl + '/locations'}/>

        </DropdownContainer>

      }
    >
      <AdminRoutes />
    </MainDashboard>
  )
}

export default AdminMain;
