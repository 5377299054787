import React, {useContext} from 'react';
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import axios from "axios";
import FoodProductForm from "./FoodProductForm";
import {SiteContext} from "../../../common/context/SiteContext";
import {useHistory, useParams} from "react-router";
import PageSection from "../../../../components/layout/PageSection";
import {PageBackLabel} from "../../../../components/layout/PageBackLabel";
import {Title} from "@wargostec/react-components";
import AsyncBlock from "../../../../components/forms/AsyncBlock";
import PageContainer from "../../../../components/layout/PageContainer";
import {errorToast, successToast} from "../../../../components/layout/ToastStyles";

const FoodProductEdit = () => {

  const {salaId} = useContext(SiteContext)
  const {id} = useParams()
  const history = useHistory()
  const {data, loading, fetchData, error} = useFetch2(Apis.FOOD + '/food/' + id)

  const update = async (params) => {
    params.salaId = salaId
    params.id = id
    await axios.put(Apis.FOOD + '/food/' + id, params)
      .then(res => {
        successToast()
        history.push('/crm/products')
      }).catch(err => {
        errorToast()
      })
  }

  return (
    <PageContainer>
      <PageSection>
        <PageBackLabel text='Productos' onClick={() => history.push('/crm/products')}/>
          <Title text="Editar producto"/>
      </PageSection>

      <PageSection>
        <AsyncBlock loading={loading} error={error}>
          <FoodProductForm value={data} onSubmit={update} mode={id} fetchData={fetchData}/>
        </AsyncBlock>
      </PageSection>
    </PageContainer>
  );
};

export default FoodProductEdit;
