import React, {useContext, useEffect, useState} from "react";
import {SiteContext} from "../../../common/context/SiteContext";
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import {useForm} from "react-hook-form";
import Slidebar from "../../../common/layout/Slidebar";
import SalaIdInput from "../../../common/components/SalaIdInput";
import Button, {ButtonBox} from "../../../../components/forms/Button";
import PageSection from "../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import Table from "react-bootstrap/Table";
import Moment from "react-moment";
import {numberFormat} from "../../../../components/util/NumberUtil";
import Card from "../../../../components/forms/Card";
import AsyncBlock from "../../../../components/forms/AsyncBlock";
import useToggle from "../../../../hooks/useToggle";
import PageContainer from "../../../../components/layout/PageContainer";
import CardOverview, {CardOverviewContainer} from "../../../../components/layout/CardOverview";
import {FaSortAmountUpAlt} from "react-icons/fa";
import {HiOutlineBanknotes, HiOutlineTicket} from "react-icons/hi2";
import PeriodAndFondoSelect from "./PeriodAndFondoSelect";
import EmptyState from "../../../common/components/EmptyState";
import {TbFileSad} from "react-icons/tb";
import {getCasinoDay} from "../../../../components/util/CasinoDates";

const CTicketReport = () => {

  const queryString = window.location.search
  const query = new URLSearchParams(queryString)
  let fondoIdParams = query.get('fondoId') || ""
  let periodParams = query.get('period') || getCasinoDay();

  const {salaId} = useContext(SiteContext)
  const {isOpen: slideOpen, toggler} = useToggle({});
  const [params, setParams] = useState({
    salaId: salaId,
    fondoId: fondoIdParams,
    period: periodParams,
  });

  const {register, handleSubmit} = useForm({
    defaultValues: params,
  });
  const determineApiEndpoint = (fondoId) => {
    return fondoId === "" 
      ? Apis.CAJA + '/operation-ticket/report/by-period' 
      : Apis.CAJA + '/operation-ticket/report/by-fondo';
  };

  const {loading, data, fetchData, error} = useFetch2(determineApiEndpoint(params.fondoId), null, params, false);

  useEffect(() => {
    const query = new URLSearchParams(params).toString();
    const newUrl = `${window.location.pathname}?${query}`;
    window.history.pushState(null, "", newUrl);
    fetchData();
  }, [params]);

  const handleFondoIdChange = (fondoId) => {
    setParams((prevParams) => ({...prevParams, fondoId: fondoId}));
  };

  const onSubmit = () => {
    fetchData();
  };

  return (
    <PageContainer>
      <Slidebar visible={slideOpen} onHide={toggler}>
        <div style={{padding: '16px'}}>
          <SalaIdInput {...register("salaId")} />
          <PeriodAndFondoSelect
            onFondoChange={handleFondoIdChange}
            period={params.period}
            setPeriod={(newPeriod) => setParams((prevParams) => ({...prevParams, period: newPeriod}))}
            {...register("fondoId")} />
          <Button variant={'primary'} title='Buscar' onClick={handleSubmit(onSubmit)} />
        </div>
      </Slidebar>

      <PageSection>
        <Title text="Canje de tickets" type={'page'} />
        <ButtonBox>
          <Button variant={'primary'} title={'Filtrar'} onClick={toggler} />
        </ButtonBox>
        <AsyncBlock loading={loading} error={error}>
          {data === null ?
            <Card padding>
              <EmptyState
                title={'Canje de tickets'}
                subtitle={'Haga click en FILTRAR para realizar una busqueda'}
                icon={<HiOutlineTicket size={32} />}
              />
            </Card>
            :
            <>
              {data?.data?.length === 0 ?
                <Card padding>
                  <EmptyState
                    title={'Sin resultados'}
                    subtitle={'Haga click en FILTRAR para realizar una nueva busqueda'}
                    icon={<TbFileSad size={32} />}
                  />
                </Card>
                :
                <>
                  <CardOverviewContainer gridColumns='repeat(2, 1fr)'>
                    <CardOverview
                      title={data?.quantity ?? 0}
                      subtitle='Operaciones'
                      icon={<FaSortAmountUpAlt size={20} />}
                    />
                    <CardOverview
                      title={'S/ ' + numberFormat(data?.totalAmount)}
                      subtitle='Total'
                      icon={<HiOutlineBanknotes size={20} />}
                    />
                  </CardOverviewContainer>
                  <Card>
                    <Table striped hover responsive>
                      <thead>
                        <tr>
                          <th className="text-center">Estado</th>
                          <th>Código Ticket</th>
                          <th className="text-right">Monto</th>
                          <th className="text-center">Emitido en</th>
                          <th className="text-center">Fecha de emisión</th>
                          <th className="text-center">Canjeado en</th>
                          <th className="text-center">Fecha de canje</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.data && data?.data.map(item =>
                          <tr key={item.id}>
                            <td className="text-center">CANJEADO</td>
                            <td>{item.validNumber}</td>
                            <td className="text-right">{'S/ ' + numberFormat(item.amount)}</td>
                            <td className="text-center">{item.machine}</td>
                            <td className="text-center"><Moment format={'YYYY-MM-DD hh:mm a'}>{item.emittedAt}</Moment></td>
                            <td className="text-center">{item.fondoName}</td>
                            <td className="text-center"><Moment format={'YYYY-MM-DD hh:mm a'}>{item.createdAt}</Moment></td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </Card>
                </>
              }
            </>
          }
        </AsyncBlock>

      </PageSection>
    </PageContainer>
  )
}

export default CTicketReport;
