import React, {useContext} from "react";
import {useHistory, useParams} from "react-router";
import {SiteContext} from "../../../common/context/SiteContext";
import axios from "axios";
import {Apis} from "../../../../util/Apis";
import LudopatiaForm from "./LudopatiaForm";
import useFetch2 from "../../../../hooks/useFetch2";


const LudopatiaEdit = () => {
  const {salaId} = useContext(SiteContext)
  const history = useHistory()
  const {id} = useParams()

  const {data} = useFetch2(Apis.BLACK + '/ban-list-members/' + id)

  const onEdit = (params) => {
    //TODO, tmp
    params.salaId = salaId
    params.imgUrl = null
    axios.put(Apis.BLACK + '/ban-list-members/' + id, params).then(res => {
      history.push('/tgm/ludopatia/gestion-listas')
    }).catch(err => {
      console.log(err);
    })
  }
  return (
    <React.Fragment>
      <LudopatiaForm value={data} onSubmit={onEdit}/>
    </React.Fragment>
  )
}

export default LudopatiaEdit
