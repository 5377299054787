import React, {useCallback, useContext, useMemo} from "react";
import PageContainer from "../../../../../components/layout/PageContainer";
import PageSection from "../../../../../components/layout/PageSection";
import Button from "../../../../../components/forms/Button";
import DeleteGenericModal from "../../../../../modals/DeleteGenericModal";
import {SiteContext} from "../../../../common/context/SiteContext";
import {Apis} from "../../../../../util/Apis";
import useDeleteModal from "../../../../../modals/useDeleteModal";
import axios from "axios";
import useFetch2 from "../../../../../hooks/useFetch2";
import {useHistory} from "react-router";
import {FlexLayout} from "../../../../../components/flex/Flex";
import ReactTable from "../../../../../components/util/ReactTable";
import AsyncBlock from "../../../../../components/forms/AsyncBlock";
import {RiFileExcel2Fill} from "react-icons/ri";
import {Dropdown} from "react-bootstrap";
import {FaSortNumericDown} from "react-icons/fa";
import {MdOutlineBallot} from "react-icons/md";
import {BiTrophy} from "react-icons/bi";
import {Title} from "@wargostec/react-components";

const EventList = () => {

  const history = useHistory()

  const baseUrl = '/bingos'
  const {salaId} = useContext(SiteContext)
  const {data, fetchData, loading, error} = useFetch2(Apis.BINGO + `/event?salaId=${salaId}`, []);

  const deleteElement = id => axios.delete(Apis.BINGO + '/event/' + id)

  const {
    wrapper: wrapper2,
    openDeleteModal
  } = useDeleteModal({
    text: '¿Desea eliminar este evento de bingo?',
    action: deleteElement,
    onRefresh: fetchData
  })

  const cardsAction = (id,name) => {
    history.push('/bingos/stock?event='+id+'&eventName='+name)
  }

  const getPrizeType = (dinamic) =>{
    switch (dinamic) {
      case 1:
        return 'Fijo';
      case 2:
        return 'Variable';
      default:
        return '';
    }
  }

  const modoCell = useCallback(props => <>{
    <FlexLayout alignItems='center' justifyContent='center' gap='4px'>
      <span>{props.renderValue() ? 'Virtual' : 'Manual'}</span>
    </FlexLayout>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }</>, []);

  const typeCell = useCallback(props => <>{
    <FlexLayout alignItems='center' justifyContent='flex-end' gap='4px'>
      <span>{props.renderValue() + ' bolillas'}</span>
    </FlexLayout>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }</>, []);

  const prizeTypeCell = useCallback(props => <>{
    <FlexLayout alignItems='center' justifyContent='center' gap='4px'>
      <span>{getPrizeType(props.renderValue())}</span>
    </FlexLayout>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }</>, []);

  const download = (eventId) => {
    const link = Apis.BINGO + `/sorteo/report.xlsx?eventId=${eventId}`;
    window.location.href = link;
  }

  const actionsValue = useCallback(props => <>{
    <FlexLayout justifyContent='center'>
      <Button variant='primary' size='s' onClick={() => history.push(`${baseUrl}/edit-event/${props.renderValue()}`)}>Editar</Button>
      <Button variant='danger' size='s' onClick={() => openDeleteModal(props.renderValue())}>Eliminar</Button>
      <Dropdown>
        <Dropdown.Toggle variant="light" id="dropdown-custom-1" size='sm'>
          Más
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => cardsAction(props.renderValue(),props.row.original.name)}>
              <MdOutlineBallot style={{marginRight:'4px'}} />
              <span>Cartones</span>
          </Dropdown.Item>
          
          <Dropdown.Item 
            onClick={() => history.push(`${baseUrl}/sorteo-jackpots/` + props.renderValue())}>
              <BiTrophy style={{marginRight:'4px'}} />
              <span>Jackpots</span>
          </Dropdown.Item>
          <Dropdown.Item 
            onClick={() => history.push(`${baseUrl}/sales-profit`)}>
            <FaSortNumericDown style={{marginRight:'4px'}} />
            <span>Porcentajes</span>
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => download(props.renderValue())}>
              <RiFileExcel2Fill style={{marginRight:'4px'}} />
              <span>Partidas</span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </FlexLayout>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }</>, []);

  const columns = useMemo(
    () => [
      {
        header: 'Bingo',
        accessorKey: 'name',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Modo',
        accessorKey: 'automatic',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: modoCell
      },
      {
        header: 'Bolillas',
        accessorKey: 'type',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
        cell: typeCell
      },
      {
        header: 'Tipo premio',
        accessorKey: 'dinamic',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: prizeTypeCell
      },

      {
        header: 'Acciones',
        accessorKey: 'id',
        enableGlobalFilter:false,
        enableSorting:false,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: actionsValue
      }
    ]
    , []
  )

  return (
    <PageContainer>
      <PageSection>
          <Title text="Lista de eventos de bingo" type={'page'}/>

        <AsyncBlock loading={loading} error={error}>
          <ReactTable
            columns={columns}
            data={data || []}
            filterAdd={() => history.push(`${baseUrl}/new-event`)}
            customSearch
            noData='No existen resultados'
            pageSize={20}
          />
        </AsyncBlock>
      </PageSection>
      <DeleteGenericModal {...wrapper2}/>

    </PageContainer>
  )
};

export default EventList;
