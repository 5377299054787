import React, {useContext} from 'react';
import {useHistory} from "react-router";
import {SiteContext} from "../../../common/context/SiteContext";
import axios from "axios";
import {Apis} from "../../../../util/Apis";
import {errorToast, successToast} from "../../../../components/layout/ToastStyles";
import PageSection from "../../../../components/layout/PageSection";
import {PageBackLabel} from "../../../../components/layout/PageBackLabel";
import {Title} from "@wargostec/react-components";
import PageContainer from "../../../../components/layout/PageContainer";
import ProgressiveForm from "./ProgressiveForm";

const ProgressiveNew = () => {

  const history = useHistory()
  const {salaId} = useContext(SiteContext)

  const create = async (params) => {
    params.salaId = salaId
    params.enabled = true
    await axios.post(Apis.TGM + '/progressive', params)
      .then(res => {
        successToast()
        history.push('/crm/progressive')
      }).catch(err => {
        errorToast()
      })
  }

  return (
    <PageContainer>
      <PageSection>
        <PageBackLabel text='Regresar' onClick={() => history.push('/crm/progressive')}/>
          <Title text="Nuevo progresivo"/>
      </PageSection>

      <PageSection>
        <ProgressiveForm value={{}} onSubmit={create} mode={false}/>
      </PageSection>
    </PageContainer>
  );
};

export default ProgressiveNew;
