import React, {useContext, useEffect, useState} from "react";
import Card from "../../../../../components/forms/Card";
import FormGroup, {FormBlockTitle} from "../../../../../components/forms/FormGroup";
import {Controller, useForm} from "react-hook-form";
import Input, {InputStyled, Select, SelectV2} from "../../../../../components/forms/FormEl";
import ZoneSelect from "../../../../common/components/selects/ZoneSelect";
import DenomSelect from "../../../../common/components/selects/DenomSelect";
import SalaHiddenInput from "../../../../common/components/SalaHiddenInput";
import Button from "../../../../../components/forms/Button";
import ReactLoading from 'react-loading';
import {ActionContext} from "../../../../common/context/ActionContextWrapper";
import SwitchToggle from "../../../../../components/layout/SwitchToggle";
import QRCode from "qrcode"
import useConfirmModal from "../../../../../modals/useConfirmModal";
import ConfirmModal from "../../../../../modals/ConfirmModal";
import SlotGroupSelectV2 from "../../../../common/components/selects/SlotGroupSelectV2";
import PangolinSelect from "../../../../common/components/selects/PangolinSelect";
import GenericModal2 from "../../../../../modals/GenericModal2";
import useEditModal from "../../../../../modals/useEditModal";
import MachineMove from "./MachineMove";
import {useHistory} from "react-router";
import OwnerSelect from "../../../../common/components/selects/OwnerSelect";
import axios from "axios";
import {Apis} from "../../../../../util/Apis";
import useDeleteModal from "../../../../../modals/useDeleteModal";
import DeleteGenericModal from "../../../../../modals/DeleteGenericModal";
import {Tooltip} from "@wargostec/react-components";

const MachineForm = ({value, onSubmit, loading}) => {

  const {setAction} = useContext(ActionContext)
  const [qrText, setQrText] = useState(value.qrCode ? value.qrCode : null)
  const [src, setSrc] = useState()

  const {register, handleSubmit, reset, formState, control} = useForm({
    mode: "onChange"
  });

  const history = useHistory()

  const {wrapper: wrapper1, editAction} = useEditModal({
    editComponent: <MachineMove/>,
    onRefresh: () => history.push('/tgm/machines')
  })

  const removePangolin = id => axios.post(Apis.TGM + '/machines/' + id + '/remove-pangolin')

  const {wrapper: wrapper3, openDeleteModal} = useDeleteModal({
    text: 'Al remover el equipo, la maquina dejara de reportar contadores y tickets.',
    action: removePangolin,
    onRefresh: () => history.push('/tgm/machines')
  })

  const {isDirty} = formState;

  useEffect(() => {
    if (value) {
      reset(value)
    }
  }, [value])

  useEffect(() => {
    if (isDirty) {
      setAction(() => handleSubmit(onSubmit))
    }
  }, [isDirty])

  useEffect(() => {
    if (qrText) {
      QRCode.toDataURL(qrText).then((setSrc))
      /*setQrText(null)*/
    }
  }, [qrText])

  const setearText = () => {
    console.log(Math.floor(Math.random() * 10000000000))
    setQrText(Math.floor(Math.random() * 10000000000).toString())
  }

  const {wrapper: wrapper2, openConfirmModal} = useConfirmModal({text: '¿Desea generar otro código QR?'})

  return (
    <div>
      <Card padding>
        {/*<FormBlockTitle>General : {'es : ' + JSON.stringify(formState, null, 2) + ' ---'}</FormBlockTitle>*/}
        <FormBlockTitle>General</FormBlockTitle>
        <SalaHiddenInput {...register("date")}/>
        <FormGroup>
          <SwitchToggle label={"Habilitado"} {...register("enabled")} id={"enabled"}/>
        </FormGroup>
        <FormGroup>
          <label>Nombre</label>
          <Input {...register("machine")}/>
        </FormGroup>

        <FormGroup>
          <label>Pangolin (SMIB)</label>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <div style={{width: '100%'}}>
              <Controller
                name="blueSn"
                control={control}
                render={({field}) => <PangolinSelect {...field} />}
              />
            </div>
            <div>
              <Button style={{marginLeft: '10px'}} variant={'danger'} title='Remover' size={'xs'}
                      onClick={() => openDeleteModal(value.id)}/>

            </div>
          </div>
        </FormGroup>

        <FormGroup>
          <label>Fabricante y modelo</label>
          <Controller
            name="groupId"
            control={control}
            render={({field}) => <SlotGroupSelectV2 {...field} />}
          />
        </FormGroup>

        <FormGroup>
          <label>Moneda</label>
          <SelectV2 {...register('currency')} options={
            [
              {id: 'PEN', name: 'PEN'},
              {id: 'USD', name: 'USD'},
              {id: 'EUR', name: 'EUR'},
              {id: 'COP', name: 'COP'},
              {id: 'GTQ', name: 'GTQ'},
            ]
          }/>
        </FormGroup>

        <FormGroup>
          <label>Juego</label>
          <Input {...register("gameName")}/>
        </FormGroup>

        <FormGroup>
          <label>Serie</label>
          <Input {...register("serialNumber")}/>
        </FormGroup>

        <FormGroup>
          <label>Lector de tarjetas</label>
          <Input {...register("cardReaderSn")}/>
        </FormGroup>

        <FormGroup>
          <label>Zona</label>
          <ZoneSelect {...register("zoneId")}/>
        </FormGroup>

        <FormGroup>
          <label>Propietario</label>
          <Controller
            name="ownerId"
            control={control}
            render={({field}) => <OwnerSelect {...field} />}
          />
        </FormGroup>

        <FormGroup>
          <label>Denominacion</label>
          <DenomSelect {...register("den")}/>
        </FormGroup>

        <FormGroup>
          <label>Posición</label>
          <Input {...register("positionId")}/>
        </FormGroup>

        <FormGroup>
          <label>RTP</label>
          <Input {...register("rtp")}/>
        </FormGroup>

        <FormGroup>
          <label>Reg. MINCETUR</label>
          <Input {...register("numReg")}/>
        </FormGroup>
      </Card>
      <Card padding>
        <FormBlockTitle>Código QR</FormBlockTitle>
        <FormGroup>
          <Button variant={"success"} title={"Generar QR"} onClick={() => openConfirmModal(setearText)}/>
          {
            qrText &&
            <>
              <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
                <img src={src} style={{height: "200px", width: "200px"}}/>
              </div>
              <div style={{width: "20%", display: "flex", justifyContent: "center", marginLeft: "40%"}}>
                <Input {...register("qrCode")} value={qrText} style={{textAlign: "center", fontSize: "18px"}}/>
              </div>
            </>
          }
        </FormGroup>

        <FormGroup>
          <label>ID Online</label>
          <InputStyled>
            <input disabled={true} {...register("confId")}/>
          </InputStyled>
        </FormGroup>
      </Card>

      <Card padding>
        <Tooltip text="Máquinas que manejan monedas" direction={'top'}>
          <FormBlockTitle>Fichas</FormBlockTitle>
        </Tooltip>
        <FormGroup>
          <SwitchToggle label={"Habilitado"} {...register("coinEnabled")} id={"coinEnabled"}/>
        </FormGroup>
      </Card>

      <Card padding>
        <FormBlockTitle>Ticket</FormBlockTitle>
        <FormGroup>
          <SwitchToggle label={"Habilitado"} {...register("ticketEnabled")} id={"ticketEnabled"}/>
        </FormGroup>
        <FormGroup>
          <label>texto 1</label>
          <Input {...register("ticketAddress1")}/>
        </FormGroup>
        <FormGroup>
          <label>texto 2</label>
          <Input {...register("ticketAddress2")}/>
        </FormGroup>
      </Card>

      <Card padding>
        <FormBlockTitle>Tarjeta de fidelizacion</FormBlockTitle>
        <FormGroup>
          <SwitchToggle label={"Habilitado"} {...register("cardEnabled")} id={"cardEnabled"}/>
        </FormGroup>
        <FormGroup>
          <label>Protocolo</label>
          <Select {...register("cardProtocol")}>
            <option key={1} value={1}>V1</option>
            <option key={2} value={2}>V2</option>
          </Select>
        </FormGroup>
      </Card>

      <Card padding>
        <FormBlockTitle>AFT</FormBlockTitle>
        <FormGroup>
          <SwitchToggle label={"Habilitado"} {...register("aftEnabled")} id={"aftEnabled"}/>
        </FormGroup>
        <FormGroup>
          <label>N° Asset</label>
          <Input {...register("assetNumber")}/>
        </FormGroup>
      </Card>

      <Card padding>
        <FormBlockTitle>Sistema online</FormBlockTitle>

        <FormGroup>
          <label>Nombre</label>
          <InputStyled>
            <input {...register("machineOnline")} disabled={true}/>
          </InputStyled>
        </FormGroup>

        <FormGroup>
          <label>Fabricante</label>
          <InputStyled>
            <input {...register("brand")} disabled={true}/>
          </InputStyled>
        </FormGroup>

        <FormGroup>
          <label>Modelo</label>
          <InputStyled>
            <input {...register("model")} disabled={true}/>
          </InputStyled>
        </FormGroup>

        {/*<FormGroup>*/}
        {/*  <label>Denominacion</label>*/}
        {/*  <InputStyled>*/}
        {/*    <input {...register("denOnline")} disabled={true}/>*/}
        {/*  </InputStyled>*/}
        {/*</FormGroup>*/}

        {/*<FormGroup>*/}
        {/*  <label>SMIB</label>*/}
        {/*  <Input {...register("mid")} disabled/>*/}
        {/*</FormGroup>*/}

        <FormGroup>
          <label>Protocolo</label>
          <Select {...register("protocol")}>
            <option key={1} value={1}>SAS6</option>
            <option key={2} value={2}>MK45</option>
            <option key={3} value={3}>SAS3</option>
            <option key={3} value={4}>XSERIES</option>
          </Select>
        </FormGroup>

        <FormGroup>
          <label>Log Level</label>
          <Select {...register("logLevel")}>
            <option key={1} value={0}>panic</option>
            <option key={2} value={2}>error</option>
            <option key={3} value={3}>warning</option>
            <option key={4} value={4}>info</option>
            <option key={5} value={5}>debug</option>
            <option key={6} value={6}>trace</option>
          </Select>
        </FormGroup>

      </Card>

      <div style={{display: "flex", justifyContent: "space-between"}}>
        <Button variant={'primary'} onClick={handleSubmit(onSubmit)} disabled={loading}>
          {loading ?
            <ReactLoading type={'spin'} height={'16px'} width={'16px'}/>
            :
            <>Guardar</>
          }
        </Button>
        <Button variant={'ligth'} title='Dar de baja' onClick={() => editAction(value.id)}/>
      </div>
      <GenericModal2 {...wrapper1}/>
      <ConfirmModal {...wrapper2}/>
      <DeleteGenericModal {...wrapper3} />
    </div>
  )
}

export default MachineForm

