import React, {useContext, useState} from "react";
import {Title} from "@wargostec/react-components";
import {Controller, useForm} from "react-hook-form";
import {useHistory} from "react-router";
import Table from "react-bootstrap/Table";
import styled from "styled-components";
import PageContainerFull from "../../../../components/layout/PageContainerFull";
import PageSection from "../../../../components/layout/PageSection";
import Card from "../../../../components/forms/Card";
import FormGroup from "../../../../components/forms/FormGroup";
import Button from "../../../../components/forms/Button";
import {Apis} from "../../../../util/Apis";
import {LoadingTable} from "../../../../components/forms/Tables";
import {SiteContext} from "../../../common/context/SiteContext";
import {PageBackLabel} from "../../../../components/layout/PageBackLabel";
import {errorToast, successToast} from "../../../../components/layout/ToastStyles";
import FileUploader from "../../../common/components/FileUploader";
import CouponTypeSelectV2 from "../../../common/components/selects/CouponTypeSelectV2";
import {FlexLayout} from "../../../../components/flex/Flex";
import useToggle from "../../../../hooks/useToggle";
import useGet from "../../../../hooks/useGet";
import {usePost} from "../../../../hooks/usePost";

const FlexBreakLine = styled.div`
    flex-basis: 100%;
    width: 0;
    margin-bottom: 20px;
`;

const UploadMassiveCoupons = () => {
  const baseUrl = '/crm'
  const history = useHistory();
  const {salaId} = useContext(SiteContext)

  const {control, getValues, watch} = useForm()

  const [coupons, setCoupons] = useState(null);

  const {isOpen: loading, setIsOpen: setLoading} = useToggle({})
  const {isOpen: validFile, setIsOpen: setValidFile} = useToggle({})

  const watchImgUrl = watch("imgUrl")

  const {fetchData} = useGet({
    url: `${Apis.PC}/coupon-batch/preview`,
    auto: false,
    onSuccess: (res) => {
      setCoupons(res);
      setValidFile(true);
    },
    onError: () => {
      errorToast("Consulte el formato del archivo, de ser correcto intente en unos minutos")
      setValidFile(false)
    }
  })

  const {mutate} = usePost({
    url: `${Apis.PC}/coupon-batch`,
    onSuccess: () => {
      setLoading(false)
      successToast("Carga de cupones subida correctamente", 3000)
      history.push(`${baseUrl}/upload-coupons-list`)
    },
    onError: (error) => {
      setLoading(false)
      errorToast(error.response.data.message, 6000)
    }
  })

  const prevAction = async () => {
    setValidFile(false)
    const fileUrl = getValues('imgUrl')
    const params = {fileName: fileUrl}
    await fetchData(params)
  }

  const saveCoupons = async () => {
    setLoading(true)
    const anforaId2 = getValues('anforaId')

    const formBody = {
      fileName: getValues('imgUrl'),
      salaId: salaId,
      couponTypeId: getValues('couponTypeId'),
      anforaId: anforaId2 === 0 ? null : anforaId2,
      optionMode: getValues('optionMode')
    }

    await mutate(formBody)
  }

  return (
    <PageContainerFull>
      <PageSection>
        <PageBackLabel text='Lista de cargas' onClick={() => history.push('/crm/upload-coupons-list')}/>
        <Title text="Nueva carga"/>
      </PageSection>

      <PageSection>
        <Card padding>
          <FlexLayout column gap='16px'>
            <label>Seleccione un archivo en formato excel:</label>
            <Controller name="imgUrl" control={control}
                        render={({field}) => <FileUploader folder={'coupons'} {...field} />}/>
            {watchImgUrl &&
              <FlexLayout gap='16px' alignItems='center'>
                <Button variant={'primary'} title={'Previsualizar'} onClick={prevAction}/>
                <span>
                  Se mostrará una lista referencial de los cupones contenidos en el archivo
                </span>
              </FlexLayout>
            }
          </FlexLayout>
        </Card>
        {
          coupons &&
          (validFile ?
              <>
                <Card padding>
                  <React.Fragment>
                    <LoadingTable formBlockStyle={{maxHeight: "600px", overflowX: "auto"}}
                                  text="El documento no presenta cupones o es un formato inválido" loading={false}
                                  data={coupons} tableData={
                      <Table striped hover>
                        <thead>
                        <tr>
                          <th>ID</th>
                          <th>Documento</th>
                          <th>Nombres</th>
                          <th>Apellidos</th>
                          <th>Cantidad</th>
                        </tr>
                        </thead>
                        <tbody>
                        {coupons && coupons.map((item, index) =>
                          <tr key={index}>
                            <td>{item.customerId}</td>
                            <td>{item.document}</td>
                            <td>{item.firstName}</td>
                            <td>{item.lastName}</td>
                            <td>{item.coupon}</td>
                          </tr>
                        )}
                        </tbody>
                      </Table>
                    }/>
                  </React.Fragment>
                </Card>

                <Card padding>
                  <FormGroup style={{display: "flex", flexWrap: "wrap", justifyContent: "flex-end", marginTop: "40px"}}>
                    <h1 style={{fontSize: "20px", color: "#0d87ef", marginRight: "20px"}}>
                      (*) Para finalizar, seleccione el tipo de cupón y el ánfora
                    </h1>

                    <FlexBreakLine/>
                    <span style={{marginRight: "200px"}}>SELECCIONE TIPO DE CUPÓN:</span>
                    <FlexBreakLine/>
                    <Controller
                      name="couponTypeId"
                      control={control}
                      render={({field}) =>
                        <div style={{minWidth: "300px", maxWidth: "400px", marginRight: "20px"}}>
                          <CouponTypeSelectV2 {...field} />
                        </div>
                      }
                    />
                    <FlexBreakLine/>
                    <span style={{marginRight: "280px"}}>SELECCIONE TIPO:</span>
                    <FlexBreakLine/>
                    <Button variant={'primary'}
                            disabled={loading}
                            onClick={() => saveCoupons()}>
                      {loading ? 'Cargando' : 'Cargar cupones'}
                    </Button>
                  </FormGroup>
                </Card>
              </>
              :
              <Card padding>
                <FormGroup>
                  <h1 style={{color: "red"}}>
                    Se ha subido un archivo incorrecto, revise la extensión o el contenido.
                  </h1>
                </FormGroup>
              </Card>
          )
        }
      </PageSection>
    </PageContainerFull>
  )
}

export default UploadMassiveCoupons
