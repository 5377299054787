import styled from "styled-components";
import React from "react";
import {Form} from "react-bootstrap";
import {HiOutlineChevronDown} from "react-icons/hi2";

const InputStyled = styled.div`
  width: 100%;
  display: inline-block;
  font-size: 1rem;
  position: relative;
  

  input, select {
    background-color: #fff;
    background-image: none;
    border-radius: 6px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    color: #566074;
    display: inline-block;
    font-size: inherit;
    height: 40px;
    line-height: 40px;
    outline: 0;
    padding: 0 12px;
    transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &:hover {
      border-color: #c0c4cc;
    }

    &:focus {
      //komercia
      //border-color: #4429b4;
      border: 2px solid #068eef;
      outline: 0;
      //background-color: #fff;
    }

    &:disabled {
      background: #EBEBE4;
    }

  }

  textarea {
    background-color: #fff;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    color: #566074;
    display: inline-block;
    font-size: inherit;
    height: 200px;
    //line-height: 40px;
    outline: 0;
    padding: 0 12px;
    transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
    width: 100%;

    &:hover {
      border-color: #c0c4cc;
    }

    &:focus {
      //komercia
      //border-color: #4429b4;
      border: 2px solid #068eef;
      outline: 0;
      //background-color: #fff;
    }

    &:disabled {
      background: #EBEBE4;
    }
  }

  input:read-only {
    background: #EBEBE4;
  }
`
const RadioStyled = styled(Form.Check)`
  display:flex;
  align-items:center;
  gap:8px;
  flex: none;
  flex-grow: 0;
  margin-bottom:0.25rem;
  cursor:pointer;
  label{
    font-weight: normal;
    margin-bottom:0;
    cursor:pointer;
  }
  input{
    height: 20px;
    width: 20px;
    margin-top:0;
    cursor:pointer;
  }
`

const FileStyled = styled.input`
  color: transparent;
  &::-webkit-file-upload-button {
    visibility: hidden;
  }
  &::before {
    content: 'Suba un archivo en formato PDF';
    color: #121212;
    display: inline-block;
    border-radius: 8px;
    padding: 16px 32px;
    outline: none;
    white-space: nowrap;
    user-select: none;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='black' stroke-width='2' stroke-dasharray='8%2c16' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
  }
  &:hover::before {
    border-color: black;
  }
  &:active {
    outline: 0;
  }
  &:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9); 
  }
`

const CustomArrow = styled(HiOutlineChevronDown)`
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
`;

const Input = React.forwardRef((props, ref) => {
  return (
    <InputStyled>
      <input ref={ref} {...props} />
    </InputStyled>
  )
})

const TextArea = React.forwardRef((props, ref) => {
  return (
    <InputStyled>
      <textarea ref={ref} {...props} />
    </InputStyled>
  )
})

const DatePickerCustom = React.forwardRef((props, ref) => {
  return (
    <InputStyled>
      <input type='date' ref={ref} {...props} />
    </InputStyled>
  )
})

const Select = React.forwardRef((props, ref) => {
  return (
    <InputStyled style={props.selectStyle}>
      <select ref={ref} {...props}>
        {props.children}
      </select>
      <CustomArrow size={16} />
    </InputStyled>
  )
})

const SelectV2 = React.forwardRef((props, ref) => {
  return (
    <InputStyled style={props.selectStyle}>
      <select ref={ref} {...props}>
        {props.options &&
          props.options.map(item => <option key={item.id} value={item.id}>{item.name}</option>)}
      </select>
      <CustomArrow size={16} />
    </InputStyled>
  )
})

export const RadioCustom = React.forwardRef((props, ref) => {
  return (
    <RadioStyled
      type='radio'
      id={props.id}
      ref={ref} {...props}
      label={props.label}
      value={props.value}
    />
  )
})

export const InputFile = React.forwardRef((props, ref) => {
  return (
    <FileStyled
      type="file"
      ref={ref} {...props}
      id={props.id}
      name={props.name}
      label={props.label}
      onChange={props.onChange}
      style={props.style}
    />
  )
})

export {DatePickerCustom}

export {Select}

export {SelectV2}

export {InputStyled}

export {TextArea}

export default Input
