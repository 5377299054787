import React from "react";
import moment from "moment";

const TimeAgo = ({children}) => {

  const goTime = (value) => {
    let valueK;
    const today = new Date()
    const day = new Date(value)
    const days = Math.round((today.getTime() - day.getTime()) / (1000 * 60 * 60 * 24))
    const horas = Math.round((today.getTime() - day.getTime()) / (1000 * 60 * 60))
    const minutes = Math.round((today.getTime() - day.getTime()) / (1000 * 60))

    if (days > 0) {
      return moment(value).format('DD/MM HH:mm')
    }

    if (horas > 0) {
      return `hace ${horas}h`;
    }

    if (minutes === 0) {
      valueK = 'Ahora';
    } else {
      valueK = `hace ${minutes}m`;
    }

    return valueK;
  }

  return (
    <span>
      {goTime(children)}
    </span>
  )
}

export default TimeAgo
