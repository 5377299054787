import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import PageSection from "../../../../components/layout/PageSection";
import PageContainerFull from "../../../../components/layout/PageContainerFull";
import DateFilter from "../../../../components/datefilter/DateFilter";
import Card from "../../../../components/forms/Card";
import { getCasinoDay } from "../../../../components/util/CasinoDates";
import CustomerLink from "../../../../components/util/CustomerLink";
import { Apis } from "../../../../util/Apis";
import { SiteContext } from "../../../common/context/SiteContext";
import useFetch2 from "../../../../hooks/useFetch2";
import moment from "moment";
import Button from "../../../../components/forms/Button";
import { useHistory } from "react-router";
import { FlexLayout } from "../../../../components/flex/Flex";
import ReactTable from "../../../../components/util/ReactTable";
import AsyncBlock from "../../../../components/forms/AsyncBlock";
import { Title } from "@wargostec/react-components";
import useGet from "../../../../hooks/useGet";
import { getAge } from "../../../../util";

const CustomerBirthday = () => {
  const history = useHistory()
  const casinoDay = getCasinoDay()
  const { salaId } = useContext(SiteContext)

  const fechasDefault = { ini: casinoDay, fin: casinoDay }

  const [fechas, setFechas] = useState(fechasDefault)
  const fechaIni = moment(fechas.ini).subtract(1, 'day').format('YYYY-MM-DD')

  let defaultParams = { salaId: salaId, fechaIni: fechaIni, fechaFin: fechas.fin }

  const [params, setParams] = useState(defaultParams)

  const { data, loading, fetchData, error } = useGet({
    url: Apis.PC + '/customers/birthday',
    params: params,
    initialValue: []
  })

  const onChangeDates = async (newFechas) => {
    setFechas(newFechas)
    const auxFecha = moment(newFechas.ini).subtract(1, 'day').format('YYYY-MM-DD')
    let newParams = { ...params, fechaIni: auxFecha, fechaFin: newFechas.fin }
    await fetchData(newParams)
    setParams(newParams)
  }

  const clienteCell = useCallback(props => <>{
    <FlexLayout alignItems='center' justifyContent='flex-start' gap='4px'>
      <CustomerLink item={{ ...props.row.original, customerId: props.row.original.id }} />
    </FlexLayout>
  }</>, []);

  const edadCell = useCallback(props => <>{
    <FlexLayout alignItems='center' justifyContent='flex-start' gap='4px'>
      <span>{getAge(props.renderValue())}</span>
    </FlexLayout>
  }</>, [])

  const nivelCell = useCallback(props => <>{
    <FlexLayout alignItems='center' justifyContent='center' gap='4px'>
      <span>{props.row.original.exists ? props.renderValue() : 'Sin nivel'}</span>
    </FlexLayout>
  }</>, [])

  const actionsValue = useCallback(props => <>{
    <FlexLayout justifyContent='center'>
      <Button variant={'light'} size='xs'
        onClick={() => history.push('/crm/customer-info/' + props.renderValue())}>
        Detalles
      </Button>
    </FlexLayout>
  }</>, []);

  const download = () => {
    const searchParams = new URLSearchParams();
    Object.keys(params).forEach(key => searchParams.append(key, params[key]));

    window.location.href = Apis.PC + `/customers/birthday.xlsx?${searchParams}`;
  }

  const columns = useMemo(
    () => [
      {
        header: 'Cliente',
        accessorKey: 'id',
        cell: clienteCell
      },
      {
        header: 'Fecha Cumpleaños',
        accessorKey: 'birthday',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        }
      },
      {
        header: 'Edad',
        accessorKey: 'birthday',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        },
        cell: edadCell
      },
      {
        header: 'Nivel',
        accessorKey: 'levelName',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: nivelCell
      },
      {
        header: 'Opciones',
        accessorKey: 'id',
        enableGlobalFilter: false,
        enableSorting: false,
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
        cell: actionsValue
      }
    ]
    , []
  )

  return (
    <PageContainerFull>
      <PageSection>
        <Title text="Cumpleaños" type={'page'} />
        <Card padding style={{ background: '#0A0A0A' }}>
          <DateFilter fechas={fechas} setFechas={onChangeDates} />
        </Card>

        <AsyncBlock loading={loading} error={error}>
          <ReactTable
            columns={columns}
            data={data || []}
            filterDownload={download}
            noData='No existen resultados'
            pageSize={20}
          />
        </AsyncBlock>

      </PageSection>

    </PageContainerFull>
  )
}

export default CustomerBirthday;
