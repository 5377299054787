import styles from './button.module.scss';

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  icon?: React.ReactNode
  iconPosition?: 'left' | 'right'
  text?: string
  variant?: 'primary' | 'danger' | 'warning' | 'success' | 'light' | 'dark'
  size?: 'lg' | 'md' | 'sm' | 'xs'
}

const Button: React.FC<ButtonProps> = ({icon, iconPosition = 'left', text = '', size = 'md', variant = 'primary', ...props}) => {
  return (
    <button className={`${styles['btn-container']} ${styles[size]} ${styles[variant]}`} {...props}>
      {icon && iconPosition === 'left' && icon}
      {text}
      {icon && iconPosition === 'right' && icon}
    </button>
  )
}

export default Button