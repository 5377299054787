import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import axios from "axios";
import {Apis} from "../../../../util/Apis";
import Form from "react-bootstrap/Form";
import FormGroup from "../../../../components/forms/FormGroup";
import Input from "../../../../components/forms/FormEl";
import Button, {ButtonBox} from "../../../../components/forms/Button";
import Card from "../../../../components/forms/Card";
import EmptyState from "../../../common/components/EmptyState";
import {IoTicketOutline} from "react-icons/io5";
import Moment from "react-moment";

const TicketValidate = () => {

  const [ticketData, setTicketData] = useState(null)

  const {
    register, getValues, handleSubmit, reset, formState: {errors}, setValue
  }
    = useForm({});

  const validate = (params) => {
    axios.get(Apis.TICKET + '/ticket/vn/' + params.validNumber, params)
      .then(res => {
        setTicketData(res.data)
      }).catch(err => {
    })
  }

  // const redeemTicket = (params) => {
  //   const validNumber = getValues('validNumber')
  //   axios.post(Apis.TICKET + '/ticket-by-caja/redeem/' + validNumber)
  //     .then(res => {
  //       successToast("Ticket canjeado", 2000)
  //       validate({validNumber: validNumber})
  //     }).catch(err => {
  //     errorToast(`El ticket ${validNumber} no puede ser canjeado`, 2000)
  //   })
  // }

  // const enableTicket = (params) => {
  //   const validNumber = getValues('validNumber')
  //   axios.post(Apis.TICKET + `/ticket/vn/${validNumber}/enable`)
  //     .then(res => {
  //       successToast("Ticket habilitado correctamente", 2000)
  //       validate({validNumber: validNumber})
  //     }).catch(err => {
  //     errorToast("Error al habilitar el ticket", 2000)
  //   })
  // }

  const getStateValue = (state) => {
    switch (state) {
      case 0:
        return 'PENDIENTE';
      case 1:
        return 'CANJEADO';
      case 2:
        return 'BLOQUEADO';
      case 3:
        return 'EXPIRADO';
      default:
        return '---';
    }
  }


  return (
    <>
      <Card padding>
        <Form>
          <FormGroup>
            <label>Buscar ticket</label>
            <Input type="number"
                   placeholder={"Ingrese el número de ticket"} {...register("validNumber", {required: "Campo obligatorio"})}/>
            {errors.validNumber && <p style={{color: 'red'}}>{errors.validNumber?.message}</p>}
          </FormGroup>
          <ButtonBox>
            <Button title={"Buscar"} variant={'primary'} onClick={handleSubmit(validate)}/>
          </ButtonBox>
        </Form>
      </Card>
      {
        ticketData &&
        <Card padding>
          {
            ticketData?.exists ?
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <span style={{fontSize: '20px'}}><IoTicketOutline
                  size={20}/><strong> {getValues('validNumber')}</strong></span>
                <span style={{paddingTop: '10px'}}>Monto: S/
                  <strong>S/ {(ticketData?.ticket?.amount * 0.01).toFixed(2)}</strong>
                </span>
                <span>Estado:
                  <strong> {getStateValue(ticketData?.ticket?.status)}</strong>
                  </span>
                <span>Emitido en:
                  <strong> {ticketData?.ticket?.machine ? ticketData?.ticket?.machine : "---"}</strong>
                  </span>
                <span>Fecha de Emisión:
                  <strong> {ticketData?.ticket?.createdAt ?
                    <Moment format={'DD/MM HH:mm'}>{ticketData?.ticket?.createdAt}</Moment> : "---"}</strong>
                  </span>
                {ticketData?.ticket?.confIdRedeem !== null && (
                  <>
								<span>
									Canjeado en: <strong>{ticketData?.ticket?.machineRedeem ? ticketData?.ticket?.machineRedeem : "---"}</strong>
								</span>
                    <span>
									Fecha de canje: <strong>{ticketData?.ticket?.redeemAt ?
                      <Moment format={'DD/MM HH:mm'}>{ticketData?.ticket?.redeemAt}</Moment> : "---"}</strong>
								</span>
                  </>
                )}
              </div>
              :
              <EmptyState
                title={`Ticket ${getValues('validNumber')} no existe`}
                subtitle={'No se encontraron resultados para la búsqueda del ticket. Intenta con otro número.'}
                icon={<IoTicketOutline size={20}/>}
              />
          }

        </Card>
      }

    </>
  );
};
export default TicketValidate;
