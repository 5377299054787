import React, {useEffect} from 'react'
import Form from "react-bootstrap/Form";
import FormGroup from "../../../../../components/forms/FormGroup";
import {Controller, useFieldArray, useForm} from "react-hook-form";
import Input from "../../../../../components/forms/FormEl";
import {Col, Row} from "../../../../../components/flex/FlexLayout";
import useFilasMesa from "../../../../../hooks/useFilasMesa";
import UserSelect from "../../../../common/components/selects/UserSelect";
import FundTypeSelect from "../../../../common/components/selects/FundTypeSelect";
import SalaIdInput from "../../../../common/components/SalaIdInput";

const FondoForm = ({value, onSubmit, setAction}) => {

  const {rows, setRows, total} = useFilasMesa("CAJA")
  const defaultParams = {rows: rows, total: total}
  const {register, control, handleSubmit, reset, setValue} = useForm({defaultValues: defaultParams});

  const {fields, append, prepend, remove} = useFieldArray({
    control,
    name: "detail"
  });

  const agregarResponsable = () => {
    append({name: 'sourceFondoId'});
  }

  useEffect(() => {
    if (value) {
      if (Object.keys(value).length !== 0) {
        reset(value)
      }
    }
  }, [value])

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit))
  }, [])

  useEffect(() => {
    setValue("iniAmount", total)
    setValue("detailDen", rows)
  }, [total])

  const isEditMode = () => {
    return value && Object.keys(value).length > 0;
    };

  return (
    <Form>
      <SalaIdInput {...register("salaId")}/>
      <FormGroup>
        <label>Fondo</label>
        <FundTypeSelect {...register("fundTypeId")} disabled={isEditMode()}/>
      </FormGroup>

      {/*<Row>*/}
      {/*  <Col>*/}
      {/*    <FormGroup>*/}
      {/*      <label>Desde fondo</label>*/}
      {/*      <FondoSelect {...register("sourceFondoId")} disabled={isEditMode()}/>*/}
      {/*    </FormGroup>*/}
      {/*  </Col>*/}
      {/*</Row>*/}

      <Row>
        <Col>
          <FormGroup>
            <label>Periodo</label>
            <Input type={"date"} {...register("period", { required: true})} disabled={isEditMode()} />
          </FormGroup>
        </Col>
      </Row>

      <FormGroup>
        <label>Responsable</label>
        <Controller
          name="responsibleId"
          control={control}
          render={({field}) => <UserSelect {...field} />}
        />
      </FormGroup>

      {/*<FormGroup>*/}
      {/*  <TableAmountsMesa rows={rows} setRows={setRows} total={total}/>*/}
      {/*</FormGroup>*/}
      {/*<FormGroup>*/}
      {/*  <label>Responsable del fondo</label>*/}
      {/*  {fields && fields.map((item, index) =>*/}
      {/*    <Row>*/}
      {/*      <Col>*/}
      {/*        <UserSelect {...register("sourceFondoId")}/>*/}
      {/*      </Col>*/}
      {/*      <Col>*/}
      {/*        <Button title={"-"} variant={'secondary'}*/}
      {/*                style={{color: "#f8f9fa", backgroundColor: "#dc3545", border: "#dc3545"}}*/}
      {/*                onClick={() => remove(index)}*/}
      {/*        />*/}
      {/*      </Col>*/}
      {/*    </Row>*/}
      {/*  )}*/}
      {/*</FormGroup>*/}
      {/*<FormGroup>*/}
      {/*  <ButtonBox>*/}
      {/*    <Button title={"Agregar Responsable"} variant={'primary'}*/}
      {/*            onClick={agregarResponsable}*/}
      {/*    />*/}
      {/*  </ButtonBox>*/}
      {/*</FormGroup>*/}
    </Form>
  )
}

export default FondoForm
