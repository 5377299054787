import React from 'react'
import { SelectV2} from "../../../../components/forms/FormEl";

const MonthSelect = React.forwardRef((props, ref) => {

  return (
    <SelectV2 ref={ref} {...props} options={[
      {id : "", name : "Seleccione"},
      {id : "1", name : "Enero"},
      {id : "2", name : "Febrero"},
      {id : "3", name : "Marzo"},
      {id : "4", name : "Abril"},
      {id : "5", name : "Mayo"},
      {id : "6", name : "Junio"},
      {id : "7", name : "Julio"},
      {id : "8", name : "Agosto"},
      {id : "9", name : "Setiembre"},
      {id : "10", name : "Octubre"},
      {id : "11", name : "Noviembre"},
      {id : "12", name : "Diciembre"},
    ]}>
    </SelectV2>
  )
})

export default MonthSelect;
