import React, {useEffect, useState} from 'react';
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import Select from "react-select";
import {SelectV2} from "../../../../components/forms/FormEl";
import {MdFiberManualRecord} from "react-icons/md";

const formatOptionLabel = ({label, online}) => {
  const getColor = (online) => {
    if (online === true) {
      return '#60FC1C'
    }else {
      return '#FF0000'
    }
  }

  return (
    <div style={{display: "flex"}}>
      <div style={{marginTop: "4px"}}>{label}</div>
      <div style={{marginLeft: "10px"}}>
        <MdFiberManualRecord color={getColor(online)} size={28}/>
      </div>
    </div>
  )
}

const PangolinSelect = React.forwardRef((props, ref) => {

  const {data, loading} = useFetch2(`${Apis.TGM}/pangolin`)
  const [selectedOption, setSelectedOption] = useState(null)
  const [optionsFormat, setOptionsFormat] = useState([])

  const prevOnChange = (selected) => {
    props.onChange(selected.value)
  }

  useEffect(() => {
    const selected = optionsFormat.find(option => option.value === props.value)
    setSelectedOption(selected)
  }, [props.value])

  useEffect(() => {
    if (data) {
      const formatted = data && data.data.map((item) => {
        return {value: item.sn, label: item.sn, online: item.online};
      });
      setOptionsFormat(formatted)
      if (props.value) {
        const selected = formatted.find(option => option.value === props.value)
        setSelectedOption(selected)
      }
    }
  }, [data])

  return (
    <>
      {
        !loading ?
          <Select
            value={selectedOption}
            onChange={prevOnChange}
            options={optionsFormat}
            formatOptionLabel={formatOptionLabel}
          />
          :
          <SelectV2 disabled options={[{id: 0, name: 'Cargando...'}]}/>
      }
    </>
  );
});

export default PangolinSelect;
