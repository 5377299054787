import React, {useContext, useEffect} from 'react';
import {SiteContext} from "../../../../common/context/SiteContext";
import {Apis} from "../../../../../util/Apis";
import axios from "axios";
import ManualRedeemForm from "./ManualRedeemForm";

const ManualRedeemNew = ({setTitle, setAction}) => {
  const {salaId} = useContext(SiteContext)

  const save = (params) => {
    params.salaId = salaId
     axios.post(Apis.PC + '/manual-redeem', params).catch((err) => {
       console.log(err)
    })
  }

  useEffect(() => {
    setTitle("Nuevo manual canjeado")
  }, [])

  return (
    <ManualRedeemForm value={{}} onSubmit={save} setAction={setAction}/>
  );
};

export default ManualRedeemNew;
