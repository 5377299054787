import React, {useEffect} from 'react';
import useFetch2 from "../../../../../hooks/useFetch2";
import {Apis} from "../../../../../util/Apis";
import axios from "axios";
import CashExpenseForm from "./CashExpenseForm";

const CashExpenseEdit = ({id, setTitle, setAction}) => {

  const {data} = useFetch2(Apis.CAJA + '/expenses-category/' + id)

  function update(params) {
    params.id = id
    return axios.put(Apis.CAJA + '/expenses-category/' + id, params);
  }

  useEffect(() => {
    setTitle("Editar categoría de gasto")
  }, [])

  return (
    <CashExpenseForm value={data} onSubmit={update} setAction={setAction}/>
  );
};

export default CashExpenseEdit;
