import React, {useMemo, useState} from 'react'
import {SelectV2} from "../../../../components/forms/FormEl";
import {Apis} from "../../../../util/Apis";
import useFetch2 from "../../../../hooks/useFetch2";

const SalaSelectV2 = React.forwardRef((props, ref) => {

  const {data,loading} = useFetch2(Apis.USER + '/users/me?type=casino', {},{})
  const [dataSalas,setDataSalas] = useState([])

  useMemo(() => {
    if (data && !data.isEmpty && data.salas) {
      setDataSalas([{id: 0, name: ""},...data.salas])
    }
  }, [data])

  return (
    <>
      {
        (!loading) ?
          <SelectV2 ref={ref} options={dataSalas} {...props}/>
          :
          <SelectV2 disabled options={[{id: 0, name: 'Cargando...'}]}/>
      }
    </>
  )
})

export default SalaSelectV2;
