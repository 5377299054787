import React from 'react'
import {Select} from "../../../../components/forms/FormEl";
import ButtonGroup from "../../../../components/forms/ButtonGroup";

const SorteoTypeSelect = React.forwardRef((props, ref) => {

  return (
    <Select name='kind' ref={ref} onChange={props.onChange} onBlur={props.onBlur} style={props.style}>
      <option value={'0'} hidden>Select...</option>
      <option value={'VIRTUAL'}>VIRTUAL</option>
      <option value={'MANUAL'}>MANUAL</option>
      <option value={'HOT_SEAT'}>HOT_SEAT</option>
      <option value={'TORNEOS'}>TORNEOS</option>
    </Select>
  )
})

const SorteoTypeSelectV2 = React.forwardRef((props, ref) =>
  (
    <ButtonGroup
      value = {props.value}
      onChange={props.onChange}
      data={[
        {text : 'MANUAL', value : 'MANUAL'},
        {text : 'VIRTUAL', value : 'VIRTUAL'},
        {text : 'HOT SEAT', value : 'HOT_SEAT'},
        {text : 'TORNEO', value: 'TORNEO'}]}
    />
  )
)

export {SorteoTypeSelectV2}

export default SorteoTypeSelect;
