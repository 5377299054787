import React, {useMemo} from 'react';
import useFetch2 from "../../../../../hooks/useFetch2";
import {Apis} from "../../../../../util/Apis";
import PageSection from "../../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import AsyncBlock from "../../../../../components/forms/AsyncBlock";
import ReactTable from "../../../../../components/util/ReactTable";
import GenericModal2 from "../../../../../modals/GenericModal2";
import PageContainer from "../../../../../components/layout/PageContainer";
import useEditModal from "../../../../../modals/useEditModal";
import RangeNew from "./RangeNew";

const RangeList = () => {

  const {loading, data, fetchData, error} = useFetch2(Apis.BINGO + `/range-increase`, []);

  const {wrapper: wrapper1, addAction} = useEditModal({
    addComponent: <RangeNew/>,
    onRefresh: fetchData
  })

  const columns = useMemo(
    () => [
      {
        header: 'Id',
        accessorKey: 'id',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Bolilla inicial',
        accessorKey: 'fromBall',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Bolilla final',
        accessorKey: 'toBall',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
    ]
    , []
  )

  return (
    <PageContainer>
      <PageSection>

          <Title text="Lista de rangos" type={'page'}/>

        <AsyncBlock loading={loading} error={error}>
          <ReactTable
            columns={columns}
            data={data || []}
            filterAdd={() => addAction()}
            customSearch
            noData='No existen resultados'
            pageSize={15}
          />
        </AsyncBlock>

      </PageSection>

      <GenericModal2 {...wrapper1}/>
    </PageContainer>
  );
};

export default RangeList;
