import React, {useEffect} from 'react'
import {useForm} from "react-hook-form";
import FormGroup from "../../../../components/forms/FormGroup";
import Input from "../../../../components/forms/FormEl";

const ExpiredManualForm = ({value, onSubmit, setAction}) => {

  const {register, control, handleSubmit, watch, errors, reset, getValues, setValue} = useForm();

  useEffect(() => {
    if (value) {
      reset(value)
    }
  }, [value])

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit));
  }, [])

  return (
    <React.Fragment>
      <FormGroup>
        <label>Fecha</label>
        <Input type='date'  {...register("scheduledAtDate")}/>
      </FormGroup>
      <FormGroup>
        <label>Hora</label>
        <Input type='time' {...register("scheduledAtTime")}/>
      </FormGroup>

      <FormGroup>
        <label>Escriba <strong style={{fontSize:"15px"}}>expirar cupones</strong> para continuar</label>
        <Input type='text' {...register("verification")}/>
      </FormGroup>
    </React.Fragment>
  )

}

export default ExpiredManualForm
