import React, {useContext, useState} from "react";
import {Apis} from "../../../util/Apis";
import useFetch2 from "../../../hooks/useFetch2";
import {SiteContext} from "../context/SiteContext";
import styled from "styled-components";

const UlMachine = styled.ul`
    max-height: 250px;
    overflow-y: auto;
    position: absolute;
    width: 100%;
    z-index:10;
  `

  const LiMachine = styled.li`
    cursor: pointer;

    &:hover {
      color: #16181b;
      text-decoration: none;
      background: #e9ecef;
    }

    &:active {
      color: #fff;
      text-decoration: none;
      background: #007bff;
    }
  `

const MachineSearchInput = React.forwardRef((props, ref) => {

  const {data, loading} = useFetch2(`${Apis.TGM}/machines/combo`)
  const [machines, setMachines] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [noQuery, setNoQuery] = useState(true);
  const [confIdValue, setConfIdValue] = useState(null)

  const cleanSearch = () => {
    setNoQuery(true)
    setConfIdValue(null)
    setSearchValue(null)
    props.onChange(null)
  }

  const filterMachine = name => {
    setNoQuery(false)
    const query = name.target.value
    setSearchValue(name.target.value)
    if (query === undefined || query === '') {
      cleanSearch()
      setMachines(data)
      return
    }

    const searchMachine = data.filter((el) => {
      return el.machine.toUpperCase().includes(query.toUpperCase())
    })
    setMachines(searchMachine)
  }

  const customOnchange = async (item) => {
    setNoQuery(true)
    setSearchValue(item.machine.toUpperCase())
    setConfIdValue(item.confId)
    props.onChange(item.confId)
  }

  return (
    <div style={{position:'relative'}}>
      <input className="form-control" type="text" placeholder="Ingrese máquina" ref={ref}
             onChange={filterMachine} value={searchValue} name={props.name} autoComplete="off"/>
      <UlMachine className="list-group" id="myList">
        {machines && !noQuery && machines.map(item =>
          <LiMachine key={item.confId} onClick={() => customOnchange(item)}
                     className="list-group-item">{item.machine}</LiMachine>
        )}
      </UlMachine>
    </div>
  );
})
export default MachineSearchInput;
