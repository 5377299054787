import React from 'react'
import {Col, Nav, Row, Tab} from 'react-bootstrap'
import {BsGiftFill, BsPrinterFill} from 'react-icons/bs'
import {FaBookReader, FaDatabase} from 'react-icons/fa'
import styled from 'styled-components'
import { Title } from "@wargostec/react-components"

import Card, { CardBody } from '../../../components/forms/Card'
import PageContainerFull from '../../../components/layout/PageContainerFull'
import PageSection from '../../../components/layout/PageSection'
import ComputerPrinterList from './coupons/ComputerPrinterList'
import ConfigOrders from './products/ConfigOrders'
import SorteoConfiguration from './sorteos/SorteoConfiguration'
import OnlineConfiguration from "./sistemaOnline/OnlineConfiguration";
import {MdOnlinePrediction} from "react-icons/md";
import ParametrosLogueo from "./configuration/ParametrosLogueo";
import {GrDocumentConfig} from "react-icons/gr";
import Datacenter from "./sistemaOnline/Datacenter";
import ServerList from "../../tgm/pages/maestros/machine/ServerList";
import {LiaServerSolid} from "react-icons/lia";

const CustomNavLink = styled(Nav.Link)`
  color: #1A1A1A;
  &.active{
    background-color: #1A1A1A !important;
    color: #fff;
  }
  &:hover{
    color: #1A1A1A;
  }
`

const Settings = () => {

  return (
    <PageContainerFull>
      <PageSection>
        <Title text="Configuración" type={'page'} />
        <Tab.Container id="left-tabs-example" defaultActiveKey="1">
          <Row>
            <Col lg={3}>
              <Card>
                <CardBody>
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <CustomNavLink eventKey="1">
                        <div style={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
                          <BsGiftFill size={16} />
                          <span style={{ fontWeight: 500 }}>Sorteos</span>
                        </div>
                      </CustomNavLink>
                    </Nav.Item>
                    <Nav.Item>
                      <CustomNavLink eventKey="2">
                        <div style={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
                          <BsPrinterFill size={16} />
                          <span style={{ fontWeight: 500 }}>Impresoras</span>
                        </div>
                      </CustomNavLink>
                    </Nav.Item>
                    <Nav.Item>
                      <CustomNavLink eventKey="3">
                        <div style={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
                          <FaBookReader size={16} />
                          <span style={{ fontWeight: 500 }}>Pedidos</span>
                        </div>
                      </CustomNavLink>
                    </Nav.Item>
                    <Nav.Item>
                      <CustomNavLink eventKey="4">
                        <div style={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
                          <GrDocumentConfig size={16} />
                          <span style={{ fontWeight: 500 }}>Sala</span>
                        </div>
                      </CustomNavLink>
                    </Nav.Item>
                    <Nav.Item>
                      <CustomNavLink eventKey="5">
                        <div style={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
                          <MdOnlinePrediction size={16} />
                          <span style={{ fontWeight: 500 }}>Parametros logueo</span>
                        </div>
                      </CustomNavLink>
                    </Nav.Item>
                    <Nav.Item>
                      <CustomNavLink eventKey="6">
                        <div style={{display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center'}}>
                          <FaDatabase size={16} />
                          <span style={{fontWeight: 500}}>Datacenter</span>
                        </div>
                      </CustomNavLink>
                    </Nav.Item>
                    <Nav.Item>
                      <CustomNavLink eventKey="7">
                        <div style={{display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center'}}>
                          <LiaServerSolid size={16} />
                          <span style={{fontWeight: 500}}>Servidores</span>
                        </div>
                      </CustomNavLink>
                    </Nav.Item>
                  </Nav>
                </CardBody>
              </Card>
            </Col>
            <Col lg={9}>
              <Tab.Content>
                <Tab.Pane eventKey="1">
                  <SorteoConfiguration />
                </Tab.Pane>
                <Tab.Pane eventKey="2">
                  <ComputerPrinterList />
                </Tab.Pane>
                <Tab.Pane eventKey="3">
                  <ConfigOrders />
                </Tab.Pane>
                <Tab.Pane eventKey="4">
                  <OnlineConfiguration />
                </Tab.Pane>
                <Tab.Pane eventKey="5">
                  <ParametrosLogueo />
                </Tab.Pane>
                <Tab.Pane eventKey="6">
                  <Datacenter />
                </Tab.Pane>
                <Tab.Pane eventKey="7">
                  <ServerList />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </PageSection>

    </PageContainerFull>
  )
}

export default Settings
