import React, {useEffect} from 'react';
import {Apis} from "../../../../util/Apis";
import useFetch2 from "../../../../hooks/useFetch2";
import {Select, SelectV2} from "../../../../components/forms/FormEl";

const LevelSelect = React.forwardRef((props, ref) => {

  const {data, loading} = useFetch2(Apis.PC + `/customer-segments`, [])

  useEffect(() => {
    if (data) {
      data.push({id: "", name: "TODOS"})
    }
  }, [data])

  return (
    <>
      {
        !loading ?
          <SelectV2
            ref={ref}
            options={data}
            {...props}
          >
          </SelectV2>
          :
          <Select disabled={true}>
            <option key={0} value={0}>{"Cargando..."}</option>
          </Select>
      }
    </>
  )
})
export default LevelSelect;
