import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import PageSection from "../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import {Apis} from "../../../../util/Apis";
import Button, {ButtonBox} from "../../../../components/forms/Button";
import Slidebar from "../../../common/layout/Slidebar";
import {SiteContext} from "../../../common/context/SiteContext";
import useFetch2 from "../../../../hooks/useFetch2";
import AsyncBlock from "../../../../components/forms/AsyncBlock";
import CardOverview, {CardOverviewContainer} from "../../../../components/layout/CardOverview";
import {FaEdit, FaTrash} from "react-icons/fa";
import MesasNewComponent from "./MesasNewComponent";
import MesasEditComponent from "./MesasEditComponent";
import {Dropdown} from "react-bootstrap";
import axios from "axios";
import useDeleteModal from "../../../../modals/useDeleteModal";
import AperturarMesaNew from "./AperturarMesaNew";
import GenericModal2 from "../../../../modals/GenericModal2";
import DeleteGenericModal from "../../../../modals/DeleteGenericModal";
import CerrarMesa from "./CerrarMesa";
import useEditModal from "../../../../modals/useEditModal";
import PageContainerFull from "../../../../components/layout/PageContainerFull";
import {FlexLayout} from "../../../../components/flex/Flex";
import {HiOutlineEllipsisVertical} from "react-icons/hi2";
import {TbPokerChip} from "react-icons/tb";
import ReactTable from "../../../../components/util/ReactTable";

const MesasListTable = ({refreshTable, setRefreshTable}) => {

  const {salaId} = useContext(SiteContext)

  const params = {salaId: salaId}
  const {loading, data, fetchData, error} = useFetch2(Apis.TABLES + '/tables', [], params, false)

  const [slideOpen, setSlideOpen] = useState(false)
  const [refresh, setRefresh] = useState(true)
  const [idMesa, setIdMesa] = useState(null)

  const {wrapper: wrapper1, editAction} = useEditModal({
    editComponent: <AperturarMesaNew />,
    onRefresh: ()=>{
      setTimeout(() => {
        fetchData()
      },1000)
    }
  })

  useEffect(() => {
    if (params && (refresh || refreshTable)) {
      fetchData()
    }
    setRefresh(false)
    setRefreshTable(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, refresh, refreshTable])

  const openSlidebar = (id) => {
    setSlideOpen(!slideOpen)
    setIdMesa(id)
  }

  const {wrapper: wrapper2, editAction: editAction2} = useEditModal({
    editComponent: <CerrarMesa />,
    onRefresh: ()=>{
      setTimeout(() => {
        fetchData()
      },1000)
    }
  })

  const deleteElement = id => axios.delete(Apis.TABLES + '/tables/' + id)

  const {
    wrapper: wrapper3,
    openDeleteModal
  } = useDeleteModal({
    text: '¿Desea eliminar esta mesa?',
    action: deleteElement,
    onRefresh: fetchData
  })

  const ActiveValue = useCallback(props => <>{props.renderValue() ? 'Activo' : 'Inactivo'}</>, []);
  const MaxCapacity = useCallback(props => <>{props.renderValue() + ' clientes'}</>, []);
  const MoreOptions = useCallback(props =>
    <FlexLayout alignItems='center' justifyContent='center'>
      {!props.row.original.active ?
        <Button variant='dark' size='s' onClick={() => editAction(props.row.original.id)}>Iniciar turno</Button>
        :
        <Button size='s' onClick={() => editAction2(props.row.original.id)}>Cerrar Turno</Button>
      }
      <Dropdown>
        <Dropdown.Toggle variant="light" id="dropdown-custom-1" size='sm'>
          <HiOutlineEllipsisVertical size={16} />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => openSlidebar(props.row.original.id)}>
            <FaEdit /> <span>Editar</span>
          </Dropdown.Item>
          <Dropdown.Item onClick={() => openDeleteModal(props.row.original.id)}>
            <FaTrash /> <span>Eliminar</span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </FlexLayout>,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

  const columns = useMemo(
    () => [
      {
        header: 'Nombre',
        accessorKey: 'tableCode',
        meta: {
          headerClassName:'text-center', // class para la cabecera
          columnClassName:'text-center', // class para la columna
        },
      },
      {
        header: 'Estado',
        accessorKey: 'active',
        meta: {
          headerClassName:'text-center',
          columnClassName:'text-center',
        },
        cell: ActiveValue
      },
      {
        header: 'Tipo',
        accessorKey: 'type',
        meta: {
          headerClassName:'text-center',
          columnClassName:'text-center',
        },
      },
      {
        header: 'Capacidad Maxima',
        accessorKey: 'maxCapacity',
        meta: {
          headerClassName:'text-center',
          columnClassName:'text-center',
        },
        cell: MaxCapacity
      },
      {
        header: 'Acciones',
        accessorKey: 'button',
        enableGlobalFilter:false, // para deshabilitar el filter
        enableSorting:false, // para deshabilitar el sort
        meta: {
          headerClassName:'text-center',
          columnClassName:'text-center', 
        },
        cell: MoreOptions
      }
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , []
  )

  return (
    <>
      <Slidebar visible={slideOpen} onHide={() => setSlideOpen(false)}>
        <div style={{padding: '16px'}}>
          {idMesa !== null &&
            <MesasEditComponent id={idMesa} slideOpen={setSlideOpen} refresh={setRefresh} />
          }
        </div>
      </Slidebar>

      <CardOverviewContainer gridColumns='repeat(3, 1fr)'>
        <CardOverview
          title={data && data.length}
          subtitle='Total de mesas'
          icon={<TbPokerChip size={26} />}
        />
      </CardOverviewContainer>


      <AsyncBlock loading={loading} error={error}>
        <ReactTable
          columns={columns}
          data={data || []}
          customSearch
          noData='No existen resultados'
          pageSize={10}
        />
      </AsyncBlock>
      <GenericModal2 {...wrapper1} />
      <GenericModal2 {...wrapper2} />
      <DeleteGenericModal {...wrapper3} />
    </>
  )
}

const MesasConfig = () => {

  const [slideOpen, setSlideOpen] = useState(false)
  const [refresh, setRefresh] = useState(false)

  return (
    <PageContainerFull>
      <PageSection>
          <Title text="Mesas" type={'page'}/>

        <ButtonBox>
          <Button variant={'primary'} title={'Nuevo'} onClick={() => setSlideOpen(true)} />
        </ButtonBox>
      </PageSection>

      <Slidebar visible={slideOpen} onHide={() => setSlideOpen(false)}>
        <div style={{padding: '16px'}}>
          <MesasNewComponent slideOpen={setSlideOpen} refresh={setRefresh} />
        </div>
      </Slidebar>

      <PageSection>
        <MesasListTable refreshTable={refresh} setRefreshTable={setRefresh} />
      </PageSection>
    </PageContainerFull>
  )
}

export default MesasConfig;
