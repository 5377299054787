import React, {useEffect, useState} from 'react'
import Select from "react-select";
import {SelectV2} from "../../../../components/forms/FormEl";
import {Badge} from "react-bootstrap";
import useCouponType from "../../../../stores/useCouponType";

const formatOptionLabel = ({ label, enabled, expired}) => (
  <div style={{display: "flex"}}>
    <div>{label}</div>
    {
      !enabled ?
        <div style={{marginLeft: "10px"}}>
          <Badge bg="secondary">{'Deshabilitado'}</Badge>
        </div>
        :
        <>
          {
            expired &&
            <div style={{marginLeft: "10px"}}>
              <Badge bg="danger">{'Expirado'}</Badge>
            </div>
          }
        </>
    }
  </div>
);

const CouponTypeSelectV2 = React.forwardRef((props) => {

  const {couponTypes: data, loading} = useCouponType(props.onlyActive)

  const [selectedOption, setSelectedOption] = useState(null)
  const [optionsFormat, setOptionsFormat] = useState([])

  const prevOnChange = (selected) => {
    props.onChange(selected.value)
  }

  useEffect(() => {
    //console.log('new value' + props.value + '| options len : '+optionsFormat.length)
    const selected = optionsFormat.find(option => option.value === props.value)
    setSelectedOption(selected)
  }, [props.value])

  useEffect(() => {
    if (data) {
      const formatted = data.map((str) => {
        return {value: str.id, label: str.name, enabled: str.enabled, expired: str.expired};
      }).sort(function (a, b) {
        return b.enabled - a.enabled
      });

      setOptionsFormat(formatted)

      if (props.value) {
        //console.log('cargo la data y el valor por default es : ' + props.value)
        const selected = formatted.find(option => option.value === props.value)
        //console.log('encontramos conincenedica : '+ selected)
        setSelectedOption(selected)
      }
    }
  }, [data])

  return (
    <>
      {
        !loading ?
          <Select
            value={selectedOption}
            onChange={prevOnChange}
            formatOptionLabel={formatOptionLabel}
            options={optionsFormat}
          />
          :
          <SelectV2 disabled={true} isLoading/>
      }
    </>
  )
})

export default CouponTypeSelectV2;
