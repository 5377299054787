import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import Form from "react-bootstrap/Form";
import FormGroup from "../../../../components/forms/FormGroup";
import Input from "../../../../components/forms/FormEl";
import RequiredLabel from "../../../../components/util/RequiredLabel";

const SorteoActaForm = ({acta, onSubmit, setAction}) => {

  const [amountValidation, setAmountValidation] = useState(false)

  let defaultParams = {nroActa: acta}
  const {
    register,
    handleSubmit,
    getValues,
  } = useForm({defaultValues: defaultParams});

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit))
  }, [])

  const handleFieldBlur = (valueName, setValidation) => {
    const property = getValues()[valueName]
    setValidation(property.length === 0)
  }

  return (
    <Form>
      <FormGroup>
        <div style={{display: 'flex'}}>
          <label style={{marginRight: '15px'}}>Acta</label>
        </div>
        <Input
          type="number"
          className="no-spinner"
          {...register("nroActa", {
            onBlur: () => handleFieldBlur('nroActa', setAmountValidation)
          })}
          style={{borderColor: amountValidation && 'red'}}/>
        <RequiredLabel show={amountValidation} text={"Ingrese un numero"}/>
      </FormGroup>
    </Form>
  )
}

export default SorteoActaForm
