import React from 'react';
import SalaIdInput from "../../../common/components/SalaIdInput";
import FormGroup from "../../../../components/forms/FormGroup";
import Input from "../../../../components/forms/FormEl";
import {Controller, useForm} from "react-hook-form";
import CategoriesSelect from "../../../common/components/selects/CategoriesSelect";
import {Apis} from "../../../../util/Apis";
import Card from "../../../../components/forms/Card";
import PageCardTitle from "../../../../components/layout/CardTitle";
import Button, {ButtonBox} from "../../../../components/forms/Button";
import Table from "react-bootstrap/Table";
import axios from "axios";
import useEditModal from "../../../../modals/useEditModal";
import GenericModal2 from "../../../../modals/GenericModal2";
import useDeleteModal from "../../../../modals/useDeleteModal";
import DeleteGenericModal from "../../../../modals/DeleteGenericModal";
import ProductVariantEdit from "./product-variant/ProductVariantEdit";
import ProductVariantNew from "./product-variant/ProductVariantNew";
import useFetch2 from "../../../../hooks/useFetch2";
import {useParams} from "react-router";
import SwitchToggle from "../../../../components/layout/SwitchToggle";
import NoDataTable from "../../../common/components/NoDataTable";
import {Col, Row} from "../../../../components/flex/FlexLayout";
import {FileUploaderV2} from "../../../common/components/fileuploader/FileUploaderV2";

const FoodProductForm = ({value, onSubmit, mode, fetchData}) => {

  const {id} = useParams()

  const {register, handleSubmit, reset, control} = useForm({defaultValues: value})

  const {data: productsVariant} = useFetch2(Apis.FOOD + '/food-variant?foodId=' + id)
  const deleteElement = id => axios.delete(Apis.FOOD + '/food-variant/' + id)

  const {wrapper: wrapper1, addAction, editAction} = useEditModal({
    editComponent: <ProductVariantEdit/>,
    addComponent: <ProductVariantNew/>,
    onRefresh: () => {
      mode && fetchData()
    }
  })

  const {
    wrapper: wrapper2,
    openDeleteModal
  } = useDeleteModal(
    {
      text: '¿Desea eliminar esta variante?',
      action: deleteElement,
      onRefresh: () => {
        mode && fetchData()
      }
    })

  return (
    <>
      <Card padding>
        <Controller name="photoUrl" control={control} render={({field}) =>
          <FileUploaderV2
            folder={'foods'}
            {...field}
            baseURL={Apis.FILE}
            assetsURL={process.env.REACT_APP_S3_WARGOS_ASSETS}/>}/>
        <SalaIdInput {...register("salaId")}/>
        <FormGroup>
          <SwitchToggle {...register("enabled")} id={"enabled"} label={"Habilitado"}/>
        </FormGroup>
        <FormGroup>
          <label>Nombre</label>
          <Input type='text' {...register("name")}/>
        </FormGroup>
        <FormGroup>
          <div style={{display: 'flex'}}>
            <label style={{marginRight: '15px'}}>Categoria</label>
          </div>
          <CategoriesSelect {...register("categoryId")}/>
        </FormGroup>
        <FormGroup>
          <Row>
            <Col>
              <div style={{display: 'flex'}}>
                <label style={{marginRight: '15px'}}>Stock</label>
              </div>
              <Input type='text' {...register("stock")}/>
            </Col>
            <Col>
              <div style={{display: 'flex'}}>
                <label style={{marginRight: '15px'}}>Precio de compra</label>
              </div>
              <Input type='text' {...register("purchasePrice")}/>
            </Col>
          </Row>
        </FormGroup>
      </Card>

      {mode &&
        <React.Fragment>
          <PageCardTitle title={'Variantes'}/>
          <Card padding>
            <Button title='Agregar' variant='primary' size='s' onClick={() => addAction()}/>
            <FormGroup style={{maxHeight: "500px", overflowY: "auto"}}>
              {
                productsVariant && productsVariant.length > 0 ?
                  <Table striped hover>
                    <thead>
                    <tr>
                      <th>Id</th>
                      <th>Nombre</th>
                      <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {productsVariant && productsVariant.map((item) =>
                      <tr key={item.id}>
                        <td>{item.id}</td>
                        <td>{item.name}</td>
                        <td>
                          <ButtonBox>
                            <Button variant={'light'} size='s' title={'Editar'} onClick={() => editAction(item.id)}/>
                            <Button variant={'danger'} size='s' title={'Eliminar'}
                                    onClick={() => openDeleteModal(item.id)}/>
                          </ButtonBox>
                        </td>
                      </tr>
                    )}
                    </tbody>
                  </Table> : <NoDataTable text={'Aún no hay variantes para este producto'}/>
              }
            </FormGroup>
          </Card>
        </React.Fragment>
      }

      <ButtonBox>
        <Button variant={'primary'} onClick={handleSubmit(onSubmit)}>Guardar</Button>
      </ButtonBox>

      <GenericModal2 {...wrapper1}/>
      <DeleteGenericModal {...wrapper2}/>
    </>
  );
};

export default FoodProductForm;
