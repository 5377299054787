import React from 'react'
import styled from 'styled-components'
import {BsExclamationCircleFill, BsX} from "react-icons/bs";
import {getContrastColor} from '../util/ContrastRatio';

const ContainerBanner = styled.div`
  display: flex;
  height: 60px;
  align-items: center;
  padding: 0px 16px;
  background: ${props => props.background || '#d4d4d4'};
  color: ${props => getContrastColor(props.background) || '#0A0A0A'};
  gap: 6px;
  transition: 0.3s all ease-in-out;
`

const BannerInfo = ({setShowBanner,background,text,title,closeBanner=true}) => {

  return (
    <ContainerBanner background={background}>
      <BsExclamationCircleFill size={20} />
      <span style={{flex: 1}}><strong>{title+': '}</strong>{text}</span>
      {closeBanner && 
        <BsX style={{cursor: 'pointer'}} size={24} onClick={() => setShowBanner(false)} />
      }
    </ContainerBanner>
  )
}

export default BannerInfo