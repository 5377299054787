import React from 'react';

const OnlineActive = ({valor}) => {

  let textColor = '#dad5d5'
  const today = new Date()
  const day = new Date(valor)
  const minutes = Math.round((today.getTime() - day.getTime()) / (1000 * 60))

  if (minutes <= 5) {
    textColor = '#0df10d'
  }

  return (
    <div className="type"
         style={{background: textColor, borderRadius: '50%', width: '20px', height: '20px', float: 'left'}}/>
  );
};

export default OnlineActive;
