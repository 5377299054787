import React, {useContext, useEffect} from 'react';
import {SiteContext} from "../../../../common/context/SiteContext";
import axios from "axios";
import {Apis} from "../../../../../util/Apis";
import CashExpenseForm from "./CashExpenseForm";

const CashExpenseNew = ({setTitle, setAction}) => {

  const {salaId} = useContext(SiteContext)

  const save = async (params) => {
    params.salaId = salaId
    await axios.post(Apis.CAJA + "/expenses-category", params)
  }

  useEffect(() => {
    setTitle("Nuevo Producto")
  }, [])

  return (
    <CashExpenseForm value={{}} onSubmit={save} setAction={setAction}/>
  );
};

export default CashExpenseNew;
