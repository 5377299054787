import React, {useEffect} from "react";
import {Apis} from "../../../../util/Apis";
import Card from "../../../../components/forms/Card";
import Table from "react-bootstrap/Table";
import useFetch2 from "../../../../hooks/useFetch2";
import NoDataTable from "../../../common/components/NoDataTable";
import Loader1 from "../../../common/components/Loader1";
import Moment from "react-moment";

const ExpiredHistory = ({setTitle}) => {


  const {loading, fetchData, error} = useFetch2(Apis.PC + '/sorteo-trace/by-sorteo?sorteoId=6231', []);

  useEffect(() => {
    setTitle("Ultimas Expiraciones");
  }, []);

  const data = [{
    fecha: "2021-11-16 18:02:34.828731", cantidad: "8", reali: "Renzo"
  }, {
    fecha: "2021-12-17 15:21:34.828731", cantidad: "15", reali: "Renato"
  }, {
    fecha: "2022-01-25 17:25:34.828731", cantidad: "24", reali: "CRRENZO"
  },

  ];


  return (<Card padding>
    <Table striped hover>
      <thead>
      <tr>
        <th>Fecha/Hora</th>
        <th>Cantidad de cupones expirados</th>
        <th>Realizado por :</th>
        <th/>
      </tr>
      </thead>
      <tbody>
      {data.map((value, index) => (
          <tr>
            <td><Moment format={'DD/MM/YYYY HH:mm'}>{value.fecha}</Moment></td>
            <td>{value.cantidad}</td>
            <td>{value.reali}</td>
          </tr>
      ))}
      </tbody>

    </Table>
    {!loading ? data.length <= 0 && <NoDataTable text={"Aún no hay Historial de Expiraciones"}/> : <Loader1/>}
  </Card>);
};

export default ExpiredHistory;
