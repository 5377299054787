import React from 'react'
import FormGroup from "../../../../components/forms/FormGroup";
import Form from "react-bootstrap/Form";

const GameForm = ({game, handleInputChange}) => {
  return (
    <Form>
      <FormGroup>
        <label>Nombre</label>
        <div className='input-group'>
          <input type='text' name='name'/>
        </div>
      </FormGroup>

      <Form.Group controlId="formBasicEmail">
        <Form.Label>Nombre</Form.Label>
        <Form.Control type="text" placeholder="Nombre" value={game.name} name='name' onChange={handleInputChange}/>
      </Form.Group>

      <Form.Group controlId="formBasicPassword">
        <Form.Label>Image</Form.Label>
        <Form.Control type="text" placeholder="Image" value={game.imgUrl} name='imgUrl'
                      onChange={handleInputChange}/>
      </Form.Group>

      <Form.Group controlId="formBasicPassword">
        <Form.Label>Background</Form.Label>
        <Form.Control type="text" placeholder="Background" value={game.backgroundUrl} name='backgroundUrl'
                      onChange={handleInputChange}/>
      </Form.Group>
    </Form>
  )

}

export default GameForm
