import React from 'react'
import {Select} from "../../../../components/forms/FormEl";

const MesaTypeSelect = React.forwardRef((props, ref) => {

  return (
    <Select ref={ref} {...props}>
      <option value={'POKER'}>Poker</option>
      <option value={'BLACK JACK'}>Black jack</option>
      <option value={'BACCARAT'}>Baccarat</option>
      <option value={'RULETA'}>Ruleta</option>
    </Select>
  )
})

export default MesaTypeSelect
