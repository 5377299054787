import React, {useEffect} from 'react';
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import FormGroup from "../../../../components/forms/FormGroup";
import Form from "react-bootstrap/Form";
import {Controller, useForm} from "react-hook-form";
import axios from "axios";
import {FileUploaderV2} from "../../../common/components/fileuploader/FileUploaderV2";
import {SelectV2} from "../../../../components/forms/FormEl";

const LayoutSvg = ({info, setAction, setTitle}) => {

  const {data, loading} = useFetch2(Apis.TGM + '/layout/frame-dimensions', [], {})

  const {handleSubmit, register, control, reset, getValues} = useForm();

  const postSvg = (params) => {
    params.fileId = info
    return axios.put(Apis.TGM + `/layout/frame-dimensions`, params)
  }

  const getData = (dataX) => {
    let convertedData = []
    if (dataX) {
      convertedData = dataX.map(item => {
        return {
          id: item.floorNumber,
          name: `Piso ${item.floorNumber}`
        }
      })
    }

    return convertedData
  }

  useEffect(() => {
    setAction(() => handleSubmit(postSvg))
  }, [])

  useEffect(() => {
    setTitle("Cargar plano")
  }, [])

  return (
    <Form>
      <Controller name="url" control={control} render={({field}) =>
        <FileUploaderV2
          folder={'floor'}
          {...field}
          baseURL={Apis.FILE}
          assetsURL={process.env.REACT_APP_S3_WARGOS_ASSETS}/>}/>
      <FormGroup>
        <label>Piso</label>
        {
          !loading &&
          <SelectV2 {...register("floorNumber")} options={getData(data)}/>
        }
      </FormGroup>
    </Form>
  );
};

export default LayoutSvg;
