import React, {useEffect} from "react";
import FormGroup from "../../../../../components/forms/FormGroup";
import {Controller, useForm} from "react-hook-form";
import Input, {Select} from "../../../../../components/forms/FormEl";
import DateTimeV2 from "../../../../../components/layout/DateTimeV2";
import BingoEventSelect from "../../../../common/components/selects/BingoEventSelect";
import useFetch2 from "../../../../../hooks/useFetch2";
import {Apis} from "../../../../../util/Apis";
import SwitchToggle from "../../../../../components/layout/SwitchToggle";
import useToggle from "../../../../../hooks/useToggle";
import Button, {ButtonBox} from "../../../../../components/forms/Button";
import Card from "../../../../../components/forms/Card";

const SorteoForm = ({value, onSubmit}) => {

  const {register, handleSubmit, reset, setValue, control, watch} = useForm();

  const {isOpen: lineDisabled, toggler: togglerSwitch1} = useToggle({});

  const {isOpen: doubleDisabled, toggler: togglerSwitch2} = useToggle({
    onOpen: (currentId) => (setValue('doubleLinePrize', null)),
    onClose: () => (console.log(doubleDisabled))
  });

  const watchEvent = watch("eventId")

  const {data: dataEvent, fetchData: fetchEvent} = useFetch2(Apis.BINGO + `/event/${watchEvent}`, [], {}, false);

  useEffect(() => {
    if (value) {
      reset(value)
      // console.log(value.sorteo)
      // console.log(!value.sorteo.linePrize, !lineDisabled, !value.sorteo.doubleLinePrize, !doubleDisabled)
      if (value && !value.linePrize) {
        if (!lineDisabled) togglerSwitch1()
      }
      if (value && !value.doubleLinePrize) {
        if (!doubleDisabled) togglerSwitch2()
      }
    }
  }, [value])

  useEffect(() => {
    if (watchEvent > 0) {
      fetchEvent()
    }
  }, [watchEvent])

  useEffect(() => {
    if (dataEvent) {
      setValue('eventType', dataEvent.dinamic)
    }
  }, [dataEvent])

  return (
    <>
      <Card padding>
        <FormGroup>
          <label>Fecha de inicio</label>
          <Controller name="startDate" control={control} render={({field}) => <DateTimeV2 {...field} />}/>
        </FormGroup>
        <div style={{display: 'flex', flexDirection: 'row', gap: 10}}>
          <FormGroup>
            <label>Evento</label>
            <Controller name="eventId" control={control} render={({field}) => <BingoEventSelect {...field} />}/>
          </FormGroup>
          <FormGroup>
            <label>Dinamica de premio</label>
            <Select disabled={dataEvent != null} value={dataEvent && dataEvent.dinamic}>
              <option key={1} value={1}>FIJO</option>
              <option key={2} value={2}>VARIABLE</option>
            </Select>
          </FormGroup>
        </div>
        <div style={{display: 'flex', flexDirection: 'row', gap: 10}}>
          <FormGroup>
            <label>Premio base</label>
            <Input type='number' {...register("basePrize")}/>
          </FormGroup>
          <FormGroup>
            <label>Total</label>
            <Input disabled={true} type='number' {...register("totalAmount")}/>
            {
              value.sorteo && value.totalAmount > 0 && (value.totalAmount < value.basePrize) &&
              <p style={{color: 'red'}}>Faltante para base
                : {(value.basePrize - value.totalAmount).toFixed(2)}</p>
            }
          </FormGroup>
        </div>
        <div style={{display: 'flex', flexDirection: 'row', gap: 10}}>
          <FormGroup>
            <SwitchToggle defaultChecked={!value.sorteo?.linePrize} checked={!lineDisabled} onClick={togglerSwitch1}/>
            <label>Premio linea</label>
            <Input disabled={lineDisabled} type='number' {...register("linePrize")}/>
          </FormGroup>
          <FormGroup>
            <SwitchToggle defaultChecked={!value.sorteo?.doubleLinePrize} checked={!doubleDisabled}
                          onClick={togglerSwitch2}/>
            <label>Premio doble linea</label>
            <Input disabled={doubleDisabled} type='number' {...register("doubleLinePrize")}/>
          </FormGroup>
        </div>
        <div style={{display: 'flex', flexDirection: 'row', gap: 10}}>
          <FormGroup>
            <label>Tipo de linea</label>
            <Select {...register("lineType")}>
              {dataEvent?.type === 75 && <option key={1} value={1}>Vertical</option>}
              <option key={2} value={2}>Horizontal</option>
            </Select>
          </FormGroup>
          <FormGroup>
            <label>Precio de carton</label>
            <Input type='number' {...register("bingoCardPrice")}/>
          </FormGroup>
        </div>
        <FormGroup>
          <label>Cartilla completa</label>
          {
            dataEvent?.dinamic === 1 ?
              <Input type='number' {...register("blackOut")}/>
              :
              <Input disabled={true} type='number'
                     value={value?.totalAmount - (value?.linePrize + value?.doubleLinePrize)}/>
          }
        </FormGroup>
      </Card>

      <ButtonBox>
        <Button variant='primary' onClick={handleSubmit(onSubmit)}>Guardar</Button>
      </ButtonBox>
    </>
  )
}

export default SorteoForm
