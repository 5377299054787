import React, { useContext, useEffect } from "react";
import axios from "axios";
import PuntosConfigurationForm from "./PuntosConfigurationForm";
import { SiteContext } from "../../../common/context/SiteContext";
import { Apis } from "../../../../util/Apis";

const PuntosConfiguracionNew = ({ setAction, setTitle }) => {

  const { salaId } = useContext(SiteContext)

  const save = (params) => {
    params.salaId = salaId
    axios.post(Apis.PC + '/point-rules', params)
  }

  useEffect(() => {
    setTitle("Nueva configuración de puntos")
  }, [])

  return (
    <PuntosConfigurationForm value={{}} onSubmit={save} setAction={setAction} />
  )
}

export default PuntosConfiguracionNew
