import React, {useContext, useState} from "react";
import {useHistory, useParams} from "react-router";
import PageContainer from "../../../../../components/layout/PageContainer";
import PageSection from "../../../../../components/layout/PageSection";
import {Title, Button} from "@wargostec/react-components";
import MachineForm from "./MachineForm";
import {PageBackLabel} from "../../../../../components/layout/PageBackLabel";
import {Apis} from "../../../../../util/Apis";
import useCrud from "../../../../../hooks/useCrud";
import Loader1 from "../../../../common/components/Loader1";
import useFetch2 from "../../../../../hooks/useFetch2";
import {SiteContext} from "../../../../common/context/SiteContext";
import {errorToast, successToast} from "../../../../../components/layout/ToastStyles";
import {FcNext, FcPrevious} from "react-icons/fc";
import {ButtonBox} from "../../../../../components/forms/Button";
import tgmApi from "../../../../../apis/tgmApi";

const MachineEdit = () => {

  const {salaId} = useContext(SiteContext)

  const history = useHistory()

  const {id} = useParams()

  const {data, loading} = useFetch2(Apis.TGM + '/machines/' + id, []);

  const machineApi = useCrud(Apis.TGM + '/machines');

  const [loadingAction, setLoadingAction] = useState(false)

  const onUpdate = (machine) => {
    let output = {...data, ...machine}
    setLoadingAction(true)
    machineApi.put(id, output).then(res => {
      setLoadingAction(false)
      successToast("Máquina actualizada correctamente",2000)
      history.push('/tgm/machines')
    }).catch(err => {
      errorToast(err.response.data.message ? err.response.data.message : "Ocurrió un problema. Por favor intenta nuevamente",2000)
      setLoadingAction(false)
    });
  }

  const machinePrevRoute = (prevConfId) => {
    tgmApi.previousMachine(prevConfId, salaId)
      .then(res => {
        history.push('/tgm/machines/edit/' + res.data.id)
      }).catch(err => {
      errorToast('Hubo un error ')
    })
  }

  const machineNextRoute = (nextConfId) => {
    tgmApi.nextMachine(nextConfId, salaId)
      .then(res => {
        history.push('/tgm/machines/edit/' + res.data.id)
      }).catch(err => {
      errorToast('Hubo un error ')
    })
  }

  return (
    <PageContainer>
      <PageSection>
        <PageBackLabel text='Máquinas' onClick={() => history.goBack()}/>
          <Title text="Edición de máquina"/>
        <ButtonBox>
          <Button variant={'light'} onClick={() => machinePrevRoute(data.machine)}
                  icon={<FcPrevious/>}/>
          <Button variant={'light'} onClick={() => machineNextRoute(data.machine)}
                  icon={<FcNext/>}/>
        </ButtonBox>

        {loading ?
          <Loader1/>
          :
          <MachineForm value={data} onSubmit={(machine) => onUpdate(machine)} loading={loadingAction}/>
        }
      </PageSection>
    </PageContainer>
  )
}
export default MachineEdit
