import React, {useContext, useState} from 'react'
import PageSection from "../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import {SiteContext} from "../../../common/context/SiteContext";
import PageContainerFull from "../../../../components/layout/PageContainerFull";
import Card from "../../../../components/forms/Card";
import {Apis} from "../../../../util/Apis";
import useFetch2 from '../../../../hooks/useFetch2';
import Loader1 from "../../../common/components/Loader1";
import {CardTitle, IconCard} from '../coupons/PrinterDetail';
import Printing from "../../../../components/images/printing.svg"
import axios from "axios";
import {useParams} from "react-router";

const OnePrinterDetail=() =>{
  const {salaId} = useContext(SiteContext)

  const {id} = useParams()
  const [params, setParams] = useState()
  const {loading, data, fetchData} = useFetch2(Apis.PRINT+`/printjobs?printerId=${id}`, [],params,true);

  const lockAndUnlock = (id, locked) => {
    if (!locked) {
      axios.post(Apis.PRINT + '/printers/' + id + '/enable')
        .then(resp =>
          (console.log(resp))
        )
    } else {
      axios.post(Apis.PRINT + '/printers/' + id + '/disable')
        .then(resp =>
          (console.log(resp))
        )
    }

    //setLockedChanged(lockedChanged + 1)
  }

  const redirectTo = (id) => {
    alert("redirecting..")

  }

  return(
    <PageContainerFull>
      <PageSection>
          <Title text={data.printerName}/>
      </PageSection>

      <PageSection>
     
      { !loading ?
      
      data.printJobs.map(item =>
      <div>
        <Card padding >
          <CardTitle> 
            <IconCard src={Printing} alt="image"></IconCard>
            {item.title} 
          </CardTitle>
        </Card>
      </div>

      )
      :
      <Loader1/>
      }
      </PageSection>
    </PageContainerFull>
  )
}

export default OnePrinterDetail;
