import {useState} from "react";
import {errorToast} from "../components/layout/ToastStyles";

const useLoadingToastr = (action, errorTxt) => {
  const [loading, setLoading] = useState(false);

  const doAction = (...args) => {
    setLoading(true);
    return action(...args).catch((e) => errorToast(errorTxt)).finally(() => setLoading(false));
  };

  return [doAction, loading];
};

export default useLoadingToastr;
