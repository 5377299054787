import React, {useContext} from "react";
import {Apis} from "../../../../../util/Apis";
import axios from "axios";
import PageContainer from "../../../../../components/layout/PageContainer";
import PageSection from "../../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import Button, {ButtonBox} from "../../../../../components/forms/Button";
import {FormBlock} from "../../../../../components/forms/FormGroup";
import Table from "react-bootstrap/Table";
import DeleteGenericModal from "../../../../../modals/DeleteGenericModal";
import RelacionAsociadosNew from "./RelacionAsociadosNew";
import {SiteContext} from "../../../../common/context/SiteContext";
import RelacionAsociadosEdit from "./RelacionAsociadosEdit";
import useEditModal from "../../../../../modals/useEditModal";
import useDeleteModal from "../../../../../modals/useDeleteModal";
import GenericModal2 from "../../../../../modals/GenericModal2";
import useFetch2 from "../../../../../hooks/useFetch2";

const RelacionAsociadosList = () => {

  const {salaId} = useContext(SiteContext)

  const {data, fetchData} = useFetch2(Apis.TGM + '/partners-relation?salaId=' + salaId, []);

  ////////////////////////
  //hooks para crear,editar y eliminar
  const {wrapper: wrapper1, addAction, editAction} = useEditModal({
    editComponent: <RelacionAsociadosEdit/>,
    addComponent: <RelacionAsociadosNew/>,
    onRefresh: fetchData
  })

  const deleteElement = id => axios.delete(Apis.TGM + '/partners-relation/' + id)

  const {wrapper: wrapper2, openDeleteModal} = useDeleteModal({
    text: '¿Desea eliminar esta asociación?',
    action: deleteElement,
    onRefresh: fetchData
  })

  return (
    <PageContainer>
      <PageSection>
          <Title text="Relación de asociados" type={'page'}/>
        <ButtonBox>
          <Button variant={'primary'} title={'Nuevo'} onClick={() => addAction()}/>
        </ButtonBox>
      </PageSection>

      <PageSection>
        <FormBlock>
          <Table striped hover>
            <thead>
            <tr>
              <th>Sala</th>
              <th>Propietario</th>
              <th>Porcentaje (%)</th>
              <th>Tipo Contrato</th>
              <th/>
            </tr>
            </thead>
            <tbody>
            {data && data.map(item =>
              <tr key={item.id}>
                <td>{item.sala.name}</td>
                <td>{item.owner.name}</td>
                <td>{item.percentage}</td>
                <td>{item.tipoContrato}</td>
                <td>
                  <ButtonBox>
                    <Button size={'s'} onClick={() => editAction(item.id)}>Editar</Button>
                    <Button variant="danger" size={'s'} onClick={() => openDeleteModal(item.id)}>Eliminar</Button>
                  </ButtonBox>
                </td>
              </tr>
            )}
            </tbody>
          </Table>
        </FormBlock>
      </PageSection>
      <GenericModal2 {...wrapper1}/>
      <DeleteGenericModal {...wrapper2}/>

    </PageContainer>
  )
}

export default RelacionAsociadosList;
