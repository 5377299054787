import React, {useContext, useEffect, useState} from "react";
import PageSection from "../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import Card from "../../../../components/forms/Card";
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import {SiteContext} from "../../../common/context/SiteContext";
import moment from "moment/moment";
import Button, {ButtonBox} from "../../../../components/forms/Button";
import SalaIdInput from "../../../common/components/SalaIdInput";
import FormGroup from "../../../../components/forms/FormGroup";
import {DatePickerCustom} from "../../../../components/forms/FormEl";
import Slidebar from "../../../common/layout/Slidebar";
import {useForm} from "react-hook-form";
import {NumberRound} from "../../../../components/util/NumberUtil";
import {successToast} from "../../../../components/layout/ToastStyles";
import DateFilter from "../../../../components/datefilter/DateFilter";
import ProduccionTable from "./ProduccionTable";
import {FaCube, FaCubes} from "react-icons/fa";
import {serializeDownload} from "../../../../components/util/SerializeParams";
import {useHistory} from "react-router"
import Container from 'react-bootstrap/Container'
import useLoadingToastr from "../../../../hooks/useLoadingToastr";
import tgmApi from "../../../../apis/tgmApi";
import AsyncBlock from "../../../../components/forms/AsyncBlock";
import CardOverview, {CardOverviewContainer} from "../../../../components/layout/CardOverview";
import {BsCashCoin} from "react-icons/bs";
import {MdOutlineBrokenImage} from "react-icons/md";

const ProduccionTotal = () => {

  const {salaId} = useContext(SiteContext)
  const [slideOpen, setSlideOpen] = useState(false)
  const initialDate = moment(new Date()).format('YYYY-MM-DD')
  const history = useHistory();

  const querystring = window.location.search
  const query = new URLSearchParams(querystring)
  let iniQueryParams = query.get('ini')
  let finQueryParams = query.get('fin')

  if (iniQueryParams === null) {
    iniQueryParams = initialDate
  }

  if (finQueryParams === null) {
    finQueryParams = initialDate
  }

  const defaultParams = {salaId: salaId, ini: iniQueryParams, fin: finQueryParams}
  const [params, setParams] = useState(defaultParams)

  const {loading, data, error, fetchData} = useFetch2(Apis.TGM + '/deltas', [], params);

  const {
    register,
    handleSubmit,
  } = useForm({defaultValues: defaultParams});

  const getData = (params) => {
    setParams(params)
  }

  const fechasDefault = {ini: iniQueryParams, fin: finQueryParams}
  const [fechas, setFechas] = useState(fechasDefault)

  const recalc = async () => {
    await tgmApi.meterSnapAnalize({salaId, ini: params.ini, fin: params.fin})
    successToast('Produccion recalculada')
    await fetchData()
  }

  const [getRecalc, loadingRecalc] = useLoadingToastr(recalc, 'Hubo un error al recalcular')

  const sync = async () => {
    await tgmApi.deltasSync(salaId, {salaId, ini: params.ini, fin: params.fin})
    successToast('Produccion sincronizada')
    await fetchData()
  }

  const [getSync, loadingSync] = useLoadingToastr(sync, 'Hubo un error al sincronizar')

  const download = () => {
    window.location.href = Apis.TGM + `/deltas/report.xlsx?${serializeDownload(params)}`;
  }

  const changeRoute = () => {
    history.push('/tgm/produccion-total?ini=' + fechas.ini + '&fin=' + fechas.fin)
  }

  useEffect(() => {
    if (params) fetchData().catch((error) => console.log('Error en fetch: ', error))
    changeRoute()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  useEffect(() => {
    setParams({...params, ...fechas})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fechas])


  return (
    <Container fluid>
      <Slidebar visible={slideOpen} onHide={() => setSlideOpen(false)}>
        <div style={{padding: '16px'}}>
          <SalaIdInput {...register("salaId")} />

          <FormGroup>
            <label>Desde</label>
            <DatePickerCustom {...register("ini")} />
          </FormGroup>

          <FormGroup>
            <label>Hasta</label>
            <DatePickerCustom {...register("fin")} />
          </FormGroup>

          <Button variant={'primary'} title='Buscar' onClick={handleSubmit(getData)}/>
        </div>
      </Slidebar>

      <PageSection>
        <Title text="Producción" type={'page'}/>
        <ButtonBox>
          <Button variant={'primary'} onClick={getRecalc} disabled={loadingRecalc}>
            Recalcular
          </Button>
          <Button variant={'primary'} onClick={getSync} disabled={loadingSync}>
            Sincronizar
          </Button>
        </ButtonBox>
        <Card padding style={{background: '#0A0A0A'}}>
          <DateFilter fechas={fechas} setFechas={setFechas}/>
        </Card>

        <AsyncBlock loading={loading} error={error}>

          <CardOverviewContainer gridColumns='repeat(5, 1fr)'>
            <CardOverview
              title={data?.cantidad}
              subtitle='Máquinas'
              icon={<FaCubes size={20}/>}
            />
            <CardOverview
              title={<NumberRound>{data?.ciTot}</NumberRound>}
              subtitle='CoinIn'
              icon={<BsCashCoin size={20}/>}
            />
            <CardOverview
              title={<NumberRound>{data.prodTot}</NumberRound>}
              subtitle='Producción'
              icon={<BsCashCoin size={20}/>}
            />
            <CardOverview
              title={data?.cereos}
              subtitle='Cereos'
              icon={<MdOutlineBrokenImage size={20}/>}
            />
            <CardOverview
              title={<NumberRound>{data.prodTot / data.cantidad}</NumberRound>}
              subtitle='Producción x maq'
              icon={<FaCube size={20}/>}
            />
          </CardOverviewContainer>
        </AsyncBlock>
        {
          data?.data &&
          <>
            <ProduccionTable
              loading={loading}
              error={error}
              setSlideOpen={setSlideOpen}
              slideOpen={slideOpen}
              download={download}
              data={data.data.filter(item => ((item.prod * item.den) < -20000) || (item.prod * item.den > 20000))}
              showDetailPerMachine={true}
            />

            <ProduccionTable
              loading={loading}
              error={error}
              data={data?.data}
              setSlideOpen={setSlideOpen}
              slideOpen={slideOpen}
              download={download}
              showDetailPerMachine={true}
              pageSize={20}
            />
          </>
        }
      </PageSection>
    </Container>
  )
}

export default ProduccionTotal;
