import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import React from "react";
import {Select, SelectV2} from "../../../../components/forms/FormEl";

const ProductManualRedeemSelect = React.forwardRef((props, ref) => {

  let defaultParams = {enabled: true}
  const {data, loading} = useFetch2(Apis.PC + '/catalog-products/active-inactive', [], defaultParams)

  return (
    <>
      {
        !loading ?
          <SelectV2 ref={ref} options={data} {...props}/>
          :
          <Select disabled={true}>
            <option key={0} value={0}>{"Cargando..."}</option>
          </Select>
      }
    </>
  )
})

export default ProductManualRedeemSelect;
