import React, {useContext, useEffect, useState} from 'react'
import {Select} from "../../../../components/forms/FormEl";

const MeterPointSelect = React.forwardRef((props, ref) => {

  return (
    <Select ref={ref} {...props}>
      <option value={1}>APOSTADO</option>
      <option value={2}>GANADO</option>
      <option value={3}>JACKPOT</option>
      <option value={20}>PUNTOS</option>
      <option value={12}>APOSTADO MESAS</option>
      <option value={13}>GANADO MESAS</option>
      <option style={{display:"none"}} selected value={""}/>
    </Select>
  )
})

export default MeterPointSelect;
