import React, {useContext, useEffect} from 'react';
import PublicidadForm from './PublicidadForm';
import axios from 'axios';
import {SiteContext} from '../../../common/context/SiteContext';
import {Apis} from '../../../../util/Apis';
import {getDateFormat, putDateFormat} from "../recompensas/RewardsEdit";
import useFetch2 from "../../../../hooks/useFetch2";

const PublicidadEdit = ({id, setAction, setTitle}) => {
  const {loading, data} = useFetch2(Apis.PC + '/ads-banners/' + id);
  const {salaId} = useContext(SiteContext)

  function update(params) {
    params.salaId = salaId
    params.id = id
    putDateFormat(params)
    return axios.put(Apis.PC + '/ads-banners/' + id, params);
  }

  useEffect(() => {
    setTitle('Edición de publicidad');
  });

  return (
    <React.Fragment>
      {!loading && data && <PublicidadForm value={data && getDateFormat(data)} onSubmit={update}
                                           setAction={setAction}/>}
    </React.Fragment>
  )
};
export default PublicidadEdit;
