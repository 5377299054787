import { useState, cloneElement } from 'react';
import useToggle from '../hooks/useToggle';

// *(Renzo B.) Si no queremos usar el onRefresh, no se pasa como parametro
const useEditModal = ({ editComponent, addComponent, onRefresh = () => { } }) => {
	const { isOpen, toggler } = useToggle({
		onClose: onRefresh
	});
	const [body, setBody] = useState(null);

	const addAction = (info) => {
		toggler();
		setBody(cloneElement(addComponent, { info }));
	};

	const editAction = (id) => {
		toggler();
		setBody(cloneElement(editComponent, { id }));
	};

	const wrapper = {
		show: isOpen,
		onHide: toggler,
		body: body
	};

	return { wrapper, refresh: false, addAction, editAction };
};

export default useEditModal;
