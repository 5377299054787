import React from "react";
import Table from "react-bootstrap/Table";
import Button, {ButtonBox} from "../../../../../components/forms/Button";
import {useHistory, useParams} from "react-router";
import axios from "axios";
import DeleteGenericModal from "../../../../../modals/DeleteGenericModal";
import useDeleteModal from "../../../../../modals/useDeleteModal";
import NoDataTable from "../../../../common/components/NoDataTable";
import Loader1 from "../../../../common/components/Loader1";
import useFetch2 from "../../../../../hooks/useFetch2";

const GroupPolicies = () => {


  const history = useHistory();
  const {groupName, id} = useParams()
  const addPoliciesPage = () => {
    const parentRoute = '/admin'
    history.push(parentRoute + `/groups/edit/${id}/${groupName}/add-policies`)
  }

  const {data, loading, fetchData} = useFetch2(`http://localhost:9081/user-group/${id}/policies`, [], null, false)

  const deleteElement = policyId => axios.delete(`http://localhost:9081/user-group/${id}/policies/${policyId}`)

  const {wrapper: wrapper2, openDeleteModal} = useDeleteModal({
    text: '¿Desea retirar política del grupo?',
    action: deleteElement,
    onRefresh: fetchData
    })


  return (
    <React.Fragment>
      <Button title={"Añadir Políticas"} variant={'primary'} style={{marginTop: "25px", marginBottom: "20px"}}
              onClick={() => addPoliciesPage()}/>
      <Table striped hover>
        <thead>
        <tr>
          <th style={{width: "10%"}}>ID</th>
          <th>Nombre</th>
          <th/>
        </tr>
        </thead>
        <tbody>
        {data.data && data.data.map(item =>
          <tr key={item.id}>
            <td>{item.id}</td>
            <td>{item.name}</td>
            <td style={{display: "flex", justifyContent: "flex-end"}}>
              <ButtonBox>
                <Button onClick={() => openDeleteModal(item.id)}>Quitar</Button>
              </ButtonBox>
            </td>
          </tr>
        )}
        </tbody>
      </Table>
      {!loading ? data.data.length <= 0 && <NoDataTable text={"Este grupo no presenta políticas"}/> : <Loader1/>}
      <DeleteGenericModal {...wrapper2}/>
    </React.Fragment>
  )
}

export default GroupPolicies
