import React from "react";
import Registrar from "../Registrar";
import {Apis} from "../../../../../util/Apis";
import {useParams} from "react-router";
import useFetch2 from "../../../../../hooks/useFetch2";

const CustomerEdit = () => {

  const {id} = useParams()
  const {loading, data} = useFetch2(Apis.PC + '/customers/' + id)

  return (
    <React.Fragment>
      {!loading && <Registrar data={data}/>}
    </React.Fragment>
  )
};

export default CustomerEdit;
