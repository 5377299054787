import styled, {css} from 'styled-components'
import React from "react";

const withPadding = css`
  padding: 16px; 
`

const CardStyled = styled.div`
    box-sizing: border-box;
    margin-bottom: 16px;
    padding: 0;
    border-radius: 8px;
    background-color: #FFFFFF;
    transition: box-shadow .2s linear, border-color .2s linear, background-color .2s linear;
    ${({ padding }) => (padding ? withPadding : '')}
`
export const CardHeader = styled.div`
  padding: 16px;
  border-bottom: 0.5px solid rgb(234, 234, 234);
`
export const CardBody = styled.div`
  padding: 16px;
`
const Card = ({padding = false, style, children, onClick}) => {
  return (
    <CardStyled style={style} padding={padding} onClick={onClick}>
      {children}
    </CardStyled>    
  )
}

export default Card
