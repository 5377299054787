import React, { useState } from 'react';
import PageSection from "../../../../components/layout/PageSection";
import Card, { CardBody, CardHeader } from "../../../../components/forms/Card";
import { Apis } from "../../../../util/Apis";
import { Title } from "@wargostec/react-components";
import Chart from "react-apexcharts";
import { FaUsers } from "react-icons/fa";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import moment from "moment";
import AsyncBlock from "../../../../components/forms/AsyncBlock";
import { getCasinoDay } from "../../../../components/util/CasinoDates";
import DateFilter from "../../../../components/datefilter/DateFilter";
import CardOverview, { CardOverviewContainer } from '../../../../components/layout/CardOverview';
import useGet from '../../../../hooks/useGet';
import { generateDistrics, generateDonut, generateLabels } from '../../../../util';

const CustomerResume = () => {

  const y = moment(new Date()).year()

  const firstDayOfYear = moment(new Date(y - 1, 0, 1)).format('YYYY-MM-DD');
  const lastDayOfYear = moment(new Date(y, 11, 31)).format('YYYY-MM-DD');
  const casinoDay = getCasinoDay()
  const fechasDefault = { ini: casinoDay, fin: casinoDay }
  const [fechas, setFechas] = useState(fechasDefault)

  let defaultParams = { enabled: true, ini: fechas.ini, fin: fechas.fin }

  const notDemographicParams = { enabled: true, ini: firstDayOfYear, fin: lastDayOfYear }

  const [params, setParams] = useState(defaultParams)

  const { loading, data, fetchData, error } = useGet({
    url: Apis.PC + '/customers/genders-by-salaid',
    initialValue: [],
    params: params
  })

  const { data: data2, fetchData: fetchData2, loading: loading2 } = useGet({
    url: Apis.PC + '/customers/ages-by-salaid',
    initialValue: [],
    params: params
  })

  const { data: data3, fetchData: fetchData3, loading: loading3 } = useGet({
    url: Apis.PC + '/customers/active-registered-summary',
    initialValue: [],
    params: notDemographicParams
  })

  const { data: data4, fetchData: fetchData4, loading: loading4 } = useGet({
    url: Apis.PC + '/session-day',
    initialValue: [],
    params: notDemographicParams
  })

  const { data: data5, fetchData: fetchData5, loading: loading5 } = useGet({
    url: Apis.PC + '/customers/distrito-by-sala',
    initialValue: [],
    params: params
  })

  const handleChangeDates = (fechas) => {
    const newParams = { ...params, ...fechas }
    setFechas(fechas)
    setParams(newParams)
    if (newParams && newParams.ini && newParams.fin) {
      fetchData(newParams)
      fetchData2(newParams)
      fetchData5(newParams)
    }
  }

  let fill = {
    opacity: 1
  }
  let plotOptions = {
    bar: {
      horizontal: false, borderRadius: 4
    },
  }
  let dataLabels = {
    enabled: false
  }
  let stroke = {
    show: true, width: 4, colors: ['transparent']
  }

  let legend = {
    show: true, showForSingleSeries: true
  }

  let tooltip = {
    intersect: false, shared: true, onDatasetHover: {
      highlightDataSeries: true,
    }, y: {
      formatter: function (val) {
        return "S/ " + val.toLocaleString() + " soles"
      }
    }
  }

  const donutGenders = {
    series: generateDonut(data).map(item => item.count), options: {
      chart: {
        type: 'donut', height: 400
      },
      legend: {
        position: 'bottom',
      },
      labels: generateDonut(data).map(item => item.name),
      plotOptions: {
        pie: {
          donut: {
            size: '50%'
          }
        }
      }
    },
  };

  const barAge = {
    options: {
      chart: {
        type: 'bar', height: 400
      }, plotOptions: {
        bar: {
          borderRadius: 4, horizontal: true
        },
      }, colors: ['#008FFB'], legend: legend, fill: fill, xaxis: {
        categories: generateLabels(data2)
      }
    }, series: [{
      name: "Clientes", data: data2.map(item => item.count)
    }]
  };

  const barDistric = {
    options: {
      chart: {
        type: 'bar', height: 400
      }, plotOptions: {
        bar: {
          borderRadius: 4, horizontal: true
        },
      }, colors: ['#ff4560'], legend: legend, fill: fill, xaxis: {
        categories: generateDistrics(data5) // [ene, fre mar , jun]
      }
    }, series: [{
      name: "Clientes", data: data5.map(item => item.cantidad)
    }]
  };

  const barRegistered = {
    options: {
      chart: {
        type: 'bar', height: 400
      },
      plotOptions: plotOptions,
      dataLabels: dataLabels,
      stroke: stroke,
      fill: fill,
      colors: ['#008FFB', '#00E396'],
      tooltip: {
        intersect: false, shared: true, onDatasetHover: {
          highlightDataSeries: true,
        }, y: {
          formatter: function (val) {
            return val + " Clientes"
          }
        }
      },
      xaxis: {
        categories: data3.map(item => item.yearMonthTxt)
      }
    }, series: [{
      name: "Activos", data: data3.map(item => item.activeCustomers)
    }, {
      name: "Registrados", data: data3.map(item => item.registeredCustomers)
    },]
  };

  const barCoin = {
    options: {
      chart: {
        type: 'bar', height: 400
      },
      plotOptions: plotOptions,
      dataLabels: dataLabels,
      stroke: stroke,
      fill: fill,
      colors: ['#FEB019', '#FF4560'],
      tooltip: tooltip,
      xaxis: {
        categories: data4.map(item => item.monthYearTxt) // [ene, fre mar , jun]
      }
    }, series: [{
      name: "Logueados", data: data4.map(item => item.ciLogged)
    }, {
      name: "Total", data: data4.map(item => item.ciTotal)
    },]
  };

  const barNetwin = {
    options: {
      chart: {
        type: 'bar', height: 400
      },
      plotOptions: plotOptions,
      dataLabels: dataLabels,
      stroke: stroke,
      fill: fill,
      colors: ['#008FFB', '#00E396'],
      tooltip: tooltip,
      xaxis: {
        categories: data4.map(item => item.monthYearTxt) // [ene, fre mar , jun]
      }
    }, series: [{
      name: "Total", data: data4.map(item => item.netwinAnom)
    }, {
      name: "Logueados", data: data4.map(item => item.netwinLogged)
    },]
  };

  const barCoverage = {
    options: {
      chart: {
        type: 'bar', height: 400
      },
      plotOptions: plotOptions,
      dataLabels: dataLabels,
      stroke: stroke,
      fill: fill,
      legend: legend,
      colors: ['#775DD0'],
      tooltip: {
        intersect: false, shared: true, onDatasetHover: {
          highlightDataSeries: true,
        }, y: {
          formatter: function (val) {
            return val + " %"
          }
        }
      },
      xaxis: {
        categories: data4.map(item => item.monthYearTxt) // [ene, fre mar , jun]
      }
    }, series: [{
      name: "Cobertura", data: data4.map(item => (item.ciCoverage * 100).toFixed(2))
    },]
  };

  return (
    <Container fluid>
      <PageSection>
        <Title text="Resumen" type={'page'} />
      </PageSection>

      <PageSection>
        <Card padding style={{ background: '#0A0A0A' }}>
          <DateFilter fechas={fechas} setFechas={handleChangeDates} />
        </Card>
      </PageSection>

      <PageSection>
        <Row>
          <AsyncBlock loading={loading} error={error}>
            <CardOverviewContainer gridColumns='repeat(3, 1fr)'>
              <CardOverview
                title={data.filter(item => item.name === 'Total').map(item => item.count)}
                subtitle='Clientes registrados'
                icon={<FaUsers size={20} />}
              />
            </CardOverviewContainer>
          </AsyncBlock>
        </Row>

        <Row>
          <AsyncBlock loading={loading && loading5 && loading2} error={error}>
            <Col xs={12} md={4}>
              <Card>
                <CardHeader>
                  <strong>Distribución por genero</strong>
                </CardHeader>
                <CardBody>
                  <Chart
                    options={donutGenders.options}
                    series={donutGenders.series}
                    type="donut"
                    height="445"
                  />
                </CardBody>
              </Card>
            </Col>

            <Col xs={12} md={4}>
              <Card>
                <CardHeader>
                  <strong>Distribución por edad</strong>
                </CardHeader>
                <CardBody>
                  <Chart
                    options={barAge.options}
                    series={barAge.series}
                    type="bar"
                    height="400"
                  />
                </CardBody>
              </Card>
            </Col>

            <Col xs={12} md={4}>
              <Card>
                <CardHeader>
                  <strong>Distribución por Distrito</strong>
                </CardHeader>
                <CardBody>
                  <Chart
                    options={barDistric.options}
                    series={barDistric.series}
                    type="bar"
                    height="400"
                  />
                </CardBody>
              </Card>
            </Col>
          </AsyncBlock>

          <Col xs={12} md={12}>
            <AsyncBlock loading={loading3} error={error}>
              <Card>
                <CardHeader>
                  <strong>Clientes activos y registrados</strong>
                </CardHeader>
                <CardBody>
                  <Chart
                    options={barRegistered.options}
                    series={barRegistered.series}
                    type="bar"
                    height="500"
                  />
                </CardBody>
              </Card>
            </AsyncBlock>
          </Col>

          <AsyncBlock loading={loading4} error={error}>
            <Col xs={12} md={6}>
              <Card>
                <CardHeader>
                  <strong>Apostado Total vs Apostado de Logueados</strong>
                </CardHeader>
                <CardBody>
                  <Chart
                    options={barCoin.options}
                    series={barCoin.series}
                    type="bar"
                    height="400"
                  />
                </CardBody>
              </Card>
            </Col>

            <Col xs={12} md={6}>
              <Card>
                <CardHeader>
                  <strong>Netwin Total vs Netwin de Logueados</strong>
                </CardHeader>
                <CardBody>
                  <Chart
                    options={barNetwin.options}
                    series={barNetwin.series}
                    type="bar"
                    height="400"
                  />
                </CardBody>
              </Card>
            </Col>
          </AsyncBlock>

          <AsyncBlock loading={loading4} error={error}>
            <Col xs={12} md={12}>
              <Card>
                <CardHeader>
                  <strong>Cobertura</strong>
                </CardHeader>
                <CardBody>
                  <Chart
                    options={barCoverage.options}
                    series={barCoverage.series}
                    type="bar"
                    height="500"
                  />
                </CardBody>
              </Card>
            </Col>
          </AsyncBlock>

        </Row>
      </PageSection>
    </Container>);
};

export default CustomerResume;
