import React, {useEffect} from "react";
import {Apis} from "../../../../util/Apis";
import AlmacenForm from "./AlmacenForm";
import useFetch2 from "../../../../hooks/useFetch2";
import axios from "axios";


const AlmacenEdit = ({id, setAction, setTitle}) => {

  const {data} = useFetch2(Apis.TGM + '/machine-warehouse/' + id,[])

  const onEdit = (params) => {
    params.id = id
    return axios.put(Apis.TGM + '/machine-warehouse/' + id, params)
  }

  useEffect(() => {
    setTitle("Edición de almacen")
  })

  return (
    <AlmacenForm value={data} onSubmit={onEdit} setAction={setAction}/>
  )
}

export default AlmacenEdit
