import React, {useContext, useEffect, useState} from 'react';
import Card from "../../../../components/forms/Card";
import PageSection from "../../../../components/layout/PageSection";
import {SiteContext} from "../../../common/context/SiteContext";
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import {PageBackLabel} from "../../../../components/layout/PageBackLabel";
import {useHistory} from "react-router";
import SalaIdInput from "../../../common/components/SalaIdInput";
import FormGroup from "../../../../components/forms/FormGroup";
import {DatePickerCustom} from "../../../../components/forms/FormEl";
import Button from "../../../../components/forms/Button";
import Slidebar from "../../../common/layout/Slidebar";
import {useForm} from "react-hook-form";
import moment from "moment/moment";
import useToggle from "../../../../hooks/useToggle";
import {GridLayout} from '../../../../components/flex/Flex';
import {Table} from 'react-bootstrap';
import PageContainerFull from '../../../../components/layout/PageContainerFull';
import PageCardTitle from '../../../../components/layout/CardTitle';

const MesasReportByHour = () => {

  const history = useHistory();
  const {salaId} = useContext(SiteContext)

  const {isOpen: slideOpen, toggler} = useToggle({});
  const initialDate = moment(new Date()).format('YYYY-MM-DD')

  const querystring = window.location.search
  const query = new URLSearchParams(querystring)
  let iniQueryParams = query.get('ini')
  let finQueryParams = query.get('fin')

  let defaultParams = {
    salaId: salaId, ini: iniQueryParams ? moment(iniQueryParams).format('YYYY-MM-DD') : initialDate,
    fin: finQueryParams ? moment(finQueryParams).format('YYYY-MM-DD') : initialDate
  }

  const [params, setParams] = useState(defaultParams)

  const {data, fetchData} = useFetch2(Apis.TABLES + `/tables/report-by-hour`, [], defaultParams);

  const {
    register,
    handleSubmit,
  } = useForm({defaultValues: defaultParams});

  const getData = (params) => {
    setParams(params)
  }

  useEffect(() => {
    if (params) fetchData()
  }, [params])


  return (
    <PageContainerFull>

      <Slidebar visible={slideOpen} onHide={toggler}>
        <div style={{padding: '16px'}}>
          <SalaIdInput {...register("salaId")} />
          <FormGroup>
            <label>Desde</label>
            <DatePickerCustom {...register("ini")} />
          </FormGroup>

          <FormGroup>
            <label>Hasta</label>
            <DatePickerCustom {...register("fin")} />
          </FormGroup>

          <Button variant={'primary'} title='Buscar' onClick={handleSubmit(getData)} />
        </div>
      </Slidebar>

      <PageSection>
        <PageBackLabel text='Regresar' onClick={() => history.goBack()} />
        <PageCardTitle title={iniQueryParams} />
        {data.length === 0 ?
          <Card padding>
            <strong >No hay sesiones abiertas en el filtro seleccionado</strong>
          </Card>
          :
          <GridLayout gridTemplateColumns='1fr 1fr' gap='16px'>

            {data.map((item, i) =>
              <Card key={item.time + i}>
                <div style={{flex: 1, textAlign: 'center', width: '100%', padding: '8px', background: '#0a0a0a', color: '#fff', borderRadius: '6px'}}>
                  <strong>{item.time}</strong>
                </div>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th className='text-center'>Mesa</th>
                      <th className='text-center'>Drop</th>
                      <th className='text-center'>Relleno</th>
                      <th className='text-center'>Devoluciones</th>
                      <th className='text-center'>Win/Lose</th>
                      <th className='text-center'>Hold</th>
                      <th className='text-center'>Jugadores</th>
                    </tr>
                  </thead>
                  <tbody>
                    {item?.data.map((session) =>
                      <tr key={session.idFake}>
                        <td className='text-center'>{session.tableCode} - {session.tableName}</td>
                        <td className='text-center'>{session.coinInTotal === null ? 0 : session.coinInTotal}</td>
                        <td className='text-center'>{session.cashInTotal === null ? 0 : session.cashInTotal}</td>
                        <td className='text-center'>{session.cashOutTotal === null ? 0 : session.cashOutTotal}</td>
                        <td className='text-center'>{session.coinInTotal === null && session.coinOutTotal === null ? 0 : session.coinInTotal - session.coinOutTotal}</td>
                        <td className='text-center'>{session.coinInTotal === 0 || session.coinInTotal === null ? 0 : ((session.coinInTotal - session.coinOutTotal) / session.coinInTotal).toFixed(2)} %</td>
                        <td className='text-center'>{session.players === null ? 0 : session.players}</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Card>
            )}


          </GridLayout>
        }
      </PageSection>

    </PageContainerFull>
  );
};

export default MesasReportByHour;
