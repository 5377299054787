import React, {useState} from 'react'
import FormGroup from "../../../../components/forms/FormGroup";
import axios from 'axios'
import {useForm} from "react-hook-form";
import {Apis} from "../../../../util/Apis";
import Button from '../../../../components/forms/Button';
import {successToast} from "../../../../components/layout/ToastStyles";
import useLoadingToastr from "../../../../hooks/useLoadingToastr";
import Card from '../../../../components/forms/Card';
import {Form, InputGroup} from 'react-bootstrap';
import {BsEyeFill, BsEyeSlashFill} from 'react-icons/bs';

const ResetPassword = () => {
  const {register, handleSubmit, getValues, formState: {errors}} = useForm();

  const savePassword = async (params) => {
    try {
      await axios.post(`${Apis.USER}/users/change-password`, params);
      successToast('Se actualizó la contraseña');
    } catch (error) {
      console.log(error)
    }
  };

  const [executeSend, loadingUpdate] = useLoadingToastr(savePassword, 'Error al actualizar contraseña')

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);

  const handleOldToggle = () => {setShowOldPassword(!showOldPassword)};
  const handleNewToggle = () => {setShowNewPassword(!showNewPassword)};
  const handleRepeatToggle = () => {setShowRepeatPassword(!showRepeatPassword)};

  return (
    <div>
      <FormGroup>
        <label>Contraseña actual</label>
        <InputGroup>
          <Form.Control {...register("oldPassword")} type={showOldPassword ? 'text' : 'password'} required />
          <InputGroup.Text style={{cursor: 'pointer'}}>
            {showOldPassword ?
              <BsEyeSlashFill onClick={handleOldToggle} />
              :
              <BsEyeFill onClick={handleOldToggle} />
            }
          </InputGroup.Text>
        </InputGroup>
        {errors.oldPassword &&
          <span style={{color: "red"}}>*Debe ingresar la contraseña antigua o comuníquese con el administrador </span>}
      </FormGroup>
      <FormGroup>
        <label>Contraseña nueva</label>
        <InputGroup>
          <Form.Control {...register('newPassword')} type={showNewPassword ? 'text' : 'password'} required />
          <InputGroup.Text style={{cursor: 'pointer'}}>
            {showNewPassword ?
              <BsEyeSlashFill onClick={handleNewToggle} />
              :
              <BsEyeFill onClick={handleNewToggle} />
            }
          </InputGroup.Text>
        </InputGroup>
        {errors.newPassword &&
          <span style={{color: "red"}}>*Debe ingresar una contraseña nueva </span>}
      </FormGroup>
      <FormGroup>
        <label>Repita contraseña nueva</label>
        <InputGroup>
          <Form.Control
            type={showRepeatPassword ? 'text' : 'password'}
            required
            {...register('newPasswordConfirm', {
              validate: (value) => value === getValues('newPassword') || 'La contraseña debe coincidir con la contraseña nueva ingresada',
            })}
          />
          <InputGroup.Text style={{cursor: 'pointer'}}>
            {showRepeatPassword ?
              <BsEyeSlashFill onClick={handleRepeatToggle} />
              :
              <BsEyeFill onClick={handleRepeatToggle} />
            }
          </InputGroup.Text>
        </InputGroup>
        {errors.newPasswordConfirm && (
          <span style={{color: 'red'}}>{errors.newPasswordConfirm.message}</span>
        )}
      </FormGroup>
      <FormGroup>
        <Button variant="primary"
          disabled={loadingUpdate}
          onClick={handleSubmit(executeSend)}
        >Actualizar</Button>
      </FormGroup>
    </div>
  )
}

export default ResetPassword
