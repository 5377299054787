import React, {useEffect} from 'react';
import axios from "axios";
import {Apis} from "../../../../../util/Apis";
import ProductVariantForm from "./ProductVariantForm";
import {useParams} from "react-router";

const ProductVariantNew = ({setAction, setTitle}) => {

  const {id} = useParams()

  const save = async (params) => {
    params.foodId = id
    await axios.post(Apis.FOOD + `/food-variant`, params)
  }

  useEffect(() => {
    setTitle("Nueva variante")
  }, [])

  return (
    <ProductVariantForm value={{}} onSubmit={save} setAction={setAction}/>
  )
};

export default ProductVariantNew;
