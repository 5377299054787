import React, {useEffect} from 'react'
import {useForm} from "react-hook-form";
import SalaIdInput from "../../../common/components/SalaIdInput";
import FormGroup from "../../../../components/forms/FormGroup";
import Input from "../../../../components/forms/FormEl";

const AnforaPurgarForm = ({value, onSubmit, setAction}) => {

  const {register, handleSubmit, reset} = useForm();

  useEffect(() => {
    if (value) {
      reset(value)
    }
  }, [value])

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit));
  }, [])


  return(
      <React.Fragment>
        <SalaIdInput {...register("salaId")}/>
        <FormGroup>
          <label>Fecha</label>
          <Input type='date' {...register("scheduledAtDate")}/>
        </FormGroup>
        <FormGroup>
          <label>Hora</label>
          <Input type='time' {...register("scheduledAtTime")}/>
        </FormGroup>

        <FormGroup>
          <label>Escriba "purgar anfora" para continuar</label>
          <Input type='text' {...register("verification")}/>
        </FormGroup>
      </React.Fragment>
  )

}

export default AnforaPurgarForm
