import React, {useEffect} from "react";
import FormGroup from "../../../../../components/forms/FormGroup";
import {Controller, useForm} from "react-hook-form";
import SalaIdInput from "../../../../common/components/SalaIdInput";
import CustomerSearchInputV2 from "../../../../common/components/CustomerSearchInputV2";
import Input from "../../../../../components/forms/FormEl";


const CardForm = ({value, onSubmit, setAction}) => {

  const {register, handleSubmit, reset, control} = useForm();

  useEffect(() => {
    if (value) {
      reset(value)
    }
  }, [value])

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit))
  },[])

  return (
    <React.Fragment>
      <SalaIdInput  {...register("eventId")}/>
      <FormGroup>
        <label>Cliente</label>
        <Controller name="customerId" control={control} render={({field}) => <CustomerSearchInputV2 {...field} />}/>
      </FormGroup>
      <FormGroup>
        <label>Numero de cartillas</label>
        <Input type='number' {...register("count")} defaultValue={1}/>
      </FormGroup>
    </React.Fragment>
  )
}

export default CardForm
