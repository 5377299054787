import React, {useContext, useEffect} from 'react'
import {SelectV2} from "../../../../components/forms/FormEl";
import {Apis} from "../../../../util/Apis";
import {SiteContext} from "../../context/SiteContext";
import useFetch2 from "../../../../hooks/useFetch2";

const ManagerSelect = React.forwardRef((props, ref) => {

  const {salaId} = useContext(SiteContext)
  const {data, loading} = useFetch2(Apis.SORTEO + '/sorteo-responsable?salaId=' + salaId, []);

  useEffect(() => {
    if (data) {
      data.push({id: '', name: "Sin responsable"})
    }
  }, [data])

  return (
    <>
      {
        !loading ?
          <SelectV2 ref={ref} options={data} {...props}/>
          :
          <SelectV2 disabled options={[{id: 0, name: 'Cargando...'}]}/>
      }
    </>
  )
})

export default ManagerSelect;
