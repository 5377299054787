import React, {useContext, useEffect} from 'react';
import {SiteContext} from "../../../../common/context/SiteContext";
import useFetch2 from "../../../../../hooks/useFetch2";
import {Apis} from "../../../../../util/Apis";
import axios from "axios";
import ProductCatalogForm from "./ProductCatalogForm";

const ProductCatalogEdit = ({id, setTitle, setAction}) => {
  const {salaId} = useContext(SiteContext)
  const {data, loading} = useFetch2(Apis.PC + '/catalog-products/' + id)

  function update(params) {
    params.price = params.price !== "" ? params.price : 0
    params.salaId = salaId
    params.id = id
    return axios.put(Apis.PC + '/catalog-products/' + id, params);
  }

  useEffect(() => {
    setTitle("Editar puntos por productos")
  }, [])

  return (
    <ProductCatalogForm value={data} onSubmit={update} setAction={setAction}/>
  );
};

export default ProductCatalogEdit;
