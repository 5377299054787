import React, {useContext} from 'react'
import {SelectV2} from "../../../../components/forms/FormEl";
import {Apis} from "../../../../util/Apis";
import {SiteContext} from "../../context/SiteContext";
import useFetch2 from "../../../../hooks/useFetch2";

const PrinterSelect = React.forwardRef((props, ref) => {

  const {salaId} = useContext(SiteContext)
  const params = {enabled: true, accountId : salaId}
  const {data, loading} = useFetch2(Apis.PRINT + '/printers/state-report', [], params)

  const getData = (dataX) => {
    let convertedData = []
    if (data) {
      convertedData = data.map(item => {
        return {
          id: item.printerId,
          name: `${item.printerId} | ${item.hostname} | ${item.printerName}`
        }
      })
    }

    return convertedData
  }

  return (
    <>
      {
        !loading &&
        <SelectV2 ref={ref} options={getData(data)} {...props}/>
      }
    </>
  )
})

export default PrinterSelect;
