import React, {useContext} from "react";
import EventForm from "./EventForm";
import useFetch2 from "../../../../../hooks/useFetch2";
import {Apis} from "../../../../../util/Apis";
import axios from "axios";
import {errorToast, successToast} from "../../../../../components/layout/ToastStyles";
import {SiteContext} from "../../../../common/context/SiteContext";
import {useHistory} from "react-router";

const EventNew = () => {

  const {salaId} = useContext(SiteContext)
  const history = useHistory()

  const {loading, data, fetchData, error} = useFetch2(Apis.BINGO + `/range-sales-profit`, []);
  const save = (params) => {
    return axios.post(Apis.BINGO + '/event/range', params)
      .then(() => {
        successToast("Evento creado correctamente")
        history.push(`/bingos/eventos`)
      })
      .catch(error => errorToast(error.response.data.message ? error.response.data.message : "Ocurrió un error al guardar.", 7000))
  }

  return (
    <EventForm data={data && {"event": {"dinamic": 1}, "listRanges": data}} mode={false} onSubmit={save}/>
  )
}

export default EventNew
