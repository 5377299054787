import React, {useContext, useEffect, useMemo, useState} from 'react';
import Slidebar from "../../../common/layout/Slidebar";
import SalaIdInput from "../../../common/components/SalaIdInput";
import FormGroup from "../../../../components/forms/FormGroup";
import {DatePickerCustom} from "../../../../components/forms/FormEl";
import Button from "../../../../components/forms/Button";
import AsyncBlock from "../../../../components/forms/AsyncBlock";
import ReactTable from "../../../../components/util/ReactTable";
import {SiteContext} from "../../../common/context/SiteContext";
import moment from "moment/moment";
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import {useForm} from "react-hook-form";

const DealerReport = () => {

  const {salaId} = useContext(SiteContext)
  const [slideOpen, setSlideOpen] = useState(false)
  const firstDayOfMonth = moment(moment(new Date())).format('YYYY-MM-DD');
  const lastDayOfMonth = moment(moment(new Date())).format('YYYY-MM-DD');


  let defaultParams = {salaId: salaId, ini: firstDayOfMonth, fin: lastDayOfMonth}
  const [params, setParams] = useState(defaultParams)
  const {data, loading, error, fetchData} = useFetch2(Apis.TABLES + '/dealer/report', [], params);
  const {register, handleSubmit} = useForm({defaultValues: defaultParams});

  const getData = (params) => {
    setParams(params)
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  const columns = useMemo(
    () => [
      {
        header: 'ID',
        accessorKey: 'dealerId',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Nombre',
        accessorKey: 'name',
      },
      {
        header: 'Apellido',
        accessorKey: 'lastName',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Coin In',
        accessorKey: 'totalCoinIn',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Coin Out',
        accessorKey: 'totalCoinOut',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Producción',
        accessorKey: 'totalProd',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , []
  )

  return (
    <div>

      <Slidebar visible={slideOpen} onHide={() => setSlideOpen(false)}>
        <div style={{padding: '16px'}}>
          <SalaIdInput {...register("salaId")} />
          <FormGroup>
            <label>Inicio</label>
            <DatePickerCustom {...register("ini")} />
          </FormGroup>
          <FormGroup>
            <label>Fin</label>
            <DatePickerCustom {...register("fin")} />
          </FormGroup>
          <Button variant={'primary'} title='Buscar' onClick={handleSubmit(getData)} />
        </div>
      </Slidebar>

      <AsyncBlock loading={loading} error={error}>
        <ReactTable
          columns={columns}
          data={data || []}
          filterOnclick={() => setSlideOpen(!slideOpen)}
          loading={loading}
          pageSize={20}
          sortArray={[{id:'dealerId', asc: false}]}
          noData='No existen resultados para el filtro seleccionado'
        />
      </AsyncBlock>
    </div>
  );
};

export default DealerReport;
