import React, {useEffect} from 'react';
import axios from "axios";
import {Apis} from "../../../../util/Apis";
import MoneyDenomForm from "./MoneyDenomForm";

const MoneyDenomNew = ({setAction, setTitle}) => {

  const save = async (params) => {
    await axios.post(Apis.CAJA + '/money-denom', params)
  }

  useEffect(() => {
    setTitle("Nueva denominación")
  }, [])

  return (
    <MoneyDenomForm value={{}} onSubmit={save} setAction={setAction}/>
  );
};

export default MoneyDenomNew;
