import React, {useEffect, useState} from 'react'
import useFetch2 from '../../../../hooks/useFetch2'
import {Apis} from '../../../../util/Apis'
import {useForm} from 'react-hook-form'
import PageContainerFull from '../../../../components/layout/PageContainerFull'
import PageSection from '../../../../components/layout/PageSection'
import {Title} from "@wargostec/react-components";
import DateFilter2 from '../../../../components/datefilter/DateFilter2'
import {getCasinoDay} from '../../../../components/util/CasinoDates'
import useToggle from '../../../../hooks/useToggle'
import Slidebar from '../../../common/layout/Slidebar'
import FormGroup from '../../../../components/forms/FormGroup'
import {DatePickerCustom} from '../../../../components/forms/FormEl'
import Button from '../../../../components/forms/Button'
import LayoutCard from './LayoutCard'
import LayoutLegend from '../../../crm/pages/clientes/LayoutLegend'

const LayoutHeatMap = () => {

  const casinoDay = getCasinoDay()
  const {isOpen: slideOpen, toggler} = useToggle({});
  const [legendVisible, setLegendVisible] = useState(true);

  const [fechas, setFechas] = useState({ini: casinoDay, fin: casinoDay})
  const {register, handleSubmit} = useForm({defaultValues: fechas});


  const {data, loading, fetchData} = useFetch2(`${Apis.TGM}/layout/zonas-calientes`, [], fechas)

  const getData = (fechas) => {
    setFechas(fechas)
    toggler(false);
  }

  const legendData =[
    { label: '0 - 700.00', color: '#2398ff' },
    { label: '700.00 - 1400.00', color: '#00f2ea' },
    { label: '1400.00 - 2100.00', color: '#28fc4c' },
    { label: '2100.00 - 2800.00', color: '#ffff05' },
    { label: '2800.00 - 3500.00', color: '#ffa805' },
    { label: '3500.00 a más', color: '#ee3e32' },
  ]

  useEffect(() => {
    if (fechas) fetchData()
  }, [fechas])

  return (
    <PageContainerFull>
      <PageSection>
          <Title text="Zonas Calientes" type={'page'}/>

        <Slidebar visible={slideOpen} onHide={toggler}>
          <div style={{padding: '16px'}}>
            <FormGroup>
              <label>Desde</label>
              <DatePickerCustom {...register("ini")} />
            </FormGroup>

            <FormGroup>
              <label>Hasta</label>
              <DatePickerCustom {...register("fin")} />
            </FormGroup>
            <Button variant='primary' onClick={handleSubmit(getData)}>Buscar</Button>
          </div>
        </Slidebar>

        <DateFilter2 fechas={fechas} setFechas={setFechas} toggleSlidebar={toggler} />

        <LayoutCard
          data={data}
          fetchData={fetchData}
          loading={loading}
          setHeight='calc(100vh - 15rem)'
          type='heatmap'
          setLegendVisible={setLegendVisible}
          caption={
            <LayoutLegend
              legendVisible={legendVisible}
              legendData={legendData}
            />
          }
        />
      </PageSection>
    </PageContainerFull>
  )
}

export default LayoutHeatMap
