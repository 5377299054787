import React, {useCallback, useEffect, useState} from 'react';
import styled from "styled-components";
import cuid from "cuid";
import {FaWindowClose} from "react-icons/fa";
import {errorToast, successToast} from "../../../../components/layout/ToastStyles";
import DropZone from "./DropZone";

const CardFileContainer = styled.div`
  display: flex;
  height: 160px;
  max-width: 160px;
  justify-content: center;
  align-items: center;
  border: 3px dashed #4d4e4f;
  border-radius: 5px;
  flex-direction: column;
  padding: 10px;
  cursor: pointer;

  &:hover {
    background: #b3edf8 !important;
  }
`

export function FileUploaderV2({value, onChange, folder, assetsURL, baseURL}) {

  const [prevButton, setPrevButton] = useState(false)

  const [fileType, setFileType] = useState('')
  const [images, setImages] = useState([])
  const [url, setUrl] = useState()

  const onDrop = useCallback(acceptedFiles => {
    acceptedFiles.map(file => {
      const reader = new FileReader();
      reader.onload = function (e) {
        setImages(prevState => [
          ...prevState,
          {id: cuid(), src: e.target.result}
        ]);
      };
      reader.readAsDataURL(file);
      uploadAction(file)
      return file;
    });
  }, []);

  const handleChange = e => {
    if (e.target.files.length) {
      let rawImage = e.target.files[0];
      uploadAction(rawImage)
    }
  };

  const deleteFile = () => {
    setUrl(undefined)
    onChange('')
  }

  function uploadAction(rawImage) {
    let data = new FormData()
    const apiFile = baseURL

    data.append("file", rawImage);
    data.append('folder', folder)

    fetch(apiFile + "/upload/doc", {method: "POST", body: data}).then(response => response.json())
      .then(res => {
        successToast("Archivo cargo");
        setPrevButton(true)

        getExtension(res.url)

        onChange(res.url)
      })
      .catch(error => {
        console.log(error);
        errorToast("Ocurrió un problema al identificar el archivo. Intente de nuevo en unos minutos.");
      })
  }

  const getExtension = (url) => {
    const parts = url.split('.')
    setFileType(parts[1])
  }

  const getPreview = (value) => {
    const parts = value.split('.')
    if (parts[1] === 'xlsx') {
      return <img
        src={'https://res.cloudinary.com/dghqjv5az/image/upload/v1664948280/wgtassets/microsoft-excel_lfygi9.webp'}
        alt={'excel'}
        style={{height: "80px", minWidth: "80px"}}
      />
    }
    if (parts[1] === 'pdf') {
      return <img
        src={'https://res.cloudinary.com/dghqjv5az/image/upload/v1668203157/wgtassets/pdf-icon_xpqshv.png'}
        alt={'pdf'}
        style={{height: "80px", minWidth: "80px"}}
      />
    } else {
      return <img src={`${assetsURL}${value}`}
                  style={{width: "140px", height: "140px", minWidth: "140px"}}
                  alt={'preview'}/>
    }
  }

  useEffect(() => {
    if (value) {
      setUrl(value)
    }
  }, [value])

  return (
    <>
      <div style={{display: "flex", justifyContent: "center"}}>
        <div style={{color: 'red'}}>
          {baseURL === ""
            ? "Falta pasar el prop de baseURL"
            : baseURL === undefined
              ? "Falta pasar el prop de baseURL"
              : ""}
        </div>
        {url ? (
          <>
            <CardFileContainer>{getPreview(url)}</CardFileContainer>
            <div style={{display: "flex", marginLeft: '10px', marginBottom: '10px'}}>
              <FaWindowClose onClick={deleteFile} size="medium" style={{height: '20px', cursor: 'pointer',}}/>
            </div>
          </>
        ) : (
          <>
            <CardFileContainer>
              <DropZone onDrop={onDrop}/>
            </CardFileContainer>
          </>
        )}
      </div>
    </>
  );
}
