import React, {useEffect} from 'react';
import axios from "axios";
import {Apis} from "../../../../util/Apis";
import AftTransferForm from "./AftTransferForm";

const AftTransferNew = ({setTitle, setAction}) => {

  const save = async (params) => {
    await axios.post(Apis.TGM + "/aft-transfer", params).catch((err) => {
    });
  }

  useEffect(() => {
    setTitle("Nueva transferencia")
  }, [])

  return (
    <AftTransferForm value={{}} onSubmit={save} setAction={setAction}/>
  );
};

export default AftTransferNew;
