import React from "react";
import {Apis} from "../../../../../util/Apis";
import JackpotForm from "./JackpotForm";
import useFetch2 from "../../../../../hooks/useFetch2";
import axios from "axios";
import PageSection from "../../../../../components/layout/PageSection";
import {PageBackLabel} from "../../../../../components/layout/PageBackLabel";
import {Title} from "@wargostec/react-components";
import Loader1 from "../../../../common/components/Loader1";
import PageContainer from "../../../../../components/layout/PageContainer";
import {useHistory, useParams} from "react-router";
import {errorToast, successToast} from "../../../../../components/layout/ToastStyles";

const JackpotEdit = () => {

  const history = useHistory()
  const {id} = useParams()

  const {data, loading} = useFetch2(Apis.BINGO + '/jackpot/' + id + '/ranges',[])

  const onEdit = (params) => {
    params.jackpot.actualAmount = params.jackpot.baseAmount
    params.jackpot.actualBall = params.jackpot.ballRaise ? params.jackpot.startBall : params.jackpot.winBall
    axios.put(Apis.BINGO + '/jackpot/' + id + '/ranges', params).then(res => {
      history.push('/bingos/jackpots')
      successToast("Jackpot actualizada correctamente")
    }).catch(err => {
      errorToast("Ocurrió un problema al actualizar. Intente nuevamente en unos minutos.")
    })
  }

  return (
    <PageContainer>
      <PageSection style={{marginBottom: "20px"}}>
        <PageBackLabel text='Jackpots' onClick={() => history.goBack()}/>
          <Title text="Editar jackpot"/>
      </PageSection>
      <PageSection>
        {
          loading ?
            <Loader1/>
            :
            <JackpotForm value={data && data} onSubmit={onEdit}/>
        }
      </PageSection>
    </PageContainer>
  )
}

export default JackpotEdit
