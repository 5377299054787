import React, {useCallback, useEffect, useMemo, useState} from 'react';
import useFetch2 from "../../../../../hooks/useFetch2";
import {Apis} from "../../../../../util/Apis";
import PageContainerFull from "../../../../../components/layout/PageContainerFull";
import {Button, Title} from "@wargostec/react-components";
import PageSection from "../../../../../components/layout/PageSection";
import AsyncBlock from "../../../../../components/forms/AsyncBlock";
import ReactTable from "../../../../../components/util/ReactTable";
import FormGroup from "../../../../../components/forms/FormGroup";
import {Select} from "../../../../../components/forms/FormEl";
import Slidebar from "../../../../common/layout/Slidebar";
import {useForm} from "react-hook-form";
import useToggle from "../../../../../hooks/useToggle";

const DevicesStatus = () => {

  let defaultParams = {lastHours: 1}
  const [params, setParams] = useState(defaultParams)
  const {isOpen: slideOpen, toggler} = useToggle({});
  const {data, loading, error, fetchData} = useFetch2(Apis.TGM + '/pangolin/report-status', [], params);
  const {register, handleSubmit} = useForm({defaultValues: defaultParams});

  const getData = (params) => {
    setParams(params)
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  const nameValue = useCallback(props => props.renderValue() ? props.renderValue() : '---', []);

  const columns = useMemo(
    () => [
      {
        header: 'Id',
        accessorKey: 'confId',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        },
      },
      {
        header: 'Maquina',
        accessorKey: 'machine',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        },
        cell: nameValue
      },
      {
        header: 'Blue (SMIB)',
        accessorKey: 'blueSn',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        },
      },
      {
        header: 'Desconexión del servidor',
        accessorKey: 'countServerLinkDown',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        },
      },
      {
        header: 'Desconexión de maquina',
        accessorKey: 'countMachineLinkDown',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        }
      },
      {
        header: 'Dispositivo iniciado',
        accessorKey: 'countDeviceUp',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        }
      },
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , []
  )

  return (
    <PageContainerFull>

      <Slidebar visible={slideOpen} onHide={toggler}>
        <div style={{padding: '16px'}}>
          <FormGroup>
            <label>Ultimas horas</label>
            <Select type='number' {...register('lastHours')} >
              <option value={1}>1</option>
              <option value={6}>6</option>
              <option value={12}>12</option>
              <option value={24}>24</option>
            </Select>
          </FormGroup>
          <Button variant={'primary'} text='Buscar' onClick={handleSubmit(getData)}/>
        </div>
      </Slidebar>

      <PageSection>
        <Title text="Historial de desconexiones" type={'page'}/>

        <AsyncBlock loading={loading} error={error}>
          <ReactTable
            columns={columns}
            data={(data && data) || []}
            loading={loading}
            filterOnclick={toggler}
            pageSize={100}
            sortArray={[{countServerLinkDown: 'countServerLinkDown'}]}
            noData='No existen resultados'
          />
        </AsyncBlock>
      </PageSection>
    </PageContainerFull>

  );
};

export default DevicesStatus;
