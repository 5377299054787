import React, { useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { BiTrash } from "react-icons/bi";
import { useFieldArray, useForm } from "react-hook-form";
import FormGroup, { FormBlock, FormBlockTitle } from "../../../../components/forms/FormGroup";
import Input from "../../../../components/forms/FormEl";
import Button, { ButtonBox } from "../../../../components/forms/Button";
import { Col, Row } from "../../../../components/flex/FlexLayout";
import { Apis } from "../../../../util/Apis";
import { errorToast, successToast } from "../../../../components/layout/ToastStyles";
import PromoConfig, { ContenedorPadding } from "../../../../components/promoConfig/PromoConfig";
import { useDelete } from "../../../../hooks/useDelete";

const FormBlockTitleCustom = styled(FormBlockTitle)`
    font-size: 30px;
  `

const PuntosPromoForm = ({ value, onSubmit, edit }) => {
  const history = useHistory()
  const [dayOfWeekArray, setDayOfWeekArray] = useState(value.daysOfWeek !== undefined && value.daysOfWeek !== null ? value.daysOfWeek : [])

  const { register, handleSubmit, control } = useForm({
    defaultValues: value
  });
  const { fields, append, remove } = useFieldArray({
    control,
    keyName: "fakeId",
    name: "listHourRange"
  });

  const agregarHoras = () => append({ ini: '', fin: '' });

  const { mutate } = useDelete({
    url: Apis.PC + '/point-promo/' + value._id,
    onSuccess: () => {
      history.push('/crm/puntos-promociones')
      successToast("Promoción borrada exitosamente")
    },
    onError: () => {
      errorToast("Hubo problemas para borrar la Promoción. Intente nuevamente en unos minutos.")
    }
  })

  const eliminarPromo = () => mutate();

  const preSubmit = (puntosPromoForm) => {
    puntosPromoForm.daysOfWeek = dayOfWeekArray
    onSubmit(puntosPromoForm)
  }

  return (
    <>
      <FormBlock style={{ height: "70vh", overflow: "auto" }}>
        <FormBlockTitleCustom>Datos generales</FormBlockTitleCustom>
        <ContenedorPadding>
          <Row>
            <Col>
              <FormGroup>
                <label>Nombre promoción</label>
                <Input {...register("name")} />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <label>Multiplicador ( X ) </label>
                <Input {...register("multiplier")} />
              </FormGroup>
            </Col>
          </Row>
        </ContenedorPadding>

        <PromoConfig fields={fields} dayOfWeekArray={dayOfWeekArray} setDayOfWeekArray={setDayOfWeekArray} register={register}
          remove={remove} agregarHoras={agregarHoras} />

      </FormBlock>

      <ButtonBox>
        <Button variant='primary' onClick={handleSubmit(preSubmit)}>Guardar</Button>
        {edit === 1 &&
          <Button variant='danger' onClick={() => eliminarPromo()}>
            <BiTrash size={22} />
            <span>Eliminar</span>
          </Button>
        }
      </ButtonBox>
    </>
  )
};

export default PuntosPromoForm
