import React, {useContext, useEffect, useState} from 'react'
import Form from "react-bootstrap/Form";
import FormGroup from "../../../../../components/forms/FormGroup";
import {useForm} from "react-hook-form";
import PrinterSelect from "../../../../common/components/selects/PrinterSelect";
import ZoneSelect from "../../../../common/components/selects/ZoneSelect";

const PrinterZoneForm = ({value, onSubmit, setAction}) => {

  const {register, control, handleSubmit, errors, reset, getValues, trigger} = useForm();

  useEffect(() => {
    if (value) {
      reset(value)
    }
  }, [value])

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit))
  }, [])

  return (
    <Form>
      <FormGroup>
        <label>Zona:</label>
        <ZoneSelect  {...register("zoneId")} allOrNone={false}/>
      </FormGroup>
      <FormGroup>
        <label>Impresora:</label>
        <PrinterSelect {...register("printerId")}/>
      </FormGroup>
    </Form>
  )
}

export default PrinterZoneForm
