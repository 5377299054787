import React, {useContext} from 'react';
import {SiteContext} from "../../../common/context/SiteContext";
import {Apis} from "../../../../util/Apis";
import useDeleteModal from "../../../../modals/useDeleteModal";
import axios from "axios";
import PageSection from "../../../../components/layout/PageSection";
import PageContainer from "../../../../components/layout/PageContainer";
import {Title} from "@wargostec/react-components";
import Button, {ButtonBox} from "../../../../components/forms/Button";
import {FaPlus} from "react-icons/fa";
import DeleteGenericModal from "../../../../modals/DeleteGenericModal";
import Table from "react-bootstrap/Table";
import Card from "../../../../components/forms/Card";
import useFetch2 from "../../../../hooks/useFetch2";
import defaultLogo from "../../../../components/images/food.png";
import {useHistory} from "react-router";
import {successToast} from "../../../../components/layout/ToastStyles";
import {ViewerImage} from "../../../../components/util/ViewerImage";
import SwitchToggle from "../../../../components/layout/SwitchToggle";

const FoodProduct = () => {
  const {salaId} = useContext(SiteContext)
  const history = useHistory();

  const {data, fetchData} = useFetch2(Apis.FOOD + `/food?salaId=${salaId}`, []);

  const deleteElement = id => axios.delete(Apis.FOOD + '/food/' + id)

  const goToDetail = (id) => {
    history.push('/crm/products-type/edit/' + id)
  }

  const goToNew = () => {
    history.push('/crm/products-type/new')
  }

  const {
    wrapper: wrapper2,
    openDeleteModal
  } = useDeleteModal({
    text: '¿Desea eliminar este producto?',
    action: deleteElement,
    onRefresh: fetchData
  })

  const image = (value) => {
    let src = `${process.env.REACT_APP_S3_WARGOS_ASSETS}${value}`
    if (value === null) {
      src = defaultLogo
    }
    return src
  }

  const enabledAdnDisabled = async (id, locked) => {
    if (!locked) {
      await axios.post(Apis.FOOD + '/food/' + id + '/enable')
        .then(() => {
            fetchData()
            successToast("Producto habilitado")
          }
        )
    } else {
      await axios.post(Apis.FOOD + '/food/' + id + '/disable')
        .then(() => {
            fetchData()
            successToast("Producto deshabilitado")
          }
        )
    }
  }

  return (
    <PageContainer>

      <PageSection>

          <Title text="Catálogo de productos" type={'page'}/>
        <ButtonBox>
          <Button variant={'primary'} onClick={() => goToNew()}>
            <FaPlus/> Nuevo
          </Button>
        </ButtonBox>

      </PageSection>

      <PageSection>

        <Card padding>
          <Table striped hover style={{display: "table", height: "10px"}}>
            <thead>
            <tr>
              <th>N°</th>
              <th>Imagen</th>
              <th>Producto</th>
              <th>Stock</th>
              <th>Habilitado</th>
              <th>Opciones</th>
            </tr>
            </thead>
            <tbody>
            {data && data.map((item, index) =>
              <tr key={item.id}>
                <td className={'item-center'}>{index + 1}</td>
                <td>
                  <ViewerImage className={'img img-fluid'}
                               src={image(item.photoUrl)} alt={'imagen'}
                               style={{width: '140px', height: '140px'}} zoom={true}/>
                </td>
                <td className={'item-center'}>{item.name}</td>
                <td className={'item-center'}>{item.stock}</td>
                <td className={'item-center'}>
                  <SwitchToggle
                  defaultChecked={item.enabled}
                  id={"enabled" + item.id}
                  name={"enabled"}
                  onClick={() => enabledAdnDisabled(item.id, item.enabled)}/></td>
                <td className={'item-center'}>
                  <ButtonBox>
                    <Button size={'s'} onClick={() => goToDetail(item.id)}>Editar</Button>
                    <Button size={'s'} onClick={() => openDeleteModal(item.id)}
                            variant={'danger'}>Eliminar</Button>
                  </ButtonBox>
                </td>
              </tr>
            )}
            </tbody>
          </Table>
        </Card>

      </PageSection>
      <DeleteGenericModal {...wrapper2}/>

    </PageContainer>
  );
};

export default FoodProduct;
