import React from 'react';
import PageSection from "../../../../components/layout/PageSection";
import {PageBackLabel} from "../../../../components/layout/PageBackLabel";
import {Title} from "@wargostec/react-components";
import AsyncBlock from "../../../../components/forms/AsyncBlock";
import PageContainer from "../../../../components/layout/PageContainer";
import {useHistory, useParams} from "react-router";
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import SiteForm from "./SiteForm";
import axios from "axios";
import {errorToast, successToast} from "../../../../components/layout/ToastStyles";

const SiteEdit = () => {

  const {id} = useParams()
  const history = useHistory()
  const {data, loading, fetchData, error} = useFetch2(Apis.CUSTOMERAPP + '/site-info/' + id)

  const update = async (params) => {
      await axios.put(Apis.CUSTOMERAPP + '/site-info/' + id, params)
        .then(res => {
          successToast()
          history.push('/crm/sites')
        }).catch(err => {
          errorToast()
        })
  }

  return (
    <PageContainer>
      <PageSection>
        <PageBackLabel text='Sitios' onClick={() => history.push('/crm/sites')}/>
          <Title text="Editar sitio" type={'page'}/>
      </PageSection>

      <PageSection>
        <AsyncBlock loading={loading} error={error}>
          <SiteForm value={data && data} onSubmit={update} mode={true} fetchData={fetchData}/>
        </AsyncBlock>
      </PageSection>
    </PageContainer>
  );
};

export default SiteEdit;
