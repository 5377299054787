import React, {useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import CardOverview, {CardOverviewContainer} from '../../../../components/layout/CardOverview';
import {FlexContainer, Title} from '@wargostec/react-components';
import {TbServerOff} from 'react-icons/tb';
import Card from '../../../../components/forms/Card';
import {HiOutlineStatusOffline} from 'react-icons/hi';
import useFetch2 from '../../../../hooks/useFetch2';
import {Apis} from '../../../../util/Apis';
import AsyncBlock from '../../../../components/forms/AsyncBlock';

const Tooltip = styled.div`
  position: absolute;
  padding: 4px 8px;
  background: #333;
  color: #fff;
  border-radius: 4px;
  font-size: 12px;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s;
`;

const LegendItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap:4px;
  span{
    font-size:12px;
  }
  div{
    width: 32px;
    height: 12px;
    border-radius: 4px;
  }
`

const machineLinkColor = '#FFA500';
const serverLinkColor = '#EA3546';
const connectedColor =  '#4CAF50';

const MachineDashboard = ({confId}) => {

  const {data,loading,error} = useFetch2(Apis.TGM + '/internal/pangolin/graphic-debugs?confId=' + confId, []);

  const canvasRef = useRef(null);
  const tooltipRef = useRef(null);
  const [tooltip, setTooltip] = useState({x: 0, y: 0, text: ''});

  const sortedData2 = Array.isArray(data?.data) ? [...data.data].sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)) : [];

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;  
    const ctx = canvas.getContext('2d');
    const rect = canvas.getBoundingClientRect();
    const canvasWidth = rect.width;
    const canvasHeight = 36;
    const devicePixelRatio = window.devicePixelRatio || 1;

    canvas.width = canvasWidth * devicePixelRatio;
    canvas.height = canvasHeight * devicePixelRatio;

    ctx.scale(devicePixelRatio, devicePixelRatio);

    const startOfDay = new Date(new Date().setHours(0, 0, 0, 0));
    const endOfDay = new Date(new Date().setHours(23, 59, 59, 999));

    ctx.clearRect(0, 0, canvasWidth, canvasHeight);

    ctx.fillStyle = '#4CAF50';
    ctx.fillRect(0, 0, canvasWidth, canvasHeight);

    const drawSegment = (start, end, color) => {
      const startX = ((start - startOfDay) / (endOfDay - startOfDay)) * canvasWidth;
      const endX = ((end - startOfDay) / (endOfDay - startOfDay)) * canvasWidth;
      ctx.fillStyle = color;
      ctx.fillRect(startX, 0, Math.max(endX - startX, 1), canvasHeight);  
    };

    sortedData2.forEach((event, index) => {
      const eventTime = new Date(event.createdAt);
      if (event.eventCode === 1004 || event.eventCode === 1006) {
        let nextEventIndex = index + 1;
        while (nextEventIndex < sortedData2.length && sortedData2[nextEventIndex].eventCode !== 1005 && sortedData2[nextEventIndex].eventCode !== 1007) {
          nextEventIndex++;
        }
        const nextEventTime = nextEventIndex < sortedData2.length ? new Date(sortedData2[nextEventIndex].createdAt) : endOfDay;
        const color = event.eventCode === 1004 ? machineLinkColor : serverLinkColor;  // Rojo para 1004, Naranja para 1006
        drawSegment(eventTime, nextEventTime, color);
      }
    });

    const handleMouseMove = (event) => {
      const rect = canvas.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const realCanvasWidth = rect.width;
      const percentOfDay = x / realCanvasWidth;
      const millisecondsInDay = endOfDay - startOfDay;
      const timeAtX = new Date(startOfDay.getTime() + percentOfDay * millisecondsInDay);

      const hours = timeAtX.getHours().toString().padStart(2, '0');
      const minutes = timeAtX.getMinutes().toString().padStart(2, '0');
      const timeString = `${hours}:${minutes}`;

      setTooltip({
        x: event.clientX,
        y: event.clientY - 30,
        text: timeString,
      });

      tooltipRef.current.style.opacity = 1;
    };

    const handleMouseLeave = () => {
      tooltipRef.current.style.opacity = 0;
    };

    canvas.addEventListener('mousemove', handleMouseMove);
    canvas.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      canvas.removeEventListener('mousemove', handleMouseMove);
      canvas.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, [sortedData2]);

  return (
    <>
      <Title text="Resumen" type='page'/>
      <CardOverviewContainer gridColumns='repeat(2, 1fr)'>
        <CardOverview
           title={data?.noServerLink ?? '0'}
          subtitle='Desconexiones del servidor'
          icon={<TbServerOff size={22} />}
        />
        <CardOverview
          title={data?.noMachineLink ?? '0'}
          subtitle='Desenlaces con máquina'
          icon={<HiOutlineStatusOffline size={22} />}
        />
      </CardOverviewContainer>
      <Card padding>
        <AsyncBlock loading={loading} error={error}>
        <FlexContainer flexDirection='column' gap='16px'>
          <Title text="Estado de conexión" type='form' />
          <FlexContainer flexDirection='column'>
          <canvas ref={canvasRef} height={16} style={{width: '100%', background: '#22c55e', borderRadius: '8px'}}></canvas>
          <div style={{display: 'flex', width: '100%', justifyContent: 'space-between', fontSize: '12px'}}>
            <span>00:00</span>
            <span>03:00</span>
            <span>06:00</span>
            <span>09:00</span>
            <span>12:00</span>
            <span>15:00</span>
            <span>18:00</span>
            <span>21:00</span>
            <span>23:59</span>
          </div>
          </FlexContainer>
          <FlexContainer flexDirection='row' gap='16px' justifyContent='center'>
            <LegendItem>
              <span>Conectado</span>
              <div style={{background: connectedColor}}></div>
            </LegendItem>
            <LegendItem>
              <span>Desconectado de la máquina</span>
              <div style={{background: machineLinkColor}}></div>
            </LegendItem>
            <LegendItem>
              <span>Desconectado del servidor</span>
              <div style={{background: serverLinkColor}}></div>
            </LegendItem>
          </FlexContainer>
          <Tooltip ref={tooltipRef} style={{top: tooltip.y, left: tooltip.x}}>
            {tooltip.text}
          </Tooltip>
        </FlexContainer>
        </AsyncBlock>
      </Card>
    </>
  )
}

export default MachineDashboard
