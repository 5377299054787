import React from 'react'
import PageSection from "../../../../../components/layout/PageSection";
import {Title, Button} from "@wargostec/react-components";
import Table from "react-bootstrap/Table";
import PageContainer from "../../../../../components/layout/PageContainer";
import {ButtonBox} from "../../../../../components/forms/Button";
import {Apis} from "../../../../../util/Apis";
import ZoneNew from "./ZoneNew";
import ZoneEdit from "./ZoneEdit";
import axios from "axios";
import Card from "../../../../../components/forms/Card";
import GenericModal2 from "../../../../../modals/GenericModal2";
import DeleteGenericModal from "../../../../../modals/DeleteGenericModal";
import useEditModal from "../../../../../modals/useEditModal";
import useDeleteModal from "../../../../../modals/useDeleteModal";
import useFetch2 from "../../../../../hooks/useFetch2";
import {AiOutlinePlus} from "react-icons/ai";

const ZoneList = () => {

  const {data, fetchData} = useFetch2(Apis.TGM + `/slot-zones`, []);

  //hooks para crear,editar y eliminar
  const {wrapper: wrapper1, addAction, editAction} = useEditModal({
    editComponent: <ZoneEdit/>,
    addComponent: <ZoneNew/>,
    onRefresh: fetchData
  })

  const deleteElement = id => axios.delete(Apis.TGM + '/slot-zones/' + id)

  const {wrapper: wrapper2, openDeleteModal} = useDeleteModal({
    text: '¿Desea eliminar esta zona?',
    action: deleteElement,
    onRefresh: fetchData
  })

  return (
    <PageContainer>

      <PageSection>
          <Title text="Zonas de máquinas" type={'page'}/>
        <ButtonBox>
          <Button variant="primary" onClick={() => addAction()}
                  icon={<AiOutlinePlus />} text="Nuevo"/>
        </ButtonBox>

        <Card padding>

          <Table striped hover>
            <thead>
            <tr>
              <th>ID</th>
              <th>Sala</th>
              <th/>
            </tr>
            </thead>
            <tbody>
            {data && data.map(item =>
              <tr key={item.id}>
                <td>{item.id}</td>
                <td>{item.name}</td>
                <td>
                  <ButtonBox>
                    <Button variant="light" size={'sm'} onClick={() => editAction(item.id)} text="Editar"/>
                    <Button variant="danger" size={'sm'} onClick={() => openDeleteModal(item.id)} text="Eliminar"/>
                  </ButtonBox>
                </td>
              </tr>
            )}
            </tbody>
          </Table>

        </Card>
      </PageSection>

      <GenericModal2 {...wrapper1}/>
      <DeleteGenericModal {...wrapper2}/>

    </PageContainer>
  )
}
export default ZoneList;
