import styled from "styled-components";
import React from "react";

const CardContentContainer = styled.div`
  margin: 0 16px;
`

const CardContainer = styled.div`
  display: flex;
  justify-content: center;
`
const NewGreenCard = styled.div`
  width: 80%;
  padding-top: 25px;
  padding-bottom: 25px;
  border: 2px solid rgb(142 199 116 / 90%);
  box-shadow: 3px 3px 5px 2px rgb(177 177 177 / 90%)
`

const NewRedCard = styled.div`
  width: 80%;
  padding-top: 25px;
  padding-bottom: 25px;
  border: 2px solid rgb(211 97 97 / 90%);
  box-shadow: 3px 3px 5px 2px rgb(177 177 177 / 90%)
`

const NewYellowCard = styled.div`
  width: 80%;
  padding-top: 25px;
  padding-bottom: 25px;
  border: 2px solid rgb(237 218 118 / 90%);
  box-shadow: 3px 3px 5px 2px rgb(177 177 177 / 90%)
`

const IconCard = styled.img`
  width: 26px;
  height: 26px;
  margin-right: 20px;
  margin-bottom: 10px;
`

const IconCard2 = styled.div`
  .icon-card-2 {
    width: 35px;
    height: 35px;
    margin-right: 30px;
    margin-bottom: 5px;
    //margin-top: 5px;
`

const CardTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
`
const TrashIcon = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
  width: 100%;
  align-content: end;
  justify-content: end;
`

const CardSubtitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
`

const CardErrorMessage = styled.div`
  margin-top: 10px;
`

const CardContent = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
`

export {
  TrashIcon,
  CardContent,
  CardErrorMessage,
  CardSubtitle,
  CardTitle,
  IconCard2,
  IconCard,
  NewYellowCard,
  NewRedCard,
  NewGreenCard,
  CardContainer,
  CardContentContainer
}
  
