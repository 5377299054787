import React, {useContext, useEffect, useState} from "react";
import {SiteContext} from "../../../common/context/SiteContext";
import {Apis} from "../../../../util/Apis";
import {useForm} from "react-hook-form";
import Slidebar from "../../../common/layout/Slidebar";
import SalaIdInput from "../../../common/components/SalaIdInput";
import Button, {ButtonBox} from "../../../../components/forms/Button";
import PageSection from "../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import Card from "../../../../components/forms/Card";
import Table from "react-bootstrap/Table";
import Moment from "react-moment";
import axios from "axios";
import useDeleteModal from "../../../../modals/useDeleteModal";
import DeleteGenericModal from "../../../../modals/DeleteGenericModal";
import useFetch2 from "../../../../hooks/useFetch2";
import useToggle from "../../../../hooks/useToggle";
import PageContainer from "../../../../components/layout/PageContainer";
import CardOverview, {CardOverviewContainer} from "../../../../components/layout/CardOverview";
import {HiOutlineBanknotes} from "react-icons/hi2";
import {FaSortAmountUpAlt} from "react-icons/fa";
import {numberFormat} from "../../../../components/util/NumberUtil";
import PeriodAndFondoSelect from "./PeriodAndFondoSelect";
import EmptyState from "../../../common/components/EmptyState";
import {TbFileSad} from "react-icons/tb";
import AsyncBlock from "../../../../components/forms/AsyncBlock";
import {getCasinoDay} from "../../../../components/util/CasinoDates";

const CBillReport = () => {

  const queryString = window.location.search
  const query = new URLSearchParams(queryString)
  let fondoIdParams = query.get('fondoId') || ""
  let periodParams = query.get('period') || getCasinoDay();

  const {salaId} = useContext(SiteContext)
  const {isOpen: slideOpen, toggler} = useToggle({});

  const {register, handleSubmit} = useForm();
  const [params, setParams] = useState({
    salaId: salaId,
    fondoId: fondoIdParams,
    period: periodParams,
  });

  const determineApiEndpoint = (fondoId) => {
    return fondoId === "" 
      ? Apis.CAJA + '/operation-billetero/report/by-period' 
      : Apis.CAJA + '/operation-billetero/report/by-fondo';
  };

  const {loading, data, fetchData, error} = useFetch2(determineApiEndpoint(params.fondoId), null, params, false);

  const deleteElement = id => axios.delete(Apis.CAJA + '/operation-billetero/' + id)

  const {
    wrapper: wrapper2,
    openDeleteModal
  } = useDeleteModal({
    text: '¿Desea eliminar esta operación de caja?',
    action: deleteElement,
    onRefresh: fetchData
  })

  useEffect(() => {
    const query = new URLSearchParams(params).toString();
    const newUrl = `${window.location.pathname}?${query}`;
    window.history.pushState(null, "", newUrl);
   fetchData()
  }, [params]);

  const handleFondoIdChange = (fondoId) => {
    setParams((prevParams) => ({...prevParams, fondoId: fondoId}));
  };

  const onSubmit = () => {
    fetchData();
  };

  return (
    <PageContainer>
      <Slidebar visible={slideOpen} onHide={toggler}>
        <div style={{padding: '16px'}}>
          <SalaIdInput {...register("salaId")} />
          <PeriodAndFondoSelect
            onFondoChange={handleFondoIdChange}
            period={params.period}
            setPeriod={(newPeriod) => setParams((prevParams) => ({...prevParams, period: newPeriod}))}
            {...register("fondoId")} />
          <Button variant={'primary'} title='Buscar' onClick={handleSubmit(onSubmit)} />
        </div>
      </Slidebar>

      <PageSection>
        <Title text="Billetero" type={'page'} />
        <ButtonBox>
          <Button variant={'primary'} title={'Filtrar'} onClick={toggler} />
        </ButtonBox>
        <AsyncBlock loading={loading} error={error}>
          {data === null ?
            <Card padding>
              <EmptyState
                title={'Pre billetero'}
                subtitle={'Haga click en FILTRAR para realizar una busqueda'}
                icon={<HiOutlineBanknotes size={32} />}
              />
            </Card>
            :
            <>
              {data?.data?.length === 0 ?
                <Card padding>
                  <EmptyState
                    title={'Sin resultados'}
                    subtitle={'Haga click en FILTRAR para realizar una nueva busqueda'}
                    icon={<TbFileSad size={32} />}
                  />
                </Card>
                :
                <>
                  <CardOverviewContainer gridColumns='repeat(2, 1fr)'>
                    <CardOverview
                      title={data?.quantity ?? 0}
                      subtitle='Operaciones'
                      icon={<FaSortAmountUpAlt size={20} />}
                    />
                    <CardOverview
                      title={'S/ ' + numberFormat(data?.totalAmount)}
                      subtitle='Total'
                      icon={<HiOutlineBanknotes size={20} />}
                    />
                  </CardOverviewContainer>
                  <Card>
                    <Table striped hover responsive>
                      <thead>
                        <tr>
                          <th className="text-center">Máquina</th>
                          <th className="text-right">Monto</th>
                          <th className="text-center">Fondo</th>
                          <th className="text-center">Fecha</th>
                          <th className="text-center">Acciones</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.data && data?.data.map(item =>
                          <tr key={item.id}>
                            <td className="text-center">{item.machine}</td>
                            <td className="text-right">{'S/ ' + numberFormat(item.amount)}</td>
                            <td className="text-center">{item.fondoName}</td>
                            <td className="text-center"><Moment format={'HH:mm DD/MM/YYYY'}>{item.createdAt}</Moment></td>
                            <td className="text-center">
                              <Button size='xs' variant='danger' onClick={() => openDeleteModal(item.id)}>Eliminar</Button>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </Card>
                </>
              }
            </>
          }
        </AsyncBlock>
      </PageSection>
      <DeleteGenericModal {...wrapper2} />
    </PageContainer>
  )
}

export default CBillReport;
