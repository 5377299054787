import React, { useContext } from 'react';
import Button, { ButtonBox } from "../../../../components/forms/Button";
import { FaPlus } from "react-icons/fa";
import Table from "react-bootstrap/Table";
import GenericModal2 from "../../../../modals/GenericModal2";
import DeleteGenericModal from "../../../../modals/DeleteGenericModal";
import useFetch2 from "../../../../hooks/useFetch2";
import { Apis } from "../../../../util/Apis";
import { SiteContext } from "../../../common/context/SiteContext";
import useEditModal from "../../../../modals/useEditModal";
import useDeleteModal from "../../../../modals/useDeleteModal";
import axios from "axios";
import EditManager from "./EditManager";
import NewManager from "./NewManager";
import { LoadingTableV2 } from "../../../../components/forms/Tables";

const ResponsableConfiguration = () => {

  const { salaId } = useContext(SiteContext)

  const {
    data,
    loading,
    error,
    fetchData
  } = useFetch2(Apis.SORTEO + '/sorteo-responsable', []);

  const { wrapper: wrapper1, addAction, editAction } = useEditModal({
    editComponent: <EditManager />,
    addComponent: <NewManager />,
    onRefresh: fetchData
  })

  const deleteElement = id => axios.delete(Apis.SORTEO + '/sorteo-responsable/' + id)

  const { wrapper: wrapper2, openDeleteModal } = useDeleteModal({
    text: '¿Desea eliminar este responsable?',
    action: deleteElement,
    onRefresh: fetchData
  })

  return (
    <>
      <h4 style={{ fontSize: '1.5rem', margin: '2rem 0', fontWeight: '600' }}>Responsables y administradores</h4>
      <Button variant={'primary'} onClick={() => addAction()} style={{marginBottom:'2rem'}}>
        <FaPlus />
        <span>Nuevo</span>
      </Button>

      <LoadingTableV2 loading={loading} error={error} data={data} noDataText={'No hay personal agregado'}>
        <Table striped hover responsive>
          <thead>
            <tr>
              <th>Usuario</th>
              <th>Rol</th>
              <th className='text-center'>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {data && data.map(item =>
              <tr key={item.id}>
                <td>{item.name}</td>
                <td>{item.title}</td>
                <td className='text-center'>
                  <ButtonBox style={{ justifyContent: 'center' }}>
                    <Button  size={'s'} onClick={() => editAction(item.id)}>Editar</Button>
                    <Button variant={'danger'} size={'s'} onClick={() => openDeleteModal(item.id)}>Eliminar</Button>
                  </ButtonBox>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </LoadingTableV2>

      <GenericModal2 {...wrapper1} />
      <DeleteGenericModal {...wrapper2} />
    </>
  );
};

export default ResponsableConfiguration;
