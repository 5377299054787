import React, { useCallback, useContext, useMemo } from "react";
import {Button, Title} from "@wargostec/react-components";
import Moment from "react-moment";
import PageContainerFull from "../../../../../components/layout/PageContainerFull";
import PageSection from "../../../../../components/layout/PageSection";
import CardNew from './CardNew';
import useEditModal from "../../../../../modals/useNewEditModal";
import GenericModal2 from "../../../../../modals/GenericModal2";
import { SiteContext } from "../../../../common/context/SiteContext";
import { Apis } from "../../../../../util/Apis";
import AsyncBlock from "../../../../../components/forms/AsyncBlock";
import ReactTable from "../../../../../components/util/ReactTable";
import useGet from "../../../../../hooks/useGet";
import {FlexLayout} from "../../../../../components/flex/Flex";
import useDeleteModal from "../../../../../modals/useDeleteModal";
import axios from "axios";
import DeleteGenericModal from "../../../../../modals/DeleteGenericModal";

const CardList = () => {

  const { data, loading, fetchData, error } = useGet({
    url: Apis.PC + `/card`,
    initialValue: []
  })

  const deleteElement = id => axios.delete(Apis.PC + '/card/' + id)

  const {
    wrapper: wrapper2,
    openDeleteModal
  } = useDeleteModal({
    text: '¿Desea desvincular esta tarjeta?',
    action: deleteElement,
    onRefresh: fetchData
  })

  const { wrapper: wrapper1, addAction } = useEditModal({
    addComponent: <CardNew />,
    onRefresh: async () => await fetchData()
  })

  const dateValue = useCallback(props => <Moment format={'YYYY-MM-DD HH:mm a'}>{props.renderValue()}</Moment>, []);

  const actionValue = useCallback(props => <>{
    <FlexLayout justifyContent='center'>
      <Button variant="danger" size='xs' onClick={() => openDeleteModal(props.row.original.id)}
              text={"Desvincular"}/>
    </FlexLayout>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }</>, []);

  const employeeValue = useCallback(props => <>{
    props.renderValue() ?
      <span>{props.renderValue()}</span>
      :
      <span>---</span>
  }</>, []);

  const columns = useMemo(
    () => [
      // {
      //   header: 'Id',
      //   accessorKey: 'id',
      //   meta: {
      //     headerClassName: 'text-center',
      //     columnClassName: 'text-center',
      //   }
      // },
      {
        header: 'Número de Tarjeta',
        accessorKey: 'number',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
      },
      {
        header: 'Cliente',
        accessorKey: 'customerFullName'
      },
      {
        header: 'Fecha de registro',
        accessorKey: 'createdAt',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: dateValue
      },
      {
        header: 'Registrado por',
        accessorKey: 'employeeName',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: employeeValue
      },
      {
        header: 'Acciones',
        accessorKey: 'acciones',
        enableGlobalFilter: false,
        enableSorting: false,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: actionValue
      }
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , []
  )

  return (
    <PageContainerFull>
      <PageSection>
        <Title text="Tarjetas" type={'page'} />
        <AsyncBlock loading={loading} error={error}>
          <ReactTable
            columns={columns}
            data={data || []}
            filterAdd={() => addAction()}
            loading={loading}
            noData='No existen tarjetas registradas'
            customSearch
            pageSize={20}
          />
        </AsyncBlock>
      </PageSection>
      <GenericModal2 {...wrapper1} />
      <DeleteGenericModal {...wrapper2} />
    </PageContainerFull>
  );
}

export default CardList;
