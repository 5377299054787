import React, {useEffect} from 'react';
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import {useForm} from "react-hook-form";
import Button from "react-bootstrap/Button";
import {GrCopy} from "react-icons/gr";
import {FlexLayout} from "../../../../components/flex/Flex";
import Input from "../../../../components/forms/FormEl";

const PrinterToken = () => {

  const {data} = useFetch2(Apis.PC + '/printers/token', [], false);

  const copyContent = async () => {
    try {
      await navigator.clipboard.writeText(data && data.token);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  }

  return (
    <>
      <h4 style={{fontSize: '1.4rem', margin: '2rem 0', fontWeight: '600'}}>Token</h4>
      <FlexLayout margin='0 0 16px' alignItems='center'>
          <Input disabled value={data && data.token} />
          <Button  size={'s'} onClick={copyContent}>
            <GrCopy/>
          </Button>
      </FlexLayout>
    </>
  );
};

export default PrinterToken;
