import React, {useEffect} from "react";
import {Apis} from "../../../../../util/Apis";
import axios from "axios";
import CardForm from "./CardForm";


const VentaCardNew = ({setAction, setTitle}) => {

  const onCreate = (params) => {
    return axios.post(Apis.BINGO + `/bingo-card/assign`, params)
  }

  useEffect(() => {
    setTitle("Nueva venta de bingo")
  })

  return (
    <CardForm value={{}} onSubmit={onCreate} setAction={setAction}/>
  )
}

export default VentaCardNew
