import React from 'react';
import Button from '../../../../components/forms/Button';
import { FlexLayout } from '../../../../components/flex/Flex';
import { FiPrinter } from 'react-icons/fi';
import { FaDownload } from 'react-icons/fa';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

const ComputerPrinterDownload = () => {
  const downloadPrinter = () => {
    window.location.href =
      'https://storage.googleapis.com/webnode-prod/bundle/bundle.zip';
  };
  const downloadJavaPOS = () => {
    window.location.href =
      'https://storage.googleapis.com/webnode-prod/Epson_JavaPOS_ADK_11424.zip';
  };
  const downloadJDK = () => {
    window.location.href =
      'https://storage.googleapis.com/webnode-prod/microsoft-jdk-17.0.11-windows-x64.msi';
  };
  const downloadWebnode = () => {
    window.location.href =
      'https://descargas.wargos.cloud/webnode3-w35b.zip';
  };

  return (
    <>
      <h4 style={{ fontSize: '1.4rem', margin: '2rem 0', fontWeight: '600' }}>Descargas</h4>

      <Row>
        <Col>
          <FlexLayout column alignItems='center' margin='0 0 1rem' justifyContent='center'>
            <FiPrinter size={18} />
            <span style={{ fontSize: '1.2rem' }}>App Impresora</span>
            <Button variant={'primary'} onClick={downloadPrinter}>
            <FaDownload />
            <span>Descargar</span>
          </Button>
          </FlexLayout>
        </Col>

        <Col>
          <FlexLayout column alignItems='center' margin='0 0 1rem' justifyContent='center'>
            <FiPrinter size={18} />
            <span style={{ fontSize: '1.2rem' }}>JavaPOS v.1.14.24W</span>
            <Button variant={'primary'} onClick={downloadJavaPOS} >
            <FaDownload />
            <span>Descargar</span>
          </Button>
          </FlexLayout>
        </Col>
        <Col>
          <FlexLayout column alignItems='center' margin='0 0 1rem' justifyContent='center'>
            <FiPrinter size={18} />
            <span style={{ fontSize: '1.2rem' }}>OpenJDK 17</span>
            <Button variant={'primary'} onClick={downloadJDK}>
            <FaDownload />
            <span>Descargar</span>
          </Button>
          </FlexLayout>
        </Col>
        <Col>
          <FlexLayout column alignItems='center' margin='0 0 1rem' justifyContent='center'>
            <FiPrinter size={18} />
            <span style={{ fontSize: '1.2rem' }}>Webnode 3</span>
            <Button variant={'primary'} onClick={downloadWebnode}>
              <FaDownload />
              <span>Descargar</span>
            </Button>
          </FlexLayout>
        </Col>
      </Row>
    </>
  );
};

export default ComputerPrinterDownload;
