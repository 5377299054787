import React, {useContext, useState} from 'react';
import {SiteContext} from "../../../common/context/SiteContext";
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import Card from "../../../../components/forms/Card";
import Table from "react-bootstrap/Table";
import Moment from "react-moment";
import {LoadingTableV2} from "../../../../components/forms/Tables";

const RedeemPending = ({customerId}) => {

  const {salaId} = useContext(SiteContext)
  let defaultParams = {salaId, state: "GENERADO", customerId}
  const [params, setParams] = useState(defaultParams)

  const {data, loading, fetchData, error} = useFetch2(Apis.PC + `/redeems`, [], params)

  return (
    <div>
      <Card>
        <LoadingTableV2 loading={loading} data={data} error={error} noDataText={"No hay información"}>
          <Table striped hover>
            <thead>
            <tr>
              <th className={"text-center"}>Cliente</th>
              <th className={"text-center"}>Nivel</th>
              <th className={"text-center"}>Producto</th>
              <th className={"text-center"}>Puntos canjeados</th>
              <th className={"text-center"}>Fecha canje</th>
              <th className={"text-center"}>Hora canje</th>
              <th className={"text-center"}>Canjeado por</th>
              <th className={"text-center"}>Estado</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            {data && data.map(item =>
              <tr key={item.id}>
                <td className={"text-center"}>{item.customerName}</td>
                <td className={"text-center"}>{item.levelName}</td>
                <td className={"text-center"}>{item.productName}</td>
                <td className={"text-center"}>{item.productPoint}</td>
                <td className={"text-center"}><Moment format={'DD/MM/YYYY'}>{item.createdAt}</Moment></td>
                <td className={"text-center"}><Moment format={'HH:mm'}>{item.createdAt}</Moment></td>
                <td className={"text-center"}>{item.employeeName}</td>
                <td className={"text-center"}>{item.state}</td>
              </tr>
            )}
            </tbody>
          </Table>
        </LoadingTableV2>
      </Card>
    </div>
  );
};

export default RedeemPending;
