import React from "react";

const MachineTypeText = (props) => {

  const showMachineType = (param) => {
    switch (param) {
      case 1:
        return "MAQUINAS"
      case 2:
        return "RULETA"
      case 3:
        return "DERBY"
      case 4:
        return "MESAS"
      default:
        return "n/a"
    }
  }

  return (
    showMachineType(props.machineTypeId)
  )
}

export default MachineTypeText;
