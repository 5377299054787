import {Apis} from "../../../../util/Apis";
import React, {useContext, useEffect} from "react";
import FormGroup from "../../../../components/forms/FormGroup";
import Input from "../../../../components/forms/FormEl";
import {SiteContext} from "../../../common/context/SiteContext";
import axios from "axios"
import {useForm} from "react-hook-form";


const GroupNew=({setAction, setTitle})=>{

  const {salaId}=useContext(SiteContext)

  const {register, handleSubmit} = useForm();

  const save = (params) => {
    params.salaId=salaId;
    return axios.post(Apis.USER + '/user-group', params);
  }

  useEffect(() => {
    setTitle("Nuevo Grupo")
    setAction(() => handleSubmit(save))
  }, [])

  return (
    <FormGroup>
      <label>Nombre</label>
      <Input type={'text'} {...register("name")}/>
    </FormGroup>
  )

}

export default GroupNew
