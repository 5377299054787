import React, {useContext, useEffect} from 'react';
import AsyncBlock from "../../../../components/forms/AsyncBlock";
import Card from "../../../../components/forms/Card";
import Button from "../../../../components/forms/Button";
import {SiteContext} from "../../../common/context/SiteContext";
import {Controller, useForm} from "react-hook-form";
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import {Select, SelectV2} from "../../../../components/forms/FormEl";
import axios from "axios";
import {errorToast, successToast} from "../../../../components/layout/ToastStyles";
import MidasList from "./MidasList";
import SwitchToggle from "../../../../components/layout/SwitchToggle";
import {FileUploaderV2} from "../../../common/components/fileuploader/FileUploaderV2";
import {FlexContainer, FormGroup, Input} from '@wargostec/react-components';
import TextWithHelp from '../../../../components/util/TextWithHelp';

const OnlineConfiguration = () => {

  const {salaId} = useContext(SiteContext)
  const {loading, data, error, fetchData} = useFetch2(Apis.SALAS + '/salas-config/' + salaId, []);
  const {data: dataTicket, fetchData: fetchDataTicket} = useFetch2(Apis.TICKET + '/ticket-global-conf', []);
  const {
    data: configLudop,
    fetchData: fetchDataConfigLudop
  } = useFetch2(Apis.BLACK + '/config-sala', []);
  const {register, handleSubmit, reset, control} = useForm();
  const {register: registerTicket, handleSubmit: handleSubmitTicket, reset: resetTicket} = useForm();
  const {register: registerConfigLudop, handleSubmit: handleSubmitConfigLudop, reset: resetConfigLudop} = useForm();

  const onCreate = (data) => {
    data.salaId = salaId;

    axios.put(Apis.SALAS + '/salas-config/' + salaId, data)
      .then(res => {
        successToast()
        fetchData()
      }).catch(err => {
      errorToast();
    })
  };

  const onCreateTicket = (data) => {
    data.salaId = salaId;

    axios.put(Apis.TICKET + '/ticket-global-conf/' + data.id, data)
      .then(res => {
        successToast()
        fetchDataTicket()
      }).catch(err => {
      errorToast();
    })
  };

  const onConfigLudop = (data) => {
    axios.put(Apis.BLACK + '/config-sala', data)
      .then(res => {
        successToast()
        fetchDataConfigLudop()
      }).catch(err => {
      errorToast();
    })
  }

  useEffect(() => {
    if (data) {
      reset(data)
    }
  }, [data])

  useEffect(() => {
    if (dataTicket) {
      resetTicket(dataTicket)
    }
  }, [dataTicket])

  useEffect(() => {
    if (configLudop) {
      resetConfigLudop(configLudop)
    }
  }, [configLudop])

  const downloadScanner = () => {
    window.location.href = data.scannerURL;
  };

  return (
    <>
      <AsyncBlock loading={loading} error={error}>
        <Card padding>
          <h4 style={{fontWeight: '600', marginBottom: '16px'}}>Sala</h4>
          <FormGroup>
            <label>Nombre</label>
            <Input type='text' disabled {...register("name")} />
          </FormGroup>
          <FormGroup>
            <label>Registro MINCETUR</label>
            <Input type='number' disabled {...register("registroMincetur")} />
          </FormGroup>
          <FormGroup>
            <label style={{marginTop: '15px'}}><strong>Dirección</strong></label>
            <Input type='text' {...register("address")} />
          </FormGroup>
          <FormGroup>
            <label>Logo</label>
            <Controller name="logoUrl" control={control} render={({field}) =>
              <FileUploaderV2
                folder={'foods'}
                {...field}
                baseURL={Apis.FILE}
                assetsURL={process.env.REACT_APP_S3_WARGOS_ASSETS}/>}/>
          </FormGroup>
          <FormGroup>
            <label>Empresa</label>
            <Input type='text' disabled {...register("companyName")} />
          </FormGroup>
          <FormGroup>
            <label>RUC</label>
            <Input type='number' {...register("ruc")} />
          </FormGroup>
          <Button title={"Guardar"} variant={'primary'} onClick={handleSubmit(onCreate)}/>
        </Card>

        <Card padding>
          <h4 style={{fontWeight: '600', marginBottom: '16px'}}>Tipo de moneda</h4>
          <FlexContainer flexDirection='row' gap='16px'>
            <FormGroup>
              <label>Moneda</label>
              <SelectV2 {...register('currencyIso')} options={
                [
                  {id: 'PEN', name: 'PEN'},
                  {id: 'USD', name: 'USD'},
                  {id: 'EUR', name: 'EUR'},
                  {id: 'COP', name: 'COP'},
                  {id: 'GTQ', name: 'GTQ'},
                ]
              }/>
            </FormGroup>
            <FormGroup>
              <label>Simbolo</label>
              <SelectV2 {...register('currencySymbol')} options={
                [
                  {id: 'S/', name: 'S/'},
                  {id: 'Q', name: 'Q'},
                  {id: '$', name: '$'},
                  {id: '€', name: '€'},
                ]
              }/>
            </FormGroup>
          </FlexContainer>
        </Card>

        <Card padding>
          <h4 style={{fontWeight: '600', marginBottom: '16px'}}>Tickets</h4>
          <FlexContainer flexDirection='row' gap='16px'>
            <FormGroup>
              <TextWithHelp label={'Validacion'} help={'Modalidad de validacion.'} />
              <Select disabled={true} {...registerTicket("validationType")} >
                <option key={1} value={1}>{"Secure Enhanced Validation"}</option>
                <option key={2} value={2}>{"System Validation"}</option>
              </Select>
            </FormGroup>
            <FormGroup>
              <TextWithHelp label={'Titulo Ticket'} help={'Setea el título de todos tickets'} />
              <Input type='text' {...registerTicket("location")} />
            </FormGroup>
            <FormGroup>
              <TextWithHelp label={'Expiración'} help={'Dias de expiración global'}/>
              <Input type='number' {...registerTicket("expiration")} />
            </FormGroup>
          </FlexContainer>
          <Button title={"Guardar"} variant={'primary'} onClick={handleSubmitTicket(onCreateTicket)}/>
        </Card>

        <Card padding>
          <h4 style={{fontWeight: '600', marginBottom: '16px'}}>Ludopatía</h4>
          <FlexContainer flexDirection='row' gap='16px'>
            <FormGroup>
              <label>Usuario</label>
              <Input type='text' {...registerConfigLudop("user")} />
            </FormGroup>
            <FormGroup>
              <label>Contraseña</label>
              <Input type='password' {...registerConfigLudop("password")} />
            </FormGroup>
          </FlexContainer>
          <Button title={"Guardar"} variant={'primary'} onClick={handleSubmitConfigLudop(onConfigLudop)}/>
        </Card>

        <Card padding>
          <h4 style={{fontWeight: '600', marginBottom: '16px'}}>Integración SUCTR</h4>

          <FormGroup>
            <label>Sistema online</label>
            <Input type='text' disabled {...register("onlineSystem")} />
          </FormGroup>
          {
            data.onlineSystem !== 'WARGOS' &&
            <FormGroup>
              <Button variant={'primary'} size='xs' onClick={downloadScanner}>Escaner</Button>
            </FormGroup>
          }
          <FlexContainer flexDirection='row' gap='16px'>
            <FormGroup>
              <label>URL publica</label>
              <Input type='text' {...register("onlineUrl")} />
            </FormGroup>
            <FormGroup>
              <label>URL privada</label>
              <Input type='text' {...register("onlineUrlPrivate")} />
            </FormGroup>
          </FlexContainer>
          <FormGroup>
            <label>Usuario</label>
            <Input type='text' {...register("onlineUsername")} />
          </FormGroup>
          <FormGroup>
            <label>Contraseña</label>
            <Input type='password'  {...register("onlinePassword")} />
          </FormGroup>
          <FormGroup>
            <label>Escaneo de contadores</label>
            <SwitchToggle {...register("scanMeter")} />
          </FormGroup>
          <FormGroup>
            <label>Escaneo de sesiones</label>
            <SwitchToggle {...register("scanCard")} />
          </FormGroup>
          <FormGroup>
            <label>Intervalo de escaneo (milisegundos)</label>
            <Input type='text' {...register("periodScan")} disabled={true}/>
          </FormGroup>
          <FormGroup>
            <label>Intervalo de escaneo sesiones (milisegundos)</label>
            <Input type='text' {...register("periodScanSessions")} disabled={true}/>
          </FormGroup>
          <Button title={"Guardar"} variant={'primary'} onClick={handleSubmit(onCreate)}/>
        </Card>
        {
          data.onlineSystem === 'ITS' &&
          <MidasList></MidasList>
        }
      </AsyncBlock>
    </>
  );
};

export default OnlineConfiguration;
