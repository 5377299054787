import React, {useContext, useEffect} from "react";
import axios from "axios";
import {SiteContext} from "../../../../common/context/SiteContext";
import {Apis} from "../../../../../util/Apis";
import FondoForm from "./FondoForm";
import useFetch2 from "../../../../../hooks/useFetch2";

const FondoEdit = ({id, setAction, setTitle}) => {

  const {loading, data} = useFetch2(Apis.CAJA + '/fondos/' + id)

  const update = (params) => {
    axios.put(Apis.CAJA + '/fondos/' + id, params)
      .catch(err => {
        console.log(err)
      })
  }

  useEffect(() => {
    setTitle("Editar fondo")
  }, [])

  return (
    <FondoForm value={data} onSubmit={update} setAction={setAction}/>
  )
}

export default FondoEdit
