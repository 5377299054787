import React, { useContext } from "react";
import { Apis } from "../../../../../util/Apis";
import { SiteContext } from "../../../../common/context/SiteContext";
import GenericModal from "../../../../../modals/GenericModal";
import PointsAwardedForm from "./PointsAwardedForm";
import { usePost } from "../../../../../hooks/usePost";

const NewPointsAwarded = ({ show, onHide }) => {

  const { salaId } = useContext(SiteContext)

  const { mutate } = usePost({
    url: Apis.PC + '/point-gift',
    onSuccess: () => onHide()
  })

  const save = async (params) => {
    params.salaId = salaId
    await mutate(params)
  }

  return (
    <GenericModal
      title={'Otorgar Puntos'}
      body={<PointsAwardedForm onHide={onHide} value={{}} onSubmit={save} />}
      show={show}
      onHide={onHide}
    />
  )
}

export default NewPointsAwarded
