import React, {useContext, useEffect} from "react";
import {Apis} from "../../../../../util/Apis";
import axios from "axios";
import {SiteContext} from "../../../../common/context/SiteContext";
import RelacionAsociadosForm from "./RelacionAsociadosForm";
import useFetch2 from "../../../../../hooks/useFetch2";


const RelacionAsociadosEdit = ({id, setAction, setTitle}) => {

  const {data} = useFetch2(Apis.TGM + '/partners-relation/' + id)

  const {salaId} = useContext(SiteContext)
  const update = (params) => {
    params.id=id
    params.salaId=salaId
    //se podria crear un componente <Id/> para guardar esta propiedad
    return axios.put(Apis.TGM + '/partners-relation/'+id, params)
  }

  useEffect(() => {
    setTitle("Edición de asociación")
  }, [])

  return (
    <RelacionAsociadosForm value={data} onSubmit={update} setAction={setAction}/>
  )
}

export default RelacionAsociadosEdit
