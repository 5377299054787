import React from "react";
import TimeAgo from "../../../../components/util/TimeAgo";
import OnlineActive from "./OnlineActive";

export const OnlineActiveValue = ({value}) => (
  <div>
    <OnlineActive valor={value}/>
    {value && <TimeAgo>{value}</TimeAgo>}
  </div>
);
