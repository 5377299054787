import React, {useEffect} from "react";
import {Apis} from "../../../../../util/Apis";
import axios from "axios";
import OwnerForm from "./OwnerForm";

const OwnerNew = ({tenant, setAction, setTitle}) => {

  const save = (params) => {
    params.tenant = tenant
    return axios.post(Apis.TGM + '/partners', params)
  }

  useEffect(() => {
    setTitle("Nueva zona")
  }, [])

  return (
    <OwnerForm value={{}} onSubmit={save} setAction={setAction}/>
  )
}

export default OwnerNew
