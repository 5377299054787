import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Apis} from "../../../../../util/Apis";
import axios from "axios";
import {FlexLayout} from "../../../../../components/flex/Flex";
import Button from "../../../../../components/forms/Button";
import useDeleteModal from "../../../../../modals/useDeleteModal";
import DeleteGenericModal from "../../../../../modals/DeleteGenericModal";
import ReactTable from "../../../../../components/util/ReactTable";
import Moment from "react-moment";
import useModal from "../../../../../modals/useModal";
import ModalInfo from "../../../../../modals/ModalInfo";
import CardShow from "./CardShow";
import useFetch2 from "../../../../../hooks/useFetch2";


const VentaCardDetail = ({customerName,setTitle,customerId,sorteoId}) => {

  useEffect(() => {
    setTitle("Cartillas del cliente " + (customerName ? customerName : ''))
  }, [customerName])
  
  const defaultParams = {customerId: customerId, sorteoId: sorteoId};
  const [params, setParams] = useState(defaultParams)

  const {data,fetchData} = useFetch2(Apis.BINGO + `/bingo-card/by-customer`, [],params);

  const deleteElement = id => axios.delete(Apis.BINGO + `/bingo-card/${id}`)

  const {wrapper: wrapper3, someAction: mostrarAction} = useModal({
    component: <CardShow />
  })

  const {
    wrapper: wrapper2,
    openDeleteModal
  } = useDeleteModal({
    text: '¿Desea eliminar esta cartilla del bingo?',
    action: deleteElement,
    onRefresh: fetchData
  })

  const numberCell = useCallback(props => <>{
    <FlexLayout alignItems='center' justifyContent='center' gap='4px'>
      <span>{props.row.original.serieCardId + '-' + props.renderValue()}</span>
    </FlexLayout>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }</>, []);


  const fechaCell = useCallback(props => <>{
    <FlexLayout alignItems='center' justifyContent='center' gap='4px'>
      <Moment format={'YYYY-MM-DD hh:mm a'}>{props.renderValue()}</Moment>
    </FlexLayout>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }</>, []);

  const actionsValue = useCallback(props => <>{
    <FlexLayout justifyContent='center'>
      <Button variant={'primary'} size={'s'} onClick={() => mostrarAction({ids: {bingoCard: props.row.original.id,
          sorteo: props.row.original.sorteoId}})}>Ver</Button>
      <Button variant={'light'} size={'s'} onClick={() => openDeleteModal(props.row.original.id)}>Eliminar</Button>
    </FlexLayout>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }</>, []);

  const columns = useMemo(
    () => [
      {
        header: 'Nro. de Cartilla',
        accessorKey: 'id',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: numberCell
      },
      {
        header: 'Fecha de creación',
        accessorKey: 'createdAt',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: fechaCell
      },
      {
        header: 'Acciones',
        accessorKey: 'opciones',
        enableGlobalFilter:false,
        enableSorting:false,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: actionsValue
      }
    ]
    , []
  )

  return (
    <>
      <ReactTable
        columns={columns}
        data={data || []}
        noData='No existen resultados'
        pageSize={20}
      />
      <DeleteGenericModal {...wrapper2}/>
      <ModalInfo size="lg" {...wrapper3} />
    </>
  )
}

export default VentaCardDetail
