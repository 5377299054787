import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import Slidebar from "../../../common/layout/Slidebar";
import SalaIdInput from "../../../common/components/SalaIdInput";
import FormGroup from "../../../../components/forms/FormGroup";
import {DatePickerCustom} from "../../../../components/forms/FormEl";
import Button, {ButtonBox} from "../../../../components/forms/Button";
import PageSection from "../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import Card from "../../../../components/forms/Card";
import AsyncBlock from "../../../../components/forms/AsyncBlock";
import {SiteContext} from "../../../common/context/SiteContext";
import {useHistory} from "react-router";
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import {useForm} from "react-hook-form";
import {FlexLayout} from "../../../../components/flex/Flex";
import {AiOutlineCloudDownload} from "react-icons/ai";
import moment from "moment/moment";
import useToggle from "../../../../hooks/useToggle";
import DateFilter from "../../../../components/datefilter/DateFilter";
import CardOverview, {CardOverviewContainer} from "../../../../components/layout/CardOverview";
import {FaPercent} from "react-icons/fa";
import {TbCashBanknote, TbPokerChip} from "react-icons/tb";
import {RiHistoryFill} from "react-icons/ri";
import PageContainerFull from '../../../../components/layout/PageContainerFull';
import ReactTable from '../../../../components/util/ReactTable';

const Score = () => {

  const {salaId} = useContext(SiteContext)
  const history = useHistory()
  const initialDate = moment(new Date()).format('YYYY-MM-DD')
  const {isOpen: slideOpen, toggler} = useToggle({});

  let defaultParams = {salaId: salaId, ini: initialDate, fin: initialDate}

  const [params, setParams] = useState(defaultParams)

  const fechasDefault = {ini: initialDate, fin: initialDate}
  const [fechas, setFechas] = useState(fechasDefault)

  const {loading, data, fetchData, error} = useFetch2(Apis.TABLES + `/tables/report`, [], params);
  const {data: dataSala} = useFetch2(Apis.SALAS + '/salas-config/' + salaId, [])
  const {register, handleSubmit} = useForm({defaultValues: defaultParams});

  const getData = (params) => {
    params.ini = fechas.ini
    params.fin = fechas.fin
    setParams(params)
  }

  useEffect(() => {
    if (params) fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  useEffect(() => {
    setParams({...params, ...fechas})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fechas])

  const excelDownload = () => {
    window.location.href = Apis.TABLES + `/tables/report.xlsx?salaId=${salaId}&ini=${params.ini}&fin=${params.fin}`;
  }

  const sumCoinInTot =  data.filter(item => item !== null).reduce((s, a) => s + a.coinInTotal, 0)

  const sumCoinOutTot = data.filter(item => item !== null).reduce((s, a) => s + a.coinOutTotal, 0)

  const symbol = dataSala && dataSala.currencySymbol !== "" ? dataSala.currencySymbol : "S/"

  const TypeValue = useCallback(props => <span>{props.renderValue()} - {props.row.original.tableName}</span>, []);
  const DefaultValue = useCallback(props => <span>{props.renderValue() === null ? '0': props.renderValue()}</span>, []);
  const WinValue = useCallback(props => <span>{props.row.original.coinInTotal - props.row.original.coinOutTotal}</span>, []);
  const CierreValue = useCallback(props => <span>{props.renderValue() === null ? 'Mesa abierta': props.renderValue()}</span>, []);
  const ClientesValue = useCallback(props => <span>{props.renderValue() === null ? '0': props.renderValue()}</span>, []);
  const HoldValue = useCallback(props =>
    <span>{props.row.original.coinInTotal === 0 || props.row.original.coinInTotal === null ? 0 :
      (((props.row.original.coinInTotal - props.row.original.coinOutTotal) / props.row.original.coinInTotal) * 100).toFixed(2)}%
    </span>,
    []);
  const MoreActions = useCallback(props =>
    <FlexLayout justifyContent='center'>
      <Button variant={'light'} size='xs'
        onClick={() => history.push(`/tables/sesiones?tableId=${props.renderValue()}&ini=${params.ini}&fin=${params.fin}`)}>
        Sesiones
      </Button>
    </FlexLayout>,
    [history,params]);

  const columns = useMemo(
    () => [
      {
        header: 'Mesa',
        accessorKey: 'tableCode',
        meta: {
          headerClassName:'text-center',
          columnClassName:'text-center',
        },
        cell: TypeValue
      },
      {
        header: 'Apertura',
        accessorKey: 'iniAmount',
        meta: {
          headerClassName:'text-center',
          columnClassName:'text-center',
        },
        cell: DefaultValue
      },
      {
        header: 'Drop',
        accessorKey: 'coinInTotal',
        meta: {
          headerClassName:'text-center',
          columnClassName:'text-center',
        },
        cell: DefaultValue
      },
      {
        header: 'Win / Lose',
        id:'winlose',
        accessorFn: cell => `${cell.coinInTotal} ${cell.coinOutTotal}`,
        meta: {
          headerClassName:'text-center',
          columnClassName:'text-center',
        },
        cell: WinValue
      },
      {
        header: 'Rellenos',
        accessorKey:'cashInTotal',
        meta: {
          headerClassName:'text-center',
          columnClassName:'text-center',
        },
        cell: DefaultValue
      },
      {
        header: 'Devoluciones',
        accessorKey:'cashOutTotal',
        meta: {
          headerClassName:'text-center',
          columnClassName:'text-center',
        },
        cell: DefaultValue
      },
      {
        header: 'Cierre',
        accessorKey:'cierre',
        meta: {
          headerClassName:'text-center',
          columnClassName:'text-center',
        },
        cell: CierreValue
      },
      {
        header: 'Hold',
        id:'id',
        accessorFn: cell => `${cell.coinInTotal} ${cell.coinOutTotal}`,
        meta: {
          headerClassName:'text-center',
          columnClassName:'text-center',
        },
        cell: HoldValue
      },
      {
        header: 'Jugadores',
        accessorKey: 'players',
        meta: {
          headerClassName:'text-center',
          columnClassName:'text-center',
        },
        cell: ClientesValue
      },
      {
        header: 'Acciones',
        accessorKey: 'tableId',
        enableGlobalFilter:false, // para deshabilitar el filter
        enableSorting:false, // para deshabilitar el sort
        meta: {
          headerClassName:'text-center',
          columnClassName:'text-center',
        },
        cell: MoreActions
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ], [])

  return (
    <PageContainerFull>
      <Slidebar visible={slideOpen} onHide={toggler}>
        <div style={{padding: '16px'}}>
          <SalaIdInput {...register("salaId")} />
          <FormGroup>
            <label>Desde</label>
            <DatePickerCustom {...register("ini")} />
          </FormGroup>

          <FormGroup>
            <label>Hasta</label>
            <DatePickerCustom {...register("fin")} />
          </FormGroup>

          <Button variant={'primary'} title='Buscar' onClick={handleSubmit(getData)} />
        </div>
      </Slidebar>

      <PageSection>
          <Title text="Score" type={'page'}/>

        <Card padding style={{background: '#0A0A0A'}}>
          <DateFilter fechas={fechas} setFechas={setFechas} />
        </Card>
        <ButtonBox>
          <Button variant='dark' onClick={() => history.push(`/tables/report-by-hour?&ini=${params.ini}&fin=${params.fin}`)}>
            <RiHistoryFill />
            <span>Detalle por horas</span>
          </Button>

          <Button variant='outline' onClick={() => excelDownload()}>
            <AiOutlineCloudDownload />
            <span>Descargar consolidado</span>
          </Button>
        </ButtonBox>
        <div style={{paddingTop: "20px"}}>
          <CardOverviewContainer gridColumns='repeat(3, 1fr)'>

            <CardOverview
              title={symbol + " " + sumCoinInTot.toFixed(2)}
              subtitle='Drop'
              icon={<TbCashBanknote size={26} />}
            />

            <CardOverview
              title={symbol + " " + (sumCoinInTot - sumCoinOutTot).toFixed(2)}
              subtitle='Win / Lose'
              icon={<TbPokerChip size={26} />}
            />

            <CardOverview
              title={sumCoinInTot !==0 ? ((sumCoinInTot - sumCoinOutTot)*100 /sumCoinInTot).toFixed(0) : 0}
              subtitle='Hold'
              icon={<FaPercent size={16} />}
            />

          </CardOverviewContainer>
        </div>

        <AsyncBlock loading={loading} error={error}>
          <ReactTable
            columns={columns}
            data={data || []}
            filterOnclick={toggler}
            filterRefresh={() => fetchData()}
            noData='No existen resultados para el filtro seleccionado'
            pageSize={15}
          />
        </AsyncBlock>
      </PageSection>
    </PageContainerFull>
  );
};

export default Score;
