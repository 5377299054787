import React from "react"
import {Route, Switch} from "react-router"
import TablesHome from "./TablesHome"
import MesasConfig from "./pages/tables/MesasConfig"
import Score from "./pages/tables/Score"
import MesasReportSession from "./pages/tables/MesasReportSession"
import MesasTransaction from "./pages/tables/MesasTransaction"
import MesasReportByHour from "./pages/tables/MesasReportByHour"
import MesasDetail from "./pages/tables/MesasDetail"
import DealerList from "./pages/tables/DealerList";
import MesasReport from "./pages/tables/MesasReport";
import DealerReport from "./pages/tables/DealerReport";

const TablesRoutes = () => {
  const parentRoute = '/tables'
  return (
    <Switch>
      <Route path={parentRoute + '/report-online'} component={MesasReport}/>
      <Route path={parentRoute + '/report-dealer'} component={DealerReport}/>
      <Route path={parentRoute + '/config'} component={MesasConfig}/>
      <Route path={parentRoute + '/mesas'} component={Score}/>
      <Route path={parentRoute + '/sesiones'} component={MesasReportSession}/>
      <Route path={parentRoute + '/transacciones'} component={MesasTransaction}/>
      <Route path={parentRoute + '/report-by-hour'} component={MesasReportByHour}/>
      <Route path={parentRoute + '/detail-session'} component={MesasDetail}/>
      <Route path={parentRoute + '/dealer'} component={DealerList}/>
      <Route path="/" component={TablesHome}/>
    </Switch>
  )
}

export default TablesRoutes;
