import React, {useEffect} from 'react';
import {Controller, useForm} from "react-hook-form";
import FormGroup from "../../../../../components/forms/FormGroup";
import Input from "../../../../../components/forms/FormEl";
import ProductManualRedeemSelect from "../../../../common/components/selects/ProductManualRedeemSelect";
import CustomerSearchInputV2 from "../../../../common/components/CustomerSearchInputV2";
import PrinterSelect from "../../../../common/components/selects/PrinterSelect";

const ManualRedeemForm = ({value, onSubmit, setAction}) => {

  const {register, handleSubmit, reset, control} = useForm()


  useEffect(() => {
    if (value) {
      if (Object.keys(value).length !== 0) {
        reset(value)
      }
    }
  }, [value]);

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit))
  }, [])

  return (
    <React.Fragment>

      <FormGroup>
        <label>Producto</label>
        <ProductManualRedeemSelect type='text' {...register("productId")}/>
      </FormGroup>

      <FormGroup>
        <label>Cliente</label>
        <Controller name="customerId" control={control} render={({ field }) => <CustomerSearchInputV2 {...field} />}/>
      </FormGroup>

      <FormGroup>
        <label>Puntos</label>
        <Input type='number' {...register("points")} />
      </FormGroup>

      <FormGroup>
        <label>Impresora</label>
        <PrinterSelect {...register("printerId")}/>
      </FormGroup>

    </React.Fragment>
  );
};

export default ManualRedeemForm;
