import React, {useEffect} from 'react'
import Form from "react-bootstrap/Form";
import FormGroup from "../../../../../components/forms/FormGroup";
import Input from "../../../../../components/forms/FormEl";
import {useForm} from "react-hook-form";

const OwnerForm = ({value, onSubmit, setAction}) => {

  const {register, handleSubmit, reset} = useForm();

  useEffect(() => {
    if (value) {
      reset(value)
    }
  }, [value])

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit))
  }, [])

  return (
    <Form>
      <FormGroup>
        <label>Nombre:</label>
        <Input type="text" {...register("name")}/>
      </FormGroup>
      <FormGroup>
        <label>RUC:</label>
        <Input type="text"  {...register("ruc")}/>
      </FormGroup>
      <FormGroup>
        <label>Dirección:</label>
        <Input type="text" {...register("address")}/>
      </FormGroup>
    </Form>
  )

}

export default OwnerForm
