import React, {useContext, useEffect} from "react";
import axios from 'axios'
import {Apis} from "../../../../util/Apis";
import {SiteContext} from "../../../common/context/SiteContext";
import ManagerForm from "./ManagerForm";

const NewManager = ({setAction, setTitle}) => {
  const {salaId} = useContext(SiteContext)

  const save = (params) => {
    params.salaId = salaId;
    return axios.post(Apis.SORTEO + '/sorteo-responsable', params);
  }

  useEffect(() => {
    setTitle("Nuevo responsable")
  }, [])

  return (
    <>
      <ManagerForm value={{}} onSubmit={save} setAction={setAction} />
    </>
  )

}

export default NewManager
