import React from "react";
import {Title} from "@wargostec/react-components";
import PageSection from "../../components/layout/PageSection";
import useFetch2 from "../../hooks/useFetch2";
import {Apis} from "../../util/Apis";
import AsyncBlock from "../../components/forms/AsyncBlock";
import CardOverview, {CardOverviewContainer} from "../../components/layout/CardOverview";
import {TbCircleNumber1, TbCircleNumber2, TbCircleNumber3} from "react-icons/tb";
import {FlexLayout} from "../../components/flex/Flex";
import Moment from "react-moment";
import Table from 'react-bootstrap/Table';
import PageContainer from "../../components/layout/PageContainer";
import EmptyState from "../common/components/EmptyState";
import {LiaCoinsSolid} from "react-icons/lia";
import Card from "../../components/forms/Card";
import {LuListPlus} from "react-icons/lu";
import Button from "../../components/forms/Button";

const BingosHome = () => {

  const {loading, data, error} = useFetch2(Apis.BINGO + `/jackpot/sala`, []);

  const {loading: loadingPartidas, data: dataPartidas, error: errorPartidas} = useFetch2(Apis.BINGO + `/sorteo/daily`, []);

  const getSubtitle = (form) => {
    switch (form) {
      case "Line":
        return {text: "LINEA", color: "#2563eb", icon: <TbCircleNumber3 size={24} />};
      case "LineV":
        return {text: "LINEA VERTICAL", color: "#2563eb", icon: <TbCircleNumber3 size={24} />};
      case "LineH":
        return {text: "LINEA HORIZONTAL", color: "#2563eb", icon: <TbCircleNumber3 size={24} />};
      case "Blackout":
        return {text: "BINGO", color: "#37ba32", icon: <TbCircleNumber2 size={24} />};
      case "Mega":
        return {text: "MEGA", color: "#dc2626", icon: <TbCircleNumber1 size={24} />};
      default:
        return {text: form, color: "#FFF"};
    }
  };

  const getStatus = (status) => {
    switch (status) {
      case "ON_HOLD":
        return {btn: <Button variant='primary' size={'s'}>EN VENTA</Button>};
      case "IN_PROGRESS":
        return {btn: <Button variant='warning' size={'s'}>EN PROGRESO</Button>};
      case "FINISHED":
        return {btn: <Button variant='danger' size={'s'}>FINALIZADO</Button>};
      default:
        return {btn: <Button variant='primary' size={'s'}>EN VENTA</Button>};
    }
  };

  return (
    <PageContainer>
      <PageSection>
          <Title text="Jackpots activos" type={'page'}/>

        <AsyncBlock loading={loading} error={error}>
          {data && data.length > 0 ? (
            <CardOverviewContainer gridColumns='repeat(3, 1fr)' style={{marginBottom: '2rem'}}>
              {data.map((item) => {
                const subtitleInfo = getSubtitle(item.form);
                return (
                  <CardOverview
                    key={item.id}
                    title={item.actualAmount.toFixed(2)}
                    subtitle={subtitleInfo.text}
                    bgcolor={subtitleInfo.color}
                    icon={subtitleInfo.icon}
                    body={
                      <FlexLayout margin='8px 0 0 0' justifyContent='flex-end'>
                        <span style={{fontSize: '16px'}}>Bolita: <strong>{item.ballRaise ? item.actualBall : item.winBall}</strong></span>
                      </FlexLayout>
                    }
                  />
                );
              })}
            </CardOverviewContainer>
          ) : (
            <Card padding>
              <EmptyState
                title={'Aún no hay jackpots creados'}
                subtitle={'Agregue jackpots en el menu de Jackpots'}
                icon={<LiaCoinsSolid size={32} />}
              />
            </Card>
          )}
        </AsyncBlock>
      </PageSection>
      <PageSection>
          <Title text="Partidas del dia de hoy" type={'page'}/>
        {dataPartidas && dataPartidas.length > 0 ?
          <Table striped hover responsive>
            <thead>
              <tr>
                <th className="text-center">Fecha</th>
                <th className="text-center">Hora</th>
                <th>Bingo</th>
                <th className="text-center">Nro bolillas</th>
                <th className="text-center">Premios</th>
                <th className="text-center">Estado</th>
              </tr>
            </thead>
            <tbody>
              {dataPartidas.map((item) => {

                return (
                  <tr key={item.sorteo.id}>
                    <td className="text-center"><Moment format={'YYYY-MM-DD'}>{item.sorteo.startDate}</Moment></td>
                    <td className="text-center"><Moment format={'HH:mm'}>{item.sorteo.startDate}</Moment></td>
                    <td>{item.sorteo.eventName}</td>
                    <td className="text-center">{item.sorteo.type}</td>
                    <td>
                      <ul>
                        {item.prizes.map((prize) => (
                          <li key={prize.id}>
                            <span>{getSubtitle(prize.form).text}:  <strong>{prize.monto.toFixed(2)}</strong></span>
                          </li>
                        ))}
                      </ul>
                    </td>
                    <td className="text-center">{getStatus(item.sorteo.status).btn}</td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
          :
          <Card padding>
            <EmptyState
              title={'No hay partidas creadas para el día de hoy'}
              subtitle={'Agregue partidas en el menu de Lista de partidas'}
              icon={<LuListPlus size={28} />}
            />
          </Card>
        }
      </PageSection>
    </PageContainer>
  )
}

export default BingosHome;
