import React, {useContext} from 'react'
import {Select} from "../../../../components/forms/FormEl";
import {Apis} from "../../../../util/Apis";
import {SiteContext} from "../../context/SiteContext";
import useFetch2 from "../../../../hooks/useFetch2";

const BingoEventSelect = React.forwardRef((props, ref) => {

  const {salaId} = useContext(SiteContext)
  const {data} = useFetch2(Apis.BINGO + `/event?salaId=${salaId}`,[])

  return (
    <Select ref={ref} {...props} >
      <option value={0}></option>
      {data && data.map(item => <option key={item.id} value={item.id}>{item.name}</option>)}
    </Select>
  )
})

export default BingoEventSelect
