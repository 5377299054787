import React from "react";
import Table from "react-bootstrap/Table";
import Button, {ButtonBox} from "../../../../../components/forms/Button";
import {useHistory, useParams} from "react-router";
import axios from 'axios'
import DeleteGenericModal from "../../../../../modals/DeleteGenericModal";
import useDeleteModal from "../../../../../modals/useDeleteModal";
import NoDataTable from "../../../../common/components/NoDataTable";
import Loader1 from "../../../../common/components/Loader1";
import useFetch2 from "../../../../../hooks/useFetch2";

const GroupUsers = () => {

  const history = useHistory();
  const {groupName, id} = useParams()
  const addUsersPage = () => {
    const parentRoute = '/admin'
    history.push(parentRoute + `/groups/edit/${id}/${groupName}/add-users`)
  }


  const {data, loading, fetchData} = useFetch2(`http://localhost:9081/user-group/${id}/users`, [], null, false)

  const deleteElement = userId => axios.delete(`http://localhost:9081/user-group/${id}/users/${userId}`)

  const {wrapper: wrapper2, openDeleteModal} = useDeleteModal({
    text: '¿Desea retirar usuario del grupo?',
    action: deleteElement,
    onRefresh: fetchData
  })

  return !loading && (
    <React.Fragment>
      <Button title={"Añadir Usuarios"} variant={'primary'} style={{marginTop: "25px", marginBottom: "20px"}}
              onClick={() => addUsersPage()}/>
      <Table striped hover>
        <thead>
        <tr>
          <th>ID</th>
          <th>Nombre</th>
          <th>DNI</th>
          <th>Celular</th>
          <th>Correo</th>
          <th/>
        </tr>
        </thead>
        <tbody>
        {data.data && data.data.map(item =>
          <tr key={item.id}>
            <td>{item.id}</td>
            <td>{item.name}</td>
            <td>{item.document}</td>
            <td>{item.cellphone}</td>
            <td>{item.email}</td>
            <td style={{display: "flex", justifyContent: "flex-end"}}>
              <ButtonBox>
                <Button onClick={() => openDeleteModal(item.id)}>Quitar</Button>
              </ButtonBox>
            </td>
          </tr>
        )}
        </tbody>
      </Table>
      {!loading ? data.data.length <= 0 && <NoDataTable text={"Este grupo no presenta usuarios"}/> : <Loader1/>}
      <DeleteGenericModal {...wrapper2}/>
    </React.Fragment>
  )
}

export default GroupUsers
