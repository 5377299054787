import React from "react";
import Button from "../../../../components/forms/Button";
import styled from "styled-components";
import {AnimatePresence, motion} from "framer-motion";
import MachineSearchInput from "../../../common/components/MachineSearchInput";
import {Controller, useForm} from "react-hook-form";
import {errorToast} from "../../../../components/layout/ToastStyles";
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import {FlexLayout} from "../../../../components/flex/Flex";

const ControlsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items:center;
  gap: 8px;
  border-radius:8px;
  box-shadow: rgb(0 0 0 / 10%) 0 2px 4px;
  background:white;
  padding:16px;
`

const ButtonContainer = styled.div`
  display: flex;
  gap: 4px;
`

const LayoutSearchMachine = ({zoomToElement, resetTransform, searchVisible, setSelectedMachine}) => {

  const {data: dataMachines} = useFetch2(`${Apis.TGM}/machines?enabled=true`, [])

  const {control, watch, reset} = useForm({
    defaultValues: {
      confId: null
    }
  })

  const confId = watch('confId');

  const elementoEncontrado = dataMachines.find(elemento => elemento.confId === confId);

  const handleSearch = () => {
    if (elementoEncontrado && elementoEncontrado.positionId) {
      zoomToElement(elementoEncontrado.positionId.toString(), 2.5, 300, 'easeOut');
      setSelectedMachine(elementoEncontrado);
    } else {
      errorToast('No se encontró la máquina o la posición');
    }
  };

  const handleReset = () => {
    resetTransform();
    reset({ confId: null });
    setSelectedMachine(null);
  };

  return (
    <AnimatePresence>
      {searchVisible && (
        <motion.div
          initial={{opacity: 0, y: -20}}
          animate={{opacity: 1, y: 0}}
          exit={{opacity: 0, y: -20}}
          style={{display: 'flex', flexDirection: 'column', width: '220px'}}
        >
          <ControlsContainer>
            <strong>Máquina</strong>
            <FlexLayout column gap='4px'>
              <Controller
                name="confId"
                control={control}
                render={({field}) => <MachineSearchInput {...field} />}
              />
            </FlexLayout>
            <ButtonContainer>
              <Button size="s" variant='dark' onClick={handleSearch} disabled={confId === null}>
                Buscar
              </Button>
              {elementoEncontrado !== undefined &&
                <Button
                  size="s"
                  onClick={handleReset}
                  disabled={confId === null}
                >
                  Limpiar
                </Button>
              }
            </ButtonContainer>
          </ControlsContainer>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default LayoutSearchMachine;
