import React, {useContext, useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import FormGroup from "../../../../../components/forms/FormGroup";
import Input from "../../../../../components/forms/FormEl";
import DinamicGroupSelect from "../../../../common/components/selects/DinamicGroupSelect";
import DateTime from "../../../../../components/layout/DateTime";
import SwitchToggle from "../../../../../components/layout/SwitchToggle";

const CustomerGroupNotifForm = ({value, onSubmit, setAction}) => {

  const {register, control, handleSubmit, watch, errors, reset, getValues, setValue} = useForm();
  const [dinamicGroups, setDinamicGroups] = useState(false);
  const [cuoponsGroups, setCuoponsGroups] = useState(false);
  useEffect(() => {
    if (value) {
      reset(value)
    }
  }, [value])

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit));
  }, [])

  function changeView(){
    setDinamicGroups(!dinamicGroups)
  }

  const handleChange = e =>{
    
    if(e.target.value == 2){
      setCuoponsGroups(true)
    }
    else{
      setCuoponsGroups(false)
    }
  }

  return (
    <React.Fragment>
      <FormGroup style={{display: 'flex', justifyContent: 'space-between', fontSize: '18px'}}>
        <label>Grupos autogenerados</label>
        <SwitchToggle id={"enabled"} name={"enabled"} label={"Habilitado"} ref={register}  onSwitch={() => changeView()}/>
      </FormGroup>
      
     {!dinamicGroups?
      <FormGroup>
        <label>Nombre del grupo</label>
        <Input {...register("name")}/>
      </FormGroup>
     
     : 
      <div>

        <FormGroup>
          <label>Grupos Autogenerados</label>
          <DinamicGroupSelect changeFunction = {handleChange} name='customerGroupId' ref={register} todos={0}/>
        </FormGroup>

        {
          cuoponsGroups && 
          <FormGroup>
            <label>Mínimo número de cupones</label>
            <Input type="number" name="min" ref={register}/>
            <label>Máximo número de cupones</label>
            <Input type="number" name="max" ref={register}/>
          </FormGroup>
        }
        
      </div>
     }
      
    </React.Fragment>
  )

}

export default CustomerGroupNotifForm
