import React, {useEffect, useState} from 'react';
import PageContainer from "../../../../components/layout/PageContainer";
import {Title} from "@wargostec/react-components";
import Button, {ButtonBox} from "../../../../components/forms/Button";
import PageSection from "../../../../components/layout/PageSection";
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import Card from "../../../../components/forms/Card";
import {CouponGridContainer} from "../coupon-type/CouponTypeList";
import {useHistory} from "react-router";
import axios from "axios";
import AsyncBlock from "../../../../components/forms/AsyncBlock";

const SiteList = () => {

  const history = useHistory();
  const [site, setSite] = useState([])
  const [loading, setLoading] = useState(false)
  const [tenant, setTenant] = useState("")

  const {data} = useFetch2(Apis.USER + '/users/me', null)

  const goToDetail = (id) => {
    history.push('/crm/sites-edit/' + id)
  }

  const goToNew = () => {
    history.push(`/crm/sites-new?tenant=${tenant}`)
  }

  const getSites = (tenant) => {
    setLoading(true)
    setTenant(tenant)
    axios.get(`${Apis.CUSTOMERAPP}/site-info?tenant=${tenant}`, {})
      .then((res) => {
        setLoading(false)
        setSite(res.data);
      }).catch(err => {
      setLoading(false)
    })
  }

  useEffect(() => {
    if (data) {
      getSites(data.user.company)
    }
  }, [data])

  return (
    <PageContainer>
      <PageSection>
        <Title text="Sitios web"/>
        <ButtonBox>
          <Button variant={'primary'} title={'Nuevo'} onClick={goToNew}/>
        </ButtonBox>
      </PageSection>

      <PageSection>
        <AsyncBlock loading={loading}>
          <CouponGridContainer>
            {data && site.map(site =>
              <Card key={site.id} padding style={{cursor: 'pointer'}}
                    onClick={() => goToDetail(site.id)}
              >
                {site.name}
              </Card>)}
          </CouponGridContainer>
        </AsyncBlock>
      </PageSection>


    </PageContainer>
  );
};

export default SiteList;
