import React, {useCallback, useMemo} from 'react';
import useFetch2 from "../../../../../hooks/useFetch2";
import {Apis} from "../../../../../util/Apis";
import useEditModal from "../../../../../modals/useEditModal";
import SalesProfitNew from "./SalesProfitNew";
import SalesProfitEdit from "./SalesProfitEdit";
import PageSection from "../../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import AsyncBlock from "../../../../../components/forms/AsyncBlock";
import ReactTable from "../../../../../components/util/ReactTable";
import GenericModal2 from "../../../../../modals/GenericModal2";
import PageContainer from "../../../../../components/layout/PageContainer";
import {FlexLayout} from "../../../../../components/flex/Flex";
import Button from "../../../../../components/forms/Button";
import useDeleteModal from "../../../../../modals/useDeleteModal";
import axios from "axios";
import {PageBackLabel} from '../../../../../components/layout/PageBackLabel';
import {useHistory} from "react-router";
import DeleteGenericModal from "../../../../../modals/DeleteGenericModal";

const SalesProfit = () => {

  const {loading, data, fetchData, error} = useFetch2(Apis.BINGO + `/range-sales-profit`, []);
  let history = useHistory()

  const {wrapper: wrapper1, addAction, editAction} = useEditModal({
    addComponent: <SalesProfitNew/>,
    editComponent: <SalesProfitEdit/>,
    onRefresh: fetchData
  })

  const deleteElement = id => axios.delete(Apis.BINGO + `/range-sales-profit/${id}`)

  const {
    wrapper: wrapper2,
    openDeleteModal
  } = useDeleteModal({
    text: '¿Desea eliminar este rango?',
    action: deleteElement,
    onRefresh: fetchData
  })

  const actionsValue = useCallback(props => <>{
    <FlexLayout justifyContent='center'>
      <Button variant={'primary'} size={'s'} onClick={() => editAction(props.renderValue())}>Editar</Button>
      <Button variant={'light'} size={'s'} onClick={() => openDeleteModal(props.renderValue())}>Eliminar</Button>
    </FlexLayout>
  }</>, []);

  const columns = useMemo(
    () => [
      {
        header: 'Id',
        accessorKey: 'id',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'De',
        accessorKey: 'fromMoney',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Hasta',
        accessorKey: 'toMoney',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Ganancia',
        accessorKey: 'percentage',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Comisión',
        accessorKey: 'commission',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Acciones',
        accessorKey: 'id',
        enableGlobalFilter:false,
        enableSorting:false,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: actionsValue
      }
    ]
    , []
  )

  return (
    <PageContainer>
      <PageSection>
        <PageBackLabel text='Lista de eventos' onClick={() => history.goBack()}/>
          <Title text="Lista de rangos de venta"/>

        <AsyncBlock loading={loading} error={error}>
          <ReactTable
            columns={columns}
            data={data || []}
            filterAdd={() => addAction()}
            customSearch
            noData='No existen resultados'
            pageSize={15}
          />
        </AsyncBlock>

      </PageSection>

      <GenericModal2 {...wrapper1}/>
      <DeleteGenericModal {...wrapper2}/>
    </PageContainer>
  );
};

export default SalesProfit;
