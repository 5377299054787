import React, {useCallback, useMemo} from 'react';
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import Moment from "react-moment";
import PageContainerFull from "../../../../components/layout/PageContainerFull";
import PageSection from "../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import AsyncBlock from "../../../../components/forms/AsyncBlock";
import ReactTable from "../../../../components/util/ReactTable";
import styled from "styled-components";

const Tag = styled.span`
  display: inline-block;
  background-color: ${(props) => props.color || 'gray'};
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  position: relative;
  margin-left: 10px;
`;

const Arrow = styled.span`
  position: absolute;
  left: -8px;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid ${(props) => props.color || 'gray'};
`;
const FacturasList = () => {

  const {loading, data, fetchData, error} = useFetch2(Apis.BILLING + '/invoices', []);

  const dateInvoce = useCallback(props => <Moment format={'YYYY-MM-DD'}>{props.renderValue()}</Moment>, []);
  const dateInvocePeriod = useCallback(props => <Moment format={'YYYY-MM-DD'}>{props.renderValue()}</Moment>, []);

  const monto = useCallback(props => <>{
    props.row.original.total ? props.row.original.currencyCode + ' ' + props.row.original.total : '--'
  }</>, []);

  const status = useCallback(props => <>{
    props.row.original.status ?  <>
      <Tag color={getColorByStatus(props.row.original.status)}>
        <Arrow color={getColorByStatus(props.row.original.status)}/>
        {props.row.original.status}</Tag>
    </> : '--'
  }</>, []);

  const getColorByStatus = (status) => {
    switch (status) {
      case 'Emitido':
        return 'yellow';
      case 'Pagado':
        return 'green';
      case 'Pago incompleto':
        return '#F75A54';
      case 'Vencido':
        return '#F75A54 ';
      default:
        return 'gray';
    }
  }

  const columns = useMemo(() => [
    {
      header: 'Fecha de emisión',
      accessorKey: 'date',
      meta: {
        headerClassName: 'text-center',
        columnClassName: 'text-center',
      },
      cell: dateInvoce
    },
    {
      header: 'Fecha de vencimiento',
      accessorKey: 'dueDate',
      meta: {
        headerClassName: 'text-center',
        columnClassName: 'text-center',
      },
      cell: dateInvocePeriod
    },
    {
      header: 'Empresa',
      accessorKey: 'clientName',
      meta: {
        headerClassName: 'text-center',
        columnClassName: 'text-center',
      },
    },
    {
      header: 'N° Factura',
      accessorKey: 'invoiceNumber',
      meta: {
        headerClassName: 'text-center',
        columnClassName: 'text-center',
      },
    },
    {
      header: 'Total',
      accessorKey: 'total',
      meta: {
        headerClassName: 'text-center',
        columnClassName: 'text-center',
      },
      cell: monto
    },
    {
      header: 'Descripción',
      accessorKey: 'description',
      meta: {
        headerClassName: 'text-center',
        columnClassName: 'text-center',
      },
    },
    {
      header: 'Estado de pago',
      accessorKey: 'status',
      meta: {
        headerClassName: 'text-center',
        columnClassName: 'text-center',
      },
      cell: status
    },
  ], [])

  return (
    <PageContainerFull>
      <PageSection>
          <Title text="Facturación" type={'page'}/>
        <AsyncBlock loading={loading} error={error}>
          <ReactTable
            columns={columns}
            data={data || []}
            noData='No existen facturas pendientes para el filtro seleccionado'
            pageSize={15}
          />
        </AsyncBlock>
      </PageSection>

    </PageContainerFull>
  );
};

export default FacturasList;
