import React, {useEffect} from 'react';
import {useParams} from "react-router";
import axios from "axios";
import {Apis} from "../../../../../util/Apis";
import LevelProgressiveForm from "./LevelProgressiveForm";

const LevelProgressiveNew = ({setAction, setTitle}) => {
  const {id} = useParams()

  const save = async (params) => {
    params.progressiveId = id
    await axios.post(Apis.TGM + `/progressive-level`, params)
  }

  useEffect(() => {
    setTitle("Nuevo Nivel")
  }, [])

  return (
    <LevelProgressiveForm value={{}} onSubmit={save} setAction={setAction}/>
  );
};

export default LevelProgressiveNew;
