import React, {useEffect} from 'react';
import {useForm} from "react-hook-form";
import FormGroup from "../../../../components/forms/FormGroup";
import Input, {Select, SelectV2} from "../../../../components/forms/FormEl";
import Form from "react-bootstrap/Form";

const MoneyDenomForm = ({value, onSubmit, setAction}) => {

  const {register, handleSubmit, reset, control} = useForm();

  useEffect(() => {
    if (value && Object.keys(value).length > 0) {
      reset(value)
    }
  }, [value])

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit))
  }, [])

  return (
    <Form>

      <FormGroup>
        <label>Denominacion</label>
        <Input type="number" {...register("value")}/>
      </FormGroup>
      <FormGroup>
        <label>Tipo</label>
        <Select {...register("type")}>
          <option key={1} value={1}>Moneda</option>
          <option key={2} value={2}>Billete</option>
          <option key={3} value={3}>Ficha</option>
        </Select>
      </FormGroup>
      <FormGroup>
        <label>Moneda</label>
        <SelectV2 {...register('currency')} options={
          [
            {id: 'PEN', name: 'PEN'},
            {id: 'USD', name: 'USD'},
            {id: 'EUR', name: 'EUR'},
            {id: 'COP', name: 'COP'},
            {id: 'GTQ', name: 'GTQ'},
          ]
        }/>
      </FormGroup>
      <FormGroup>
        <label>Simbolo</label>
        <Input type="text" {...register("currencySymbol")}/>
      </FormGroup>
    </Form>
  );
};

export default MoneyDenomForm;
