import React, {useContext} from "react";
import PageSection from "../../../../components/layout/PageSection";
import FormGroup, {FormBlock} from "../../../../components/forms/FormGroup";
import Input from "../../../../components/forms/FormEl";
import {useForm} from "react-hook-form";
import {SiteContext} from "../../../common/context/SiteContext";
import Button from "../../../../components/forms/Button";
import PageContainer from "../../../../components/layout/PageContainer";
import {useHistory} from "react-router";
import {Title} from "@wargostec/react-components";

const CTicketExchange = () => {

  const {salaId} = useContext(SiteContext)
  const history = useHistory()
  let defaultParams = {salaId: salaId, enabled: true}
  const {register, control, handleSubmit, watch, errors, reset} = useForm({defaultValues: defaultParams});

  /* const onCreate = (params) => {
     params.salaId = salaId
     params.fondoId=123
     axios.post(Apis.CAJA + '/refill', params).then(res => {
       history.push('/refill')
     }).catch(err => {

     })
   }*/

  return (
    <PageContainer>
      <PageSection>
          <Title text="Canje de tickets" type={'page'}/>
      </PageSection>

      <PageSection>
        <FormBlock>
          <FormGroup>
            <label>Código de ticket</label>
            <Input {...register("amount")}/>
          </FormGroup>
          <FormGroup>
            <Button title={"Limpiar"} variant={'primary'}/>
          </FormGroup>
        </FormBlock>
      </PageSection>
    </PageContainer>
  )
}

export default CTicketExchange
