import React, {useContext, useEffect, useState} from 'react'
import {SelectV2} from "../../../../components/forms/FormEl";
import {Apis} from "../../../../util/Apis";
import useFetch2 from '../../../../hooks/useFetch2';
import {SiteContext} from '../../context/SiteContext';

const FondoActiveSelect = React.forwardRef((props, ref) => {

  const {salaId} = useContext(SiteContext)
  const {onChange, ...restProps} = props;
  const {data, loading} = useFetch2(`${Apis.CAJA}/fondos?salaId=${salaId}&active=true`, []);
  const [newData, setNewData] = useState([{id: '', name: 'Seleccione'}]);

  useEffect(() => {
    if (data) {
      setNewData([{id: '', name: 'Seleccione'}, ...data]);
    }
  }, [data]);

  return (
    <>
      {!loading ?
        <SelectV2 ref={ref} options={newData} onChange={onChange} {...restProps} />
        :
        <SelectV2 disabled options={[{id: 0, name: 'Cargando...'}]}/>
      }
    </>
  )
})

export default FondoActiveSelect
