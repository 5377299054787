import React, {useContext, useEffect, useState} from 'react'
import FormGroup from "../../../../components/forms/FormGroup";
import Input from "../../../../components/forms/FormEl";
import {SiteContext} from "../../../common/context/SiteContext";
import {Controller, useForm} from "react-hook-form";
import CustomerSearchInputV2 from "../../../common/components/CustomerSearchInputV2";
import ManagerSelect from '../../../common/components/selects/ManagerSelect';
import {Apis} from "../../../../util/Apis";
import RequiredLabel from "../../../../components/util/RequiredLabel";
import axios from "axios";
import Button from "../../../../components/forms/Button";
import ButtonGroup from "../../../../components/forms/ButtonGroup";
import useFetch2 from "../../../../hooks/useFetch2";
import ActaImpresionInput from "./ActaImpresionInput";


const SorteoAsignarGanador = ({onSubmit, setAction, kind}) => {
  const {salaId} = useContext(SiteContext)
  const defaultParams = {salaId: salaId}

  const {data: data9} = useFetch2(Apis.SORTEO + '/sorteo-conf', []);

  const [couponValidation, setCouponValidation] = useState(false)
  const [actaValidation, setActaValidation] = useState(false)
  const [active, setActive] = useState(false)
  const [coupon, setCoupon] = useState('')
  const [exists, setExists] = useState(false)
  const [tab, setTab] = useState(false)
  const [imprimir,setImprimir] = useState(true)

  const [formSelect, setFormSelect] = useState('coupon')

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control
  } = useForm({defaultValues: defaultParams});

  const changeForm = (e) => {
    if (e === 'customer') {
      setActive(true)
    } else {
      setActive(false)
      setExists(false)
    }
  }

  useEffect(() => {
    if (data9) {
      console.log('seteando nroacta')
      setValue('nroActa', data9.nroActa);
    }
  }, [data9])

  const getCupon = () => {
    axios.get(`${Apis.PC}/coupon/${coupon}/validate-dto?salaId=${salaId}`, {})
      .then(res => {
        setTab(true)
        setCoupon(res.data)
        setValue('couponId', res.data.couponCode)
        setValue('customerId', res.data.customerId)
        setValue('confId', res.data.confId)
        setExists(false)
      }).catch(() => {
      setExists(true)
      setTab(false)
    })

  }

  const changeImprimir = () => {
    if(!imprimir) setValue('printerId','')
    setImprimir(!imprimir)
  }

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit))
    //setAction(() => conso)
  }, [])

  const handleFieldBlur = (valueName, setValidation) => {
    const propiedad = getValues()[valueName]
    setValidation(propiedad.length === 0)
  }

  useEffect(() => {
    changeForm(formSelect)
  }, [formSelect])

  return (
    <div>
      <div className={'mb-4'}>
        <ButtonGroup
          value={formSelect}
          onChange={e => setFormSelect(e)}
          data={[{text: 'Por cupon', value: 'coupon'}, {text: 'Por cliente', value: 'customer'}]}
        >
        </ButtonGroup>
      </div>

      {active === false ?
        <div>
          <FormGroup>
            <label>Codigo cupón:</label>
            <Input
              type="number"
              className="no-spinner"
              {...register("couponValidate", {
                onBlur: () => handleFieldBlur('couponValidate', setCouponValidation)
              })}

              style={{borderColor: couponValidation && 'red'}}
              onChange={event => setCoupon(event.target.value)}/>
            <RequiredLabel show={couponValidation} text={"Ingrese un cúpon"}/><br/>
            <Button style={{marginTop: '15px', marginRight: '8px'}} title={"Validar"}
                    onClick={() => getCupon()}/>
            <RequiredLabel show={exists} text={"El cúpon no existe"}/><br/>
          </FormGroup>
          {tab &&
            <div>
              <FormGroup>
                <label>Cliente:</label>
                <Controller name="customerId" control={control}
                            render={({field}) => <CustomerSearchInputV2 {...field} />}/>
              </FormGroup>

              <FormGroup>
                <label>Codigo cupón:</label>
                <Input
                  type="number"
                  className="no-spinner"
                  {...register("couponId", {
                    onBlur: () => handleFieldBlur('couponId', setCouponValidation)
                  })}
                  style={{borderColor: couponValidation && 'red'}}/>
                <RequiredLabel show={couponValidation} text={"Ingrese un cúpon"}/>
              </FormGroup>
              <ActaImpresionInput register={register} actaValidation={actaValidation} setActaValidation={setActaValidation}
                                  changeImprimir={changeImprimir} imprimir={imprimir} handleFieldBlur={handleFieldBlur}/>
            </div>}
        </div>
        :
        <div>
          <FormGroup>
            <label>Cliente:</label>
            <Controller name="customerId" control={control} render={({field}) => <CustomerSearchInputV2 {...field} />}/>
          </FormGroup>
          <ActaImpresionInput register={register} actaValidation={actaValidation} setActaValidation={setActaValidation}
                              changeImprimir={changeImprimir} imprimir={imprimir} handleFieldBlur={handleFieldBlur}/>
          <FormGroup>
            <label>Responsable:</label>
            <ManagerSelect {...register("responsableId")}/>
          </FormGroup>
        </div>
      }
    </div>
  )
}

export default SorteoAsignarGanador
