import React, {useContext} from "react";
import PageSection from "../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import FormGroup, {FormBlock} from "../../../../components/forms/FormGroup";
import Input from "../../../../components/forms/FormEl";
import {useForm} from "react-hook-form";
import {SiteContext} from "../../../common/context/SiteContext";
import Button, {ButtonBox} from "../../../../components/forms/Button";
import PageContainer from "../../../../components/layout/PageContainer";
import MachineSelect from "../../../common/components/selects/MachineSelect";
import axios from "axios";
import {Apis} from "../../../../util/Apis";
import {useHistory} from "react-router";
import {errorToast, successToast} from "../../../../components/layout/ToastStyles";
import PrinterSelect from "../../../common/components/selects/PrinterSelect";

const RefillOp = () => {

  const {salaId} = useContext(SiteContext)
  const history = useHistory()
  let defaultParams = {salaId: salaId, enabled: true}
  const {register, control, handleSubmit, watch, errors, reset, setValue} = useForm({defaultValues: defaultParams});

  const onCreate = (params) => {
    params.salaId = salaId
    axios.post(Apis.CAJA + '/refill', params).then(res => {
      history.push('/caja/refill-create')
      successToast()
      clear()
    }).catch(err => {
      errorToast()
    })
  }

  const clear = () => {
    setValue('confId', "")
    setValue('amount', "")
  }

  return (
    <PageContainer>
      <PageSection>
          <Title text="Rellenos" type={'page'}/>
      </PageSection>

      <PageSection>
        <FormBlock>
          <FormGroup>
            <label>Monto</label>
            <Input  {...register("amount")}/>
          </FormGroup>
          <FormGroup>
            <label>Máquina</label>
            <MachineSelect {...register("confId")}/>
          </FormGroup>
          <FormGroup>
            <label>Impresora</label>
            <PrinterSelect {...register("printerId")}/>
          </FormGroup>
          <ButtonBox>
            <Button title={"Guardar"} variant={'primary'} onClick={handleSubmit(onCreate)}/>
          </ButtonBox>
        </FormBlock>
      </PageSection>
    </PageContainer>
  )
}

export default RefillOp
