import React, {useState} from "react";
import styled from "styled-components";
import {AnimatePresence, motion} from "framer-motion";
import {HiOutlineSlotMachine} from "../../../../components/assets/icons/slot-machine";
import {HiOutlineQuestionMarkCircle} from "react-icons/hi2";
import {TbEdit} from "react-icons/tb";
import MachineSearchInput from "../../../common/components/MachineSearchInput";
import {Controller, useForm} from "react-hook-form";
import Button, {ButtonBox} from "../../../../components/forms/Button";
import FormGroup from "../../../../components/forms/FormGroup";
import {Apis} from "../../../../util/Apis";
import axios from "axios";
import {errorToast, successToast} from "../../../../components/layout/ToastStyles";
import {FlexLayout} from "../../../../components/flex/Flex";
import {numberFormat} from "../../../../components/util/NumberUtil";

const MachineInfo = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`

const MachineIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  background: #e5e5e5;
`

const MachineDetails = styled.div`
  display: flex;
  flex:1;
  flex-direction: column;
`

const BodyDialog = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const StyledBackdrop = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  z-index: 5;
`

const StyledDialog = styled(motion.div)`
  display:flex;
  flex-direction:column;
  gap:16px;
  position: absolute;
  background: #fff;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  min-width:400px;
  z-index: 10;
  transition: 0.3s all ease-in-out;
  @media screen and (max-width: 768px) {
    transition: 0.3s all ease-in-out;
    min-width:300px;
  }
  @media screen and (max-width: 468px) {
    transition: 0.3s all ease-in-out;
    min-width:95vw;
  }
`;


const LayoutInfoMachine = ({selectedMachine, setDialogOpen, dialogOpen,fetchData,type}) => {

  const {handleSubmit,control} = useForm();

  const [editMachine, setEditMachine] = useState(false)

  const createPosition = (params) => {
    params.positionId = selectedMachine.id;
    axios.post(Apis.TGM + `/machines/change-position`, params)
      .then(() => {
        successToast("Posicion Asignada exitosamente")
        setDialogOpen(false);
        fetchData()
      })
      .catch(err => {
        console.log('hubo un error')
        errorToast(err.response.data.message, 6000)
      });
  }

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setEditMachine(false);
  };

  const getContentBasedOnType = (type, selectedMachine) => {
    switch (type) {
      case 'heatmap':
        return (
          <>
            <span>Total apostado:</span>
            <strong style={{fontSize: '16px'}}>S/ {selectedMachine.data?.ciSoles}</strong>
          </>
        );
      case 'config':
        return (
          <>
            <span>Grupo:</span>
            <strong style={{fontSize: '16px'}}>{selectedMachine.data?.groupName}</strong>
          </>
        );
      case 'meterOnline':
        return (
          <FlexLayout gap='48px'>
            <FlexLayout>
              <FlexLayout column alignItems='flex-start'>
                <span>Coin in:</span>
                <span>Coin out:</span>
                <span>Jackpot:</span>
                <span>Cancel credit:</span>
              </FlexLayout>
              <FlexLayout column alignItems='flex end'>
                <strong className="text-right">{selectedMachine.data?.ci}</strong>
                <strong className="text-right">{selectedMachine.data?.co}</strong>
                <strong className="text-right">{selectedMachine.data?.jp}</strong>
                <strong className="text-right">{selectedMachine.data?.cc}</strong>
              </FlexLayout>
            </FlexLayout>
            <FlexLayout>
              <FlexLayout column alignItems='flex-start'>
                <span>Billetes:</span>
                <span>Ticket in:</span>
                <span>Ticket out:</span>
                <span>Creditos:</span>
              </FlexLayout>
              <FlexLayout column alignItems='flex end'>
                <strong className="text-right">{selectedMachine.data?.bi}</strong>
                <strong className="text-right">{selectedMachine.data?.ticketIn}</strong>
                <strong className="text-right">{selectedMachine.data?.ticketOut}</strong>
                <strong className="text-right">{selectedMachine.data?.currentCredits}</strong>
              </FlexLayout>
            </FlexLayout>
          </FlexLayout>
        );
        case 'online':
      return (
        <>
          <span>Apuesta promedio:</span>
          <strong style={{fontSize: '16px'}}> {'S/ ' + numberFormat(selectedMachine.data?.apProm)}</strong>
        </>
      );
      default:
        return null;
    }
  };

  return (
    <AnimatePresence>
      {dialogOpen && selectedMachine && (
        <>
          <StyledBackdrop
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            onClick={handleCloseDialog}
          />

          <StyledDialog
            initial={{opacity: 0, y: -20}}
            animate={{opacity: 1, y: 0}}
            exit={{opacity: 0, y: -20}}
          >
            {selectedMachine.data !== null ?
              <>
                <MachineInfo>
                  <MachineIcon>
                    <HiOutlineSlotMachine size={28} />
                  </MachineIcon>
                  <MachineDetails>
                    <strong style={{fontSize: '16px'}}>
                      {type !=='online'? selectedMachine.data?.machine : selectedMachine.data?.machineName}
                    </strong>
                    <span style={{fontSize: '12px'}}>{selectedMachine.data?.gameName}</span>
                  </MachineDetails>
                  <Button size='s' onClick={()=> setEditMachine(!editMachine)}>
                    <TbEdit size={20} style={{cursor:'pointer'}} /> 
                    <span>Editar</span>
                  </Button>
                </MachineInfo>
                <BodyDialog>
                  {editMachine ?
                  <FormGroup>
                    <label>Seleccione una máquina nueva:</label>
                    <Controller name="confId" control={control} render={({field}) => <MachineSearchInput {...field} />} />
                  </FormGroup>
                  :
                  <FlexLayout column gap='16px'>
                    {getContentBasedOnType(type, selectedMachine)}
                    <span style={{fontSize: '14px'}}>Dispositivo: <strong>{selectedMachine.data?.blueSn}</strong></span>
                  </FlexLayout>
                  }
                </BodyDialog>
              </>
              :
              <>
                <MachineInfo>
                  <MachineIcon>
                    <HiOutlineQuestionMarkCircle size={20} />
                  </MachineIcon>
                  <MachineDetails>
                    <strong style={{fontSize: '16px'}}>Posición libre</strong>
                    <span style={{fontSize: '12px'}}>No hay máquina registrada en esta posición</span>
                  </MachineDetails>
                </MachineInfo>
                <BodyDialog>
                  <FormGroup>
                    <label>Seleccione máquina:</label>
                    <Controller name="confId" control={control} render={({field}) => <MachineSearchInput {...field} />} />
                  </FormGroup>
                </BodyDialog>
              </>
            }
            <ButtonBox style={{width:'100%',justifyContent:'flex-end',marginBottom:'0px'}}>
              <Button size='s' variant='dark' onClick={handleCloseDialog}>Cerrar</Button>
              {(editMachine || (selectedMachine.data === null)) &&
                <Button size='s' onClick={handleSubmit(createPosition)}>Guardar</Button>
              }
            </ButtonBox>
          </StyledDialog>
        </>
      )}
    </AnimatePresence>
  );
};

export default LayoutInfoMachine;
