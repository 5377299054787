import React from "react";
import ReactLoading from 'react-loading';


const Loader1 = () => {
  return (
    <div style={{display: 'flex', justifyContent: 'center', padding: '2rem', flexDirection: 'column', alignItems: 'center', gap: '1rem'}}>
      <ReactLoading type={'spin'} color={'black'} height={'40px'} width={'40px'} />
      <span>Cargando...</span>
    </div>
  )
}
export default Loader1


