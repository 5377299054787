import React from 'react';
import {Controller, useForm} from "react-hook-form";
import {useParams} from "react-router";
import {Apis} from "../../../../util/Apis";
import Card from "../../../../components/forms/Card";
import Button, {ButtonBox} from "../../../../components/forms/Button";
import useEditModal from "../../../../modals/useEditModal";
import LevelProgressiveEdit from "./LevelsProgressive/LevelProgressiveEdit";
import LevelProgressiveNew from "./LevelsProgressive/LevelProgressiveNew";
import useDeleteModal from "../../../../modals/useDeleteModal";
import FormGroup from "../../../../components/forms/FormGroup";
import Input from "../../../../components/forms/FormEl";
import Table from "react-bootstrap/Table";
import useFetch2 from "../../../../hooks/useFetch2";
import MachineGroupSelect from "../../../common/components/selects/MachineGroupSelect";
import GenericModal2 from "../../../../modals/GenericModal2";
import AsyncBlock from "../../../../components/forms/AsyncBlock";
import Moment from "react-moment";
import axios from "axios";
import DeleteGenericModal from "../../../../modals/DeleteGenericModal";
import {errorToast, successToast} from "../../../../components/layout/ToastStyles";
import {useHistory} from "react-router";

const ProgressiveForm = ({value, onSubmit, mode}) => {

  const {id} = useParams()
  const history = useHistory()
  const {register, handleSubmit,control} = useForm({defaultValues: value});

  const {data, fetchData, loading, error} = useFetch2(Apis.TGM + '/progressive-level?progressiveId=' + id)

  const {wrapper: wrapper1, addAction, editAction} = useEditModal({
    editComponent: <LevelProgressiveEdit/>,
    addComponent: <LevelProgressiveNew/>,
    onRefresh: fetchData
  })

  const deleteElement = id => axios.delete(Apis.TGM + '/progressive-level/' + id)
    .then(()=>{successToast("Nivel borrado exitosamente")})
    .catch(()=>{errorToast("Hubo problemas. Intente nuevamente en unos minutos.")})

  const {wrapper: wrapper2, openDeleteModal} = useDeleteModal({
    text: '¿Desea eliminar este nivel?',
    action: deleteElement,
    onRefresh: fetchData
  });

  const deleteProgresive = () => {
    axios.delete(Apis.TGM + '/progressive/' + value.id)
      .then(() => {
        history.push('/crm/progressive')
        successToast("Progresivo borrado exitosamente")
      })
      .catch(() => {
        errorToast("Hubo problemas. Intente nuevamente en unos minutos.")
      })
  }

  return (
    <>
      <Card padding>
        <FormGroup>
          <label>Nombre</label>
          <Input name="name" {...register("name")}/>
        </FormGroup>
        <FormGroup>
          <label>Grupo de máquinas </label>
          <FormGroup>
            <label>Máquinas</label>
            <Controller
              name="machineGroupId"
              control={control}
              render={({field}) => <MachineGroupSelect {...field} />}
            />
          </FormGroup>
        </FormGroup>
      </Card>

      {mode &&
        <Card padding>
          <Button title='Agregar' variant='primary' size='s' onClick={() => addAction()}/>

          <FormGroup style={{maxHeight: "500px", overflowY: "auto"}}>
            <AsyncBlock loading={loading} error={error}>
              <Table striped hover>
                <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Porcentaje</th>
                  <th>Monto mínimo</th>
                  <th>Monto máximo</th>
                  <th>Inicio</th>
                  <th>Fin</th>
                </tr>
                </thead>
                <tbody>
                {data && data.map((item) =>
                  <tr key={item.id}>
                    <td>{item.name}</td>
                    <td>{item.percentage}</td>
                    <td>{item.minAmount}</td>
                    <td>{item.maxAmount}</td>
                    <td><Moment format={'HH:mm DD/MM/YYYY'}>{item.ini}</Moment></td>
                    <td><Moment format={'HH:mm DD/MM/YYYY'}>{item.fin}</Moment></td>
                    <td>
                      <ButtonBox>
                        <Button variant={'light'} size='s' title={'Editar'} onClick={() => editAction(item.id)}/>
                        <Button variant={'danger'} size='s' title={'Eliminar'}
                                onClick={() => openDeleteModal(item.id)}/>
                      </ButtonBox>
                    </td>
                  </tr>
                )}
                </tbody>
              </Table>
            </AsyncBlock>
          </FormGroup>

        </Card>
      }

      <ButtonBox>
        <Button variant={'primary'} onClick={handleSubmit(onSubmit)}>Guardar</Button>
        <Button variant={'danger'} onClick={deleteProgresive}>Eliminar</Button>
      </ButtonBox>

      <GenericModal2 {...wrapper1}/>
      <DeleteGenericModal {...wrapper2}/>
    </>
  );
};

export default ProgressiveForm;
