import React from 'react'
import {useHistory, useParams} from "react-router";
import {PageSection, Title} from '@wargostec/react-components'
import {PageBackLabel} from '../../../../components/layout/PageBackLabel'
import {Nav, Tab, Row, Col} from 'react-bootstrap'
import styled from 'styled-components'
import {LuGamepad, LuLayoutDashboard, LuListOrdered, LuListTodo} from 'react-icons/lu';
import Card from '../../../../components/forms/Card';
import PageContainerFull from '../../../../components/layout/PageContainerFull';
import {TbListNumbers, TbMoneybag} from 'react-icons/tb';
import MachineDashboard from './MachineDashboard';
import GameList from './GamesList'
import useFetch2 from '../../../../hooks/useFetch2';
import {Apis} from '../../../../util/Apis';
import CardOverview, {CardOverviewContainer} from '../../../../components/layout/CardOverview';
import {HiOutlineSlotMachine} from '../../../../components/assets/icons/slot-machine';
import MachineMeters from './MachineMeters';
import MachineEvents from './MachineEvents';

const CustomNavLink = styled(Nav.Link)`
  color: #1A1A1A;
  display:flex;
  gap:8px;
  align-items:center;
  &.active{
    background-color: #1A1A1A !important;
    color: #fff;
  }
  &:hover{
    color: #1A1A1A;
  }
`

const MachineOverview = () => {

  const history = useHistory()
  const {id} = useParams()

  const {data,loading} = useFetch2(Apis.TGM + '/machines/find-by-conf?confId=' + id, []);

  if (loading) {
    return <div>Cargando...</div>;
  }

  return (
    <PageContainerFull>
      <PageSection>
        <PageBackLabel text='Regresar a la lista' onClick={() => history.goBack()} />
        <Title text={"Máquina " + data?.machine} type='page' />
        <CardOverviewContainer gridColumns='repeat(3, 1fr)' style={{marginBottom:'32px'}}>
          
          <CardOverview
            title={data?.slotGroup?.manufacturerName}
            subtitle={''+data?.slotGroup?.name}
            icon={<LuGamepad size={22} />}
          />
          <CardOverview
            title={data?.gameName}
            subtitle='Juego'
            icon={<LuGamepad size={22} />}
          />
          <CardOverview
            title={data?.numReg}
            subtitle='Registro mincetur'
            icon={<HiOutlineSlotMachine size={22} />}
          />
        </CardOverviewContainer>
        <Tab.Container id="left-tabs-example" defaultActiveKey="1">
          <Row>
            <Col lg={3}>
              <Card padding>
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <CustomNavLink eventKey="1">
                      <LuLayoutDashboard size={18} />
                      <span>Resumen</span>
                    </CustomNavLink>
                  </Nav.Item>
                  <Nav.Item>
                    <CustomNavLink eventKey="2">
                      <LuGamepad size={18} />
                      <span>Juegos</span>
                    </CustomNavLink>
                  </Nav.Item>
                  <Nav.Item>
                    <CustomNavLink eventKey="3">
                      <TbMoneybag size={18} />
                      <span>Producción</span>
                    </CustomNavLink>
                  </Nav.Item>
                  <Nav.Item>
                    <CustomNavLink eventKey="4">
                      <TbListNumbers size={18} />
                      <span>Contadores</span>
                    </CustomNavLink>
                  </Nav.Item>
                  <Nav.Item>
                    <CustomNavLink eventKey="5">
                      <LuListTodo size={18} />
                      <span>Eventos</span>
                    </CustomNavLink>
                  </Nav.Item>
                  <Nav.Item>
                    <CustomNavLink eventKey="6">
                      <LuListOrdered size={18} />
                      <span>Sesiones</span>
                    </CustomNavLink>
                  </Nav.Item>
                </Nav>
              </Card>
            </Col>
            <Col lg={9}>
              <Tab.Content>
                <Tab.Pane eventKey="1">
                  <MachineDashboard confId={id}/>
                </Tab.Pane>
                <Tab.Pane eventKey="2">
                  <GameList values={{confId: id}}/>
                </Tab.Pane>
                <Tab.Pane eventKey="3">
                  Producción
                </Tab.Pane>
                <Tab.Pane eventKey="4">
                  <MachineMeters confId={id}/>
                </Tab.Pane>
                <Tab.Pane eventKey="5">
                  <MachineEvents confId={id}/>
                </Tab.Pane>
                <Tab.Pane eventKey="6">
                  Sesiones
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>

      </PageSection>

    </PageContainerFull>
  )
}

export default MachineOverview
