import React, {useEffect} from "react";
import axios from "axios";
import CustomerGroupDetailForm from "./CustomerGroupDetailForm";
import {Apis} from "../../../../../util/Apis";
import {useParams} from "react-router";

const CustomerGroupDetailNew = ({setAction, setTitle}) => {

  const {id} = useParams()
  const save = async (params) => {
    params.customerGroupId=id
    params.salaId = JSON.parse(localStorage.getItem("sala")).id
    return  await axios.post(Apis.KCUSTOMERGROUP + '/group-member?groupId=' + id , params)
  }

  useEffect(() => {
    setTitle("Agregar cliente")
  }, [])

  return (
    <CustomerGroupDetailForm value={null} onSubmit={save} setAction={setAction}/>
  )
};

export default CustomerGroupDetailNew
