import React, {useCallback, useEffect, useState} from 'react';
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import {Button, PageSection, Title, Tooltip} from "@wargostec/react-components";
import {getDateFilter} from "../../../../components/util/CasinoDates";
import useToggle from "../../../../hooks/useToggle";
import FormGroup from "../../../../components/forms/FormGroup";
import {DatePickerCustom} from "../../../../components/forms/FormEl";
import Slidebar from "../../../common/layout/Slidebar";
import ReactTable from "../../../../components/util/ReactTable";
import {FlexLayout} from "../../../../components/flex/Flex";
import {useHistory} from "react-router";
import {useForm} from "react-hook-form";
import PageContainerFull from "../../../../components/layout/PageContainerFull";
import Card from "../../../../components/forms/Card";
import DateFilter from "../../../../components/datefilter/DateFilter";
import {numberFormat} from "../../../../components/util/NumberUtil";
import MoneyColored from "../../../../components/util/MoneyColored";
import {FcSynchronize} from "react-icons/fc";
import axios from "axios";
import {successToast} from "../../../../components/layout/ToastStyles";
import useLoadingToastr from "../../../../hooks/useLoadingToastr";
import styled from "styled-components";
import Moment from "react-moment";

export const CustomSpan = styled.span`
    cursor: pointer;
    text-decoration: none;

    &:hover {
        text-decoration: none;
        color: #27a8df;
    }
`;

const AuditCuadreFisicoDiario = () => {

  const {isOpen: slideOpen, toggler} = useToggle({});
  const date = getDateFilter('month')
  const fechasDefault = {ini: date.ini, fin: date.fin}
  const [fechas, setFechas] = useState(fechasDefault)
  let defaultParams = {ini: fechas.ini, fin: fechas.fin}
  const [params, setParams] = useState(defaultParams)
  const history = useHistory();
  const {data, fetchData, loading, error} = useFetch2(Apis.CAJA + `/report-fisico/cuadre-fisico-diario`, [], params);
  const {register, handleSubmit} = useForm({defaultValues: defaultParams});

  const getData = (params) => {
    setParams(params)
  }

  useEffect(() => {
    setParams({...params, ...fechas})
  }, [fechas])

  useEffect(() => {
    if (params) fetchData()
  }, [params])

  const numberValue = useCallback(props => <>{numberFormat(props.renderValue())}</>, []);
  const coloresNumberValue = useCallback(props => <MoneyColored
    currencySymbol={''}>{props.renderValue()}</MoneyColored>, []);

  const recalc = async (value) => {
    const params = {
      period: value,
      type: 'AUTOMATIC'
    }
    await axios.post(Apis.CAJA + '/report-fisico/cuadre-fisico-diario/generate', params)
    successToast("Cuadre fisico recalculado")
    fetchData();
  }

  const [getRecalc, loadingRecalc] = useLoadingToastr(recalc, "Hubo un error");

  const nameValue = useCallback(props => props.renderValue() ? props.renderValue() : 'AUTOMÁTICO', []);

  const actionValue = useCallback(props => <>{
    <FlexLayout justifyContent='center' gap='8px'>
      <Tooltip text="Recalcular" direction={'top'}>
        <Button variant={'light'} size='xs' icon={<FcSynchronize/>}
                onClick={() => getRecalc(props.row.original.period)} disabled={loadingRecalc}/>
      </Tooltip>
    </FlexLayout>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }</>, [loadingRecalc]);

  const periodValue = useCallback(props => <>{
    <CustomSpan
      onClick={() => history.push(`/caja/audit-cuadre-fisico?period=${props.row.original.period}`)}
      style={{justifyContent: 'center', display: 'flex'}}
    >
      <Tooltip text="Detalle por maquina" direction={'top'}>
        {props.renderValue()}
      </Tooltip>
    </CustomSpan>
  }</>, []);

  const dateValue = useCallback((props) => {
    const nameColumn = props.column.id;
    const id = props.row.original.id;
    const createdBy = props.row.original.createdBy;
    const updatedBy = props.row.original.updatedBy;
    let tooltipName;
    switch (nameColumn) {
      case 'createdAt':
        tooltipName = (createdBy != 0) ? `Generado por ${props.row.original.employeeName}` : `Automático`;
        break;
      case 'updatedAt':
        tooltipName = updatedBy ? `Actualizado por ${props.row.original.employeeNameUpdated}` : `Automático`;
        break;
      default:
        tooltipName = '';
        break;
    }
    return (
      <>
        {props.renderValue() && id ?
          <FlexLayout justifyContent='center'>
            <Tooltip text={tooltipName} direction={'top'}>
              <Moment format={'YYYY-MM-DD HH:mm'}>{props.renderValue()}</Moment>
            </Tooltip>
          </FlexLayout>
          :
          <span>---</span>
        }
      </>
    );
  }, []);

  const columns = [
      {
        header: 'Acciones',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: actionValue
      },
      {
        header: 'Periodo',
        accessorKey: 'period',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: periodValue
      },
      {
        header: "Contadores",
        meta: {
          headerClassName: 'text-center border-l',
        },
        columns: [
          {
            header: 'Billetero',
            accessorKey: 'billMachine',
            meta: {
              headerClassName: 'text-right  border-l',
              columnClassName: 'text-right  border-l',
            },
            cell: numberValue
          },
          {
            header: 'TicketIn',
            accessorKey: 'ticketInMachine',
            meta: {
              headerClassName: 'text-right',
              columnClassName: 'text-right',
            },
            cell: numberValue
          },
          {
            header: 'TicketOut',
            accessorKey: 'ticketOutMachine',
            meta: {
              headerClassName: 'text-right',
              columnClassName: 'text-right',
            },
            cell: numberValue
          },
          {
            header: 'P.manual',
            accessorKey: 'handpayMachine',
            meta: {
              headerClassName: 'text-right',
              columnClassName: 'text-right',
            },
            cell: numberValue
          },
        ]
      },
      {
        header: "Fisico",
        meta: {
          headerClassName: 'text-center border-l',
        },
        columns: [
          {
            header: 'Recaudo',
            accessorKey: 'audit',
            meta: {
              headerClassName: 'text-right  border-l',
              columnClassName: 'text-right  border-l',
            },
            cell: numberValue
          },
          {
            header: 'TicketIn',
            accessorKey: 'ticketInPhysical',
            meta: {
              headerClassName: 'text-right',
              columnClassName: 'text-right',
            },
            cell: numberValue
          },
          {
            header: 'TicketOut',
            accessorKey: 'ticketOutPhysical',
            meta: {
              headerClassName: 'text-right',
              columnClassName: 'text-right',
            },
            cell: numberValue
          },
          {
            header: 'P.manual',
            accessorKey: 'handpayPhysical',
            meta: {
              headerClassName: 'text-right',
              columnClassName: 'text-right',
            },
            cell: numberValue
          }
        ]
      },
      {
        header: "Diferencias",
        meta: {
          headerClassName: 'text-center border-l',
        },
        columns: [
          {
            header: 'Bill',
            accessorKey: 'diffBill',
            meta: {
              headerClassName: 'text-right  border-l',
              columnClassName: 'text-right  border-l',
            },
            cell: numberValue
          },
          {
            header: 'TicketIn',
            accessorKey: 'diffTicketIn',
            meta: {
              headerClassName: 'text-right',
              columnClassName: 'text-right',
            },
            cell: numberValue
          },
          {
            header: 'TicketOut',
            accessorKey: 'diffTicketOut',
            meta: {
              headerClassName: 'text-right',
              columnClassName: 'text-right',
            },
            cell: numberValue
          },
          {
            header: 'P.manual',
            accessorKey: 'diffHandpay',
            meta: {
              headerClassName: 'text-right',
              columnClassName: 'text-right',
            },
            cell: numberValue
          },
          {
            header: 'Total',
            accessorKey: 'diffTotal',
            meta: {
              headerClassName: 'text-right',
              columnClassName: 'text-right',
            },
            cell: coloresNumberValue
          }
        ]
      },
      {
        header: 'Creación',
        accessorKey: 'createdAt',
        meta: {
          headerClassName: 'text-center border-l',
          columnClassName: 'text-center border-l',
        },
        cell: dateValue
      },
      // {
      //   header: 'Actualización',
      //   accessorKey: 'updatedAt',
      //   meta: {
      //     headerClassName: 'text-center',
      //     columnClassName: 'text-center',
      //   },
      //   cell: dateValue
      // }
    ]

  return (
    <PageContainerFull>
      <Slidebar visible={slideOpen} onHide={toggler}>
        <FormGroup>
          <label>Desde</label>
          <DatePickerCustom {...register("ini")} />
        </FormGroup>

        <FormGroup>
          <label>Hasta</label>
          <DatePickerCustom {...register("fin")} />
        </FormGroup>

        <Button variant='primary' text='Buscar' onClick={handleSubmit(getData)}/>
      </Slidebar>

      <PageSection>
        <Title text="Cuadre fisico diario" type={'page'}/>

        <Card padding style={{background: '#0A0A0A'}}>
          <DateFilter fechas={fechas} setFechas={setFechas} modeDate={'month'}/>
        </Card>

          <ReactTable
            columns={columns}
            data={(data && data) || []}
            noData='No existen resultados para el filtro seleccionado'
            pageSize={200}
            customSearch
            filterOnclick={toggler}
          />
      </PageSection>

    </PageContainerFull>
  );
};

export default AuditCuadreFisicoDiario;
