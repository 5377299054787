import React, {useContext} from 'react';
import {useForm} from "react-hook-form";
import FormGroup, {FormBlockTitle} from "../../../../components/forms/FormGroup";
import Input from "../../../../components/forms/FormEl";
import {Apis} from "../../../../util/Apis";
import axios from "axios";
import Button from "../../../../components/forms/Button";
import {SiteContext} from "../../../common/context/SiteContext";
import {errorToast, successToast} from "../../../../components/layout/ToastStyles";
import MesaTypeSelect from "../../../common/components/selects/MesaTypeSelect";

const MesasNewComponent = ({slideOpen, refresh}) => {

  const {salaId} = useContext(SiteContext)
  const {register, handleSubmit, setValue, formState: {errors}} = useForm();

  const create = (params) => {
    params.salaId = salaId
    axios.post(Apis.TABLES + '/tables', params)
      .then(() => {
        successToast()
        slideOpen(false)
        refresh(true)
        setValue('type', "")
        setValue('maxCapacity', "")
      })
      .catch(() => {
        errorToast()
      })
  }


  return (
    <>
      <FormBlockTitle>General</FormBlockTitle>

      <FormGroup>
        <label>Nombre de mesa</label>
        <Input type="text" {...register("tableCode", {required: "Campo obligatorio"})}/>
        {errors.tableCode && <p style={{color: 'red'}}>{errors.tableCode?.message}</p>}
      </FormGroup>

      <FormGroup>
        <label>Tipo</label>
        <MesaTypeSelect {...register("type", {required: "Campo obligatorio"})}/>
        {errors.type && <p style={{color: 'red'}}>{errors.type?.message}</p>}
      </FormGroup>

      <FormGroup>
        <label>Capacidad Maxima</label>
        <Input type="number" {...register("maxCapacity", {required: "Campo obligatorio"})}/>
        {errors.maxCapacity && <p style={{color: 'red'}}>{errors.maxCapacity?.message}</p>}
      </FormGroup>

      <Button variant={'primary'} title='Guardar' onClick={handleSubmit(create)}/>
    </>
  );
};

export default MesasNewComponent;
