import React, {useCallback, useContext, useMemo} from "react";
import MoneyColoredRound from "../../../../components/util/MoneyColored";
import Button from "../../../../components/forms/Button";
import {useHistory} from "react-router-dom";
import AsyncBlock from "../../../../components/forms/AsyncBlock";
import {FlexLayout} from "../../../../components/flex/Flex";
import ReactTable from "../../../../components/util/ReactTable";
import {SiteContext} from "../../../../modules/common/context/SiteContext";
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import Moment from "react-moment";

const ProduccionTable = ({loading, error, data, showDetailPerMachine, setSlideOpen, slideOpen, download}) => {

  const {salaId} = useContext(SiteContext)
  const {data: dataSala} = useFetch2(Apis.SALAS + '/salas-config/' + salaId, [])

  const visible = showDetailPerMachine
  const history = useHistory();

  const getBreakType = (wbreakType) => {
    let contador = '';
    if (wbreakType === 1) {
      contador = 'CEREO';
    } else if (wbreakType === 2) {
      contador = 'ROLLOVER';
    } else if (wbreakType === 3) {
      contador = 'OTRO';
    }
    return contador;
  };

  const defaultValue = useCallback(props => (props.renderValue() * props.row.original.den).toFixed(2), []);
  const moneyValue = useCallback(props => props.renderValue().toFixed(2), []);
  const moneyColoredValue = useCallback(props => <MoneyColoredRound
    currencySymbol={dataSala.currencySymbol}>{props.renderValue()}</MoneyColoredRound>, [dataSala]);

  const breakValue = useCallback(props => getBreakType(props.renderValue()), []);
  const numberValue = useCallback(props => <MoneyColoredRound
    currencySymbol={dataSala.currencySymbol}>{props.renderValue() * props.row.original.den}</MoneyColoredRound>, [dataSala]);
  const dateValue = useCallback(props => <Moment format={'DD/MM HH:mm'}>{props.renderValue()}</Moment>, []);

  const actionsValue = useCallback(cell =>
      <FlexLayout justifyContent='center'>
        <Button variant={'light'} size='xs'
                onClick={() => history.push(`/tgm/meter-snap?ini=${cell.row.original.fecha}&fin=${cell.row.original.fecha}&confId=${cell.row.original.confId}`)}>
          Ver
        </Button>
        {visible &&
          <Button variant={'light'} size='xs'
                  onClick={() => history.push(`/tgm/produccion-por-maquina?ini=${cell.row.original.fecha}&fin=${cell.row.original.fecha}&confId=${cell.row.original.confId}`)}>
            Máquina
          </Button>
        }
        {visible &&
          <Button variant={'primary'} size='xs'
                  onClick={() => history.push(`/crm/sessions?confId=${cell.row.original.confId}&ini=${cell.row.original.fecha}&fin=${cell.row.original.fecha}`)}>
            Sesiones
          </Button>
        }

      </FlexLayout>
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , []);


  const columns = useMemo(
    () => [
      {
        header: 'Fecha',
        accessorKey: 'fecha',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Máquina',
        accessorKey: 'machine',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Fab.',
        accessorKey: 'brand',
      },
      {
        header: 'WAnalisis',
        accessorKey: 'wbreakpoints',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Tipo',
        accessorKey: 'wbreakType',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: breakValue
      },
      {
        header: 'CoinIn',
        accessorKey: 'ci',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
        cell: defaultValue
      },
      {
        header: 'CoinOut',
        accessorKey: 'co',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
        cell: defaultValue
      },
      {
        header: 'Jackpot',
        accessorKey: 'jp',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
        cell: defaultValue
      },
      {
        header: 'Den',
        accessorKey: 'den',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
      },
      {
        header: 'Producción',
        accessorKey: 'prod',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
        cell: numberValue
      },
      {
        header: 'Billetes',
        accessorKey: 'bi',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
        cell: defaultValue
      },
      {
        header: 'Ticket in',
        accessorKey: 'ticketIn',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
        cell: defaultValue
      },
      {
        header: 'Ticket out',
        accessorKey: 'ticketOut',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
        cell: defaultValue
      },
      {
        header: 'CC Man',
        accessorKey: 'cc',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
        cell: defaultValue
      },
      {
        header: 'Inicio',
        accessorKey: 'iniDate',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: dateValue
      },
      {
        header: 'Fin',
        accessorKey: 'finDate',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: dateValue
      },
      {
        header: 'Prod.Fisico',
        accessorKey: 'prodPhysical',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
        cell: moneyColoredValue
      },
      {
        header: 'Diff',
        accessorKey: 'diffPhysical',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
        cell: moneyValue
      },
      {
        header: 'Acciones',
        enableGlobalFilter: false, // para deshabilitar el filter
        enableSorting: false, // para deshabilitar el sort
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        },
        cell: actionsValue
      },
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [dataSala]
  )

  return (
    <AsyncBlock loading={loading} error={error}>
      {data.length > 0 &&
        <ReactTable
          columns={columns}
          data={data || []}
          customSearch
          filterOnclick={() => setSlideOpen(!slideOpen)}
          filterDownload={download}
          pageSize={30}
          sortArray={[{id: 'machine', asc: false}]}
          noData='No existen resultados para el filtro seleccionado'
        />
      }
    </AsyncBlock>
  )
}

export default ProduccionTable
