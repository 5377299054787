import React, { useContext } from "react";
import { Apis } from "../../../../../util/Apis";
import { SiteContext } from "../../../../common/context/SiteContext";
import GenericModal from "../../../../../modals/GenericModal";
import CouponAwardedForm from "../../../modal-body/CouponAwardedForm";
import { errorToast, successToast } from "../../../../../components/layout/ToastStyles";
import { usePost } from "../../../../../hooks/usePost";

const NewCouponAwarded = ({ show, onHide }) => {

  const { salaId } = useContext(SiteContext)

  const { mutate } = usePost({
    url: Apis.PC + '/coupon-gift',
    onSuccess: () => {
      successToast()
      onHide()
    },
    onError: () => {
      errorToast()
    }
  })

  const save = async (params) => {
    params.salaId = salaId
    await mutate(params)
  }

  return (
    <GenericModal
      title={'Nueva operacion'}
      body={<CouponAwardedForm onHide={onHide} value={{}} onSubmit={save} />}
      show={show}
      onHide={onHide}
    />
  )
}

export default NewCouponAwarded
