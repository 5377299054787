import React, {useEffect} from 'react';
import useFetch2 from "../../../../../hooks/useFetch2";
import {Apis} from "../../../../../util/Apis";
import axios from "axios";
import SegmentsModal from "./SegmentsModal";

const SegmentsEdit = ({id, setAction, setTitle}) => {

  const {data} = useFetch2(Apis.PC + '/customer-segments/' + id)

  function update(params) {
    params.id = id
    return axios.put(Apis.PC + '/customer-segments/' + id, params)
  }

  useEffect(() => {
    setTitle("Edición del Segmento")
  }, [])

  return (
    <SegmentsModal value={data} onSubmit={update} setAction={setAction}/>
  );
};

export default SegmentsEdit;
