import React, {useContext} from "react";
import PageSection from "../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import FormGroup, {FormBlock} from "../../../../components/forms/FormGroup";
import Input from "../../../../components/forms/FormEl";
import {useForm} from "react-hook-form";
import {SiteContext} from "../../../common/context/SiteContext";
import Button, {ButtonBox} from "../../../../components/forms/Button";
import PageContainer from "../../../../components/layout/PageContainer";
import axios from "axios";
import {Apis} from "../../../../util/Apis";
import {useHistory} from "react-router";
import OtherPayCategorySelect from "../../../common/components/selects/OtherPayCategorySelect";
import FondoSelect from "../../../common/components/selects/FondoSelect";

const OtherPayOp = () => {

  const {salaId} = useContext(SiteContext)
  const history = useHistory()
  let defaultParams = {salaId: salaId, enabled: true}
  const {register, handleSubmit} = useForm({defaultValues: defaultParams});

  const onCreate = (params) => {
    params.salaId = salaId
    params.printerId = null
    axios.post(Apis.CAJA + '/operation-other', params)
      .then(res => {
        history.push('/caja')
      }).catch(err => {
      console.log(err)
    })
  }

  return (
    <PageContainer>
      <PageSection>
          <Title text="Gastos"/>
      </PageSection>

      <PageSection>
        <FormBlock>
          <FormGroup>
            <label>Monto</label>
            <Input {...register("amount")}/>
          </FormGroup>
          <FormGroup>
            <label>Fondo</label>
            <FondoSelect {...register("fondoId")}/>
          </FormGroup>
          <FormGroup>
            <label>Descripción</label>
            <textarea
              placeholder={"Describa el concepto de gasto"}
              {...register("description")}
              style={{
                width: "100%",
                display: "inline-block",
                fontSize: "1rem",
                backgroundColor: "#fff",
                backgroundImage: "none",
                border: "1px solid #dcdfe6",
                height: "150px"
              }}
            />
          </FormGroup>
          <FormGroup>
            <label>Categoría</label>
            <OtherPayCategorySelect {...register("expenseCategoryId")} form={true}/>
          </FormGroup>
          <FormGroup>
            <label>Recibido por (Opcional)</label>
            <Input {...register("receivedBy")} placeholder={"Ejm: Juan, Sr. Perez, Sra. Julia, etc"}/>
          </FormGroup>
          <ButtonBox>
            <Button title={"Guardar"} variant={'primary'} onClick={handleSubmit(onCreate)}/>
          </ButtonBox>
        </FormBlock>
      </PageSection>
    </PageContainer>
  )
}

export default OtherPayOp
