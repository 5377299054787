import React, {useContext, useEffect} from "react";
import PrinterZoneForm from "./PrinterZoneForm";
import {Apis} from "../../../../../util/Apis";
import axios from "axios";
import {getComputerId} from "./PrinterZoneNew";
import useFetch2 from "../../../../../hooks/useFetch2";

const PrinterZoneEdit = ({id, setAction, setTitle}) => {

  const {loading, data} = useFetch2(Apis.PC + '/coupon-zone-printer/' + id)

  const update = async (params) => {
    params.computerId = await getComputerId(params)
    await axios.put(Apis.PC + '/coupon-zone-printer/' + id, params)
  }

  useEffect(() => {
    setTitle("Mapeo zona impresora")
  }, [])

  return (
    <React.Fragment>
      {data && !loading &&
      <PrinterZoneForm value={data} onSubmit={update} setAction={setAction}/>
      }
    </React.Fragment>
  )
}

export default PrinterZoneEdit
