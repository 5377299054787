import React, {useContext, useEffect, useMemo} from 'react'
import PageSection from "../../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import {Apis} from "../../../../../util/Apis";
import MachineGroupNew from "./MachineGroupNew";
import DeleteGenericModal from "../../../../../modals/DeleteGenericModal";
import useEditModal from "../../../../../modals/useEditModal";
import useDeleteModal from "../../../../../modals/useDeleteModal";
import MachineGroupEdit from "./MachineGroupEdit";
import GenericModal2 from "../../../../../modals/GenericModal2";
import useFetch2 from "../../../../../hooks/useFetch2";
import ReactTable from '../../../../../components/util/ReactTable';
import AsyncBlock from '../../../../../components/forms/AsyncBlock';
import PageContainerFull from '../../../../../components/layout/PageContainerFull';

const MachineGroupList = () => {

  const {data, fetchData, loading, error} = useFetch2(Apis.TGM + '/slot-groups/with-quantity', []);

  //hooks para crear,editar y eliminar
  const {wrapper: wrapper1, refresh: refresh1} = useEditModal({
    editComponent: <MachineGroupEdit />,
    addComponent: <MachineGroupNew />
  })

  const {wrapper: wrapper2, refresh: refresh2} = useDeleteModal({text: '¿Desea eliminar este grupo?'})

  //hook para 'escuchar' cuando debemos refrescar la vista
  useEffect(() => {
    fetchData()
    // eslint-disable-next-line
  }, [refresh1, refresh2])

  const columns = useMemo(
    () => [
      {
        header: 'Fabricante',
        accessorKey: 'manufacturerShortName'
      },
      {
        header: 'Nombre',
        accessorKey: 'name'
      },
      {
        header: 'Cantidad',
        accessorKey: 'quantity'
      }
    ]
    , []
  )

  return (
    <PageContainerFull>
      <PageSection>
          <Title text={"Modelos"}/>
        <AsyncBlock loading={loading} error={error}>
          <ReactTable
            columns={columns}
            data={data || []}
          />
        </AsyncBlock>
      </PageSection>

      <GenericModal2 {...wrapper1} />
      <DeleteGenericModal {...wrapper2} />

    </PageContainerFull>
  )
}

export default MachineGroupList;
