import React from "react";
import { SiteProvider } from "../context/SiteContext";
import { Redirect, Route, Switch } from "react-router";
import PrivateRoute from "../components/PrivateRoute";
import CasinoOnlineMain from "../../casinonline/CasinoOnlineMain";
import TgmMain from "../../tgm/TgmMain";
import CrmMain from "../../crm/CrmMain";
import AdminMain from "../../admin/AdminMain";
import SalaSelector from "./SalaSelector";
import axios from "axios";
import CajaMain from "../../caja/CajaMain";
import ActionContextWrapper from "../context/ActionContextWrapper";
import { CustomerProvider } from "../context/CustomerContext";
import TablesMain from "../../tables/TablesMain";
import BingosMain from "../../bingos/BingosMain";

// Set the AUTH token for any request
axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem('auth');
  const salaId = localStorage.getItem('salaId');
  const tenant = localStorage.getItem('tenant');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }

  if (salaId && salaId > 0) {
    config.headers['X-Sala-ID'] = salaId
  }
  if (tenant) {
    config.headers['X-Tenant'] = tenant
  }

  return config;
});

axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.response) {
    if (401 === error.response.status) {
      window.location.href = '/logout?referer=' + window.location.pathname;
    } else {
      return Promise.reject(error);
    }
  } else {
    return Promise.reject(error);
  }
});

const SecureApp = () => {
  return (
    <SiteProvider>
      <CustomerProvider>
        <ActionContextWrapper>
          <Switch>
            {/*Desde aqui redireccionamos a los demas modulos */}
            <PrivateRoute path='/casino' component={CasinoOnlineMain} />
            <PrivateRoute path='/tgm' component={TgmMain} />
            <PrivateRoute path='/crm' component={CrmMain} />
            <PrivateRoute path='/tables' component={TablesMain} />
            <PrivateRoute path='/bingos' component={BingosMain} />
            <PrivateRoute path='/admin' component={AdminMain} />
            <PrivateRoute path='/caja' component={CajaMain} />

            {/*podriamos mover este login a otro modulo*/}
            <PrivateRoute path="/selector" component={SalaSelector} />
            <Route path="/" render={() => (<Redirect to="/crm" />)} />
          </Switch>
        </ActionContextWrapper>
      </CustomerProvider>
    </SiteProvider>
  )
}

export default SecureApp
