import React, {useCallback, useEffect, useMemo, useState} from "react";
import PageContainer from "../../../../../components/layout/PageContainer";
import PageSection from "../../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import Button from "../../../../../components/forms/Button";
import FormGroup from "../../../../../components/forms/FormGroup";
import {Apis} from "../../../../../util/Apis";
import Moment from "react-moment";
import useFetch2 from "../../../../../hooks/useFetch2";
import axios from "axios";
import useDeleteModal from "../../../../../modals/useDeleteModal";
import DeleteGenericModal from "../../../../../modals/DeleteGenericModal";
import {DatePickerCustom} from "../../../../../components/forms/FormEl";
import Slidebar from "../../../../common/layout/Slidebar";
import {useForm} from "react-hook-form";
import {FlexLayout} from "../../../../../components/flex/Flex";
import AsyncBlock from "../../../../../components/forms/AsyncBlock";
import ReactTable from "../../../../../components/util/ReactTable";
import useToggle from "../../../../../hooks/useToggle";
import {useHistory} from "react-router";

const SorteoBingoList = () => {
  const {isOpen: slideOpen, toggler} = useToggle({});
  let defaultParams = {}
  const [params, setParams] = useState(defaultParams)
  const {register, handleSubmit} = useForm({defaultValues: defaultParams});

  const {loading, data, fetchData, error} = useFetch2(Apis.BINGO + `/sorteo/daily`, [], params);
  const [dataTable,setDataTable] = useState([])

  const history = useHistory()

  const getData = (params) => {
    setParams(params)
  }

  useMemo(()=>{
    if(data){
      let newArray = data.map(item => item.sorteo)
      setDataTable(newArray)
    }
  },[data])

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  const deleteElement = id => axios.delete(Apis.BINGO + `/sorteo/${id}`)

  const {
    wrapper: wrapper2,
    openDeleteModal
  } = useDeleteModal({
    text: '¿Desea eliminar esta partida del bingo?',
    action: deleteElement,
    onRefresh: fetchData
  })

  const baseUrl = '/bingos'

  const fechaCell = useCallback(props => <>{
    <FlexLayout alignItems='center' justifyContent='center' gap='4px'>
      <Moment format={'YYYY-MM-DD'}>{props.renderValue()}</Moment>
    </FlexLayout>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }</>, []);

  const horaCell = useCallback(props => <>{
    <FlexLayout alignItems='center' justifyContent='center' gap='4px'>
      <Moment format={'HH:mm'}>{props.row.original.startDate}</Moment>
    </FlexLayout>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }</>, []);

  const actionsValue = useCallback(props => <>{
    <FlexLayout justifyContent='center'>
      <Button variant={'primary'} size={'s'} onClick={() => history.push(`${baseUrl}/edit-partidas/` + props.renderValue())}>Editar</Button>
      <Button variant={'danger'} size={'s'} onClick={() => openDeleteModal(props.renderValue())}>Eliminar</Button>
    </FlexLayout>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }</>, []);

  const columns = useMemo(
    () => [
      {
        header: 'Fecha',
        accessorKey: 'startDate',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: fechaCell
      },
      {
        header: 'Hora',
        accessorKey: 'hora',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: horaCell
      },
      {
        header: 'Bingo',
        accessorKey: 'eventName'
      },
      {
        header: 'Bolillas',
        accessorKey: 'type',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        }
      },
      {
        header: 'Acciones',
        accessorKey: 'id',
        enableGlobalFilter:false,
        enableSorting:false,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: actionsValue
      }
    ]
    , []
  )

  return (
    <PageContainer>
      <Slidebar visible={slideOpen} onHide={toggler}>
        <div style={{padding: '16px'}}>
          <FormGroup>
            <label>Desde</label>
            <DatePickerCustom {...register("ini")}/>
          </FormGroup>
          <FormGroup>
            <label>Hasta</label>
            <DatePickerCustom {...register("fin")}/>
          </FormGroup>
          <Button variant={'primary'} title='Buscar' onClick={handleSubmit(getData)} />
        </div>
      </Slidebar>
      <PageSection>

          <Title text="Lista de partidas" type={'page'}/>

        <AsyncBlock loading={loading} error={error}>
          <ReactTable
            columns={columns}
            data={dataTable || []}
            filterOnclick={toggler}
            filterAdd={() => history.push(`${baseUrl}/new-partidas`)}
            customSearch
            noData='No existen resultados para el filtro seleccionado'
            pageSize={15}
          />
        </AsyncBlock>

      </PageSection>

      <DeleteGenericModal {...wrapper2}/>
    </PageContainer>
  )
}

export default SorteoBingoList
