import React, {useEffect} from 'react'
import Form from "react-bootstrap/Form";
import FormGroup from "../../../../../components/forms/FormGroup";
import {useForm} from "react-hook-form";
import ManufacterSelect from "../../../../common/components/selects/ManufacterSelect";
import Input from "../../../../../components/forms/FormEl";

const MachineGroupModalForm = ({value, onSubmit, setAction}) => {

  const {register, handleSubmit, reset} = useForm();

  useEffect(() => {
    if (value) {
      //react hook form descartar las propiedades que no tienen un componente
      reset(value)
    }
  }, [value])

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit))
  }, [])

  return (
    <React.Fragment>
      <Form>
        <FormGroup>
          <label>Fabricante</label>
          <ManufacterSelect {...register("manufacturerId")}/>
        </FormGroup>

        <FormGroup>
          <label>Nombre</label>
          <Input type="text" {...register("name")}/>
        </FormGroup>
      </Form>
    </React.Fragment>
  )

}

export default MachineGroupModalForm
