import {jwtDecode} from "jwt-decode"
import myAuthApi from '../myAuthApi'
import {Apis} from "../../../util/Apis";
import {datadogRum} from "@datadog/browser-rum";

export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_SOCIAL_LOGIN = "SET_SOCIAL_LOGIN";

//deprecated
export const loginUser = (username, password, dispatch, setError, setLoading) => { //login

  const TOKEN_AUTH_USERNAME = process.env.REACT_APP_AUTH_CLIENT_ID
  const TOKEN_AUTH_PASSWORD = '123456'
  const TENANT = 'gaming'

  const body = `username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}&grant_type=password&tenant=${TENANT}`;

  let config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Basic ' + btoa(TOKEN_AUTH_USERNAME + ':' + TOKEN_AUTH_PASSWORD)
    }
  };

  setLoading(true);
  myAuthApi.post(Apis.OAUTH + '/oauth/token', body, config).then(res => {
    if (res.status === 200) {
      setLoading(false)
      const token = res.data.access_token;
      localStorage.setItem("auth", token);
      const decodedToken = jwtDecode(token);
      setError("")
      dispatch(setCurrentUser(decodedToken));
    }
  }).catch(err => {
    setLoading(false)
    console.log('hubo un error en auth : ' + err)
    setError(err.message)
  });

};

export const logoutUser = (dispatch) => {//logout
  localStorage.removeItem("auth");
  datadogRum.clearUser();
  goToExternalLogout();
};

//TODO funcion duplicada en LogoutPage.js
const goToExternalLogout = () => {
  const authorizeEndpoint = process.env.REACT_APP_AUTH_SERVER + '/auth/logout'
  //TODO, andres oct 15 2023
  const redirectTo = window.location.origin + '/login'
  //const redirectTo = window.location.origin

  const params = {redirect_uri: redirectTo, client_id: process.env.REACT_APP_AUTH_CLIENT_ID}
  const paramString = new URLSearchParams(params)
  window.location.href = `${authorizeEndpoint}?${paramString.toString()}`
}

export const setCurrentUser = decodedToken => {//si se loguea , setear datos del usuario
  return {
    type: SET_CURRENT_USER,
    payload: decodedToken
  };
};
