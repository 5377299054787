import React, {useContext, useEffect, useState} from 'react';
import {SiteContext} from "../../../../common/context/SiteContext";
import getCasinoRange from "../../../../../components/util/CasinoDates";
import useFetch2 from "../../../../../hooks/useFetch2";
import {Apis} from "../../../../../util/Apis";
import {Controller, useForm} from "react-hook-form";
import PageContainer from "../../../../../components/layout/PageContainer";
import Slidebar from "../../../../common/layout/Slidebar";
import SalaIdInput from "../../../../common/components/SalaIdInput";
import FormGroup from "../../../../../components/forms/FormGroup";
import MachineSelect from "../../../../common/components/selects/MachineSelect";
import Button from "../../../../../components/forms/Button";
import PageSection from "../../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import Card from "../../../../../components/forms/Card";
import TicketTable from "./TicketTable";
import {BsTicketPerforated} from "react-icons/bs";
import CardOverview, {CardOverviewContainer} from "../../../../../components/layout/CardOverview";
import {FaCoins} from "react-icons/fa";
import DateTimeV2 from "../../../../../components/layout/DateTimeV2";
import MachineSelectV2 from "../../../../common/components/selects/MachineSelectV2";
import {Select} from "../../../../../components/forms/FormEl";

const TicketRedeem = () => {

  const {salaId} = useContext(SiteContext)

  const {ini, fin} = getCasinoRange()
  const [slideOpen, setSlideOpen] = useState(false)

  let defaultParams = {salaId: salaId, ini: ini, fin: fin, type: true}
  const [params, setParams] = useState(defaultParams)

  const {data, loading, fetchData, error} = useFetch2(Apis.TICKET + `/ticket`, [], params);
  const {register, handleSubmit,control} = useForm({defaultValues: defaultParams});

  const getData = (params) => {
    if (params.confId === '0' || params.confId === 0) {
      params.confId = null
    }
    setParams(params)
  }

  useEffect(() => {
    if (params) fetchData()
  }, [params])

  return (
    <PageContainer>

      <Slidebar visible={slideOpen} onHide={() => setSlideOpen(false)}>
        <div style={{padding: '16px'}}>
          <SalaIdInput {...register("salaId")}/>

          <FormGroup>
            <label>Maquina</label>
            <Controller
              name="confId"
              control={control}
              render={({field}) => <MachineSelectV2 {...field} />}
            />
          </FormGroup>
          <FormGroup>
            <label>Desde</label>
            <Controller name="ini" control={control} render={({field}) => <DateTimeV2 {...field} />}/>
          </FormGroup>

          <FormGroup>
            <label>Hasta</label>
            <Controller name="fin" control={control} render={({field}) => <DateTimeV2 {...field} />}/>
          </FormGroup>

          <FormGroup>
            <label>Tipo</label>
            <Select {...register("status")}>
              <option value={1}>Canjeados</option>
              <option value={0}>Pendientes</option>
              <option value={''}>Todos</option>
            </Select>
          </FormGroup>

          <Button variant={'primary'} title='Buscar' onClick={handleSubmit(getData)}/>
        </div>
      </Slidebar>

      <PageSection>
          <Title text="Tickets canjeados" type={'page'}/>

        <CardOverviewContainer gridColumns='repeat(2, 1fr)'>
          <CardOverview
            title={data && (data.amountTotal / 100)}
            subtitle='Monto total'
            icon={<FaCoins fontSize={'20'} />}
          />
          <CardOverview
            title={data && data.quantity}
            subtitle='Cantidad de tickets'
            icon={<BsTicketPerforated size={20} />}
          />
        </CardOverviewContainer>
      </PageSection>

      <PageSection>
        <Card padding>
          <TicketTable loading={loading} error={error} data={(data && data.data) || []} fetchData={fetchData}
                       setSlideOpen={setSlideOpen} slideOpen={slideOpen}/>
        </Card>
      </PageSection>

    </PageContainer>
  );
};

export default TicketRedeem;
