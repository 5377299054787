import React from 'react'
import {Select, SelectV2} from "../../../../components/forms/FormEl";
import {Apis} from "../../../../util/Apis";
import useFetch2 from "../../../../hooks/useFetch2";

const DocumentTypeSelect = React.forwardRef((props, ref) => {

  const {data, loading} = useFetch2(Apis.PC + `/document-type`, [])

  const getData = (dataX) => {
    let convertedData = []
    if (dataX) {
      convertedData = dataX.map(item => {
        return {
          id: item.id,
          name: item.name
        }
      })
    }
    return convertedData
  }

  return (
    <>
      {
        !loading ?
          <SelectV2
            ref={ref}
            options={getData(data)}
            {...props}
          >
          </SelectV2>
          :
          <Select disabled={true}>
            <option key={0} value={0}>{"Cargando..."}</option>
          </Select>
      }
    </>
  )
})

export default DocumentTypeSelect
