import React, {useContext} from 'react'
import {SiteContext} from "../../../common/context/SiteContext";
import {Apis} from "../../../../util/Apis";
import useFetch2 from '../../../../hooks/useFetch2';
import axios from "axios";
import useDeleteModal from "../../../../modals/useDeleteModal";
import DeleteGenericModal from "../../../../modals/DeleteGenericModal";
import Table from "react-bootstrap/Table";
import Button from "../../../../components/forms/Button";
import {successToast} from "../../../../components/layout/ToastStyles";
import {LoadingTableV2} from '../../../../components/forms/Tables';
import SwitchToggle from "../../../../components/layout/SwitchToggle";
import {OnlineActiveValue} from "../../../admin/pages/users/OnlineActiveValue";

const ComputerList = () => {
  const {salaId} = useContext(SiteContext)

  const {
    data,
    loading,
    fetchData
  } = useFetch2(Apis.PRINT + `/computers/get-all?accountId=${salaId}`, []);

  const deleteElement = id => axios.delete(Apis.PRINT + "/computers/" + id)

  const {
    wrapper: wrapper2, openDeleteModal
  } = useDeleteModal({
    text: '¿Desea eliminar esta computadora y todas las impresoras asociadas a ella?',
    action: deleteElement,
    onRefresh: fetchData
  })

  const lockAndUnlock = async (id, locked) => {
    if (!locked) {
      await axios.post(Apis.PRINT + '/computers/' + id + '/enable')
        .then(() => {
          fetchData()
          successToast("Computadora habilitada correctamente")
        })
    } else {
      await axios.post(Apis.PRINT + '/computers/' + id + '/disable')
        .then(() => {
          fetchData()
          successToast("Computadora deshabilitada correctamente")
        })
    }
  }

  return (<>
    <h4 style={{fontSize: '1.4rem', margin: '2rem 0', fontWeight: '600'}}>Computadoras</h4>
    <LoadingTableV2 loading={loading} data={data}
                    noDataText={'No hay computadoras registradas'}>
      <Table striped hover responsive={true} size={'sm'}>
        <thead>
        <tr>
          <th className={'text-left'}>Computadora</th>
          <th className={'text-center'}>Version</th>
          <th className={'text-center'}>Conectado</th>
          <th className={'text-center'}>Habilitado</th>
          <th className={'text-center'}>Acciones</th>
        </tr>
        </thead>
        <tbody>
        {data && data.map(item => <tr key={item.id}>

          <td className="text-left">{item.hostname}</td>
          <td className="text-center">{item.appVersion}</td>
          <td className="text-center">
            <OnlineActiveValue value={item.connectedAt}/>

          </td>
          <td style={{display: 'flex', justifyContent: 'center'}}>
            <SwitchToggle
              style={{justifyContent: 'center'}} defaultChecked={item.authorized}
              id={"computer" + item.id}
              name={"enabled"}
              label={"Hab"}
              onClick={() => lockAndUnlock(item.id, item.authorized)}/>
          </td>
          <td className="text-center"><Button variant={'danger'} size='s' title={'Eliminar'}
                                              onClick={() => openDeleteModal(item.id)}/></td>
        </tr>)}
        </tbody>
      </Table>
    </LoadingTableV2>

    <DeleteGenericModal {...wrapper2}/>
  </>)
}

export default ComputerList;
