import React from "react";
import {Apis} from "../../../../../util/Apis";
import SorteoForm from "./SorteoForm";
import useFetch2 from "../../../../../hooks/useFetch2";
import axios from "axios";
import {useHistory, useParams} from "react-router";
import PageSection from "../../../../../components/layout/PageSection";
import {PageBackLabel} from "../../../../../components/layout/PageBackLabel";
import {Title} from "@wargostec/react-components";
import Loader1 from "../../../../common/components/Loader1";
import PageContainer from "../../../../../components/layout/PageContainer";
import {errorToast, successToast} from "../../../../../components/layout/ToastStyles";

const SorteoEdit = () => {

  const history = useHistory()
  const {id} = useParams()

  const {data, loading} = useFetch2(Apis.BINGO + '/sorteo/prizes/' + id, [])

  const onEdit = (params) => {
    const eventType = params.eventType
    if (eventType == 2) {
      const totalAmount = params.totalAmount ? params.totalAmount : 0
      const linePrize = params.linePrize ? params.linePrize : 0
      const doubleLinePrize = params.doubleLinePrize ? params.doubleLinePrize : 0
      const totalPrize = parseInt(linePrize) + parseInt(doubleLinePrize)
      params.blackOut = totalAmount - (totalPrize).toFixed(2)
    }

    axios.put(Apis.BINGO + '/sorteo/form/' + id, params).then(res => {
      history.push('/bingos/partidas')
      successToast("Partida actualizada correctamente")
    }).catch(err => {
      errorToast("Ocurrió un problema al actualizar. Intente nuevamente en unos minutos.")
    })
  }

  return (
    <PageContainer>
      <PageSection style={{marginBottom: "20px"}}>
        <PageBackLabel text='Lista de partidas' onClick={() => history.goBack()}/>
          <Title text="Editar partida"/>
      </PageSection>
      <PageSection>
        {
          loading ?
            <Loader1/>
            :
            <SorteoForm value={data} onSubmit={onEdit}/>
        }
      </PageSection>
    </PageContainer>
  )
}

export default SorteoEdit
