import Modal from "react-bootstrap/Modal";
import React, { useState } from "react";
import Button from "../components/forms/Button";
import { errorToast, successToast } from "../components/layout/ToastStyles";

const GenericModal2 = ({ body, onHide, ...props }) => {

  const [action, setAction] = useState(null)
  const [title, setTitle] = useState('')
  const [loading, setLoading] = useState(false)

  function doModalAction() {
    setLoading(true)
    action().then(res => {
      setLoading(false)
      onHide()
      successToast()
    }).catch(err => {
      let toastrTxt = "Ocurrio un problema. Por favor intenta nuevamente"
      if (err.response !== undefined) {
        toastrTxt = err.response.data.message
      }

      errorToast(toastrTxt)
      setLoading(false)
    })
  }

  return (
    <Modal onHide={onHide} {...props}>
      <Modal.Header closeButton>
        <Modal.Title style={{ fontSize: '20px' }}>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {
          body &&
          <div style={{ display: 'block', flexDirection: 'row' }}>
            {React.cloneElement(body, { setAction, setTitle })}
          </div>
        }
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={onHide}>
          Cerrar
        </Button>
        <Button variant="primary" onClick={doModalAction} disabled={loading}>
          Guardar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default GenericModal2;
