import React, {useContext} from 'react';
import {SiteContext} from "../../../../common/context/SiteContext";
import useFetch2 from "../../../../../hooks/useFetch2";
import {Apis} from "../../../../../util/Apis";
import PageContainer from "../../../../../components/layout/PageContainer";
import {Title} from "@wargostec/react-components";
import Button, {ButtonBox} from "../../../../../components/forms/Button";
import {FaPlus} from "react-icons/fa";
import PageSection from "../../../../../components/layout/PageSection";
import Card from "../../../../../components/forms/Card";
import {LoadingTableV2} from "../../../../../components/forms/Tables";
import Table from "react-bootstrap/Table";
import useEditModal from "../../../../../modals/useEditModal";
import ManualRedeemNew from "./ManualRedeemNew";
import axios from "axios";
import useDeleteModal from "../../../../../modals/useDeleteModal";
import GenericModal2 from "../../../../../modals/GenericModal2";
import DeleteGenericModal from "../../../../../modals/DeleteGenericModal";
import Moment from "react-moment";

const ManualRedeemList = () => {

  const {salaId} = useContext(SiteContext)
  const defaultParams = {salaId}

  const {data, fetchData, loading} = useFetch2(Apis.PC + '/manual-redeem', [], defaultParams);

  const {wrapper: wrapper1, addAction, editAction} = useEditModal({
    // editComponent: <ManualRedeemEdit/>,
    addComponent: <ManualRedeemNew/>,
    onRefresh: fetchData
  })

  const deleteElement = id => axios.delete(Apis.PC + '/manual-redeem/' + id)

  const {
    wrapper: wrapper2,
    openDeleteModal
  } = useDeleteModal({
    text: '¿Desea eliminar este producto?',
    action: deleteElement,
    onRefresh: fetchData
  })

  return (
    <PageContainer>
      <PageSection>

        <Title text="Canje manual" type={'page'}/>
        <ButtonBox>
          <Button variant={'primary'} onClick={() => addAction()}>
            <FaPlus/> Nuevo
          </Button>
        </ButtonBox>

      </PageSection>

      <PageSection>

        <Card padding>
          <LoadingTableV2 loading={loading} data={data} noDataText={'No hay canjes manuales registrados'}>
            <Table striped hover>
              <thead>
              <tr>
                <th>Producto</th>
                <th>Cliente</th>
                <th>Puntos</th>
                <th>Fecha</th>
                <th>Acción</th>
                <th></th>
                <th/>
              </tr>
              </thead>
              <tbody>
              {data && data.map((item, index) =>
                <tr key={item.id}>
                  <td className={'item-center'}>{item.productName}</td>
                  <td className={'item-center'}>{item.customerName}</td>
                  <td className={'item-center'}>{item.points}</td>
                  <td className={'item-center'}>
                    <Moment format={'DD/MM/YYYY HH:mm'}>{item.createdAt}</Moment>
                  </td>
                  <td className={'item-center'}>
                    <ButtonBox>
                      {/*<Button size={'s'} onClick={() => editAction(item.id)}>Editar</Button>*/}
                      <Button size={'s'} onClick={() => openDeleteModal(item.id)}
                              variant={'danger'}>Eliminar</Button>
                    </ButtonBox>
                  </td>
                  <td className={'item-center'}></td>
                </tr>
              )}
              </tbody>
            </Table>
          </LoadingTableV2>
        </Card>

      </PageSection>

      <GenericModal2 {...wrapper1}/>
      <DeleteGenericModal {...wrapper2}/>

    </PageContainer>
  );
};

export default ManualRedeemList;
