import React, {useContext, useEffect, useState} from "react";
import PageContainerFull from "../../../../components/layout/PageContainerFull";
import {Title} from "@wargostec/react-components";
import Button, {ButtonBox} from "../../../../components/forms/Button";
import {FaCalendar, FaClock, FaPlus, FaTrash} from "react-icons/fa";
import {BiReset} from "react-icons/bi"
import PageSection from "../../../../components/layout/PageSection";
import styled from "styled-components";
import {Controller, useFieldArray, useForm} from "react-hook-form";
import {Calendar} from "react-multi-date-picker"
import "react-multi-date-picker/styles/layouts/prime.css"
import {SiteContext} from "../../../common/context/SiteContext";
import SorteoTypeSelect from "../../../common/components/selects/SorteoTypeSelect";
import AnforaSelect from "../../../common/components/selects/AnforaSelect";
import FormGroup from "../../../../components/forms/FormGroup";
import axios from 'axios';
import {Apis} from "../../../../util/Apis";
import {errorToast, successToast} from "../../../../components/layout/ToastStyles";
import {DateTime} from "luxon";
import Card, { CardHeader} from "../../../../components/forms/Card";
import Input, {SelectV2} from "../../../../components/forms/FormEl";
import CouponTypeSelectV2 from "../../../common/components/selects/CouponTypeSelectV2";

const HoursTitle = styled.div`
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  border-right: 0.5px solid rgb(234, 234, 234);
`

const CronoBody = styled.div`
  width: calc(100% - 54px);
  overflow-x: auto;
  padding:16px;
`

const TitleHours = styled.div`
  background: #CAE5FA;
  grid-column: 2/3;
  margin-top: 55px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`

const ContainerCuadradoCol = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  gap:16px;
`;

const ContenedorAllColumns = styled.div`
  display: flex;
`

const CuadradoCol = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  gap:8px;
  padding:16px;
  min-width: 290px;
  border: 0.5px solid #adb5bd;
  border-bottom:none;

  .rmdp-input {
    border: 1px solid rgb(118, 118, 118);
    border-radius: 3px;
    height: 25px;
    margin: 20px 0;
    padding: 2px 5px;
    width: 100%;
    box-shadow: 0 0 5px rgb(0 0 0 / 20%);
  }
`

const CuadradoRow = styled.div`
  min-width: 180px;
  border: 0.5px solid #adb5bd;
  border-right:none;
  background: #CAE5FA;
  padding: 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const CuadradoIntermedio = styled.div`
  display: flex;
  min-width: 290px;
  border: 0.5px solid rgb(173, 181, 189);
  padding: 8px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  
`

const ContenedorCols = styled.div`
  display: grid;
  grid-template-columns: 70px 180px repeat(10, 290px) ;
`

const ContenedorAllRows = styled.div`
  display: grid;
  grid-template-columns: 70px 180px repeat(10, 290px);
`

const ContenedorRows = styled.div`
`

const InputAmountCustom = styled.input`
  width: 100%;
  border-radius: 4px;
  padding: 4px 4px 4px 16px;
  border: 1px solid #b5b5b5;
  text-align:center;
  &:focus{
    outline:0;
  }
`

const InputTime = styled.input`
  width: 100%;
  border-radius: 4px;
  padding: 4px 4px 4px 16px;
  border: 1px solid #b5b5b5;
  text-align:center;
  &:focus{
    outline:0;
  }
`
const TotalSorteos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 58px;
`

const SorteoMassive = () => {

  const [dateModified, setDateModified] = useState(false)
  const [raffleQuantity, setRaffleQuantity] = useState(0)
  const [sumHours, setSumHours] = useState(0)
  const [numCols, setNumCols] = useState(0)
  const [numRows, setNumRows] = useState(0)
  const [buttonReset, setButtonReset] = useState(false)

  const {salaId} = useContext(SiteContext)

  const defaultValues = {
    'fechas': [{'name': '', 'fechas': []}],
    'hours': ['08:00'],
  }

  const {control, handleSubmit, register, setValue, reset, watch} = useForm({defaultValues: defaultValues});

  const typeSorteo = watch('kind')
  const watchFechas = watch('fechas')

  const arrayAddColumns = useFieldArray({
    control, name: "fechas"
  })

  const arrayAddMatriz = useFieldArray({
    control, name: "info"
  })

  const arrayAddReplicaRow = useFieldArray(
    {control, name: "hours"}
  )
  const watchHours = watch(arrayAddReplicaRow.fields.forEach((indexRow) => {return `horas.${indexRow}.time`}))

  const AgregarCols = () => {
    setNumCols(numCols + 1)
    arrayAddColumns.append({fechas: []})
    arrayAddMatriz.append({amount: "bill", couponTypeId: "luo"})
  }

  const AgregarRows = () => {
    setNumRows(numRows + 1)
    arrayAddReplicaRow.append({})
  }

  const enviarData = async (params, e) => {
    const data = []
    if (!params.fechas || !params.kind) {
      errorToast("Asegúrese de haber ingresado los datos y vuelva a intentar")
      return
    }

    for (let i = 0; i < params.fechas.length; i++) {
      let detail = []
      for (let j = 0; j < params.horas.length; j++) {
        const infoCont = {
          time: params.horas[j].time,
          amount: params.info[j][i].amount,
          timeStart: params.info[j].timeStart,
          relativePeriod: params.info[j].relativePeriod,
          relativePeriodAmount: params.info[j].relativePeriodAmount
        }

        detail.push(infoCont)
      }

      const sorteos = {
        fechas: params.fechas[i].fechas,
        detail: detail
      }

      data.push(sorteos)
    }
    if (params.kind === "0" || params.anforaId === "0") {
      return errorToast("Verifica el tipo de sorteo o ánfora")
    }

    const sorteoProgramacion = {
      salaId: salaId,
      sorteos: data,
      anforaId: params.anforaId,
      kind: params.kind,
      couponTypeId: params.couponTypeId
    }

    axios.post(Apis.SORTEO + '/sorteos/batch-insert/v2', sorteoProgramacion)
      .then(resp => {
        setButtonReset(true)
        successMsg()
      }).catch(err => {
        setButtonReset(true)
        errorMsg()
      });
  }

  const successMsg = () => {
    successToast("Sorteos creados correctamente, dirígase a la lista de sorteos para poder visualizarlos", 8000)
  }

  const errorMsg = () => {
    errorToast("Asegúrese que los datos ingresados sean correctos y vuelva a intentar")
  }

  const onChange = (fechas, index) => {
    register(`fechas[${index}].name`)
    const formattedDates = fechas.map(fecha => DateTime.local(fecha.year, Number(fecha.month), Number(fecha.day)).toFormat('yyyy-MM-dd'))

    setValue(`fechas[${index}].name`, `${formattedDates.map((help) => help)}`)
    setValue(`fechas[${index}].fechas`, formattedDates)
    setDateModified(!dateModified)
  }

  useEffect(() => {
    setSumHours(watchHours.hours.length)

    if (watchFechas === undefined) {
      return
    }

    let total = 0
    for (let i = 0; i < watchFechas.length; i++) {
      const quantity = watchFechas[i].fechas.length
      total += quantity
    }

    setRaffleQuantity(total)
  }, [watchFechas, watchHours, dateModified])

  const resetView = () => {
    reset()
  }

  return (
    <PageContainerFull>
      <PageSection>
          <Title text="Creación masiva de sorteos" type={'page'}/>
        <ButtonBox>
          <Button variant={'primary'} onClick={handleSubmit(enviarData)}>
            <FaPlus /> Guardar
          </Button>
          {buttonReset ?
            <Button variant={'primary'} onClick={resetView}>
              <BiReset /> Reset
            </Button> : ''}
        </ButtonBox>
      </PageSection>
      <PageSection>
        <Card padding>
          <div style={{display: 'flex'}}>
            <FormGroup style={{marginRight: "30px"}}>
              <label>Promoción</label>
              <Controller
                name="couponTypeId"
                control={control}
                render={({field}) => <CouponTypeSelectV2 {...field} />}
              />
            </FormGroup>
            <FormGroup style={{marginRight: "30px"}}>
              <label>Tipo de sorteo</label>
              <SorteoTypeSelect {...register("kind")} />
            </FormGroup>
            <FormGroup style={{marginRight: "30px"}}>
              <label>Ánfora</label>
              <AnforaSelect  {...register("anforaId")} disabled={typeSorteo !== 'VIRTUAL'} />
            </FormGroup>
            <FormGroup>
              <TotalSorteos>Total sorteos <strong>{raffleQuantity * sumHours}</strong></TotalSorteos>
            </FormGroup>
          </div>
        </Card>

        <Card>
          <CardHeader style={{display: 'flex', justifyContent: 'center'}}>
            <strong style={{fontSize: '16px'}}>DÍAS SEGÚN CRONOGRAMA</strong>
          </CardHeader>
          <div style={{display: 'flex'}}>
            <HoursTitle>
              <span style={{transform: "rotate(180deg)", writingMode: 'vertical-lr'}}>HORAS</span>
            </HoursTitle>
            <CronoBody>
              <ContenedorCols>
                <TitleHours>ASIGNE HORAS</TitleHours>
                <ContenedorAllColumns>
                  {arrayAddColumns.fields.map((item, index) => {
                    return (
                      <ContainerCuadradoCol key={index}>
                        <Button variant='danger' onClick={() => {
                          arrayAddColumns.remove(1)
                        }}>
                          <FaTrash />
                          <span>Eliminar</span>
                        </Button>
                        <CuadradoCol>
                          <span>Seleccione días</span>
                          <Calendar
                            className="rmdp-prime"
                            onChange={(date) => onChange(date, index)}
                            multiple
                            sort
                            hideYear
                          />
                        </CuadradoCol>

                      </ContainerCuadradoCol>
                    )
                  })}
                  <div style={{display: 'flex', alignItems: 'center', margin: '55px 0 0 10px'}}>
                    <Button variant={'primary'} onClick={AgregarCols}>
                      <FaCalendar />
                      <span>Agregar días</span>
                    </Button>
                  </div>
                </ContenedorAllColumns>
              </ContenedorCols>
              {
                arrayAddReplicaRow.fields.map((itemsRow, indexRow) => {
                  return (
                    <ContenedorAllRows key={indexRow}>
                      <Button variant='danger' style={{marginRight: "10px", alignSelf: "center"}}
                        onClick={() => arrayAddReplicaRow.remove(indexRow)}>
                        <FaTrash />
                      </Button>
                      <CuadradoRow>
                        <InputTime type={"time"} {...register(`horas.${indexRow}.time`, {required: true})} />
                      </CuadradoRow>
                      <ContenedorAllColumns>
                        {arrayAddColumns.fields.map((item, index) => {
                          return (
                            <CuadradoIntermedio key={index}>
                              <span>MONTO</span>
                              <div style={{display: "flex",gap:'8px',alignItems:'center'}}>
                                <span>S/</span>
                                <InputAmountCustom type="text" autoComplete="off"
                                 {...register(`info.${indexRow}.${index}.amount`, {required: true})} />
                              </div>
                            </CuadradoIntermedio>
                          )
                        })}
                        {
                          typeSorteo === 'VIRTUAL' &&
                          <ContenedorRows>
                            <CuadradoRow>
                              RANGO
                              <Input type={'text'} {...register(`info.${indexRow}.relativePeriodAmount`, {})} defaultValue="8" />
                              <SelectV2 {...register(`info.${indexRow}.relativePeriod`, {})} options={
                                [
                                  {id: "HOUR", name: "HORAS ANTES"},
                                  {id: "DAY", name: "DIAS ANTES"},
                                  {id: "WEEK", name: "SEMANAS ANTES"}
                                ]
                              } />
                            </CuadradoRow>
                          </ContenedorRows>
                        }
                      </ContenedorAllColumns>
                    </ContenedorAllRows>
                  )
                })
              }
              <div style={{display: 'flex', marginLeft: '90px', marginTop: '15px'}}>
                <Button style={{minWidth: "135px"}} variant={'primary'} onClick={AgregarRows}>
                  <FaClock /> Agregar horas
                </Button>
              </div>
            </CronoBody>
          </div>
        </Card>
      </PageSection>
    </PageContainerFull>
  )
}

export default SorteoMassive;
