import React from 'react';
import MachineEvents from "./MachineEvents";

const ModalEvent = ({values}) => {
  return (
    <div>
      <MachineEvents confId={values.confId} size={15} type={'page'}/>
    </div>
  );
};

export default ModalEvent;
