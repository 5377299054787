import React, {useContext} from "react";
import MainDashboard from "../../components/layout/MainDashboard";
import CasinoOnlineRoutes from "./CasinoOnlineRoutes";
import DropdownMenu from "../../components/layout/DropdownMenu";
import SecDropdownMenuItem from "../../components/layout/SecDropdownItem";
import {SiteContext} from "../common/context/SiteContext";
import DropdownContainer from "../../components/layout/DropdownContainer";


const CasinoOnlineMain = () => {
  const baseUrl = '/casino'
  const {moreInfo} = useContext(SiteContext)
  const {p, user} = moreInfo

  return (
    <MainDashboard brand={'RGS'} username={user.name}
                   items={
                     <React.Fragment>
                       <DropdownContainer>
                         <DropdownMenu title={'Operations'}>
                           {/*<SecDropdownMenuItem title={'En línea'} link={baseUrl + '/online'}/>*/}
                           <SecDropdownMenuItem title={'Agregar fondos'} link={baseUrl + '/add-funds'}/>
                         </DropdownMenu>

                         {/*<DropdownMenu title={'Reports'}>
                           <DropdownMenuItem title={'Games'} link={baseUrl + '/games'}/>
                           <DropdownMenuItem title={'Jugadores'} link={baseUrl + '/players'}/>
                         </DropdownMenu>*/}
                       </DropdownContainer>
                     </React.Fragment>
                   }
    >
      <CasinoOnlineRoutes/>
    </MainDashboard>
  )
}

export default CasinoOnlineMain;
