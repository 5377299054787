import React, {useEffect, useState} from "react";
import PageContainer from "../../../../../components/layout/PageContainer";
import PageSection from "../../../../../components/layout/PageSection";
import {Title, Button} from "@wargostec/react-components";
import Card from "../../../../../components/forms/Card";
import {Apis} from "../../../../../util/Apis";
import FormGroup from "../../../../../components/forms/FormGroup";
import Input from "../../../../../components/forms/FormEl";
import {ButtonBox} from "../../../../../components/forms/Button";
import {useForm} from "react-hook-form";
import check from "./greenCheck.png"
import avoid from "./AVOID.png"
import useFetch2 from "../../../../../hooks/useFetch2";

const BuscadorLudopata = () => {

  const salaId=localStorage.getItem('salaId')
  let defaultParams = {salaId: salaId, enabled: true}
  const [inicio, setInicio] = useState(true)

  const [params, setParams] = useState()
  const {data, fetchData} = useFetch2(Apis.BLACK + '/ban-list-members/query', null, params,false)

  const {register, handleSubmit, reset} = useForm({defaultValues: defaultParams});

  const getData = (params) => {
    //onsole.log(params)
    params.salaId = salaId
    setInicio(false)
    setParams(params)
  }

  useEffect(() => {
    fetchData()
  }, [params,reset])

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSubmit(getData)()
    }
  }

  return (
    <PageContainer>
      <PageSection>
          <Title text="Buscar ludópatas" type={'page'}/>

        <Card padding>
          <div className={"form-group row"} >
            <div className={"col-12 col-sm-9"}>
              <FormGroup>
                <Input {...register("document")} placeholder={"Buscar por documento"} onKeyDown={handleKeyDown} style={{fontSize:"25px",height:"50px"}}/>
              </FormGroup>
            </div>
            <div className={"col col-sm-3"}>
              <FormGroup>
                <ButtonBox>
                  <Button text={"Buscar"}  variant={'primary'} size={'lg'} onClick={handleSubmit(getData)}/>
                  <Button text={"Limpiar"} variant={'light'} size={'lg'} onClick={()=>reset({document:""})}/>
                </ButtonBox>
              </FormGroup>
            </div>
          </div>
          {data &&
          <div> {
            data.length > 0
              ? <div>{
                data.map(item =>
                  <div className={"form-group row"} style={{marginTop:"50px",marginBottom:"50px"}}>
                    <div className={"col-4 offset-2"} style={{alignSelf:"center"}}>
                      {item.imgUrl != null
                        ? <td style={{justifyContent: "center", display: "flex"}}>
                              {/*<img src={iniUrl + item.imgUrl} style={{
                                width: "60%",
                                height: "200px",
                                borderRadius: "200px"
                            }}/>*/}
                            <img src={avoid} style={{
                                height: "200px",
                                borderRadius: "200px"
                            }}/>
                          </td>
                        : <td style={{justifyContent: "center", display: "flex",alignSelf:"center"}}><img src={avoid} style={{width: "60%", height: "200px"}}/></td>
                      }
                    </div>
                    <div className={"col-5"}>
                      {/*<strong style={{fontSize: "1.8em"}}>{item.firstName + ' ' + item.lastName1 + ' ' + item.lastName2}</strong><br/>*/}
                      <strong style={{fontSize: "2.5em"}}>{item.firstName}</strong><br/>
                      <label style={{fontSize: "1.8em"}}>{item.document}</label><br/>
                      <label style={{fontSize: "1.8em"}}>{item.location}</label><br/>
                    </div>
                  </div>
                )}</div>
              : <React.Fragment>
                <div className={"form-group row"} style={{marginTop:"50px",marginBottom:"50px"}}>
                  <div className={"col-4 offset-2"}>
                    <td style={{justifyContent: "center", display: "flex"}}><img alt={"check"} src={check} style={{width: "60%", height: "200px",minWidth:"200px"}}/></td>
                  </div>
                  <div className={"col-3"} style={{alignSelf:"center"}}>
                    <strong style={{fontSize:"2em"}}>No se encuentra registrado</strong><br/>
                  </div>
                </div>
              </React.Fragment>
          }
          </div>
          }
        </Card>
      </PageSection>
    </PageContainer>
  )
}

export default BuscadorLudopata
