import React, {useContext, useEffect} from "react";
import PageContainer from "../../../../components/layout/PageContainer";
import PageSection from "../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import Button, {ButtonBox} from "../../../../components/forms/Button";
import FormGroup from "../../../../components/forms/FormGroup";
import Table from "react-bootstrap/Table";
import Card from "../../../../components/forms/Card";
import {SiteContext} from "../../../common/context/SiteContext";
import {Apis} from "../../../../util/Apis";
import Moment from "react-moment";
import DefaultProfile from './Profiel.png'
import {useHistory} from "react-router";
import Loader1 from "../../../common/components/Loader1";
import useFetch2 from "../../../../hooks/useFetch2";
import axios from "axios";
import useDeleteModal from "../../../../modals/useDeleteModal";
import DeleteGenericModal from "../../../../modals/DeleteGenericModal";

const LudopatiaList = () => {

  const history = useHistory()

  const querystring = window.location.search
  const query = new URLSearchParams(querystring)
  let list = query.get('list')

  const {salaId} = useContext(SiteContext)
  //const [params, setParams] = useState({q: "_"})
  const {loading, data, fetchData} = useFetch2(Apis.BLACK + `/ban-list/${list}/members`, [], {});

  // useEffect(() => {
  //   fetchData()
  // }, [params])

  const goToEdit = (id) => {
    history.push('/tgm/ludopatia/edit/' + id)
  }

  const deleteElement = id => axios.delete(Apis.BLACK + `/ban-list-members/${id}`)

  const {wrapper, refresh, openDeleteModal} = useDeleteModal({
    text: '¿Desea quitar esta persona de la lista?',
    action: deleteElement,
    onRefresh: fetchData
  })

  useEffect(() => {
    fetchData()
  }, [refresh])

  // let search = params
  // const handleSearch = e => {
  //   search.q = e.target.value ? e.target.value : "_"
  //   setParams(search)
  //   fetchData()
  // }

  return (
    <PageContainer>
      <PageSection>
          <Title text="Lista de ludopatía"/>
      </PageSection>
      <PageSection>
        <FormGroup>
          <ButtonBox>
            <Button title={"Nuevo"} variant={'primary'} onClick={() => history.push('/tgm/ludopatia-new')}/>
          </ButtonBox>
        </FormGroup>
        {/*<div style={{display: 'flex', width: '100%', marginBottom: '16px'}}>*/}
        {/*  <Input placeholder='Filtrar por nombre' onChange={handleSearch}/>*/}
        {/*</div>*/}
        {/* ESTA LISTA SERÁ DESHABILITADA TEMPORALMENTE MIENTRAS SOLO SE BUSQUE EN LUDOPATÍA OFICIAL
        <FormGroup>
          <LudopatiaListSelect ref={{}}/>
        </FormGroup>*/}
        <React.Fragment>
          {!loading ?
            <Card padding>
              <Table striped hover>
                <thead>
                <tr>
                  <th>Foto</th>
                  <th>Nombre</th>
                  <th>DNI</th>
                  <th>Localidad</th>
                  <th>Fecha de adición</th>
                  <th>Ingreso</th>
                  <th/>
                </tr>
                </thead>
                <tbody>
                {data && data.map(item =>
                  <tr key={item.id}>
                    {/*?<td><img src={iniUrl + item.imgUrl} style={{width:"60px", height:"60px"}}/></td>*/}
                    <td><img src={DefaultProfile} style={{width: "60px", height: "60px"}}/></td>
                    {/*<td>{item.firstName + ' '+ item.lastName1}</td>*/}
                    <td>{item.firstName + ' '}</td>
                    <td>{item.document}</td>
                    <td>{item.location}</td>
                    {item.createdAt != null
                      ?/*<td><Moment format={'HH:mm DD/MM/YYYY'}>{item.createdAt}</Moment></td>*/
                      <td><Moment format={'DD/MM/YYYY'}>{item.createdAt}</Moment></td>
                      : <td>{"Fecha no asignada"}</td>
                    }
                    {<td>{item.blockEntry? "Bloqueado" : "Con advertencia"}</td>}
                    <td>
                      <ButtonBox>
                        <Button title={'Editar'} size={'s'} variant={'secondary'} onClick={() => goToEdit(item.id)}/>
                        <Button title={'Quitar'} size={'s'} variant={'danger'} onClick={() => openDeleteModal(item.id)}/>
                      </ButtonBox>
                    </td>
                  </tr>
                )}
                </tbody>
              </Table>

            </Card>
            : <Loader1/>
          }
        </React.Fragment>
      </PageSection>
      <DeleteGenericModal {...wrapper}/>
    </PageContainer>
  )
}

export default LudopatiaList
