import React, {useEffect} from 'react';
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import axios from "axios";
import AftTransferForm from "./AftTransferForm";

const AftTransferEdit = ({id, setTitle, setAction}) => {

  const {data, loading} = useFetch2(Apis.TGM + '/aft-transfer/' + id)

  function update(params) {
    return axios.put(Apis.TGM + '/aft-transfer/' + id, params);
  }

  useEffect(() => {
    setTitle("Editar transferencia")
  }, [])

  return (
    <AftTransferForm value={data} onSubmit={update} setAction={setAction}/>
  );
};

export default AftTransferEdit;
