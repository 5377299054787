import React, {useMemo, useRef, useState} from "react";
import PageContainerFull from "../../../../../components/layout/PageContainerFull";
import PageSection from "../../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import Button, {ButtonBox} from "../../../../../components/forms/Button";
import {FormBlock} from "../../../../../components/forms/FormGroup";
import Table from "react-bootstrap/Table";
import {Apis} from "../../../../../util/Apis";
import axios from "axios";
import OwnerNew from "./OwnerNew";
import DeleteGenericModal from "../../../../../modals/DeleteGenericModal";
import OwnerEdit from "./OwnerEdit";
import GenericModal2 from "../../../../../modals/GenericModal2";
import useEditModal from "../../../../../modals/useEditModal";
import useDeleteModal from "../../../../../modals/useDeleteModal";
import useFetch2 from "../../../../../hooks/useFetch2";
import {LoadingTableV2} from "../../../../../components/forms/Tables";
import {errorToast, successToast} from "../../../../../components/layout/ToastStyles";

const OwnerList = () => {

  const [data, setData] = useState([])
  const tenant = useRef("")

  const {data: info,loading : loadingInfo, fetchData} = useFetch2(Apis.USER + '/users/me', null)

  const deletePartner = id => axios.delete(Apis.TGM + '/partners/' + id).then(res => {
    successToast("Propietario eliminado exitosamente")
  })
    .catch(err => {
      errorToast(err.response.data.message, 6000)
    })

  //INICIO REUTILIZABLE
  //hooks para crear,editar y eliminar
  const {wrapper: wrapper1, addAction, editAction} = useEditModal({
    editComponent: <OwnerEdit/>,
    addComponent: <OwnerNew tenant={tenant.current}/>,
    onRefresh: fetchData
  })

  const {
    wrapper: wrapper2,
    openDeleteModal
  } = useDeleteModal({
    text: '¿Desea eliminar este propietario?',
    action: deletePartner,
    onRefresh: fetchData
  })

  const getOwners = (newTenant) => {
    tenant.current = newTenant
    axios.get(`${Apis.TGM}/partners?tenant=${newTenant}`, {})
      .then((res) => {
        setData(res.data);
      }).catch(() => {
    })
  }

  //FIN DE REUTILIZABLE

  useMemo(() => {
    if (info) {
      getOwners(info.user.company)
    }
  }, [info])

  return (
    <PageContainerFull>
      <PageSection>
          <Title text="Lista de propietarios" type={'page'}/>
        <ButtonBox>
          <Button variant={'primary'} title={'Nuevo'} onClick={() => addAction()}/>
        </ButtonBox>
      </PageSection>

      <PageSection>
        <FormBlock>
          <LoadingTableV2 loading={loadingInfo} data={data} noDataText={"No hay información"}>
            <Table striped hover>
              <thead>
              <tr>
                <th>RUC</th>
                <th>Nombre</th>
                <th>Dirección</th>
                <th/>
              </tr>
              </thead>
              <tbody>
              {data && data.map(item =>
                <tr key={item.id}>
                  <td>{item.ruc}</td>
                  <td>{item.name}</td>
                  <td>{item.address}</td>
                  <td>
                    <Button onClick={() => editAction(item.id)}>Editar</Button>
                  </td>
                  <td>
                    <Button variant={'danger'} onClick={() => openDeleteModal(item.id)}>Eliminar</Button>
                  </td>
                </tr>

              )}
              </tbody>
            </Table>
          </LoadingTableV2>
        </FormBlock>
      </PageSection>

      <GenericModal2 {...wrapper1}/>
      <DeleteGenericModal {...wrapper2}/>

    </PageContainerFull>
  )
}

export default OwnerList;
