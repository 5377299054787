import React, {useEffect} from 'react';
import {Apis} from "../../../util/Apis";
import {FormBlock} from "../../../components/forms/FormGroup";
import useFetch2 from "../../../hooks/useFetch2";
import {LoadingTableV2} from "../../../components/forms/Tables";
import Table from "react-bootstrap/Table";

const DetailCouponsWin = ({values, setTitle}) => {

  const {loading, data, error} = useFetch2(Apis.PC + `/session/${values.id}/coupons`, []);

  useEffect(() => {
    setTitle("Cupones acumulados");
  }, []);


  return (
    <>
      <FormBlock>
        <LoadingTableV2 loading={loading} error={error} data={data} noDataText={'Aún no hay cupones'}>
          <Table striped hover>
            <thead>
            <tr>
              <th>Cupone</th>
              <th>Cantidad</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            {data && data.map(item =>
              <tr key={item.sessionId}>
                <td>{item.couponName}</td>
                <td>{item.quantity}</td>
              </tr>
            )}
            </tbody>
          </Table>
        </LoadingTableV2>
      </FormBlock>
    </>
  );
};

export default DetailCouponsWin;
