import React, {useContext, useEffect, useRef, useState} from 'react'
import {Button, Title} from '@wargostec/react-components'
import Select from 'react-select';
import useFetch2 from '../../../../hooks/useFetch2';
import Input from '../../../../components/forms/FormEl';
import FormGroup from '../../../../components/forms/FormGroup';
import Card from '../../../../components/forms/Card';
import {ProgressBar} from 'react-bootstrap';
import styled, {keyframes} from "styled-components";
import {FlexLayout} from '../../../../components/flex/Flex';
import {Apis} from '../../../../util/Apis';
import {BsFillCheckCircleFill, BsFillExclamationCircleFill} from 'react-icons/bs';
import axios from 'axios';
import {numberFormat} from '../../../../components/util/NumberUtil';
import {HiOutlineSlotMachine} from '../../../../components/assets/icons/slot-machine';
import {SiteContext} from '../../../common/context/SiteContext';
import {successToast} from '../../../../components/layout/ToastStyles';

const gradient = keyframes`
	0% {
		background-position:100%;
	}

	100% {
		background-position:0%;
	}
`

const CustomProgressBar = styled(ProgressBar)`
  background: #d9dbdd;
  border-radius:6px;
  width:100%;
  height:32px;

  > div {
    background: linear-gradient(90deg, #7735fa, #455ade, #a960e5, #523ce8);
    box-shadow: 0px 2px 3px 0px rgb(90 97 105 / 40%);
    background-size: 300% 100%;
    border-radius:6px;
    animation: ${gradient} 1s linear infinite;
    text-align:center;
    max-width:100%;
  }
`

const CustomSpan = styled.span`
  text-align: right;
  width: 80px;
  font-weight: bold;
  font-size: 16px;
`

const AuditRecaudoCoin = ({fechas}) => {

  const {salaId, cajaConfig} = useContext(SiteContext);

  const {data, fetchData} = useFetch2(Apis.CAJA + `/report-bill/machines-audit`, [], fechas);
  const {data: data2, fetchData: fetchData2} = useFetch2(Apis.TGM + `/meter-day/summary`, [], fechas);

  const {data: machines, loading, error} = useFetch2(Apis.TGM + `/machines/combo`, []);
  const [selectedMachine, setSelectedMachine] = useState(null);

  const [totalBillAmount, setTotalBillAmount] = useState("");
  const [billAmount200, setBillAmount200] = useState("");
  const [billAmount100, setBillAmount100] = useState("");
  const [billAmount50, setBillAmount50] = useState("");
  const [billAmount20, setBillAmount20] = useState("");
  const [billAmount10, setBillAmount10] = useState("");

  useEffect(() => {
    const total = (billAmount200 * 200) + (billAmount100 * 100) + (billAmount50 * 50) + (billAmount20 * 20) + (billAmount10 * 10);
    setTotalBillAmount(total);
  }, [billAmount200, billAmount100, billAmount50, billAmount20, billAmount10]);

  const [coinAmount, setCoinAmount] = useState(0);
  const [expectedBillAmount, setExpectedBillAmount] = useState(0);
  const [expectedCoinAmount, setExpectedCoinAmount] = useState(0);
  const [faltanteBilletes, setFaltanteBilletes] = useState(0);
  const [faltanteFichas, setFaltanteFichas] = useState(0);
  const machineSelectRef = useRef(null);

  const billInputRef = useRef(null);

  const billInput200Ref = useRef(null);
  const billInput100Ref = useRef(null);
  const billInput50Ref = useRef(null);
  const billInput20Ref = useRef(null);
  const billInput10Ref = useRef(null);

  const coinInputRef = useRef(null);

  useEffect(() => {
    let intervalId = setInterval(fetchData2, 5000);
    return () => {
      clearInterval(intervalId);
    };
  }, [fetchData2]);

  useEffect(() => {
    if (selectedMachine) {
      const body = {
        period: fechas.period,
        confId: selectedMachine.value
      };
      axios.post(Apis.CAJA + `/report-bill/calculate-bill`, body)
        .then(response => {
          const expectedBill = response.data.expected;
          const expectedCoin = 0;
          setExpectedBillAmount(expectedBill);
          setExpectedCoinAmount(expectedCoin);
          setFaltanteBilletes(expectedBill);
          setFaltanteFichas(expectedCoin);
        })
        .catch(error => {
          console.error('Error al consultar los valores esperados:', error);
        });

      if (cajaConfig && cajaConfig.auditByDenomEnabled) {
        billInput200Ref.current.focus();
      } else {
        billInputRef.current.focus();
      }

    }
  }, [selectedMachine, fechas, cajaConfig]);

  useEffect(() => {
    // Al seleccionar una máquina, enfocar el input de billetes de S/ 200
    if (selectedMachine) {
      if (cajaConfig && cajaConfig.auditByDenomEnabled) {
        billInput200Ref.current.focus();
      } else {
        billInputRef.current.focus();
      }
    }
  }, [selectedMachine, cajaConfig]);

  const handleMachineChange = (selectedOption) => {
    setSelectedMachine(selectedOption);
    setTotalBillAmount('');
    setBillAmount200('');
    setBillAmount100('');
    setBillAmount50('');
    setBillAmount20('');
    setBillAmount10('');
    setCoinAmount('');
    setFaltanteBilletes(expectedBillAmount);
    setFaltanteFichas(expectedCoinAmount);
  };

  const handleBillAmountChange = (event, denomination) => {
    const value = parseFloat(event.target.value) || 0;

    if (cajaConfig.auditByDenomEnabled) {
        switch (denomination) {
          case 200:
            setBillAmount200(value);
            break;
          case 100:
            setBillAmount100(value);
            break;
          case 50:
            setBillAmount50(value);
            break;
          case 20:
            setBillAmount20(value);
            break;
          case 10:
            setBillAmount10(value);
            break;
          default:
            break;
        }

        const total = (billAmount200 * 200) +
          (billAmount100 * 100) +
          (billAmount50 * 50) +
          (billAmount20 * 20) +
          (billAmount10 * 10);

        setTotalBillAmount(total);

        const newFaltanteBilletes = expectedBillAmount - total;
        setFaltanteBilletes(newFaltanteBilletes);
    } else {
        setTotalBillAmount(value); 
        const newFaltanteBilletes = expectedBillAmount - parseFloat(value || 0);
        setFaltanteBilletes(newFaltanteBilletes);
    }
};

  const handleCoinAmountChange = (event) => {
    const value = event.target.value;
    setCoinAmount(value);
    const newFaltanteFichas = expectedCoinAmount - parseFloat(value || 0);
    setFaltanteFichas(newFaltanteFichas);
  };

  const handleBillInputKeyPress = (event, nextInputRef) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (nextInputRef) {
        nextInputRef.current.focus();
      } else {
        coinInputRef.current.focus(); // Después del último input de billetes, enfocar en el input de fichas
      }
    }
  };

  const handleBillAmountKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      coinInputRef.current.focus();
    }
  };

  const handleCoinAmountKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleFormSubmit(event);
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (selectedMachine && totalBillAmount !== null && coinAmount !== null) {
      const body = {
        salaId: salaId,
        periodo: fechas.period,
        amount: totalBillAmount,
        confId: selectedMachine.value,
        coinAmount: coinAmount
      };

      axios.post(Apis.CAJA + '/audit-bill', body)
        .then(response => {
          console.log('Datos enviados correctamente:', response.data);
          // Limpiar el formulario después de enviar los datos
          setSelectedMachine(null);
          setTotalBillAmount(0);
          setBillAmount200('');
          setBillAmount100('');
          setBillAmount50('');
          setBillAmount20('');
          setBillAmount10('');
          setCoinAmount(0);
          setFaltanteBilletes(0);
          setFaltanteFichas(0);
          machineSelectRef.current.focus();
          fetchData();
          successToast();
        })
        .catch(error => {
          console.error('Error al enviar los datos:', error);
        });
    } else {
      console.error('Por favor, ingrese valores válidos en los campos de billetes y fichas.');

    }
  };

  const machineOptions = machines?.map(machine => ({
    value: machine.confId,
    label: machine.machine,
  })) || [];

  useEffect(() => {
    handleSearch()
    machineSelectRef.current.focus();
    // eslint-disable-next-line
  }, [fechas])

  const handleSearch = () => {
    fetchData()
    fetchData2()
  }

  return (
    <>
      <Card padding style={{width: '100%', gap: '16px', display: 'flex', flexDirection: 'row'}}>
        <FlexLayout column alignItems='center' style={{flex: 1}}>
          <span style={{textAlign: 'center', fontWeight: 'bold', fontSize: '16px'}}>
            Máquinas recaudadas {data2?.recaudadas} / {data?.total}
          </span>
          <CustomProgressBar
            animated
            now={data2?.recaudadas / data2?.total * 100}
            label={(data2?.recaudadas / data2?.total * 100).toFixed(1) + '%'}
          />
        </FlexLayout>
        <FlexLayout column alignItems='center' style={{flex: 1}}>
          <span style={{textAlign: 'center', fontWeight: 'bold', fontSize: '16px'}}>
            Ingreso de billetero {data?.machinesAudit} / {data?.quantityMachine}
          </span>
          <CustomProgressBar
            animated
            now={data?.machinesAudit / data?.quantityMachine * 100}
            label={(data?.machinesAudit / data?.quantityMachine * 100).toFixed(1) + '%'}
          />
        </FlexLayout>
      </Card>

      <FlexLayout gap='16px' alignItems='stretch' style={{width: '100%'}}>
        <Card padding style={{flex: 1}}>
          <Title text='Ingrese monto' type='form' />
          <form onSubmit={handleFormSubmit}>
            {loading ? (
              <span>Cargando máquinas...</span>
            ) : error ? (
              <span>Error al cargar máquinas: {error}</span>
            ) : (
              <FormGroup>
                <label>Máquina</label>
                <Select
                  options={machineOptions}
                  value={selectedMachine}
                  onChange={handleMachineChange}
                  placeholder="Seleccione..."
                  autoFocus
                  ref={machineSelectRef}
                  styles={{
                    container: (baseStyles) => ({
                      ...baseStyles,
                      width: '100%'
                    }),
                    indicatorSeparator: (baseStyles) => ({
                      ...baseStyles,
                      display: 'none'
                    })
                  }}
                />
              </FormGroup>
            )}
            {selectedMachine && (
              cajaConfig && cajaConfig.auditByDenomEnabled ?
                <FlexLayout column padding='0 16px'>
                  <FlexLayout gap='16px' alignItems='center' style={{width: '100%'}}>
                    <CustomSpan>Billetes</CustomSpan>
                    <span style={{width: '100%', fontWeight: 'bold'}}>Cantidad</span>
                    <CustomSpan>Total</CustomSpan>
                  </FlexLayout>
                  <FlexLayout gap="16px" alignItems="center" style={{width: '100%'}}>
                    <CustomSpan>S/ 200</CustomSpan>
                    <Input
                      type="number"
                      value={billAmount200}
                      onChange={(e) => handleBillAmountChange(e, 200)}
                      ref={billInput200Ref}
                      onKeyPress={(e) => handleBillInputKeyPress(e, billInput100Ref)}
                    />
                    <CustomSpan>{billAmount200 * 200}</CustomSpan>
                  </FlexLayout>
                  <FlexLayout gap="16px" alignItems="center" style={{width: '100%'}}>
                    <CustomSpan>S/ 100</CustomSpan>
                    <Input
                      type="number"
                      value={billAmount100}
                      onChange={(e) => handleBillAmountChange(e, 100)}
                      ref={billInput100Ref}
                      onKeyPress={(e) => handleBillInputKeyPress(e, billInput50Ref)}
                    />
                    <CustomSpan>{billAmount100 * 100}</CustomSpan>
                  </FlexLayout>
                  <FlexLayout gap="16px" alignItems="center" style={{width: '100%'}}>
                    <CustomSpan>S/ 50</CustomSpan>
                    <Input
                      type="number"
                      value={billAmount50}
                      onChange={(e) => handleBillAmountChange(e, 50)}
                      ref={billInput50Ref}
                      onKeyPress={(e) => handleBillInputKeyPress(e, billInput20Ref)}
                    />
                    <CustomSpan>{billAmount50 * 50}</CustomSpan>
                  </FlexLayout>
                  <FlexLayout gap="16px" alignItems="center" style={{width: '100%'}}>
                    <CustomSpan>S/ 20</CustomSpan>
                    <Input
                      type="number"
                      value={billAmount20}
                      onChange={(e) => handleBillAmountChange(e, 20)}
                      ref={billInput20Ref}
                      onKeyPress={(e) => handleBillInputKeyPress(e, billInput10Ref)}
                    />
                    <CustomSpan>{billAmount20 * 20}</CustomSpan>
                  </FlexLayout>
                  <FlexLayout gap="16px" alignItems="center" style={{width: '100%'}}>
                    <CustomSpan>S/ 10</CustomSpan>
                    <Input
                      type="number"
                      value={billAmount10}
                      onChange={(e) => handleBillAmountChange(e, 10)}
                      ref={billInput10Ref}
                      onKeyPress={(e) => handleBillInputKeyPress(e, null)} // Aquí enfoca el input de fichas
                    />
                    <CustomSpan>{billAmount10 * 10}</CustomSpan>
                  </FlexLayout>
                </FlexLayout>
                :
                <FormGroup>
                  <label>Billetes</label>
                  <Input
                    type="number"
                    value={totalBillAmount}
                    onChange={handleBillAmountChange}
                    onKeyPress={handleBillAmountKeyPress}
                    placeholder="Billetes"
                    ref={billInputRef}
                  />
                </FormGroup>
            )}
            <FormGroup>
              <label>Fichas</label>
              <Input
                type="number"
                value={coinAmount}
                onChange={handleCoinAmountChange}
                onKeyPress={handleCoinAmountKeyPress}
                placeholder="Monedas"
                ref={coinInputRef}
              />
            </FormGroup>
            <Button type="submit" text="Guardar" />
          </form>

        </Card>
        <Card padding style={{flex: 1}}>
          <FlexLayout column gap='16px' alignItems='center' justifyContent='center' style={{height: '100%'}}>
            {selectedMachine ?
              <FlexLayout gap='32px'>
                <FlexLayout column alignItems='center'>
                  {faltanteBilletes >= 0 ?
                    <BsFillCheckCircleFill size={48} color='#16a34a' />
                    :
                    <BsFillExclamationCircleFill size={48} color='#fbbf24' />
                  }
                  <span>Faltante Billetes</span>
                  <span style={{fontSize: '24px', fontWeight: 'bold'}}>{numberFormat(faltanteBilletes)}</span>
                  <Button variant='dark' size='sm' text='Billetes' />
                </FlexLayout>
                <FlexLayout column alignItems='center'>
                  {faltanteFichas > 0 ?
                    <BsFillCheckCircleFill size={48} color='#16a34a' /> :
                    faltanteFichas < 0 ?
                      <BsFillExclamationCircleFill size={48} color='#fbbf24' /> :
                      <BsFillCheckCircleFill size={48} color='#d9dbdd' />
                  }
                  <span>Faltante Fichas</span>
                  <span style={{fontSize: '24px', fontWeight: 'bold'}}>{numberFormat(faltanteFichas)}</span>
                </FlexLayout>

              </FlexLayout>
              :
              <FlexLayout column alignItems='center'>
                <HiOutlineSlotMachine size={38} />
                <span>Seleccione una máquina para empezar</span>
              </FlexLayout>
            }

          </FlexLayout>
        </Card>
      </FlexLayout>
    </>
  );
};

export default AuditRecaudoCoin;
