import React, { useContext, useState } from 'react';
import { Title } from "@wargostec/react-components";
import { useHistory, useParams } from "react-router";
import Moment from "react-moment";
import Chart from 'react-apexcharts'
import Table from "react-bootstrap/Table";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {PageBackLabel} from '../../../../components/layout/PageBackLabel';
import PageSection from '../../../../components/layout/PageSection';
import {FlexLayout} from '../../../../components/flex/Flex';
import {getDateFilter} from "../../../../components/util/CasinoDates";
import {SlDiamond} from "react-icons/sl";
import {DateTime} from 'luxon';
import CardOverview, {CardOverviewContainer} from '../../../../components/layout/CardOverview';
import {IoMdWalk} from "react-icons/io";
import EmptyState from "../../../common/components/EmptyState";
import {BsBadge8kFill} from "react-icons/bs";

import Button from "../../../../components/forms/Button";
import Card, { CardBody, CardHeader } from "../../../../components/forms/Card";
import DateFilter from "../../../../components/datefilter/DateFilter";
import { SiteContext } from "../../../common/context/SiteContext";
import { Apis } from "../../../../util/Apis";
import { NumberRound } from "../../../../components/util/NumberUtil";
import AsyncBlock from "../../../../components/forms/AsyncBlock";
import { LoadingTableV2 } from "../../../../components/forms/Tables";
import useGet from '../../../../hooks/useGet';
import { getName, getAge, getMachineLabels } from '../../../../util';
import {FaChild, FaHandHoldingUsd, FaIdCard, FaRunning, FaUserCircle} from "react-icons/fa";
import {MdCake, MdLocalPlay, MdPhonelinkRing} from "react-icons/md";

const CustomerInfo = () => {

  const { id } = useParams()
  const { salaId } = useContext(SiteContext)
  const history = useHistory()
  const date = getDateFilter('month')

  const defaultDates = { ini: date.ini, fin: date.fin }
  const defaultParams = { salaId: salaId, customerId: id, ini: defaultDates.ini, fin: defaultDates.fin }

  const [fechas, setFechas] = useState(defaultDates)
  const [params, setParams] = useState(defaultParams)

  const {
    data: customer,
    loadingCustomer
  } = useGet({
    url: Apis.PC + '/customers/' + id
  })

  const {
    data: session,
    loading: loadingSession,
    error: errorSession,
    fetchData: getSession
  } = useGet({
    url: Apis.PC + '/session',
    initialValue: [],
    params
  })

  const {
    data: monthlyStats,
    loading: loadingMonthlyStats,
    error: errorMonthlyStats
  } = useGet({
    url: Apis.PC + '/customers/' + id + '/stadistics-by-month',
    initialValue: [],
    params
  })

  const {
    data: machineTypeDistribution,
    loading: loadingMachineTypeDistribution,
    error: errorMachineTypeDistribution,
    fetchData: getMachineTypeDistribution
  } = useGet({
    url: Apis.PC + '/customers/' + id + '/machine-type-distribution',
    initialValue: [],
    params
  })

  const {
    data: slotGroupDistribution,
    loading: loadingSlotGroupDistribution,
    error: errorSlotGroupDistribution,
    fetchData: getSlotGroupDistribution
  } = useGet({
    url: Apis.PC + '/customers/' + id + '/slot-group-distribution',
    initialValue: [],
    params
  })

  const {
    data: timeDistribution,
    fetchData: getTimeDistribution
  } = useGet({
    url: Apis.PC + '/customers/' + id + '/time-distribution',
    initialValue: [],
    params
  })

  const {
    data: totalByConstumer,
    loading: loadingTotalByConstumer,
    fetchData: getTotalByConstumer
  } = useGet({
    url: Apis.SORTEO + '/sorteo-winner/total-by-customer',
    initialValue: [],
    params
  })

  const {
    data: gameDistribution,
    loading: loadingGameDistribution,
    error: errorGameDistribution,
    fetchData: getGameDistribution
  } = useGet({
    url: Apis.PC + '/customers/' + id + '/game-distribution',
    initialValue: [],
    params
  })

  const {
    data: dailyByCustomer,
    loading: loadingDailyByCustomer,
    error: errorDailyByCustomer,
    fetchData: getDailyByCustomer
  } = useGet({
    url: Apis.PC + '/session-day/daily-by-customer',
    initialValue: [],
    params
  })

  const {
    data: trendingPerCustomer,
    loading: loadingTrendingPerCustomer,
    fetchData: getTrendingPerCustomer
  } = useGet({
    url: Apis.PC + '/customers/' + id + '/trending-per-customer',
    initialValue: [],
    params
  })

  const {
    data: segmentsHistory,
    loading: loadingSegmentsHistory,
    error: errorSegmentsHistory,
    fetchData: getSegmentsHistory
  } = useGet({
    url: Apis.PC + '/customers/' + id + '/segments-history',
    initialValue: [],
  })

  const handleChangeDates = (dates) => {
    let auxParams = { ...params, ...dates }

    getSession(auxParams)
    getMachineTypeDistribution(auxParams)
    getSlotGroupDistribution(auxParams)
    getTimeDistribution(auxParams)
    getTotalByConstumer(auxParams)
    getGameDistribution(auxParams)
    getDailyByCustomer(auxParams)
    getTrendingPerCustomer(auxParams)
    getSegmentsHistory(auxParams)

    setFechas(dates)
    setParams(auxParams)
  }

  const donutTypeMachine = {
    series: machineTypeDistribution.map(item => item.ci),
    options: {
      chart: {
        type: 'donut',
      },
      legend: {
        position: 'bottom',
      },
      labels: getMachineLabels(machineTypeDistribution)
    },
  };

  const donutGroupMachine = {
    series: slotGroupDistribution.map(item => item.ci),
    options: {
      chart: {
        type: 'donut',
      },
      legend: {
        position: 'bottom',
      },
      labels: slotGroupDistribution.map(item => item.groupName)
    },
  };

  const donutTypeGame = {
    series: gameDistribution.map(item => item.ci),
    options: {
      chart: {
        type: 'donut',
      },
      legend: {
        position: 'bottom',
      },
      labels: gameDistribution.map(item => item.gameName)
    },
  };

  const barVisits = {
    options: {
      chart: {
        type: 'bar',
        height: 400
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 4
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 4,
        colors: ['transparent']
      },
      fill: {
        opacity: 1
      },
      legend: {
        show: true,
        showForSingleSeries: true
      },
      tooltip: {
        intersect: false,
        shared: true,
        onDatasetHover: {
          highlightDataSeries: true,
        },
        y: {
          formatter: function (val) {
            return val + " días"
          }
        }
      },
      xaxis: {
        categories: monthlyStats.map(month => month.monthName)
      }
    },
    series: [
      {
        name: "Días visitados",
        data: monthlyStats.map(month => month.visitas)
      }
    ]
  };

  const lineApostado = {
    options: {
      chart: {
        type: 'area',
        height: 400
      },
      colors: ["#feb019"],
      fill: {
        opacity: 1
      },
      legend: {
        show: true,
        showForSingleSeries: true
      },
      tooltip: {
        intersect: false,
        shared: true,
        onDatasetHover: {
          highlightDataSeries: true,
        },
        y: {
          formatter: function (val) {
            return "S/ " + val.toLocaleString() + " soles"
          }
        }
      },
      xaxis: {
        categories: monthlyStats.map(month => month.monthName),
      },
      stroke: {
        curve: 'smooth'
      },
    },
    series: [
      {
        name: "cantidad apostada",
        data: monthlyStats.map(month => month.ci),
      },
    ],
  };

  const lineNetwin = {
    options: {
      chart: {
        id: "line-netwin"
      },
      colors: ["#ff4560"],
      fill: {
        opacity: 1
      },
      legend: {
        show: true,
        showForSingleSeries: true
      },
      tooltip: {
        intersect: false,
        shared: true,
        onDatasetHover: {
          highlightDataSeries: true,
        },
        y: {
          formatter: function (val) {
            return "S/ " + val.toLocaleString() + " soles"
          }
        }
      },
      xaxis: {
        categories: monthlyStats.map(month => month.monthName),
      },
      stroke: {
        curve: 'smooth'
      },
    },
    series: [
      {
        name: "netwin",
        data: monthlyStats.map(month => month.netwin),
      },
    ],
  };

  const heatmap = {
    series: timeDistribution,
    options: {
      chart: {
        height: 350,
        type: 'heatmap',
      },
      dataLabels: {
        enabled: false
      },
      colors: ["#775dd0"],
      plotOptions: {
        bar: {
          borderRadius: 4,
        }
      }
    },
  }

  return (
    <Container fluid>
      <PageSection>
        <PageBackLabel text='Regresar' onClick={() => history.goBack()} />
        <Title text={getName(customer)} />
      </PageSection>

      <PageSection>
        <AsyncBlock loading={loadingCustomer}>
          {customer &&
            <Row>
              <Col xs={12} md={4}>
                <Card padding>
                  <FlexLayout column alignItems='center' style={{ paddingBottom: "20px" }}>
                    <FaUserCircle fontSize={'48'} />
                    <strong>{customer?.person?.firstName + ' ' + customer?.person?.lastName1}</strong>
                    <FlexLayout alignItems='center'>
                      <FlexLayout alignItems='center'>
                        <FaIdCard />
                        <span> {customer?.person?.document} </span>
                      </FlexLayout>
                    </FlexLayout>
                  </FlexLayout>
                </Card>
              </Col>

              <Col xs={12} md={4}>
                <Card>
                  <CardHeader>
                    <strong>Club de Jugadores</strong>
                  </CardHeader>
                  <Row style={{ padding: '16px', gap: '8px' }}>
                    <Col md={4} xs={12}>
                      <FlexLayout gap='4px' alignItems='center'>
                        <SlDiamond />
                        <span style={{ whiteSpace: 'nowrap' }}>Nivel:</span>
                        <strong>{customer?.levelName !== null ? customer?.levelName : ' -- '}</strong>
                      </FlexLayout>
                    </Col>
                    <Col md={4} xs={12}>
                      <FlexLayout gap='4px' alignItems='center'>
                        <span style={{ whiteSpace: 'nowrap' }}>Puntos:</span>
                        <strong>{customer && customer.pointsBalance !== null ? customer.pointsBalance : "0"}</strong>
                      </FlexLayout>
                    </Col>
                    <Col md={4} xs={12}>
                      <FlexLayout gap='4px' alignItems='center'>
                        <span style={{ whiteSpace: 'nowrap' }}>Puntos de nivel:</span>
                        <strong>{customer && customer.pointsLevel !== null ? customer.pointsLevel : "0"}</strong>
                      </FlexLayout>
                    </Col>
                    <Col md={8} xs={12}>
                      <FlexLayout gap='4px' alignItems='center'>
                        <span style={{ whiteSpace: 'nowrap' }}>Puntos de canje:</span>
                        <strong>{customer && customer.pointsRedeemable !== null ? customer.pointsRedeemable : "0"}</strong>
                      </FlexLayout>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col xs={12} md={4}>
                <Card>
                  <CardHeader>
                    <strong>Información Personal</strong>
                  </CardHeader>
                  <Row style={{ padding: '16px', gap: '8px' }}>
                    <Col md={4} xs={12}>
                      <FlexLayout gap='4px' alignItems='center'>
                        <FaChild />
                        <span style={{ whiteSpace: 'nowrap' }}>Edad:</span>
                        <strong>{getAge(customer?.person?.birthday)}</strong>
                      </FlexLayout>
                    </Col>
                    <Col md={4} xs={12}>
                      <FlexLayout gap='4px' alignItems='center'>
                        <MdCake />
                        <span style={{ whiteSpace: 'nowrap' }}>Cumpleaños:</span>
                        <strong><Moment format={'DD/MM'}>{customer?.person?.birthday}</Moment></strong>
                      </FlexLayout>
                    </Col>
                    <Col md={4} xs={12}>
                      <FlexLayout gap='4px' alignItems='center'>
                        <MdPhonelinkRing />
                        <span style={{ whiteSpace: 'nowrap' }}>Teléfono:</span>
                        <strong> {customer?.person?.cellphone ? customer?.person?.cellphone : "- -"}</strong>
                      </FlexLayout>
                    </Col>
                    <Col md={4} xs={12}>
                      <FlexLayout gap='4px' alignItems='center'>
                        <FaRunning />
                        <span style={{ whiteSpace: 'nowrap' }}>Última visita:</span>
                        <strong> <Moment format={'DD/MM/YYYY'}>{customer?.lastGame}</Moment> </strong>
                      </FlexLayout>
                    </Col>
                    <Col md={4} xs={12}>
                      <FlexLayout gap='4px' alignItems='center'>
                        <FaRunning />
                        <span style={{ whiteSpace: 'nowrap' }}>Miembro desde:</span>
                        <strong> <Moment format={'DD/MM/YYYY'}>{customer?.person?.createdAt}</Moment> </strong>
                      </FlexLayout>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          }
        </AsyncBlock>
        <AsyncBlock loading={loadingMonthlyStats} error={errorMonthlyStats}>
          <Row>
            <Col xs={12} md={4}>
              <Card>
                <CardHeader>
                  <strong>Visitas</strong>
                </CardHeader>
                <CardBody>
                  <Chart
                    options={barVisits.options}
                    series={barVisits.series}
                    type="bar"
                    height="350"
                  />
                </CardBody>
              </Card>
            </Col>
            <Col xs={12} md={4}>
              <Card>
                <CardHeader>
                  <strong>Apostado</strong>
                </CardHeader>
                <CardBody>
                  <Chart
                    options={lineApostado.options}
                    series={lineApostado.series}
                    type="area"
                    height="350"
                  />
                </CardBody>
              </Card>
            </Col>
            <Col xs={12} md={4}>
              <Card>
                <CardHeader>
                  <strong>Netwin</strong>
                </CardHeader>
                <CardBody>
                  <Chart
                    options={lineNetwin.options}
                    series={lineNetwin.series}
                    type="area"
                    height="350"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </AsyncBlock>
      </PageSection>

      <PageSection>
        <Card padding style={{ background: '#0A0A0A' }}>
          <DateFilter fechas={fechas} setFechas={handleChangeDates} modeSelect={"month"} modeDate={'month'} />
        </Card>
        {
          machineTypeDistribution.length > 0 && session.length ?
            <div>
              <Row>
                <AsyncBlock loading={loadingTrendingPerCustomer}>
                  <CardOverviewContainer gridColumns='repeat(3, 1fr)'>
                    <CardOverview
                      title={'S/ ' + Number(trendingPerCustomer.apProm).toFixed(2)}
                      subtitle='Apuesta Promedio'
                      icon={<FaHandHoldingUsd size={20} />}
                    />
                    <CardOverview
                      title={trendingPerCustomer.visits}
                      subtitle='Visitas'
                      icon={<IoMdWalk size={20} />}
                    />
                    <AsyncBlock loading={loadingTotalByConstumer}>
                      <CardOverview
                        title={'S/ ' + Number(totalByConstumer.amount).toFixed(2)}
                        subtitle='Monto total de Sorteo ganados'
                        icon={<BsBadge8kFill size={20}/>}
                      />
                    </AsyncBlock>
                  </CardOverviewContainer>
                </AsyncBlock>
              </Row>
              <Row>
                <Col xs={12} md={4}>
                  <AsyncBlock loading={loadingMachineTypeDistribution} error={errorMachineTypeDistribution}>
                    <Card>
                      <CardHeader>
                        <strong>Preferencia por tipo de máquina</strong>
                      </CardHeader>
                      <CardBody>
                        <Chart
                          options={donutTypeMachine.options}
                          series={donutTypeMachine.series}
                          type="donut"
                          height="400"
                        />
                      </CardBody>
                    </Card>
                  </AsyncBlock>
                </Col>
                <Col xs={12} md={4}>
                  <AsyncBlock loading={loadingSlotGroupDistribution} error={errorSlotGroupDistribution}>
                    <Card>
                      <CardHeader>
                        <strong>Máquinas Preferidas</strong>
                      </CardHeader>
                      <CardBody>
                        <Chart
                          options={donutGroupMachine.options}
                          series={donutGroupMachine.series}
                          type="donut"
                          height="400"
                        />
                      </CardBody>
                    </Card>
                  </AsyncBlock>
                </Col>
                <Col xs={12} md={4}>
                  <AsyncBlock loading={loadingGameDistribution} error={errorGameDistribution}>
                    <Card>
                      <CardHeader>
                        <strong>Preferencia por tipo de juego</strong>
                      </CardHeader>
                      <CardBody>
                        <Chart
                          options={donutTypeGame.options}
                          series={donutTypeGame.series}
                          type="donut"
                          height="400"
                        />
                      </CardBody>
                    </Card>
                  </AsyncBlock>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card>
                    <CardHeader>
                      <strong>Distribución de Coin In</strong>
                    </CardHeader>
                    <Chart
                      options={heatmap.options}
                      series={heatmap.series}
                      type="heatmap"
                      height="400"
                    />
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <Card>
                    <CardHeader>
                      <strong>Ultimas Sesiones</strong>
                    </CardHeader>
                    <LoadingTableV2 loading={loadingSession} error={errorSession} data={session} noDataText={'No hay información'}>
                      <Table striped hover style={{ paddingTop: '8px' }}>
                        <thead>
                          <tr>
                            <th className={'text-center'}>Máquina</th>
                            <th className={'text-center'}>Apostado</th>
                            <th className={'text-center'}>Ap.Prom</th>
                          </tr>
                        </thead>
                        <tbody>
                          {session && session.slice(0, 5).map((item, i) =>
                            <tr key={i}>
                              <td className={'text-center'}>{item.machine}</td>
                              <td className={'text-center'}>{item.ci}</td>
                              <td className={'text-center'}><NumberRound>{item.apProm}</NumberRound></td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </LoadingTableV2>
                  </Card>
                </Col>
                <Col xs={12} md={6}>
                  <Card>
                    <CardHeader>
                      <strong>Sesiones por día</strong>
                    </CardHeader>
                    <LoadingTableV2 loading={loadingDailyByCustomer} error={errorDailyByCustomer} data={dailyByCustomer} noDataText={'No hay información'}>
                      <Table striped hover style={{ paddingTop: '8px' }}>
                        <thead>
                          <tr>
                            <th className={'text-center'}>Fecha</th>
                            <th className={'text-center'}>Horas de juego</th>
                            <th className={'text-center'}>Coin In</th>
                            <th className={'text-center'}>Netwin</th>
                            <th className={'text-center'}>Acciones</th>
                          </tr>
                        </thead>
                        <tbody>
                          {dailyByCustomer && dailyByCustomer.map((item, i) =>
                            <tr key={i}>
                              <td className={'text-center'}>{item.fecha}</td>
                              <td className={'text-center'}>{(item.gamingMinutes / 60).toFixed(1)} h</td>
                              <td className={'text-center'}><NumberRound>{item.ci}</NumberRound></td>
                              <td className={'text-center'}><NumberRound>{item.netwin}</NumberRound></td>
                              <td className={'text-center'}>
                                <Button variante={'light'}
                                  size={'xs'}
                                  onClick={() => history.push(`/crm/sessions?customerId=${id}&ini=${item.fecha}&fin=${item.fecha}`)}>
                                  Detalle
                                </Button>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </LoadingTableV2>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <Card>
                    <CardHeader>
                      <strong>Historial de Niveles</strong>
                    </CardHeader>
                    <LoadingTableV2 loading={loadingSegmentsHistory} error={errorSegmentsHistory} data={segmentsHistory} noDataText={'No hay información'}>
                      <Table striped hover style={{ paddingTop: '8px' }}>
                        <thead>
                          <tr>
                            <th className={'text-center'}>Fecha</th>
                            <th className={'text-center'}>Nivel</th>
                            <th className={'text-center'}>Puntos</th>
                          </tr>
                        </thead>
                        <tbody>
                          {segmentsHistory && segmentsHistory.map((item, i) =>
                            <tr key={i}>
                              <td
                                className={'text-center'}>{DateTime.fromISO(item.createdAt).toFormat('dd/MM/yyyy HH:mm')}</td>
                              <td
                                className={'text-center'}>{item.oldSegment === null && item.newSegment === null ? '' : item.oldSegment + '→' + item.newSegment}</td>
                              <td className={'text-center'}>{item.points + ' puntos'}</td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </LoadingTableV2>
                  </Card>
                </Col>
              </Row>
            </div>
            :
            <Card padding>
              <EmptyState
                title={'No hay actividad registrada en estas fechas'}
                subtitle={'Puedes realizar una nueva busqueda en meses anteiores.'}
                icon={<MdLocalPlay size={32} />}
              />
            </Card>
        }
      </PageSection>
    </Container>
  );
};

export default CustomerInfo;
