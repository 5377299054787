import React, {useState} from "react";
import {Apis} from "../../../util/Apis";
import {errorToast, successToast} from "../../../components/layout/ToastStyles";
import styled from "styled-components";
import {AiOutlineCheck, AiOutlineFileExcel, AiOutlineFileExclamation} from "react-icons/ai";
import {LuFileUp} from "react-icons/lu";
import Button from "../../../components/forms/Button";

const CardFileContainer = styled.div`
  display: flex;
  border-radius: 8px;
  padding:12px;
  height: 40px;
  gap:8px;
  justify-content: center;
  align-items: center;
  border: 1px solid #d4d4d4;
`

const FileUploader = ({value, onChange, folder}) => {

  const [fileType, setFileType] = useState('')

  const handleChange = e => {
    if (e.target.files.length) {
      let rawImage = e.target.files[0];
      uploadAction(rawImage)
    }
  };

  function uploadAction(rawImage) {
    let data = new FormData()

    data.append("file", rawImage);
    data.append('folder', folder)

    fetch(Apis.FILE + "/upload/doc", {method: "POST", body: data}).then(response => response.json())
      .then(res => {
        successToast("Archivo cargo");
        getExtension(res.url)
        onChange(res.url)
      })
      .catch(error => {
        console.log(error);
        errorToast("Ocurrió un problema al identificar el archivo. Intente de nuevo en unos minutos.");
      })
  }

  const getExtension = (url) => {
    const parts = url.split('.')
    setFileType(parts[1])
  }

  const getPreview = () => {
    if (fileType === 'xlsx') {
      return <AiOutlineFileExcel size={24} color="#15803d" />
    } else {
      return <AiOutlineFileExclamation size={24} />
    }
  }

  return (
    <React.Fragment>
      {value ?
        <CardFileContainer>
          {getPreview()}
          <span>Archivo cargado correctamente</span>
          <AiOutlineCheck />
        </CardFileContainer>
        :
        <>
          <input type="file" onChange={handleChange} style={{display: 'none'}} id="fileInput" />
          <label htmlFor="fileInput">
            <Button onClick={() => document.getElementById('fileInput').click()}>
              <LuFileUp size={18} />
              <span>Subir Archivo</span>
            </Button>
          </label>
        </>

      }
    </React.Fragment>
  )
}

export default FileUploader

