import React from "react";

const RequiredLabel = ({show, text}) => {
  return (
    <>
      {show && <label style={{color:'red'}}>{`${text}`}</label>}
    </>
  )
}

export default RequiredLabel
