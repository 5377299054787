import React, {useEffect, useMemo} from "react";
import FormGroup from "../../../../components/forms/FormGroup";
import { useForm} from "react-hook-form";
import Input from "../../../../components/forms/FormEl";
const AlmacenForm = ({value, onSubmit, setAction}) => {

  const {register, reset, handleSubmit} = useForm();

  useMemo(()=>{
    if (value?.name) reset(value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[value])

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <React.Fragment>
      <FormGroup>
        <label>Nombre</label>
        <Input type='text' {...register("name")}/>
      </FormGroup>
    </React.Fragment>
  )
}

export default AlmacenForm
