import React, { useState } from "react";
import MainDashboard from "../../components/layout/MainDashboard";
import DropdownContainer from "../../components/layout/DropdownContainer";
import DropdownMenu from "../../components/layout/DropdownMenu";
import {MdBallot} from "react-icons/md";
import SecDropdownMenuItem from "../../components/layout/SecDropdownItem";
import BingosRoutes from "./BingosRoutes";

const BingosMain = () => {
  const baseUrl = '/bingos'

  const [isOpen, setIsOpen] = useState(false)
  const toggleMobile = () => {
    setIsOpen(!isOpen)
  }

  const [activeMenu, setActiveMenu] = useState('Bingos');

   const handleMenuClick = (menuTitle) => {
    setActiveMenu(menuTitle);
    setIsOpen(false); // Cerrar el menú móvil al cambiar de menú
  };

  return (
    <MainDashboard
      brand={'BINGO'}
      isOpen={isOpen}
      toggleMobile={toggleMobile}

      items={
        <DropdownContainer>
          <DropdownMenu
            title={'Bingos'}
            icon={<MdBallot />}
            setSelected={handleMenuClick}
            selected={activeMenu}
          >
            <SecDropdownMenuItem title={'Configuración'} link={baseUrl + '/eventos'} onClick={toggleMobile} />
            <SecDropdownMenuItem title={'Lista de partidas'} link={baseUrl + '/partidas'} onClick={toggleMobile} />
            <SecDropdownMenuItem title={'Jackpots'} link={baseUrl + '/jackpots'} onClick={toggleMobile} />
            <SecDropdownMenuItem title={'Venta de cartones'} link={baseUrl + '/venta'} onClick={toggleMobile} />
            <SecDropdownMenuItem title={'Rangos'} link={baseUrl + '/ranges'} onClick={toggleMobile} />
            {/*<SecDropdownMenuItem title={'Ganancia por ventas'} link={baseUrl + '/sales-profit'} onClick={toggleMobile} />*/}
          </DropdownMenu>
        </DropdownContainer>

      }
    >
      <BingosRoutes />
    </MainDashboard>
  )
}

export default BingosMain;
