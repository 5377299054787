import React, {useEffect} from 'react';
import axios from "axios";
import {Apis} from "../../../../util/Apis";
import {Controller, useForm} from "react-hook-form";
import {FileUploaderV2} from "../../../common/components/fileuploader/FileUploaderV2";
import FormGroup from "../../../../components/forms/FormGroup";
import Input, {SelectV2} from "../../../../components/forms/FormEl";

export const getVoucherName = (type) => {
  const value = parseInt(type)
  switch (value) {
    case 0:
      return 'Ninguno';
    case 1:
      return 'Factura';
    case 2:
      return 'Boleta';
    case 3:
      return 'Recibo';
    default:
      return 'Ninguno';
  }
}

const VoucherTypeComponent = ({info, setAction, setTitle}) => {

  const updateVoucherType = (params) => {
    const body = {
      "voucherType": params.voucherType,
      "voucherNumber": params.voucherNumber,
      "voucherPath": params.voucherPath
    }
    return axios.put(Apis.CAJA + `/operation-other/${info.id}/voucher-type`, body);
  }

  const {handleSubmit, register, control,watch, reset} = useForm();
  const watchType = watch("voucherType")

  useEffect(() => {
    setAction(() => handleSubmit(updateVoucherType))
  }, [])

  useEffect(() => {
    if (info) {
      if (Object.keys(info).length !== 0) {
        reset(info)
      }
    }
  }, [info]);

  useEffect(() => {
    setTitle("Tipo de comprobante")
  }, [])

  return (
    <React.Fragment>
      <FormGroup>
        <label>Tipo de comprobante</label>
        <SelectV2 {...register("voucherType")} options={
          [
            {id: 0, name: "NINGUNO"},
            {id: 1, name: "FACTURA"},
            {id: 2, name: "BOLETA"},
            {id: 3, name: "RECIBO"}
          ]
        }
        />
      </FormGroup>
      {
        watchType != 0 &&
        <>
          <FormGroup>
            <label>N° de {getVoucherName(watchType)}</label>
            <Input type='text' {...register("voucherNumber")} />
          </FormGroup>
          <Controller
            name="voucherPath"
            control={control}
            render={({field}) => <FileUploaderV2 folder={'other'} {...field}
                                                 assetsURL={`${process.env.REACT_APP_S3_WARGOS_ASSETS}`}
                                                 baseURL={Apis.FILE}/>}
          />
        </>
      }
    </React.Fragment>
  );
};

export default VoucherTypeComponent;
