import React, {useEffect} from 'react';
import {useForm} from "react-hook-form";
import {Form} from "react-bootstrap";
import FormGroup from "../../../../../components/forms/FormGroup";
import Input from "../../../../../components/forms/FormEl";

const SiteSalaForm = ({value, onSubmit, setAction}) => {

  const {register, handleSubmit, reset} = useForm();

  useEffect(() => {
    if (value) {
      reset(value)
    }
  }, [reset, value])

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit))
  }, [])

  return (
    <Form>
      <FormGroup>
        <label>Nombre</label>
        <Input name="name" {...register("name")}/>
      </FormGroup>
      <FormGroup>
        <label>Compañia</label>
        <Input name="company" {...register("company")}/>
      </FormGroup>
      <FormGroup>
        <label>Facebook</label>
        <Input name="facebook" {...register("facebook")}/>
      </FormGroup>
      <FormGroup>
        <label>Instagram</label>
        <Input name="instagram" {...register("instagram")}/>
      </FormGroup>
      <FormGroup>
        <label>Whatsapp</label>
        <Input name="whatsapp" {...register("whatsapp")}/>
      </FormGroup>

    </Form>
  );
};

export default SiteSalaForm;
