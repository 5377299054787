import React, {useEffect} from "react";
import PageContainer from "../../components/layout/PageContainer";
import PageSection from "../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import FormGroup from "../../components/forms/FormGroup";
import Input from "../../components/forms/FormEl";
import Button from "../../components/forms/Button";
import {useForm} from "react-hook-form";
import {Apis} from "../../util/Apis";
import axios from "axios";
import useFetch2 from "../../hooks/useFetch2";
import useLoadingToastr from "../../hooks/useLoadingToastr";
import ResetPassword from "../admin/pages/password/ResetPassword";
import {Tab, Tabs} from "../../components/tabs/Tabs";


const UserProfile = () => {

  const {loading, data} = useFetch2(Apis.USER + '/users/me-info', []);

  const {register, formState: {errors}, handleSubmit, reset} = useForm();

  useEffect(() => {
    if (data) {
      reset(data)
    }
  }, [data])

  const saveChanges = async (data) => {
    await axios.put(Apis.USER + `/users/edit-info`, data)
  }

  const [executeSend, loadingUpdate] = useLoadingToastr(saveChanges, "Error al actualizar usuario")

  return (
    <React.Fragment>
      {
        !loading && data &&
        <PageContainer>
          <PageSection style={{marginBottom: "40px"}}>
              <Title text="Mi perfil"/>

            <Tabs>
              <Tab title={'Datos personales'}>
                <div>
                  <FormGroup>
                    <label>Usuario</label>
                    <Input {...register("username", {required: "Usuario es obligatorio"})} />
                    {errors.username && <p style={{color: 'red'}}>{errors.username?.message}</p>}
                  </FormGroup>

                  <FormGroup>
                    <label>Email</label>
                    <Input {...register("email", {required: "Email es obligatorio"})} />
                  </FormGroup>

                  <FormGroup>
                    <label>Nombres</label>
                    <Input {...register("firstName", {required: "Nombre es obligatorio"})} />
                    {errors.firstName && <p style={{color: 'red'}}>{errors.firstName?.message}</p>}
                  </FormGroup>

                  <FormGroup>
                    <label>Apellidos</label>
                    <Input {...register("lastName", {required: "Apellido es obligatorio"})} />
                    {errors.lastName && <p style={{color: 'red'}}>{errors.lastName?.message}</p>}
                  </FormGroup>

                  <FormGroup>
                    <label>Documento de Identidad</label>
                    <Input {...register("document", {required: "Documento es obligatorio"})} />
                    {errors.document && <p style={{color: 'red'}}>{errors.document?.message}</p>}
                  </FormGroup>

                  <FormGroup>
                    <Button title={"Guardar"}
                            onClick={handleSubmit(executeSend)}
                            variant={'primary'}
                            disabled={loadingUpdate}
                    />
                  </FormGroup>
                </div>
              </Tab>
              <Tab title={'Contraseña'}>
                <ResetPassword/>
              </Tab>
            </Tabs>
          </PageSection>
        </PageContainer>
      }
    </React.Fragment>
  )
}
export default UserProfile;
