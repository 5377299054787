import React from "react";
import { Title } from "@wargostec/react-components";
import { useHistory } from "react-router";
import PageContainer from "../../../../components/layout/PageContainer";
import PageSection from "../../../../components/layout/PageSection";
import Button, { ButtonBox } from "../../../../components/forms/Button";
import { Apis } from "../../../../util/Apis";
import Card from "../../../../components/forms/Card";
import AsyncBlock from "../../../../components/forms/AsyncBlock";
import { NoDataTable } from "../../../../components/forms/Tables";
import CouponTypeMultiplicaTable from "../coupon-type/CouponTypeMultiplierTable";
import PageCardSubTitle from "../../../../components/layout/CardSubTitle";
import useGet from "../../../../hooks/useGet";

const CouponPromoList = () => {

  const history = useHistory();
  const { data, loading, error, fetchData } = useGet({
    url: Apis.PC + '/coupon-type/promos',
  })


  return (
    <PageContainer>
      <PageSection>
        <Title text="Multiplicadores" type={'page'} />
        <ButtonBox>
          <Button variant={'primary'} title={'Nuevo'} onClick={() => history.push("coupon-promo/new")} />
        </ButtonBox>
        <AsyncBlock loading={loading} error={error}>
          {data ? (
            data.length > 0 ?
              <>
                {data.map(couponType =>
                  <>
                    <PageCardSubTitle title={couponType.name} />
                    <Card>
                      <CouponTypeMultiplicaTable id={couponType.id} fetchData={fetchData} />
                    </Card>
                  </>
                )}
              </>
              :
              <Card padding>
                <NoDataTable text={'No hay multiplicadores configurados'} />
              </Card>
          )
            :
            <></>
          }
        </AsyncBlock>
      </PageSection>
    </PageContainer>
  )
}

export default CouponPromoList;
