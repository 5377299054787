import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react'
import PageContainer from '../../../../../components/layout/PageContainer'
import PageSection from '../../../../../components/layout/PageSection'
import {Title} from "@wargostec/react-components";
import Button from "../../../../../components/forms/Button"
import Card from "../../../../../components/forms/Card"
import FormGroup from "../../../../../components/forms/FormGroup"
import SalaIdInput from "../../../../common/components/SalaIdInput";
import {DatePickerCustom} from "../../../../../components/forms/FormEl";
import Slidebar from "../../../../common/layout/Slidebar";
import {SiteContext} from "../../../../common/context/SiteContext";
import {Apis} from "../../../../../util/Apis";
import {Controller, useForm} from "react-hook-form";
import {useHistory} from "react-router";
import useFetch2 from "../../../../../hooks/useFetch2";
import calculateRegression from "./regression";
import {serializeDownload} from "../../../../../components/util/SerializeParams";
import {getDateFilter} from "../../../../../components/util/CasinoDates";
import DateFilter from "../../../../../components/datefilter/DateFilter";
import {Scatter} from 'react-chartjs-2';
import {Chart, registerables} from 'chart.js';
import AsyncBlock from '../../../../../components/forms/AsyncBlock'
import {FlexLayout} from '../../../../../components/flex/Flex'
import CouponTypeSelectV2 from "../../../../common/components/selects/CouponTypeSelectV2";
import ReactTable from '../../../../../components/util/ReactTable'
import {NumberRound} from '../../../../../components/util/NumberUtil'

Chart.register(...registerables);

export const options = {
  responsive: true,
  scales: {
    y: {
      beginAtZero: true,
      title: {display: true, text: "Ganado"}
    },
    x: {
      title: {display: true, text: "Teórico"}
    }
  },
  plugins: {
    legend: {
      position: 'bottom',
    },
    title: {
      display: true,
      text: 'Probabilidad de sorteos ganados',
    },
  },
};

export default function GanadorList() {

  const {salaId} = useContext(SiteContext)
  const [slideOpen, setSlideOpen] = useState(false)
  const date = getDateFilter('day')

  const querystring = window.location.search
  const query = new URLSearchParams(querystring)
  let iniQueryParams = query.get('ini')
  let finQueryParams = query.get('fin')
  let couponTypeIdParams = query.get('couponTypeId')

  if (iniQueryParams === null) {
    iniQueryParams = date.ini
  }
  if (finQueryParams === null) {
    finQueryParams = date.fin
  }

  if (couponTypeIdParams === null) {
    couponTypeIdParams = ""
  }

  let history = useHistory()
  let defaultParams = {salaId: salaId, ini: iniQueryParams, fin: finQueryParams, couponTypeId: couponTypeIdParams}
  const [params, setParams] = useState(defaultParams)
  const [points, setPoints] = useState([])
  const [baseline, setBaseline] = useState([])
  const [max, setMax] = useState(null)
  const fechasDefault = {ini: params.ini, fin: params.fin}
  const [fechas, setFechas] = useState(fechasDefault)

  const {
    loading,
    data,
    fetchData,
    error
  } = useFetch2(Apis.PC + '/sorteo-trace', [], params, false)

  const {register, handleSubmit, setValue, control} = useForm({defaultValues: defaultParams});

  const getData = (params) => {
    if (params.couponTypeId === '0' || params.couponTypeId === 0) {
      params.couponTypeId = null
    }
    setParams(params)
  }

  const changeRoute = (params) => {
    history.push(`/crm/analisis-ganador?${serializeDownload(params)}`)
  }

  useEffect(() => {
    if (params) fetchData()
    changeRoute(params)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  useEffect(() => {
    setParams({...params, ...fechas})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fechas])

  useEffect(() => {
    if (slideOpen)
      if (params.couponTypeId !== null) {
        setValue('couponTypeId', params.couponTypeId)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slideOpen])


  const goToDetail = (customerId) => {
    history.push(`/crm/analisis-ganador/by-customer?customerId=${customerId}&${serializeDownload(params)}`)
  }

  const scatterData = () => {
    const pointsAux = data.map(nodo => {
      return {
        x: parseFloat(nodo.teorico),
        y: parseFloat(nodo.ganados)
      }
    }
    )
    setPoints(pointsAux)
  }

  const generateBaseline = () => {
    let pointsAux = []
    for (let i = 0; i < Math.round(max); i++) {
      pointsAux.push({x: i, y: i})
    }
    setBaseline(pointsAux)
  }

  const getMaxXAxisValue = () => {
    let max = 0
    for (let i = 0; i < points.length - 1; i++) {
      if (max < points[i].x) max = points[i].x
    }
    setMax(max)
  }

  const scatterPlot = {
    labels: points.map(x => x.x),
    datasets: [
      {
        type: 'scatter',
        label: 'probabilidad',
        data: points,
        backgroundColor: 'rgb(51 171 177 / 0.7)',
        pointBackgroundColor: 'rgb(51 171 177 / 0.7)',
        borderColor: 'rgb(51 171 177 / 0.7)',
        pointRadius: 6,
        borderWidth: 0.1
      },
      {
        type: 'line',
        label: 'Linea real',
        data: calculateRegression(points).regressionData.map(x => x),
        lineTension: 0.1,
        backgroundColor: 'rgba(255, 99, 132, 0.7)',
        borderColor: 'rgba(255, 99, 132, 0.7)',
        pointRadius: 1,
        borderWidth: 3
      },
      {
        type: 'line',
        label: 'Linea teorica',
        data: calculateRegression(baseline).regressionData.map(y => y),
        lineTension: 0.1,
        borderColor: 'rgba(53, 162, 235, 0.7)',
        backgroundColor: 'rgba(53, 162, 235, 0.7)',
        pointRadius: 1,
        borderWidth: 3
      }
    ],
  };

  useEffect(() => {
    if (data) {
      scatterData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    if (points) {
      getMaxXAxisValue()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [points])

  useEffect(() => {
    if (max) {
      generateBaseline()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [max])

  const numberValue = useCallback(props => <NumberRound>{props.renderValue()}</NumberRound>, []);
  const defaultValue = useCallback((props => props.renderValue() === null ? '--': props.renderValue()), []);
  const actionsValue = useCallback(props => 
    <FlexLayout justifyContent='center'>
      <Button title={'Ver'} size={'s'} onClick={() => goToDetail(props.row.original.customerId)} />
    </FlexLayout>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  , []);

  const columns = useMemo(
    () => [
      {
        header: 'ClienteID',
        accessorKey: 'customerId',
        enableGlobalFilter:false, // para deshabilitar el filter
        enableSorting:false, // para deshabilitar el sort
        meta: {
          headerClassName:'text-center',
          columnClassName:'text-center font-small',
        },
      },
      {
        header: 'Cliente',
        accessorKey: 'customerName',
      },
      {
        header: 'Nivel',
        accessorKey: 'levelName',
        enableSorting:false, // para deshabilitar el sort
        meta: {
          headerClassName:'text-center',
          columnClassName:'text-center',
        },
        cell: defaultValue
      },
      {
        header: 'Participaciones',
        accessorKey: 'partipaciones',
        meta: {
          headerClassName:'text-center',
          columnClassName:'text-center',
        },
      },
      {
        header: 'Teórico',
        accessorKey: 'teorico',
        meta: {
          headerClassName:'text-center',
          columnClassName:'text-center',
        },
        cell:numberValue
      },
      {
        header: 'Ganados',
        accessorKey: 'ganados',
        meta: {
          headerClassName:'text-center',
          columnClassName:'text-center',
        },
      },
      {
        header: 'Acciones',
        accessorKey: 'acciones',
        enableGlobalFilter:false, // para deshabilitar el filter
        enableSorting:false, // para deshabilitar el sort
        meta: {
          headerClassName:'text-center',
          columnClassName:'text-center',
        },
        cell: actionsValue
      },
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , []
  )

  return (
    <PageContainer>
      <Slidebar visible={slideOpen} onHide={() => setSlideOpen(false)}>
        <div style={{padding: '16px'}}>
          <SalaIdInput  {...register("salaId")} />
          <FormGroup>
            <label>Desde</label>
            <DatePickerCustom {...register("ini")} />
          </FormGroup>
          <FormGroup>
            <label>Hasta</label>
            <DatePickerCustom {...register("fin")} />
          </FormGroup>
          <FormGroup>
            <label>Cupón</label>
            <Controller
              name="couponTypeId"
              control={control}
              render={({field}) => <CouponTypeSelectV2 {...field} />}
            />
          </FormGroup>

          <Button variant={'primary'} title='Buscar' onClick={handleSubmit(getData)} />
        </div>
      </Slidebar>

      <PageSection>
          <Title text="Análisis de ganadores" type={'page'}/>
        <Card padding style={{background: '#0A0A0A'}}>
          <DateFilter fechas={fechas} setFechas={setFechas} />
        </Card>

        {points && points.length > 1 &&
          <Card padding>
            <Scatter options={options} data={scatterPlot} />
          </Card>
        }

        <AsyncBlock loading={loading} error={error}>
          <ReactTable
            columns={columns}
            data={data || []}
            filterOnclick={() => setSlideOpen(!slideOpen)}
            noData='No existen resultados para el filtro seleccionado'
            pageSize={20}
          />
        </AsyncBlock>
      </PageSection>
    </PageContainer>
  )
}


