export const getSnapText = (code) => {
  if (code === 0) {
    return ''
  } else if (code === 11) {
    return 'Stacker'
  } else if (code === 20) {
    return 'Cierre'
  } else {
    return 'n/a'
  }
};
