import React, {useEffect, useState} from "react";
import PageSection from "../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import Button, {ButtonBox} from "../../../../components/forms/Button";
import FormGroup from "../../../../components/forms/FormGroup";
import Table from "react-bootstrap/Table";
import {Apis} from "../../../../util/Apis";
import {useForm} from "react-hook-form";
import SalaIdInput from "../../../common/components/SalaIdInput";
import {DatePickerCustom} from "../../../../components/forms/FormEl";
import Slidebar from "../../../common/layout/Slidebar";
import axios from "axios";
import {FaSearch} from "react-icons/fa";
import useEditModal from "../../../../modals/useEditModal";
import GenericModal2 from "../../../../modals/GenericModal2";
import AnforaEdit from "./AnforaEdit";
import AnforaNew from "./AnforaNew";
import {useHistory} from "react-router";
import PageContainerFull from "../../../../components/layout/PageContainerFull";
import Moment from "react-moment";
import useFetch2 from "../../../../hooks/useFetch2";
import AsyncBlock from "../../../../components/forms/AsyncBlock";
import useModal from "../../../../modals/useModal";
import AnforaPurgarSave from "./AnforaPurgarSave";
import ModalInfo from "../../../../modals/ModalInfo";
import ClearHistory from "./ClearHistory";
import Card from "../../../../components/forms/Card";
import {successToast} from "../../../../components/layout/ToastStyles";
import useToggle from "../../../../hooks/useToggle";
import SwitchToggle from "../../../../components/layout/SwitchToggle";

const AnforaList = () => {

  const history = useHistory()
  let defaultParams = {enabled: true}

  const [params, setParams] = useState()

  const {
    loading,
    data,
    fetchData,
    error
  } = useFetch2(Apis.PC + '/anforas/grouped', [], params, false);

  const allCouponTypes = useFetch2(`${Apis.PC}/coupon-type`)
  const {register, handleSubmit} = useForm({defaultValues: defaultParams});

  const getData = (params) => {
    setParams(params)
  }

  const fetch = () => {
    const timer = setTimeout(() => {
      fetchData()
    }, 470)
    return () => clearTimeout(timer)
  }

  //hooks para crear,editar y eliminar
  const {wrapper: wrapper1, addAction, editAction} = useEditModal({
    editComponent: <AnforaEdit/>,
    addComponent: <AnforaNew/>,
    onRefresh: fetch
  })

  const {wrapper: wrapper3, someAction: purgarAction} = useModal({
    component: <AnforaPurgarSave/>
  })

  const {wrapper: wrapper4, someAction: verAction} = useModal({
    component: <ClearHistory/>
  })

  //hook para 'escuchar' cuando debemos refrescar la vista
  useEffect(() => {
    fetch()
  }, [params])

  const {isOpen: slideOpen, toggler} = useToggle({});

  const goToParticipantes = (id) => {
    history.push('/crm/anforas/participantes/' + id)
  }

  const lockAndUnlock = (id, locked) => {
    if (!locked) {
      axios.post(Apis.PC + '/anforas/' + id + '/lock')
        .then(() =>
          successToast("Ánfora bloqueada")
        )
    } else {
      axios.post(Apis.PC + '/anforas/' + id + '/unlock')
        .then(() =>
          successToast("Ánfora desbloqueada")
        )
    }
    fetch()
  }

  return (
    <PageContainerFull>
      <Slidebar visible={slideOpen} onHide={toggler}>
        <div style={{padding: '16px'}}>
          <SalaIdInput {...register("salaId")}/>
          <FormGroup>
            <label>Fecha</label>
            <DatePickerCustom {...register("fecha")}/>
          </FormGroup>

          <Button variant={'primary'} title='Buscar' onClick={handleSubmit(getData)}/>
        </div>
      </Slidebar>

      <PageSection>
          <Title text="Ánforas" type={'page'}/>

        <ButtonBox>
          {/*<Button variant={'primary'} onClick={() => addAction()}>*/}
          {/*  <FaPlus/> Nuevo*/}
          {/*</Button>*/}
          <Button variant={'primary'} onClick={toggler}>
            <FaSearch/> Buscar
          </Button>
        </ButtonBox>
      </PageSection>

      <PageSection>
        <Card padding>
          <AsyncBlock loading={loading} error={error}>
            {data && data.map(part =>
              <React.Fragment key={part.first}>
                <h3>{part.first}</h3>
                <Table striped hover size={'sm'}>
                  <thead>
                  <tr>
                    <th>ID</th>
                    <th>Nombre</th>
                    <th>Nombre Cupón</th>
                    <th>Inicio</th>
                    <th>Fin</th>
                    <th>Bloqueo</th>
                    <th>Acciones</th>
                  </tr>
                  </thead>
                  <tbody>
                  {part.second.map((item) =>
                    <tr key={item.id}>
                      <td>{item.id}</td>
                      <td>{item.name}</td>
                      <td>
                        {allCouponTypes.data &&
                          allCouponTypes.data.map(
                            cup => cup.id === item.couponTypeId && cup.name
                          )
                        }
                      </td>
                      <td><Moment format={'DD/MM/YYYY HH:mma'}>{item.ini}</Moment></td>
                      <td><Moment format={'DD/MM/YYYY HH:mma'}>{item.fin}</Moment></td>
                      <td>
                        <ButtonBox>
                          <SwitchToggle defaultChecked={item.locked} id={"enabled" + item.id} name={"enabled"}
                                        label={"Bloqueado"} onClick={() => lockAndUnlock(item.id, item.locked)}/>
                        </ButtonBox>
                      </td>
                      <td>
                        <ButtonBox>
                          <Button variant={'light'} size='xs' style={{minWidth: "105px"}}
                                  onClick={() => goToParticipantes(item.id)}
                                  title={'Participantes'}/>
                          <Button variant={'light'} size='xs' style={{minWidth: "105px"}}
                                  onClick={() => editAction(item.id)}
                                  title={'Editar'}/>
                          <Button variant={'danger'} size='xs' style={{minWidth: "105px"}}
                                  onClick={() => purgarAction({anforaId: item.id})}
                                  title={'Purgar ánfora'}/>
                          <Button variant={'light'} size='xs' style={{minWidth: "105px"}}
                                  onClick={() => verAction(item.id)}> Descargas</Button>
                        </ButtonBox>
                      </td>
                    </tr>
                  )}
                  </tbody>
                </Table>
              </React.Fragment>
            )}
          </AsyncBlock>
        </Card>
      </PageSection>

      <GenericModal2 {...wrapper1}/>
      <GenericModal2 {...wrapper3}/>
      <ModalInfo {...wrapper4}/>

    </PageContainerFull>
  )
};

export default AnforaList
