import React, {useContext, useEffect} from "react";
import {SiteContext} from "../../../common/context/SiteContext";
import {useForm} from "react-hook-form";
import axios from "axios";
import FormGroup from "../../../../components/forms/FormGroup";
import Input from "../../../../components/forms/FormEl";


const PolicyNew = ({setAction, setTitle}) => {

  const {salaId} = useContext(SiteContext)

  const {register, handleSubmit} = useForm();

  const save = (params) => {
    params.salaId = salaId;
    params.actions = 'test';
    return axios.post('http://localhost:9081/policies', params);
  }

  useEffect(() => {
    setTitle("Nueva Politica")
    setAction(() => handleSubmit(save))
  }, [])

  return (
    <FormGroup>
      <label>Nombre</label>
      <Input type={'text'} {...register("name")}/>
    </FormGroup>
  )

}

export default PolicyNew
