import React, {useEffect} from 'react';
import axios from "axios";
import {Apis} from "../../../../util/Apis";
import useFetch2 from "../../../../hooks/useFetch2";
import ClosedFondoForm from "./ClosedFondoForm";
import {errorToast} from "../../../../components/layout/ToastStyles";

const ClosedFondoEdit = ({id, setAction , setTitle}) => {

  const {loading, data} = useFetch2(Apis.CAJA + '/fondos/' + id + '/detail-close')

  const update = async (params) => {
    const body = {
      fondoId: params.fondoId,
      foundedAmount: params.input + params.output
    }
    try {
      await axios.post(Apis.CAJA + `/fondos/${id}/arqueo-and-close`, body)
    }catch (error) {
      errorToast({
        title: 'Error al generar el cierre, intente nuevamente en unos minutos',
        variant: 'danger',
      })
  }}

  useEffect(() => {
    setTitle("Cerrar fondo")
  }, [])

  return (
    <ClosedFondoForm value={data} onSubmit={update} setAction={setAction}/>
  );
};

export default ClosedFondoEdit;
