import React, {useContext, useEffect} from "react";
import axios from "axios";
import {Apis} from "../../../../../util/Apis";
import {SiteContext} from "../../../../common/context/SiteContext";
import CustomerGroupNotifForm from "./CustomerGroupNotifForm";

const CustomerGroupNotifNew = ({setAction, setTitle}) => {

  const {salaId} = useContext(SiteContext);
  const save = async (params) => {
    params.salaId = salaId
    if(params.customerGroupId == null){
      params.type="normal-group"
      await axios.post(Apis.KCUSTOMERGROUP + '/group', params)
    }
    else{
      if(params.customerGroupId == 1){
        //alert("birthday group")
        let massiveParams ={"salaId": salaId}
        await axios.post(Apis.KCUSTOMERGROUP + '/group/massive', massiveParams)
      }
      else{
       // alert("coupons group")
       let couponsParams ={"salaId": salaId, "min": params.min, "max": params.max}
       await axios.post(Apis.KCUSTOMERGROUP + '/coupons', couponsParams)
      }
    }
  }

  useEffect(() => {
    setTitle("Nuevo grupo")
  }, [])

  return (
    <CustomerGroupNotifForm value={null} onSubmit={save} setAction={setAction}/>
  )
};

export default CustomerGroupNotifNew
