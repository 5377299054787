import React, {useEffect} from 'react';
import {useForm} from "react-hook-form";
import FormGroup, {FormBlockTitle} from "../../../../components/forms/FormGroup";
import Input from "../../../../components/forms/FormEl";
import {Apis} from "../../../../util/Apis";
import axios from "axios";
import Button from "../../../../components/forms/Button";
import MesaTypeSelect from "../../../common/components/selects/MesaTypeSelect";
import useFetch2 from "../../../../hooks/useFetch2";

const MesasEditComponent = ({id,slideOpen, refresh}) => {

  const {data} = useFetch2(Apis.TABLES + '/tables/' + id, {});
  const {register, handleSubmit, reset,formState: {errors}} = useForm();

  const update = (params) => {
    axios.put(Apis.TABLES + '/tables/' + id, params)
      .then(() => {
        slideOpen(false)
        refresh(true)
      })
      .catch(() => {
      })
  }
  useEffect(() => {
    if (data) {
      reset(data)
    }
  }, [data])


  return (
    <>
      <FormBlockTitle>General</FormBlockTitle>

      <FormGroup>
        <label>Nombre de mesa</label>
        <Input type="text" {...register("tableCode", {required: "Campo obligatorio"})}/>
        {errors.tableCode && <p style={{color: 'red'}}>{errors.tableCode?.message}</p>}
      </FormGroup>

      <FormGroup>
        <label>Tipo</label>
        <MesaTypeSelect {...register("type", {required: "Campo obligatorio"})}/>
        {errors.type && <p style={{color: 'red'}}>{errors.type?.message}</p>}
      </FormGroup>

      <FormGroup>
        <label>Capacidad Maxima</label>
        <Input type="number" {...register("maxCapacity", {required: "Campo obligatorio"})}/>
        {errors.maxCapacity && <p style={{color: 'red'}}>{errors.maxCapacity?.message}</p>}
      </FormGroup>

      <Button variant={'primary'} title='Guardar' onClick={handleSubmit(update)}/>
    </>
  );
};

export default MesasEditComponent;
