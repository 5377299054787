import React, {useContext, useEffect, useState} from "react";
import PageSection from "../../../../components/layout/PageSection";
import {Title, Button} from "@wargostec/react-components";
import {LoadingTableV2} from "../../../../components/forms/Tables";
import Table from "react-bootstrap/Table";
import {SiteContext} from "../../../common/context/SiteContext";
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import {Link} from "react-router-dom";
import moment from "moment";
import Card, {CardBody, CardHeader} from "../../../../components/forms/Card";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import styled from "styled-components"
import Chart from 'react-apexcharts'
import {FlexLayout} from "../../../../components/flex/Flex";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const ItemTdStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
`


const ItemTd = ({ano, incremento, link}) => {
  return (
    <ItemTdStyled>
      <div>
        <strong>{ano}</strong>
        <OverlayTrigger placement={'left'} overlay={<Tooltip>Incremento vs año anterior</Tooltip>}>
          <span>{incremento}</span>
        </OverlayTrigger>
      </div>
      <Button size="sm" variant={"light"} text={link}/>
    </ItemTdStyled>
  )
}

const ProduccionPorMes = () => {

  const baseUrl = '/tgm'

  const [params, setParams] = useState({type: 'ci'})

  const {
    loading,
    data,
    error,
    fetchData
  } = useFetch2(Apis.TGM + '/deltas/pormes', [], params, false);

  useEffect(() => {
    fetchData()
  }, [])

  const typeProd = () => {
    setParams({type: 'ci'})
    fetchData()
  }

  const typeCi = () => {
    setParams({type: 'prod'})
    fetchData()
  }

  const getYear = (anYear) => {
    let onlyYear = '';
    if (anYear !== null) {
      onlyYear = anYear.toLocaleString('en');
    } else if (anYear === null) {
      onlyYear = '---';
    }
    return onlyYear;
  }

  const getInc = (incyear) => {
    let incYear = '';
    if (incyear !== null) {
      incYear = incyear.toFixed(2) + '%';
    } else if (incyear === null) {
      incYear = '---';
    }
    return incYear;
  }

  const calcIni = (monthInt, year) => {
    return moment(moment(new Date)).month(monthInt - 1).year(year).startOf('month').format('yyyy-MM-DD')
  }

  const calcFin = (fin, year) => {
    return moment(moment(new Date)).month(fin - 1).year(year).endOf('month').format('yyyy-MM-DD')
  }

  const bar = {
    options: {
      chart: {
        type: 'bar',
        height: 400
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 4
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: data.map(month => month.mesNombre),

      },
      yaxis: {
        labels: {
          formatter: (val) => {
            return val + ' k'
          },
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        intersect: false,
        shared: true,
        onDatasetHover: {
          highlightDataSeries: true,
        },
        y: {
          formatter: function (val) {
            return "S/ " + val + " K"
          }
        }
      }
    },
    series: [{
      name: '2019',
      data: data.map(ci => (ci.ano2019 / 1000).toFixed(1)),
    }, {
      name: '2020',
      data: data.map(ci => (ci.ano2020 / 1000).toFixed(1)),
    }, {
      name: '2021',
      data: data.map(ci => (ci.ano2021 / 1000).toFixed(1)),
    }, {
      name: '2022',
      data: data.map(ci => (ci.ano2022 / 1000).toFixed(1)),
    }, {
      name: '2023',
      data: data.map(ci => (ci.ano2023 / 1000).toFixed(1)),
    }, {
      name: '2024',
      data: data.map(ci => (ci.ano2024 / 1000).toFixed(1)),
    }
    ]
  };

  return (
    <Container>
      <PageSection>
          <Title text="Producción por mes" type={'page'}/>

        <Row>
          <Col xs={12} md={12} >
            <Card>
              <CardHeader>
                <FlexLayout justifyContent='space-between' alignItems='center'>
                  <strong>Cuadro comparativo por mes</strong>
                  <FlexLayout>
                    <Button variant={'primary'} size='sm' onClick={typeProd} text={"Netwin"}/>
                    <Button variant={'primary'} size='sm' onClick={typeCi} text={"Apostado"}/>
                  </FlexLayout>
                </FlexLayout>
              </CardHeader>
              <CardBody>
                <Chart
                  options={bar.options}
                  series={bar.series}
                  type="bar"
                  height={400}
                />
              </CardBody>
            </Card>
          </Col>
          <Col xs={12} md={12} >
            <Card>
              <LoadingTableV2 loading={loading} error={error} data={data}>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th className="text-center">Mes</th>
                      <th className="text-right">2019</th>
                      <th className="text-right">2020</th>
                      <th className="text-right">2021</th>
                      <th className="text-right">2022</th>
                      <th className="text-right">2023</th>
                      <th className="text-right">2024</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data && data.map((item, i) =>
                      <tr key={i}>
                        <td style={{verticalAlign: 'middle', textAlign: 'center'}}>
                          {item.mesNombre}
                        </td>

                        <td style={{verticalAlign: 'middle', textAlign: 'center'}}>
                          <ItemTd
                            ano={getYear(item.ano2019)}
                            incremento={getInc(item.inc2019)}
                            link={<Link
                              to={baseUrl + `/produccion-diaria?ini=${calcIni(item.mes, 2019)}&fin=${calcFin(item.mes, 2019)}`}>Detalle</Link>}
                          />
                        </td>

                        <td style={{verticalAlign: 'middle', textAlign: 'center'}}>
                          <ItemTd
                            ano={getYear(item.ano2020)}
                            incremento={getInc(item.inc2020)}
                            link={<Link
                              to={baseUrl + `/produccion-diaria?ini=${calcIni(item.mes, 2020)}&fin=${calcFin(item.mes, 2020)}`}>Detalle</Link>}
                          />
                        </td>

                        <td style={{verticalAlign: 'middle', textAlign: 'center'}}>
                          <ItemTd
                            ano={getYear(item.ano2021)}
                            incremento={getInc(item.inc2021)}
                            link={<Link
                              to={baseUrl + `/produccion-diaria?ini=${calcIni(item.mes, 2021)}&fin=${calcFin(item.mes, 2021)}`}>Detalle</Link>}
                          />
                        </td>

                        <td style={{verticalAlign: 'middle', textAlign: 'center'}}>
                          <ItemTd
                            ano={getYear(item.ano2022)}
                            incremento={getInc(item.inc2022)}
                            link={<Link
                              to={baseUrl + `/produccion-diaria?ini=${calcIni(item.mes, 2022)}&fin=${calcFin(item.mes, 2022)}`}>Detalle</Link>}
                          />
                        </td>

                        <td style={{verticalAlign: 'middle', textAlign: 'center'}}>
                          <ItemTd
                            ano={getYear(item.ano2023)}
                            incremento={getInc(item.inc2023)}
                            link={<Link
                              to={baseUrl + `/produccion-diaria?ini=${calcIni(item.mes, 2023)}&fin=${calcFin(item.mes, 2023)}`}>Detalle</Link>}
                          />
                        </td>

                        <td style={{verticalAlign: 'middle', textAlign: 'center'}}>
                          <ItemTd
                            ano={getYear(item.ano2024)}
                            incremento={getInc(item.inc2024)}
                            link={<Link
                              to={baseUrl + `/produccion-diaria?ini=${calcIni(item.mes, 2024)}&fin=${calcFin(item.mes, 2024)}`}>Detalle</Link>}
                          />
                        </td>
                      </tr>
                    )}
                  </tbody>

                </Table>
              </LoadingTableV2>
            </Card>
          </Col>
        </Row>
      </PageSection>
    </Container>
  )
}

export default ProduccionPorMes;
