import React, {useContext, useMemo} from 'react';
import {useForm} from "react-hook-form";
import useFilasMesa from "../../../../hooks/useFilasMesa";
import TableAmountsMesa from "../../../../components/util/TableAmountsMesa";
import {FlexLayout} from '../../../../components/flex/Flex';
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import {SiteContext} from "../../../common/context/SiteContext";


const CerrarMesaForm = ({id, type, data, onSubmit, setAction}) => {

  const {handleSubmit} = useForm();
  const {rows, setRows, total} = useFilasMesa(type);
  const montoTotal = data?.iniAmount + data?.cashIn + data?.stuffed - data?.cashOut - data?.refund;

  const {salaId} = useContext(SiteContext)

  const {data: dataSala} = useFetch2(Apis.SALAS + '/salas-config/' + salaId, [])

  const symbol = dataSala && dataSala.currencySymbol !== "" ? dataSala.currencySymbol : "S/"

  useMemo(() => {
    setAction(() => handleSubmit(() => {
      const params = {
        valueTokens: rows,
        amountCents: total,
        type: 6,
        customerId: null,
        snapId: data.snapId,
        tableId: id,
        salaId: salaId
      }
      onSubmit(params)
    }))
  }, [total])

  return (
    <React.Fragment>
      <FlexLayout column>
        <TableAmountsMesa rows={rows} setRows={setRows} total={total} />
        <FlexLayout column padding='16px' style={{width: '100%'}}>
          <FlexLayout style={{width: '100%'}}>
            <span style={{flex:1}}>Carga Inicial</span>
            <strong>{symbol + (data?.iniAmount ? data?.iniAmount : 0)}</strong>
          </FlexLayout>
          <FlexLayout style={{width: '100%'}}>
            <span style={{flex:1}}>Rellenos</span>
            <strong>{symbol + data?.stuffed ? data?.stuffed : 0}</strong>
          </FlexLayout>
          <FlexLayout style={{width: '100%'}}>
            <span style={{flex:1}}>Pagos</span>
            <strong>{symbol + data?.cashOut ? data?.cashOut : 0}</strong>
          </FlexLayout>

          <FlexLayout style={{width: '100%'}}>
            <span style={{flex:1}}>Ingresos</span>
            <strong>{symbol + (data?.cashIn ? data?.cashIn : 0)}</strong>
          </FlexLayout>
          <FlexLayout style={{width: '100%'}}>
            <span style={{flex:1}}>Devoluciones</span>
            <strong>{symbol + (data?.refund ? data?.refund : 0)}</strong>
          </FlexLayout>
          <hr style={{width: '100%',margin:0}} />
          <FlexLayout style={{width: '100%'}}>
            <span style={{flex:1}}>Total</span>
            <strong>{symbol + montoTotal}</strong>
          </FlexLayout>
          <FlexLayout style={{width: '100%'}}>
            <span style={{flex:1}}>Cierre</span>
            <strong>{symbol + total}</strong>
          </FlexLayout>
          <hr style={{width: '100%',margin:0}} />
          <FlexLayout style={{width: '100%'}}>
            <span style={{flex:1}}>Diferencia</span>
            <strong>{symbol + (montoTotal - total)}</strong>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    </React.Fragment>
  );
};

export default CerrarMesaForm;
