import React, {useCallback, useContext, useEffect, useMemo} from 'react';
import {SiteContext} from "../../../../common/context/SiteContext";
import {useHistory, useParams} from "react-router";
import useFetch2 from "../../../../../hooks/useFetch2";
import {Apis} from "../../../../../util/Apis";
import PageContainer from "../../../../../components/layout/PageContainer";
import PageSection from "../../../../../components/layout/PageSection";
import {PageBackLabel} from "../../../../../components/layout/PageBackLabel";
import {Title} from "@wargostec/react-components";
import Card from "../../../../../components/forms/Card";
import Button from "../../../../../components/forms/Button";
import Input from "../../../../../components/forms/FormEl";
import {useForm} from "react-hook-form";
import axios from "axios";
import {successToast} from "../../../../../components/layout/ToastStyles";
import AsyncBlock from "../../../../../components/forms/AsyncBlock";
import {FlexLayout} from '../../../../../components/flex/Flex';
import ReactTable from '../../../../../components/util/ReactTable';
import SwitchToggle from "../../../../../components/layout/SwitchToggle";

const MachinesSetEdit = () => {

  const {salaId} = useContext(SiteContext)
  const history = useHistory()
  const {id} = useParams()

  const {
    loading,
    data,
    error,
  } = useFetch2(Apis.TGM + `/machine-groups/${id}/machines`, []);

  const {data: data2} = useFetch2(Apis.TGM + `/machine-groups/${id}`, []);

  const {register, reset, handleSubmit} = useForm()

  useEffect(() => {
    if (data2) {
      reset(data2)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data2])

  const addOrRemoveMachine = async (machineId, confId, wenabled) => {
    const params = {machineId, confId, salaId, machineGroupId: id}

    if (!wenabled) {
      await axios.post(Apis.TGM + '/machine-groups/add-machine', params)
        .then(resp => {
            //fetchData()
            successToast("Registro habilitado correctamente")
          }
        )
    } else {
      await axios.post(Apis.TGM + '/machine-groups/remove-machine', params)
        .then(resp => {
            //fetchData()
            successToast("Registro deshabilitado correctamente")
          }
        )
    }
  }

  function goAdd() {
    history.push('/tgm/machine-set-new')
  }

  function update(params) {
    params.salaId = salaId
    params.id = id
    return axios.put(Apis.TGM + '/machine-groups/' + id, params).then(resp => {
        successToast("Nombre guardado correctamente")
      }
    )
  }

  const actionsValue = useCallback(props =>
      <FlexLayout justifyContent='center'>
        <SwitchToggle
          defaultChecked={props.row.original.enabled}
          id={"enabled" + props.row.original.id}
          name={"enabled"}
          label={"Agregado"}
          onClick={() => addOrRemoveMachine(props.row.original.id, props.row.original.confId, props.row.original.enabled)}/>
      </FlexLayout>
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , []);

  const columns = useMemo(
    () => [
      {
        header: 'ID',
        accessorKey: 'id',
        enableSorting: false, // para deshabilitar el sort
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center font-small',
        },
      },
      {
        header: 'Máquina',
        accessorKey: 'machine',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Fabricante y modelo',
        id: 'brand',
        accessorFn: (row) => row.brand === null ? '--' : row.brand,
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        },
      },
      {
        header: 'Juego',
        accessorKey: 'gameName',
      },
      {
        header: '',
        accessorKey: 'enabled',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        enableGlobalFilter: false, // para deshabilitar el filter
        cell: actionsValue
      },
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , []
  )

  return (
    <PageContainer>
      <PageSection>
        <PageBackLabel text='Grupos de máquinas' onClick={() => history.push('/tgm/machine-set')}/>
          <Title text="Editar grupo de máquinas"/>

        <Card padding>
          <label>Nombre</label>
          <Input {...register("name")} />
          <Button style={{marginTop: '10px'}} variant={'primary'} onClick={handleSubmit(update)}>Guardar</Button>
        </Card>

        <AsyncBlock loading={loading} error={error}>
          <ReactTable
            columns={columns}
            data={data}
            customSearch
            filterAdd={() => goAdd()}
            noData='No existen resultados para el filtro seleccionado'
            pageSize={20}
          />
        </AsyncBlock>
      </PageSection>

    </PageContainer>
  );
};

export default MachinesSetEdit;
