import React, {useEffect} from 'react';
import {Apis} from "../../../../util/Apis";
import useFetch2 from "../../../../hooks/useFetch2";
import axios from "axios";
import MidasForm from "./MidasForm";

const MidasEdit = ({id, setAction, setTitle}) => {

  const {loading, data} = useFetch2(Apis.SALAS + '/sala-hub/' + id)

  const update = async (params) => {
    params.salaId = parseInt(params.salaId)
    await axios.put(Apis.SALAS + '/sala-hub/' + id, params)
  }

  useEffect(() => {
    setTitle("Editar IP")
  }, [])

  return (
    <React.Fragment>
      {data && !loading &&
        <MidasForm value={data} onSubmit={update} setAction={setAction}/>
      }
    </React.Fragment>
  );
};

export default MidasEdit;
