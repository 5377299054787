import Modal from "react-bootstrap/Modal";
import React, {useState} from "react";
import Button from "../components/forms/Button";

const ModalInfo = ({body, size="xl", onHide, ...props}) => {

  const [title, setTitle] = useState('')
  const [xlShow] = useState(false);

  return (
    <Modal size={size}
           show={xlShow} onHide={onHide} scrollable {...props}>
      <Modal.Header closeButton>
        <Modal.Title style={{fontSize:'20px'}}>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {
          body &&
          <div style={{display: 'block', flexDirection: 'row'}}>
            {React.cloneElement(body, {setTitle})}
          </div>
        }
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={onHide}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalInfo;
