import React, {useEffect} from "react";
import {Apis} from "../../../../util/Apis";
import AlmacenForm from "./AlmacenForm";
import axios from "axios";


const AlmacenNew = ({setAction, setTitle}) => {

  const onCreate = (params) => {
    if(params.eventId !== 0) return axios.post(Apis.TGM + '/machine-warehouse', params)

  }

  useEffect(() => {
    setTitle("Nuevo almacen")
  })

  return (
    <AlmacenForm value={{}} onSubmit={onCreate} setAction={setAction}/>
  )
}

export default AlmacenNew
