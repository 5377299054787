import React, {useCallback, useMemo, useState} from 'react';
import {getDateFilter} from "../../../../components/util/CasinoDates";
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import {numberFormat} from "../../../../components/util/NumberUtil";
import MoneyColored from "../../../../components/util/MoneyColored";
import {PageSection, Title} from "@wargostec/react-components";
import PageContainerFull from "../../../../components/layout/PageContainerFull";
import ReactTable from "../../../../components/util/ReactTable";
import AsyncBlock from "../../../../components/forms/AsyncBlock";
import {PageBackLabel} from "../../../../components/layout/PageBackLabel";
import {useHistory} from "react-router";

const AuditCuadreFisicoMachine = () => {

  const history = useHistory()
  const querystring = window.location.search
  const query = new URLSearchParams(querystring)
  let machineQueryParams = query.get('machine')
  let confId = query.get('confId')
  let periodQueryParams = query.get('period')
  const date = getDateFilter('month')

  let ini = date.ini
  let fin = date.fin

  if (periodQueryParams) {
    const date = new Date(periodQueryParams);
    const year = date.getFullYear();
    const month = date.getMonth();
    ini = new Date(year, month, 1).toISOString().split('T')[0];
    fin = new Date(year, month + 1, 0).toISOString().split('T')[0];
  }

  const fechasDefault = {ini: ini, fin: fin}
  const [fechas, setFechas] = useState(fechasDefault)
  let defaultParams = {enabled: true, ini: fechas.ini, fin: fechas.fin, confId: confId}

  const [params, setParams] = useState(defaultParams)
  const {data, fetchData, loading, error} = useFetch2(Apis.CAJA + `/report-bill/by-conf`, [], params);

  const text = machineQueryParams ? `Cuadre físico - MAQ ${machineQueryParams}` : 'Cuadre físico por máquina'

  const numberValue = useCallback(props => <>{numberFormat(props.renderValue())}</>, []);
  const coloresMoneyValue = useCallback(props => <MoneyColored
    currencySymbol={''}>{props.renderValue()}</MoneyColored>, []);
  const coloresNumberValue = useCallback(props => <MoneyColored digits={0}
                                                                currencySymbol={''}>{props.renderValue()}</MoneyColored>, []);

  const columns = useMemo(
    () => [
      {
        header: 'Periodo',
        accessorKey: 'period',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: "Billetes",
        meta: {
          headerClassName: 'text-center border-l',
        },
        columns: [
          {
            header: 'Cont',
            accessorKey: 'bill',
            meta: {
              headerClassName: 'text-right border-l',
              columnClassName: 'text-right border-l'
            },
            cell: numberValue
          },
          {
            header: 'Recaudo',
            accessorKey: 'billAudit',
            meta: {
              headerClassName: 'text-right',
              columnClassName: 'text-right'
            },
            cell: numberValue
          },
          {
            header: 'Dif',
            accessorKey: 'diffBill',
            meta: {
              headerClassName: 'text-right',
              columnClassName: 'text-right'
            },
            cell: coloresMoneyValue
          }
        ],
      },
      {
        header: "TicketIn",
        meta: {
          headerClassName: 'text-center border-l',
        },
        columns: [
          {
            header: 'Cont',
            accessorKey: 'ticketIn',
            meta: {
              headerClassName: 'text-right border-l',
              columnClassName: 'text-right border-l'
            },
            cell: numberValue
          },
          {
            header: 'Recaudo',
            accessorKey: 'pticketIn',
            meta: {
              headerClassName: 'text-right',
              columnClassName: 'text-right'
            },
            cell: numberValue
          },
          {
            header: 'Dif',
            accessorKey: 'diffTicketIn',
            meta: {
              headerClassName: 'text-right',
              columnClassName: 'text-right'
            },
            cell: coloresMoneyValue
          }
        ],
      },
      {
        header: "TicketOut",
        meta: {
          headerClassName: 'text-center border-l',
        },
        columns: [
          {
            header: 'Cont',
            accessorKey: 'ticketOut',
            meta: {
              headerClassName: 'text-right border-l',
              columnClassName: 'text-right border-l'
            },
            cell: numberValue
          },
          {
            header: 'Recaudo',
            accessorKey: 'pticketOut',
            meta: {
              headerClassName: 'text-right',
              columnClassName: 'text-right'
            },
            cell: numberValue
          },
          {
            header: 'Dif',
            accessorKey: 'diffTicketOut',
            meta: {
              headerClassName: 'text-right',
              columnClassName: 'text-right'
            },
            cell: coloresMoneyValue
          }
        ],
      },
      {
        header: "Pagos Manuales",
        meta: {
          headerClassName: 'text-center border-l',
        },
        columns: [
          {
            header: 'JP',
            accessorKey: 'jp',
            meta: {
              headerClassName: 'text-right border-l',
              columnClassName: 'text-right border-l'
            },
            cell: numberValue
          },
          {
            header: 'CC',
            accessorKey: 'cc',
            meta: {
              headerClassName: 'text-right',
              columnClassName: 'text-right'
            },
            cell: numberValue
          },
          {
            header: 'Fisico',
            accessorKey: 'hp',
            meta: {
              headerClassName: 'text-right',
              columnClassName: 'text-right'
            },
            cell: numberValue
          },
          {
            header: 'Dif',
            accessorKey: 'diffHandPay',
            meta: {
              headerClassName: 'text-right',
              columnClassName: 'text-right'
            },
            cell: coloresMoneyValue
          }
        ],
      },
      {
        header: "TicketIn Cantidad",
        meta: {
          headerClassName: 'text-center border-l',
        },
        columns: [
          {
            header: 'Contadores',
            accessorKey: 'tiq',
            meta: {
              headerClassName: 'text-right border-l',
              columnClassName: 'text-right border-l'
            },
          },
          {
            header: 'Recaudo',
            accessorKey: 'ptiq',
            meta: {
              headerClassName: 'text-right',
              columnClassName: 'text-right'
            },
          },
          {
            header: 'Dif',
            accessorKey: 'diffTiq',
            meta: {
              headerClassName: 'text-right',
              columnClassName: 'text-right'
            },
            cell: coloresNumberValue
          }
        ],
      },
      {
        header: "TicketOut Cantidad",
        meta: {
          headerClassName: 'text-center border-l',
        },
        columns: [
          {
            header: 'Contadores',
            accessorKey: 'toq',
            meta: {
              headerClassName: 'text-right border-l',
              columnClassName: 'text-right border-l'
            },
          },
          {
            header: 'Recaudo',
            accessorKey: 'ptoq',
            meta: {
              headerClassName: 'text-right',
              columnClassName: 'text-right'
            },
          },
          {
            header: 'Dif',
            accessorKey: 'diffToq',
            meta: {
              headerClassName: 'text-right',
              columnClassName: 'text-right'
            },
            cell: coloresNumberValue
          }
        ],
      },
      {
        header: "Fichas",
        meta: {
          headerClassName: 'text-center border-l',
        },
        columns: [
          {
            header: 'Contadores',
            accessorKey: 'coin',
            meta: {
              headerClassName: 'text-right border-l',
              columnClassName: 'text-right border-l'
            },
            cell: numberValue
          },
          {
            header: 'Recaudo',
            accessorKey: 'coinAudit',
            meta: {
              headerClassName: 'text-right',
              columnClassName: 'text-right'
            },
            cell: numberValue
          },
          {
            header: 'PFichas',
            accessorKey: 'preCoin',
            meta: {
              headerClassName: 'text-right',
              columnClassName: 'text-right'
            },
            cell: numberValue
          },
          {
            header: 'Dif',
            accessorKey: 'diffCoin',
            meta: {
              headerClassName: 'text-right',
              columnClassName: 'text-right'
            },
            cell: coloresMoneyValue
          }
        ],
      },
    ]
    , []
  )


  return (
    <PageContainerFull>
      <PageSection>
        <PageBackLabel text='Regresar' onClick={() => history.goBack()}/>
        <Title text={text} type={'page'}/>

        <AsyncBlock loading={loading} error={error}>
          <ReactTable
            columns={columns}
            data={(data && data) || []}
            noData='No existen resultados para el filtro seleccionado'
            pageSize={150}
            customSearch
          />
        </AsyncBlock>

      </PageSection>
    </PageContainerFull>
  );
};

export default AuditCuadreFisicoMachine;
