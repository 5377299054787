import React, {useContext, useEffect, useState} from 'react';
import Slidebar from "../../../common/layout/Slidebar";
import SalaIdInput from "../../../common/components/SalaIdInput";
import FormGroup, {FormBlock} from "../../../../components/forms/FormGroup";
import {ButtonBox} from "../../../../components/forms/Button";
import PageSection from "../../../../components/layout/PageSection";
import {Title, Button} from "@wargostec/react-components";
import {BsFilter} from "react-icons/bs";
import Loader1 from "../../../common/components/Loader1";
import NoDataTable from "../../../common/components/NoDataTable";
import PageContainer from "../../../../components/layout/PageContainer";
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import {SiteContext} from "../../../common/context/SiteContext";
import {Controller, useForm} from "react-hook-form";
import ManufacterSelect from "../../../common/components/selects/ManufacterSelect";
import Table from "react-bootstrap/Table";
import MoneyColoredRound from "../../../../components/util/MoneyColored";
import {DatePickerCustom} from "../../../../components/forms/FormEl";
import MachineSearchInput from "../../../common/components/MachineSearchInput";

const ProduccionPorJuego = () => {

  const {salaId} = useContext(SiteContext)
  const [slideOpen, setSlideOpen] = useState(false)

  const defaultParams = {salaId: salaId}
  const [params, setParams] = useState(defaultParams)

  const {
    loading,
    data,
    fetchData
  } = useFetch2(Apis.STATISTIC + `/meter-online-per-game`, [], params, false);

  const {data: dataSala, loading: loadingDataSala} = useFetch2(Apis.SALAS + '/salas-config/' + salaId, [])

  const {
    register,
    handleSubmit,
    control
  } = useForm({defaultValues: defaultParams});

  const getData = (params) => {
    setParams(params)
  }

  useEffect(() => {
    if (params) fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  return (
    <PageContainer>

      <Slidebar visible={slideOpen} onHide={() => setSlideOpen(false)}>
        <div style={{padding: '16px'}}>
          <SalaIdInput {...register("salaId")}/>

          <FormGroup>
            <label>Fabricante</label>
            <ManufacterSelect {...register("manufacturerId")}/>
          </FormGroup>
          <FormGroup>
            <label>Máquina</label>
            <Controller name="confId" control={control} render={({field}) => <MachineSearchInput {...field} />}/>
          </FormGroup>
          <FormGroup>
            <label>Desde</label>
            <DatePickerCustom {...register("ini")}/>
          </FormGroup>

          <FormGroup>
            <label>Hasta</label>
            <DatePickerCustom {...register("fin")}/>
          </FormGroup>

          <Button variant={'primary'} text='Buscar' onClick={handleSubmit(getData)}/>
        </div>
      </Slidebar>

      <PageSection>
        <Title text="Producción por juego" type={'page'}/>

        <ButtonBox style={{marginBottom: "20px"}}>
          <Button variant="primary" onClick={() => setSlideOpen(!slideOpen)}
                  icon={<BsFilter />} text="Filtrar"/>
        </ButtonBox>

      {
        (
          loading && loadingDataSala ? <Loader1/> :
            (
              data && data.length === 0 ?
                <FormBlock>
                    <NoDataTable text={params.confId ? "No hay datos" : "Filtrar por fabricante"}/>
                </FormBlock>
                :
                <FormBlock>
                    <Table striped hover>
                      <thead>
                      <tr>
                        <th>Juego</th>
                        <th>Cantidad</th>
                        <th>Producción</th>
                        <th></th>
                      </tr>
                      </thead>
                      <tbody>
                      {data && dataSala && data.sort((a, b) => b.totalCi - a.totalCi).map((item, index) =>
                        <tr key={item.gameName}>
                          <td>{item.gameName}</td>
                          <td>{item.quantity}</td>
                          <td><MoneyColoredRound currencySymbol={dataSala.currencySymbol}>{item.totalCi * 0.01}</MoneyColoredRound>
                          </td>
                        </tr>
                      )}
                      </tbody>
                    </Table>
                </FormBlock>
            )
        )
      }
      </PageSection>
    </PageContainer>
  );
};

export default ProduccionPorJuego;
