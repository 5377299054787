import React, {useContext, useEffect} from 'react'
import Form from "react-bootstrap/Form";
import FormGroup from "../../../../components/forms/FormGroup";
import {SiteContext} from "../../../common/context/SiteContext";
import {useForm} from "react-hook-form";
import PrinterSelect from "../../../common/components/selects/PrinterSelect";

const SorteoPrintForm = ({value, onSubmit, setAction}) => {

  const {salaId} = useContext(SiteContext)

  let defaultParams = {salaId: salaId, enabled: true, anforaId: 0}
  const {
    register,
    handleSubmit,
    reset,
  } = useForm({defaultValues: defaultParams});

  useEffect(() => {
    if (value) {
      reset(value)
    }
  }, [value])

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit))
  }, [])

  return (
    <Form>
      <FormGroup>
        <label>Impresora</label>
        <PrinterSelect {...register("printerId")}/>
      </FormGroup>
    </Form>
  )
}

export default SorteoPrintForm
