import {FaChevronLeft} from "react-icons/fa";
import React from "react";
import styled from "styled-components";

const PageBackLabelStyled = styled.span`
  cursor : pointer;
  font-weight: normal;
  color : rgb(99, 115, 129);
  padding: 0.5rem 0 1rem;
  display: flex;
  align-items: center;
  user-select: none;
  gap:4px;
  
  &:hover{
    color: #212b36;
    text-decoration: none;
  }
`

export const PageBackLabel = ({text, onClick}) => {
  return (
    <PageBackLabelStyled onClick={onClick}>
      <FaChevronLeft/>
      <span>{text}</span>
    </PageBackLabelStyled>
  )
}
