import React, {useState} from 'react';
import PageContainer from "../../../../components/layout/PageContainer";
import {PageBackLabel} from "../../../../components/layout/PageBackLabel";
import {Title} from "@wargostec/react-components";
import PageSection from "../../../../components/layout/PageSection";
import {useHistory, useParams} from "react-router";
import {Apis} from "../../../../util/Apis";
import useFetch2 from "../../../../hooks/useFetch2";
import {LoadingTableV2} from "../../../../components/forms/Tables";
import Table from "react-bootstrap/Table";
import Card from "../../../../components/forms/Card";
import Moment from "react-moment";
import useDeleteModal from "../../../../modals/useDeleteModal";
import axios from "axios";
import Button, {ButtonBox} from "../../../../components/forms/Button";
import DeleteGenericModal from "../../../../modals/DeleteGenericModal";
import useEditModal from "../../../../modals/useEditModal";
import GenericModal2 from "../../../../modals/GenericModal2";
import PrintJobContent from "./PrintJobContent";

const PrinterJobList = () => {

  const {id} = useParams()
  let history = useHistory()
  let defaultParams = {printerId: id}
  const [params, setParams] = useState(defaultParams)

  const {data, loading,fetchData} = useFetch2(Apis.PRINT + '/printjobs', [], params)

  const getPrinters = (state) => {
    switch (state) {
      case 'DONE':
        return 'Impresos';
      case 'ERROR':
        return 'Error';
      case 'NEW_JOB':
        return 'Nueva tarea';
      case 'SENT_TO_CLIENT':
        return 'Enviado a impresora';
      case 'QUEUED':
        return 'En cola';
      case 'CANCELLED':
        return 'Cancelado';
      default:
        return '';
    }
  }

  const {
    wrapper: wrapper1,
    editAction
  } = useEditModal({
    editComponent: <PrintJobContent/>,
    onRefresh: fetchData
  });

  const deletePrintJob = (id) => axios.post(Apis.PRINT + '/printjobs/' + id + '/cancel');

  const {wrapper: wrapper2, openDeleteModal} = useDeleteModal({
    text: '¿Desea cancelar esta impresión?',
    onRefresh: fetchData,
    action: deletePrintJob
  });

  return (
    <PageContainer>
      <PageSection>
        <PageBackLabel text='Regresar' onClick={() => history.goBack()}/>
          <Title text="Impresiones"/>
      </PageSection>

      <PageSection>
        <Card>
          <LoadingTableV2 loading={loading} data={data.printJobs}
                          noDataText={'No hay impresiones por el momento'}>
            <Table striped hover responsive={true} size={'sm'}>
              <thead>
              <tr>
                <th className={'text-center'}>Cantidad</th>
                <th className={'text-center'}>Estado</th>
                <th className={'text-center'}>Error</th>
                <th className={'text-center'}>Contenido</th>
                <th className={'text-center'}>Fecha</th>
                <th/>
              </tr>
              </thead>
              <tbody>
              {data.printJobs && data.printJobs.map(item =>
                <tr key={item.id}>
                  <td className="text-center">{item.title}</td>
                  <td className="text-center">{getPrinters(item.state)}</td>
                    <td className="text-center">{item.message ? item.message :'--'}</td>
                  <td className="text-center">
                    <Button size='xs'
                            onClick={() => editAction(item.id)}
                            title={"Ver contenido"}
                    />
                  </td>
                  <td className="text-center"><Moment format={'HH:mm DD/MM/YYYY'}>{item.createdAt}</Moment></td>
                  <td>
                    <ButtonBox>
                      {(item.state !== "DONE" && item.state !=='CANCELLED') && (
                          <Button size='xs' variant={'danger'} onClick={() => openDeleteModal(item.id)}>
                            Eliminar
                          </Button>
                      )}
                    </ButtonBox>
                  </td>
                </tr>
              )}
              </tbody>
            </Table>
          </LoadingTableV2>
        </Card>
      </PageSection>

      <GenericModal2 {...wrapper1} />
      <DeleteGenericModal {...wrapper2} />
    </PageContainer>
  );
};

export default PrinterJobList;
