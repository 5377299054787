import React, {useCallback, useEffect, useMemo} from 'react'
import PageSection from "../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import {Apis} from "../../../../util/Apis";
import PageContainerFull from "../../../../components/layout/PageContainerFull";
import Button from "../../../../components/forms/Button";
import Moment from "react-moment";
import useFetch2 from "../../../../hooks/useFetch2";
import {useHistory} from "react-router";
import {Tab, Tabs} from '../../../../components/tabs/Tabs';
import CardOverview, {CardOverviewContainer} from '../../../../components/layout/CardOverview';
import {HiOutlineSlotMachine} from '../../../../components/assets/icons/slot-machine';
import ReactTable from '../../../../components/util/ReactTable';
import AsyncBlock from '../../../../components/forms/AsyncBlock';
import {FlexLayout} from '../../../../components/flex/Flex';
import {HiOutlineSignal} from 'react-icons/hi2';
import MeterOnlineLayout from './MeterOnlineLayout';
import SegmentCircleV2 from "../../../crm/pages/clientes/customers/SegmentCircleV2";
import GameList from "./GamesList";
import useModal from "../../../../modals/useModal";
import ModalInfo from "../../../../modals/ModalInfo";
import {CiWarning} from "react-icons/ci";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import ModalEvent from "./ModalEvent";
import ModalEventBill from "./ModalEventBill";
import {getGenericLink} from "../../../../util";

const MeterOnline = () => {

  const {loading, data, fetchData, error} = useFetch2(Apis.TGM + `/machines/meter-online`, []);

  const history = useHistory()
  const goToEdit = (id) => {
    history.push('/tgm/machines/edit/' + id)
  }
  const goToOverView = (id) => {
    history.push('/tgm/machine-overview/' + id)
  }

  const {
    wrapper: wrapper1,
    someAction: modalGames
  } = useModal({
    component: <GameList/>,
  });

  const {
    wrapper: wrapper2,
    someAction: modalEvents,
  } = useModal({
    component: <ModalEvent/>,
  });

  const {
    wrapper: wrapper3,
    someAction: modalEventsBill,
  } = useModal({
    component: <ModalEventBill/>,
  });

  useEffect(() => {
    let intervalId = setInterval(fetchData, 30000);
    return () => {
      clearInterval(intervalId);
    };
  }, [fetchData]);

  const dateValue = props => <Moment fromNow>{props.renderValue()}</Moment>;

  const serverLinkValue = (props) => {
    const link = props.row.original.serverLink;
    return (
      <span>
        <FlexLayout justifyContent="center" gap="16px">
          <SegmentCircleV2 color={getGenericLink(link)}/>
        </FlexLayout>
      </span>
    );
  };

  const machineLinkValue = (props) => {
    const link = props.row.original.machineLink;
    return (
      <span>
        <FlexLayout justifyContent="center" gap="16px">
          <SegmentCircleV2 color={getGenericLink(link)}/>
        </FlexLayout>
      </span>
    );
  };

  const cardReaderLink = (props) => {
    const link = props.row.original.cardReaderLink;
    return (
      <span>
        <FlexLayout justifyContent="center" gap="16px">
          <SegmentCircleV2 color={getGenericLink(link)}/>
        </FlexLayout>
      </span>
    );
  };

  const machineValue = (props) => {
    const machineName = props.row.original.machine;
    const breakdown = props.row.original.breakdown;

    return (
      <>
        <FlexLayout justifyContent="center" alignItems="center" gap="16px">
          <span>{machineName}</span>
          <span>
            {
              breakdown &&
              <OverlayTrigger
                placement={'top'}
                overlay={
                  <Tooltip>
                    <span style={{fontSize: '12px'}}>Maquina averiada</span>
                  </Tooltip>
                }
              >
                <div>
                  <CiWarning style={{color: "red"}}/>
                </div>
              </OverlayTrigger>
            }
          </span>
        </FlexLayout>
      </>
    );
  }

  const actionsValue = useCallback(props => <>{
    <FlexLayout justifyContent='center'>
      <Button variant='light' size='xs'
              onClick={() => modalGames({
                values: {
                  confId: props.row.original.confId,
                  machine: props.row.original.machine
                }
              })}>Juegos
      </Button>
      <Button variant='light' size='xs'
              onClick={() => modalEvents({
                values: {
                  confId: props.row.original.confId
                }
              })}>Eventos
      </Button>
      <Button variant='light' size='xs'
              onClick={() => modalEventsBill({
                values: {
                  confId: props.row.original.confId,
                  size: 30,
                  machine: props.row.original.machine,
                }
              })}>Billetes
      </Button>
      <Button size='xs' onClick={() => goToEdit(props.row.original.machineId)}>Editar</Button>
      <Button variant='dark' size='xs' onClick={() => goToOverView(props.row.original.confId)}>Detalles</Button>
    </FlexLayout>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }</>, []);

  const columns = useMemo(
    () => [
      {
        header: 'Máquina',
        accessorKey: 'machine',
        meta: {
          headerClassName: 'text-center font-small',
          columnClassName: 'text-center font-small',
        },
        cell: machineValue
      },
      {
        header: 'Fab. y modelo',
        accessorKey: 'groupName',
        meta: {
          headerClassName: 'font-small',
          columnClassName: 'font-small',
        },
      },
      {
        header: 'Server',
        accessorKey: 'serverLink',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        enableSorting: true,
        cell: serverLinkValue
      },
      {
        header: 'Maquina',
        accessorKey: 'machineLink',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        enableSorting: true,
        cell: machineLinkValue
      },
      {
        header: 'SN',
        accessorKey: 'blueSn',
        meta: {
          headerClassName: 'text-center font-small',
          columnClassName: 'text-center font-small',
        },
      },
      {
        header: 'RFID',
        accessorKey: 'cardReaderLink',
        meta: {
          headerClassName: 'text-center font-small',
          columnClassName: 'text-center font-small',
        },
        cell: cardReaderLink
      },
      {
        header: 'Créditos',
        accessorKey: 'currentCredits',
        meta: {
          headerClassName: 'text-right font-small',
          columnClassName: 'text-right font-small',
        },
      },
      {
        header: 'Coin In',
        accessorKey: 'ci',
        meta: {
          headerClassName: 'text-right font-small',
          columnClassName: 'text-right font-small',
        },
      },
      {
        header: 'Coin out',
        accessorKey: 'co',
        meta: {
          headerClassName: 'text-right font-small',
          columnClassName: 'text-right font-small',
        },
      },
      {
        header: 'Jackpot',
        accessorKey: 'jp',
        meta: {
          headerClassName: 'text-right font-small',
          columnClassName: 'text-right font-small',
        },
      },
      {
        header: 'Cancel Credit',
        accessorKey: 'cc',
        meta: {
          headerClassName: 'text-right font-small',
          columnClassName: 'text-right font-small',
        },
      },
      {
        header: 'Billetes',
        accessorKey: 'bi',
        meta: {
          headerClassName: 'text-right font-small',
          columnClassName: 'text-right font-small',
        },
      },
      {
        header: 'Ticket In',
        accessorKey: 'ticketIn',
        meta: {
          headerClassName: 'text-right font-small',
          columnClassName: 'text-right font-small',
        },
      },
      {
        header: 'Ticket Out',
        accessorKey: 'ticketOut',
        meta: {
          headerClassName: 'text-right font-small',
          columnClassName: 'text-right font-small',
        },
      },
      {
        header: 'Jugadas',
        accessorKey: 'gp',
        meta: {
          headerClassName: 'text-right font-small',
          columnClassName: 'text-right font-small',
        },
      },
      {
        header: 'Ultima act.',
        accessorKey: 'updatedAt',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right font-small',
        },
        cell: dateValue
      },
      {
        header: 'Acciones',
        accessorKey: 'machineId',
        enableSorting: false,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: actionsValue
      },
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , []
  )

  return (
    <PageContainerFull>
      <PageSection>
        <Title text="Contadores en línea" type={'page'}/>
        <Tabs>
          <Tab title='Vista tabla'>
            <AsyncBlock loading={loading} error={error} showLoader={false}>
              <CardOverviewContainer gridColumns='repeat(4, 1fr)'>
                <CardOverview
                  title={data.total}
                  subtitle='Total'
                  icon={<HiOutlineSlotMachine size={20}/>}
                />
                <CardOverview
                  title={data.serverLink}
                  subtitle='Conex con servidor'
                  icon={<HiOutlineSignal size={20}/>}
                />
                <CardOverview
                  title={data.machineLink}
                  subtitle='Conex con maquina'
                  icon={<HiOutlineSignal size={20}/>}
                />
                <CardOverview
                  title={data.cardReaderLink}
                  subtitle='Tarjetero'
                  icon={<HiOutlineSignal size={20}/>}
                />
              </CardOverviewContainer>

              <ReactTable
                columns={columns}
                filterRefresh={() => fetchData()}
                data={data.data || []}
                customSearch
                noData='No hay contadores registrados'
                pageSize={500}
                sortArray={[{id: 'machine', asc: false}]}
              />
            </AsyncBlock>
          </Tab>

          <Tab title='Vista layout'>
            <MeterOnlineLayout/>
          </Tab>
        </Tabs>
      </PageSection>
      <ModalInfo {...wrapper1} size='lg'/>
      <ModalInfo {...wrapper2} size='lg'/>
      <ModalInfo {...wrapper3} size='lg'/>
    </PageContainerFull>
  )
}

export default MeterOnline;
