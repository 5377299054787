import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import { Title } from "@wargostec/react-components";
import { useHistory } from "react-router";
import { useForm } from "react-hook-form";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { BsFillInfoCircleFill } from "react-icons/bs";
import PageSection from "../../../../components/layout/PageSection";
import { SiteContext } from "../../../common/context/SiteContext";
import { Apis } from "../../../../util/Apis";
import Card from "../../../../components/forms/Card";
import DateFilter from "../../../../components/datefilter/DateFilter";
import MoneyColoredRound from "../../../../components/util/MoneyColored";
import { getCasinoDay } from "../../../../components/util/CasinoDates";
import PageContainerFull from "../../../../components/layout/PageContainerFull";
import { FlexLayout } from "../../../../components/flex/Flex";
import SegmentCircle from "./customers/SegmentCircle";
import AsyncBlock from "../../../../components/forms/AsyncBlock";
import ReactTable from "../../../../components/util/ReactTable";
import { NumberRound } from "../../../../components/util/NumberUtil";
import FormGroup from "../../../../components/forms/FormGroup";
import { DatePickerCustom } from "../../../../components/forms/FormEl";
import Slidebar from "../../../common/layout/Slidebar";
import SalaIdInput from "../../../common/components/SalaIdInput";
import useToggle from "../../../../hooks/useToggle";
import Button from "../../../../components/forms/Button";
import {getBetType, serializeDownload} from "../../../../util";
import useGet from "../../../../hooks/useGet";
import useFetch2 from "../../../../hooks/useFetch2";

const CustomerRanking = () => {
  const { salaId } = useContext(SiteContext)
  const history = useHistory()

  const casinoDay = getCasinoDay()

  const fechasDefault = { ini: casinoDay, fin: casinoDay }
  const [fechas, setFechas] = useState(fechasDefault)

  let defaultParams = { salaId: salaId, enabled: true, ini: fechas.ini, fin: fechas.fin }
  const [params, setParams] = useState(defaultParams)

  const { isOpen: slideOpen, toggler } = useToggle({});

  const { register, handleSubmit } = useForm({ defaultValues: defaultParams });

  const { loading, data, fetchData, error } = useFetch2(
    Apis.PC + '/customers/trending',
    [],
    params
  )
  const { data: levels } = useGet({
    url: Apis.PC + `/customer-segments`,
    initialValue: [],
  })

  const { data: dataSala } = useGet({
    url: Apis.SALAS + '/salas-config/' + salaId,
    initialValue: [],
  })

  const download = () => {
    const searchParams = new URLSearchParams();
    Object.keys(params).forEach(key => searchParams.append(key, params[key]));

    const link = Apis.PC + `/customers/trending.xlsx?${serializeDownload(params)}`;
    window.location.href = link;
  }

  const getData = (params) => {
    setParams(params)
  }

  const handleChangeDate = (fechas) => {
    setFechas(fechas)
    let newParams = { ...params, ...fechas }
    if (newParams && newParams.ini && newParams.fin) fetchData(params)
    setParams({ ...newParams })
  }

  useEffect(() => {
    setParams({...params, ...fechas})
  }, [fechas])

  useEffect(() => {
    if (params) fetchData()
  }, [params])

  const defaultValue = useCallback(props => NumberRound({ children: props.renderValue() }), []);
  const NumberComponent = useCallback(props => <MoneyColoredRound dataSala={dataSala}>{props.renderValue()}</MoneyColoredRound>, [dataSala]);
  const levelValue = useCallback(props => <>{
    <FlexLayout alignItems='center' justifyContent='flex-end' gap='4px'>
      <span>{props.renderValue() === null ? '-' : props.renderValue()}</span>
      <SegmentCircle levelId={props.row.original.levelId} array={levels} />
    </FlexLayout>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }</>, []);

  const holdValue = useCallback(props => <>{
    <OverlayTrigger
      placement={'top'}
      overlay={
        <Tooltip>
          {props.renderValue() > 0 ?
            <span>
              {'La sala obtuvo una ganancia de ' +
                Number(props.renderValue() * 100).toFixed(1) + ' %'}
            </span>
            :
            <span>
              {'El cliente a ganado un '
                + Math.abs(props.renderValue() * 100).toFixed(1) +
                '% de su total apostado'}
            </span>
          }
        </Tooltip>
      }
    >
      <FlexLayout alignItems='center' justifyContent='flex-end' gap='4px'>
        <span>{props.renderValue() === 'NaN' ? (0 + ' %') : (Number(props.renderValue() * 100).toFixed(1) + ' %')}</span>
        <BsFillInfoCircleFill size={12} />
      </FlexLayout>
    </OverlayTrigger>
  }</>, []);

  const apPromValue = useCallback(props => <>{
    <FlexLayout alignItems='center' justifyContent='flex-end' gap='4px'>
      <span>{NumberRound({ children: props.renderValue() })}</span>
      {getBetType({ valor: NumberRound({ children: props.renderValue() }) })}
    </FlexLayout>
  }</>, []);

  const actionsValue = useCallback(props => <>{
    <FlexLayout justifyContent='center'>
      <Button variant={'light'} size='xs'
        onClick={() => history.push('/crm/customer-info/' + props.renderValue())}>
        Detalles
      </Button>
    </FlexLayout>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }</>, []);

  const columns = useMemo(
    () => [
      {
        header: 'Posición',
        accessorKey: 'rank',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Cliente',
        accessorKey: 'customerName'
      },
      {
        header: 'Nivel',
        accessorKey: 'levelName',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
        cell: levelValue
      },
      {
        header: 'Visitas',
        accessorKey: 'visits',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Coin In',
        accessorKey: 'ciUnif',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
        cell: defaultValue
      },
      {
        header:
          <OverlayTrigger
            placement={'top'}
            overlay={<Tooltip>Producción respecto a la sala</Tooltip>}
          >
            <FlexLayout alignItems='center' justifyContent='flex-end' gap='4px' style={{ display: 'inline-flex' }}>
              <span>Net Win</span>
              <BsFillInfoCircleFill size={12} />
            </FlexLayout>
          </OverlayTrigger>,
        accessorKey: 'netwin',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
        cell: NumberComponent
      },
      {
        header: 'Hold',
        accessorKey: 'hold',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
        cell: holdValue
      },
      {
        header: 'Ap.Prom.',
        accessorKey: 'apProm',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
        cell: apPromValue
      },
      {
        header: 'Acciones',
        accessorKey: 'customerId',
        enableGlobalFilter: false, // para deshabilitar el filter
        enableSorting: false, // para deshabilitar el sort
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: actionsValue
      },
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [dataSala]
  )

  return (
    <PageContainerFull>
      <Slidebar visible={slideOpen} onHide={toggler}>
        <div style={{ padding: '16px' }}>
          <SalaIdInput {...register("salaId")} />
          <FormGroup>
            <label>Desde</label>
            <DatePickerCustom {...register("ini")} />
          </FormGroup>

          <FormGroup>
            <label>Hasta</label>
            <DatePickerCustom {...register("fin")} />
          </FormGroup>

          <Button variant={'primary'} title='Buscar' onClick={handleSubmit(getData)} />
        </div>
      </Slidebar>
      <PageSection>
        <Title text="Ranking de clientes" type={'page'} />
        <Card padding style={{ background: '#0A0A0A' }}>
          <DateFilter fechas={fechas} setFechas={setFechas} />
        </Card>

        <AsyncBlock loading={loading} error={error}>
          <ReactTable
            columns={columns}
            filterOnclick={toggler}
            data={data || []}
            filterDownload={download}
            noData='No existen resultados para el filtro seleccionado'
            pageSize={20}
          />
        </AsyncBlock>

      </PageSection>
    </PageContainerFull>
  )
}

export default CustomerRanking;
