import React, {useEffect} from 'react';
import axios from "axios";
import {Apis} from "../../../../../util/Apis";
import SalesProfitForm from "./SalesProfitForm";

const SalesProfitNew = ({setAction, setTitle}) => {

  const onCreate = (params) => {
    return axios.post(Apis.BINGO + '/range-sales-profit', params)
  }

  useEffect(() => {
    setTitle("Nuevo rango de ganancia")
  })

  return (
    <SalesProfitForm value={{}} onSubmit={onCreate} setAction={setAction}/>
  );
};

export default SalesProfitNew;
