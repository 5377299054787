import React, {useEffect} from 'react'
import {SelectV2} from "../../../../components/forms/FormEl";
import {Apis} from "../../../../util/Apis";
import useFetch2 from "../../../../hooks/useFetch2";

const SalaSelect = React.forwardRef((props, ref) => {

  const {data} = useFetch2(Apis.USER + '/users/me')

  useEffect(() => {
    if (data) {
      data.salas.push({id: 0, name: "TODOS"})
    }
  }, [data])

  return (
    <>
      {
        (data !== undefined && data.salas !== undefined) ?
          <SelectV2 ref={ref} options={data.salas} {...props}/>
          :
          <SelectV2 disabled options={[{id: 0, name: 'Cargando...'}]}/>
      }
    </>
  )
})

export default SalaSelect;
