import React, {useMemo, useState} from 'react'
import {SelectV2} from "../../../../components/forms/FormEl";
import {Apis} from "../../../../util/Apis";
import useFetch2 from "../../../../hooks/useFetch2";

const AlmacenSelect = React.forwardRef((props, ref) => {

  const {data, loading} = useFetch2(Apis.TGM + '/machine-warehouse',[])
  const [dataAlmacenes,setDataAlmacenes] = useState(null)

  useMemo(() => {
    if (data && data.length > 0) {
      setDataAlmacenes([{id: 0, name: ""},...data])
    }
  }, [data])

  return (
    <>
      {
        (!loading) ?
          <SelectV2 ref={ref} options={dataAlmacenes} {...props}/>
          :
          <SelectV2 disabled options={[{id: 0, name: 'Cargando...'}]}/>
      }
    </>
  )
})

export default AlmacenSelect;
