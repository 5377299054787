import React, {useContext, useEffect} from "react";
import {SiteContext} from "../../../common/context/SiteContext";
import {Apis} from "../../../../util/Apis";
import axios from "axios";
import RewardForm from "./RewardForm";
import useFetch2 from "../../../../hooks/useFetch2";

const getDateFormat = (data) => {
  data.fechaInicio = data.inicioVigencia?.split('T')[0]
  data.horaInicio = data.inicioVigencia?.split('T')[1]
  data.fechaFin = data.finVigencia?.split('T')[0]
  data.horaFin = data.finVigencia?.split('T')[1]
  return data
}

const putDateFormat = (params) => {
  if (params.advanced){
    if (params.fechaInicio !== undefined && params.fechaInicio?.length > 0) {
      params.inicioVigencia = params.fechaInicio + 'T00:00:00';
    } else params.inicioVigencia = '0000-01-01T00:00:00';
    if (params.fechaFin !== undefined && params.fechaFin?.length > 0) {
      params.finVigencia = params.fechaFin + 'T00:00:00';
    } else params.finVigencia = '0000-01-01T00:00:00';
  }
}

const RewardsEdit = ({id, setAction, setTitle}) => {
  const {salaId} = useContext(SiteContext)

  const {loading, data} = useFetch2(Apis.PC + '/rewards/' + id)

  const update = async (params) => {
    params.id = id
    params.salaId = salaId
    putDateFormat(params)
    await axios.put(Apis.PC + '/rewards/' + id, params)
  }

  useEffect(() => {
    setTitle("Edición de recompensa")
  })

  return (
    <React.Fragment>
      {data && !loading &&
        <RewardForm value={data && getDateFormat(data)} onSubmit={update} setAction={setAction}/>
      }
    </React.Fragment>
  )
}
export default RewardsEdit
export {getDateFormat, putDateFormat}
