import {Apis} from "../../../../util/Apis";
import React, {useMemo} from "react";
import useFetch2 from "../../../../hooks/useFetch2";
import Moment from "react-moment";
import {Title} from "@wargostec/react-components";
import ReactTable from "../../../../components/util/ReactTable";
import AsyncBlock from "../../../../components/forms/AsyncBlock";
import {LuArrowUp01, LuCheck} from "react-icons/lu";
import CardOverview, {CardOverviewContainer} from "../../../../components/layout/CardOverview";

const GameList = ({values}) => {

  const confId = values.confId
  const {loading, data, error} = useFetch2(Apis.TGM + '/meter-online/'+confId, []);

  const dateValue = props => <Moment fromNow>{props.renderValue()}</Moment>;

  const columns = useMemo(
    () => [
      {
        header: 'Nro',
        accessorKey: 'gamePosition',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        },
      },
      {
        header: 'Juego',
        accessorKey: 'gameName',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        },
      },
      {
        header: 'CoinIn',
        accessorKey: 'ci',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
      },
      {
        header: 'CoinOut',
        accessorKey: 'co',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
      },
      {
        header: 'Jackpot',
        accessorKey: 'jp',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
      },
      {
        header: 'Jugadas',
        accessorKey: 'gp',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
      },
      {
        header: 'Última act.',
        accessorKey: 'updatedAt',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: dateValue
      }
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , []
  )

  const getActiveGameName = (games) => {
    const activeGame = games.find(game => game.activeGame);
    return activeGame ? activeGame.gameName : 'No hay juego activo';
  };

  return (
    <>
      <Title text="Juegos" type='page'/>
      <CardOverviewContainer gridColumns='repeat(2, 1fr)'>
        <CardOverview
          title={data?.length}
          subtitle='Cantidad'
          icon={<LuArrowUp01 size={20}/>}
        />
        <CardOverview
           title={getActiveGameName(data || [])}
          subtitle='Juego activo'
          icon={<LuCheck size={20}/>}
          bgcolor={'#fbbf24'}
        />
      </CardOverviewContainer>
      <AsyncBlock loading={loading} error={error}>
        <ReactTable
          columns={columns}
          data={data || []}
          customSearch
          noData='No existen juegos registrados'
          pageSize={100}
        />
      </AsyncBlock>
    </>
  )
};
export default GameList;
