import React, {useCallback, useMemo} from 'react';
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import PageSection from "../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import AsyncBlock from "../../../../components/forms/AsyncBlock";
import ReactTable from "../../../../components/util/ReactTable";
import PageContainerFull from "../../../../components/layout/PageContainerFull";

const LocationList = () => {

  const {loading, data, fetchData, error} = useFetch2(Apis.USER + '/salas', []);

  const type = useCallback(props => <>{
    props.row.original.type ? getType(props.row.original.type) : '--'
  }</>, []);

  const companyName = useCallback(props => <>{
    props.row.original.companyName ? props.row.original.companyName : '--'
  }</>, []);

  const getType = (type) => {
    switch (type) {
      case 'casino':
        return 'Casino';
      case 'office':
        return 'Oficina';
      case 'restaurant':
        return 'Restaurante';
      case 'sportbet':
        return 'Casa de apuestas';
      default:
        return 'Casino';
    }
  }

  const columns = useMemo(() => [
    {
      header: 'ID',
      accessorKey: 'id',
      meta: {
        headerClassName: 'text-center',
        columnClassName: 'text-center',
      },
    },
    {
      header: 'Nombre',
      accessorKey: 'name',
      meta: {
        headerClassName: 'text-left',
        columnClassName: 'text-left',
      },
    },
    {
      header: 'Empresa',
      accessorKey: 'companyName',
      meta: {
        headerClassName: 'text-left',
        columnClassName: 'text-left',
      },
      cell: companyName
    },
    {
      header: 'Tipo',
      accessorKey: 'type',
      meta: {
        headerClassName: 'text-left',
        columnClassName: 'text-left',
      },
      cell: type
    },
  ], [])

  return (
    <PageContainerFull>
      <PageSection>
          <Title text="Establecimientos" type={'page'}/>
        <AsyncBlock loading={loading} error={error}>
          <ReactTable
            columns={columns}
            data={data || []}
            noData='No existen establecimientos registrados'
            pageSize={15}
          />
        </AsyncBlock>
      </PageSection>

    </PageContainerFull>
  );
};

export default LocationList;
