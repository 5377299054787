import React, {useContext} from "react";
import {SiteContext} from "../context/SiteContext";

const SalaIdInput = React.forwardRef((props, ref) => {
  const {salaId} = useContext(SiteContext)

  return (
    <input name='salaId' defaultValue={salaId} ref={ref} style={{display: 'none'}}/>
  )
})

export default SalaIdInput
