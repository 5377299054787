import React from "react"
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const ConfirmModal = ({
                        show,
                        onHide,
                        action,
                        title = 'Confirmación',
                        text = '¿Esta seguro que desea realizar esta acción?',
                      }) => {
  return (

    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{text}</p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>Cancelar</Button>
        <Button variant="primary" onClick={action}>Confirmar</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ConfirmModal
