import React, {useContext} from 'react';
import {useHistory} from "react-router";
import {SiteContext} from "../../../common/context/SiteContext";
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import {PageBackLabel} from "../../../../components/layout/PageBackLabel";
import {Title} from "@wargostec/react-components";
import AsyncBlock from "../../../../components/forms/AsyncBlock";
import Card from "../../../../components/forms/Card";
import PageSection from "../../../../components/layout/PageSection";
import CardOverview, {CardOverviewContainer} from "../../../../components/layout/CardOverview";
import {BsCardChecklist, BsCashCoin} from "react-icons/bs";
import {RiFileSettingsLine} from "react-icons/ri";
import Moment from "react-moment";
import TableTransaction from "./TableTransaction";
import PageCardTitle from "../../../../components/layout/CardTitle";
import PageContainerFull from '../../../../components/layout/PageContainerFull';

const style = {
  minimumFractionDigits: 2,
  useGrouping: true
};

const MesasDetail = () => {

  const history = useHistory();
  const {salaId} = useContext(SiteContext)
  const querystring = window.location.search
  const query = new URLSearchParams(querystring)
  let snapId = query.get('snapId')

  let defaultParams = {salaId: salaId, snapId: snapId}

  const {loading, data, fetchData, error} = useFetch2(Apis.TABLES + `/snap/detail/${snapId}`, [], defaultParams);

  const numberFormatter = Intl.NumberFormat('en-US', style);

  return (
    <PageContainerFull>
      <PageSection>
        <PageBackLabel text='Sesiones' onClick={() => history.goBack()}/>

          <Title text={data.tableName + "-" + data.tableCode}/>

        <AsyncBlock loading={loading} error={error}>

          <CardOverviewContainer gridColumns='repeat(3, 1fr)'>

            <CardOverview
              title={
                <span>
                <Moment format={'DD/MM HH:mm'}>{data.openedAt}</Moment>
              </span>
              }
              subtitle='Apertura'
              icon={<BsCashCoin size={20}/>}
            />

            <CardOverview
              title={
                data.closedAt ?
                  <span>
                <Moment format={'DD/MM HH:mm'}>{data.closedAt}</Moment>
                </span>
                  :
                  '---'
              }
              subtitle='Cierre'
              icon={<RiFileSettingsLine size={20}/>}
            />

          </CardOverviewContainer>

          <PageCardTitle title={"Resumen"}/>

          <CardOverviewContainer gridColumns='repeat(5, 1fr)'>
            <CardOverview
              title={"S/. " + numberFormatter.format(data.iniAmount)}
              subtitle='Apertura'
              icon={<BsCardChecklist size={20}/>}
            />
            <CardOverview
              title={"S/. " + numberFormatter.format(data.coinInTotal)}
              subtitle='Coin In'
              icon={<BsCashCoin size={20}/>}
            />
            <CardOverview
              title={"S/. " + numberFormatter.format(data.coinOutTotal)}
              subtitle='Coin out'
              icon={<BsCashCoin size={20}/>}
            />
            <CardOverview
              title={data.finAmount !== null ? "S/. " + numberFormatter.format(data.finAmount) : '---'}
              subtitle='Cierre turno'
              icon={<RiFileSettingsLine size={20}/>}
            />
            <CardOverview
              title={data.difference ? "S/. " + numberFormatter.format(data.difference) : '----'}
              subtitle='Faltante / sobrante'
              icon={<RiFileSettingsLine size={20}/>}
            />
          </CardOverviewContainer>

          <PageCardTitle title={"Detalle - operaciones"}/>

          <Card>
            {
              data.data &&
              <TableTransaction data={data.data && data.data} loading={loading} fetchData={fetchData}/>

            }
          </Card>

        </AsyncBlock>
      </PageSection>

    </PageContainerFull>
  );
};

export default MesasDetail;
