import React, {useCallback, useContext, useMemo, useState} from 'react';
import {SiteContext} from "../../../../common/context/SiteContext";
import {FlexLayout} from "../../../../../components/flex/Flex";
import Button from "../../../../../components/forms/Button";
import useEditModal from "../../../../../modals/useEditModal";
import axios from "axios";
import {Apis} from "../../../../../util/Apis";
import {errorToast, successToast} from "../../../../../components/layout/ToastStyles";
import useFetch2 from "../../../../../hooks/useFetch2";
import useDeleteModal from "../../../../../modals/useDeleteModal";
import PageSection from "../../../../../components/layout/PageSection";
import AsyncBlock from "../../../../../components/forms/AsyncBlock";
import ReactTable from "../../../../../components/util/ReactTable";
import GenericModal2 from "../../../../../modals/GenericModal2";
import DeleteGenericModal from "../../../../../modals/DeleteGenericModal";
import PageContainer from "../../../../../components/layout/PageContainer";
import FundTypeEdit from "../fundType/FundTypeEdit";
import FundTypeNew from "../fundType/FundTypeNew";
import {Title} from "@wargostec/react-components";

const FundTypeList = () => {

  const {salaId} = useContext(SiteContext);
  const defaultParams = {salaId};
  const [params, setParams] = useState(defaultParams);

  const {loading, data, fetchData, error} = useFetch2(
    Apis.CAJA + '/fund-type',
    [],
    params
  );

  const deleteElement = (id) =>
    axios
      .delete(Apis.CAJA + '/fund-type/' + id)
      .then(() => successToast('Se anuló correctamente'))
      .catch((res) => errorToast(res.response.data.message, 3000));

  const {
    wrapper: wrapper2,
    openDeleteModal
  } = useDeleteModal({
    text: '¿Desea eliminar este usuario?',
    action: deleteElement,
    onRefresh: fetchData
  })

  const {
    wrapper: wrapper1,
    addAction,
    editAction
  } = useEditModal({
    editComponent: <FundTypeEdit/>,
    addComponent: <FundTypeNew/>,
    onRefresh: fetchData
  });

  const actionsValue = useCallback(props => <>{
    <FlexLayout justifyContent='center'>
      <Button variant={'light'} size='xs'
              onClick={() => editAction(props.renderValue())}>
        Editar
      </Button>
      <Button size='xs' variant='danger' onClick={() => openDeleteModal(props.renderValue())}>Eliminar</Button>
    </FlexLayout>
  }</>, []);

  const typeValue = useCallback(props => {
      switch (props.renderValue()) {
        case 1:
          return 'BOVEDA'
        case 2:
          return 'CAJA'
        case 3:
          return 'FONDO EXTERNO'
        default:
          return 'NO DEFINIDO'
      }
    }
    , []);

  const columns = useMemo(
    () => [
      {
        header: 'Id',
        accessorKey: 'id',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Nombre',
        accessorKey: 'name',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Tipo',
        accessorKey: 'type',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: typeValue
      },
      {
        header: 'Operaciones',
        accessorKey: 'id',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: actionsValue
      }
    ]
    , []
  )

  return (
    <PageContainer>

      <PageSection>
        <Title text='Configuración' type={"page"}/>

        <AsyncBlock loading={loading} error={error}>
          <ReactTable
            columns={columns}
            data={data || []}
            filterAdd={() => addAction()}
            noData='No existen resultados'
            pageSize={15}
          />
        </AsyncBlock>
      </PageSection>

      <GenericModal2 {...wrapper1} />
      <DeleteGenericModal {...wrapper2} />
    </PageContainer>
  );
};

export default FundTypeList;
