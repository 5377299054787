import React from 'react'
import Table from "react-bootstrap/Table";
import { useHistory, useParams } from "react-router";
import { Title } from "@wargostec/react-components";
import Moment from "react-moment";
import PageContainer from "../../../../components/layout/PageContainer";
import PageSection from "../../../../components/layout/PageSection";
import { PageBackLabel } from "../../../../components/layout/PageBackLabel";
import { FormBlock } from "../../../../components/forms/FormGroup";
import { Apis } from "../../../../util/Apis";
import useGet from '../../../../hooks/useGet';

const PointBalanceDetail = () => {
  const history = useHistory()
  const { id } = useParams()

  const { data } = useGet({
    url: Apis.PC + `/customers/${id}/transactions`,
    initialValue: [],
  })

  const sumarValores = (amount, previusBalance) => amount + previusBalance

  return (
    <PageContainer>
      <PageSection>
        <PageBackLabel text={`Balance`} onClick={() => history.goBack()} />
        <Title text="Detalle | Andres" />
      </PageSection>

      <PageSection>
        <FormBlock>
          <Table style={{ marginTop: "20px" }} striped hover>
            <thead>
              <tr>
                <th>Id</th>
                <th>Concepto</th>
                <th className='text-right'>Puntos</th>
                <th className='text-right'>Balance</th>
                <th>Fecha</th>
                <th>Hora</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) =>
                <tr key={item.hash}>
                  <td>{item.id}</td>
                  <td>{item.description}</td>
                  <td className='text-right'>{item.amount < 0 ? item.amount : `+${item.amount}`}</td>
                  <td className='text-right'>{sumarValores(item.amount, item.previousBalance)}</td>
                  <td><Moment format={'DD/MM/YYYY'}>{item.createdAt}</Moment></td>
                  <td><Moment format={'HH:mm:ss '}>{item.createdAt}</Moment></td>
                </tr>
              )}
            </tbody>
          </Table>
        </FormBlock>
      </PageSection>
    </PageContainer>
  )
}

export default PointBalanceDetail
