import React, {useEffect} from 'react';
import axios from "axios";
import {Apis} from "../../../../util/Apis";
import DealerForm from "./DealerForm";

const DealerNew = ({setAction, setTitle}) => {

  const save = async (params) => {
    await axios.post(Apis.TABLES + "/dealer", params).catch((err) => {
    });
  }

  useEffect(() => {
    setTitle("Nuevo dealer")
  }, [])

  return (
    <DealerForm value={{}} onSubmit={save} setAction={setAction}/>
  );
};

export default DealerNew;
