import React, {useEffect} from "react";

const NewLoginPage = () => {

  useEffect(() => {
    goToExternalLogin()
  })

  const goToExternalLogin = () => {
    const searchParams = new URLSearchParams(window.location.search)

    const authorizeEndpoint = process.env.REACT_APP_AUTH_SERVER + '/authorize'
    const state = randomString(20)
    let redirectTo = window.location.origin + '/callback'

    const referer1 = searchParams.get("referer")
    if (referer1 !== null && referer1 !== '') {
      redirectTo = window.location.origin + '/callback?referer=' + referer1
    }

    const params = {
      state: state,
      redirect_uri: redirectTo,
      client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
      scope: "profile email"
    }
    const paramString = new URLSearchParams(params)
    window.location.href = `${authorizeEndpoint}?${paramString.toString()}`
  }

  function randomString(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }


  return (
    <div>Cargando...</div>
  )
};

export default NewLoginPage;
