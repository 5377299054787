import {Route, Switch} from "react-router";
import React from "react";
import TgmHome from "./TgmHome";
import MachineList from "./pages/maestros/machine/MachineList";
import MeterOnline from "./pages/realtime/MeterOnline";
import BillAlert from "./pages/alertas/BillAlert";
import BillOnline from "./pages/realtime/BillOnline";
import ZoneList from "./pages/maestros/zone/ZoneList";
import MachineGroupList from "./pages/maestros/machine-group/MachineGroupList";
import ConexionGlobal from "./pages/global/ConexionGlobal";
import ProduccionGlobal from "./pages/global/ProduccionGlobal";
import Billetera from "./pages/reportes/Billetera";
import Cereos from "./pages/reportes/Cereos";
import CuadreMaquinas from "./pages/reportes/CuadreMaquinas";
import Liquidacion from "./pages/reportes/Liquidacion";
import ProduccionDiaria from "./pages/reportes/ProduccionDiaria";
import ProduccionSegunDia from "./pages/reportes/ProduccionSegunDia";
import ProduccionPorMaquina from "./pages/reportes/ProduccionPorMaquina";
import Avanzados from "./pages/analisis/Avanzados";
import ProduccionPorMes from "./pages/analisis/ProduccionPorMes";
import Promedios from "./pages/analisis/Promedios";
import ManufacturerList from "./pages/maestros/manufacturer/ManufacturerList";
import DocAtribucion from "./pages/asociados/DocAtribucion";
import OwnerList from "./pages/asociados/listaPropietarios/OwnerList";
import PeriodosDocAtribucion from "./pages/asociados/PeriodosDocAtribucion";
import RelacionAsociadosList from "./pages/asociados/relacionAsociados/RelacionAsociadosList";
import MachineEdit from "./pages/maestros/machine/MachineEdit";
import LudopatiaList from "./pages/ludopatia/LudopatiaList";
import LudopatiaNew from "./pages/ludopatia/LudopatiaNew";
import LudopatiaEdit from "./pages/ludopatia/LudopatiaEdit";
import TipoListaLudopatiaList from "./pages/ludopatia/gestionListas/TipoListaLudopatiaList";
import BuscadorLudopata from "./pages/ludopatia/buscador/BuscadorLudopata";
import HistorialDeContadores from "./pages/reportes/HistorialDeContadores";
import ProduccionTotal from "./pages/reportes/ProduccionTotal";
import MachinesSetList from "./pages/maestros/machines-set/MachinesSetList";
import MachinesSetNew from "./pages/maestros/machines-set/MachinesSetNew";
import MachinesSetEdit from "./pages/maestros/machines-set/MachinesSetEdit";
import Accountants from "./pages/reportes/Accountants";
import ProductionMachine from "./pages/maestros/machine/ProductionMachine";
import ModelAndManufacturer from "./pages/maestros/model/ModelAndManufacturer";
import ProduccionPorModelo from "./pages/reportes/ProduccionPorModelo";
import TomaDeContadores from "./pages/reportes/TomaDeContadores";
import MachineEvents from "./pages/maestros/machine/MachineEvents";
import HandpayEvents from "./pages/maestros/machine/HandpayEvents";
import TicketEmit from "./pages/maestros/machine/TicketEmit";
import TicketRedeem from "./pages/maestros/machine/TicketRedeem";
import MeterPerGame from "./pages/multijuegos/MeterPerGame";
import ProduccionPorJuego from "./pages/reportes/ProduccionPorJuego";
import Devices from "./pages/maestros/machine/Devices";
import AlmacenList from "./pages/almacenes/AlmacenList";
import LayoutConfig from "./pages/layout/LayoutConfig";
import LayoutHeatMap from "./pages/layout/LayoutHeatMap";
import AftTransfersList from "./pages/aft/AftTransfersList";
import MachineOverview from "./pages/realtime/MachineOverview";
import Tickets from "../caja/pages/tickets/Tickets";
import DevicesStatus from "./pages/maestros/machine/DevicesStatus";
import ServerList from "./pages/maestros/machine/ServerList";

const TgmRoutes = () => {
  const parentRoute = '/tgm'
  return (
    <Switch>

      <Route path={parentRoute + '/conexion'} component={ConexionGlobal}/>
      <Route path={parentRoute + '/produccion'} component={ProduccionGlobal}/>

      <Route path={parentRoute + '/meter-online'} component={MeterOnline}/>
      <Route path={parentRoute + '/bill-online'} component={BillOnline}/>
      <Route path={parentRoute + '/machine-overview/:id'} component={MachineOverview}/>
      <Route path={parentRoute + '/cuadre-maquina'} component={CuadreMaquinas}/>
      <Route path={parentRoute + '/liquidacion'} component={Liquidacion}/>

      <Route path={parentRoute + '/meter-snap'} component={HistorialDeContadores}/>
      <Route path={parentRoute + '/meter-build'} component={TomaDeContadores}/>
      <Route path={parentRoute + '/accountants'} component={Accountants}/>
      <Route path={parentRoute + '/machine-events'} component={MachineEvents}/>
      <Route path={parentRoute + '/billetera'} component={Billetera}/>
      <Route path={parentRoute + '/handpay-events'} component={HandpayEvents}/>
      <Route path={parentRoute + '/cereos'} component={Cereos}/>

      <Route path={parentRoute + '/meter-per-game'} component={MeterPerGame}/>

      <Route path={parentRoute + '/produccion-total'} component={ProduccionTotal}/>
      <Route path={parentRoute + '/produccion-diaria'} component={ProduccionDiaria}/>
      <Route path={parentRoute + '/producción-segun-dia'} component={ProduccionSegunDia}/>
      <Route path={parentRoute + '/produccion-por-maquina'} component={ProduccionPorMaquina}/>
      <Route path={parentRoute + '/produccion-por-modelo'} component={ProduccionPorModelo}/>
      <Route path={parentRoute + '/avanzados'} component={Avanzados}/>
      <Route path={parentRoute + '/produccion-por-mes'} component={ProduccionPorMes}/>
      <Route path={parentRoute + '/promedios'} component={Promedios}/>

      <Route path={parentRoute + '/layout-config'} component={LayoutConfig}/>
      <Route path={parentRoute + '/layout-heatmap'} component={LayoutHeatMap}/>

      <Route path={parentRoute + '/alerts'} component={BillAlert}/>

      <Route path={parentRoute + '/ludopatia-list'} component={LudopatiaList}/>
      <Route path={parentRoute + '/ludopatia-new'} component={LudopatiaNew}/>
      <Route path={parentRoute + '/ludopatia/edit/:id'} component={LudopatiaEdit}/>
      <Route path={parentRoute + '/ludopatia/gestion-listas'} component={TipoListaLudopatiaList}/>
      <Route path={parentRoute + '/ludopatia/search'} component={BuscadorLudopata}/>

      <Route path={parentRoute + '/machines/edit/:id'} component={MachineEdit}/>
      <Route path={parentRoute + '/machines'} component={MachineList}/>

      <Route path={parentRoute + '/prod-machine'} component={ProductionMachine}/>
      <Route path={parentRoute + '/prod-per-game'} component={ProduccionPorJuego}/>

      <Route path={parentRoute + '/machine-model'} component={MachineGroupList}/>
      <Route path={parentRoute + '/devices'} component={Devices}/>
      <Route path={parentRoute + '/devices-status'} component={DevicesStatus}/>
      <Route path={parentRoute + '/machine-manufacturer'} component={ManufacturerList}/>
      <Route path={parentRoute + '/machine-zones'} component={ZoneList}/>

      <Route path={parentRoute + '/tickets'} component={Tickets}/>
      <Route path={parentRoute + '/tickets-emitted'} component={TicketEmit}/>
      <Route path={parentRoute + '/tickets-redeemed'} component={TicketRedeem}/>

      <Route path={parentRoute + '/machine-set'} component={MachinesSetList}/>
      <Route path={parentRoute + '/machine-set-new'} component={MachinesSetNew}/>
      <Route path={parentRoute + '/machine-set-edit/:id'} component={MachinesSetEdit}/>

      <Route path={parentRoute + '/machine-warehouse'} component={AlmacenList}/>

      <Route path={parentRoute + '/model-manufacturer'} component={ModelAndManufacturer}/>

      <Route path={parentRoute + '/documentos-atribucion'} component={DocAtribucion}/>
      <Route path={parentRoute + '/lista-propietarios'} component={OwnerList}/>
      <Route path={parentRoute + '/periodos-doc-atribucion'} component={PeriodosDocAtribucion}/>
      <Route path={parentRoute + '/relacion-asociados'} component={RelacionAsociadosList}/>

      <Route path={parentRoute + '/aft-transfers'} component={AftTransfersList}/>

      <Route path="/tgm" component={TgmHome}/>
    </Switch>
  )
}

export default TgmRoutes;
