import React, {useEffect, useState} from "react";
import Input from "../forms/FormEl";
import {FlexContainer} from "@wargostec/react-components";

const DateTimeV2 = ({value, onChange, disabled = false, disableHour = false}) => {

  const [date, setDate] = useState()
  const [time, setTime] = useState()

  const handleChange1 = (event) => {
    setDate(event.target.value)
  };

  const handleChange2 = (event) => {
    setTime(event.target.value)
  };

  useEffect(() => {
    const dateTime = `${date}T${time}`
    onChange(dateTime)
  }, [date, time])

  useEffect(() => {
    if (value) {
      setDate(value.split('T')[0])
      setTime(value.split('T')[1].substring(0, 8))
    }
  }, [value])

  return (
    <FlexContainer flexDirection="row" gap='16px' justifyContent="center">
      <Input disabled={disabled} type={'date'} name={"date"} value={date} onChange={handleChange1}/>
      {
        !disableHour &&
        <Input disabled={disableHour} type={'time'} name={"time"} value={time} onChange={handleChange2}/>
      }
    </FlexContainer>
  )
}

export default DateTimeV2
