import React, {useContext, useEffect} from 'react';
import axios from 'axios';
import {Apis} from "../../../../util/Apis";
import CerrarMesaForm from "./CerrarMesaForm";
import useFetch2 from "../../../../hooks/useFetch2";
import {SiteContext} from "../../../common/context/SiteContext";
import {errorToast, successToast} from "../../../../components/layout/ToastStyles";

const CerrarMesa = ({id,setAction, setTitle}) => {

  const {salaId} = useContext(SiteContext)

  const params = {salaId: salaId}

  const {data} = useFetch2(Apis.TABLES + `/tables/${id}/close`,{},params)
  const {data: table} = useFetch2(Apis.TABLES + `/tables/${id}`,{},params)

  const save = (params) => {
    axios.post(Apis.TABLES + '/transaction', params)
      .then(() => {
        successToast()
      })
      .catch(() => {
        errorToast();
      })
  }

  useEffect(() => {
    setTitle("Cerrar mesa")
  }, [])

  return (
   <CerrarMesaForm id={id} type={table && table.type} data={data && data} onSubmit={save} setAction={setAction}/>
  );
};

export default CerrarMesa;
