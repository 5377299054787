import React, {useEffect} from 'react';
import useFetch2 from "../../../../../hooks/useFetch2";
import {Apis} from "../../../../../util/Apis";
import axios from "axios";
import FundTypeForm from "./FundTypeForm";

const FundTypeEdit = ({id, setAction, setTitle}) => {

  const {loading, data} = useFetch2(Apis.CAJA + '/fund-type/' + id)

  function update(params) {
    return axios.put(Apis.CAJA + '/fund-type/' + id, params)
  }

  useEffect(() => {
    setTitle("Editar")
  }, [])

  return (
    <FundTypeForm value={data} onSubmit={update} setAction={setAction}/>
  );
};

export default FundTypeEdit;
