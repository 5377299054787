import React, {useContext, useEffect, useState} from 'react'
import FormGroup, {FormBlockTitle} from "../../../../components/forms/FormGroup";
import {Controller, useForm} from "react-hook-form";
import Input from "../../../../components/forms/FormEl";
import {Col, Row} from "../../../../components/flex/FlexLayout";
import Card from "../../../../components/forms/Card";
import {Apis} from "../../../../util/Apis";
import styled from 'styled-components'
import Button from "../../../../components/forms/Button";
import useFetch2 from "../../../../hooks/useFetch2";
import RoleFormSelect from "../../../common/components/selects/RoleFormSelect";
import {IoFootball, IoRestaurantOutline} from "react-icons/io5";
import {HiOutlineBuildingOffice} from 'react-icons/hi2';
import {HiOutlineSlotMachine} from '../../../../components/assets/icons/slot-machine';
import {FaDog} from "react-icons/fa";
import {SiteContext} from "../../../common/context/SiteContext";
import Table from "react-bootstrap/Table";
import {MdCasino, MdOutlineCoPresent} from "react-icons/md";
import {GrUserAdmin} from "react-icons/gr";

const MultiSelectContainerStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 8px;
  transition: 0.5s all ease-in-out;
  @media screen and (max-width: 992px){
    transition: 0.5s all ease-in-out;
    grid-template-columns: repeat(5, 1fr);
  }
  @media screen and (max-width: 768px){
    transition: 0.5s all ease-in-out;
    grid-template-columns: repeat(4, 1fr);
  }
  @media screen and (max-width: 425px){
    transition: 0.5s all ease-in-out;
    grid-template-columns: repeat(2, 1fr);
  }
`

const MultiSelectElementStyled = styled.div`
  display: flex;
  flex:1;
  padding:20px 4px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: ${props => props.selected ? '3px solid #068eef' : '1px solid gray'};
  border-radius:8px;
  box-sizing: border-box;
  gap:8px;
  cursor: pointer;
  text-align:center;
  user-select:none;
`

const MultiSelectContainer = ({value = [], onChange, children}) => {

  const [selectedValues, setSelectedValues] = useState(value)

  useEffect(() => {
    onChange(selectedValues)
  }, [selectedValues])

  // useEffect(() => {
  //   setSelectedValues(value)
  // }, [value])

  const onClick = (id) => {
    //console.log('se clickeo : ' + id)
    let newArray
    if (selectedValues.includes(id)) {
      //console.log('debemos quitar')
      newArray = selectedValues.filter(item => item !== id)
    } else {
      //console.log('debemos agregar')
      newArray = [...selectedValues, id]
    }
    setSelectedValues(newArray)
  }

  return (
    <MultiSelectContainerStyled>
      {
        React.Children.map(children, child => {
          return React.cloneElement(child, {
            values: selectedValues,
            onClick: onClick,
            ...child.props,
          })
        })
      }
    </MultiSelectContainerStyled>
  )
}

const renderIcon = (type) => {
  switch (type) {
    case 'casino':
      return <HiOutlineSlotMachine size={24} />;
    case 'office':
      return <HiOutlineBuildingOffice size={24} />;
    case 'restaurant':
      return <IoRestaurantOutline size={24} />;
    case 'sportbet':
      return <IoFootball size={24} />;
    case 'vet':
      return <FaDog size={24} />;
    default:
      return null;
  }
};

const MultiSelectElement = ({values, item, onClick}) => {
  const isSelected = () => {
    if (values) {
      return values.includes(item.id);
    } else {
      return false
    }
  }

  return (
    <MultiSelectElementStyled selected={isSelected()} onClick={() => onClick(item.id)}>
      {renderIcon(item.type)}
      <span>{item.name} </span>
    </MultiSelectElementStyled>
  )
}


const UserForm = ({value, onSubmit}) => {

  const {config} = useContext(SiteContext)

  const {register, handleSubmit, formState: {errors}, reset, control} = useForm(
    {defaultValues: value}
  );

  const {data} = useFetch2(Apis.USER + '/salas')

  useEffect(() => {
    if (value) {
      reset(value)
    }
  }, [value])

  return (
    <form>
      {/*puede causar lag */}
      <Card padding>
        <FormBlockTitle>Establecimientos</FormBlockTitle>
        <Controller
          name="salaIds"
          control={control}
          render={({field}) => (
            <MultiSelectContainer {...field}>
              {data && data.map(item => (
                <MultiSelectElement key={item.id} item={item} />
              ))}
            </MultiSelectContainer>
          )}
        />
      </Card>

      <Card padding>
        <FormBlockTitle>Credenciales</FormBlockTitle>
        <FormGroup>
          <label>Email</label>
          <Input type='text' {...register("email")}/>
        </FormGroup>

        <FormGroup>
          <label>Usuario</label>
          <Input type='text' {...register("username")}/>
        </FormGroup>

        <FormGroup>
          <label>Contraseña</label>
          <Input type='password' {...register("password")}/>
        </FormGroup>
      </Card>

      <Card padding>
        <FormBlockTitle>Roles</FormBlockTitle>
        <Table striped hover>
          <thead>
          <tr>
            <th>Producto</th>
            <th>Rol</th>
          </tr>
          </thead>
          <tbody>
          {
            config && config.gamingEnabled &&
            <tr>
              <td><MdCasino size={25} style={{paddingRight: '5px'}}/>Gaming</td>
              <td>
                <Controller
                  name="userGroupId"
                  control={control}
                  render={({field}) => <RoleFormSelect {...field} product={'gaming'}/>}
                />
              </td>
            </tr>
          }
          {
            config && config.asisEnabled &&
            <tr>
              <td><MdOutlineCoPresent size={25} style={{paddingRight: '5px'}}/>Asistencia</td>
              <td>
                <Controller
                  name="roles.asisRoleId"
                  control={control}
                  render={({field}) => <RoleFormSelect {...field} product={'asis'}/>}
                />
              </td>
            </tr>
          }
          <tr>
            <td><GrUserAdmin size={25} style={{paddingRight: '5px'}}/>Admin</td>
            <td>
              <Controller
                name="roles.adminRoleId"
                control={control}
                render={({field}) => <RoleFormSelect {...field} product={'admin'}/>}
              />
            </td>
          </tr>
          </tbody>
        </Table>
      </Card>

      <Card padding>
        <FormBlockTitle>Datos personales</FormBlockTitle>

        <Row>
          <Col>
            <FormGroup>
              <label>Nombre</label>
              <Input type='text' {...register("firstName")}/>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <label>Apellidos</label>
              <Input type='text' {...register("lastName")}/>
            </FormGroup>

          </Col>
        </Row>

        <FormGroup>
          <label>Documento</label>
          <Input type='text' {...register("document")}/>
        </FormGroup>
      </Card>

      <Button variant={'primary'} onClick={handleSubmit(onSubmit)}>Guardar</Button>
    </form>
  )
}

export default UserForm;
