import React, {useEffect} from "react";
import SorteoActaForm from "./SorteoActaForm";
import axios from "axios";
import {Apis} from "../../../../util/Apis";
import {errorToast, successToast} from "../../../../components/layout/ToastStyles";

const SorteoActa = ({setAction, setTitle, acta, id}) => {

  const save = async (params) => {
    params.sorteoWinnerId = id
    await axios.put(Apis.SORTEO + '/sorteo-winner/' + id + '/acta', params)
      .then(() => {
        successToast()
      })
      .catch(() => {
        errorToast();
      })
  }

  useEffect(() => {
    setTitle("Nuevo Sorteo")
  }, [])

  return (
    <SorteoActaForm acta={acta} onSubmit={save} setAction={setAction}/>
  )

}

export default SorteoActa
