import {useState} from "react";
import axios from "axios";

export const usePost = ({
                          url,
                          initialValue,
                          onSuccess = () => {
                          },
                          onError = () => {
                          },
                        }) => {
  const [data, setData] = useState(initialValue);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const mutate = async function (params = {}) {
    try {
      setLoading(true);
      const response = await axios.post(url, params);
      if (response.status === 200) {
        setData(response.data);
        onSuccess(response.data);
        setError(null);
      }
    } catch (error) {
      onError(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return {loading, data, mutate, error};
};
