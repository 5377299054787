import React, {useContext, useEffect} from "react";
import axios from "axios";
import {SiteContext} from "../../../common/context/SiteContext";
import {Apis} from "../../../../util/Apis";
import AnforaForm from "./AnforaForm";
import {errorToast, successToast} from "../../../../components/layout/ToastStyles";
import useFetch2 from "../../../../hooks/useFetch2";


const AnforaEdit = ({id, setAction, setTitle}) => {

  const {salaId} = useContext(SiteContext)

  const {loading, data} = useFetch2(Apis.PC + '/anforas/' + id)

  const update = (params) => {
    params.id = id
    params.salaId = salaId
    putDateFormat(params)
    axios.put(Apis.PC + '/anforas/' + id, params)
      .then(res => {
        //console.log(res)
        successToast()
      }).catch(err => {
      //console.log(err)
        errorToast();
      })
  }

  useEffect(() => {
    setTitle("Edición de anfora")
  }, [])

  const getDateFormat = (data) => {
    data.ini = data.ini.split('T')[0]
    data.fin = data.fin.split('T')[0]

    return data
  }

  const putDateFormat = (params) => {
    params.ini = params.ini + 'T08:00:00';
    params.fin = params.fin + 'T08:00:00';
  }

  return (
    <React.Fragment>
      {data && !loading &&
        <AnforaForm value={data && getDateFormat(data)} onSubmit={update} setAction={setAction}/>
      }
    </React.Fragment>
  )
}

export default AnforaEdit
