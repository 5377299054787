import React, {useEffect, useState} from 'react';
import Button from "../forms/Button";
import moment from "moment";
import 'moment/locale/es';
import ToolbarTitle from "./ToolbarTitle";
import styled from "styled-components"
import {FaChevronLeft, FaChevronRight} from 'react-icons/fa'
import {FlexLayout} from '../flex/Flex';

const FilterInfo = styled(FlexLayout)`
  align-items: center;
  color: white;
  gap: 8px;
  flex: 1;
  transition: 0.5s all ease-in-out;
  @media screen and (max-width: 768px) {
    transition: 0.5s all ease-in-out;
    justify-content: center;
  }
`

const FilterButton = styled(FlexLayout)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  color: white;
  gap: 8px;
  transition: 0.5s all ease-in-out;
  @media screen and (max-width: 768px) {
    transition: 0.5s all ease-in-out;
    justify-content: center;
  }
`
const FilterContainer = styled(FlexLayout)`
  align-items: center;
  flex-wrap: wrap;
  transition: 0.5s all ease-in-out;
  @media screen and (max-width: 768px) {
    transition: 0.5s all ease-in-out;
    flex-direction: column;
  }
`

const DateFilter = ({fechas, setFechas, modeDate = 'day'}) => {

  const [mode, setMode] = useState(modeDate)

  const inicio = moment(fechas.ini).format('LL')
  const final = moment(fechas.fin).format('LL')
  const mes = moment(fechas.ini).format('MMMM YYYY')
  const anho = moment(fechas.ini).format('YYYY')

  const changeMode = (modeSelected) => {
    setMode(modeSelected)
  }

  const updateParams = (ini, fin) => {
    setFechas({ini, fin})
  }

  const hoy = () => {
    const fechaHoy = moment(new Date()).format('YYYY-MM-DD')
    updateParams(fechaHoy, fechaHoy)
  }

  const previous = () => {
    return handleUpdate(false)
  }

  const next = () => {
    return handleUpdate(true)
  }

  const handleUpdate = (next) => {
    const fechaMod = next ? fechas.fin : fechas.ini
    const amount = next ? 1 : -1

    let modeLocal = mode
    if (modeLocal === "week") {
      modeLocal = "weeks"
    }

    if (modeLocal === "day" || modeLocal === "today") {
      setMode('day')
      const ini = moment(fechas.ini).add(amount, "days").format('YYYY-MM-DD')
      updateParams(ini, ini)
    } else if (modeLocal === "month" || modeLocal === "year" || modeLocal === "weeks") {
      const ini = moment(fechaMod).add(amount, next ? "days" : modeLocal).format('YYYY-MM-DD')
      const fin = moment(fechaMod).add(amount, next ? modeLocal : "days").format('YYYY-MM-DD')
      updateParams(ini, fin)
    }
  }

  const day = () => {
    updateParams(fechas.ini, fechas.ini)
  }

  const week = () => {
    const y = moment(fechas.ini).year()
    const firstDayOfYear = moment(new Date(y, 0, 1)).format('YYYY-MM-DD');
    const day = fechas.ini === firstDayOfYear ? moment(fechas.ini).add('days', 1).format('YYYY-MM-DD') : fechas.ini
    const weeknumber = (moment(day).format('w'))
    const year = (moment(fechas.ini).format('YYYY'))

    const firstDayOfWeek = moment(new Date(year, 0, (weeknumber - 1) * 7 + 3)).format('YYYY-MM-DD');
    const lastDayOfWeek = moment(new Date(year, 0, (weeknumber - 1) * 7 + 9)).format('YYYY-MM-DD');

    updateParams(firstDayOfWeek, lastDayOfWeek)
  }

  const month = () => {
    const firstDayOfMonth = moment(moment(fechas.ini)).startOf('month').format('YYYY-MM-DD');
    const lastDayOfMonth = moment(moment(fechas.ini)).endOf('month').format('YYYY-MM-DD');

    updateParams(firstDayOfMonth, lastDayOfMonth)
  }

  const year = () => {
    const firstDayOfYear = moment(moment(fechas.ini)).startOf('year').format('YYYY-MM-DD');
    const lastDayOfYear = moment(moment(fechas.ini)).endOf('year').format('YYYY-MM-DD');

    updateParams(firstDayOfYear, lastDayOfYear)
  }

  useEffect(() => {
    if (fechas && fechas.ini === moment(new Date()).format('YYYY-MM-DD')) {
      setMode('today')
    }
  }, [fechas])

  return (
    <FilterContainer>
      <FilterInfo>
        <FaChevronLeft fontSize={'20'} onClick={previous} cursor='pointer' />
        <ToolbarTitle mode={mode} inicio={inicio} final={final} mes={mes} anho={anho} />
        <FaChevronRight fontSize={'20'} onClick={next} cursor='pointer' />
      </FilterInfo>

      <FilterButton>
        <Button size={'s'} value={mode} variant={mode === "today" ? "primary" : "light"}
          onClick={() => {
            changeMode("today")
            hoy();
          }}
        >
          Hoy
        </Button>
        <Button size={'s'} value={mode} variant={mode === "day" ? "primary" : "light"}
          onClick={() => {
            day();
            changeMode("day")
          }}
        >
          Día
        </Button>
        <Button size={'s'} value={mode} variant={mode === "week" ? "primary" : "light"}
          onClick={() => {
            changeMode("week")
            week();
          }}
        >
          Semana
        </Button>
        <Button size={'s'} value={mode} variant={mode === "month" ? "primary" : "light"}
          onClick={() => {
            changeMode("month")
            month();
          }}
        >
          Mes
        </Button>
        <Button size={'s'} value={mode} variant={mode === "year" ? "primary" : "light"}
          onClick={() => {
            changeMode("year")
            year();
          }}
        >
          Año
        </Button>
      </FilterButton>
    </FilterContainer>
  );
};

export default DateFilter;
