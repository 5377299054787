import React, { useState } from "react";
import { Title } from "@wargostec/react-components";
import styled from 'styled-components'
import { useHistory } from "react-router";
import { MdOutlineLocalPlay } from "react-icons/md";
import { HiOutlineChevronRight } from "react-icons/hi2";
import PageSection from "../../../../components/layout/PageSection";
import Button, { ButtonBox } from "../../../../components/forms/Button";
import { Apis } from "../../../../util/Apis";
import PageContainer from "../../../../components/layout/PageContainer";
import { IconContainer } from "../../../../components/layout/CardOverview";
import { FlexLayout } from "../../../../components/flex/Flex";
import Card from "../../../../components/forms/Card";
import AsyncBlock from "../../../../components/forms/AsyncBlock";
import useGet from "../../../../hooks/useGet";
import {Tab, Tabs} from "../../../../components/tabs/Tabs";

export const CouponGridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 12px;
    width: 100%;
    transition: 0.3s all ease-in-out;
    @media screen and (max-width: 992px) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 468px) {
        grid-template-columns: 1fr;
    }
`

const CouponTypeList = () => {

  const history = useHistory();

  const [vigentes, setVigentes] = useState(null)
  const [expirados, setExpirados] = useState(null)
  const [proximos, setProximos] = useState(null)

  const { loading, error } = useGet({
    url: Apis.PC + '/coupon-type/grouped',
    initialValue: [],
    onSuccess: setCoupons
  })

  const goToDetail = (id) => {
    history.push('/crm/coupon-types/edit/' + id)
  }

  const goToNew = () => {
    history.push('/crm/coupon-types/new')
  }

  function setCoupons(data) {
    if (data) {
      setExpirados(data.filter(item => item.first === 'Anteriores')[0])
      setVigentes(data.filter(item => item.first === 'Vigentes ahora')[0])
      setProximos(data.filter(item => item.first === 'Proximos')[0])
    }
  }

  return (
    <PageContainer>
      <PageSection>
        <Title text="Cupones" type={'page'} />
        <ButtonBox>
          <Button variant='primary' onClick={goToNew}>Nuevo</Button>
        </ButtonBox>

        <AsyncBlock loading={loading} error={error}>
          <Tabs>
            <Tab title='Vigentes'>
              {vigentes &&
                <CouponGridContainer>
                  {vigentes.second.map(coupon =>
                    <Card style={{ padding: '12px', margin: 0, cursor: 'pointer' }} key={coupon.id}
                      onClick={() => goToDetail(coupon.id)}>
                      <FlexLayout alignItems='center'>
                        <IconContainer>
                          <MdOutlineLocalPlay size={20} />
                        </IconContainer>
                        <FlexLayout column gap='0' style={{ flex: 1 }}>
                          <strong>{coupon.name}</strong>
                          <span style={{
                            color: '#212529',
                            fontWeight: '400',
                            fontSize: '12px'
                          }}>{coupon.printable ? 'Cupón Fisico' : 'Cupón Virtual'}</span>
                        </FlexLayout>
                      </FlexLayout>
                      <FlexLayout alignItems='center' justifyContent='flex-end' gap='4px'
                        style={{ alignSelf: 'stretch' }}>
                        <span style={{ fontSize: '12px' }}>Ver detalles</span>
                        <HiOutlineChevronRight />
                      </FlexLayout>
                    </Card>
                  )}
                </CouponGridContainer>
              }
            </Tab>
            <Tab title='Expirados'>
              {expirados &&
                <CouponGridContainer>
                  {expirados.second.map(coupon =>
                    <Card style={{ padding: '12px', margin: 0, cursor: 'pointer' }} key={coupon.id}
                      onClick={() => goToDetail(coupon.id)}>
                      <FlexLayout alignItems='center'>
                        <IconContainer>
                          <MdOutlineLocalPlay size={20} />
                        </IconContainer>
                        <FlexLayout column gap='0' style={{ flex: 1 }}>
                          <strong>{coupon.name}</strong>
                          <span style={{
                            color: '#212529',
                            fontWeight: '400',
                            fontSize: '12px'
                          }}>{coupon.printable ? 'Cupón Fisico' : 'Cupón Virtual'}</span>
                        </FlexLayout>
                      </FlexLayout>
                      <FlexLayout alignItems='center' justifyContent='flex-end' gap='4px'
                        style={{ alignSelf: 'stretch' }}>
                        <span style={{ fontSize: '12px' }}>Ver detalles</span>
                        <HiOutlineChevronRight />
                      </FlexLayout>
                    </Card>
                  )}
                </CouponGridContainer>
              }
            </Tab>
            <Tab title='Próximos'>
              {proximos &&
                <CouponGridContainer>
                  {proximos.second.map(coupon =>
                    <Card style={{ padding: '12px', margin: 0, cursor: 'pointer' }} key={coupon.id}
                      onClick={() => goToDetail(coupon.id)}>
                      <FlexLayout alignItems='center'>
                        <IconContainer>
                          <MdOutlineLocalPlay size={20} />
                        </IconContainer>
                        <FlexLayout column gap='0' style={{ flex: 1 }}>
                          <strong>{coupon.name}</strong>
                          <span style={{
                            color: '#212529',
                            fontWeight: '400',
                            fontSize: '12px'
                          }}>{coupon.printable ? 'Cupón Fisico' : 'Cupón Virtual'}</span>
                        </FlexLayout>
                      </FlexLayout>
                      <FlexLayout alignItems='center' justifyContent='flex-end' gap='4px'
                        style={{ alignSelf: 'stretch' }}>
                        <span style={{ fontSize: '12px' }}>Ver detalles</span>
                        <HiOutlineChevronRight />
                      </FlexLayout>
                    </Card>
                  )}
                </CouponGridContainer>
              }
            </Tab>
          </Tabs>
        </AsyncBlock>
      </PageSection>


    </PageContainer>
  )
}

export default CouponTypeList;
