import React from "react"
import styled from 'styled-components'

const ButtonBox = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
`

const ButtonStyled = styled.button`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 8px 14px;

  white-space: nowrap;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  box-sizing: border-box;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.10);
  outline: 0;
  font-size: 14px;
  font-weight: 500;

  background: #F8F9FA;
  border: 1px solid #F8F9FA;
  color: #000;
  border-radius: 4px;
  cursor: pointer;
  -ms-user-select: none;
  user-select:none;
  transition: background-color .15s ease-in-out,border-color .15s ease-in-out;

  svg{
    font-size: 18px;
  }

  &:focus:not(:focus-visible) {
    outline: 0;
  }

  &:hover{
    background: #D3D4D5;
    border-color: #C6C7C8;
  }

  &:active{
    background: #C6C7C8;
    border-color: #BABBBC;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }
  
  &.btn-primary {
    background: linear-gradient(45deg, #0674db, #069cf9);
    color: rgb(255, 255, 255);
    border-color: #069cf9;
  }

  &.btn-primary:hover {
    background: linear-gradient(45deg, #0363be, #0682d0);
    border-color: #0682d0;
  }

  &.btn-primary:active {
    background: linear-gradient(45deg, #0363be, #0682d0);
    border-color: #0682d0;
  }

  &.btn-secondary {
    color: var(--purple);
    background-color: #00dd8d;
    border: 2px solid #00dd8d;
  }

  &.btn-success {
    background:#37BA32;
    color: #FFFFFF;
    border-color: #37BA32;
  }

  &.btn-success:hover {
    background: #34B030;
    border-color: #2CAF27;
  }

  &.btn-success:active {
    background: #30A32B;
    border-color: #2AA725;
  }

  &.btn-light {
    color: #000;
    background: #F8F9FA;
    border-color: #F8F9FA;
  }

  &.btn-light:hover {
    background: #D3D4D5;
    border-color: #C6C7C8;
  }

  &.btn-light:active {
    background: #C6C7C8;
    border-color: #BABBBC;
  }

  &.btn-danger {
    background:#DC3545;
    color: #FFFFFF;
    border-color: #DC3545;
  }

  &.btn-danger:hover {
    background: #BB2D3B;
    border-color: #B02A37;
  }

  &.btn-danger:active {
    background: #B02A37;
    border-color: #A52834;
  }

  &.btn-warning {
    background:#FFC107;
    color: #000;
    border-color: #FFC107;
  }

  &.btn-warning:hover {
    background: #FFCA2C;
      border-color: #FFC720;
  }

  &.btn-warning:active {
    background: #FFCD39;
    border-color: #FFC720;
  }

  &.btn-dark {
    background: #212529;
    color: #FFFFFF;
    border-color: #212529;
  }

  &.btn-dark:hover {
    background: #424649;
    border-color: #373B3E;
  }

  &.btn-dark:active {
    background: #4D5154;
    border-color: #373B3E;
  }

  &.outline {
    background: transparent;
    color: #0a0a0a;
    border-color: #0a0a0a;
  }

  &.outline:hover {
    background: #e5e5e5;
    color: #0a0a0a;
    border-color: #0a0a0a;
  }

  &.outline:active {
    background: #e5e5e5;
    color: #0a0a0a;
    border-color: #0a0a0a;
  }

  &.btn-small {
    min-width: 40px;
    height: 32px;
    padding: 4px 10px;
    border-radius: 6px;
    font-size: 13px;
  }

  &.btn-xsmall {
    min-width: 20px;
    height: 28px;
    padding: 2px 6px;
    border-radius: 4px;
    font-size: 12px;
  }

  &[disabled=disabled], &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`

const Button = ({variant, size, onClick, title, children, ...props}) => {

  //variant : default, primary, success
  let variantClass = ''
  if (variant === 'primary') {
    variantClass = 'btn-primary'
  } else if (variant === 'secondary') {
    variantClass = 'btn-secondary'
  } else if (variant === 'light') {
    variantClass = 'btn-light'
  } else if (variant === 'success') {
    variantClass = 'btn-success'
  } else if (variant === 'danger') {
    variantClass = 'btn-danger'
  } else if (variant === 'warning') {
    variantClass = 'btn-warning'
  }else if (variant === 'dark') {
    variantClass = 'btn-dark'
  }else if (variant === 'outline') {
    variantClass = 'outline'
  } else {
    variantClass = 'btn-default'
  }

  if (size === 's') {
    variantClass = variantClass + ' btn-small'
  } else if (size === 'xs') {
    variantClass = variantClass + ' btn-xsmall'
  }

  return (
    <ButtonStyled onClick={onClick} className={variantClass} {...props}>
      {title ? title : children}
    </ButtonStyled>
  )
}

export {ButtonBox}
export default Button
