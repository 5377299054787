import React, {createContext, useState} from "react";

export const ActionContext = createContext(null);

const ActionContextWrapper = ({children}) => {

  const [action, setAction] = useState(null);
  const [visible, setVisible] = useState(true);

  // function toggleStyle() {
  //   setStyle(style => (style === "light" ? "dark" : "light"));
  // }
  // function toggleVisible() {
  //   setVisible(visible => !visible);
  // }

  return (
    <ActionContext.Provider
      value={{ action, setAction }}
    >
      {children}
    </ActionContext.Provider>
  );
}

export default ActionContextWrapper
