import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { MdCloudUpload } from "react-icons/md";
import { IoMdCloseCircle } from "react-icons/io";
import styles from './file-uploader.module.scss'

type FileUploaderProps = {
  value: string;
  domain?: string;
  height?: string | number;
  width?: string | number;
  onUpload: (file: File) => void;
  onDelete?: () => void;
};

const FileUploader: React.FC<FileUploaderProps> = ({ value, domain = 'https://wgt-tmp-uploads.s3.amazonaws.com/', height = 160, width = 160, onDelete, onUpload }) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: useCallback((acceptedFiles) => {
      acceptedFiles.map(file => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        onUpload(file)
        return file;
      });
    }, []),
    accept: {
      'image/jpeg': [],
      'image/jpg': [],
      'image/png': [],
      'application/pdf': [],
      'application/xlsx': []
    },
    maxFiles: 1,
  })

  const getPreview = () => {
    const parts = value.split('.')
    if (parts[1] === 'xlsx') {
      return <img
        src={'https://res.cloudinary.com/dghqjv5az/image/upload/v1664948280/wgtassets/microsoft-excel_lfygi9.webp'}
        alt={'excel'}
        style={{ height: "80px", minWidth: "80px" }}
      />
    }
    if (parts[1] === 'pdf') {
      return <img
        src={'https://res.cloudinary.com/dghqjv5az/image/upload/v1668203157/wgtassets/pdf-icon_xpqshv.png'}
        alt={'pdf'}
        style={{ height: "80px", minWidth: "80px" }}
      />
    } else {
      return <img src={`${domain}${value}`}
        style={{ width: "140px", height: "140px", minWidth: "140px" }}
        alt={'preview'} />
    }
  }


  return (
    <div className={styles['file-uploader-container']}>
      <div style={{ height, width }} >
        {
          !value || value.trim() === '' ?
            <div className={styles['file-box']} {...getRootProps()}>
              <input {...getInputProps()} />
              <div className={styles['dropzone']}>
                <MdCloudUpload fontSize={'50'} color={'#4d4e4f'} />
                <p>
                  Arrastre los archivos aquí o haga clic para cargarlos
                </p>
              </div>
            </div> :
            <div>
              {getPreview()}
            </div>
        }
      </div>
      {
        !value || value.trim() !== '' &&
        <div>
          <IoMdCloseCircle onClick={onDelete} fontSize={'26'} style={{ cursor: 'pointer' }} />
        </div>
      }
    </div>
  )
}

export default FileUploader