import React from "react";
import {Apis} from "../../../../../util/Apis";
import SorteoForm from "./SorteoForm";
import axios from "axios";
import PageSection from "../../../../../components/layout/PageSection";
import {PageBackLabel} from "../../../../../components/layout/PageBackLabel";
import {Title} from "@wargostec/react-components";
import PageContainer from "../../../../../components/layout/PageContainer";
import {useHistory} from "react-router";
import {errorToast, successToast} from "../../../../../components/layout/ToastStyles";

const SorteoNew = () => {

  const history = useHistory()

  const onCreate = (params) => {
    const eventType = params.eventType
    if (eventType == 2) {
      const totalAmount = params.totalAmount ? params.totalAmount : 0
      const linePrize = params.linePrize ? params.linePrize : 0
      const doubleLinePrize = params.doubleLinePrize ? params.doubleLinePrize : 0
      const totalPrize = parseInt(linePrize) + parseInt(doubleLinePrize)
      params.blackOut = totalAmount - (totalPrize).toFixed(2)
    }
    if (params.eventId !== 0) return axios.post(Apis.BINGO + '/sorteo', params).then(res => {
      history.push('/bingos/partidas')
      successToast("Partida creada correctamente")
    }).catch(err => {
      errorToast("Ocurrió un problema al actualizar. Intente nuevamente en unos minutos.")
    })
  }

  return (
    <PageContainer>
      <PageSection style={{marginBottom: "20px"}}>
        <PageBackLabel text='Lista de partidas' onClick={() => history.goBack()}/>
          <Title text="Nueva partida"/>
      </PageSection>

      <PageSection>
        <SorteoForm value={{}} onSubmit={onCreate}/>
      </PageSection>

    </PageContainer>
  )
}

export default SorteoNew
