import React, { useContext } from "react";
import { Title } from "@wargostec/react-components";
import { useHistory } from "react-router";
import PageContainer from "../../../../components/layout/PageContainer";
import PageSection from "../../../../components/layout/PageSection";
import { Apis } from "../../../../util/Apis";
import PuntosPromoForm from "./PuntosPromoForm";
import { SiteContext } from "../../../common/context/SiteContext";
import { PageBackLabel } from "../../../../components/layout/PageBackLabel";
import { errorToast, successToast } from "../../../../components/layout/ToastStyles";
import { usePost } from "../../../../hooks/usePost";

const PuntosPromoNew = () => {

  const { salaId } = useContext(SiteContext)
  const history = useHistory()

  const { mutate } = usePost({
    url: Apis.PC + '/point-promo',
    onSuccess: () => {
      history.push('/crm/puntos-promociones')
      successToast("Multiplicador creado")
    },
    onError: (err) => {
      errorToast(err.response.data.message ? err.response.data.message : "Ocurrió un problema. Por favor intenta nuevamente", 2000)
    }
  })

  const onCreate = (params) => {
    params.salaId = salaId
    mutate(params)
  }
  return (
    <PageContainer>
      <PageSection style={{ marginBottom: "20px" }}>
        <PageBackLabel text='Promociones' onClick={() => history.push('/crm/puntos-promociones')} />
        <Title text="Nuevo multiplicador" />
      </PageSection>

      <PageSection>
        <PuntosPromoForm value={{}} onSubmit={onCreate} />
      </PageSection>

    </PageContainer>
  )
}

export default PuntosPromoNew
