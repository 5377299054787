import React, {useContext, useEffect} from 'react';
import {SiteContext} from "../../../../common/context/SiteContext";
import axios from "axios";
import {Apis} from "../../../../../util/Apis";
import ProductCatalogForm from "./ProductCatalogForm";

const ProductCatalogNew = ({setTitle, setAction}) => {
  const {salaId} = useContext(SiteContext)

  const save = async (params) => {
    params.price = params.price !== "" ? params.price : 0
    params.salaId = salaId
    params.enabled = true
    await axios.post(Apis.PC + "/catalog-products", params).catch((err) => {
    });
  }

  useEffect(() => {
    setTitle("Nuevo Producto")
  }, [])
  return (
    <ProductCatalogForm value={{}} onSubmit={save} setAction={setAction}/>
  );
};

export default ProductCatalogNew;
