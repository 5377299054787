import React, {useContext} from 'react';
import {SiteContext} from "../../context/SiteContext";
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import {Select, SelectV2} from "../../../../components/forms/FormEl";

const FundTypeSelect = React.forwardRef((props, ref) => {

  const {salaId} = useContext(SiteContext)
  const {loading, data, fetchData, error} = useFetch2(`${Apis.CAJA}/fund-type?salaId=${salaId}`, []);

  return (
    <>
      {
        !loading ?
          <SelectV2
            ref={ref}
            selectStyle={props.selectStyle}
            options={data}
            {...props}
          ></SelectV2>
          :
          <Select disabled={true}>
            <option key={0} value={0}>{"Cargando..."}</option>
          </Select>
      }
    </>
  );
});

export default FundTypeSelect;
