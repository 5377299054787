import {useState} from "react";

//Hook para realizar confirmaciones el cual se emplearán para realizar una acción individual que solo cambie 1 atributo de un objeto o realice una función
const useConfirmModal = ({text}) => {

  const [show, setShow] = useState(false);
  //saved for later use
  const [id, setId] = useState(null);
  const [action, setAction] = useState(null);

  const openConfirmModal = (action, id) => {
    //save function and id for later use, if it were necessary
    setId(id)
    setAction(() => action)
    //open modal
    setShow(true)
  }

  const onCloseConfirmModal = () => {
    setShow(false)
  }

  const executeAction = () => {
    action(id)
    setShow(false)
  }

  const wrapper = {show: show, onHide: onCloseConfirmModal, 'action': executeAction, text: text}

  return {wrapper, id, openConfirmModal}
}

export default useConfirmModal
