import React from "react";
import PageContainer from "../../../../components/layout/PageContainer";
import PageSection from "../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";

const ActivosVSRegistrados = () => {
  return (
    <PageContainer>
      <PageSection>
          <Title text="Activos vs Registrados"/>
      </PageSection>

      <PageSection>

      </PageSection>
    </PageContainer>
  )
}

export default ActivosVSRegistrados;
