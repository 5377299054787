import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {SiteContext} from "../../../common/context/SiteContext";
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import useEditModal from "../../../../modals/useEditModal";
import AftTransferEdit from "./AftTransferEdit";
import AftTransferNew from "./AftTransferNew";
import axios from "axios";
import useDeleteModal from "../../../../modals/useDeleteModal";
import PageContainerFull from "../../../../components/layout/PageContainerFull";
import {getCasinoDay} from "../../../../components/util/CasinoDates";
import SalaIdInput from "../../../common/components/SalaIdInput";
import FormGroup from "../../../../components/forms/FormGroup";
import {DatePickerCustom} from "../../../../components/forms/FormEl";
import Button from "../../../../components/forms/Button";
import Slidebar from "../../../common/layout/Slidebar";
import {Title} from "@wargostec/react-components";
import AsyncBlock from "../../../../components/forms/AsyncBlock";
import ReactTable from "../../../../components/util/ReactTable";
import PageSection from "../../../../components/layout/PageSection";
import useToggle from "../../../../hooks/useToggle";
import {useForm} from "react-hook-form";
import {FlexLayout} from "../../../../components/flex/Flex";
import GenericModal2 from "../../../../modals/GenericModal2";
import DeleteGenericModal from "../../../../modals/DeleteGenericModal";
import Moment from "react-moment";
import MachineSelect from "../../../common/components/selects/MachineSelect";
import {FaCheck} from "react-icons/fa";
import {GrUpdate} from "react-icons/gr";

const AftTransfersList = () => {

  const {salaId} = useContext(SiteContext);

  const casinoDay = getCasinoDay()

  let defaultParams = {salaId: salaId, ini: casinoDay, fin: casinoDay}
  const [params, setParams] = useState(defaultParams)

  const {data, fetchData, loading, error} = useFetch2(Apis.TGM + '/aft-transfer', [], params);

  const {wrapper: wrapper1, addAction, editAction} = useEditModal({
    editComponent: <AftTransferEdit/>,
    addComponent: <AftTransferNew/>,
    onRefresh: fetchData
  })

  const {isOpen: slideOpen, toggler} = useToggle({});
  const {register, handleSubmit, reset,} = useForm({defaultValues: defaultParams});

  const deleteElement = id => axios.delete(Apis.TGM + '/aft-transfer/' + id)

  const {
    wrapper: wrapper2,
    openDeleteModal
  } = useDeleteModal({
    text: '¿Desea eliminar la transferencia?',
    action: deleteElement,
    onRefresh: fetchData
  })

  useEffect(() => {
    fetchData()
  }, [params])

  const getData = (params) => {
    if (params.confId === '0' || params.confId === 0) {
      params.confId = null
    }
    setParams(params)
  }

  const actionsValue = useCallback(props => <>{
    <FlexLayout justifyContent='center'>
      <Button size='xs' variant='light' onClick={() => editAction(props.row.original.id)}>Editar</Button>
      <Button size='xs' variant='danger' onClick={() => openDeleteModal(props.row.original.id)}>Eliminar</Button>
    </FlexLayout>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }</>, []);

  const typeValue = useCallback(props => getTransferTypeName(props.renderValue()), []);
  const stateValue = useCallback(props => props.renderValue() ? getStateName(props.renderValue()) : '---', []);

  const createdValue = useCallback(props => <>{
    props.renderValue() ?
      <FlexLayout alignItems='center' justifyContent='center' gap='4px'>
        <Moment format={'YYYY-MM-DD hh:mm'}>{props.renderValue()}</Moment>
      </FlexLayout>
      :
      <span>---</span>
  }</>, []);

  const employeeValue = useCallback(props => <>{
    props.renderValue() ?
      <span>{props.renderValue()}</span>
      :
      <span>---</span>
  }</>, []);


  const sync = useCallback(props => <>{
      props.renderValue() ?
        <span><FaCheck color={'green'}/></span> :
        <span><GrUpdate color={'#FCB51C'}/></span>
    }</>
    , []);


  const columns = useMemo(
    () => [
      {
        header: 'ID',
        accessorKey: 'id',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Maquina',
        accessorKey: 'machineName',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'M. Canjeable',
        accessorKey: 'cashableAmount',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'M. Restringido',
        accessorKey: 'restrictedAmount',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'M. No Restringido',
        accessorKey: 'nonrestrictedAmount',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Estado',
        accessorKey: 'state',
        cell: stateValue,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Tipo',
        accessorKey: 'transferType',
        cell: typeValue,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Motivo',
        accessorKey: 'motive',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Empleado',
        accessorKey: 'employeeName',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: employeeValue
      },
      {
        header: 'Fecha de creación',
        accessorKey: 'createdAt',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: createdValue
      },
      {
        header: 'Sync',
        accessorKey: 'synced',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: sync
      },
      {
        header: 'Acciones',
        accessorKey: 'id',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
        cell: actionsValue
      }
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [data]
  )

  const getStateName = (state) => {
    switch (state) {
      case 'GENERATED':
        return 'Generado';
      case 'PROGRESS':
        return 'En progreso';
      case 'COMPLETED':
        return 'Completado';
      case 'CANCELED':
        return 'Cancelado';
      case 'APPROVED':
        return 'Aprobado';
      case 'ERROR':
        return 'Error';
      case 'SENT_TO_DEVICE':
        return 'Enviado a dispositivo';
      default:
        return 'Desconocido';
    }
  }

  const getTransferTypeName = (transferType) => {
    switch (transferType) {
      case 0:
        return 'Normal';
      case 16:
        return 'Bonus';
      case 17:
        return 'Jackpot';
      default:
        return 'Normal';
    }
  }

  return (
    <PageContainerFull>
      <Slidebar visible={slideOpen} onHide={toggler}>
        <div style={{padding: '16px'}}>
          <SalaIdInput {...register("salaId")} />
          <FormGroup>
            <label>Desde</label>
            <DatePickerCustom {...register("ini")} />
          </FormGroup>

          <FormGroup>
            <label>Hasta</label>
            <DatePickerCustom {...register("fin")} />
          </FormGroup>

          <FormGroup>
            <label>Maquina</label>
            <MachineSelect todos={1} {...register("confId")}/>
          </FormGroup>

          <Button variant={'primary'} title='Buscar' onClick={handleSubmit(getData)}/>
        </div>
      </Slidebar>

      <PageSection>
          <Title text="Transferencias" type={'page'}/>

        <AsyncBlock loading={loading} error={error}>
          <ReactTable
            columns={columns}
            filterOnclick={toggler}
            filterAdd={addAction}
            data={data || []}
            noData='No existen resultados para el filtro seleccionado'
            pageSize={20}
          />
        </AsyncBlock>
      </PageSection>

      <GenericModal2 {...wrapper1} />
      <DeleteGenericModal {...wrapper2} />
    </PageContainerFull>
  );
};

export default AftTransfersList;
