import React, {useContext, useEffect, useState} from "react";
import axios from 'axios'
import {Redirect, useLocation} from "react-router";
import {Apis} from "../../util/Apis";
import {jwtDecode} from "jwt-decode";
import {SET_SOCIAL_LOGIN} from "./actions/authentication.action";
import AuthContext from "./store/AuthContext";

const CallbackPage = () => {

  const {stateUser, dispatch} = useContext(AuthContext)
  const [message, setMessage] = useState("Verificando cuenta")

  let location = useLocation()
  const defaultReferer = '/selector'
  const [referer, setReferer] = useState(defaultReferer)


  useEffect(() => {
    sendIdToken()
  }, [])

  function sendIdToken() {
    const searchParams = new URLSearchParams(window.location.search)
    const params = getHashParams()
    const idToken = params.get("#id_token")
    const wsrf = params.get("wsrf")

    const referer1 = searchParams.get("referer")
    if (referer1 !== null && referer1 !== '') {
      console.log('referer found : ' + referer1)
      setReferer(referer1)
    }

    const authHeader = "Bearer " + idToken

    axios.get(Apis.USER + '/users/verify', {headers: {"Authorization": authHeader}})
      .then(res => {
        setMessage('Cuenta verificada')
        socialUserExist(idToken)
        localStorage.setItem("wsrf", wsrf);
      })
      .catch(error => {
        setMessage('Su cuenta no esta asociada , contactese con el administrador')
      })
  }

  const socialUserExist = (token) => {
    localStorage.setItem("auth", token);
    const decodedToken = jwtDecode(token);
    dispatch({
      type: SET_SOCIAL_LOGIN,
      payload: decodedToken
    });
  }

  function getHashParams() {
    let arr = new Map();
    location.hash.split('&').map((par, index) => {
      arr.set(par.split('=')[0], par.split('=')[1]);
    })
    return arr
  }

  const isLoggedIn = stateUser.isAuthenticated

  if (isLoggedIn) {
    return <Redirect to={referer}/>
  }

  return (
    <div>
      {message}
    </div>
  )
};

export default CallbackPage;
