import React, { useContext } from "react";
import { useHistory, useParams } from "react-router";
import { Title } from "@wargostec/react-components";
import PageContainer from "../../../../components/layout/PageContainer";
import PageSection from "../../../../components/layout/PageSection";
import { PageBackLabel } from "../../../../components/layout/PageBackLabel";
import CouponPromoForm from "./CouponPromoForm";
import { SiteContext } from "../../../common/context/SiteContext";
import { Apis } from "../../../../util/Apis";
import Loader1 from "../../../common/components/Loader1";
import { errorToast, successToast } from "../../../../components/layout/ToastStyles";
import useGet from "../../../../hooks/useGet";
import { usePut } from "../../../../hooks/usePut";

const CouponPromoEdit = () => {

  const { id } = useParams()
  const { salaId } = useContext(SiteContext)
  const history = useHistory()

  const { data, loading } = useGet({
    url: Apis.PC + '/coupon-type-promo/' + id,
    initialValue: {}
  })

  const { mutate } = usePut({
    url: Apis.PC + '/coupon-type-promo/' + id,
    onSuccess: () => {
      successToast("Promoción actualizada correctamente")
      history.push('/crm/coupon-promo')
    },
    onError: (err) => {
      errorToast(err.response.data.message ? err.response.data.message : "Ocurrió un problema al actualizar. Intente nuevamente en unos minutos.", 2000)
    }
  })

  const onUpdate = (params) => {
    params.salaId = salaId
    mutate(params)
  }

  return (
    <PageContainer>
      <PageSection style={{ marginBottom: "20px" }}>
        <PageBackLabel text='Promociones' onClick={() => history.goBack()} />
        <Title text="Editar promoción" />
      </PageSection>
      <PageSection>
        {
          loading ?
            <Loader1 />
            :
            <CouponPromoForm value={data} onSubmit={onUpdate} edit={1} />
        }
      </PageSection>
    </PageContainer>
  )
}

export default CouponPromoEdit
