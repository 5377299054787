import React from "react";
import styled from "styled-components";

const CardTitleSpan = styled.h6`
  font-size: 1rem;

  margin-bottom: 0.2rem;

  @media screen and (min-width: 768px) {
    margin-bottom: 20px;
  }

  //ECWIND
  @media screen and (min-width: 768px) {
    font-size: 1.2rem;
    line-height: 30px;
  }
`;

// TODO :  Evaluate if icon is needed
const PageCardSubTitle = ({title, icon}) => {
  return (
    <CardTitleSpan>
      {title}
      {icon}
    </CardTitleSpan>
  );
};


export default PageCardSubTitle;


