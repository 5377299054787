import React, {useContext, useEffect, useState} from "react";
import PageContainerFull from "../../../../components/layout/PageContainerFull";
import PageSection from "../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import {FormBlock} from "../../../../components/forms/FormGroup";
import Table from "react-bootstrap/Table";
import NewCouponAwarded from "../coupons/CouponAwarded/NewCouponAwarded";
import useFetch from "../../../../hooks/useFetch";
import {Apis} from "../../../../util/Apis";
import {SiteContext} from "../../../common/context/SiteContext";
import Loader1 from "../../../common/components/Loader1";
import {Col, Row} from "../../../../components/flex/FlexLayout";
import {Flex} from "../../../../components/flex/Flex";
import StadisticTitle from "../../../../components/layout/StadisticTitle";
import StadisticValue from "../../../../components/layout/StadisticValue";
import Card from "../../../../components/forms/Card";
import useFetch2 from "../../../../hooks/useFetch2";
import AsyncBlock from "../../../../components/forms/AsyncBlock";

const SesionesExternas = () => {

  const [show, setShow] = useState(false);
  const {salaId} = useContext(SiteContext)
  const [params, setParams] = useState()
  const [clientes, setClientes] = useState()
  const [maquinas, setMaquinas] = useState([])

  const {loading, data, fetchData, error} = useFetch2(Apis.PC + '/session/sessions-external?salaId=' + salaId, [], params);
  const machines = useFetch(Apis.PC + '/machines?enabled=true&salaId=' + salaId, [], params);

  const handleClose = () => {
    setShow(false);
  }

  useEffect(() => {
    setClientes(data)
  }, [data])

  useEffect(() => {
    setMaquinas(machines.data)
  }, [machines.data])

  const encontrarMachineName = (itemId) => {
    const machineName = <span style={{color: "red"}}>No está registrado</span>
    for (let i = 0; i < maquinas.length; i++) {
      if (maquinas[i].id === itemId) return maquinas[i].machine
    }
    return machineName
  }

  return (
    <PageContainerFull>
      <PageSection>
          <Title text="Sesiones externas"/>
        <Card padding>
          <AsyncBlock loading={loading} error={error}>
            <Row>
              <Col>
                <Flex flexDirection={'column'}>
                  <StadisticTitle>Número de sesiones</StadisticTitle>
                  <StadisticValue>{data && data?.length}</StadisticValue>
                </Flex>
              </Col>
            </Row>
          </AsyncBlock>
        </Card>
      </PageSection>
      <PageSection>
        <FormBlock>
          <Table striped hover>
            <thead>
            <tr>
              <th>ID Máquina</th>
              <th>Nombre Máquina</th>
              <th>Cliente</th>
              <th>Documento</th>
              <th>Fecha de Sesión</th>
            </tr>
            </thead>
            {
              !loading
              && data.length > 0 && <tbody>
              {data && data.map(item =>
                <tr key={item.id}>
                  <td>{item.confId}</td>
                  <td>{encontrarMachineName(item.confId)}</td>
                  <td>{item.customer.firstName+ ' ' + item.customer.lastName}</td>
                  <td>{`${item.customer.document}`}</td>
                  <td>{`${item.sessionStartDatetime}`}</td>
                </tr>
              )}
              </tbody>
            }
          </Table>
          {loading && <div style={{display: "flex", justifyContent: "center"}}><Loader1/></div>}
          {!loading && data.length === 0 && <span style={{
            display: "flex",
            justifyContent: "center",
            fontSize: "20px",
            marginTop: "50px",
            fontWeight: "bolder"
          }}>Aún no hay sesiones externas</span>}
        </FormBlock>
      </PageSection>
      <NewCouponAwarded show={show} onHide={() => handleClose()}/>
    </PageContainerFull>
  )
}

export default SesionesExternas;
