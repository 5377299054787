import React, {useState} from "react";
import Card from "../../../../components/forms/Card";
import GroupPolicies from "./groupTabs/GroupPolicies";
import GroupUsers from "./groupTabs/GroupUsers";
import {Tab, Tabs} from "../../../../components/tabs/Tabs";


const GroupForm = ({value, onSubmit}) => {


  const [key, setKey] = useState('users')

  const cambiarKey=(k)=>{
    setKey(k)
    localStorage.setItem('activeKey',k)
  }

  return (
    <form>
      <Card padding style={{marginBottom:"25px"}}>

        <Tabs defaultActiveKey={localStorage.getItem('activeKey')?localStorage.getItem('activeKey'):'users'} onSelect={(k)=>cambiarKey(k)}  >
          <Tab title="Usuarios" ><GroupUsers /></Tab>
          <Tab title="Políticas" ><GroupPolicies /></Tab>
        </Tabs>
      </Card>
    </form>
  )
}

export default GroupForm;
