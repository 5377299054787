import React from 'react'
import {FaBatteryEmpty} from 'react-icons/fa'
import {FlexLayout} from '../../../components/flex/Flex'

const EmptyState = ({title,subtitle,icon}) => {
  return (
    <FlexLayout column  alignItems='center'>
      {icon ? icon : <FaBatteryEmpty color='grey' size={32} />}
      <strong style={{fontSize:'20px'}}>
        {title}
      </strong>
      <span>
        {subtitle}
      </span>
    </FlexLayout>
  )
}

export default EmptyState