import React from "react";
import Button from "../../../../components/forms/Button";
import styled from "styled-components";
import {AnimatePresence, motion} from "framer-motion";
import {Controller, useForm} from "react-hook-form";
import {errorToast} from "../../../../components/layout/ToastStyles";
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../util/Apis";
import SlotGroupSelectV2 from "../../../common/components/selects/SlotGroupSelectV2";

const ControlsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    gap: 8px;
    border-radius: 8px;
    box-shadow: rgb(0 0 0 / 10%) 0 2px 4px;
    background: white;
    padding: 16px;
`

const ButtonContainer = styled.div`
    display: flex;
    gap: 4px;
`

const LayoutSearchGroup = ({resetTransform, groupVisible, setSelectedGroup}) => {

  const {data: dataMachines} = useFetch2(`${Apis.TGM}/machines?enabled=true`, [])

  const {control, watch, reset} = useForm({
    defaultValues: {
      groupId: null
    }
  })

  const groupId = watch('groupId');

  const grupoEncontrado = dataMachines.filter(elemento => elemento.groupId === groupId);

  const handleSearch = () => {
    if (grupoEncontrado.length > 0) {
      setSelectedGroup(grupoEncontrado)
    } else {
      errorToast('No se encontró grupo');
    }
  };

  const handleReset = () => {
    resetTransform();
    reset({groupId: null});
    setSelectedGroup(null);
  };

  return (
    <AnimatePresence>
      {groupVisible && (
        <motion.div
          initial={{opacity: 0, y: -20}}
          animate={{opacity: 1, y: 0}}
          exit={{opacity: 0, y: -20}}
          style={{display: 'flex', flexDirection: 'column', width: '250px'}}
        >
          <ControlsContainer>
            <strong>Grupo de Máquinas</strong>
            <div style={{width: '100%'}}>
              <Controller
                name="groupId"
                control={control}
                render={({field}) => <SlotGroupSelectV2  {...field} />}
              />
            </div>
            <ButtonContainer>
              <Button size="s" variant='dark' onClick={handleSearch} disabled={groupId === null}>
                Buscar
              </Button>
              {grupoEncontrado !== undefined &&
                <Button
                  size="s"
                  onClick={handleReset}
                  disabled={groupId === null}
                >
                  Limpiar
                </Button>
              }
            </ButtonContainer>
          </ControlsContainer>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default LayoutSearchGroup;
