import React from "react";
import styled from "styled-components";
import {Col, Row} from "../flex/FlexLayout";
import Button from "../forms/Button";
import Input from "../forms/FormEl";
import PageCardTitle from "../layout/CardTitle";
import PageCardSubTitle from "../layout/CardSubTitle";
import {BiPlusCircle, BiTrash} from "react-icons/bi";
import {Dates} from "../util/Dates";

const ContenedorPadding = styled.div`
  padding: 0 30px 0 30px;
  margin-top: 5px;
  margin-bottom: 10px;
`

export {ContenedorPadding}

const InnerContenedorPadding = styled.div`
  padding: 0 10% 0 10%;
  margin-top: 5px;
  margin-bottom: 10px;
`
export {InnerContenedorPadding}




const PromoConfig = ({fields, dayOfWeekArray, setDayOfWeekArray, register, agregarHoras, remove}) => {
  return (<>
      <PageCardTitle title={'Configuración'}/>

      <Row>
        <Col>
          <PageCardSubTitle title={'Días'}/>
          <div>
            <Dates value={dayOfWeekArray} onChange={setDayOfWeekArray} />
          </div>
        </Col>
        <Col>
          <PageCardSubTitle title={'Horas'}/>
          <div>
            <Row style={{marginBottom: "10px"}}>
              <Col>Desde</Col>
              <Col>Hasta</Col>
              <Col/>
            </Row>
            <div>
              {fields && fields.map((item, index) =>
                <Row key={item.id} style={{display : 'flex', marginBottom: "10px"}}>
                  <Col>
                    <Input type={"time"} defaultValue={item.ini} {...register(`listHourRange[${index}].ini`)}/>
                  </Col>
                  <Col>
                    <Input type={"time"} defaultValue={item.fin}  {...register(`listHourRange[${index}].fin`)}/>
                  </Col>
                  <Col>
                    <Button variant='danger' onClick={() => remove(index)}>
                      <BiTrash size={22} />
                    </Button>
                  </Col>
                </Row>
              )}
            </div>
          </div>
          <Button onClick={agregarHoras}>
              <BiPlusCircle size={22}/>
              <span>Agregar</span>
            </Button>
        </Col>
      </Row>
    </>
  )
}

export default PromoConfig;
