import React, {useEffect} from 'react'
import {useForm} from "react-hook-form";
import FormGroup from "../../../../components/forms/FormGroup";
import Input, { Select } from "../../../../components/forms/FormEl";

const ManagerForm = ({value, onSubmit, setAction}) => {

  const {register, handleSubmit, reset} = useForm();

  useEffect(() => {
    if (value) {
      reset(value)
    }
  }, [value])

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit))
  }, [])

  const roles=[
    {
      "id": 1,
      "title": "Administrador"
    },
    {
      "id": 2,
      "title": "Adjunto"
    }
  ]

  return (
    <React.Fragment>
      <FormGroup>
        <label>Nombre</label>
        <Input type={'text'} {...register("name",{required: "Nombre es requerido"})}/>
      </FormGroup>
      <FormGroup>
        <label>Documento</label>
        <Input type={'text'} {...register("document",{required: "Documento es requerido"})}/>
      </FormGroup>
      <FormGroup>
        <label>Titulo</label>
        <Select {...register("title")}>
          <option key={0} value={""} disabled>{"--Seleccionar una opción--"}</option>
          {roles.map(item => <option key={item.id} value={item.title} >{item.title}</option>)}
        </Select>
      </FormGroup>
    </React.Fragment>
  )

}

export default ManagerForm
