const numberFormat = (numero, digitos = 2) => {
  let parsedNumber = parseFloat(numero) || 0;
  return parsedNumber.toLocaleString('en-US', { minimumFractionDigits: digitos, maximumFractionDigits: digitos });
};

const NumberRound = ({digits, children}) => {
  let d = digits ? digits : 2
  let parsedNumber = parseFloat(children) || 0;
  return parsedNumber.toLocaleString('en-US', {minimumFractionDigits: d, maximumFractionDigits: d});
}

export {NumberRound,numberFormat}
