import React from "react";
import ComputerList from "./ComputerList";
import PrinterList from "./PrinterList";
import Card from "../../../../components/forms/Card";
import ComputerPrinterDownload from "./ComputerPrinterDownload";
import PrinterToken from "./PrinterToken";

const ComputerPrinterList = () => {

  return (
    <>
      <Card padding>
        <PrinterList />
      </Card>
      <Card padding>
        <ComputerList />
      </Card>
      <Card padding>
        <PrinterToken />
      </Card>
      <Card padding>
        <ComputerPrinterDownload />
      </Card>
    </>
  )
}

export default ComputerPrinterList;
