import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react'
import PageSection from "../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";
import {useHistory} from "react-router";
import {Apis} from "../../../../util/Apis";
import useFetch2 from "../../../../hooks/useFetch2";
import Button from "../../../../components/forms/Button";
import {SelectV2} from "../../../../components/forms/FormEl";
import axios from "axios";
import {errorToast, successToast} from "../../../../components/layout/ToastStyles";
import useDeleteModal from "../../../../modals/useDeleteModal";
import DeleteGenericModal from "../../../../modals/DeleteGenericModal";
import FormGroup from "../../../../components/forms/FormGroup";
import Slidebar from "../../../common/layout/Slidebar";
import {useForm} from "react-hook-form";
import {SiteContext} from "../../../common/context/SiteContext";
import SalaSelect from "../../../common/components/selects/SalaSelect";
import {FlexLayout} from '../../../../components/flex/Flex';
import AsyncBlock from '../../../../components/forms/AsyncBlock';
import PageContainerFull from '../../../../components/layout/PageContainerFull';
import useToggle from "../../../../hooks/useToggle";
import ReactTable from '../../../../components/util/ReactTable';
import CardOverview, {CardOverviewContainer} from "../../../../components/layout/CardOverview";
import {FiUsers} from "react-icons/fi";
import {GrUserAdmin} from "react-icons/gr";
import {HiStatusOnline} from "react-icons/hi";
import {OnlineActiveValue} from "./OnlineActiveValue";

const RoleSelect = ({userId, userGroupId, userGroups}) => {

  const [role, setRole] = useState(userGroupId)

  const changeRole = (userGroupId) => {
    setRole(userGroupId)
    axios.post(Apis.USER + '/users/' + userId + '/change-role', {userGroupId: userGroupId})
      .then(() => {
        successToast("Rol cambiado")
      })
      .catch(err => {
        console.log('hubo un error')
        errorToast(err.response.data.message, 6000)
      });
  }

  return (
    <SelectV2 style={{minWidth: '140px'}} onChange={(e) => changeRole(e.target.value)} value={role || 0}
              options={userGroups}/>
  )
}

const UserList = () => {

  let history = useHistory()

  const {salaId} = useContext(SiteContext)
  const {isOpen: slideOpen, toggler} = useToggle({});
  let defaultParams = {salaId: salaId}
  const [params, setParams] = useState(defaultParams)

  const {register, handleSubmit} = useForm({defaultValues: defaultParams});

  const {loading, data, fetchData, error} = useFetch2(Apis.USER + '/users', [], params);

  const {data: userGroups} = useFetch2(Apis.USER + '/user-group', []);

  const deleteElement = id => axios.delete(Apis.USER + '/users/' + id)

  const {
    wrapper: wrapper1,
    openDeleteModal
  } = useDeleteModal({
    text: '¿Desea eliminar este usuario?',
    action: deleteElement,
    onRefresh: fetchData
  })

  function addUser() {
    history.push('/admin/users/new')
  }

  function editUser(item) {
    history.push('/admin/users/edit/' + item.id)
  }

  const getData = (params) => {
    setParams(params)
  }

  useEffect(() => {
    if (params) fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  //const nameValue = useCallback(cell => cell.renderValue() + ' ' + cell.row.original.lastName, []);
  const nameValue = useCallback(cell => cell.renderValue(), []);
  const activityValue = useCallback(cell => <OnlineActiveValue value={cell.renderValue()}/>, []);

  const actionsValue = useCallback(cell =>
      <FlexLayout justifyContent='center'>
        <Button size='xs' variant='light' onClick={() => editUser(cell.row.original)}>Editar</Button>
        <Button size='xs' variant='danger' onClick={() => openDeleteModal(cell.row.original.id)}>Eliminar</Button>
      </FlexLayout>
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , []);

  const columns = useMemo(
    () => [
      {
        header: 'Nombre',
        id: 'fullname',
        accessorFn: cell => `${cell.firstName} ${cell.lastName}`,
        cell: nameValue
      },
      // {
      //   header: 'Documento',
      //   accessorKey: 'document',
      //   meta: {
      //     headerClassName: 'text-center', // class para la cabecera
      //     columnClassName: 'text-center', // class para la columna
      //   }
      // },
      {
        header: 'Usuario',
        accessorKey: 'username',
      },
      {
        header: 'Email',
        accessorKey: 'email',
      },
      {
        header: 'Establecimientos',
        accessorKey: 'locations',
      },
      {
        header: 'Actividad',
        accessorKey: 'lastPing',
        enableSorting: true,
        meta: {
          headerClassName: 'text-center', // class para la cabecera
          columnClassName: 'text-center', // class para la columna
        },
        cell: activityValue
      },
      // {
      //   header: 'Rol',
      //   accessorKey: 'userGroupId',
      //   enableSorting: true,
      //   meta: {
      //     headerClassName: 'text-center', // class para la cabecera
      //     columnClassName: 'text-center', // class para la columna
      //   },
      //   cell: cell => (
      //     <FlexLayout justifyContent='center'>
      //       {userGroups &&
      //         <RoleSelect
      //           userId={cell.row.original.id}
      //           userGroupId={cell.row.original.userGroupId ? cell.row.original.userGroupId : ''}
      //           userGroups={[{
      //             id: 0,
      //             name: "Seleccione"
      //           }, ...userGroups]}/>
      //       }
      //     </FlexLayout>
      //   )
      // },
      {
        header: 'Acciones',
        accessorKey: 'id',
        meta: {
          headerClassName: 'text-center', // class para la cabecera
          columnClassName: 'text-center', // class para la columna
        },
        enableSorting: false, // para deshabilitar el sort
        cell: actionsValue
      }
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [userGroups]
  )

  return (
    <PageContainerFull>

      <Slidebar visible={slideOpen} onHide={toggler}>
        <div style={{padding: '16px'}}>
          <FormGroup>
            <label>Sala</label>
            <SalaSelect {...register("salaId")} />
          </FormGroup>

          <Button variant={'primary'} title='Buscar' onClick={handleSubmit(getData)}/>
        </div>
      </Slidebar>

      <PageSection>
          <Title text="Usuarios" type={'page'}/>

        <CardOverviewContainer gridColumns='repeat(3, 1fr)'>
          <CardOverview
            title={data && data.total}
            subtitle='Total de usuarios'
            icon={<FiUsers size={20} />}
          />
          <CardOverview
            title={data && data.activeUsers}
            subtitle='Usuarios activos'
            icon={<HiStatusOnline size={20} />}
          />
          <CardOverview
            title={data && data.adminUsers}
            subtitle='Administradores'
            icon={<GrUserAdmin size={20} />}
          />

        </CardOverviewContainer>

        <AsyncBlock loading={loading} error={error}>
          <ReactTable
            columns={columns}
            data={(data && data.data) || []}
            customSearch
            filterOnclick={toggler}
            filterAdd={() => addUser()}
            noData='No existen usuarios registrados'
            pageSize={20}
          />
        </AsyncBlock>
      </PageSection>
      <DeleteGenericModal {...wrapper1} />
    </PageContainerFull>
  )
}

export default UserList
