import React, {useEffect} from "react";
import axios from "axios";
import {Apis} from "../../../../../util/Apis";
import OwnerForm from "./OwnerForm";
import useFetch2 from "../../../../../hooks/useFetch2";


const OwnerEdit = ({id, setAction, setTitle}) => {

  const {data} = useFetch2(Apis.TGM + '/partners/' + id)

  const update = (params) => {
    //se podria crear un componente <Id/> para guardar esta propiedad
    params.id = id;
    return axios.put(Apis.TGM + '/partners/' + id, params)
  }

  useEffect(() => {
    setTitle("Edicion de propietario")
  }, [])

  return (
    <OwnerForm value={data} onSubmit={update} setAction={setAction}/>
  )

}

export default OwnerEdit
