import React, { useContext } from "react";
import PageContainer from "../../../../components/layout/PageContainer";
import { Title } from "@wargostec/react-components";
import PageSection from "../../../../components/layout/PageSection";
import { Apis } from "../../../../util/Apis";
import CouponPromoForm from "./CouponPromoForm";
import { SiteContext } from "../../../common/context/SiteContext";
import { PageBackLabel } from "../../../../components/layout/PageBackLabel";
import { useHistory } from "react-router";
import { errorToast, successToast } from "../../../../components/layout/ToastStyles";
import { usePost } from "../../../../hooks/usePost";

const CouponPromoNew = () => {

  const { salaId } = useContext(SiteContext)
  const history = useHistory()

  const { mutate } = usePost({
    url: Apis.PC + '/coupon-type-promo',
    onSuccess: () => {
      history.push('/crm/coupon-promo')
      successToast("Promoción guardada correctamente")
    },
    onError: (err) => {
      errorToast(err.response.data.message ? err.response.data.message : "Ocurrió un problema. Por favor intenta nuevamente", 2000)
    }
  })

  const onCreate = (params) => {
    params.salaId = salaId
    mutate(params)
  }

  return (
    <PageContainer>
      <PageSection style={{ marginBottom: "20px" }}>
        <PageBackLabel text='Promociones' onClick={() => history.goBack()} />
        <Title text="Nueva promoción" />
      </PageSection>

      <PageSection>
        <CouponPromoForm value={{}} onSubmit={onCreate} />
      </PageSection>

    </PageContainer>
  )
}

export default CouponPromoNew
