import React, {useContext, useEffect} from 'react';
import PageContainer from '../../../../components/layout/PageContainer';
import PageSection from '../../../../components/layout/PageSection';
import {Title} from "@wargostec/react-components";
import {FormBlock} from '../../../../components/forms/FormGroup';
import Table from 'react-bootstrap/Table';
import useEditModal from '../../../../modals/useEditModal';
import {SiteContext} from '../../../common/context/SiteContext';
import useDeleteModal from '../../../../modals/useDeleteModal';
import axios from 'axios';
import PolicyNew from './PolicyNew';
import GenericModal2 from '../../../../modals/GenericModal2';
import DeleteGenericModal from '../../../../modals/DeleteGenericModal';
import Button, {ButtonBox} from '../../../../components/forms/Button';
import {useHistory} from 'react-router';
import NoDataTable from '../../../common/components/NoDataTable';
import Loader1 from '../../../common/components/Loader1';
import useFetch2 from '../../../../hooks/useFetch2';
import {Apis} from '../../../../util/Apis';

const PoliciesList = () => {
	const history = useHistory();
	const {salaId} = useContext(SiteContext);
	const sala = JSON.parse(localStorage.getItem('sala'));

	const {data, loading, fetchData, error} = useFetch2(
		Apis.USER + `/policies?salaId=${salaId}`,
		[]
	);

	const deleteElement = (id) => axios.delete(Apis.USER + '/policies/' + id);

	//hooks para crear,editar y eliminar
	const {wrapper: wrapper1, addAction} = useEditModal({
		addComponent: <PolicyNew />,
		onRefresh: fetchData
	});

	const {wrapper: wrapper2, openDeleteModal} = useDeleteModal({
		text: '¿Desea eliminar esta política?',
		onRefresh: fetchData,
		action: deleteElement
	});

	const editPolicy = (item) => {
		localStorage.setItem('policySalaId', item.salaId);
		history.push('/admin/policies/edit/' + item.id + '/' + item.name);
	};

	return (
		<PageContainer>
			<PageSection>
					<Title text="Políticas" type={'page'}/>
				<Button onClick={() => addAction()}>Crear Política</Button>
			</PageSection>
			<PageSection>
				<FormBlock>
					<Table striped hover>
						<thead>
							<tr>
								<th>ID</th>
								<th>Nombre</th>
								<th>Pertenece a</th>
								<th>Acciones</th>
							</tr>
						</thead>
						<tbody>
							{data &&
								data.map((item) => (
									<tr key={item.id}>
										<td>{item.id}</td>
										<td>{item.name}</td>
										<td>{item.salaId === 0 ? 'WARGOS' : sala.name}</td>
										{/*<td>{item.usedTimes + ' grupos'}</td>*/}
										<td>
											<ButtonBox>
												<Button
													style={{minWidth: '50px'}}
													onClick={() => editPolicy(item)}
												>
													{item.salaId === 0 ? 'Ver' : 'Editar'}
												</Button>
												{item.salaId !== 0 && (
													<Button onClick={() => openDeleteModal(item.id)}>
														Eliminar
													</Button>
												)}
											</ButtonBox>
										</td>
									</tr>
								))}
						</tbody>
					</Table>
					{!loading ? (
						data.length <= 0 && (
							<NoDataTable text={'Aún no hay políticas creadas en esta sala'} />
						)
					) : (
						<Loader1 />
					)}
				</FormBlock>
			</PageSection>

			<GenericModal2 {...wrapper1} />
			<DeleteGenericModal {...wrapper2} />
		</PageContainer>
	);
};

export default PoliciesList;
