import React, {useEffect} from "react";
import axios from "axios";
import {Apis} from "../../../../../util/Apis";
import RuleEditForm from "./RuleEditForm";
import {useParams} from "react-router";

const RuleNew = ({setAction, setTitle}) => {

  const {id} = useParams()

  const save = async (params) => {
    params.floatPoints = params.floatPoints ? 1 : 0
    await axios.post(Apis.PC + `/coupon-type/${id}/rule`, params)
  }

  useEffect(() => {
    setTitle("Nueva regla")
  }, [])

  return (
    <RuleEditForm value={{}} onSubmit={save} setAction={setAction}/>
  )
}

export default RuleNew
